<template>
  <v-container>
    <!-- Llistat Bales -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1>Llistat de Bales
          <v-btn color="success" dark @click="redireccio('BalaCreate')">+</v-btn>
        </h1>
        
        </td>
      <td v-if="grupo!=2 && grupo!=3 && grupo!=4">
        <v-btn
        v-if="grupo!=2" 
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        v-if="grupo!=2" 
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        v-if="grupo!=2" 
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <!-- <v-col sm="2">
          <v-text-field v-model="filtre.quilos" label="Quilos" @input="filtrar()" required></v-text-field>
        </v-col> -->
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.estat"
            :items="estatOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="id"
            label="Estat"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.tipo"
            :items="tipoOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="id"
            label="Composició"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.materia_primera__nom"
            :items="materiesPrimeresNomsSenseDuplicarOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="id"
            label="Materia primera"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.posicion_almacenamiento"
            :items="OpcionesSubalmacenes"
            @change="filtrar()"
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="barcode"
            label="Magatzem"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.proveidor"
            :items="proveidorOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="id"
            label="Proveïdor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="filtre.creada_en"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="filtre.creada_en" label="Creada en" readonly 
              rounded
              outlined
              clearable
              @click:clear="datepickerDelete()"
              v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filtre.creada_en" locale="ca" :first-day-of-week="1" scrollable @click:date="datepicker()">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(bala, index) in bales" :key="index" :class="{'bala-seleccionat': bala.estat==2,'bala-consumida': bala.estat==4,'bala-expedida': bala.estat==6, 'bala-pendent': bala.impresa==false && bala.estat!=2 && bala.estat!=4 && bala.estat!=6 && bala.estat!=5 && bala.estat!=3} ">
          <td @click="balaDetail(bala.id)" class="text-bala"><strong> {{textNomBala(bala)}} </strong> {{ textBala(bala) }} 
          <img
          v-if="bala.estat==2"
          src="https://cdn.pixabay.com/photo/2014/04/02/10/44/warning-sign-304370_960_720.png"
          alt
          />
          <img
          v-if="bala.estat==3 || bala.estat==5"
          src="https://cdn.pixabay.com/photo/2013/07/12/12/29/arrow-145781_960_720.png"
          alt
          />
          <img
          v-if="bala.impresa==false && bala.estat!=2 && bala.estat!=4 && bala.estat!=6 && bala.estat!=5 && bala.estat!=3"
          src="https://cdn.pixabay.com/photo/2017/06/26/00/46/flat-2442462_960_720.png"
          alt
          />
          

          </td>
          <td>
          <v-btn
              v-if="bala.magatzem"
              @click="quitarAlmacenBala(bala)"
            >Traure magatzem</v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Bales -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="bales.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";


export default {
  name: "bala-llistat",
  data: () => ({
    OpcionesSubalmacenes:[],
    filtre: {
      creada_en: "",
      materia_primera__nom: null,
      magatzem: null,
      posicion_almacenamiento:null,
      proveidor: null,
      quilos: "",
      pagina: null, 
      tipo: null
    },
    estatOpcions: [
      { id: 1, nom: "Sin asignar" },
      { id: 2, nom: "Asignanada a Mesclada" },
      { id: 3, nom: "Mezclando" },
      { id: 4, nom: "Mezclada" },
      { id: 5, nom: "Asignada a OE" },
      { id: 6, nom: "Expedida" }
    ],

    paginasAñadidas:[],
    json_fields: {
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        QUILOS: "quilos", 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        ESTAT: {
          field: "estat",
          callback: value => (value==4 ? "Sí" : value==3 ? "En procés" : "No")
        },
        MATERIA_PRIMREA: "materia_primera_nom", 
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        PROVEIDOR: "proveidor_nom", 
        DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales.xls",
    
    modalData: false,
    balesFiltrades: [],
    pagina: 1,
    carregaInicial: false,
    // totalPagines: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      bales: "getBales",
      totalPagines: "getBalesPaginacio",
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      materiesPrimeresNomsSenseDuplicarOpcions:
        "getMateriesPrimeresNomsSenseDuplicarCap",
      proveidorOpcions: "getProveidors",
      tipoOpcions: "getTipusMateria",
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getBales",
      "getBalesFiltrades",
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getMateriesPrimeresNomsSenseDuplicar",
      "getProveidors",
      "getTipusMateria", 
      "desalmacenarBalas", 
    ]),
    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      this.getBalesFiltrades(this.filtre);
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      this.getBalesFiltrades(this.filtre);
    },

    // Rerouting
    balaDetail(balaID) {
      this.$router.push({ name: "BalaDetail", params: { id: balaID , pagina: this.filtre.pagina} });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    getExcelData(){
      this.excelArray.forEach(elem=> 
      {elem.materia_primera_nom = this.textMateriaPrimera(elem.materia_primera);
      elem.proveidor_nom = this.textProveidor(elem.materia_primera)}
      )
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.bales); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Auxiliars
    datepicker() {
      this.$refs.dialog.save(this.filtre.creada_en);
      this.modalData = false;
      this.filtrar();
    },

    datepickerDelete() {
      this.$refs.dialog.save((this.filtre.creada_en = ""));
      this.modalData = false;
      this.filtrar();
    },
    quitarAlmacenBala(bala){
      if(bala.barcode.charAt(0) == "0") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_materia_prima: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
           //this.$router.go()
          }else{
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      if(bala.barcode.charAt(0) == "1") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_borra: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){

            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
            
          }else{

            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      
    },
    textNomBala(bala) {
      const textMP = this.textMateriaPrimera(bala.materia_primera);
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === bala.materia_primera
      );

      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }

      return `${textMP} - ${mpProveidor?.nom}`;
    },
    textBala(bala) {

      let textMagatzem;
      if (bala?.magatzem) {
        textMagatzem = this.magatzemOpcions.find(el => el.numero === 
        parseInt(bala.posicion_almacenamiento.substr(1, 3))
        )
          ?.nom;
        if(parseInt(bala.posicion_almacenamiento.substr(4, 2))>0){
              textMagatzem = textMagatzem + ' - Nº '+ bala.posicion_almacenamiento.substr(4, 2)
            }
      }

      if (textMagatzem === undefined) 
        {if(bala.estat==4){
          textMagatzem = "Consumida"
        } else if(bala.estat==6){
          textMagatzem = "Expedida"
        }else{
          textMagatzem = "Sense magatzem";
        }}
      

      return `
      [${parseInt(bala.quilos)} Kg] - ${textMagatzem} - ${bala.creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}`;
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''}`;
    },
    textProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }
      return mpProveidor?.nom;
    }
  },
  created() {
    
    this.getBales();
    if(this.$route.params.pagina) {this.pagina = this.$route.params.pagina;
    this.filtre.pagina = this.$route.params.pagina;
    this.paginar();}
    
    this.getIngredients();
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getMateriesPrimeresNomsSenseDuplicar();
    this.getProveidors();
    this.getTipusMateria();
    
  },
  // watch: {
  //   bales: function(bales) {
  //     // TODO - Fix horrible, s'ha de vore com fer que carregue inicialment les bales en bales filtrades
  //     if (!this.carregaInicial) {
  //       this.balesFiltrades = bales;
  //       this.carregaInicial = true;
  //     }

  //     // Quan modifiquem bales (mitjançant petició de filtrat), actualitzem bales i paginació
  //     this.balesFiltrades = this.bales;
  //     // this.totalPagines = this.getBalesPaginacio;
  //   }
  // }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
img {
  width: 15px;
}
.bala-seleccionat {
      background: rgb(241, 241, 153) ;
}
.bala-pendent {
      background: rgb(153, 188, 241) ;
}
.bala-consumida {
      background: rgb(241, 171, 153) ;
}
.bala-expedida {
      background: rgb(241, 171, 153) ;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>