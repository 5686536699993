<template>
  <v-container>
    <!-- Llistat Entrades -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1>Llistat d'Entrades 
          <v-btn color="success" dark @click="redireccio('EntradaCreate')">+</v-btn>
          </h1>
        </td>
        <td v-if="grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
      </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="4">
          <v-text-field v-model="filtre.quilos_total" label="Quilos" @input="filtrar()" 
          outlined
          rounded
          required></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-autocomplete
            v-model="filtre.proveidor"
            :items="proveidorOpcions"
            @change="filtrar()"
            clearable
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Proveïdor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="filtre.data_albara"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="filtre.data_albara" label="Data d'albarà"
              outlined
              rounded
              clearable
              @click:clear="datepickerDelete()"
              readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="filtre.data_albara"
              locale="ca"
              :first-day-of-week="1"
              scrollable
              @click:date="datepicker()"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(entrada, index) in entrades" :key="index">
          <td @click="entradaDetail(entrada.id)" class="text-entrada"><strong>{{entrada.proveidor_nom}}</strong>
          {{ textEntrada(entrada) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Entrades -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="entrades.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "entrada-llistat",
  data: () => ({
    filtre: {
      data_albara: null,
      proveidor: null,
      quilos_total: null,
      pagina: null
    },
    paginasAñadidas:[],
    json_fields: {
        ENTRADA: "entrada_nom", 
        QUILOS: "quilos_total", 
        COST: "cost_total", 
        NUM_BALES: {
          field: "bales",
          callback: value => (value.length)
        },
        PROVEIDOR: "proveidor_nom", 
        DATA_ALBARA: "data_albara", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Entradas",
    modalData: false,
    pagina: 1
  }),
  computed: {
    ...mapGetters({
      entrades: "getEntrades",
      proveidorOpcions: "getProveidors",
      totalPagines: "getEntradesPaginacio", 
      grupo: "getGroup"
    })
  },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions(["getEntrades", "getEntradesFiltrades", "getProveidors"]),

    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      localStorage.setItem('filtre_entrades',  JSON.stringify(this.filtre));
      this.getEntradesFiltrades(this.filtre);
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      localStorage.setItem('filtre_entrades',  JSON.stringify(this.filtre) )
      this.getEntradesFiltrades(this.filtre);
    },

    // Rerouting
    entradaDetail(entradaID) {
      this.$router.push({ name: "EntradaDetail", params: { id: entradaID } });
    },
    //Excel
    getExcelData(){
      this.excelArray.forEach(elem=> 
      {
      elem.entrada_nom = this.textEntrada(elem)}
      )
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];
    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.entrades); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Auxiliars
    datepicker() {
      this.$refs.dialog.save(this.filtre.data_albara);
      this.modalData = false;
      this.filtrar();
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.filtre.data_albara = ""));
      this.modalData = false;
      this.filtrar();
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textEntrada(entrada) {
      return `${
        entrada.quilos_total ? ` - Bales: ${entrada.bales.length}  - [${entrada.quilos_total} kg] ` : ""
      } ${entrada.data_albara ? " - " + entrada.data_albara.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1') : ""}
      ${
        entrada.observacions ? ` - ${entrada.observacions} ` : ""
      }
      `;
    },
  },
  created() {
    
    
    this.getProveidors();
    if(localStorage.getItem('filtre_entrades'))
    {this.filtre=JSON.parse(localStorage.getItem('filtre_entrades'));
    this.pagina=this.filtre.pagina; 
    this.getEntradesFiltrades(this.filtre);
    }else{
      this.getEntrades();
    }
  }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-top: 1em;
  display: flex;
  justify-content: space-around;
}
.text-entrada {
  text-align: left;
  padding-left: 12%;
  font-size: 20px;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>