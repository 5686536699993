<template>
  <v-container>
    <!-- Magatzem -->
    <v-simple-table v-if="magatzem" class="mt-12">
      <thead>
        <h1>Vista detallada de magatzem</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ magatzem.nom }}</td>
        </tr>
        <tr>
          <td>
            <b>Numero</b>
          </td>
          <td>{{ magatzem.numero }}</td>
        </tr>
      </tbody>
      <thead style="display: flex; justify-content: space-between;">
        <h1>Posicions</h1> <v-btn @click="dialogPosicio=true"> Afegir</v-btn><v-btn @click="btnEliminarPosicions"> Eliminar totes</v-btn>
      </thead>
      <tbody v-if="magatzem.posicions">
        <tr v-for="posicio in magatzem.posicions" :key="posicio.id">
          <td style="width:45% !important">
            <b>Magatzem: {{ (posicio.zona)}}</b>
          </td>
          <td style="width:25%">
            {{ posicio.barcode }}</td>
          <td> <v-btn @click="btnGenerarPegatina(posicio.id)"> GENERAR Codi </v-btn></td>
          <td> <v-btn color="error" dark @click="btnEliminarPosicio(posicio.id)"> Eliminar </v-btn></td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per afegfir posicio magatzem -->
      <v-dialog v-model="dialogPosicio" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Crear nova posicio</v-card-title>
          <v-container>
            <v-form id="formMagatzem" ref="formMagatzem" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="nova_posicio.numero" label="Numero" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="nova_posicio.zona" label="Zona" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogPosicio = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="afegirPosicio"
              :disabled="!nova_posicio.numero"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per afegfir posicio magatzem -->
      <!-- Dialog/Modal per editar magatzem -->
      <v-dialog v-model="dialogEditMagatzem" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark @click="btnDialogMagatzem" v-on="on">Editar magatzem</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar magatzem</v-card-title>
          <v-container>
            <v-form id="formMagatzem" ref="formMagatzem" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="magatzem_edit.nom" label="Nom" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="magatzem_edit.numero" label="Numero" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditMagatzem = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btnEditMagatzem"
              :disabled="!magatzem_edit.nom"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn @click="dialogEliminarMagatzem=true" :disabled="magatzem.posicions.length != 0 " color="error">Eliminar</v-btn>
      <!-- ./Dialog/Modal per editar magatzem -->
      <!-- Dialog/Modal per eliminar magatzem -->
      <v-dialog v-model="dialogEliminarMagatzem" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Confirmar eliminar magatzem</v-card-title>
          <v-card-text>Està segur que vol eliminar este magatzem?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEliminarMagatzem = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarMagatzem">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar magatzem -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Magatzem -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('MagatzemList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "magatzem-detail",
  data: () => ({
    magatzem: {
      nom: "",
    },
    magatzem_edit: {
      nom: "",
      numero: ""
    },
    nova_posicio:{},
    dialogEditMagatzem: false,
    dialogEliminarMagatzem: false,
    dialogPosicio: false
  }),
  methods: {
    ...mapActions(["editMagatzem", "eliminarMagatzem", "getMagatzem", "createPosicioMagatzem",
    "eliminarPosicioMagatzem", "generarPegatinaPosicio"]),

    // Botonera
    btnDialogMagatzem() {
      this.magatzem_edit = JSON.parse(JSON.stringify(this.magatzem));
    },
    btnGenerarPegatina(id) {
      this.generarPegatinaPosicio({id: id, info_impresion:{destino: "FrontEnd", request_id: localStorage.getItem('uuid')}});
      this.dialogPegatina = false;
    },
    afegirPosicio(){
      this.createPosicioMagatzem({magatzem: this.magatzem.id, posicio: this.nova_posicio.numero, zona:this.nova_posicio.zona}).then(
        response=>{
          if (response) {
            this.dialogPosicio = false;
            this.magatzem.posicions.push(response)
          }
        }
      )
    },
    btnEditMagatzem() {
      this.editMagatzem(this.magatzem_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("Magatzem.Notificacio", {
            text: "Magatzem editat correctament",
            color: "success"
          });
          this.dialogEditMagatzem = false;
          this.getMagatzem(this.magatzem.id).then(
            magatzem => (this.magatzem = magatzem)
          );
        } else
          EventBus.$emit("Magatzem.Notificacio", {
            text: "Error editant el magatzem",
            color: "error"
          });
      });
    },
    btnEliminarPosicio(id){
      this.eliminarPosicioMagatzem(id).then(
          response=>
          {if(response==204){ this.magatzem.posicions = this.magatzem.posicions.filter(el=> el.id!=id)}
          else{
            EventBus.$emit("Magatzem.Notificacio", {
            text: "Error eliminant les posicions",
            color: "error"
          });}
          })
    },
    btnEliminarPosicions(){
      this.magatzem.posicions.forEach(element => {
        this.eliminarPosicioMagatzem(element.id).then(
          response=>
          {if(response==204){ this.magatzem.posicions = this.magatzem.posicions.filter(el=> el.id!=element.id)}
          else{
            EventBus.$emit("Magatzem.Notificacio", {
            text: "Error eliminant les posicions",
            color: "error"
          });
          }}
        )
      });
    },
    btnEliminarMagatzem() {
      if (this.magatzem.posicions.length == 0 ) {this.eliminarMagatzem(this.magatzem.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Magatzem.Notificacio", {
            text: "Magatzem eliminat correctament",
            color: "success"
          });
          this.$router.push({ name: "MagatzemList" });
        } else
          EventBus.$emit("Magatzem.Notificacio", {
            text: "Error eliminant el magatzem",
            color: "error"
          });
      });}
      else{
        EventBus.$emit("Magatzem.Notificacio", {
            text: "Error eliminant el magatzem, té posicions associades",
            color: "error"
          });
      }
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getMagatzem(this.$route.params.id).then(
      magatzem => (this.magatzem = magatzem)
    );
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>