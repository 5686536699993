<template>
  <v-container>
    <!-- Ordre Eixida -->
    <v-form ref="form" class="mt-12">
      <h1>Creació Ordre d'Eixida</h1>
      <!-- Client -->
      <v-row>
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-autocomplete
            v-model="client"
            :items="clientOpcions"
            label="Client"
            item-text="nom"
            item-value="id"
            @change="carregaReserves"
            no-data-text
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col v-if="grupo!=4" sm="1">
          <!-- Dialog/Modal per crear client -->
          <v-dialog v-model="dialogClient" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear client</v-card-title>
              <v-container>
                <v-form id="formClient" ref="formClient" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="client_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.emails" label="Correu"></v-text-field>
                      </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogClient = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearClient"
                  tabindex="-1"
                  :disabled="!client_nou.nom || !client_nou.poblacio"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear client -->
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox" v-model="imprimir_logo">
          <label for="checkbox">Imprimir LOGO</label>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox1" v-model="hoja_extra">
          <label for="checkbox1">Imprimir HOJA EXTRA</label>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox3" v-model="llacer">
          <label for="checkbox3">Carga LLACER</label>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field
              v-model="num_pedido"
              label="Nº pedido"
              required
              outlined
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
          <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            
            v-model="comentari_general" 
            outlined
            label="Comentari General Albarà"></v-text-field>
          </v-col>
        </v-row>
      <v-row>
          <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            
            v-model="comentaris" 
            outlined
            label="Comentaris per a Operari"></v-text-field>
          </v-col>
        </v-row>
      <v-row>
        <v-col v-if="tte_fuera_listado" sm="9" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field
              v-model="transportista"
              label="Transportista"
              required
              outlined
            ></v-text-field>
        </v-col>
        <v-col v-else sm="9" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-autocomplete
            v-model="transportista"
            :items="transOpcions_hardcode"
            label="Transportista"
            item-text="nom"
            item-value="nom"
            no-data-text
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col sm="2" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox2" v-model="tte_fuera_listado">
          <label for="checkbox2">TTE fuera listado</label>
        </v-col>
        
      </v-row>
      <v-row>
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field
              v-model="entregado_en"
              label="Entregado en"
              required
              outlined
            ></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Client -->
      <!-- Afegir partida -->
      <v-row>
        <v-col md="2" offset-md="6">
          <v-btn
            block
            color="info"
            @click="afegirLiniaOrdreEixida()"
            tabindex="-1"
          >Afegir BORRA</v-btn>
          </v-col>
          <v-col md="2" offset-md="1">
          <v-btn 
          block 
          color="info" 
          @click="afegirLiniaOrdreEixidaMP()" 
          tabindex="-1
          ">Afegir DRAP</v-btn>
        
        </v-col>
      </v-row>
      <!-- ./Afegir partida -->
      <!-- Partida -->
      <div class="producte" v-for="(linia, index) in liniesOrdreEixida" :key="index">
        <v-row style="justify-content: space-around">
          <v-col sm="4">
            <strong>PRODUCTE #{{ index + 1}}</strong>
          </v-col>
          <v-col sm="2">
            <v-btn
              block
              color="warning"
              @click="eliminarLineaOrdreEixida(index)"
              tabindex="-1"
            >Eliminar</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="linia.tipo_balas==1">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field
              v-model="linia.materia_primera"
              label="NOM PRODUCTE"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="linia.tipo_balas!=1" >
          <v-col sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-autocomplete
              v-model="linia.reserva"
              :items="reservaOpcions"
              label="Reserva"
              item-text="nom"
              item-value="id"
              outlined
              clearable
              @click:clear="quitarReserva(linia)"
              no-data-text
              :disabled="!client"
              :@change="reservaTriada(index,linia)"  
            ></v-autocomplete>
          </v-col>
          <v-col sm="5" v-if="linia.reserva" style="padding-top: 0px; padding-bottom: 0px; ">
             <v-switch v-model="linia.completa" class="mx-2" label="Completa?" 
             @change="cambioSwitch(linia,index)"></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="linia.tipo_balas!=1">
          <v-col v-if="linia.partida_reserva" sm="12" 
          style="padding-top: 0px; padding-bottom: 10px; ">
            <span> <strong> Partida: {{linia.partida_reserva}} </strong></span>
          </v-col>
          <!-- ./ 
          <v-col v-else sm="3">
          <v-autocomplete
              :disabled="!client"
              v-model="linia.prefix"
              :items="prefixosOpcions_hardcode"
              label="Referencia"
              item-value="nom"
              item-text="nom"
              no-data-text
              outlined
              clearable
              @change="filtrar(linia.prefix, index)"
            >
              </v-autocomplete>
          </v-col>
          -->
          <v-col v-if="!linia.reserva" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-autocomplete
              :disabled="!client"
              v-model="linia.partida"
              :items="partidaOpcions"
              label="Partida"
              item-value="id"
              item-text="codi"
              no-data-text
              outlined
              clearable
              @click:clear="borrarPartida(linia)"
              :@input="actualizaPartidas(index,linia)"
              
            >
              <template slot="item" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}}- {{ textProducteFinal(data.item.producte_final) }}{{textObservacions(data.item.observacions)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}}- {{ textProducteFinal(data.item.producte_final) }}{{textObservacions(data.item.observacions)}}
              </template>
              </v-autocomplete>
          </v-col>
          <v-col v-if="linia.partida && !linia.reserva" sm="2" style="padding-top: 0px; padding-bottom: 0px; ">
              <v-switch
                  v-model="linia.partida_completa"
                  label="Toda"
                  inset
                  hide-details
                  @change="cambio_tota_partida(linia)"
                ></v-switch>
          </v-col>
          
          
        </v-row>
        <v-row >
        <v-col v-if="linia.tipo_balas!=1 && !GRS_fuera_listado[index]" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">  
            <v-autocomplete
              v-model="linia.codigo_grs"
              label="GRS"

              item-value="text1"
              item-text="text2"
              required
              outlined
              :disabled="!client"
              :items="GRSOpcions"
            ></v-autocomplete>
        </v-col>
            <v-col v-if="linia.tipo_balas!=1 && GRS_fuera_listado[index]" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">  
            <v-text-field
              v-model="linia.codigo_grs"
              label="GRS"

              required
              outlined
            ></v-text-field>
          
          </v-col>
          <v-col sm="2" style="padding-top: 0px; padding-bottom: 0px; " v-if="linia.tipo_balas!=1">
          <input type="checkbox" id="checkbox3" v-model="GRS_fuera_listado[index]">
          <label for="checkbox3">GRS fuera de listado</label>
          </v-col>
          <v-col sm="2" style="padding-top: 0px; padding-bottom: 0px; " v-if="linia.tipo_balas!=1">
          <input type="checkbox" id="checkbox4" v-model="linia.imprimir_observaciones">
          <label for="checkbox4">Imprimir OBSERVACIONES</label>
          </v-col>
        </v-row> 
        <v-row >
          <!--
          <v-col v-if="linia.completa && linia.tipo_balas!=1" sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <span> Quilos aproximats: {{linia.quilos_aproximats}} kg</span>
          </v-col>
          -->
          <v-col v-if="!linia.completa && linia.tipo_balas!=1 && !linia.reserva" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="!client"
              v-model="linia.quilos_aproximats"
              @click="vaciarCampo(index,'q')"
              label="Quilos aproximats"
              type="number"
              outlined

              required
              :rules="[v => v <=linia.estado.estado_reservas.quilos_disponibles 
              || 'Superats kg disponibles ('+linia.estado.estado_reservas.quilos_disponibles+ 'kg)']"
            ></v-text-field>
          </v-col>
          <v-col v-if="linia.tipo_balas!=1 && linia.reserva" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="!client"
              v-model="linia.quilos_aproximats"
              @click="vaciarCampo(index,'q')"
              label="Quilos aproximats"
              type="number"
              outlined

              required
              :rules="[v => v <=linia.quilos_disponibles 
              || 'Superats kg disponibles ('+linia.quilos_disponibles+ 'kg)']"
            ></v-text-field>
          </v-col>
          <v-col v-if="linia.tipo_balas==1" sm="12" style="padding-top: 0px; padding-bottom: 0px; ">  
            <v-text-field
              v-model="linia.quilos_aproximats"
              label="Quilos aproximats"
              @click="vaciarCampo(index,'q')"
              type="number"

              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col v-if="!linia.completa && linia.estado && !linia.reserva" sm="3" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="true"
              v-model="linia.quilos_disponibles"
              label="Quilos disponibles"
              type="number"

              outlined
              rounded
              required
            ></v-text-field>
          </v-col>
          <v-col v-if="(linia.estado)" sm="3" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="true"
              v-model="linia.bales_disponibles"
              label="Bales disponibles"
              type="number"
              outlined
              rounded
              required
            ></v-text-field>
          </v-col>
          <v-col v-if="linia.reserva" sm="3" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="true"
              v-model="linia.quilos_disponibles"
              label="Quilos pendents"
              type="number"
              outlined
              rounded
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!linia.partida_completa">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="!client"
              v-model="linia.max_num_bales"
              label="Màxim número de bales"
              @click="vaciarCampo(index,'m')"
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="linia.partida_completa">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="true"
              v-model="linia.max_num_bales"
              label="Màxim número de bales"
              @click="vaciarCampo(index,'m')"
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            :disabled="!client"
            v-model="linia.observacions" 
            outlined
            label="Observacions"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            :disabled="!client"
            v-model="linia.comentaris" 
            outlined
            label="Comentaris"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field v-model="linia.ref_materia" 
            label="Ref Matèria" required
            :disabled="!client"
            outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field v-model="linia.num_pedido" 
            label="Num Comanda" 
            outlined
            :disabled="!client"
            required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field v-model.number="linia.ref_ari" 
                  label="Referència Cliente"
                  :disabled="!client"
                  outlined
                  required></v-text-field>
          </v-col>
        </v-row>
      </div>
      <!-- ./Partida -->
    </v-form>
    <!-- ./Ordre Eixida -->
    <!-- Botonera -->
    <div class="botonera">
      <v-btn color="error" @click="btnCancelar" tabindex="-1">Cancel·lar</v-btn>
      <v-btn
            color="info"
            @click="afegirLiniaOrdreEixida()"
            tabindex="-1"
          >Afegir BORRA</v-btn>
          
          <v-btn 
          color="info" 
          @click="afegirLiniaOrdreEixidaMP()" 
          tabindex="-1
          ">Afegir DRAP</v-btn>
      <!-- <v-btn color="success" @click="pas = 2" :disabled="!client">Escanejar bales</v-btn> -->
      <v-btn color="success" @click="btnCrearOrdreEixida" :disabled="!client">Crear</v-btn>
    </div>
    <!-- ./Botonera -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('OrdreEixidaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "ordre-eixida-create",
  data: () => ({
    partida_anterior:[],
    client: null,
    hoja_extra: false,
    llacer: true,
    comentaris:"",
    comentari_general:"",
    liniesOrdreEixida: 
      [ {bales: "",
        estado: "",
        max_num_bales:"0",
        num_pedido:"",
        observacions:"",
        partida:"",
        quilos_aproximats:"",
        quilos_disponibles:"",
        ref_ari:"",
        ref_materia:"",
        reserva: null,
        bales_disponibles:""}
        ]
    ,
    imprimir_logo:true,
    partidaOpcions_lineas:[{},{},{},{},{},{},{}],
    prefixosOpcions_hardcode:[
      {id:"1", nom:"1"},
      {id:"1", nom:"2"},
      {id:"1", nom:"3"},
      {id:"1", nom:"RC"},
      {id:"1", nom:"10"},
    ],
     transOpcions_hardcode:[
      {id:"1", nom:"TTES FCO SANCHIS"},
      {id:"2", nom:"TTES BERENGUER"}
    ],
    GRSOpcions:[
      {text1:"(98% R-CO 2%R-TR)", text2:"(98% - 2%)" },
      {text1:"(90% R-CO 10%R-TR)", text2:"(90% - 10%)" },
      {text1:"(80% R-CO 20%R-TR)", text2:"(80% - 20%)"},
      {text1:"(90% R-CO 10% R-VI)", text2:"(90% - 10% viscosa)"},
      {text1:"(60% R-CO 20% R-PL 20% R-TR)", text2:"(60% - 20% - 20%)"},
      {text1:"(70% R-CO 15% R-PL 15% R-TR)", text2:"(70CO - 15PES - 15OT)"},
    ],
    GRS_fuera_listado:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
    tte_fuera_listado: false,
    entregado_en:"",
    num_pedido:"",
    transportista:"",
    partidaOpcionsLinea:[],
    reservaLinia:[],
    estado:{estado_reservas:[]},
    dialogClient: false,
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    reservaOpcions: []
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      mesclades: "getMescladesReservades",
      tipusBorra: "getMateriesProducteFinal",
      partidaOpcions: "getPartides", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getBalaProducteFinalBarcode",
      "getClients",
      "getPartida",
      "getMesclada",
      "getReserva",
      "getPartidesFiltrades",
      "getMateriesProducteFinal",
      "getMescladesReservades",
      "getPartidesTodas",
      "getBorresFiltrades",
      "getPartidesTodasSinAcabar",
      "getPartidesTodasDispo",
      "getReservesPerClient",
      "sendClient",
      "sendLiniaOrdreEixida",
      "sendOrdreEixida", 
      "getEstadoReserva"
    ]),

    // Crear element
    btnCrearClient() {
      this.sendClient(this.client_nou).then(data => {
        if (data.status === 201) {
          this.client=data.data.id; 
          this.client_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogClient = false;
          this.ordreEixida.client = data.data.id;
        }
      });
    },
    cambioSwitch(linia){
      if(linia.completa){
        linia.quilos_aproximats = linia.quilos_disponibles;
       // linia.quilos_aproximats = 
      //parseInt(this.reservaLinia[index].quilos)-parseInt(this.reservaLinia[index].quilos_enviats);
      }
      else{
        linia.quilos_aproximats = 0;  
      }
    },
    cambio_tota_partida(lin){
      if(lin.partida_completa == true){
        //lin.observacions = "TODA";
        lin.quilos_aproximats = lin.quilos_disponibles;
        this.getBorresFiltrades({pagina:1, estats:["c","m"], partida:lin.partida}).then(
                  bales => {lin.observacions = "TODA (" + bales.count + "b)";
                  lin.max_num_bales = bales.count}
                );
      }
    },
    vaciarCampo(index,tipo){
      if(tipo=='m'){
        if(this.liniesOrdreEixida[index].max_num_bales ==0){
          this.liniesOrdreEixida[index].max_num_bales = ''
        }
      }
      if(tipo=='q'){
        if(this.liniesOrdreEixida[index].quilos_aproximats ==0){
          this.liniesOrdreEixida[index].quilos_aproximats = ''
        }
      }
    },
    btnCrearOrdreEixida() {
      this.liniesOrdreEixida.forEach(lin=>{
        if(lin.max_num_bales == ''){lin.max_num_bales =0}
        if(lin.quilos_aproximats == ''){lin.quilos_aproximats =0}
        //if(lin.max_num_bales.toUpperCase() == 'TOTES' || lin.max_num_bales.toUpperCase() == 'TOT'){lin.max_num_bales =100}
      })
      this.sendOrdreEixida({
        client: this.client,
        transportista: this.transportista,
        entregado_en: this.entregado_en,
        num_pedido: this.num_pedido,
        imprimir_logo: this.imprimir_logo,
        comentaris: this.comentaris,
        comentari_general: this.comentari_general,
        hoja_extra: this.hoja_extra,
        llacer: this.llacer,
      }).then(ordreEixida => {
        if (ordreEixida.status === 201) {
          var count=0;
          var last = this.liniesOrdreEixida.length;
          this.liniesOrdreEixida.forEach(linia => {
            linia.ordre_eixida = ordreEixida.data.ordre_eixida.id;
            count += 1
              this.sendLiniaOrdreEixida(linia)
              .then(dataLinia => 
              {if(count==last){
                if(dataLinia) {
                  this.$router.push({
                    name: "OrdreEixidaDetail",
                    params: { id: ordreEixida.data.ordre_eixida.id }
                  });
                }
              }});
            
          });
          EventBus.$emit("OrdreEixida.Notificacio", {
                    text: "Creada Ordre d'Eixida amb èxit",
                    color: "success"
                  });
          this.$router.push({
                    name: "OrdreEixidaDetail",
                    params: { id: ordreEixida.data.ordre_eixida.id }
                  });
        }
      });
    },

    // Auxiliar
    reservaTriada(index,linia){
      if(linia.reserva) {
        linia.partida_reserva = this.reservaOpcions.find(el=> el.id== linia.reserva)?.nom.split(" ")[0];
        linia.partida = this.reservaOpcions.find(el=> el.id== linia.reserva)?.partida;
      this.getReserva(linia.reserva).then(reserva=>
        {this.reservaLinia[index]= reserva;
        linia.num_pedido = reserva.num_pedido;
        if(reserva.observacions && this.comentari_general=="") {this.comentari_general = reserva.observacions;}
        linia.quilos_disponibles = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats);
        this.getBorresFiltrades({pagina:1, estats:["c","m"], partida:linia.partida}).then(
              bales => linia.bales_disponibles = bales.count
            );
        if(linia.completa) linia.quilos_aproximats = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
      )}
    },
    borrarPartida(linia){
     linia.partida = "";
     linia.quilos_disponibles = 0;
     linia.bales_disponibles = 0;
     
     
    },
    // partidaTriada(linia){
      
    // },
    actualizaPartidas(index, linia){
      
      if(linia.partida && linia.partida!=this.partida_anterior[index] )
      {
        this.partida_anterior[index] = linia.partida;
        this.getEstadoReserva(linia.partida).then(response => {
        linia.estado = response
        linia.quilos_disponibles = response.estado_reservas.quilos_disponibles
        })
        this.getBorresFiltrades({pagina:1, estats:["c","m"], partida:linia.partida}).then(
              bales => linia.bales_disponibles = bales.count
            );
        }
      // var reserva_id = this.liniesOrdreEixida[index].reserva;
      // if(linia.reserva && linia.quilos_aproximats==0) {linia.partida = this.reservaOpcions.find(el=> el.id== reserva_id)?.partida;
      // this.getReserva(linia.reserva).then(reserva=>
      //   {this.reservaLinia[index]= reserva;
      //   if(linia.completa) linia.quilos_aproximats = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
      // )}
      // if(linia.partida && !linia.partida_ok)
      // {this.getMesclada(linia.partida).then(
      //   of=>{
      //     this.partidaOpcionsLinea[index] = of.codi; 
      //     linia.partida_ok = true;
      //   }
      // )}
      // this.partidaOpcionsLinea[index] = this.textPartida(this.reservaOpcions.find(el=> el.id== reserva_id)?.partida)
    },
    filtrar(prefix, index){
      this.getPartidesFiltrades({pagina: 1, prefix: prefix}).then(response=>{
        this.partidaOpcions_lineas[index]=response.result
      })
    },
    textProducteFinal(id_producte){
      return this.tipusBorra.find(el => el.id === id_producte)?.nom
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    quitarReserva(linia){
      linia.partida_ok=false; 
      linia.completa = false; 
      linia.partida_reserva ="";
    },
    afegirLiniaOrdreEixida() {
      this.liniesOrdreEixida.push({
        quilos_aproximats: 0,
        max_num_bales: "0",
        observacions: "",
        reserva: null,
        bales: [],
        partida: null, 
        ref_ari:"",
        num_pedido:"",
        ref_materia:""
      });
    },
    afegirLiniaOrdreEixidaMP() {
      this.liniesOrdreEixida.push({
        quilos_aproximats: 0,
        max_num_bales: 0,
        tipo_balas: 1,
        observacions: "",
        reserva: "",
        bales: [],
        partida: "", 
        ref_ari:"",
        num_pedido:"",
        ref_materia:""
      });
    },
    btnCancelar() {
      this.$router.push({
        name: "Home"
      });
    },
    carregaReserves() {
      // Carreguem les reserves filtrades per client
      this.getReservesPerClient(this.client)
        .then(reserves => {
          this.reservaOpcions = reserves;
        })
        // Un cop les tenim, afegim el camp 'nom', calculat a partir de OF i quilos
        .then(() => {
          this.reservaOpcions = this.reservaOpcions.map(reserva => {
            //let reservaCodi = this.mesclades.find(
            //  el => el.id === reserva.partida
            //)?.codi.replaceAll('.', '');
            return { ...reserva, nom: `${reserva.nombre_producto.replaceAll('.', '')} - ${reserva.quilos_enviats}/${reserva.quilos} kg - Nº: ${reserva.num_pedido}` };
          });
        });
    },
    eliminarLineaOrdreEixida(index) {
      this.liniesOrdreEixida.splice(index, 1);
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getClients();
    this.getMateriesProducteFinal();
    this.getMescladesReservades();
    this.getPartidesTodasDispo({internas: '1'});
    this.getMateriesProducteFinal();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

.container {
  max-width: 100% !important;
}
/* Creació */

// Botonera
.botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 20%;
  }
}
.botonera-escanejar {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
  }
}

.producte{
  border: #8f8080 solid 1px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
// Stepper
.container {
  padding-top: 3em;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}
</style>