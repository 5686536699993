import { render, staticRenderFns } from "./AlbaraOrdreEixidaDetail.vue?vue&type=template&id=75453e8d&scoped=true"
import script from "./AlbaraOrdreEixidaDetail.vue?vue&type=script&lang=js"
export * from "./AlbaraOrdreEixidaDetail.vue?vue&type=script&lang=js"
import style0 from "./AlbaraOrdreEixidaDetail.vue?vue&type=style&index=0&id=75453e8d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75453e8d",
  null
  
)

export default component.exports