<template>
  <v-container>
    <!-- Borra  -->
      <v-dialog v-model="dialogImpresoras" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">TRIA L'IMPRESORA</v-card-title>
          <v-card-text>
            <v-autocomplete
            v-model="borra.impresora"
            :items="impresorasOps"
            outlined
            item-text="nom"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap impresora"
            label="Impresora"
          ></v-autocomplete></v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogImpresoras = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-simple-table v-if="borra.id" class="mt-12">
      <thead>
        <h1>Vista detallada de bala borra</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Barcode</b>
          </td>
          <td>{{ borra.barcode }}</td>
        </tr>
        <tr>
          <td>
            <b>Estat</b>
          </td>
          <td>{{ textEstat(borra.estat) }}</td>
        </tr>
        <tr>
          <td>
            <b>Fàbrica</b>
          </td>
          <td>{{ textFabrica(borra.fabrica) }}</td>
        </tr>
        <tr>
          <td>
            <b>Fabricada en quarto</b>
          </td>
          <td>{{ borra.cuarto }}</td>
        </tr>
        <tr v-if="borra.magatzem">
          <td>
            <b>Magatzem</b>
          </td>
          <td>{{ borra.magatzem }}</td>
        </tr>
        <tr v-if="borra.partida_nom">
          <td>
            <b>Partida</b>
          </td>
          <td>{{ borra.partida_nom }}</td>
        </tr>
        <tr v-if="grupo!=4">
          <td>
            <b>Cost quilo</b>
          </td>
          <td>{{ borra.cost_quilo }} €/kg</td>
        </tr>
        <tr v-if="grupo!=4">
          <td>
            <b>Preu quilo (€/kg)</b>

          </td>
          <td>{{ parseFloat(borra.preu_quilo).toFixed(3) }} €/kg</td>
        </tr>
        <tr>
          <td>
            <b>Quilos</b>
          </td>
          <td>{{ borra.quilos }} kg</td>
        </tr>
        <tr>
          <td>
            <b>Reservada</b>
          </td>
          <td>{{ borra.reservada ? 'Sí' : 'No' }}</td>
        </tr>
        <tr>
          <td>
            <b>Tipus de borra</b>
          </td>
          <td>{{ borra.tipus_borra }}</td>
        </tr>
        <tr v-if="borra.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ borra.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="red" dark @click="redireccio('PartidaDetail')">Tornar al llistat</v-btn>
      <v-btn color="primary" dark @click="btnGenerarPegatina_impresora">Imprimir etiqueta</v-btn>
      <v-btn color="success" dark @click="btnEditarBala"> Editar</v-btn>
    </div>
    <!-- Botons d'opcions -->
    <v-dialog v-model="dialogPegatina" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Etiqueta impresa</v-card-title>
          <v-card-text>Esta etiqueta està impresa</v-card-text>
          <v-card-text>Confirme que vol imprimir-la de nou.</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogPegatina = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina_impresora">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ./Proveïdor -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col v-if="$route.params.list" class="text-center" cols="12" @click="redireccio('BalaPFList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
      <v-col v-else class="text-center" cols="12" @click="redireccio('PartidaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
/* import { EventBus } from "@/event-bus.js"; */
import { mapActions, mapGetters } from "vuex";

export default {
  name: "partida-bala-detail",
  data: () => ({
    impresorasOps:[
      {nom: "PDF",
      id: "FrontEnd"}, 
      {nom: "IMPRESORA 1",
      id: "Pantalla1"},
      {nom: "IMPRESORA 2",
      id: "Pantalla2"},
      {nom: "IMPRESORA 3",
      id: "Pantalla3"},

    ],
    borra: {
      quilos: null,
      preu_quilo: null,
      reservada: false,
      observacions: "",
      fabricada_en_cuarto: null,
      tipus_borra: null,
      fabrica: null,
      magatzem: null,
      posicio: null,
      partida: null
    },
    estatOpcions: [
      { id: "c", nom: "Creada" },
      { id: "m", nom: "En magatzem" },
      { id: "a", nom: "Asignada" },
      { id: "e", nom: "Expedida" }
    ],
    dialogPegatina:false,
    dialogImpresoras: false 
    
    
  }),
  computed: {
    ...mapGetters({
      fabriques: "getFabriques", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "editMateriaProducteFinal",
      "eliminarMateriaProducteFinal",
      "getBorra",
      "getFabriques",
      "getMagatzem",
      "getPosicioMagatzem",
      "getMateriaProducteFinal",
      "getOrdreEixida", 
      "getPartida",
      "generarPegatinaBalaBorra",
      "imprimirPegatinaBalaBorra_new",
      "getQuartoMesclat"
    ]),

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.borra.partida, list: false} });
    },
    

    textFabrica(fabricaID) {
      return this.fabriques.find(el => el.id === fabricaID)?.nom;
    },
    textQuarto(id){
      if(id){
        this.getQuartoMesclat(id).then(quartoInfo=>{
          this.borra.cuarto = quartoInfo.quarto
        })
      }
    },
    textEstat(estat) {
      return this.estatOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    textPartida(partidaID) {
      return this.partides.find(el => el.id === partidaID)?.nom;
    },
    btnImprimirPegatina(){
      if(this.borra.impresa){
        this.dialogPegatina = true;
      } else {
        this.imprimirPegatina();
      }
    },
    btnGenerarPegatina_impresora(){
      this.dialogImpresoras =true;
      this.dialogPegatina = false;
    },
    btnGenerarPegatina() {
      this.dialogImpresoras = false;
      this.imprimirPegatinaBalaBorra_new({id: this.borra.id, info_impresion:{destino:this.borra.impresora, 
      request_id: localStorage.getItem('uuid')}})
      this.dialogPegatina = false;
    },
    textOrdreEixida(ordreEixidaID) {
      const data = this.ordresEixida
        .find(el => el.id === ordreEixidaID)
        ?.data_hora.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
      return `${ordreEixidaID} - ${data}`;
    },
    textTipusBorra(tipusBorraID) {
      return this.tipusBorra.find(el => el.id === tipusBorraID)?.nom;
    },
    btnEditarBala() {
      this.$router.push({ name: "PartidaBalaEdit", params: { id: this.borra.id } });
    },
  },
  async created() {
    this.getFabriques();
    await this.getBorra(this.$route.params.id).then(
      borra => (this.borra = borra)
    );
    if(this.borra.magatzem)
    {this.getPosicioMagatzem(this.borra.magatzem).then(posicion=>{
      if(posicion.magatzem){this.getMagatzem(posicion.magatzem).then(magatzem => {
      this.borra.magatzem = magatzem?.nom;
    });}
    })}
    
    this.getPartida(this.borra.partida).then(partida => {
      this.borra.partida_nom = partida?.codi.replaceAll('.', '');
      this.borra.cost_quilo = (partida.cost_materies_primeres/partida.quilos_borra_totales).toFixed(2)
    });
    this.getMateriaProducteFinal(this.borra.tipus_borra).then(tipusBorra => {
      this.borra.tipus_borra = tipusBorra?.nom;
    });
    this.textQuarto(this.borra.cuarto);
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  .v-data-table td {
    height: 38px;
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin-right: 5%;
    width: 30%;
  }
}
</style>