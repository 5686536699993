<template>
  <v-container>
    <!-- Llistat Bales -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1 v-if="magatzem.nom">Llistat de Bales de MP Assignades 
        en {{ magatzem.nom }}</h1>
        </td>
        <td>
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="2" offset-sm="1">
          <v-text-field v-model="filtre.quilos" 
            rounded
            outlined
            label="Quilos" @input="filtrar()" required></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.materia_primera"
            :items="materiesPrimeresOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Materia Primera"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" offset-sm="0">
        <v-autocomplete
            v-model="filtre.proveidor"
            :items="proveidorOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Proveïdor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" offset-sm="0">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="filtre.creada_en"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="filtre.creada_en" 
              rounded
              outlined
              clearable
              @click:clear="datepickerDelete()"
              label="Creada en" readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filtre.creada_en" locale="ca" :first-day-of-week="1" scrollable @click:date="datepicker()">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(bala, index) in bales" :key="index">
          <td @click="balaDetail(bala.id)" class="text-bala">{{ textBala(bala) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Bales -->
     <!-- Paginació -->
        <v-pagination
          v-model="filtre.pagina"
          :length="totalPagines"
          @input="paginar()"
          v-if="totalPagines > 1"
        ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('BalaAssignadaMagatzemsList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "bala-assignada-magatzem-llistat",
  data: () => ({
    filtre: {
      creada_en: "",
      materia_primera__nom: null,
      proveidor: null,
      quilos: "",
      magatzem: null,
      pagina: 1,
    },
    paginasAñadidas:[],
    json_fields: {
        BARCODE: {
          field: "barcode",
          callback: value => ("_"+String(value))
        },
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        QUILOS: "quilos", 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        ESTAT: {
          field: "estat",
          callback: value => (value==4 ? "Sí" : value==3 ? "En procés" : "No")
        },
        MATERIA_PRIMREA: "materia_primera_nom", 
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        PROVEIDOR: "proveidor_nom", 
        DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales",
    magatzem: {},
    modalData: false,
    balesFiltrades: [],
    carregaInicial: false,
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      bales: "getBales",
      ingredientsOpcions: "getIngredientsCap",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      // materiesPrimeresOpcionsCap: "getMateriesPrimeresCap",
      materiesPrimeresNomsSenseDuplicarOpcions:
        "getMateriesPrimeresNomsSenseDuplicarCap",
      proveidorOpcions: "getProveidors",
      proveidorOpcionsCap: "getProveidorsCap",
      totalPagines: "getBalesPaginacio"
    })
  },
  methods: {
    ...mapActions([
      "getBalesAssignadesFiltrades",
      "getIngredients",
      "getMateriesPrimeres",
      "getMateriesPrimeresNomsSenseDuplicar",
      "getMagatzem",
      "getProveidors"
    ]),
    // Filtrat
    filtrar() {
      this.getBalesAssignadesFiltrades(this.filtre);
    },
    paginar() {
      this.getBalesAssignadesFiltrades(this.filtre);
    },
    // Rerouting
    balaDetail(balaID) {
      this.$router.push({ name: "BalaAssignadaDetail", params: { id: balaID } });
    },
    redireccio(component) {
      this.$router.push({ name: component}
      );
    },
    //Excel
    getExcelData(){
      
      this.excelArray.forEach(elem=> 
      {elem.materia_primera_nom = this.textMateriaPrimera(elem.materia_primera);
      elem.proveidor_nom = this.textProveidor(elem.materia_primera)}
      )

      
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      this.nombreExcel = 'Llistat_BalesAssginades_'+this.magatzem.nom;
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.bales); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Text
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return materiaPrimera?.nom;
    },
    textProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }
      return mpProveidor?.nom;
    },
    // Auxiliars
    datepicker() {
      this.$refs.dialog.save(this.filtre.creada_en);
      this.modalData = false;
      this.filtrar();
    },

    datepickerDelete() {
      this.$refs.dialog.save((this.filtre.creada_en = ""));
      this.modalData = false;
      this.filtrar();
    },
    textBala(bala) {
      const textMP = this.textMateriaPrimera(bala.materia_primera);
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === bala.materia_primera
      );

      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }

      return `${textMP} - ${mpProveidor?.nom} [${bala.quilos} Kg] - ${
        bala.creada_en?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
      }`;
    }
  },
  created() {
    this.filtre.magatzem = this.$route.params.id;
    this.getMagatzem(this.filtre.magatzem).then(
      magatzem => (this.magatzem = magatzem)
    );
    this.getBalesAssignadesFiltrades(this.filtre);
    this.getIngredients();
    this.getMateriesPrimeres();
    this.getMateriesPrimeresNomsSenseDuplicar();
    this.getProveidors();
  },
  // watch: {
  //   bales: function(bales) {
  //     // TODO - Fix horrible, s'ha de vore com fer que carregue inicialment les bales en bales filtrades
  //     if (!this.carregaInicial) {
  //       this.balesFiltrades = bales;
  //       this.carregaInicial = true;
  //     }

  //     // Quan modifiquem bales (mitjançant petició de filtrat), actualitzem bales i paginació
  //     this.balesFiltrades = this.bales;

  //   }
  // }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>