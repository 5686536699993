<template>
  <v-container>
    <!-- Bala -->
    <v-dialog v-model="dialogImpresoras" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">TRIA L'IMPRESORA</v-card-title>
          <v-card-text>
            <v-autocomplete
            v-model="bala.impresora"
            :items="impresorasOps"
            outlined
            item-text="nom"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap impresora"
            label="Impresora"
          ></v-autocomplete></v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogImpresoras = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-simple-table v-if="bala && visible_todo" class="mt-12">
      <thead>
        <h1>Vista detallada de Bala</h1>
      </thead>
      <tbody>
        <tr v-if="bala.barcode">
          <td>
            <b>Barcode</b>
          </td>
          <td>{{ bala.barcode }}</td>
        </tr>
        <tr v-if="bala.quilos">
          <td>
            <b>Quilos</b>
          </td>
          <td>{{ bala.quilos }} kg</td>
        </tr>
        <tr v-if="bala_text.entrada.data_albara">
          <td>
            <b>Data entrada</b>
          </td>
          <td>{{ bala_text.entrada.data_albara }}</td>
        </tr>
        <tr v-if="bala_text.entrada.observacions">
          <td>
            <b>Observacions entrada</b>
          </td>
          <td>{{ bala_text.entrada.observacions }}</td>
        </tr>
        <tr v-if="bala.preu_quilo">
          <td>
            <b>Preu quilo (€/kg)</b>

          </td>
          <td>{{ parseFloat(bala.preu_quilo).toFixed(3) }} €/kg</td>
        </tr>
        <tr v-if="bala.estat">
          <td>
            <b>Estado</b>
          </td>
          <td>{{ textConsumida(bala.estat) }}</td> 
        </tr>
        <tr v-if="bala.estat ==2 ">
          <td>
            <b>Assigando a:</b>
          </td>
          <td><strong v-if="bala.nom_producte_final "> {{ bala.nom_producte_final }} </strong> {{ bala.partida_observacions }}</td> 
        </tr>
        <tr></tr>
        <tr>
          <td>
            <b>Materia primera</b>
          </td>
          <td>{{ bala_text.materia_primera }}</td>
        </tr>
        <tr v-if="bala.entrada" @click="entradaDetail(bala.entrada)">
          <td>
            <b>Entrada</b>
          </td>
          <td>{{textEntrada(entrada) }}</td>
        </tr>
        <tr></tr>
        <tr>
          <td>
            <b>Magatzem</b>
          </td>
          <td>{{ bala_text.magatzem }}</td>
        </tr>
        <tr v-if="bala.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ bala.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera" v-if="visible_todo">
      <v-btn color="success" dark @click="btnGenerarPegatina_impresora" v-if="!bala.impresa">Generar etiqueta</v-btn>
      <v-dialog v-model="dialogPegatina" persistent max-width="600" v-else>
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark v-on="on">Generar etiqueta</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Etiqueta impresa</v-card-title>
          <v-card-text>Esta etiqueta està impresa</v-card-text>
          <v-card-text>Confirme que vol imprimir-la de nou.</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogPegatina = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina_impresora">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn color="primary" dark @click="btnEditarBala">Editar bala</v-btn>
      <!-- Dialog/Modal per eliminar bala -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar bala</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar bala</v-card-title>
          <v-card-text>Està segur que vol eliminar esta bala?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarBala">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar bala -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Bala -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col
        class="text-center"
        cols="12"
        @click="redireccio('StockMateriaPrimeraMagatzemMateriaPrimeraList')"
        style="padding:0px"
      >
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "stock-materia-primera-bala-detail",
  data: () => ({
    impresorasOps:[
      {nom: "PDF",
      id: "FrontEnd"}, 
      {nom: "IMPRESORA 1",
      id: "Pantalla1"},
      {nom: "IMPRESORA 2",
      id: "Pantalla2"},
      {nom: "IMPRESORA 3",
      id: "Pantalla3"},

    ],
    entrada:{},
    visible_todo:false,
    bala: {
      quilos: "",
      preu_quilo: "",
      observacions: "",
      materia_primera: null,
      magatzem: null,
    },
    bala_text: {
      materia_primera: "",
      magatzem: "", 
      entrada:[],
    },
    dialog: false,
    dialogPegatina: false,
    dialogImpresoras: false
  }),
  methods: {
    ...mapActions([
      "eliminarBala",
      "getBala",
      "getMagatzem",
      "getProveidor",
      "getMateriaPrimera",
      "generarPegatinaBala", 
      "imprimirPegatinaBala_new", 
      "getMateriaProducteFinal", 
      "getMesclada", 
      "getMesclada_new", 
      "getPartida_new", 
      "getQuartoMesclat", 
      "getBalaParcial", 
      "getEntrada"
    ]),

    // Botonera
    btnGenerarPegatina_impresora(){
      this.dialogImpresoras =true;
      this.dialogPegatina = false;
    },
    btnGenerarPegatina() {
      this.imprimirPegatinaBala_new({id: this.bala.id, info_impresion:{destino: this.bala.impresora, request_id: localStorage.getItem('uuid')}})
      this.dialogPegatina = false;
    },
    btnEditarBala() {
      this.$router.push({ name: "BalaEdit", params: { id: this.bala.id, origen:"stock" } });
    },
    btnEliminarBala() {
      this.eliminarBala(this.bala.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala eliminada correctament",
            color: "success"
          });
          this.redireccio('StockMateriaPrimeraMagatzemMateriaPrimeraList');
        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error eliminant la bala",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({
        name: component,
        params: {
          magatzemID: this.bala.posicion_almacenamiento,
          materiaPrimeraID: this.bala.materia_primera
        }
      });
    },
    textEntrada(entrada) {
      return `
      ${
        entrada.observacions ? ` ${entrada.observacions} ` : ""
      }
      ${
        entrada.quilos_total ? ` Bales: ${entrada.bales.length}  - [${entrada.quilos_total} kg] ` : ""
      } ${entrada.data_albara ? " - " + entrada.data_albara.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1') : ""}
      
      `;
    },
    // Rerouting
    entradaDetail(entradaID) {
      this.$router.push({ name: "EntradaDetail", params: { id: entradaID } });
    },
    textConsumida(valor) {
      if (valor == 4) return "Consumida";
      else if (valor == 1) return "Sin asignar";
      else if (valor == 2 || valor == 3) return "En proceso de consumir";
      else if (valor == 5) return "Asignada a Carga";
      else if (valor == 6) return "Expedida";
    },
  },
  created() {
    this.getBala(this.$route.params.id)
      .then(bala => {this.bala = bala; 
      if(bala.estat==2){
        this.getBalaParcial(bala.barcode).then(bala_parcial=>{
            if(bala_parcial[0].quarto>0){
              this.getQuartoMesclat(bala_parcial[0].quarto).then(quarto=>
             {
               if(quarto.ordre_fabricacio)
               {
                 if(quarto.ordre_fabricacio_estat =="i" || quarto.ordre_fabricacio_estat== "e" || quarto.ordre_fabricacio_estat== "a")
                  this.getPartida_new(quarto.ordre_fabricacio)
                .then(mesclada => {
                  this.mesclada = mesclada;
                  this.visible_todo=false;
                  this.getMateriaProducteFinal(mesclada.producte_final).then(
                    materia_PF =>{
                      this.bala.nom_producte_final = mesclada.codi + "-" +materia_PF.nom
                      this.bala.partida_observacions =`${mesclada.observacions? `[ ${mesclada.observacions} ]`:''}`;
                      this.visible_todo=true;
                    }
                  )
                })
                }
                else{
                  this.getMesclada_new(quarto.ordre_fabricacio)
                .then(mesclada => {
                  this.mesclada = mesclada;
                  this.visible_todo=false;
                  this.getMateriaProducteFinal(mesclada.producte_final).then(
                    materia_PF =>{
                      this.bala.nom_producte_final = materia_PF.nom
                      this.bala.partida_observacions =`${mesclada.observacions? `[ ${mesclada.observacions} ]`:''}`;
                      this.visible_todo=true;
                    }
                  )
                })
                }
              })
          }});
      }
      })
      .then(() => {
        this.getMateriaPrimera(this.bala.materia_primera).then(
          materiaPrimera => {
            this.bala_text.materia_primera = materiaPrimera?.nom;
            this.getProveidor(materiaPrimera.proveidor).then(
              proveidor =>
                (this.bala_text.materia_primera += ` - ${proveidor?.nom}`)
            );
          }
        );
        if (this.bala.magatzem) {
          this.getMagatzem(this.bala.magatzem).then(magatzem => {
            this.bala_text.magatzem = magatzem?.nom;
          });
        }
        if (this.bala.entrada) {
          this.getEntrada(this.bala.entrada).then(entrada => {
            this.bala_text.entrada = entrada;
            this.entrada = entrada; 
          });
        }
        this.visible_todo=true;
      });
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 30%;
  }
}
</style>