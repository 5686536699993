<template>
  <v-container>
    <!-- Llistat maquines -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="8" offset-sm="1">
            <h1>CONTADORES</h1>
          </v-col>
          <v-col sm="1" offset-sm="0">
            <!-- Dialog/Modal per crear maquines -->
            <v-dialog v-model="dialogMaquina" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">MODIFICAR CONTADOR</v-card-title>
                <v-container>
                  <v-form id="formMaquina" ref="formMaquina" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="salto" label="Número siguiente partida" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCrearMaquina">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="btnResetearContador">Subir al más alto</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearGap"
                    :disabled="!salto"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear fàbrica -->
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="(contador, index) in contadores" :key="index">
          <td class="text-maquina">
            <div @click="btnModificar(prefixos[index].id)" v-if="contador.gaps">MÁQUINA: {{contador.prefix}} 00{{lasts_values[index].last_sequence_value}}<strong v-if="contador.gaps.length>0">- Huecos disponibles:</strong> <strong v-for="(gap, index2) in contador.gaps" :key="index2"> {{ gap.start }}-{{ gap.stop }} //</strong></div>
            <div>
              <v-btn color="error" @click="btnModificar(prefixos[index].id)" >MODIFICAR</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Fàbriques -->
    <!-- Dialog/Modal per eliminar fàbrica -->
    <v-dialog v-model="dialogEliminarMaquina" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar fàbrica</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarMaquina = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminarMaquina()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar fàbrica -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "maquina-llistat",
  data: () => ({
    dialogMaquina: false,
    dialogEliminarMaquina: false,
    maquinaEliminarID: null,
    numero_contador:[],
    contadores:[],
    prefix_id:0,
    salto:0,
    lasts_values:[],
    maquina_nova: {
      nom: "",
      prefix: "",
    },
    pagina: 1,
    totalPagines: 1
  }),
  computed: {
    ...mapGetters({
      maquines: "getMaquinas", 
      fabriques: "getFabriques", 
      prefixos: "getPrefixos"
    })
  },
  methods: {
    ...mapActions(["eliminarMaquina", "getMaquinas","getFabriques", 
    "sendMaquina", 
    "sendGap",
    "resetGap",
    "resetearContador",
    "getPrefixos", "getSecuencia","getLastValues"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    // Edició de fàbrica
    btnModificar(id){
      this.dialogMaquina=true; 
      this.prefix_id = id;
    },
    btnResetearContador(){
      this.resetearContador(this.prefix_id).then(maquina => {
        this.pagina = maquina;
        if (maquina.new_last_value != this.salto) {
          this.tancarCrearMaquina();
          this.contadores = [];
          this.lasts_values = [];
          this.prefixos.forEach(el => {
          if(el.nom=='1' || el.nom=='2' || el.nom=='3' || el.nom=='10'){
          this.getSecuencia(el.id).then(result=>
          this.contadores.push(result))
          this.getLastValues(el.id).then(result=>
          this.lasts_values.push(result))}})
        }
      });
    },
    btnCrearMaquina() {
      this.sendMaquina(this.maquina_nova).then(maquina => {
        this.pagina = maquina
        if (maquina == 201) {
          this.tancarCrearMaquina();
          
        }
      });
    },
    btnCrearGap() {
      //this.sendGap({id: this.prefix_id, data:{num_huecos: this.salto}}).then(maquina => {
      //  if (maquina == 201) {
      //    this.tancarCrearMaquina();
      //  }
      //});
      this.resetGap({id: this.prefix_id, data:{posicion: this.salto}}).then(maquina => {
        this.pagina = maquina;
        if (maquina.new_last_value == this.salto) {
          this.tancarCrearMaquina();
          this.contadores = [];
          this.lasts_values = [];
          this.prefixos.forEach(el => {
          if(el.nom=='1' || el.nom=='2' || el.nom=='3' || el.nom=='10'){
          this.getSecuencia(el.id).then(result=>
          this.contadores.push(result))
          this.getLastValues(el.id).then(result=>
          this.lasts_values.push(result))}})
        }
      });
    },
    btnModalEliminar(maquina) {
      this.dialogEliminarMaquina = true;
      this.maquinaEliminarID = maquina;
    },
    textFabrica(fabricaID) {
      return this.fabriques.find(el => el.id === fabricaID)?.nom;
    },

    btnEliminarMaquina() {
      this.eliminarMaquina(this.maquinaEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Maquina.Notificacio", {
            text: "Maquina eliminat correctament",
            color: "success"
          });
          this.dialogEliminarMaquina = false;
          this.maquinaEliminarID = null;
        } else
          EventBus.$emit("Maquina.Notificacio", {
            text: "Error eliminant el maquina",
            color: "error"
          });
      });
    },

    // Rerouting
    maquinaDetail(maquinaID) {
      this.$router.push({
        name: "MaquinaDetail",
        params: { id: maquinaID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearMaquina() {
      this.maquina_nova = {
        nom: "",
        prefix: "",
      };
      this.dialogMaquina = false;
    }
  },
  created() {
    this.getMaquinas();
    this.getFabriques();
    this.getPrefixos().then(prefixos=>
    {
      prefixos.forEach(el => {
        if(el.nom=='1' || el.nom=='2' || el.nom=='3' || el.nom=='10'){this.getSecuencia(el.id).then(result=>
        this.contadores.push(result))
        this.getLastValues(el.id).then(result=>
        this.lasts_values.push(result))}
      });
    });
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.text-maquina {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>