import { render, staticRenderFns } from "./PlantillaDetail.vue?vue&type=template&id=3e16ead0&scoped=true"
import script from "./PlantillaDetail.vue?vue&type=script&lang=js"
export * from "./PlantillaDetail.vue?vue&type=script&lang=js"
import style0 from "./PlantillaDetail.vue?vue&type=style&index=0&id=3e16ead0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e16ead0",
  null
  
)

export default component.exports