<template>
  <div id="punt-pesatge-bales">
    <div id="container">
      <!-- Escanejar bala -->
      <v-row>
        <v-col sm="4">
          <v-autocomplete
                v-model="OEselec"
                :items="ordresEixida"
                no-data-text="No hay cargas preparadas"
                outlined
                @change="RellenarDatosOE()"
                :disabled="(bales_carregades.length !=0 || !OEs_ok)"
                item-text="nom"
                item-value="id"
                label="Selecciona un ALBARÁN"
                clearable
              >
              <template slot="selection" slot-scope="data">{{ textOrdreEixida(data.item) }}</template>
            <template slot="item" slot-scope="data">{{ textOrdreEixida(data.item) }}</template>

              </v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-btn @click="cargarOEs"> Recargar </v-btn>
        </v-col>
        <v-col sm="4" offset-sm="1">
          <v-text-field
            v-model="codiEscanejat"
            label="Código escaneado"
            v-on:keyup.enter="carregaBala"
            outlined
            required
            ref="campBarcode"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Escanejar bala -->
      <!-- Dades -->
      <v-row id="dades1" v-if="OEselec">
        <!-- Informació Bala PA -->
        <v-col sm="12" class="text-center">
          <v-row id="info-bala-titol">
            <h3>Datos del Albarán</h3>
          </v-row>
          <div id="info-bala-pa">
            <v-row>
              <div v-if="dades_oe.client" id="kg-bala">
                <strong>Cliente</strong>
                <span>{{ dades_oe.client }}</span>
              </div>
            </v-row>
            
            <v-row>
              <div v-for="(linea,index) in dades_oe.lineas" :key="index">
                <strong v-if="linea.partida">Partida {{index +1}}</strong>
                <strong v-else>MP  {{index +1}} - {{textMateriaPrimera(linea.materia_primera)}} <span style="font-size: 20px" v-if="linea.observacions"> - {{linea.observacions}} </span></strong>
                <span v-if="linea.partida">{{ textPartida(linea.partida) }} - {{ textProducteFinal(linea.partida) }} <span style="font-size: 20px" v-if="linea.observacions"> 
                  - {{linea.observacions}} {{linea.num_bales_dispo}} </span>
                  <span style="font-size: 20px"> 
                  - {{linea.max_num_bales}} </span> </span>
              </div>
            </v-row>
          </div>
        <!-- ./Informació Bala PA -->
        <!-- Partida actual -->
        
          <div v-for="(linea,index) in dades_oe.lineas" :key="index">
          <v-row id="info-dades-titol" style="justify-content: space-around">
            <v-btn 
              v-if="linea.partida==null && !linea.seleccionada" 
              @click="SeleccionarLOE(linea)"
              > Seleccionar </v-btn>
            <v-btn 
              v-if="linea.partida==null && linea.seleccionada"
              :disabled="dades_oe.lineas.length<=1" 
              @click="SeleccionarLOE(linea)"
              > Deseleccionar </v-btn>
            <h3 v-if="linea.partida">Partida {{index +1}}: {{ textPartida(linea.partida) }} {{ textProducteFinal(linea.partida) }}
               {{ linea.quilos_aproximats>0 ? `-[${linea.quilos_aproximats} kg ] ` : '' }} 
               {{ linea.max_num_bales>0 ? `-[${linea.max_num_bales} bales ] ` : '' }}
               {{ linea.num_pedido>0 ? `-[Nº pedido ${linea.num_pedido} ] ` : '' }} <span style="font-size: 20px" v-if="linea.observacions"> - {{linea.observacions}} </span>
               <span style="font-size: 20px" v-if="linea.comentaris"> - {{linea.comentaris}} </span>
               </h3>
            <h3 v-else>MP {{index +1}} - {{textMateriaPrimera(linea.materia_primera)}} 
              {{ linea.quilos_aproximats>0 ? `-[${linea.quilos_aproximats} kg ] ` : ''}}  
              {{ linea.max_num_bales>0 ? `-[${linea.max_num_bales} bales ] ` : ''}}
              </h3>
            <v-btn 
            v-if="linea.partida==null"
            :disabled="true"
            dark
              > {{LOE_seleccionada}} / {{linea.seleccionada}}</v-btn>
              
          </v-row>
          <div id="info-dades-pa">
            <v-row 
            >
              <div id="num-maquina" :class="{'LOE-seleccionada': linea.seleccionada}">
                <p>BALAS TOTALES</p>
                <span v-if="linea.partida">{{ quilos_totales.find(el => el.partida == linea.partida).num_bales }}</span>
                <span v-else>{{ quilos_totales.find(el => el.linea == linea.id).num_bales }}</span>
              </div>
              <div id="num-maquina" :class="{'LOE-seleccionada': linea.seleccionada}">
                <p>KG TOTALES</p>
                <span v-if="linea.partida">{{ quilos_totales.find(el => el.partida == linea.partida).quilos }}</span>
                <span v-else>{{ quilos_totales.find(el => el.linea == linea.id).quilos }}</span>
              </div>
                <div id="botonera1">
                  <v-btn x-large color="primary" @click.stop="verBalas(linea.partida, linea.id)">Ver balas</v-btn>
                </div>
            </v-row>
          </div>
          </div> 
        </v-col>
        <!-- ./Partida actual -->
      </v-row>
      <v-row id="dades2">
        <div id="botonera">
            <v-btn x-large color="error" :disabled="OEselec==0" @click="dialogCancelar = true">Cancelar</v-btn>
            <v-btn x-large color="warning" :disabled="OEselec==0" @click="dialogExpedir=true">Guardar</v-btn>
          </div>
      </v-row>
      <!-- ./Dades -->
      <v-dialog v-model="dialogExpedir" persistent max-width="800px">
        <v-card>
          <v-card-title class="headline"> Expedición de balas de borra </v-card-title>
          <v-card-actions>
            <v-btn color="red" dark @click="dialogExpedir = false">Ha sido un error, CONTINUAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="expedirOrdre">CONFIRMAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogBalas" persistent max-width="800px">
        <v-card>
          <v-card-title class="headline">Listado de balas asignadas </v-card-title>
          <v-card-text style="font-size: 20px" v-if="bales_carregades.length != 0">
            <tr v-for="(bala, index) in bales_carregades" :key="bala.id" style="justify-content: left">
                <p style="font-size: 1em" v-if="filtro_balas==bala.partida">{{textProducteFinal(bala.partida)}}-  {{bala.barcode}} - {{bala.quilos}}kg 
                <img
                        src="https://cdn.pixabay.com/photo/2016/03/31/18/31/cancel-1294426_960_720.png"
                        alt
                        @click="borrarBalaListado(bala,index,linea_dialog)"
                      />
                <br>
                </p>
                <p style="font-size: 1em" v-if="filtro_balas=='no partida' && !bala.partida">{{textMateriaPrimeraNom(bala.materia_primera)}}-  {{bala.barcode}} - {{bala.quilos}}kg 
                <img
                        src="https://cdn.pixabay.com/photo/2016/03/31/18/31/cancel-1294426_960_720.png"
                        alt
                        @click="borrarBalaListado(bala,index,linea_dialog)"
                      />
                <br>
                </p>
              </tr>
             </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" dark @click="dialogBalas=false">CERRAR</v-btn>
          </v-card-actions>
          
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCancelar" persistent max-width="800px">
        <v-card>
          <v-card-title class="headline"> Cancelar expedición de balas de borra </v-card-title>
          <v-card-text style="font-size: 25px" v-if="bales_carregades.length != 0">
            Este albarán no tiene balas asignadas, ¿quieres cancelar la asignación? </v-card-text>
          <v-card-text style="font-size: 25px" v-else>
            Este albarán no tiene balas asignadas, ¿quieres cancelar la asignación? </v-card-text>
          <v-card-actions>
            <v-btn color="primary" dark @click="dialogCancelar = false">VOLVER</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" dark @click="cancelarOrdre">CANCELAR ALBARÁN</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogError" @keydown="tancarDialogError" max-width="600">
        <v-card>
          <v-card-title class="justify-center"><pre>{{msg_error}}</pre></v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError" autofocus>VOLVER</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Footer -->
      <v-footer class="font-weight-medium" height="38%">
        <v-col class="text-center" cols="12" @click="redireccio('MenuTreballadors')" style="padding:0px">
          <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
      <!-- ./Footer -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "carrega-ordre-eixida",
  data: () => ({
    OpcionesSubalmacenes:[],
    num_bales_dispo:"",
    ordresEixida_preparadas:[],
    OEs_ok: true,
    dialogBalas: false,
    LOE_seleccionada:"",
    codiEscanejat: "",
    OEselec: "",
    filtro_balas:"",
    linea_dialog:"",
    //partidaOpcions:[],
    dialogExpedir: false,
    dialogCancelar: false,
    dialogError: false,
    msg_error:"",
    quilos_totales:[],
    ordresEixidaFiltrades: [],
    balas_visibles: [false, false, false, false, false],
    ordresSelec: [],
    OE_completes: [],
    carregaInicial: false,
    bales_carregades:[],
    calculo_kg:[0,0],
    dades_oe: {
      lineas: "",
      client: "",
      num_maquina: 0,
      bales_totals: 0,
      kg_totals: 0
    },
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClientsCap",
      productesFinals: "getMateriesProducteFinal",
      ordresEixida: "getOrdresEixidaFiltrades",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors", 
      partidaOpcions:"getPartidesSimples",
      magatzems: "getMagatzems"
    })
  },
  methods: {
    ...mapActions([
      "getClients",
      "getMateriesProducteFinal",
      "getOrdresEixidaFiltrades",
      "getPartides",
      "getPartidesTodas",
      "getPartidesTodasSinAcabar",
      "getPartidesSimples",
      "getPartida",
      "getBorres",
      "getOrdreEixida",
      "getBorresFiltrades",
      "getBalaProducteFinalBarcode", 
      "asignarPFAOrdreEixida", 
      "asignarMPAOrdreEixida", 
      "completarOrdreEixida",
      "getBalaBarcode",
      "getMagatzems",
      "almacenarBalas",
      "desasignarBalasOrdreEixida",
      "getMateriesPrimeres",
      "getProveidors"
    ]),
    //auxiliars 
    tancarDialogError(){
      this.dialogError = false;
      this.$nextTick(() => this.focusCodi());
    },
    // Escaneja bala
    carregaBala() {
      if (this.codiEscanejat.length<9) {
        this.codiEscanejat = "";
        return;}
      if (this.codiEscanejat==""){
        this.tancarDialogError;
        return;}
      if (this.OEselec == "") {
        this.dialogError = true;
        this.codiEscanejat = "";
        this.msg_error = "PRIMERO TIENES QUE SELECCIONAR \n UNA CARGA"; 
        return}
      if (
        this.bales_carregades.find(el => el.barcode == this.codiEscanejat)
      ) {
        this.dialogError = true;
        this.msg_error = `La bala ${this.codiEscanejat} ya está asignada a esta salida`;
        this.codiEscanejat = "";
        return;
      }else{
        if(this.codiEscanejat.charAt(0) == "1"){this.getBalaProducteFinalBarcode(this.codiEscanejat).then(bala => {
          if(bala.estat == "a") {
            this.dialogError = true;
            this.msg_error = `La bala ${this.codiEscanejat} ya está asignada a otra salida`;
            this.codiEscanejat = "";
            return;
          }
          if(bala.estat == "e") {
            this.dialogError = true;
            this.msg_error = `La bala ${this.codiEscanejat} ya ha sido expedida`;
            this.codiEscanejat = "";
            return;
          }
          if (this.OEselec.lineas.find(el=> el.partida === bala.partida)) {
            if(bala.magatzem==null) {
            this.dialogError = true;
            this.msg_error = `La bala ${this.codiEscanejat} no está en ningún almacén`;
            this.codiEscanejat = "";
            return;
            }
            bala.linea_ordre_eixida = this.OEselec.lineas.filter(el=> el.partida === bala.partida).id;
            this.bales_carregades.unshift(bala);
            this.quilos_totales.forEach(el => {
              if (el.partida == bala.partida) {
                el.quilos = el.quilos + parseInt(bala.quilos);
                el.num_bales = parseInt(el.num_bales) + 1;
              }
            });
            this.codiEscanejat = "";
            return;
            } else {
              this.dialogError = true;
              this.msg_error = `La bala ${this.codiEscanejat} NO VA EN ESTA CARGA`;
              this.codiEscanejat = "";
              return;
            }
        });
        }else{
          this.getBalaBarcode(this.codiEscanejat).then(bala => {
              if (bala?.id) {
                if(bala.estat == 3 ){
                  this.dialogError = true; 
                  this.msg_error = `La bala ${bala.barcode} esta consumida`;
                  this.codiEscanejat="";
                }else if(bala.estat == 4 || bala.estat == 5){
                  this.dialogError = true; 
                  this.msg_error = `La bala ${bala.barcode} esta asignada a otra carga o ya está EXPEDIDA`;
                  this.codiEscanejat="";
                }
                else{
                    if(this.LOE_seleccionada==""){
                      this.dialogError = true; 
                      this.msg_error = `Seleciona una Materia prima`;
                      this.codiEscanejat="";
                      return
                  }else{
                    if(bala.magatzem==null) {
                    this.dialogError = true;
                    this.msg_error = `La bala ${this.codiEscanejat} no está en ningún almacén almacén`;
                    this.codiEscanejat = "";
                    return;
                    }
                    bala.LOE = this.LOE_seleccionada;
                    this.bales_carregades.unshift(bala);
                    this.quilos_totales.forEach(el => {
                        {
                          if(el.linea==this.LOE_seleccionada) {
                          el.quilos = el.quilos + parseInt(bala.quilos);
                          el.num_bales = parseInt(el.num_bales) + 1;
                          el.balas.unshift(bala.barcode)}
                        }
                      });
                      this.codiEscanejat = "";
                      return; 
                    
                  }
                  
                  }
                return;
              }else{
                EventBus.$emit("Mesclada.Notificacio", {
                  text: `No se ha encontrado la bala ${this.codiEscanejatLlistat} `,
                  color: "warning"
                });
                this.codiEscanejatLlistat="";
                return;
              }
            });
        }
      }
          
    },
    cargarOEs(){
        this.getOrdresEixidaFiltrades({completa:true, expedida:false}).then(
          response=> {
            if(response){
              response.results.forEach(
                ordre=>{
                  this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
                }
              )
            }
          }
        )
        
      
    },
    SeleccionarLOE(linea){
      if(linea.seleccionada){
        this.LOE_seleccionada="";
      }
      else
      {this.LOE_seleccionada=linea.id;}
      // if(linea.seleccionada)
      // linea.seleccionada= false;
      // else
      // linea.seleccionada= true;

      this.dades_oe.lineas.forEach(el=>{
        if(el.id == linea.id){
          if(el.seleccionada){
            el.seleccionada =false;
          }else{
            el.seleccionada = true;
          }
          
        }else{
          el.seleccionada = false;
        }

      })
      this.$nextTick(() => this.focusCodi());
    },
    //text

    textOrdreEixida(ordre) {
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      var productes = ""
      var comentaris = ""
      ordre.productos_lineas.forEach((prod, index)=>
      {
        comentaris = this.OE_completes.find(OE => OE.id === ordre.id)?.lineas[index]?.comentaris;
        productes = productes+ " - "+prod.replaceAll('.', '') 
        if(this.partidaOpcions.find(part => part.codi === prod)?.producte_final_nom)
        {productes = productes+ " [" + this.partidaOpcions.find(part => part.codi === prod)?.producte_final_nom + "]"}
        productes = productes + " "+ comentaris
      })

      return `${client} ${this.OE_completes.find(OE => OE.id === ordre.id)?.comentaris} ${productes}`;
    },
    textPartida(partida) {
      return this.partidaOpcions.find(el => el.id === partida)?.codi.replaceAll('.', '');
    },
    textMateriaPrimera(mp_nom) {
      return mp_nom;
    },
    verBalas(partida, id){
      if(partida) this.filtro_balas = partida
      else this.filtro_balas = 'no partida' 
      this.dialogBalas = true;
      this.linea_dialog =  id;    
    },
    textMateriaPrimeraNom(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }
      return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''} ${mpProveidor?.nom}`;
    },
    textProducteFinal(partida){
      const id_PF = this.partidaOpcions.find(el => el.id === partida)?.producte_final;
      var obs = this.partidaOpcions.find(el => el.id === partida)?.observacions;
      var nom = this.productesFinals.find(el => el.id === id_PF)?.nom;
      return `${nom} ${obs? `${obs}`:``}`
    },
    borrarBalaListado(bala, index, lin){
      if(bala.estat =='a' || bala.estat == 5){
        this.desasignarBalasOrdreEixida({barcodes: [bala.barcode]})
          
        this.almacenarBalas({balas_materia_prima: [bala.id],
        posicio_id: this.OpcionesSubalmacenes.find(elem=> 
          elem.barcode == bala.ultima_posicion_almacenamiento )?.id
        } )
        this.bales_carregades.splice(index, 1);
        this.quilos_totales.forEach(el => {
              if (el.linea == lin || el.partida == bala.partida) {
                el.quilos = el.quilos - parseInt(bala.quilos);
                el.num_bales = parseInt(el.num_bales) - 1;
              }
            });
      }
      if(bala.estat =='c' || bala.estat ==1){
        this.bales_carregades.splice(index, 1);
        this.quilos_totales.forEach(el => {
              if (el.linea == lin || el.partida == bala.partida) {
                el.quilos = el.quilos - parseInt(bala.quilos);
                el.num_bales = parseInt(el.num_bales) - 1;
              }
            });
      }
    },
    // Accions
    expedirOrdre() {
      this.bales_carregades.forEach(bala => 
      {if(bala.barcode.charAt(0) == "1")
        {this.asignarPFAOrdreEixida({id_oe: this.OEselec.id, barcode: bala.barcode})}
        else {this.asignarMPAOrdreEixida({id_oe: this.OEselec.id, barcode: bala.barcode, linea_id: bala.LOE})}
        });
      // this.completarOrdreEixida(this.OEselec.id)
      this.dialogExpedir=false;
      this.cancelarOrdre();
      this.OEselec="";
      this.getOrdresEixidaFiltrades({completa:true, expedida:false}).then(
          response=> {
            if(response){
              response.results.forEach(
                ordre=>{
                  this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
                }
              )
            }
          }
        )
    },
    assignarBales() {
      // this.asignarALineaOrdreEixida({id_loe: this.bales_carregades})
      this.dialogExpedir=false;

      // this.cancelarOrdre();
      // this.OEselec="";
    },
    cancelarOrdre() {
      this.dialogCancelar = false;
      this.dades_oe= {
        lineas: "",
        client: "",
        num_maquina: 0,
        bales_totals: 0,
        kg_totals: 0
      },
      this.OEselec = "";
      this.bales_carregades=[];
      this.calculo_kg[0]=0;
      //this.redireccio('MenuTreballadors')
    },

    // Auxiliars
    RellenarDatosOE(){
      if(this.OEselec){
        this.getOrdreEixida(this.OEselec).then(
        response=> {if(response){
          this.OEselec = response
          if(this.OEselec){
            this.dades_oe.client = this.clientOpcions.find(el => el.id === this.OEselec.client)?.nom;
            this.dades_oe.lineas = this.OEselec.lineas;
            this.OEselec.lineas.forEach(el=> {
              if(el.observacions=="TODA"){
                this.getBorresFiltrades({pagina:1, estats:["c","a","m"], partida:el.partida}).then(
                  bales => el.observacions = "TODA (" + bales.count + "b)"
                );
              }
              this.CrearContadoresQuilos(el);
              
              el.seleccionada = false;
              if(this.OEselec.lineas.length==1){el.seleccionada=true;
              this.LOE_seleccionada=el.id}});
            this.$nextTick(() => this.focusCodi());
            response.lineas.forEach(lin=>{
              lin.bales.forEach(bala=>{
                this.bales_carregades.unshift(bala)
              })
            })
            } else {
              this.cancelarOrdre();
            }
        }}
      )}
      else{
        this.dades_oe= {
          lineas: "",
          client: "",
          num_maquina: 0,
          bales_totals: 0,
          kg_totals: 0
        }
      }
      //this.OEselec = this.ordresEixida.filter(el=> el.id==this.OEselec)[0]
      
    },
    focusCodi(){
      this.$refs.campBarcode.focus();
      // this.$nextTick(() => this.focusCodi());
    },
    CrearContadoresQuilos(linea){
      if(linea.partida)
      {this.quilos_totales.push({partida:linea.partida, quilos:linea.quilos, num_bales: linea.num_bales});
      }else{
      this.quilos_totales.push({linea: linea.id, quilos:linea.quilos, num_bales: linea.num_bales , balas:[]})
      }
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
    created() {
    this.getClients();
    this.getProveidors();
    this.getMagatzems();
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    /* this.getPartides().then(partides=>{
      this.partidaOpcions=partides.result
    }); */
    this.getPartidesSimples();
    this.getMateriesProducteFinal();
    this.getMateriesPrimeres();
    this.getOrdresEixidaFiltrades({completa:true, expedida:false}).then(
          response =>{
            if(response){
              response.results.forEach(ordre=>
              {
                this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
              })
              this.ordresSelec=[];
              this.ordresEixida.forEach((el)=> {
              
              if(el.lineas.length>0)
                {el.nom= this.textOrdreEixida(el);
                
                if(el.nom) this.ordresSelec.push(el);}
                
              })
              this.OEs_ok = true;
            }
          }
        )
    }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

h1,
h2,
h3,
h3,
h4,
p,
span,
strong {
  font-size: 2em;
}

footer > div > span {
  font-size: 1em !important;
}

// General
#punt-pesatge-bales {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #container {
    width: 100%;
    padding: 0px;
    padding-top: 6%;
    .row{
      height: auto;
      justify-content: center;
      .col{
        padding-bottom: 0px;
      }
    }
  }
}

// Títols
#info-bala-titol,
#info-dades-titol {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 5px;
  h3 {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    background: white;
    border: 0.5px solid black;
    padding: 2px;
    font-size: 2.5vh;
  }
  col{
    padding-top: 1vh;
  }
  button{
    margin-top: 8px;
    width: 20%;
  }
}

// Row central
#dades1 {
  margin-bottom:2vh;
  justify-content: center;
}
// #dades2 {
//   margin-bottom:8%;
// }
.LOE-seleccionada {
      background: rgb(252, 236, 148) !important;
}
.LOE-seleccionada-text {
      background: rgb(230, 215, 130) !important;
    }
#codi-escanejat {
  border: none !important;
  margin-top: 5px ;
  margin-bottom: 15px;
  height: 55px;
  background: white;
  padding: 2px;
}

// Bloc esquerra
#info-bala-pa {
  background: rgb(211, 211, 211);
  padding: 0 0.5em;
  border: 1px solid black;
  //height: 40vh;
  //min-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.2vh;
    div {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
    button {
      height: 40% !important;
      max-height: 15vh;
    }
  }
}

// Bloc dreta
#info-dades-pa {
  background: rgb(211, 211, 211);
  padding: 0 0.2em;
  border: 1px solid black;
  //height: 20vh;
  // min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    font-size: 1.2vh;
    justify-content: center;
    div {
      background: white;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
}

// Botonera
#botonera {
  
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: none !important;

  button {
    width: auto;
    height: 5vh;
    border-radius: 10px;
    font-size: 2vh !important;
  }
}
img {
  width: 20px;
}
#botonera1 {
  background: none !important;
  width: 100%;
  height: 90px;
  justify-content: right;
  border: none !important;
  justify-content: center  !important;
  button {
    width: auto;
    height: 3vh;
    border-radius: 10px;
    font-size: 1.5vh !important;
  }
}
</style>