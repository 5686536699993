<template>
  <div id="correcio-ordre-eixida">
        <div id="container">
      <!-- Escanejar bala -->
      <!-- ./Escanejar bala -->
      <!-- Dades -->
      <v-row id="dades1" justify="center">
        <!-- Informació Bala PA -->
        <v-col sm="5">
          <h3>Corrección de Cargas (Ordenes de Salida)</h3>
          <!-- Escanejar bala -->
        </v-col>
        <v-col sm="5">
          <v-text-field
            ref="campBarcode"
            v-model="codiEscanejat"
            label="Código escaneado"
            v-on:keyup.enter="carregaBala"
            outlined
            autofocus
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row id="dades-bales">
        <v-col>
          <v-row id="info-bala-titol" v-if="bales_carregades!=0">
            <h3>Listado de balas escaneadas</h3>
          </v-row>
          <div id="info-bala-pa" v-if="bales_carregades!=0">
            <v-row>
              <div v-for="(bala, index) in bales_carregades" :key="index">
                <strong>Bala {{index +1}}</strong>
                <span id="text-bala">{{ bala.barcode }} - {{ textBorra(bala.tipus_borra) }}
                  <img
                  src="https://cdn.pixabay.com/photo/2016/03/31/18/31/cancel-1294426_960_720.png"
                  alt
                  @click.stop="btnBorrarBala(bala.id)"
                  /> 
                </span>
              </div>
            </v-row>
            <v-btn id="btn-borrar" x-large color="warning" dark @click="borrarLista">Borrar</v-btn>
          </div>
        </v-col>
        <!-- ./Partida actual -->
      </v-row>
      <v-row id="dades2">
        <div id="botonera">
            <v-btn x-large color="error"  @click="cancelarOrdre">Cancelar</v-btn>
            <v-btn x-large color="primary"  @click="dialogDesassignar = true" :disabled="bales_carregades.length<1">Desasignar</v-btn>
          </div>
      </v-row>
      <v-dialog v-model="dialogDesassignar" persistent max-width="800px">
        <v-card>
          <v-card-title class="justify-center" style="font-size: 2vh"> 
            DESASIGNAR BALAS DE ORDREN DE SALIDA </v-card-title>
          <v-card-text style="font-size: 2vh" v-for="(bala,index) in bales_carregades" :key="index">
            Bala {{index +1}}: {{ bala.barcode }} - {{bala.quilos}} kg - {{ textBorra(bala.tipus_borra) }}</v-card-text>
          <v-card-actions>
            <v-btn color="red" dark @click="tancarDialogError">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="desassignarBales">DESASIGNAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogError" justify="center" @keydown="tancarDialogError" max-width="600">
        <v-card>
          <v-card-title class="headline">{{msg_error}}</v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError">VOLVER</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dades -->
      <!-- Footer -->
      <v-footer class="font-weight-medium" height="38%">
        <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
          <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
      <!-- ./Footer -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import { EventBus } from "@/event-bus.js";

export default {
  name: "carrega-ordre-eixida",
  data: () => ({
    codiEscanejat: "",
    dialogError: false,
    dialogDesassignar: false,
    msg_error: "",
    bales_carregades:[],
  }),
  computed: {
    ...mapGetters({
      borres: "getBorres",
      materiesProducteFinalOpcions: "getMateriesProducteFinal"
    })
  },
  methods: {
    ...mapActions([
      "getBalaProducteFinalBarcode",
      "getBorres",
      "getMateriesProducteFinal",
      "desasignarBalasOrdreEixida",
      "getBalaBarcode"
    ]),
    // Escaneja bala
    carregaBala() {
      if(this.codiEscanejat.length<9) {
        this.codiEscanejat ="";
        return;}
      if(this.codiEscanejat=="") {this.tancarDialogError; return;}
      var codi = this.codiEscanejat;
      if (this.bales_carregades.find(el => el.barcode == codi)) {
        this.dialogError = true; 
        this.msg_error = `La bala ${codi} ja es troba al llistat`;
        this.codiEscanejat="";
        // this.codiEscanejat = "";
        return;
      }else{
      if(this.codiEscanejat.charAt(0) == "1")
      {this.getBalaProducteFinalBarcode(codi).then(bala => {
        if (bala.barcode>1){
          if(bala.linea_ordre_eixida>1){
              this.bales_carregades.push(bala);
              this.codiEscanejat = "";
              }else{
                this.dialogError = true; 
                this.msg_error = `La bala ${codi} no esta assignada a cap Ordre d'eixida `;
                this.codiEscanejat = "";
            return;
              }
          return;
          } else {
            this.dialogError = true; 
            this.msg_error = `No s'ha trobat la bala ${codi} `;
            this.codiEscanejat = "";
            return;
          }
          });}
          else{
            this.getBalaBarcode(this.codiEscanejat).then(bala => {
              if (bala.barcode>1){
                  if(bala.estat==5){
                      this.bales_carregades.push(bala);
                      this.codiEscanejat = "";
                      }else{
                        this.dialogError = true; 
                        this.msg_error = `La bala ${codi} no esta assignada a cap Ordre d'eixida `;
                        this.codiEscanejat = "";
                    return;
                      }
                  return;
                  } else {
                    this.dialogError = true; 
                    this.msg_error = `No s'ha trobat la bala ${codi} `;
                    this.codiEscanejat = "";
                    return;
                  }
                  });
          }
      } 
    },
    tancarDialogError(){
      this.dialogError = false;
      this.dialogDesassignar = false;
      this.$nextTick(() => this.focusCodi());
    },
    focusCodi(){
      this.$refs.campBarcode.focus();
      // this.$nextTick(() => this.focusCodi());
    },
    textBorra(tipusBorraID) {
      return this.materiesProducteFinalOpcions.find(
        el => el.id === tipusBorraID
      )?.nom;
    },
    // Accions
    imprimirPegatina() {
      console.log("imprimirPegatina");
    },
    btnBorrarBala(id){
      this.bales_carregades = this.bales_carregades.filter(el => el.id != id);
    },
    desassignarBales() {
      var balas_barcodes=[];
      this.bales_carregades.forEach(el =>balas_barcodes.push(el.barcode))
      this.desasignarBalasOrdreEixida({barcodes: balas_barcodes});
      this.dialogDesassignar=false;
      this.$nextTick(() => this.focusCodi());
      this.bales_carregades=[]; 
    },
    cancelarOrdre() {
      this.bales_carregades=[];
      this.redireccio('MenuTreballadors')
    },
    borrarLista() {
      console.log("Borrar lista");
      this.bales_carregades=[];
      //this.redireccio('MenuTreballadors')
    },

    // Auxiliars

    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getBorres();
    this.getMateriesProducteFinal();
    }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

h1,
h2,
h3,
h3,
h4,
p,
span,
strong {
  font-size: 3.5vw;
}

footer > div > span {
  font-size: 1em !important;
}

img {
  width: 3vw;
}
// General
#punt-pesatge-bales {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #container {
    height: 80%;
    width: 100%;
  }
}

// Títols
#info-bala-titol,
#info-dades-titol {
  display: flex;
  justify-content: center;
  font-size: 2vw;
  h3 {
    position: relative;
    top: 10px;
    background: white;
    border: 1px solid black;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
#text-bala{
  font-size: 3.5vw;
}
#titol {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 35px;
  h3 {
    position: relative;
    top: 10px;
    background: white;
    border: none;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
// Row central
#dades1 {
  margin-bottom:3px;
  padding-top: 5vh;
  vertical-align: center;
  col{
    padding: 0px;
  }
  h3{
    vertical-align: center;
    font-size: 3vh;
    text-align: center;
  }
}
#dades2 {
  margin-bottom:10vh;
}
#dades-bala{
  col{
    padding-top: 0px;}
  button {
      height: 40% !important;
      max-height: 50px;
      width: 30%; 
      font-size: 25px;
      margin-top: 20px
    }
}

#codi-escanejat {
  border: none !important;
  background: white;
}

// Bloc esquerra
#info-bala-pa {
  background: rgb(211, 211, 211);
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 12px;
  padding-top: 20px;
  border: 1px solid black;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
}
#btn-borrar {
      width: 30%; 
      font-size: 3vw;
      height: 8vw;
      margin-top: 4vh
    }
// Bloc dreta
#info-dades-pa {
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  //height: 20vh;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      background: white;
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
}

// Botonera
#botonera {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: none !important;

  button {
    width: 35%;
    height: 100%;
    border-radius: 10px;
    font-size: 3.2vw !important;
  }
}
</style>