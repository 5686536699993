import Vue from 'vue'
import jwt_decode from 'jwt-decode'
import { uuid } from 'vue-uuid'


import router from '../router/index.js'
import { mapActions} from "vuex";

import { EventBus } from "@/event-bus.js";

// function refrescarToken() {
//   // TODO - Fix fins que el refresh funcione

//   EventBus.$emit("Auth.Notificacio", {
//     text: "La sessió ha caducat",
//     color: "warning"
//   });
//   EventBus.$emit('dialogLogin',true);
//   // router.push({ name: 'Login' })
// }

export default {

    ...mapActions([
      "load_initial_data"
    ]),
  // Exemple
  login(username, password) {
    const url_endpoint = 'token-auth/'
    const payload = {
      username,
      password
    }
    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        if (response.data.access)
          localStorage.setItem('jwt', response.data.access)
          localStorage.setItem('refresh', response.data.refresh)
          const decoded = jwt_decode(response.data.access);
          localStorage.setItem('user_id', decoded.user_id);
          localStorage.setItem('uuid', uuid.v1());
          EventBus.$emit('dialogLogin',false);
        return response.data.access
      })
      .catch(error => {
        console.debug(error)
        if (error?.response?.status === 400)
          return error.response.status
      })
  },
  userType(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `user/${id}/`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  inspectToken(token){
    if(token){
      const tiempo_total_refresh = 2492000; // ms tiempo máximo que se puede refrescar un token
      const tiempo_para_refresh = 2000; // ms tiempo para pedir refresh
      const decoded = jwt_decode(token);
      const exp = decoded.exp
      const orig_iat = decoded.orig_iat
/*       console.log(exp - (Date.now()/1000)) 
      console.log(token) 
      console.log(localStorage.getItem('jwt')) 
      console.log((Date.now()/1000) - orig_iat) */
      // this.refreshToken(token)
      if((exp - (Date.now()/1000)) < tiempo_para_refresh && (Date.now()/1000) - orig_iat < tiempo_total_refresh){
        if(token)
        {this.refreshToken(localStorage.getItem('refresh')).then(NewToken=>
          {
            if(NewToken){
              // this.load_initial_data();
              EventBus.$emit('refrescadoToken',true)
              Vue.prototype.$http.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`
              return true
            }
          })}
        // return true
      // } else if ((Date.now()/1000) - orig_iat > 120){
      //   EventBus.$emit('dialogAviso',true);
      //   // DO NOTHING, DO NOT REFRESH         
        
      } else if ((exp - (Date.now()/1000)) < tiempo_para_refresh && (Date.now()/1000) - orig_iat > tiempo_total_refresh){
          //EventBus.$emit('dialogLogin',true)
          //localStorage.removeItem('jwt')
          return false
        // DO NOTHING, DO NOT REFRESH          
      } 
      else {
        // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
        // EventBus.$emit("Auth.Notificacio", {
        //   text: "La sessió ha caducat",
        //   color: "warning"
        // });
        // EventBus.$emit('dialogLogin',true);
        
        // EventBus.$emit('dialogAviso',{value:true, time: (-(120-((Date.now()/1000) - orig_iat))/60).toFixed(2)});
        return 'jose'
      }
      // if ((Date.now()/1000) - orig_iat < 170){
      //   EventBus.$emit('dialogAviso',true);
      //   // DO NOTHING, DO NOT REFRESH          
      // }
    }
  },
  refreshToken(token){
    const url_endpoint = 'token-refresh/'
    const payload = {
      refresh: token
    }
    if (token) {return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        if (response.data.access)
          localStorage.setItem('jwt', response.data.access)
          localStorage.setItem('refresh', response.data.refresh)
        return response.data.access
      })
      .catch(error => {
        console.debug(error)
        if (error?.response?.status === 400)
          EventBus.$emit("Auth.Notificacio", {
              text: "La sessió ha caducat",
              color: "warning"
            });
          EventBus.$emit('dialogLogin',true);
          //localStorage.removeItem('jwt')
      })}
  },
  //Número partida
  getSecuencia(prefix_id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `prefixos/${prefix_id}/list_sequence_gaps/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data 
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getLastValues(prefix_id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `prefixos/${prefix_id}/get_last_codis/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data 
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  createGap(prefix) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `prefixos/${prefix.id}/create_sequence_gap/`

    return Vue.prototype.$http.post(url_endpoint,prefix.data)
      .then((response) => {
        return response.data 
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  resetearContador(prefix) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `prefixos/${prefix}/reset_sequence_to_last_value/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.data 
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Error reseteando el contador de la Máquina - ${error.response.data.message}`,
            color: "error"
          });
      })
  },
  cambiarContadorPartida(partida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${partida.id}/cambiar_codi_partida/`

    return Vue.prototype.$http.post(url_endpoint,partida)
      .then((response) => {
        return response.data 
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Error reseteando el contador de la Máquina - ${error.response.data.error}`,
            color: "error"
          });
      })
  },
  resetGap(prefix) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `prefixos/${prefix.id}/reset_sequence_to_gap/`

    return Vue.prototype.$http.post(url_endpoint,prefix.data)
      .then((response) => {
        return response.data 
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Error cambiando el contador de la Máquina - ${error.response.data.message}`,
            color: "error"
          });
          //return error.response
        
      })
  },

  // Bala
  createBala(bala) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "bales_materies_primeres/"

    return Vue.prototype.$http.post(url_endpoint, bala)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  editBala(bala) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/${bala.id}/`
    return Vue.prototype.$http.put(url_endpoint, bala)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarBala(balaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/${balaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBala(balaID) {
    const url_endpoint = `bales_materies_primeres/${balaID}/`
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalaBarcode(balaBarcode) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/?barcode=${balaBarcode}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        if (response?.data?.count === 0) {
          EventBus.$emit("Bala.Notificacio", {
            text: `No s'ha trobat la bala amb el barcode "${balaBarcode}"`,
            color: "warning"
          });
          return 0
        }
        else
          return response?.data?.results[0]
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesMesclada(mesclada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/?limit=400&mesclada=${mesclada}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        if (response?.data?.count === 0) {
          return 0
        }
        else
          return response?.data?.results
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("Bala.Notificacio", {
            text: `No s'han trobat bales per a la mesclada`,
            color: "warning"
          });
      })
  },
  getBales() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesAssignades() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/?estat=2`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesAssignadesFiltrades(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `bales_materies_primeres/?limit20&offset=${(data.pagina - 1) * 20}&estat=2`

    // Filtres que afegim a la URL
    if (data.quilos)
      url_endpoint += `&quilos=${data.quilos}`
    if (data.magatzem)
      url_endpoint += `&magatzem_nom=${data.magatzem}`
    if (data.proveidor)
      url_endpoint += `&materia_primera__proveidor=${data.proveidor}`
    if (data.materia_primera__nom)
      url_endpoint += `&materia_primera__nom=${data.materia_primera__nom}`
    if (data.creada_en)
      url_endpoint += `&data_creacio=${data.creada_en}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  //TODO - PARA LA VISTA DE STOCK
  getBalesSenseMagatzem(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `bales_materies_primeres/?limit20&offset=${(data.pagina - 1) * 20}`
    url_endpoint += `&magatzem_nom=null`
    // Filtres que afegim a la URL
    if (data.estat)
      url_endpoint += `&estat=${data.estat}`
      
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesFiltrades(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `bales_materies_primeres/?limit20&offset=${(data.pagina - 1) * 20}`

    // Filtres que afegim a la URL
    if (data.estat)
      url_endpoint += `&estat=${data.estat}`
    if (data.quilos)
      url_endpoint += `&quilos=${data.quilos}`
    if (data.magatzem)
      url_endpoint += `&magatzem_nom=${data.magatzem}`
    if(data.posicion_almacenamiento)
      url_endpoint += `&posicio=${data.posicion_almacenamiento}`
    if (data.proveidor)
      url_endpoint += `&materia_primera__proveidor=${data.proveidor}`
    if (data.materia_primera__nom)
      url_endpoint += `&materia_primera__nom=${data.materia_primera__nom}`
    if (data.creada_en)
      url_endpoint += `&data_creacio=${data.creada_en}`
    if(data.tipo)
      url_endpoint += `&materia_primera__tipo=${data.tipo}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesFiltradesAsignades(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `bales_materies_primeres/get_bales_materies_primeres_asignadas_no_consumidas/?`

    // Filtres que afegim a la URL
    if (data.quilos)
      url_endpoint += `&quilos=${data.quilos}`
    if (data.magatzem)
      url_endpoint += `&magatzem_nom=${data.magatzem}`
    if (data.proveidor)
      url_endpoint += `&materia_primera__proveidor=${data.proveidor}`
    if (data.materia_primera__nom)
      url_endpoint += `&materia_primera__nom=${data.materia_primera__nom}`
    if (data.creada_en)
      url_endpoint += `&data_creacio=${data.creada_en}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesMagatzemMateriaPrimera(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/?limit20&offset=${(payload.pagina - 1) * 20}&materia_primera=${payload.materiaPrimeraID}&magatzem_nom=${payload.magatzemID}&posicio=${payload.posicion_almacenamiento}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  generarPegatinaBala(bala) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_bala/${bala}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  imprimirPegatinaBala_new(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_materies_primeres/${bala.id}/imprimir_pegatina/`

    return Vue.prototype.$http.post(url_endpoint,bala.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  generarPegatinasRepartidas(mezcladaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_mezclada/${mezcladaID}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Bala Parcial
  createBalaParcial(balaParcial) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "porcions_mesclat/"

    return Vue.prototype.$http.post(url_endpoint, balaParcial)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  getBalaParcial(barcode) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `porcions_mesclat/?bala__barcode=${barcode}`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        if (response?.data?.count === 0) {
          EventBus.$emit("Bala.Notificacio", {
            text: `No s'ha trobat bala parcial amb el barcode "${barcode}"`,
            color: "warning"
          });
          return 0
        }
        else
          return response?.data?.results
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarBalaParcial(balaParcialID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `porcions_mesclat/${balaParcialID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  modificarBalaParcial(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `porcions_mesclat/${bala.id}/`

    return Vue.prototype.$http.post(url_endpoint,bala)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Maquina - Mesclada 
  getMaquinas(){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })   
  },
  getInformes(payload){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `informes/?limit=20&offset=${(payload.pagina - 1) * 20}`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })   
  },
  getInforme(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `informes/${id}/`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })   
  },
  getMaquina(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${id}/`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })   
  },
  getMaquinaInfo(num_maquina){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${num_maquina}/`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })   
  },
  editMaquina(maquina) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${maquina.id}/`

    return Vue.prototype.$http.put(url_endpoint, maquina)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarMaquina(maquinaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${maquinaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  createMaquina(maquina) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "maquinas/"

    return Vue.prototype.$http.post(url_endpoint, maquina)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  mezclarBala(mezcla){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${mezcla.num_maquina}/mezclar_bala/`
    return Vue.prototype.$http.post(url_endpoint, { barcode: mezcla.barcode, quarto_id: mezcla.quarto })
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
          console.debug(error)
      })
  },
  cancelarMezclaCuarto(mezclada_data){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `quartos_mesclat/${mezclada_data.quarto_id}/cancelar_mezcla/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error
          //console.debug(error)
      })
  },
  procesarMezcladaCompleta(mezclada_data){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${mezclada_data.maquina_id}/procesar_mezclada_completa/`
    return Vue.prototype.$http.post(url_endpoint, mezclada_data)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error
          //console.debug(error)
      })
  },
  afegirBalesBorraPartida(info){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${info[0].id}/add_balas_a_partida_acabada/`
    return Vue.prototype.$http.post(url_endpoint, info[1])
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error
          //console.debug(error)
      })
  },
  forzarLlenado(mezcla){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${mezcla.num_maquina}/forzar_llenado_cuarto/`
    return Vue.prototype.$http.post(url_endpoint, { quarto_id: mezcla.quarto_id })
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  finalizarEjecucionQuarto(mezcla){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${mezcla.num_maquina}/finalizar_ejecucion_cuarto/`
    return Vue.prototype.$http.post(url_endpoint, { quarto_id: mezcla.quarto_id })
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          {EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data.error}"`,
            color: "warning"
          })
          console.debug(error)
          return error.response.data
          }
      })
  },
  iniciarEjecucionQuarto(mezcla){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${mezcla.num_maquina}/iniciar_ejecucion_cuarto/`
    return Vue.prototype.$http.post(url_endpoint, { quarto_id: mezcla.quarto_id })
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          {EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data.error}"`,
            color: "warning"
          })
          console.debug(error)
          return error.response.data}
          
      })
  },
  // Quarto Mesclat
  createQuartosMesclat(quartos) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${quartos.mesclada}/add_cuartos/`
    return Vue.prototype.$http.post(url_endpoint, quartos)
      .then((response) => {
        return response.data.message
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  eliminarQuartosMesclat(quartos) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${quartos.mesclada}/remove_cuartos/`
    return Vue.prototype.$http.post(url_endpoint,quartos)
      .then((response) => {
        return response.data.message
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  eliminarBalaMesclada(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${bala.mesclada}/remove_bala/`
    return Vue.prototype.$http.post(url_endpoint,bala.info)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  eliminarBalaPartida(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${bala.mesclada}/remove_bala/`
    return Vue.prototype.$http.post(url_endpoint,bala.info)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  eliminarBalaQuarto(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${bala.mesclada}/remove_bala_de_cuarto/`
    return Vue.prototype.$http.post(url_endpoint,bala.info)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  eliminarBalaQuartoPartida(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${bala.mesclada}/remove_bala_de_cuarto/`
    return Vue.prototype.$http.post(url_endpoint,bala.info)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  getQuartosMesclat(of) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `quartos_mesclat/?limit=500&ordre_fabricacio=${of}`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data.results
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  getQuartoMesclat(id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `quartos_mesclat/${id}/`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  moureQuartoMesclat(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `quartos_mesclat/${data.id}/asignar_cuarto_a_otra_mezclada/`
    return Vue.prototype.$http.post(url_endpoint,data.post_data)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data.error}"`,
            color: "warning"
          });
          console.debug(error.response)
          
        }
        else
          console.debug(error.response)
      })
  },
  // Bala Producte Final
  createBalaProducteFinal(info) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${info.num_maquina}/producir_bala_borra/`

    return Vue.prototype.$http.post(url_endpoint, info)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  editBalaProducteFinal(bala) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_producte_final/${bala.id}/`
    return Vue.prototype.$http.put(url_endpoint, bala)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  generarPegatinaBalaBorra(bala) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_bala_borra/${bala}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  imprimirPegatinaBalaBorra_new(bala){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_producte_final/${bala.id}/imprimir_pegatina/`

    return Vue.prototype.$http.post(url_endpoint,bala.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data}"`,
            color: "warning"
          });
      })
  },

  getBalaProducteFinalBarcode(balaBarcode) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_producte_final/?barcode=${balaBarcode}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        if (response?.data?.count === 0) {
          EventBus.$emit("Bala.Notificacio", {
            text: `No s'ha trobat la bala amb el barcode "${balaBarcode}"`,
            color: "warning"
          });
          return 0
        }
        else
          return response?.data?.results[0]
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBalesMagatzemProducteFinal(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_producte_final/?limit20&offset=${(payload.pagina - 1) * 20}&tipus_borra=${payload.BorraID}&magatzem_nom=${payload.magatzemID}&posicio=${payload.posicion_almacenamiento}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Client
  createClient(client) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "clients/"

    return Vue.prototype.$http.post(url_endpoint, client)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  editClient(client) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `clients/${client.id}/`
    return Vue.prototype.$http.put(url_endpoint, client)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarClient(clientID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `clients/${clientID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getClient(clientID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `clients/${clientID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getClients() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url = 'clients/?limit=100'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data.results
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Borres
  getBorra(borraID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_producte_final/${borraID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarBorra(balaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `bales_producte_final/${balaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBorres() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url = 'bales_producte_final/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getBorresFiltrades(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `bales_producte_final/?limit20&offset=${(data.pagina - 1) * 20}`

    // Filtres que afegim a la URL
    if (data.estat)
      url_endpoint += `&estat=${data.estat}`
    if (data.estats)
      data.estats.forEach(el=>
        {url_endpoint += `&estats=${el}`})
    if (data.quilos)
      url_endpoint += `&quilos=${data.quilos}`
    if (data.magatzem)
      url_endpoint += `&magatzem_nom=${data.magatzem}`
    if (data.posicion_almacenamiento)
      url_endpoint += `&posicio=${data.posicion_almacenamiento}`
    if (data.client)
      url_endpoint += `&client=${data.client}`
    if (data.tipus_borra)
      url_endpoint += `&tipus_borra=${data.tipus_borra}`
    if (data.maquina)
      url_endpoint += `&maquina=${data.maquina}`
    if (data.partida)
      url_endpoint += `&partida=${data.partida}`
    if (data.creada_en)
      url_endpoint += `&data_creacio=${data.creada_en}`
    if(data.magatzem=="sense")
      url_endpoint += `&magatzem_nom=null`

      return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Entrada
  createEntrada(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'entradas_materies_primeres/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)

          campsError.forEach(el => {
            if (isNaN(parseInt(el)))
              EventBus.$emit("Entrada.Notificacio", {
                //text: `Falta el camp "${el}"`,
                text: `${error.response.data.message}`,
                color: "warning"
              });
            else {
              const campsBalaError = Object.keys(error.response.data[el])
              EventBus.$emit("Entrada.Notificacio", {
                text: `Falta el camp "${campsBalaError[0]}" en la bala ${parseInt(el) + 1}`,
                color: "warning"
              });
            }
          })
        }
        else
          console.debug(error)
      })
  },
  editEntrada(entrada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `entradas_materies_primeres/${entrada.id}/`
    return Vue.prototype.$http.put(url_endpoint, entrada)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarEntrada(entradaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `entradas_materies_primeres/${entradaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.data?.status === 'entrada_materies_primeres_borrar_error') {
          EventBus.$emit("Entrada.Notificacio", {
            text: error?.response?.data?.message,
            color: "error"
          });
        }
        else if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getEntrada(entradaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `entradas_materies_primeres/${entradaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getEntrades() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `entradas_materies_primeres/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getEntradesFiltrades(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `entradas_materies_primeres/?limit20&offset=${(data.pagina - 1) * 20}`

    // Filtres que afegim a la URL
    if (data.quilos_total)
      url_endpoint += `&quilos_total=${data.quilos_total}`
    if (data.proveidor)
      url_endpoint += `&proveidor=${data.proveidor}`
    if (data.data_albara)
      url_endpoint += `&data_albara=${data.data_albara}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  generarPegatinaEntrada(entrada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_entrada/${entrada}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  imprimirPegatinaEntrada_new(entrada){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `entradas_materies_primeres/${entrada.id}/imprimir_pegatina/`

    return Vue.prototype.$http.post(url_endpoint,entrada.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  imprimirPegatinasPartida(partida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_balas_partida/${partida}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  imprimirPegatinasMesclada_new(partida){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${partida.id}/imprimir_mezclada/`

    return Vue.prototype.$http.post(url_endpoint,partida.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  imprimirPegatinasPartida_new(partida){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${partida.id}/imprimir_balas_borra_partida/`

    return Vue.prototype.$http.post(url_endpoint,partida.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Fàbriques
  createFabrica(fabrica) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "fabriques/"

    return Vue.prototype.$http.post(url_endpoint, fabrica)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  editFabrica(fabrica) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `fabriques/${fabrica.id}/`

    return Vue.prototype.$http.put(url_endpoint, fabrica)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarFabrica(fabricaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `fabriques/${fabricaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getFabrica(fabricaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `fabriques/${fabricaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getFabriques() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url = 'fabriques/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Ingredients
  createIngredient(ingredient) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "ingredients/"

    return Vue.prototype.$http.post(url_endpoint, ingredient)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          return campsError
        }
        else
          console.debug(error)
      })
  },
  editIngredient(ingredient) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ingredients/${ingredient.id}/`

    return Vue.prototype.$http.put(url_endpoint, ingredient)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarIngredient(ingredientID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ingredients/${ingredientID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getIngredient(ingredientID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ingredients/${ingredientID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getIngredients() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'ingredients/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Línia Ordre Eixida
  getLiniaOrdreEixida(liniaOrdreEixidaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `lineas_ordres_eixida/${liniaOrdreEixidaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `No existeix la linia d'ordre d'eixida "${liniaOrdreEixidaID}"`,
            color: "warning"
          });
          router.push({ name: 'OrdreEixidaList' })
        }
        else
          console.debug(error)
      })
  },
  createLiniaOrdreEixida(liniaOrdreEixida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "lineas_ordres_eixida/"

    return Vue.prototype.$http.post(url_endpoint, liniaOrdreEixida)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Error creant Línea d'ordre d'eixida revisa KQ aprox. o Num bales`,
            color: "warning"
          });
      })
  },
  addLiniaOrdreEixidaMP(liniaOrdreEixida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${liniaOrdreEixida.ordre_eixida}/add_linea_materia_primera/`

    return Vue.prototype.$http.post(url_endpoint, liniaOrdreEixida)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  editLiniaOrdreEixida(liniaOrdreEixida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `lineas_ordres_eixida/${liniaOrdreEixida.id}/`
    return Vue.prototype.$http.patch(url_endpoint, liniaOrdreEixida)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarLiniaOrdreEixida(liniaOrdreEixidaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `lineas_ordres_eixida/${liniaOrdreEixidaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  asignarPFAOrdreEixida(bales){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${bales.id_oe}/asociar_bala_producte_final/`

    return Vue.prototype.$http.post(url_endpoint,bales)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  asignarMPAOrdreEixida(bales){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${bales.id_oe}/asociar_bala_materia_primera/`

    return Vue.prototype.$http.post(url_endpoint,bales)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  expedirOrdreEixida(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint=`ordres_eixida/${id}/expedir/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Error al expedir - ${error.response.data.error}`,
            color: "error"
          });
      })
  },
  desexpedirOrdreEixida(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint=`ordres_eixida/${id}/desexpedir/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Error al desexpedir - ${error.response.data.error}`,
            color: "error"
          });
      })
  },
  desCompletarOrdreEixida(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint=`ordres_eixida/${id}/cancelar_completar/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  completarOrdreEixida(id){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint=`ordres_eixida/${id}/completar/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  desasignarAOrdreEixida(bales){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${bales.id_oe}/desasociar_balas/`

    return Vue.prototype.$http.post(url_endpoint,bales)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  desasignarBalasOrdreEixida(bales){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/desasociar_balas/`

    return Vue.prototype.$http.post(url_endpoint,bales)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  generarAlbaran(ordreEixida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_albaran_eixida/${ordreEixida}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data}"`,
            color: "warning"
          });
      })
  },
  imprimirAlbaran_new(ordreEixida){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${ordreEixida.id}/imprimir_albaran/`

    return Vue.prototype.$http.post(url_endpoint,ordreEixida.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  enviarAlbaran_mail(ordreEixida){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${ordreEixida.id}/enviar_email/`

    return Vue.prototype.$http.post(url_endpoint,ordreEixida)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  generarInformePartida(partida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `imprimir_partida/${partida}/`

    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
          EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data}"`,
            color: "warning"
          });
      })
  },
  imprimirInformePartida_new(partida){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${partida.id}/imprimir_partida/`

    return Vue.prototype.$http.post(url_endpoint,partida.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Magatzem
  generarPegatinaPosicio(bala) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `posicions_magatzems/${bala.id}/imprimir_pegatina/`


    return Vue.prototype.$http.post(url_endpoint, bala.info_impresion)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  createMagatzem(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'magatzems/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },
  editMagatzem(magatzem) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/${magatzem.id}/`
    return Vue.prototype.$http.put(url_endpoint, magatzem)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  almacenarBalas(balas){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `posicions_magatzems/${balas.posicio_id}/almacenar_balas/`
    return Vue.prototype.$http.post(url_endpoint, balas)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  desalmacenarBalas(balas){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `posicions_magatzems/${balas.posicio_id}/desalmacenar_balas/`
    return Vue.prototype.$http.post(url_endpoint, balas)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarMagatzem(magatzemID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/${magatzemID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarPosicioMagatzem(posicioID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `posicions_magatzems/${posicioID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMagatzem(magatzemID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/${magatzemID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMagatzemNumero(magatzemNumero) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/?numero=${magatzemNumero}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMagatzemFiltre(filtre) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    var url_endpoint = `posicions_magatzems/?`
    if (filtre.magatzem)
      url_endpoint = url_endpoint + `magatzem=${filtre.magatzem}&`
    if (filtre.zona)
      url_endpoint = url_endpoint + `zona=${filtre.zona}&`
    if (filtre.posicio)
      url_endpoint = url_endpoint + `posicio=${filtre.posicio}&`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getInformeMP(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_primeres/${data.id}/estado_fecha/${data.fecha.replace(/-/gi,'/')}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getInformeMagatzems(fecha) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/estado_fecha/${fecha.replace(/-/gi,'/')}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  createPosicioMagatzem(datos) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'posicions_magatzems/'

    return Vue.prototype.$http.post(url_endpoint, datos)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },
  getPosicioMagatzem(posicioID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `posicions_magatzems/${posicioID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMagatzemMateriesPrimeres(magatzemID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/${magatzemID}/magatzem_materies_primeres/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMagatzemProductesFinals(magatzemID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `magatzems/${magatzemID}/magatzem_productes_finals/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMagatzems() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'magatzems/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getStockMP() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'magatzems/get_materies_primeres/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getStockMP_filtrat(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    var url= `magatzems/get_materies_primeres/?`
    if(data.proveidor){
      url= url + `proveidor_id=${data.proveidor}&`
    }
    if(data.tipo){
      url= url + `tipo_id=${data.tipo}&`
    }
    if(data.ingredient){
      url= url + `ingredient_id=${data.ingredient}&`
    }
    if(data.materia_primera){
      //url= url + `materia_primera_id=${data.materia_primera}`    NO FUNCIONA
    }
    
    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getStockPF() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'magatzems/get_productes_finals/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // MateriesPrimeres
  createMateriaPrimera(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'materies_primeres/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },
  editMateriaPrimera(materiaPrimera) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_primeres/${materiaPrimera.id}/`
    return Vue.prototype.$http.put(url_endpoint, materiaPrimera)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarMateriaPrimera(materiaPrimeraID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_primeres/${materiaPrimeraID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.data?.status === 'materia_primera_borrar_error') {
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: error?.response?.data?.message,
            color: "error"
          });
        }
        else if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMateriaPrimera(materiaPrimeraID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_primeres/${materiaPrimeraID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMateriesPrimeres() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= "materies_primeres/"

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMateriesPrimeresFiltrades(filtre) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    var url= `materies_primeres/?proveidor=${filtre.proveidor}`
    if (filtre.tipo)
      url += `&tipo=${filtre.tipo}`
    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMateriesPrimeresNomsSenseDuplicar() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= "materies_primeres/noms_materies_primeres/"

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data.noms_materies_primeres
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // MateriesProducteFinal - Tipus de borra
  createMateriesProducteFinal(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'materies_producte_final/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  editMateriaProducteFinal(materiaProducteFinal) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_producte_final/${materiaProducteFinal.id}/`

    return Vue.prototype.$http.put(url_endpoint, materiaProducteFinal)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarMateriaProducteFinal(materiaProducteFinalID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_producte_final/${materiaProducteFinalID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.data?.status === 'materia_primera_borrar_error') {
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: error?.response?.data?.message,
            color: "error"
          });
        }
        else if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMateriaProducteFinal(materiaProducteFinalID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_producte_final/${materiaProducteFinalID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `No existeix la Mesclada "${materiaProducteFinalID}"`,
            color: "warning"
          });
          router.push({ name: 'MescladaList' })
        }
        else
          console.debug(error)
      })
  },
  getMateriesProducteFinal() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `materies_producte_final/?limit=300` //Xavi 26/10/2024

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Mesclades
  createMesclada(mesclada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "mesclades/"

    return Vue.prototype.$http.post(url_endpoint, mesclada)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)
          EventBus.$emit("Mesclada.Notificacio", {
            text: `"${error.response.data}"`,
            color: "warning"
          });
          return campsError
        }
        else
          console.debug(error)
      })
  },
  encolarMesclada(mesclada_id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mesclada_id}/encolar/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  desencolarMesclada(mesclada_id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mesclada_id}/desencolar/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.data.mezclada
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  addBalaMesclada(mesclada){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mesclada.id}/add_bala/`
    return Vue.prototype.$http.post(url_endpoint, mesclada.bala)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  addBalaPartida(mesclada){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${mesclada.id}/add_bala_materia_primera/`
    return Vue.prototype.$http.post(url_endpoint, mesclada.bala)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  editMesclada(mesclada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mesclada.id}/`
    return Vue.prototype.$http.put(url_endpoint, mesclada)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  editPartida(mesclada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${mesclada.id}/`
    return Vue.prototype.$http.put(url_endpoint, mesclada)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getEstadoMesclada(mescladaID){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_fabricacio/${mescladaID}/estado_preparacion_mesclada/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `No existeix la Mesclada "${mescladaID}"`,
            color: "warning"
          });
        }
        else
          console.debug(error)
      })
  },
  editPrefix(mesclada) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mesclada.id}/cambiar_prefix/`
    return Vue.prototype.$http.post(url_endpoint, { nou_prefix: mesclada.nou_prefix })
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarMesclada(mescladaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mescladaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMesclada(mescladaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mescladaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `No existeix la Mesclada "${mescladaID}"`,
            color: "warning"
          });
          router.push({ name: 'MescladaList' })
        }
        else
          console.debug(error)
      })
  },
  getPartida_new(mescladaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${mescladaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `No existeix la Mesclada "${mescladaID}"`,
            color: "warning"
          });
          router.push({ name: 'MescladaList' })
        }
        else
          console.debug(error)
      })
  },
  getMesclada_new(mescladaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${mescladaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `No existeix la Mesclada "${mescladaID}"`,
            color: "warning"
          });
          router.push({ name: 'MescladaList' })
        }
        else
          console.debug(error)
      })
  },
  getMesclades() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMescladesFiltrades(data) {
    let url_endpoint = `mesclades/?limit20&offset=${(data.pagina - 1) * 20}`

    // Filtres que afegim a la URL
    if (data.prefix)
      url_endpoint += `&prefix=${data.prefix}`
    if (data.codi)
      url_endpoint += `&codi=${data.codi}`
    if (data.plantilla)
      url_endpoint += `&plantilla=${data.plantilla}`
    if (data.quilos_totals)
      url_endpoint += `&quilos_totals=${data.quilos_totals}`
    if (data.estat)
      url_endpoint += `&estat=${data.estat}`
    if (data.producte_final)
      url_endpoint += `&producte_final=${data.producte_final}`

    // TODO - Provar Barcode per cercar bales
    // if (data.creada_en)
    //   url_endpoint += `&data_creacio=${data.creada_en}`


    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMescladesExecusio() {
    let url_endpoint = `mesclades/?estat=e`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMescladesNoAcabades(data) {
    let url_endpoint = `mesclades/?limit20&offset=${(data.pagina - 1) * 20}&acabadas=1`
    if (data.prefix)
      url_endpoint += `&prefix=${data.prefix}`
    if (data.internas=='0')
      url_endpoint += `&internas=0`
    if (data.internas=='1')
      url_endpoint += `&internas=1`
    if (data.codi)
      url_endpoint += `&codi=${data.codi}`
    if (data.plantilla)
      url_endpoint += `&plantilla=${data.plantilla}`
    if (data.quilos_totals)
      url_endpoint += `&quilos_totals=${data.quilos_totals}`
    if (data.estat)
      url_endpoint += `&estat=${data.estat}`
    if (data.producte_final)
      url_endpoint += `&producte_final=${data.producte_final}`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMescladesInterrumpidas(data) {
    let url_endpoint = `partidas/?limit20&offset=${(data.pagina - 1) * 20}&estat=i`
    if (data.prefix)
      url_endpoint += `&prefix=${data.prefix}`
    if (data.internas=='0')
      url_endpoint += `&internas=0`
    if (data.internas=='1')
      url_endpoint += `&internas=1`
    if (data.codi)
      url_endpoint += `&codi=${data.codi}`
    if (data.plantilla)
      url_endpoint += `&plantilla=${data.plantilla}`
    if (data.quilos_totals)
      url_endpoint += `&quilos_totals=${data.quilos_totals}`
    if (data.estat)
      url_endpoint += `&estat=${data.estat}`
    if (data.producte_final)
      url_endpoint += `&producte_final=${data.producte_final}`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getMescladesNoAcabadesFiltrades(data) {
    let url_endpoint = `mesclades/?limit20&offset=${(data.pagina - 1) * 20}&acabadas=1`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  getMescladesReservades() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/?reservadas=true`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  reanudarMesclada(id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${id}/reanudar_partida/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // interrumpirPartida(id) {
  //   if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    //const url_endpoint = `ordres_fabricacio/${id}/interrumpir_partida/`
  //   return Vue.prototype.$http.post(url_endpoint)
  //     .then((response) => {
  //       return response.status
  //     })
  //     .catch(error => {
  //       if (error?.response?.status === 401)
  //         this.refreshToken(localStorage.getItem('jwt'))
  //       else
  //         console.debug(error)
  //     })
  // },
  interrumpirPartida(partida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `maquinas/${partida.num_maquina}/interrumpir_cuarto_partida/`
    return Vue.prototype.$http.post(url_endpoint, partida)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  finalizarCuarto(id) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${id}/finalizar_cuarto/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  iniciarPartidaSalida(info) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${info.id}/iniciar_partida/`

    return Vue.prototype.$http.post(url_endpoint,info)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Ordres eixida
  createOrdreEixida(ordreEixida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "ordres_eixida/"

    return Vue.prototype.$http.post(url_endpoint, ordreEixida)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },
  editOrdreEixida(ordreEixida) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${ordreEixida.id}/`
    return Vue.prototype.$http.put(url_endpoint, ordreEixida)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarOrdreEixida(ordreEixidaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${ordreEixidaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getOrdreEixida(ordreEixidaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `ordres_eixida/${ordreEixidaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `No existeix la ordre d'eixida "${ordreEixidaID}"`,
            color: "warning"
          });
          router.push({ name: 'OrdreEixidaList' })
        }
        else
          console.debug(error)
      })
  },
  getOrdresEixida() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'ordres_eixida/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data.results
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getOrdresEixidaFiltrades(data) {
    let url_endpoint = `ordres_eixida/?limit20&offset=${(data.pagina - 1) * 20}`

    // Filtres que afegim a la URL
    if (data.client)
      url_endpoint += `&client=${data.client}`
    if (data.expedida==true)
      url_endpoint += `&expedida=${data.expedida}`
    if (data.expedida==false)
      url_endpoint += `&expedida=${data.expedida}`
    if (data.completa==true)
      url_endpoint += `&completa=${data.completa}`
    if (data.completa==false)
      url_endpoint += `&completa=${data.completa}`
    if (data.facturada==true)
      url_endpoint += `&facturada=${data.facturada}`
    if (data.facturada==false)
      url_endpoint += `&facturada=${data.facturada}`
    if (data.llacer==true)
      url_endpoint += `&llacer=false`
    if (data.llacer==false)
      url_endpoint += `&llacer=true`
    if (data.partida)
      url_endpoint += `&lineas__partida=${data.partida}`
    if (data.producte_final)
      url_endpoint += `&lineas__partida__producte_final=${data.producte_final}`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { results: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // ordreEixidaAfegeixBales(payload) {
  //   const url_endpoint = `ordres_eixida/${payload.ordreEixidaID}/add_bales/`

  //   return Vue.prototype.$http.post(url_endpoint, { bales: payload.bales })
  //     .then((response) => {
  //       return response.status
  //     })
  //     .catch(error => {
  //       if (error?.response?.status === 401)
  //         this.refreshToken(localStorage.getItem('jwt'))
  //       else {
  //         return error.response.data
  //       }
  //     })
  // },
  // ordreEixidaEliminaBales(payload) {
  //   const url_endpoint = `ordres_eixida/${payload.ordreEixidaID}/remove_bales/`

  //   return Vue.prototype.$http.post(url_endpoint, { bales: payload.bales })
  //     .then((response) => {
  //       return response.status
  //     })
  //     .catch(error => {
  //       if (error?.response?.status === 401)
  //         this.refreshToken(localStorage.getItem('jwt'))
  //       else {
  //         return error.response.data
  //       }
  //     })
  // },

  // Partides
  getPartida(partidaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${partidaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Partida.Notificacio", {
            text: `No existeix la Partida "${partidaID}"`,
            color: "warning"
          });
          router.push({ name: 'PartidaList' })
        }
        else
          console.debug(error)
      })
  },
  getBalesPartida(partidaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${partidaID}/bales_borra/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Partida.Notificacio", {
            text: `No existeix la Partida "${partidaID}"`,
            color: "warning"
          });
          router.push({ name: 'PartidaList' })
        }
        else
          console.debug(error)
      })
  },
  getPartidaStocks(data){
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${data}/stocks_antes_y_despues/`
    
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPartides() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/?`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  //TO DO - CORREGIR
  getPartidesTodas() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/?limit=800&estat=a`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPartidesSimples() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas_simple/?limit=1000&estat=a`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPartidesReservades() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/?limit=400&reservadas=true`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPartidesTodasSinAcabar() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/?limit=500&`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPartidesTodasDispo(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `partidas/?limit=800&amb_quilos_disponibles=true`
    if (data.internas=='0')
      url_endpoint += `&internas=0`
    if (data.internas=='1')
      url_endpoint += `&internas=1`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPartidesFiltrades(data) {
    let url_endpoint = `partidas/?limit=20&offset=${(data.pagina - 1) * 20}&order=estat,${data.ordre}`

    // Filtres que afegim a la URL
    if (data.internas=='0')
      url_endpoint += `&internas=0`
    if (data.internas=='1')
      url_endpoint += `&internas=1`
    if (data.prefix)
      url_endpoint += `&prefix=${data.prefix}`
    if (data.codi)
      url_endpoint += `&codi=${data.codi}`
    if (data.plantilla)
      url_endpoint += `&plantilla=${data.plantilla}`
    if (data.producte_final)
      url_endpoint += `&producte_final=${data.producte_final}`
    if (data.quilos_totals)
      url_endpoint += `&quilos_totals=${data.quilos_totals}`
    if (data.data_finalitzacio)
      url_endpoint += `&data_finalizacio=${data.data_finalitzacio}`
    if (data.amb_quilos_disponibles==true)
      url_endpoint += `&amb_quilos_disponibles=${data.amb_quilos_disponibles}`
    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Plantilles
  createPlantilla(plantilla) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = "plantilles_mesclades/"

    return Vue.prototype.$http.post(url_endpoint, plantilla)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)

          campsError.forEach(el => {
            if (isNaN(parseInt(el)))
              EventBus.$emit("Plantilla.Notificacio", {
                text: `Falta el camp "${el}"`,
                color: "warning"
              });
            else {
              const campsBalaError = Object.keys(error.response.data[el])
              EventBus.$emit("Plantilla.Notificacio", {
                text: `Falta el camp "${campsBalaError[0]}" en el ingredient ${parseInt(el) + 1}`,
                color: "warning"
              });
            }
          })
        }
        else
          console.debug(error)
      })
  },
  editPlantilla(plantilla) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `plantilles_mesclades/${plantilla.id}/`

    return Vue.prototype.$http.put(url_endpoint, plantilla)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarPlantilla(plantillaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `plantilles_mesclades/${plantillaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPlantilla(plantillaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `plantilles_mesclades/${plantillaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getPlantilles() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'plantilles_mesclades/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // Percentatge Mesclat
  createPercentatgeMesclat(percentatgeMesclat) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'percentatge_mesclades/'

    return Vue.prototype.$http.post(url_endpoint, percentatgeMesclat)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  editPercentatgeMesclat(percentatgeMesclat) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `percentatge_mesclades/${percentatgeMesclat.id}/`

    return Vue.prototype.$http.put(url_endpoint, percentatgeMesclat)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 400) {
          const campsError = Object.keys(error.response.data)

          campsError.forEach(el => {
            if (isNaN(parseInt(el)))
              EventBus.$emit("PercentatgeMesclat.Notificacio", {
                text: `Falta el camp "${el}"`,
                color: "warning"
              });
            else {
              const campsBalaError = Object.keys(error.response.data[el])
              EventBus.$emit("PercentatgeMesclat.Notificacio", {
                text: `Falta el camp "${campsBalaError[0]}" en el ingredient ${parseInt(el) + 1}`,
                color: "warning"
              });
            }
          })
        }
        else
          console.debug(error)
      })
  },
  eliminarPercentatgeMesclat(percentatgeMesclatID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `percentatge_mesclades/${percentatgeMesclatID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Proveïdors
  createProveidor(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'proveidors/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },
  editProveidor(proveidor) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `proveidors/${proveidor.id}/`
    return Vue.prototype.$http.put(url_endpoint, proveidor)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarProveidor(proveidorID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `proveidors/${proveidorID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },
  getProveidor(proveidorID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `proveidors/${proveidorID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getProveidors() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'proveidors/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getProveidorsFiltrats(stock) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= `proveidors/?poblacio=&con_stock=${stock}`

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getInfoTipusMateria(tipusMP) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `tipus_materies_primeres/${tipusMP}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getTipusMateria() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'tipus_materies_primeres/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  createTipusMateria(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'tipus_materies_primeres/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  editTipusMateria(tipusMP) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `tipus_materies_primeres/${tipusMP.id}/`
    return Vue.prototype.$http.put(url_endpoint, tipusMP)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarTipusMateria(tipusMP_ID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `tipus_materies_primeres/${tipusMP_ID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
      })
  },

  // 
  // Reserva
  createReserva(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = 'reserves_producte_final/'

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          return error.response.data
          console.debug(error)
      })
  },
  editReserva(reserva) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reserva.id}/`
    return Vue.prototype.$http.put(url_endpoint, reserva)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  incrementarReserva(reserva) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reserva.id}/incrementar_quilos/`
    return Vue.prototype.$http.post(url_endpoint, reserva)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  reducirReserva(reserva) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reserva.id}/reducir_quilos/`
    return Vue.prototype.$http.post(url_endpoint, reserva)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  finalizarReserva(reserva) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reserva}/finalizar/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  anularReserva(reservaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reservaID}/anular/`
    return Vue.prototype.$http.post(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  eliminarReserva(reservaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reservaID}/`

    return Vue.prototype.$http.delete(url_endpoint)
      .then((response) => {
        return response.status
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getReserva(reservaID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/${reservaID}/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Reserva.Notificacio", {
            text: `No existeix la Reserva "${reservaID}"`,
            color: "warning"
          });
          router.push({ name: 'ReservaList' })
        }
        else
          console.debug(error)
      })
  },
  getReserves() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url= 'reserves_producte_final/'

    return Vue.prototype.$http.get(url)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  
  getReservesFiltrades(data) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    let url_endpoint = `reserves_producte_final/?limit20&offset=${(data.pagina - 1) * 20}`
    // Filtres que afegim a la URL
    if (data.client)
      url_endpoint += `&client=${data.client}`
    if (data.amb_quilos_pendents ==true)
      url_endpoint += `&amb_quilos_pendents=${data.amb_quilos_pendents}`
    if (data.amb_quilos_pendents ==false)
      url_endpoint += `&amb_quilos_pendents=${data.amb_quilos_pendents}`
    if (data.quilos)
      url_endpoint += `&quilos=${data.quilos}`
    if (data.codi_partida && data.codi_partida != 'No filtrar')
      url_endpoint += `&codi=${data.codi_partida}`
    if (data.partida)
      url_endpoint += `&partida=${data.partida}`


    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return { result: response.data.results, count: response.data.count }
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  getReservesPerClient(clientID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `reserves_producte_final/?limit=100&client=${clientID}&amb_quilos_pendents=true`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data.results
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Client.Notificacio", {
            text: `No existeix el Client "${clientID}"`,
            color: "warning"
          });
          router.push({ name: 'ReservaList' })
        }
        else
          console.debug(error)
      })
  },
  getEstadoReserva(ordreID) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `partidas/${ordreID}/estado_reservas/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else if (error?.response?.status === 404) {
          EventBus.$emit("Client.Notificacio", {
            text: `No existeix l'ordre  "${ordreID}"`,
            color: "warning"
          });
        }
        else
          console.debug(error)
      })
  },
  // TODO - Aquest mètode és provisional fins que l'usuari puga escanejar bales
  iniciarMesclada(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${payload.ordre_fabricacio}/iniciar_mesclada/`

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },
  // TODO - Aquest mètode és provisional fins que l'usuari puga escanejar bales
  iniciarPartida(payload) {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `mesclades/${payload.ordre_fabricacio}/iniciar_partida/`

    return Vue.prototype.$http.post(url_endpoint, payload)
      .then((response) => {
        return response
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

  // Auxiliar
  getPrefixos() {
    if (this.inspectToken(localStorage.getItem('jwt'))==false){return}
    const url_endpoint = `prefixos/`

    return Vue.prototype.$http.get(url_endpoint)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        if (error?.response?.status === 401)
          EventBus.$emit('dialogLogin',true);
        else
          console.debug(error)
      })
  },

}