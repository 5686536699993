<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Bala -->
      <h1>Edició de Bala</h1>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="borra.quilos" label="Quilos" outlined autofocus required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="borra.cuarto"
            :items="opcionsQuartos"
            label="Fabricat en quarto"
            item-value="id"
            item-text="quarto"
            no-data-text="No s'ha pogut carregar cap materia primera"
            @change="carregarPreu()"
            outlined
          >
          </v-autocomplete>
        </v-col>

      </v-row>
      <v-row v-if="grupo!=4">
        <v-col sm="6">
          <v-text-field v-model="borra.preu_quilo" 
          label="Preu per quilo (€/kg)" 
          :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
          required
          outlined>
          </v-text-field>
        </v-col>
        <v-col sm="6">
          <v-text-field v-model="borra.cost_quilo" label="Cost per quilo (€/kg)" disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="borra.observacions" outlined label="Observacions"></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Balas -->
      <div id="botonera">
        <v-btn color="error" @click="cancelarEdicio" tabindex="-1">Cancel·lar</v-btn>
        <v-btn color="success" @click="editarBala" >Guardar canvis</v-btn>
      </div>
    </v-form>
    <!-- ./Form -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('PartidaBalaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "bala-borra-edit",
  data: () => ({
    borra: {
      id: null,
      quilos: "",
      preu_quilo: "",
      observacions: ""
    },
    opcionsQuartos:[]
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors", 
      abriques: "getFabriques", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "editBalaProducteFinal",
      "getMagatzems",
      "getMateriesPrimeres",
      "getProveidors",
      "getFabriques",
      "getBorra",
      "getFabriques",
      "getMagatzem",
      "getMateriaProducteFinal",
      "getOrdreEixida", 
      "getPartida",
      "generarPegatinaBalaBorra",
      "getQuartoMesclat", 
      "getQuartosMesclat" 
    ]),

    editarBala() {
      this.editBalaProducteFinal(this.borra).then(response => {
        if (response === 200) {
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala editada correctament",
            color: "success"
          });
          this.$router.push({
            name: "PartidaBalaDetail",
            params: { id: this.borra.id }
          });
        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error editant la bala",
            color: "error"
          });
      });
    },

    // Auxiliar
    cancelarEdicio() {
      this.$router.push({
        name: "PartidaBalaDetail",
        params: { id: this.$route.params.id }
      });
    },
    carregarPreu() {
      const preu_quilo =
        this.materiesPrimeresOpcions.find(
          el => el.id === this.borra.materia_primera
        )?.preu_quilo || 0;

      this.borra.preu_quilo = preu_quilo;
    },
    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.borra.id } });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor.nom;
      return materiaPrimera?.nom;
    },
    
  },
  async created() {
    this.getFabriques();
    await this.getBorra(this.$route.params.id).then(
      borra => {this.borra = {
      id: borra.id,
      quilos: borra.quilos,
      preu_quilo: borra.preu_quilo,
      observacions: borra.observacions,
      cuarto: borra.cuarto, 
      partida: borra.partida
    };
    }
    );
    await this.getPartida(this.borra.partida).then(partida => {
      this.borra.cost_quilo = (partida.cost_materies_primeres/partida.quilos_borra_totales).toFixed(2)
    });
    this.getQuartosMesclat(this.borra.partida).then(
      quartos=>{
        quartos.forEach(element => {
          this.opcionsQuartos.push({id: element.id, quarto: element.quarto})
        })}
    );
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

#botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 46%;
  }
}
</style>