<template>
  <v-container>
    <!-- Llistat Stock de borra -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="12" v-if="$route.params.magatzemID!='Sense'">
            <h1>Llistat de Stock de Borra en {{magatzem.nom}} - Nº {{magatzem.zona}}</h1>
          </v-col>
          <v-col sm="12" v-else>
            <h1>Llistat de Stock de Borra SENSE MAGATZEM</h1>
          </v-col>
        </v-row>
        <v-row sm="12" v-if="$route.params.magatzemID=='Sense'" style="justify-content: space-around">
        <v-btn
        v-if="bales.length!=balas_seleccionadas.length"
        @click="seleccionarBalas('todas')"
        >Seleccionar todas</v-btn>
        <v-btn
        v-else
        @click="seleccionarBalas('ninguna')"
        >Deseleccionar todas</v-btn>
      <v-btn
        @click="AlmacenarBalas"
        >Almacenar</v-btn>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="borra in datos" :key="borra.tipo_borra_id">
          <td  v-if="borra.quilos!=0" class="text-materia-producte-final">
            <div></div>
            <div ></div>
            <v-row @click="stockBorraList(borra.tipo_borra_id)">
              <v-col sm="6"> <strong> {{borra.tipo_borra}} </strong></v-col>
              <v-col sm="6">[{{borra.quilos}} kg] - {{borra.num_bales}} bales</v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
       <tbody v-if="$route.params.magatzemID=='Sense'">
        <tr v-for="(bala, index) in bales" :key="index">
          <td  class="text-bala"> <input type="checkbox" id="checkbox" v-model="check[index]" @change="seleccionarBalas(bala)">  
          <span @click="stockBorraDetail(bala.id)"> <strong > {{textNomBala(bala)}} [{{bala.codi_partida.replaceAll('.','')}}] </strong> {{ textBala(bala) }}</span> 
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Stock Borra -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="bales.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('StockBorraEnMagatzemsList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "stock-borra-llistat",
  data: () => ({ 
    pagina:"", 
    totalPagines:"", 
    balas_seleccionadas:[],
    datos:[], 
    magatzem:[], 
    num_bales_senseMagatzem:"", 
    bales:[],
    check:[false, false,false, false,false, false,false, false,false, false,false, false,false, false,false, false,false, false,false, false]
  }),
  computed: {
    ...mapGetters({
      materiesProducteFinalOpcions: "getMateriesProducteFinal"
    })
  },
  methods: {
    ...mapActions([ "getMagatzems", "getMateriesProducteFinal",
    "getMagatzemProductesFinals","getBorresFiltrades", "getMagatzem", "getStockPF", "almacenarBalas"]),

    // Paginació
    paginar() {
      //this.filtre.pagina = this.pagina;
      this.getBorresFiltrades({pagina: this.pagina, estat: "c", magatzem: "sense"}).then(
      response=>{
          this.bales = response.result; 
          this.totalPagines = Math.ceil(response.count/20)
      })
    },
    AlmacenarBalas(){
      this.almacenarBalas({magatzem_id: 4, posicio_id: 4, balas_borra: this.balas_seleccionadas}).then(response=>
      {if(response){ this.$router.go() }})
    },
    seleccionarBalas(bala){
      if(bala=='todas'){
        this.balas_seleccionadas=[]
        this.check=[]
        this.bales.forEach(bala=> {
          this.balas_seleccionadas.push(bala.id)
          this.check.push(true)
          })
      }else if(bala=='ninguna'){
         this.balas_seleccionadas=[]
        this.check=!this.check
      }else{
        if(!this.balas_seleccionadas.includes(bala.id)) {this.balas_seleccionadas.push(bala.id)}
        else{ this.balas_seleccionadas = this.balas_seleccionadas.filter(e=> e!=bala.id)}
      }
      

    },
    // Rerouting

    stockBorraList(BorraID) {
      this.$router.push({
        name: "StockBorraList",
        params: { magatzemID: this.$route.params.magatzemID, BorraID:  BorraID}
      });
    },
    stockBorraDetail(balaID) {
      this.$router.push({ name: "StockBorraDetail", params: { id: balaID, sense: true } });
    },
    textProducteFinal(ID) {
      const producteFinal = this.materiesProducteFinalOpcions.find(
        el => el.id === ID
      );
      return producteFinal?.nom;
    },
    textNomBala(bala){
      const textPF = this.textProducteFinal(bala.tipus_borra);
      return textPF
    },
    textBala(bala) {
      // const textPF = this.textProducteFinal(bala.tipus_borra);

      return `- [${
        bala.quilos
      } Kg] - ${bala.barcode} - ${bala.creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}`;
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
  },
  created() {
    if(this.$route.params.magatzemID=='Sense'){
      //this.getBorres
      this.getMateriesProducteFinal()
      this.getBorresFiltrades({pagina:1, estat: "c", magatzem: "sense"}).then(
      response=>{
          this.bales = response.result; 
          this.totalPagines = Math.ceil(response.count/20)
      }
    )
    }else
    {this.getStockPF().then(
      data=> {
        for (const prop in data) {
        if(parseInt(prop.charAt(2) + prop.charAt(3))==parseInt(this.$route.params.magatzemID.charAt(1)+ this.$route.params.magatzemID.charAt(2) + this.$route.params.magatzemID.charAt(3) ))
         {this.magatzem = data[prop]
         var borres=[]
         for (const prop2 in data[prop].posicions){ 
         if(prop2 == this.$route.params.magatzemID)
         {this.magatzem.zona= data[prop].posicions[prop2].zona
           data[prop].posicions[prop2].tipus_borra.forEach(borra=>
         {if(borra.tipo_borra_id in borres)
           {
             this.datos.find(element => element.tipo_borra_id == borra.tipo_borra_id).num_bales+= borra.num_bales
           }
            else{
              borres.push(borra.tipo_borra_id)
             this.datos.push(borra)
            }})
         }}
        }
        this.borres= borres
    }})}
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.text-materia-producte-final {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
</style>
