<template>
  <v-container>
    <!-- ./Form -->
    <v-form ref="form" class="mt-12">
      <!-- Entrada -->
      <h1>Edició d'Entrada</h1>
      <v-row>
        <v-col sm="12">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="entrada.data_albara"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="entrada.data_albara"
                label="Data albarà"
                outlined
                readonly
                v-on="on"
                autofocus
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="entrada.data_albara"
              locale="ca"
              :first-day-of-week="1"
              scrollable
              @click:date="datepicker()"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()" tabindex="-1">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()" tabindex="-1">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="entrada.proveidor"
            :items="proveidorOpcions"
            outlined
            item-text="nom"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap proveïdor"
            label="Proveïdor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear proveïdor -->
          <v-dialog v-model="dialogProveidor" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn block outlined color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear proveïdor</v-card-title>
              <v-container>
                <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="proveidor_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="proveidor_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogProveidor = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="btnCrearProveidor" tabindex="-1">Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per eliminar bala -->
        </v-col>
      </v-row>
      <v-text-field v-model="entrada.cost_total" 
      label="Cost total" 
      outlined
      readonly
      v-if="grupo!=4"
      required>
      </v-text-field>
      <v-text-field v-model="entrada.quilos_total" label="Quilos total" 
      outlined
      required></v-text-field>
      <v-text-field v-model="entrada.observacions" 
      outlined
      label="Observacions"></v-text-field>
      <!-- ./Entrada -->
      <div id="botonera">
        <v-btn color="info" @click="crearGrupMaterial" tabindex="-1">Afegir Grup</v-btn>
        <v-btn color="success" @click="editarEntrada">Guardar canvis</v-btn>
        <v-btn color="error" @click="cancelarEdicio" tabindex="-1">Cancel·lar</v-btn>
      </div>
      <!-- Balas -->
      <div v-if="grups.grup_bales.length>=1">
      <div v-for="(grup_bales, index_grup) in grups.grup_bales" :key="index_grup" class="pb-2 pt-2">
        <!-- Creació de bales -->
        <h3>Grup de bales {{grups.grup_bales.length-index_grup}} - <strong :class="{'quilos-not-ok': !quilosGrupOk(index_grup)}">
          {{ calcular_kg_grup(grup_bales.bales) }} kg </strong>
          <img v-if="quilosGrupOk(index_grup)"
            src="https://cdn.pixabay.com/photo/2017/01/13/01/22/ok-1976099_960_720.png"
            alt
          />
          <strong v-if="grupo!=4"> - {{ calcular_preu_grup(grup_bales.bales) }} € </strong></h3>
        <v-row>
          <v-col md="3">
            <v-autocomplete
              v-model="grups.grup_bales[index_grup].materia_primera"
              :items="materiesPrimeresOpcionsFiltrades"
              label="Materia primera"
              item-value="id"
              outlined
              :filter="customFilter"
              no-data-text="No s'ha pogut carregar cap materia primera"
              @change="carregarPreuGrup(index_grup)"
            >
              <template slot="selection" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
              <template slot="item" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            </v-autocomplete>
          </v-col>
          <v-col md="1">
            <v-btn
              outlined
              block
              color="success"
              dark
              v-if="grupo!=4"
              tabindex="-1"
              @click="btnCrearMateriaPrimeraModal(index_grup,true)"
            >+</v-btn>
          </v-col>
          <v-col md="1">
            <v-text-field
              style="width: 90px"
              v-model="grups.grup_bales[index_grup].preu_quilo"
              :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
              label="Preu quilo"
              @change="calcular_preu_grup(grup_bales.bales)"
              v-if="grupo!=4"
              outlined
              required
            ></v-text-field>
            </v-col>
          <v-col md="1.5">
            <v-autocomplete
              v-model="grups.grup_bales[index_grup].posicion_almacenamiento"
              :items="OpcionesSubalmacenes"
              label="Magatzem"
              item-text="nom"
              item-value="barcode"
              outlined
              no-data-text="No s'ha pogut carregar cap materia primera"
            >
            </v-autocomplete>
          </v-col>
            <v-col md="1">
            <v-text-field
              v-model="grups.grup_bales[index_grup].bales_a_crear"
              label="Bales a crear"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col md="1">
            <v-text-field
              v-model="grups.grup_bales[index_grup].quilos_grup"
              label="kg grup"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-btn
              color="success"
              @click="crearBales(index_grup)"
              class="crear-bales"
              tabindex="-1"
            >Crear bales</v-btn>
            <v-btn
              color="warning"
              @click="eliminarGrup(index_grup)"
              class="crear-bales"
              tabindex="-1"
            >Eliminar grup</v-btn>
          </v-col>
        </v-row>
        <!-- ./Creació de bales -->
        <!-- Bales -->
        <div
          v-for="(bala, index_bala) in grups.grup_bales[index_grup].bales"
          :key="index_bala"
          class="pb-2 pt-2"
        >
          <v-row>
            <v-col md="3">
              <h4>Bala #{{ index_grup +1 }}.{{ index_bala +1 }}</h4>
            </v-col>
            <v-col md="3">
              <v-text-field
                v-model="bala.quilos"
                label="Quilos"
                @input="calcular_kg_preu()"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="3"></v-col>
            <v-col md="3">
              <v-btn
                block
                color="error"
                @click="eliminarBalaGrup(index_grup, index_bala)"
                tabindex="-1"
              >Eliminar bala</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- ./Bales -->
      </div>
      </div>
      <div v-for="(bala, index) in entrada.bales" :key="index" class="pb-2 pt-2">
        <h2>Bala #{{ bala.id }} 
              <v-btn

                v-if="bala.estat==1"
                color="error"
                @click="sendEliminarBala(index, bala.id)"
                tabindex="-1"
              >Eliminar bala</v-btn>
              </h2>  
        
        <v-row>
          <v-col md="12">
            <v-text-field v-model="bala.quilos" 
            outlined
            label="Quilos" 
            required @input="calcular_kg_preu()" 
            :disabled="bala.estat!=1"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="11">
            <v-autocomplete
              v-model="bala.materia_primera"
              :items="materiesPrimeresOpcions"
              label="Materia primera"
              item-value="id"
              outlined
              :filter="customFilter"
              no-data-text="No s'ha pogut carregar cap materia primera"
              @change="carregarPreu(index)"
              :disabled="bala.estat!=1"
            >
              <template slot="selection" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
              <template slot="item" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            </v-autocomplete>
          </v-col>
          <v-col md="1">
            <v-btn
              outlined
              block
              color="success"
              dark
              @click="btnCrearMateriaPrimeraModal(index,false)"
              :disabled="bala.estat!=1"
              tabindex="-1"
            >+</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-text-field
              v-model="bala.preu_quilo"
              label="Preu per quilo"
              outlined
              v-if="grupo!=4"
              @input="calcular_kg_preu()"
              :disabled="bala.estat!=1"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-text-field v-model="bala.observacions" 
            label="Observacions" 
            :disabled="bala.estat!=1"
            outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="11">
            <v-autocomplete
              v-model="bala.posicion_almacenamiento"
              :items="OpcionesSubalmacenes"
              label="Magatzem"
              item-text="nom"
              item-value="barcode"
              outlined
              @change="marcarCambioAlmacen(bala)"
              no-data-text="No s'ha pogut carregar cap magatzem"
              :disabled="bala.estat!=1"
            ></v-autocomplete>
          </v-col>
          <v-col sm="1">
            <v-btn
              outlined
              block
              color="success"
              dark
              @click="btnCrearMagatzemModal(index)"
              tabindex="-1"
              :disabled="bala.estat!=1"
            >+</v-btn>
          </v-col>
        </v-row>
        <div id="botonera-bala">
          <v-btn
            v-if="!bala.entrada"
            color="info"
            @click="btnCrearBala(bala)"
            tabindex="-1"
          >Guardar bala</v-btn>
          <v-btn
            v-if="!bala.entrada"
            color="error"
            @click="sendEliminarBala(index, bala.id)"
            tabindex="-1"
          >Eliminar bala</v-btn>
        </div>
      </div>
      <!-- ./Balas -->
    </v-form>
    <!-- ./Form -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('EntradaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
    <!-- Dialog/Modal per crear materia primera -->
    <v-dialog v-model="dialogMateriaPrimera" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Crear materia primera</v-card-title>
        <v-container>
          <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
            <v-row>
              <v-col md="12">
                <v-text-field v-model="material_nou.nom" label="Nom" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field v-model="material_nou.preu_quilo" label="Preu quilo" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="10">
                <v-autocomplete
                  v-model="material_nou.proveidor"
                  :items="proveidorOpcions"
                  no-data-text="No s'ha pogut carregar cap proveïdor"
                  item-text="nom"
                  item-value="id"
                  label="Proveïdor"
                ></v-autocomplete>
              </v-col>
              <v-col sm="2">
                <!-- Dialog/Modal per crear proveïdors -->
                <v-dialog v-model="dialogProveidor" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear proveïdor</v-card-title>
                    <v-container>
                      <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="proveidor_nou.poblacio"
                              label="Població"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="proveidor_nou.id_facturaplus"
                              label="ID Facturaplus"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialogProveidor = false"
                        tabindex="-1"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearProveidorMP"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear ingredients -->
              </v-col>
            </v-row>
            <v-row>
              <v-col md="10">
                <v-autocomplete
                  v-model="material_nou.ingredient"
                  :items="ingredientsOpcions"
                  no-data-text="No s'ha pogut carregar cap tipus de materia"
                  item-text="nom"
                  item-value="id"
                  label="Tipo de materia"
                ></v-autocomplete>
              </v-col>
              <v-col sm="2">
                <!-- Dialog/Modal per crear ingredients -->
                <v-dialog v-model="dialogIngredient" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear tipus de materia</v-card-title>
                    <v-container>
                      <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="ingredient_nou.nom" label="Nom" required autofocus @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        tabindex="-1"
                        @click="dialogIngredient = false"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearIngredient"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear ingredients -->
              </v-col>
            </v-row>
            <v-row>
                      <v-col md="10">
                        <v-autocomplete
                          v-model="material_nou.tipo"
                          :items="tiposOpcions"
                          no-data-text="No s'ha pogut carregar cap tipo de materia"
                          clearable
                          item-text="nom"
                          item-value="id"
                          label="Composició"
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="2">
                  <!-- Dialog/Modal per crear ingredients -->
                  <v-dialog v-model="dialogTipoMateria" persistent max-width="600">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Crear Composició</v-card-title>
                      <v-container>
                        <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="tipusMP_nou.nom" label="Composició" required autofocus @keydown.enter.prevent="btnCrearTipusMP"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialogTipoMateria = false"
                        >Cancel·lar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="btnCrearTipusMP">Crear</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- ./Dialog/Modal per crear ingredients -->
                </v-col>
                    </v-row>
            <v-row>
              <v-col md="12">
                <v-checkbox v-model="material_nou.ignifug" label="Ignífug"></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMateriaPrimera = false"
            tabindex="-1"
          >Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnCrearMateriaPrimera" tabindex="-1">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per crear materia primera -->
    <!-- Dialog/Modal per crear magatzem -->
    <v-dialog v-model="dialogMagatzem" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Crear magatzem</v-card-title>
        <v-container>
          <v-form id="formMagatzem" ref="formMagatzem" class="mt-12">
            <v-row>
              <v-col md="12">
                <v-text-field v-model="magatzem_nou.nom" label="Nom" required></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMagatzem = false"
            tabindex="-1"
          >Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnCrearMagatzem" tabindex="-1">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per crear magatzem -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "entrada-edit",
  data: () => ({
    entrada: {
      data_albara: null,
      cost_total: "",
      quilos_total: "",
      observacions: "",
      proveidor: ""
    },
    grups: {
      grup_bales: [
      ]
    },
    ingredient_nou: {
      nom: ""
    },
    tipusMP_nou: {
      nom: ""
    },
    magatzem_nou: {
      nom: ""
    },
    material_nou: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    proveidor_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    bales_a_eliminar_ID: [],
    dialogIngredient: false,
    dialogMagatzem: false,
    dialogMateriaPrimera: false,
    dialogTipoMateria:false,
    dialogProveidor: false,
    nouMagatzemIndex: null,
    OpcionesSubalmacenes:[],
    novaMateriaPrimeraIndex: null,
    MPgrup: false,
    materiesPrimeresOpcionsFiltrades:[],
    modalData: false
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors",
      tiposOpcions: "getTipusMateria",
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "editBala",
      "editEntrada",
      "eliminarBala",
      "getEntrada",
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getProveidors",
      "sendBalaAEntrada",
      "sendIngredient",
      "sendMagatzem",
      "sendTipusMateria", 
      "sendMateriaPrimera",
      "sendProveidor", 
      "almacenarBalas",
      "desalmacenarBalas",
      "getTipusMateria"
    ]),
    crearGrupMaterial() {
      this.filtrarMateriesPrimeres();
      this.grups.grup_bales.unshift({
        materia_primera: "",
        preu_quilo: "",
        bales_a_crear: "",
        bales: []
      });
    },
    eliminarBalaGrup(index_grup, index_bala) {
      this.grups.grup_bales[index_grup].bales.splice(index_bala, 1);
      this.calcular_kg_preu();
    },
    eliminarGrup(index_grup) {
      this.grups.grup_bales.splice(index_grup, 1);
      this.calcular_kg_preu();
    },
    sendEliminarBala(index, balaID) {
      this.entrada.bales.splice(index, 1);
      this.bales_a_eliminar_ID.push(balaID);
      this.calcular_kg_preu();
    },
    btnCrearTipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.tipo = data.data.id;
          this.tipusMP_nou = {
            nom: ""
          };
          this.dialogTipoMateria = false;
        }
      });
    },
    editarEntrada() {
      this.editEntrada(this.entrada).then(response => {
        if (response === 200) {
          this.grups.grup_bales.forEach(el => this.entrada.bales.push(el.bales));
          this.grups.grup_bales.forEach(el => el.bales.forEach(el => el.entrada = this.$route.params.id));
          this.grups.grup_bales.forEach(el => el.bales.forEach(el_bala => {this.sendBalaAEntrada(el_bala).then(
            response=> {
              if(response.id){
                this.almacenarBalas({balas_materia_prima: [response.id], posicio_id: this.OpcionesSubalmacenes.find(elem=> 
                elem.barcode == el.posicion_almacenamiento).id})
              }
            }
          );
          }));
          
          // Si editem una Entrada satisfactoriament, fem el mateix amb bales
          this.entrada.bales.forEach(bala => {
            // Si tenen ID, es a dir, ja existien, edita bala
            if (bala.id) 
             {if (bala.cambiar_alamcen){
               this.editBala(bala);
               if(bala.posicion_actual!="")
               {this.desalmacenarBalas({posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == bala.posicion_actual).id, balas_materia_prima: [bala.id]}).then(
                 response => {
                if(response){
                   this.almacenarBalas({balas_materia_prima: [bala.id], posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == bala.posicion_almacenamiento).id})
                 }}
               )}else{
                 this.almacenarBalas({balas_materia_prima: [bala.id], posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == bala.posicion_almacenamiento).id})
               }
               }
             else{
               this.editBala(bala)
             }}
          });

          this.bales_a_eliminar_ID.forEach(balaID => {
            this.eliminarBala(balaID);
          });

          EventBus.$emit("Entrada.Notificacio", {
            text: "Entrada editada correctament",
            color: "success"
          });

          this.redireccio('EntradaDetail');
        } else
          EventBus.$emit("Entrada.Notificacio", {
            text: "Error editant l'entrada",
            color: "error"
          });
      });
    },
    // Auxiliar
    calcular_kg_grup(grup_bales) {
      if (grup_bales.length === 0) return 0;
      let total = 0;
      grup_bales.forEach(bala => {
        if (bala.quilos) total += parseFloat(bala.quilos);
      });
      return total;
    },
    calcular_preu_grup(grup_bales) {
      if (grup_bales.length === 0) return 0;
      let total = 0;
      grup_bales.forEach(bala => {
        if (bala.quilos){
           total += parseFloat(bala.quilos*parseFloat(bala.preu_quilo).toFixed(3))
           }
      });
      return total.toFixed(2);
    },
    crearBales(index_grup) {
      const grup = this.grups.grup_bales[index_grup];
      const materia_primera = grup.materia_primera;
      const preu_quilo = grup.preu_quilo;

      const bales_a_crear = grup.bales_a_crear;
      const bales_existents = grup.bales.length;

      if (!materia_primera || !preu_quilo || !bales_a_crear) {
        EventBus.$emit("Entrada.Notificacio", {
          text: "No et deixes cap camp buid per a crear bales",
          color: "warning"
        });
        return;
      }

      if (bales_existents > bales_a_crear) {
        // Eliminar bales
        this.grups.grup_bales[index_grup].bales.splice(
          bales_a_crear - bales_existents,
          bales_existents
        );
      } else if (bales_existents < bales_a_crear) {
        // Afegir bales
        const bales_totals_a_crear = bales_a_crear - bales_existents;
        for (let index = 0; index < bales_totals_a_crear; index++) {
          this.grups.grup_bales[index_grup].bales.push({
            quilos: "",
            preu_quilo: preu_quilo,
            materia_primera: materia_primera,
            magatzem: this.grups.grup_bales[index_grup].magatzem
          });
        }
      } else {
        // No fer res
      }
    },
    // Crear nous models
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.ingredient = data.data.id;
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearProveidor() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data.status === 201) {
          this.proveidor_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogProveidor = false;
          this.entrada.proveidor = data.data.id;
        }
      });
    },
    btnCrearProveidorMP() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data.status === 201) {
          this.proveidor_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogProveidor = false;
          this.material_nou.proveidor = data.data.id;
        }
      });
    },
    btnCrearMagatzem() {
      this.sendMagatzem(this.magatzem_nou).then(data => {
        if (data.status === 201) {
          this.magatzem_nou = {
            nom: ""
          };
          this.dialogMagatzem = false;
          this.entrada.bales[this.nouMagatzemIndex].magatzem = data.data.id;
        }
      });
    },
    btnCrearMateriaPrimera() {
      //this.material_nou.ingredients=[this.material_nou.ingredients];
      this.sendMateriaPrimera(this.material_nou).then(data => {
        if (data.status === 201) {
          this.material_nou = {
            nom: "",
            preu_quilo: null,
            proveidor: "",
            ignifug: false
          };
          this.dialogMateriaPrimera = false;
          if(this.MPgrup==false){this.entrada.bales[this.novaMateriaPrimeraIndex].materia_primera =
            data.data.id;
          this.entrada.bales[this.novaMateriaPrimeraIndex].preu_quilo =
            data.data.preu_quilo;}
            else{
              this.grups.grup_bales[this.novaMateriaPrimeraIndex].materia_primera =
            data.data.id;
          this.grups.grup_bales[this.novaMateriaPrimeraIndex].preu_quilo =
            data.data.preu_quilo;
            }
          this.materiesPrimeresOpcionsFiltrades.push(data.data)
        }
      });
    },
    btnCrearMateriaPrimeraModal(index, group) {
      this.novaMateriaPrimeraIndex = index;
      this.MPgrup= group;
      this.dialogMateriaPrimera = true;
    },

    btnCrearMagatzemModal(index) {
      this.nouMagatzemIndex = index;
      this.dialogMagatzem = true;
    },
    marcarCambioAlmacen(bala){
      bala.cambiar_alamcen = true;
    },
    // Datepicker
    datepicker() {
      this.$refs.dialog.save(this.entrada.data_albara);
      this.modalData = false;
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.entrada.data_albara = null));
      this.modalData = false;
    },
    // Auxiliar
    btnCrearBala(bala) {
      bala.entrada = this.$route.params.id;
      this.sendBalaAEntrada(bala);
    },
    calcular_kg_preu() {
      let finalKG = 0;
      let finalPreu = 0;

      // Agrupem totes les bales en un únic array
      this.entrada.bales.forEach(el => {
        finalKG += parseFloat(el.quilos);
        finalPreu += parseFloat(el.quilos * parseFloat(el.preu_quilo).toFixed(3));
      });
      if(this.grups.grup_bales.length >= 1) this.grups.grup_bales.forEach(el=>el.bales.forEach(bala => finalKG += parseFloat(bala.quilos)));
      if(this.grups.grup_bales.length >= 1) this.grups.grup_bales.forEach(el=>el.bales.forEach(bala => finalPreu += parseFloat(bala.quilos * bala.preu_quilo)));
      this.entrada.quilos_total = finalKG;
      this.entrada.cost_total = (finalPreu).toFixed(2);
    },
    filtrarMateriesPrimeres(){
      this.materiesPrimeresOpcionsFiltrades = this.materiesPrimeresOpcions.filter(el=> el.proveidor == this.entrada.proveidor);
    },
    quilosGrupOk(index_grup){
      if(this.grups.grup_bales[index_grup].quilos_grup!=0 && 
      this.grups.grup_bales[index_grup].quilos_grup == this.calcular_kg_grup(this.grups.grup_bales[index_grup].bales)){
        return true
      }
    },
    cancelarEdicio() {
      this.$router.push({
        name: "EntradaDetail",
        params: { id: this.$route.params.id }
      });
    },
    carregarPreuGrup(index_grup) {
      const materia_primera_id = this.grups.grup_bales[index_grup]
        .materia_primera;
      const preu_quilo =
        this.materiesPrimeresOpcions.find(el => el.id === materia_primera_id)
          ?.preu_quilo || 0;
      this.grups.grup_bales[index_grup].preu_quilo = preu_quilo;
    },
    carregarPreu(index) {
      const materia_primera_id = this.entrada.bales[index].materia_primera;
      const preu_quilo =
        this.materiesPrimeresOpcions.find(el => el.id === materia_primera_id)
          ?.preu_quilo || 0;
      this.entrada.bales[index].preu_quilo = preu_quilo;
    },
    crearFormBala() {
      this.entrada.bales.push({
        quilos: "",
        preu_quilo: "",
        observacions: "",
        materia_primera: "",
        magatzem: ""
      });
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());
    },
    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.entrada.id } });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera.proveidor
      );

      if (mpProveidor) return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''} - ${mpProveidor.nom}`;
      return materiaPrimera?.nom;
    }
  },
  created() {
    this.getEntrada(this.$route.params.id).then(
      entrada => 
        {this.entrada = entrada;
        this.entrada.bales.forEach(el=>{
          el.posicion_actual = el.posicion_almacenamiento;
        })}
    );
    this.getTipusMateria();
    this.getIngredients();
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getProveidors();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
h2 {
  text-align: center;
  padding-bottom: 0.5em;
}
#botonera-bala {
  padding-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 30%;
  }
}
img {
  width: 25px;
}
.quilos-not-ok{
  color: red;
}
</style>