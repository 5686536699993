<template>
  <v-container>
    <!-- Bales -->
    <v-simple-table class="mt-12">
      <thead>
        <td style="height: auto">
        <h1>Llistat de Stock de {{ materiaPrimera.nom}} {{ materiaPrimera.codigo_grs }} {{ materiaPrimera.nom_ingredient }} en {{ magatzem.nom }}</h1>
        </td>
        <td>
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <tbody>
        <tr v-for="(bala, index) in bales" :key="index" :class="{'bala-seleccionat': bala.estat==2, 'bala-pendent': bala.impresa==false && bala.estat!=2 } ">
          <td @click="balaDetail(bala.id)" class="text-bala">{{ textBala(bala) }}
            <img
              v-if="bala.estat==2"
              src="https://cdn.pixabay.com/photo/2014/04/02/10/44/warning-sign-304370_960_720.png"
              alt
              />
              <img
              v-if="bala.impresa==false && bala.estat!=2"
              src="https://cdn.pixabay.com/photo/2017/06/26/00/46/flat-2442462_960_720.png"
              alt
              />
            
          </td>
          <v-btn
              
              @click="quitarAlmacenBala(bala)"
            >Traure magatzem</v-btn>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Bales -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('StockMateriaPrimeraEnMagatzemList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";

export default {
  name: "stock-materia-primera-final-llistat",
  data: () => ({
    materiaPrimera: {},
    magatzem: {},
    pagina: 1,
    paginasAñadidas:[],
    json_fields: {
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        QUILOS: "quilos", 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        ESTAT: {
          field: "estat",
          callback: value => (value==4 ? "Sí" : value==3 ? "En procés" : "No")
        },
        MATERIA_PRIMREA: "materia_primera_nom", 
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        PROVEIDOR: "proveidor_nom", 
        DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales"
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      bales: "getBales",
      totalPagines: "getBalesPaginacio",
      ingredientsOpcions: "getIngredients",
      materiesPrimeres: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors"
    })
  },
  methods: {
    ...mapActions([
      "getBalesMagatzemMateriaPrimera",
      "getIngredients",
      "getMagatzem",
      "getMateriaPrimera",
      "getMateriesPrimeres",
      "getProveidors", 
      "desalmacenarBalas", 
      "getMagatzemNumero"
    ]),

    // Paginació
    balaDetail(balaID) {
      this.$router.push({ name: "StockMateriaPrimerBalaDetail", params: { id: balaID} });
    },
    paginar() {
      this.getBalesMagatzemMateriaPrimera({
        pagina: this.pagina,
        magatzemID: this.magatzem.id,
        posicion_almacenamiento: this.$route.params.magatzemID,
        materiaPrimeraID: this.$route.params.materiaPrimeraID
      });
    },
  quitarAlmacenBala(bala){
      if(bala.barcode.charAt(0) == "0") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_materia_prima: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
           this.$router.go()
          }else{
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      if(bala.barcode.charAt(0) == "1") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_borra: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){

            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
            
          }else{

            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      
    },
  //Excel
    getExcelData(){
      
      this.excelArray.forEach(elem=> 
      {elem.materia_primera_nom = this.textMateriaPrimera(elem.materia_primera);
      elem.proveidor_nom = this.textProveidor(elem.materia_primera)}
      )

      
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      this.nombreExcel = 'Llistat_Stock_'+this.materiaPrimera.nom+'_'+this.magatzem.nom;
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.bales); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Text
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeres.find(
        el => el.id === mpID
      );
      return materiaPrimera?.nom;
    },
    textProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeres.find(
        el => el.id === mpID
      );
      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }
      return mpProveidor?.nom;
    },
    textBala(bala) {
      const materiaPrimera = this.materiesPrimeres.find(
        el => el.id === bala.materia_primera
      );

      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }

      return `${bala.barcode} - ${mpProveidor?.nom} [${bala.quilos} Kg] - ${
        bala.creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
      } - ${bala.observacions}`;
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({
        name: component,
        params: { id: this.$route.params.magatzemID }
      });
    }
  },
  created() {
    this.getIngredients();
    this.getMagatzemNumero(parseInt(this.$route.params.magatzemID.charAt(1)+ this.$route.params.magatzemID.charAt(2) + this.$route.params.magatzemID.charAt(3) ))
    .then(
      magatzem => (
      this.getBalesMagatzemMateriaPrimera({
      pagina: this.pagina,
      magatzemID: magatzem[0].id,
      posicion_almacenamiento: this.$route.params.magatzemID,
      materiaPrimeraID: this.$route.params.materiaPrimeraID
      }),
      this.magatzem = magatzem[0]
      )
    );
    this.getMateriaPrimera(this.$route.params.materiaPrimeraID).then(
      materiaPrimera => (this.materiaPrimera = materiaPrimera)
    );
    this.getMateriesPrimeres();
    this.getProveidors();
    
  },
  // watch: {
  //   bales: function() {
  //     this.totalPagines = this.getBalesPaginacio;
  //   }
  // }
};
</script>

<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
img {
  width: 15px;
}
.bala-seleccionat {
      background: rgb(241, 241, 153) ;
}
.bala-pendent {
      background: rgb(153, 188, 241) ;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 100%;
      }
    }
  }
}
#botonera {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    width: 90%;
  }
}
#downloadexcel {
  display: none;
}
</style>