<template>
  <v-container>
    <!-- TipusMP -->
    <v-simple-table v-if="tipusMP" class="mt-12">
      <thead>
        <h1>Vista detallada de tipus de Materia primera</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ tipusMP.nom }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per editar tipusMP -->
      <v-dialog v-model="dialogeditTipusMP" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark @click="btnDialogTipusMP" v-on="on">Editar</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar</v-card-title>
          <v-container>
            <v-form id="formTipusMP" ref="formTipusMP" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="tipusMP_edit.nom" label="Nom" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogeditTipusMP = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btneditTipusMP"
              :disabled="!tipusMP_edit.nom"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar tipusMP -->
      <!-- Dialog/Modal per eliminar tipusMP -->
      <v-dialog v-model="dialogEliminarTipusMP" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar Tipus de Materia Primera</v-card-title>
          <v-card-text>Està segur que vol eliminar este Tipus de Materia Primera?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEliminarTipusMP = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarTipusMP">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar tipusMP -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./TipusMP -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('TipusMateriaPrimeraList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "tipusMP-detail",
  data: () => ({
    tipusMP: {
      nom: "",
    },
    tipusMP_edit: {
      nom: "",
    },
    dialogeditTipusMP: false,
    dialogEliminarTipusMP: false
  }),
  methods: {
    ...mapActions(["editTipusMateria", "eliminarTipusMateria", "getInfoTipusMateria"]),

    // Botonera
    btnDialogTipusMP() {
      this.tipusMP_edit = JSON.parse(JSON.stringify(this.tipusMP));
    },
    btneditTipusMP() {
      this.editTipusMateria(this.tipusMP_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("Ingredient.Notificacio", {
            text: "TipusMP editat correctament",
            color: "success"
          });
          this.dialogeditTipusMP = false;
          this.getInfoTipusMateria(this.tipusMP.id).then(
            tipusMP => (this.tipusMP = tipusMP)
          );
        } else
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Error editant el Tipus de Materia Primera",
            color: "error"
          });
      });
    },
    btnEliminarTipusMP() {
      this.eliminarTipusMateria(this.tipusMP.id).then(response => {
        if (response === 204) {
          EventBus.$emit("TipusMP.Notificacio", {
            text: "Tipus de Materia primera eliminat correctament",
            color: "success"
          });
          this.$router.push({ name: "TipusMateriaPrimeraList" });
        } else
          EventBus.$emit("TipusMP.Notificacio", {
            text: "Error eliminant el tipus de materia primera",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getInfoTipusMateria(this.$route.params.id).then(
      tipusMP => (this.tipusMP = tipusMP)
    );
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>