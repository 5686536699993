<template>
  <v-container>
    <!-- Ordre Eixida -->
    <v-simple-table class="mt-12">
      <thead>
        <h1>Vista detallada d'albarà</h1>
      </thead>
      <tbody>
        <tr v-if="ordreEixida.numero_albaran">
          <td>
            <b>Num. Albarà</b>
          </td>
          <td>{{ ordreEixida.numero_albaran }}</td>
        </tr>
        <tr>
          <td></td>
          <td style="display: flex">
            <v-checkbox
            v-model="ordreEixida.facturada"
            no-data-text
            clearable
            @change="facturar()"
            label="FACTURAT"
            style="margin-top:0px; margin-left:5px"
          ></v-checkbox>
          <img v-if="ordreEixida.correo_enviado"
            src="https://cdn.pixabay.com/photo/2014/07/10/14/58/letters-389108_960_720.png"
            alt
          />
          </td>
        </tr>
        <tr v-if="ordreEixida.imprimir_logo">
          <td></td>
          <td>
            <b>Amb logo</b>
          </td>
        </tr>
        <tr v-else>
          <td></td>
          <td>
            <b>Sense logo</b>
          </td>
        </tr>
        <tr v-if="ordreEixida.hoja_extra">
          <td></td>
          <td>
            <b>Imprimir 3 alabarans</b>
          </td>
        </tr>

        <tr v-if="ordreEixida.num_pedido">
          <td>
            <b>Num. Comanda</b>
          </td>
          <td>{{ ordreEixida.num_pedido }}</td>
        </tr>
          <tr v-if="ordreEixida.comentari_general">
            <td>
            <b>Comentaris generals Albarà</b>
          </td>
            <td>{{ ordreEixida.comentari_general }}</td>
          </tr>
        <tr>
          <td>
            <b>Data Expedició</b>
          </td>
          <td>{{ textData(ordreEixida.data_hora_expedicio) }}</td>
        </tr>
        <tr>
          <td>
            <b>Client</b>
          </td>
          <td
          @click="dialogEditClient=true"
          >{{ client_nom }}
            <img 
            style="width: 15px;
              height: auto;
              margin-left: 10px;"
            src="https://cdn.pixabay.com/photo/2013/07/12/12/30/cogwheel-145804_960_720.png"
            alt
          />

          </td>
        </tr>
        <!-- Dialog/Modal per editar client -->
      <v-dialog v-model="dialogEditClient" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Editar client</v-card-title>
          <v-container>
            <v-form id="formClient" ref="formClient" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="client_edit.emails" label="Correu"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditClient = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btnEditClient"
              
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar client -->
      <!-- Dialog/Modal per desexpedir -->
      <v-dialog v-model="dialogDesexpedir" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Desxpedir albarà</v-card-title>
          <v-container>
            <v-form id="formClient" ref="formClient" class="mt-12">
              <v-row>
                <v-col md="12">
                  <b> Estàs desexpedint un albarà, aixó vol dir que tornaràs a crear una Ordre d'eixida modificable. Pulsa Confirmar per continuar.</b>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogDesexpedir = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btnDesexpedir"
              
            >Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per desexpedir -->
        <tr v-if="ordreEixida.expedida">
          <td>
            <b>Estat</b>
          </td>
          <td>Expedida</td>
        </tr>
        <tr v-if="ordreEixida.transportista">
          <td>
            <b>Transportista</b>
          </td>
          <td>{{ ordreEixida.transportista }}</td>
        </tr>
        <tr v-if="ordreEixida.entregado_en">
          <td>
            <b>Entregado en</b>
          </td>
          <td>{{ ordreEixida.entregado_en }}</td>
        </tr>
        <tr v-if="ordreEixida.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ ordreEixida.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Ordre Eixida -->
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="success" dark @click="btnGenerarAlbaran">Imprimir albarà</v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="deshabilitar_envio" color="success"  @click="btnEnviarAlbaran">Enviar albarà</v-btn>
      <!-- Dialog/Modal per eliminar ordre d'eixida -->
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="btnEditarAlbaran">Editar albarà</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="dialogDesexpedir=true">Desexpedir</v-btn>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar</v-btn>
          </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar ordre d'eixida</v-card-title>
          <v-card-text>Està segur que vol eliminar esta ordre d'eixida?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarOrdreEixida">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar ordre d'eixida -->
    </div>
    <!-- Botons d'opcions -->
    <!-- Linies -->
    <v-simple-table id="info-linies" v-if="ordreEixida.lineas" class="mt-12">
      <thead>
        <h2>Linies d'Ordre d'Eixida</h2>
      </thead>
      <tbody>
        <div v-for="(linia, index) in ordreEixida.lineas" :key="linia.id">
          <tr>
            <h3>
              <b>Producte {{ index + 1}}</b>
            </h3>
          </tr>
          <tr v-if="linia.reserva">
            <td>
              <b>Reserva</b>
            </td>
            <td>{{ textReserva(linia.reserva) }}</td>
          </tr>
          <tr v-if="linia.partida">
            <td>
              <b>Partida</b>
            </td>
            <td >{{ textPartida(linia.partida) }} <v-btn v-if="(auth.nom=='raul'|| auth.nom=='ivan')" color="secondary" small style="margin-left: 10px" @click="btnVoreMesclada(linia.partida)">Vore mesclada</v-btn> 
            <v-btn small v-if="(auth.nom=='raul'|| auth.nom=='ivan')" color="secondary" style="margin-left: 10px" @click="$router.push({ name: 'PartidaDetail', params: { id: linia.partida, list: false} });">Vore partida</v-btn></td>
          </tr>
          <tr v-if="linia.imprimir_observaciones">
            <td>
              
            </td>
            <td><b>**Se imprimen las observaciones</b></td>
          </tr>
          <tr v-if="linia.codigo_grs">
            <td>
              <b>Código GRS para Albarán </b>
            </td>
            <td>{{linia.codigo_grs}}</td>
          </tr>
          <tr v-if="linia.materia_primera">
            <td>
              <b>Materia Primera</b>
            </td>
            <td>{{ textMateriaPrimera(linia.materia_primera) }}</td>
          </tr>
          <tr v-if="linia.quilos_aproximats>0">
            <td>
              <b>Quilos aproximats</b>
            </td>
            <td>{{ linia.quilos_aproximats }} kg</td>
          </tr>
          <tr v-if="linia.quilos>0">
            <td>
              <b>Quilos assignats</b>
            </td>
            <td>{{ linia.quilos }} kg</td>
          </tr>
          <tr v-if="linia.quilos>0 && linia.materia_primera && (auth.nom=='raul'|| auth.nom=='ivan')">
            <td>
              <b>Cost mig</b>
            </td>
            <td>{{ textCostMig(linia) }} €/kg</td>
          </tr>
          <tr v-if="linia.num_bales>0 && linia.materia_primera && (auth.nom=='raul'|| auth.nom=='ivan')">
            <td>
              <b>Bales</b>
            </td>
            <td>
              <tr v-for="(bala, index) in linia.bales" :key="bala.id" style="justify-content: left">
                {{index+1}} -  {{bala.barcode}} - {{parseFloat(bala.quilos).toFixed(0)}} kg - {{bala.preu_quilo}} €/kg </tr></td>
          </tr>
          <tr v-else>
            <td>
              <b>Bales</b>
            </td>
            <td>
              <tr v-for="(bala, index) in linia.bales" :key="bala.id" style="justify-content: left">
                {{index+1}} -  {{bala.barcode}} - {{parseFloat(bala.quilos).toFixed(0)}} kg </tr></td>
          </tr>
          <tr v-if="linia.observacions">
            <td>
              <b>Observacions</b>
            </td>
            <td>{{ linia.observacions }}</td>
          </tr>
          <tr v-if="linia.ref_ari">
            <td>
              <b>Ref. Ari</b>
            </td>
            <td>{{ linia.ref_ari }}</td>
          </tr>
          <tr v-if="linia.ref_materia">
            <td>
              <b>Ref. Materia</b>
            </td>
            <td>{{ linia.ref_materia }}</td>
          </tr>
        </div>
      </tbody>
    </v-simple-table>
    <!-- ./Linies -->

    
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('AlbaraOrdreEixidaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "albara-detall",
  data: () => ({
    deshabilitar_envio: true,
    client_edit:{
      id:"",
      email:""
    },
    dialogEditClient:false,
    dialogDesexpedir:false,
    client_nom: "",
    dialog: false,
    partides:[],
    ordreEixida: {
      quilos_aproximats: null,
      max_num_bales: null,
      observacions: "",
      client: null
    }
  }),
  computed: {
    ...mapGetters({ reserves: "getReserves", materiesPrimeresOpcions: "getMateriesPrimeres", auth: "getAuth"  })
  },
  methods: {
    ...mapActions([
      "eliminarOrdreEixida",
      "getClient",
      "getMesclada",
      "getPartida_new",
      "getOrdreEixida",
      "getPartides",
      "getReserves", 
      "completarOrdreEixida",
      "desCompletarOrdreEixida", 
      "generarAlbaran",
      "getMateriesPrimeres", 
      "imprimirAlbaran_new", 
      "enviarAlbaran_mail", 
      "editClient", 
      "editOrdreEixida", 
      "desexpedirOrdreEixida"
    ]),

    // Botonera
    completarOE(){
      this.completarOrdreEixida(this.ordreEixida.id).then(response=>{
         this.ordreEixida.completa = response.ordre_eixida.completa
      })
    },
    btnVoreMesclada(partida) {
      this.$router.push({
        name: "MescladaEdit",
        params: { id: partida, partida: 2 }
      });
    },
    btnEditClient() {
      this.client_edit.emails = this.client_edit.emails.split(';');
      this.editClient(this.client_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("Client.Notificacio", {
            text: "Client editat correctament",
            color: "success"
          });
          this.dialogEditClient = false;
          this.$router.go()
        } else
          EventBus.$emit("Client.Notificacio", {
            text: "Error editant el client",
            color: "error"
          });
      });
    },
    facturar(){
      this.editOrdreEixida(this.ordreEixida).then(
        response=> {
          if(response){
            this.$router.go()
          }
        }
      )
    },
    desCompletarOE(){
      this.desCompletarOrdreEixida(this.ordreEixida.id).then(response=>{
        this.ordreEixida.completa = response.ordre_eixida.completa
      })
    },
    btnEditarAlbaran() {
      this.$router.push({
        name: "AlbaraOrdreEixidaEdit",
        params: { id: this.ordreEixida.id }
      });
    },
    btnDesexpedir() {
      this.desexpedirOrdreEixida(this.ordreEixida.id).then(status => {
        if (status === 200)
          //this.redireccio('AlbaraOrdreEixidaList');
          this.$router.push({
            name: "OrdreEixidaDetail",
            params: { id: this.ordreEixida.id }
          });
         
      })
    },
    btnEliminarOrdreEixida() {
      this.eliminarOrdreEixida(this.ordreEixida.id).then(response => {
        if (response === 204) {
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: "OrdreEixida eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "OrdreEixidaList" });
        } else
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: "Error eliminant l'ordre d'eixida",
            color: "error"
          });
      });
    },
    btnGenerarAlbaran() {
      /* this.generarAlbaran(this.ordreEixida.id).then(status => {
        if (status === 200)
          window.open(
            "https://" +
              window.location.host +
              // `dev.recuperadosllacer.com/api/v1.0/imprimir_albaran_eixida/${OE}/`,
              `/api/v1.0/imprimir_albaran_eixida/${this.ordreEixida.id}/`,
            "_blank"
          );
      }); */
      this.imprimirAlbaran_new({id: this.ordreEixida.id, info_impresion:{destino:"FrontEnd", 
      request_id: localStorage.getItem('uuid')}})
    },
    btnEnviarAlbaran() {
      this.enviarAlbaran_mail({id: this.ordreEixida.id, permitir_reenviar: true})
      this.$router.go()
    },
    // Text
    textData(data_hora) {
      return data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    },
    textReserva(reservaID) {
      const reserva = this.reserves.find(el => el.id === reservaID);
      const partidaCodi = reserva?.nombre_producto.replaceAll('.', '');
      return `${partidaCodi} - ${reserva?.quilos}`;
    },
    textPartida(partidaID) {
      var part = this.partides.find(el => el.id === partidaID)?.codi.replaceAll('.', '');
      var mat = this.partides.find(el => el.id === partidaID)?.producte_final_nom;
      var obs = this.partides.find(el => el.id === partidaID)?.observacions;
      return `${part} - ${mat} ${obs}`
    },
    textMateriaPrimera(mp_nom) {
      return mp_nom;
    },
    textCostMig(linia) {
      var cost = 0;
      linia.bales.forEach(
        bala=>
        {
          cost = cost+parseFloat(bala.preu_quilo).toFixed(4)/linia.bales.length;
        }
      )
      return cost.toFixed(3);
    },
    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  async created() {
    await this.getOrdreEixida(this.$route.params.id).then(ordreEixida => {
      this.ordreEixida = ordreEixida;
      ordreEixida.lineas.forEach(element => {
        if(element.partida!=null){
          this.getPartida_new(element.partida).then(mesclada=>{
          this.partides.push(mesclada)
        })}
      });
    });
    this.client_edit.id = this.ordreEixida.client;
    this.getClient(this.ordreEixida.client).then(
      client => {
        if(client.emails) this.deshabilitar_envio = false
        this.client_edit = client;
        this.client_nom = client?.nom}
      
      
    );
    

    this.getPartides();
    this.getReserves();
    this.getMateriesPrimeres();
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1,
    h2 {
      text-align: center;
      padding-bottom: 1em !important;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
.v-data-table td {
    font-size: 0.875rem;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;

}
h3 {
  text-align: center;
  padding-bottom: 1em !important;
}
img {
  width: 25px;
  height: 20px;
  margin-left: 10px;
}
#info-linies{
  padding-bottom: 38px;
}
#botonera {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 15%;
  }
}
</style>