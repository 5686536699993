<template>
  <v-container>
    <v-dialog v-model="dialogCodigo" persistent max-width="600">
              <v-card>
                <v-card-title class="headline">MODIFICAR CÓDIGO</v-card-title>
                <v-container>
                  <v-form id="formMaquina" ref="formMaquina" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field @click="nuevo_código=''" v-model="nuevo_código" label="Nuevo número de código (sólo los últimos 4 dígitos)" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="dialogCodigo=false">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCambiarCodigo"
                    :disabled="nuevo_código<0"
                  >Cambiar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    <v-dialog v-model="dialogDatosPartida" persistent max-width="600">
              <v-card>
                <v-card-title class="headline">DATOS PARTIDA - IMPRIMIR</v-card-title>
                <v-container>
                  <v-form id="formMaquina" ref="formMaquina" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-dialog
                        ref="dialogF1"
                        v-model="modalData1"
                        :return-value.sync="mesclada.inici_mesclada_en_date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="mesclada.inici_mesclada_en_date" label="Fecha Inicio" 
                          outlined readonly v-on="on" autofocus prepend-icon="mdi-calendar"
                          @click:clear="datepickerDelete1()"
                          clearable></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="mesclada.inici_mesclada_en_date"
                          locale="ca"
                          :first-day-of-week="1"
                          scrollable
                          
                          @click:date="datepicker1()"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="datepickerDelete1()" tabindex="-1">Borrar</v-btn>
                          <v-btn text color="primary" @click="datepicker1()" tabindex="-1">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                      <v-dialog
                        ref="dialog1"
                        v-model="modal1"
                        :return-value.sync="mesclada.inici_mesclada_en_time"
                        persistent
                        width="390px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="mesclada.inici_mesclada_en_time"
                            label="Hora de inicio"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="modal1"
                          v-model="mesclada.inici_mesclada_en_time"
                          full-width
                          use-seconds
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal1 = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog1.save(mesclada.inici_mesclada_en_time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-dialog
                        ref="dialogF2"
                        v-model="modalData"
                        :return-value.sync="mesclada.finalizat_en_date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="mesclada.finalizat_en_date" label="Fecha fin" 
                          outlined readonly
                          prepend-icon="mdi-calendar"
                           v-on="on" autofocus
                          @click:clear="datepickerDelete()"
                          clearable></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="mesclada.finalizat_en_date"
                          locale="ca"
                          :first-day-of-week="1"
                          scrollable
                          @click:date="datepicker()"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="datepickerDelete()" tabindex="-1">Borrar</v-btn>
                          <v-btn text color="primary" @click="datepicker()" tabindex="-1">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                      <v-dialog
                        ref="dialog"
                        v-model="modal2"
                        :return-value.sync="mesclada.finalizat_en_time"
                        persistent
                        width="390px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="mesclada.finalizat_en_time"
                            label="Hora de finalización"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="modal2"
                          v-model="mesclada.finalizat_en_time"
                          full-width
                          use-seconds
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal2 = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(mesclada.finalizat_en_time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row v-if="mesclada.quilos_totals>100">
                      <v-col md="12">
                        <v-text-field @click="nuevo_código=''" v-model="mesclada.quilos_totals" 
                        label="Kg Materia prima"
                        disabled
                        suffix="Kg" required outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col md="12">
                        <v-text-field @click="nuevo_código=''" 
                        v-model="mesclada.quilos_deseados" 
                        label="Kg deseados"
                        suffix="Kg"
                        required outlined></v-text-field>
                      </v-col>
                    </v-row>
                    
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="dialogDatosPartida=false">Cerrar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnGenerarInformePartida"
                  >IMPRIMIR</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    <!-- Partida -->
    <v-simple-table v-if="partida" class="mt-12">
      <thead>
        <td>
        <h1 style="text-align: center">Vista detallada de Partida</h1>
        </td>
        <td>
          <!--  
        <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        @click="getExcelData_TC"
        :disabled="true"
      >Crear TC</v-btn>-->
      <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        @click="afegirBales(partida.id)"
      >Afegir bales</v-btn>
      <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        @click="dialogCodigo=true"
      >Cambiar código</v-btn>
      <!-- TODO - NO FUNCIONA EN PROD -->
      <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        @click="dialogDatosPartida=1"
        :disabled="partida.estat!='a'"
      >Imprimir finalització</v-btn>
      <v-dialog v-model="dialogInforme" persistent max-width="850">
            <v-card style="padding: 3%">
              <v-card-title class="headline">INFORME DE STOCK</v-card-title>
              <v-row v-if="informe.inici_mesclada" style="justify-content: center">
                <strong>INICI:  </strong> {{informe.inici_mesclada.replace('T',' -- ').replace('+00:00','')}} 
                - <strong>FINALITZACIÓ:  </strong> {{informe.finalitzacio_partida.replace('T',' -- ').replace('+00:00','')}}</v-row>
               <v-card-title class="headline" v-if="informe.inici_mesclada">ABANS</v-card-title>
              <v-row v-if="informe">
              <v-col v-for="(materia, index) in informe_filtrado_antes" :key="index" sm="6">
               <v-row v-for="(materiaProveidor,index2) in materia" :key="index2">
                 <strong> {{index}} 
                  -{{ index2}}: </strong> {{materiaProveidor.num_bales}} bales - {{materiaProveidor.quilos}} kg
               </v-row></v-col>
              </v-row>
              <v-card-title class="headline" v-if="informe.inici_mesclada">DESPRES</v-card-title>
              <v-row v-if="informe">
              <v-col v-for="(materia, index) in informe_filtrado_despues" :key="index" sm="6">
               <v-row v-for="(materiaProveidor,index2) in materia" :key="index2">
                 <strong> {{index}} 
                  - {{ index2}}: </strong> {{materiaProveidor.num_bales}} bales - {{materiaProveidor.quilos}} kg
               </v-row></v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  tabindex="-1"
                  @click="dialogInforme=false"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  rounded
                  tabindex="-1"
                  @click="ImprimirInforme"
                >Descarregar informe</v-btn>
                <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel_Informe"
                class="btn"
                :name="nombreExcel_Informe"
                :data="excelArray_informe"
                :fields="json_fields_Informe"
                worksheet = "Pàgina 1"
                type="xls"
              ></downloadexcel>
              <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  rounded
                  tabindex="-1"
                  @click="descargarTC"
                  id="generarExcel"
                  ref="buttonGetExcelDataTC"
                >Llistat bales</v-btn>
                <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel_TC"
                class="btn"
                :name="nombreExcel_TC"
                :data="excelArray_TC"
                :fields="json_fields_TC"
                worksheet = "Pàgina 1"
                type="xls"
              ></downloadexcel>
              </v-card-actions>
            </v-card>
            </v-dialog>
           <v-btn
              v-if="grupo!=2 && grupo!=1 && grupo!=3"
              @click="crearReserva"
              :disabled="partida.estat!='a'"
            >Crear reserva</v-btn>
            
      </td>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nº Partida</b>
          </td>
          <td>{{ partida.codi.replaceAll('.', '') }}</td>
        </tr>
        <tr v-if="!partida.finalizat_en">
          <td>
            <b>Estat</b>
          </td>
          <td>{{ textEstat(partida.estat) }}</td>
        </tr>
        <tr v-if="partida.finalizat_en">
          <td>
            <b>Data de finalització</b>
          </td>
          <td>{{ textData(partida.finalizat_en) }}</td>
        </tr>
        <tr v-if="partida.plantilla">
          <td>
            <b>Plantilla</b>
          </td>
          <td>{{ partida.plantilla }}</td>
        </tr>
        <tr v-if="partida.producte_final">
          <td>
            <b>Producte final</b>
          </td>
          <td>{{ textProducteFinal() }}</td>
        </tr>
        <tr>
        <!-- <tr>
          <td>
            <b>Cost Total Materies</b>
          </td>
          <td v-if="partida.quilos_totals>100">{{ partida.cost_materies_primeres }}€</td>
          <td v-else> No calculabe</td>

        </tr>
        <tr>
          <td>
            <b>Cost Trinxat Materies</b>
          </td>
          <td>{{ calculCostTrinxat(partida.quilos_borra_totales)  }} €</td>
        </tr>
        <tr>
          <td>
            <b>Cost per kg</b>
          </td>
          <td>{{ ((partida.cost_materies_primeres+ cost_trinxat_total )/partida.quilos_borra_totales).toFixed(2)  }} €</td>
        </tr> -->
        <tr>
        <tr>
          <td>
            <b>Quilos totals borra</b>
          </td>
          <td>{{ partida.quilos_borra_totales }} kg</td>
        </tr>
        <tr v-if="false">
          <td>
            <b>Quilos Expedidos</b>
          </td>
          <td>{{ quilos_expedidos }} kg</td>
        </tr>
        <tr v-if="false">
          <td>
            <b>Quilos totals Materia primera</b>
          </td>
          <td v-if="partida.quilos_totals>100">{{ partida.quilos_totals }} kg</td>
          <td v-else>{{ partida.quilos_deseados }} kg</td>
        </tr>
        <tr>
          <td>
            <b>Merma</b>
          </td>
          <td v-if="calcular_kg_actual()>100 && (calcular_kg_actual() - partida.quilos_borra_totales)>-5">{{ (100*((calcular_kg_actual() - partida.quilos_borra_totales)/calcular_kg_actual())).toFixed(2) }} %</td>
          <td v-else-if="partida.quilos_deseados>0 && (partida.quilos_deseados - partida.quilos_borra_totales)>0">{{ (100*((partida.quilos_deseados - partida.quilos_borra_totales)/partida.quilos_deseados)).toFixed(2) }} %</td>
          <td v-else>No calculable</td>
        </tr>
    <tr>
          <td>
            <b>Producció (kg/h)</b>
          </td>
          
          <td v-if="produccio_ok">{{     produccio  }}</td>
        </tr>
      
        <tr v-if="false">
          <td>
            <b>Bales de borra</b>
          </td>
          <td>{{ partida.numero_bales_borra }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Partida -->
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="success" dark @click="redireccio('PartidaList')">Tornar al llistat</v-btn>
      <v-btn color="primary" dark @click="btnVoreMesclada()">Vore mesclada</v-btn>
    </div>
    <!-- Botons d'opcions -->
        <!-- Bales de borra -->
    <v-simple-table v-if="reserves.length>0" class="llistat-bales">
      <thead>
        <td>
        <h1>Reservas</h1>
        </td>
        
      </thead>
      <tbody v-if="reserves.length>0">
        <tr>
          <td style="width: 5%">
            <b>#</b>
          </td>
          
          <td style="width: 20%">
            <b>Fecha creación </b>
          </td>
          <td>
            <b>Client</b>
          </td>
          <td style="width: 10%">
            <b>Quilos</b>
          </td>
          <td style="width: 10%">
            <b>Quilos enviados</b>
          </td>
          <td style="width: 10%">
            <b>Quilos pendents</b>
          </td>
          <td style="width: 20%">
            <b>Observaciones</b>
          </td>
          <td style="width: 15%">
            <b>Nº Pedido</b>
          </td>
        </tr>
        <tr v-for="(reserva,index) in reserves" :key="index" >
          <td style="width: 5%; margin-top: 5px;" >
            {{index+1}}
          </td>
          
          <td style="width: 20%; margin-top: 5px;" @click="reservaDetail(reserva.id)">
            {{ textData(reserva.data_creacio) }}
          </td>
          <td @click="reservaDetail(reserva.id)" style="margin-top: 5px;">
            {{textClients(reserva)}}
          </td>
          <td style="width: 10%; margin-top: 5px;" @click="reservaDetail(reserva.id)">
            {{parseFloat(reserva.quilos).toFixed(0)}} kg
          </td>
          <td style="width: 10%; margin-top: 5px;" @click="reservaDetail(reserva.id)">
            {{parseFloat(reserva.quilos_enviats).toFixed(0)}} kg
          </td>
          <td style="width: 10%; margin-top: 5px;" @click="reservaDetail(reserva.id)">
            {{parseFloat(reserva.quilos_pendents).toFixed(0)}} kg
          </td>
          <td style="width: 20%; margin-top: 5px;" @click="reservaDetail(reserva.id)">
            {{reserva.observacions}}
          </td>
          <td style="width: 15%; margin-top: 5px;" @click="reservaDetail(reserva.id)">
            {{reserva.num_pedido}}
          </td>
        </tr>
        <tr>
          <td style="width: 5%">
            
          </td>
          
          <td style="width: 20%">
            
          </td>
          <td>
            
          </td>
          <td style="width: 10%">
            <b>{{quilos_reservats_totals}} kg</b>
          </td>
          <td style="width: 10%">
            <b>{{quilos_reservats_enviats}} kg</b>
          </td>
          <td style="width: 10%">
            <b>{{quilos_reservats_pendents}} kg</b>
          </td>
          <td style="width: 20%">
           
          </td>
          <td style="width: 15%">
            
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table  class="llistat-bales">
      <thead>
        <td>
        <h1>Expedicions</h1>
        </td>
        
      </thead>
      <tbody v-if="ordres.length>0">
        <tr>
          <td style="width: 5%">
            <b>#</b>
          </td>
          <td style="width: 20%">
            <b>Albarán</b>
          </td>
          <td style="width: 20%">
            <b>Fecha </b>
          </td>
          <td>
            <b>Client</b>
          </td>
          <td style="width: 10%">
            <b>Quilos</b>
          </td>
          <td style="width: 20%" v-if="grupo!=4">
            <b>Precio venta</b>
          </td>
          <!-- 
          <td style="width: 10%">
            <b>Quarto</b>
          </td>
          <td>
            <b>Magatzem</b>
          </td>
           -->
        </tr>
        <tr v-for="(ordre,index) in ordres" :key="index" >
          <td style="width: 5%; margin-top: 5px;" >
            {{index+1}}
          </td>
          <td style="width: 20%; margin-top: 5px;" @click="albaraDetail(ordre.id)">
            {{ordre.numero_albaran}}
          </td>
          <td style="width: 20%; margin-top: 5px;" @click="albaraDetail(ordre.id)">
            {{ textData(ordre.data_hora_expedicio) }}
          </td>
          <td @click="albaraDetail(ordre.id)" style="margin-top: 5px;">
            {{textClients(ordre)}}
          </td>
          <td style="width: 10%; margin-top: 5px;" @click="albaraDetail(ordre.id)">
            {{parseFloat(quilos_esta_partida[index]).toFixed(0)}} kg
          </td>
          <td style="width: 20%; padding-top: 0px; align: center" v-if="grupo!=4">
            <v-text-field  style="width: 60%; text-align: center; margin-left: 20%; font-size: 1.5vh" v-model="precio_venta[index]" 
            dense
            suffix="€"
            v-on:keyup.enter="guardarOrdre(ordre,precio_venta[index])"
            outlined hide-details="auto"></v-text-field>
            
            
          </td>
          <!-- 
          <td style="width: 8%; text-align:center">
            {{parseFloat(ordre.num_bales)}}
          </td>
          <td>
            {{ parseFloat(ordre.num_bales) }}
          </td>
           -->
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="width: 15%">
          </td>
          <td style="width: 15%">
          </td>
        </tr>
        <tr v-if="false">
          <td style="width: 15%">
            <b>Ingresos totales:</b>
          </td>
          <td style="width: 15%">
            {{precio_partida}} €
          </td>
        </tr>
        <tr>
          <td style="width: 15%">
            <b>Total disponible:</b>
          </td>
          <td style="width: 15%">
            {{partida.quilos_disponibles}} kg 
          </td>
        </tr>
        <tr v-if="false">
          <td style="width: 15%">
            <b>Balas sin asignar:</b>
          </td>
          <td style="width: 15%">
            {{balas_disponibles}} balas
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div id="botonera2">
      <v-btn v-if="balas_visibles" color="primary" dark @click="voreLlistat">Amagar llistat bales</v-btn>
      <v-btn v-else color="primary" dark @click="voreLlistat">Vore llistat bales</v-btn>
    </div>
    <tbody v-if="balas_visibles">
        <tr>
          <td style="width: 15%">
          </td>
          <td style="width: 15%">
          </td>
        </tr>
        <tr>
          <td style="width: 15%">
            <b>Balas sin asignar:</b>
          </td>
          <td style="width: 15%">
            {{balas_disponibles}} balas
          </td>
        </tr>
      </tbody>
    <v-simple-table v-if="partida.bales_productes_finals && balas_visibles" class="llistat-bales">
      <thead>
        <td>
        <h1>Llistat de bales</h1> 
        <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <!-- TODO - HABILITAR BOTÓN -->
      <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        @click="ImprimirPegatinas"
        :loading='deshabilitar_boton'
      >Imprimir etiquetes</v-btn>
      <v-btn
        v-if="grupo!=2 && grupo!=1 && grupo!=3"
        @click="AlmacenarBalas"
        :loading='deshabilitar_boton'
      >Almacenar</v-btn>
        </td>
        
      </thead>
      <tbody>
        <tr>
          <td style="width: 5%">
            <b>Num.</b>
          </td>
          <td>
            <b>Barcode</b>
          </td>
          <td style="width: 14%">
            <b>Quilos</b>
          </td>
          <td style="width: 14%">
            <b>Estat</b>
          </td>
          <td style="width: 10%">
            <b>Quarto</b>
          </td>
          <td>
            <b>Magatzem</b>
          </td>
          <td>
            <b>Data</b>
          </td>
        </tr>
        <tr v-for="(bala,index) in partida.bales_productes_finals" 
        :key="index"
        :class="{'bala-consumida': bala.estat=='e', 'bala-pendent': bala.estat=='a'} " 
        @click="balaDetail(bala.id)">
          <td style="width: 5%">
            {{index+1}}
          </td>
          <td>
            {{bala.barcode}}
          </td>
          <td style="width: 14%">
            {{parseFloat(bala.quilos).toFixed(0)}} kg
          </td>
          <td style="width: 14%">
            {{textEstatBala(bala.estat)}}
          </td>
          <td style="width: 8%; text-align:center">
            {{textQuarto(bala.cuarto)}}
          </td>
          <td>
            {{ textMagatzem(parseInt(bala.posicion_almacenamiento.substr(1, 3))) }}
          </td>
          <td>
            {{ textDataTime(bala.creada_en) }}
          </td>
        </tr>

      </tbody>
    </v-simple-table>
    <!-- ./Bales de borra -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('PartidaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import downloadexcel from "vue-json-excel";
/* import { EventBus } from "@/event-bus.js"; */


export default {
  name: "partida-detall",
  data: () => ({
    produccio_ok: false,
    produccio:0,
    modal2: false,
    modal1: false,
    dialogCodigo: false,
    dialogDatosPartida: false,
    nuevo_código:0,
    MP_partida:[],
    deshabilitar_boton:false,
    balas_borra:[],
    bales_carregades:[],
    balas_borra_ids_sin_almacen:[],
    balas_borra_ids_almacenadas:[],
    informe_filtrado_antes:{},
    informe_filtrado_despues:{},
    quilos_reservats_totals:0.0,
    quilos_reservats_enviats:0.0,
    quilos_reservats_pendents:0.0,
    informe:[],
    balas_visibles:false,
    dialogInforme:false,
    json_fields_TC: {
        QUILOS: "quilos",
        MATERIA_PRIMERA: "materiaPrimera", 
        PROVEIDOR: "proveidor", 
        GRS: "codi_grs",
        QUARTO: "quarto",
        x: "[]",
        xx: "[]",
        xxx: "[]"
      },
    excelArray_TC: [],
    precio_partida:0,
    json_fields: {
        NUM: "numero",
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        MAGATZEM: "magatzem_nom",
        QUILOS: {
          field: "quilos", 
          callback: value =>(parseFloat(value).toFixed(0))
        },
        ESTAT: {
          field: "estat",
          callback: value => (value=="e" ? "Expedida" : "Creada")
        },
        QUARTO: "quarto_nom",
        PRODUCTE_FINAL: "borra_nom",
        DATA_CREACIO: {
          field: "creada_en", 
          callback: value =>(value.replace('T',' -- ').split('.')[0])
        },
        // DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    modalData: false,
    modalData1: false,
    nombreExcel: "Llistat_Bales_Partida",
    nombreExcel_TC: "Llistat_Bales_Partida",
    nombreExcel_Informe: "",
    json_fields_Informe:{
        MATERIA_PRIMERA: "materiaPrimera", 
        PROVEIDOR: "proveidor", 
        BALAS_ANTES: "balas_antes",
        BALAS_DESPUES: "balas_despues",
        QUILOS_ANTES: "quilos_antes",
        QUILOS_DESPUES: "quilos_despues",
    },
    excelArray_informe:[],
    quilos_expedidos: 0,
    balas_disponibles:0,
    dialog: false,
    cost_trinxat_total:0,
    partida: {},
    mesclada: {},
    reserves: {},
    precio_venta:[],
    quilos_esta_partida:[],
    producte_final:"",
    quartos_mesclat:"",
    estatBalaOpcions: [
      { id: "c", nom: "Creada" },
      { id: "m", nom: "En magatzem" },
      { id: "a", nom: "Asignada" },
      { id: "e", nom: "Expedida" }
    ],
    prioritatOpcions: [
      { id: 1, nom: "Baixa" },
      { id: 2, nom: "Mitjana" },
      { id: 3, nom: "Alta" },
      { id: 4, nom: "Urgent" }
    ]
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      prefixosOpcions: "getPrefixos", 
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      materiesPrimeres: "getMateriesPrimeres",
      magatzemOpcions: "getMagatzems", 
      proveidors: "getProveidors",
      ordres: "getOrdresEixidaFiltrades",
      grupo: "getGroup", 
      clientOpcions: "getClients"
    })
  },
  methods: {
    ...mapActions(["getPartida",
    "getMesclada", 
    "getPartida_new", 
    "getPartidesReservades",
    "editMesclada", 
    "editPartida", 
      "getBalesPartida",
      "getBalesMesclada",
      "getPlantilla", 
      "getPrefixos",
      "getMagatzems",
      "getProveidors",
      "getMateriesPrimeres",
      "imprimirPegatinasPartida",
      "imprimirPegatinasPartida_new",
      "getPartidaStocks",
      "getQuartosMesclat",
      "almacenarBalas",
      "getClients",
      "sendLiniaOrdreEixida",
      "editLiniaOrdreEixida",
      "generarInformePartida",
      "cambiarContadorPartida",
      "getMateriaProducteFinal",
      "imprimirInformePartida_new",
      "getOrdreEixida",
      "getReservesFiltrades",
      "getOrdresEixidaFiltrades",
      "getMateriesProducteFinal"]),

    // Auxiliars
    // TODO - Simplificar en una sola funció on passem array i valor
    redireccio(component) {
      this.$router.push({ name: component });
    },
    redireccio_resreva(component) {
      this.$router.push({ name: component[0], params: { partida: component[1] } });
    },
    afegirBales(id) {
      this.$router.push({
        name: "AfegirBalesPartida",
        params: { id: id }
      });
    },
    btnCambiarCodigo(){
      this.cambiarContadorPartida({id: this.partida.id, nou_codi: this.nuevo_código}).then(
        response=> {
          
          if(response.partida.id){
            this.dialogCodigo=false; 
            this.partida = response.partida;
          }
        }
      )
    },
    // Rerouting
    mescladaDetail(mescladaID) {
      this.$router.push({
        name: "MescladaDetail",
        params: { id: mescladaID }
      });
    },
    // Rerouting
    balaDetail(balaID) {
      this.$router.push({ name: "PartidaBalaDetail", params: { id: balaID } });
    },
    albaraDetail(ordreEixidaID) {
      this.$router.push({
        name: "AlbaraOrdreEixidaDetail",
        params: { id: ordreEixidaID }
      });
    },
    reservaDetail(reservaID) {
      this.$router.push({ name: "ReservaDetail", params: { id: reservaID } });
    },
    // Rerouting
    stockBorraDetail(materiaProducteFinalID) {
      this.$router.push({
        name: "StockBorraDetail",
        params: { id: materiaProducteFinalID }
      });
    },
    btnVoreMesclada() {
      this.$router.push({
        name: "MescladaEdit",
        params: { id: this.partida.id, partida: 2 }
      });
    },
    guardarOrdre(ordre,precio) {
      this.getOrdreEixida(ordre.id).then(ordreEixida => {
      ordreEixida.lineas.forEach(
        element=>{
          if(element.partida == this.partida.id){
            this.editLiniaOrdreEixida({id: element.id, precio_venta: precio.replaceAll(',', '.')}).then(
              response=> {if (response) this.$router.go()}
            )
          }
        }
      );
      
      });
      
      
      },
    textData(data_hora) {
      return data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    },
    textDataTime(data_hora) {
      return ` ${data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')} - ${data_hora?.split("T")[1].split(".")[0]} ` ;
    },
    textEstat(estat_partida){
      if (estat_partida == 'e') return `Execució - Última bala:
      ${this.partida.bales_productes_finals[this.partida.bales_productes_finals.length - 1].creada_en.split("T")[1].split(".")[0]}h
      ${this.partida.bales_productes_finals[this.partida.bales_productes_finals.length - 1].creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}`;
      else if (estat_partida == 'i') return "Interrumpida";
      else return "Acabada"
    },
    textQuarto(quartoID){
      const quarto = this.quartos_mesclat.find(el => el.id === quartoID);
      return quarto?.quarto
    },
    textMP(MP_id){
      const MP = this.materiesPrimeres.find(el => el.id === MP_id);
      return MP?.nom
    },
    textCodiGrs(MP_id){
      const MP = this.materiesPrimeres.find(el => el.id === MP_id);
      return MP?.codigo_grs
    },
    textProveidor(MP_id){
      const P = this.materiesPrimeres.find(el => el.id === MP_id).proveidor;
      const Proveidor = this.proveidors.find(el => el.id === P);
      return Proveidor?.nom
    },
    textMagatzem(magatzemNum) {
      const magatzem = this.magatzemOpcions?.find(el => el.numero === magatzemNum);
      if(magatzem?.nom){
        return magatzem?.nom;
        }
      else{
        return "Sense magatzem"
      }
    },
    crearReserva(){
      this.redireccio_resreva(['ReservaCreate', this.partida.id])
    },
    calcular_kg_actual() {

      if (this.bales_carregades.length>1){
        return this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) || 0),
        0
      );}
      else{
        return 0
      }
    },
    calcular_producció() {
      
      if (this.bales_carregades.length>1){
        var tiempo=[];
        var kg_okey=[];
        var differences=[];
        this.partida.bales_productes_finals.forEach(
          bala =>{
            tiempo.push((bala.creada_en))
          })
          for (let i = 0; i < tiempo.length - 3; i++) {
            if((Date.parse(tiempo[i+1]) - Date.parse(tiempo[i]))/1000/60 < 80 && (Date.parse(tiempo[i+1]) - Date.parse(tiempo[i]))/1000/60 >10){
            differences.push((Date.parse(tiempo[i+1]) - Date.parse(tiempo[i]))/1000/3600);
            kg_okey.push(parseInt(this.partida.bales_productes_finals[i].quilos))  
          }
          }
        return parseFloat(kg_okey.reduce((a, b) => a + (parseInt(b) || 0))/ differences.reduce((a, b) => a + (b || 0))).toFixed(2)
      }
      else{
        return 0
      }
    },
    getExcelData(){
      this.nombreExcel= "Llistat_Bales_Partida" + this.partida.codi.replaceAll('.', '')
      this.$nextTick(() => {
      this.$refs.buttonGenerarExcel.$el.click();})
    },
    btnGenerarInformePartida() {
      this.editPartida({
        id: this.mesclada.id,
        prefix: this.mesclada.prefix,
        producte_final: this.mesclada.producte_final,
        quilos_deseados: parseInt(this.mesclada.quilos_deseados),
        finalizat_en: `${this.mesclada.finalizat_en_date}T${this.mesclada.finalizat_en_time}`,
        /*inici_mesclada_en: `${this.mesclada.inici_mesclada_en_date}T${this.mesclada.inici_mesclada_en_time}`*/
      }).then(response => {
        if (response === 200) {
          this.imprimirInformePartida_new({id: this.partida.id, info_impresion:{destino:"FrontEnd", 
              request_id: localStorage.getItem('uuid')}})
            /*this.generarInformePartida(this.partida.id).then(response=>
          {if(response)
           {
             this.dialogDatosPartida = false; 
             
           /*  window.open(
            "https://" +
              window.location.host +
              // `dev.recuperadosllacer.com`+
              `/api/v1.0/imprimir_partida/${this.partida.id}/`,
            "_blank"
          );
          }}) */
          }
      });
      
      
    },
    getExcelData_TC(){
      this.getPartidaStocks(this.partida.id).then(response=> {this.informe = response.data;
      this.dialogInforme = true;
      for( const prop in response.data.antes){
          if(this.MP_partida.includes(prop)) {this.informe_filtrado_antes[prop]=response.data.antes[prop]
          for(const prov in response.data.antes[prop]) 
          { if(response.data.después[prop]) {this.excelArray_informe.push({materiaPrimera: prop, proveidor: prov, 
          balas_antes: response.data.antes[prop][prov].num_bales, quilos_antes: response.data.antes[prop][prov].quilos, 
          balas_despues: response.data.después[prop][prov].num_bales, quilos_despues: response.data.después[prop][prov].quilos })}
          else{
            this.excelArray_informe.push({materiaPrimera: prop, proveidor: prov, 
          balas_antes: response.data.antes[prop][prov].num_bales, quilos_antes: response.data.antes[prop][prov].quilos, 
          balas_despues: 0, quilos_despues: 0 })
          }
          }
          }
        }
      for( const prop in response.data.después){
          if(this.MP_partida.includes(prop)) {this.informe_filtrado_despues[prop]=response.data.después[prop]}
        }  })
      this.nombreExcel_TC= "Bales_MP_" + this.partida.codi.replaceAll('.', '')
      this.nombreExcel_Informe = "TC_" + this.partida.codi.replaceAll('.', '')
    },
    descargarTC(){
      this.$nextTick(() => {
      this.$refs.buttonGenerarExcel_TC.$el.click();})
    },
    ImprimirInforme(){
      this.$nextTick(() => {
      this.$refs.buttonGenerarExcel_Informe.$el.click();})
    },
    ImprimirPegatinas(){
      /* this.deshabilitar_boton = true; */
      /* this.imprimirPegatinasPartida(this.partida.id).then(status => {
        if (status === 200)
          {this.deshabilitar_boton = false;
          window.open(
            "https://" +
              window.location.host +
              `/api/v1.0/imprimir_balas_partida/${this.partida.id}/`,
            "_blank"
          );}
        else
          {
            this.deshabilitar_boton = false;
            EventBus.$emit("Bala.Notificacio", {
            text: "Error generant la pegatina",
            color: "error"
          });}
      }); */
      this.imprimirPegatinasPartida_new({id: this.partida.id, info_impresion:{destino:"FrontEnd", 
      request_id: localStorage.getItem('uuid')}})
      
    },
    voreLlistat(){
      this.balas_visibles = !this.balas_visibles
    },
    AlmacenarBalas(){
      if(this.balas_borra_ids_sin_almacen.length>0)
      {this.almacenarBalas({magatzem_id: 4, posicio_id: 4, balas_borra: this.balas_borra_ids_sin_almacen}).then(response=>
      {if(response){ this.$router.go() }})}
    },
    calculCostTrinxat(kg_partida){
      var cost_trinxat = this.producte_final.cost_trinxat
      this.cost_trinxat_total=kg_partida*cost_trinxat;
      return (kg_partida*cost_trinxat).toFixed(2)
    },
     datepicker() {
      this.$refs.dialogF2.save(this.mesclada.finalizat_en_date);
      this.modalData = false;
    },

    datepickerDelete() {
      this.$refs.dialogF2.save((this.mesclada.finalizat_en_date = ""));
      this.modalData = false;
    },
     datepicker1() {
      this.$refs.dialogF1.save(this.mesclada.inici_mesclada_en_date);
      this.modalData1 = false;
    },

    datepickerDelete1() {
      this.$refs.dialogF1.save((this.mesclada.inici_mesclada_en_date = ""));
      this.modalData1 = false;
    },
    textProducteFinal() {
      return `${this.producte_final.nom} ${this.partida.observacions ? ` ${this.partida.observacions} ` : ""}`;
    },
    textPrioritat(prioritat) {
      return this.prioritatOpcions.filter(el => el.id === prioritat)[0]?.nom;
    },
    textEstatBala(estat) {
      return this.estatBalaOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    textClients(ordre) {
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      return client;
    },
  },
  async created() {
    //await this.getProveidors();
    //await this.getMateriesPrimeres();
    //this.getMateriesProducteFinal();
    this.getOrdresEixidaFiltrades({partida: this.$route.params.id}).then(
      ordres=>
      {
        //this.precio_venta=ordres.results;
        ordres.results.forEach((OE)=>
        {
          this.getOrdreEixida(OE.id).then(ordreEixida => {
            ordreEixida.lineas.forEach(
              lin =>{
                if(lin.partida == this.$route.params.id) {
                  this.quilos_esta_partida.push(lin.quilos)
                  this.precio_venta.push(lin.precio_venta)
                  if(lin.precio_venta) this.precio_partida += parseFloat(lin.precio_venta)*lin.quilos
                }
              }
            )
            
          })
          }
      )}
    )
    this.getPartida(this.$route.params.id).then(partida => {
      this.partida = partida;
      this.getClients();
      this.getMateriaProducteFinal(partida.producte_final).then(response=>
      {if(response) this.producte_final = response})
      this.getPartida_new(this.$route.params.id).then(
        mesclada => {
          this.mesclada = mesclada
          this.mesclada.finalizat_en_date= mesclada.finalizat_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$1-$2-$3')
          this.mesclada.finalizat_en_time= mesclada.finalizat_en.split("T")[1].substr(0,8)
          this.mesclada.inici_mesclada_en_date= mesclada.inici_mesclada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$1-$2-$3')
          this.mesclada.inici_mesclada_en_time= mesclada.inici_mesclada_en.split("T")[1].substr(0,8)

          }
      )
      this.getBalesMesclada(this.$route.params.id).then(
        bales=>{
          if(bales.length>0) {this.bales_carregades = bales;}
        }
      )
      this.getBalesPartida(partida.id).then(
       bales => { 
         this.getPartidesReservades().then(
           response => {
             if(response)
             {
               if(response.result.find(el=> el.id == partida.id)){
                this.getReservesFiltrades({partida: this.$route.params.id}).then(response => 
                {this.reserves = response.result
                  if(response.result.length>0){
                    response.result.forEach(reserva=>
                  {
                    this.quilos_reservats_totals= parseFloat(reserva.quilos)+ parseFloat(this.quilos_reservats_totals);
                    this.quilos_reservats_enviats+=reserva.quilos_enviats;
                    this.quilos_reservats_pendents+= reserva.quilos_pendents;
                  })}
                }
                )
               }
             }
           }
         )
         
         this.partida.bales_productes_finals = bales.bales_productes_finals
       bales.bales_productes_finals.forEach(el=> 
      {
        
        this.balas_borra.push(el.id);
        if(el.magatzem && el.estat!="e" && el.estat!="a" && el.estat!="e" || el.estat=="e" || el.estat=="a" ) {this.balas_borra_ids_almacenadas.push(el.id)} else {this.balas_borra_ids_sin_almacen.push(el.id)}
        if(el.estat=="e"  || el.estat=="a") this.quilos_expedidos = this.quilos_expedidos + (parseInt(el.quilos))
        if(el.estat!="e" && el.estat!="a") this.balas_disponibles += 1
      })
      if(this.partida.bales_productes_finals.length>1){
        var tiempo=[];
        var kg_okey=[];
        var differences=[];
        this.partida.bales_productes_finals.forEach(
          bala =>{
            tiempo.push((bala.creada_en))
          })
          for (let i = 0; i < tiempo.length - 3; i++) {
            if((Date.parse(tiempo[i+1]) - Date.parse(tiempo[i]))/1000/60 < 80 && (Date.parse(tiempo[i+1]) - Date.parse(tiempo[i]))/1000/60 >10){
            differences.push((Date.parse(tiempo[i+1]) - Date.parse(tiempo[i]))/1000/3600);
            kg_okey.push(parseInt(this.partida.bales_productes_finals[i].quilos))  
          }
          }
            this.produccio = parseFloat(kg_okey.reduce((a, b) => a + (parseInt(b) || 0))/ differences.reduce((a, b) => a + (b || 0))).toFixed(2)
          }
          else{
            this.produccio = 0
          }
       

       }
      )
      this.getQuartosMesclat(partida.id).then(
        quartos_mesclada=>{
          this.quartos_mesclat = quartos_mesclada;
          quartos_mesclada.forEach(el=> {el.porcions_mesclat.forEach(bala => {if(!this.MP_partida.includes(bala.materia_primera)) this.MP_partida.push(this.textMP(bala.materia_primera))})})
      
      this.excelArray = this.partida.bales_productes_finals;
      var i=0
      this.excelArray.forEach(el => 
        {
        i = i + 1;
        el.numero = i;
        el.quarto_nom = this.textQuarto(el.cuarto);
        el.borra_nom = this.textProducteFinal();
        el.magatzem_nom = this.textMagatzem(parseInt(el.posicion_almacenamiento.substr(1, 3)))
        })  
      quartos_mesclada.forEach(el => {
        el.porcions_mesclat.forEach(porcio=> {this.excelArray_TC.push({
          quilos : porcio.quilos, 
          //materiaPrimera : this.textMP(porcio.materia_primera),
          //proveidor : this.textProveidor(porcio.materia_primera),
          //codi_grs: this.textCodiGrs(porcio.materia_primera),
          quarto: el.quarto
        })
        })
        })
      this.excelArray_TC.push({
          quilos: partida.quilos_totals,
          materiaPrimera: "Kgs totales MP",
          proveidor : partida.quilos_borra_totales,
          quarto: "Kgs totales Borra"
        })
        }
      )
      
    });
    this.produccio_ok=true;
    if(this.partida.plantilla)
    {await this.getPlantilla(this.partida.plantilla).then(
      plantilla => (this.partida.plantilla = plantilla?.nom)
    );}
    
        await this.getMagatzems();
  }
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  text-align: center;
  padding-bottom: 0.5em;
}
// Taules
table {
  thead {
    td {
      text-align: center;
      padding-bottom: 1em;
      display: flex;
      justify-content: space-around;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        justify-content: center;
        width: 30%;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
      }
    }
  }
}
.llistat-bales{
  padding-top: 15px;
  padding-bottom: 20px;
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        justify-content: center;
        text-align: center;
        width: 20%;
        height: auto;
        padding-top: 1vh;
        padding-bottom: 0;
      }
    }
  }
}
#botonera {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 3.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    width: 35%;
    margin-right: 5px;  }
}
#botonera2 {
  width: 100%;
  padding-bottom: 50px;
  padding-top: 3.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    width: 35%; }
}
img {
  width: 18px;
}
#downloadexcel {
  display: none;
}
.bala-pendent {
      background: rgb(241, 241, 153) ;
}
.bala-consumida {
      background: rgb(241, 171, 153) ;
}
</style>