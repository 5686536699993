<template>
  <v-container>
    <!-- Llistat Partides -->
    <v-simple-table class="mt-12">
      <thead>
        <td >
        <h1 style="display: contents">Llistat de Partides
          <v-btn color="success" :loading='!deshabilitar_tabla' v-if="!deshabilitar_tabla"></v-btn>
          <v-btn color="success" :loading='!cargando_excel' v-if="!cargando_excel"></v-btn>
          <v-checkbox
            v-model="filtre.amb_quilos_disponibles"
            @change="filtrar()"
            no-data-text
            clearable
            label="Sólo disponibles"
            style="margin-top:0px; margin-left:5px"
          ></v-checkbox>

        </h1>
        
        </td>
        <td v-if="grupo!=1 && grupo!=2 && grupo!=3 && grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        :loading='!cargando_excel'
        @click="getExcelData"
      >Generar Excel {{paginasAñadidas}}</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.ordre"
            :items="orderOptions"
            @input="filtrar()"
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="order"
            label="Ordre"
          >
          <template slot="selection" slot-scope="data">{{ (data.item.nom.replaceAll('.','')) }}</template>
            <template slot="item" slot-scope="data">{{(data.item.nom.replaceAll('.','')) }}</template>
          </v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.prefix"
            :items="prefixosOpcions_hardcode"
            @input="filtrar()"
            no-data-text
            outlined
            rounded
            clearable
            @clear="filtrar()"
            item-text="nom"
            item-value="nom"
            label="Referencia"
          >
          <template slot="selection" slot-scope="data">{{ (data.item.nom.replaceAll('.','')) }}</template>
            <template slot="item" slot-scope="data">{{(data.item.nom.replaceAll('.','')) }}</template>
          </v-autocomplete>
        </v-col>
        
        <v-col sm="2" offset-sm="0">
          <v-text-field v-model="filtre.codi_filtro" label="Num. Partida" @input="filtrar()" 
          outlined
          rounded
          required></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.producte_final"
            :items="porductesFinalsOpcions"
            @input="filtrar()"
            no-data-text
            outlined
            rounded
            @clear="filtrar()"
            clearable
            item-text="nom"
            item-value="id"
            label="Producte final"
          ></v-autocomplete>
        </v-col>
        
        
        <!-- <v-col sm="3">
          <v-text-field
            v-model="filtre.quilos_totals"
            label="Quilos Totals"
            @input="filtrar()"
            required
          ></v-text-field>
        </v-col> -->
        <v-col sm="2">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="filtre.data_finalitzacio"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="filtre.data_finalitzacio" label="Data finalització" 
              clearable 
              outlined
              rounded
              readonly v-on="on"
              @click:clear="datepickerDelete()"></v-text-field>
            </template>
            <v-date-picker
              v-model="filtre.data_finalitzacio"
              locale="ca"
              :first-day-of-week="1"
              scrollable
              @click:date="datepicker()"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody v-if="deshabilitar_tabla">
        <tr v-for="(partida, index) in partides" :key="index">
          <td
            @click="partidaDetail(partida.id)"
            
            :class="{'partida-execusio': partida.estat=='e', 'partida-vacia': partida.quilos_disponibles==0, 'partida-interrompuda': partida.estat=='i', 'partida-normal': partida.estat!='i' && partida.estat!='e'} "
          > <strong>{{partida.codi.replaceAll('.', '')}}</strong> {{ textPartida(partida) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Partida -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="partides.length > 0 && deshabilitar_tabla"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "partida-llistat",
  data: () => ({
    deshabilitar_tabla:false,
    cargando_excel:true,
    filtre: {
      internas: '1',
      codi: "",
      ordre: "-codi_partida",
      codi_filtro: "",
      prefix: null,
      plantilla: null,
      quilos_totals: "",
      pagina: null, 
      producte_final: null,
      maquina: null,
      amb_quilos_disponibles: true,
    },
    prefixosOpcions_hardcode:[
      {id:"1", nom:"1"},
      {id:"1", nom:"2"},
      {id:"1", nom:"3"},
      {id:"1", nom:"4"},
      {id:"1", nom:"RC"},
      {id:"1", nom:"A"},
      {id:"1", nom:"AR"},
      {id:"1", nom:"LL"},
      {id:"1", nom:"P"},
    ],
        orderOptions:[
      //{ order: "id", nom: "Δ ID" },
      //{ order: "-id", nom: "∇ ID" },
      //{ order: "ts_ultima_bala", nom: "Δ Creació última bala" },
      //{ order: "-ts_ultima_bala", nom: "∇ Creació última bala" },
      { order: "creat_en", nom: "Δ Data de creació" },
      { order: "-creat_en", nom: "∇ Data de creació" },
      { order: "prefix", nom: "Δ Prefix" },
      { order: "-prefix", nom: "∇ Prefix" },
      //{ order: "estat", nom: "Δ Estat" },
      //{ order: "-estat", nom: "∇ Estat" },
      { order: "codi_partida", nom: "Δ Codi de partida" },
      { order: "-codi_partida", nom: "∇ Codi de partida" },
      
    ],
    modalData: false,
    paginasAñadidas:[],
    pagina: 1,
    json_fields: {
        CODI: "codi",
        PRODUCTE_FINAL: "producte_final_nom_excel", 
        QUILOS_BORRA_FABRICADOS: "quilos_borra_totales", 
        QUILOS_BORRA_DISPONIBLES: "quilos_disponibles", 
        COST_MATERIES_PRIMERES: "cost_materies_primeres",
        QUILOS_MATERIES_PRIMERES: "quilos_totals", 
        RESULTA: "resulta", 
        COST_TRINXAT: "cost_trinxat", 
        COST_MERMA: "merma", 
        COST_PORTE: "cost_transport", 
        COST_GIRO: "cost_financier", 
        COST_TOTAL: "cost_total", 
        PREU_VENTA: "preu_venda", 
        BENEFICI: "benefici", 
        //NUM_BALES: "numero_bales_borra",
        //NUM_QUARTOS: "numero_quartos",
        //PREU_QUILO: {
        //  field: "preu_quilo", 
        //  callback: value =>(parseFloat(value).toFixed(2))
        //},
        //DATA_CREACIO: "creat_en", 
        DATA_FINALITZACIO: "finalizat_en"
      },
    excelArray: [],
    nombreExcel: "Llistat_Partides",
  }),
  computed: {
    ...mapGetters({
      partides: "getPartides",
      plantillesOpcions: "getPlantilles",
      prefixosOpcions: "getPrefixos",
      porductesFinalsOpcions: "getMateriesProducteFinal",
      totalPagines: "getPartidesPaginacio", 
      grupo: "getGroup"
    })
  },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions([
      "getPartides",
      "getPartida",
      "getBalesMesclada",
      "getPartidesFiltrades",
      "getPlantilles",
      "getPrefixos", 
      "getMateriesProducteFinal"
    ]),

    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;

      //this.filtre.amb_quilos_disponibles = true;
      
      if(this.filtre.codi_filtro.length>4){
        this.filtre.prefix = this.filtre.codi_filtro.substring(0,this.filtre.codi_filtro.length-4);
        //if(this.filtre.prefix =="1") this.filtre.prefix= ".1" 
        if(this.filtre.prefix){
          this.filtre.internas = ""
        }else{
          this.filtre.internas ='1';
        }
        this.filtre.codi = this.filtre.codi_filtro.substring(this.filtre.codi_filtro.length-4,6); 
        this.deshabilitar_tabla = false;
        this.getPartidesFiltrades(this.filtre).then(response=>{
          if(response){
            this.deshabilitar_tabla = true;
          }
        });
      }else{
        this.filtre.codi = this.filtre.codi_filtro;
        this.deshabilitar_tabla = false;
        if(this.filtre.prefix){
          this.filtre.internas = ""
        }else{
          this.filtre.internas ='1';
        }
        this.getPartidesFiltrades(this.filtre).then(response=>{
          if(response){
            localStorage.setItem('filtre_partides',  JSON.stringify(this.filtre));
            this.deshabilitar_tabla = true;
          }
        });
      }
      // Codigo de cuando no iba el listado
      //if(this.filtre.codi=="" && this.filtre.producte_final==null && this.filtre.prefix==null) 
      //{
      //  this.deshabilitar_tabla = true;
      //  return;}
      // Comprovem si té prefix i si en té, afegir a filtres
      
    },
    textQuilosBorra(decimal){
      return(new Intl.NumberFormat('id').format(decimal).toString())
      },
    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      localStorage.setItem('filtre_partides',  JSON.stringify(this.filtre));
      this.getPartidesFiltrades(this.filtre);
    },
    // Rerouting
    partidaDetail(partidaID) {
      this.$router.push({
        name: "PartidaDetail",
        params: { id: partidaID }
      });
    },
    getExcelData(){
      this.excelArray.forEach(elem=> 
      {
      //elem.producte_final_nom = this.textProducteFinal(elem.producte_final);
      elem.finalizat_en = elem.finalizat_en?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
      elem.cost_total = parseFloat(elem.cost_total) + parseFloat(elem.resulta) + parseFloat(elem.merma);
      elem.resulta = elem.resulta.replaceAll('.', ',');
      elem.merma = elem.merma.replaceAll('.', ',');
      elem.benefici = (parseFloat(elem.preu_de_venta)-parseFloat(elem.cost_total)).toFixed(3).replaceAll('.', ',');
      elem.cost_total = elem.cost_total.toFixed(3).replaceAll('.', ',');
      //elem.merma = (100*(elem.quilos_totals-elem.quilos_borra_totales)/elem.quilos_totals).toFixed(2)
      }
      )
      this.$nextTick(() => this.$refs.buttonGenerarExcel.$el.click());
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {
      this.cargando_excel=false;
      this.partides.forEach((partida,key, arr) =>{
        
        this.getPartida(partida.id).then(resultado=>{
          partida.cost_financier = resultado.cost_financier.replaceAll('.', ',');
          partida.cost_transport = parseFloat(resultado.cost_transport).toFixed(3).replaceAll('.', ',');
          partida.cost_trinxat = parseFloat(resultado.cost_trinxat).toFixed(3).replaceAll('.', ',');
          partida.quilos_borra_totales = this.textQuilosBorra(resultado.quilos_borra_totales);
          partida.quilos_disponibles = this.textQuilosBorra(resultado.quilos_disponibles);
          partida.preu_venda =resultado.preu_venda;
          partida.preu_de_venta =resultado.preu_venda;
          partida.producte_final_nom_excel = resultado.producte_final_nom + " "+ resultado.observacions;
          partida.cost_total = (parseFloat(resultado.cost_trinxat)+parseFloat(resultado.cost_transport)
           + parseFloat(resultado.preu_venda)*parseFloat(resultado.cost_financier)/100).toFixed(3);
          if (Object.is(arr.length - 1, key)) {
              this.cargando_excel=true;
            }
          partida.preu_venda =parseFloat(partida.preu_venda).toFixed(3).replaceAll('.', ',');
          partida.cost_financier = partida.cost_financier+"%";
        })
        this.getBalesMesclada(partida.id).then(
        bales=>{
          if(bales.length>0) {
            var quilos = 0; 
            var coste =0;
            bales.forEach(bala=>
            {

              coste += (parseInt(bala.quilos) * bala.preu_quilo);
              quilos += parseInt(bala.quilos);
              partida.resulta= (coste/quilos).toFixed(3);
              partida.cost_materies_primeres= this.textQuilosBorra((coste).toFixed(3));
              partida.quilos_totals= this.textQuilosBorra((quilos).toFixed(0));
              partida.merma= (coste/quilos*0.05).toFixed(3);
              partida.benefici= 0;
            }
            
            )
          }
        }
      )
      })
      
      
      this.excelArray.push.apply(this.excelArray, this.partides); 
      this.paginasAñadidas.push(this.pagina);
      
      }
    },
    // Auxiliars
    datepicker() {
      this.$refs.dialog.save(this.filtre.data_finalitzacio);
      this.modalData = false;
      this.filtrar();
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.filtre.data_finalitzacio = ""));
      this.modalData = false;
      this.filtrar();
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textPartida(partida) {
      return ` - ${partida.producte_final_nom} ${partida.observacions ? `${partida.observacions} ` : ""}
       ${partida.quilos_disponibles>=0 ? `- Disponibles: ${partida.quilos_disponibles}kg` : ""} ${partida.finalizat_en ? `- ${this.textData(partida.finalizat_en)}` : ""} `;
       //${partida.quilos_borra_totales ? `- ${partida.quilos_disponibles}/${partida.quilos_borra_totales} kg` : ""} - Bales: ${partida.numero_bales_borra} ${partida.finalizat_en ? `- ${this.textData(partida.finalizat_en)}` : ""} `;
    },
    textProducteFinal(producte_final) {
      return this.porductesFinalsOpcions.filter(el => el.id === producte_final)[0].nom
    },
    textData(data_hora) {
      return data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    },
    textPlantilla(plantillaID) {
      return (
        this.plantillesOpcions.filter(el => el.id === plantillaID)[0].nom ||
        0
      );
    }
  },

  mounted() {
    //this.deshabilitar_tabla =true;
    if(localStorage.getItem('filtre_partides')){
      this.filtre=JSON.parse(localStorage.getItem('filtre_partides'));
      this.pagina = this.filtre.pagina;
    }
    this.getPartidesFiltrades(this.filtre).then(
      response=>{
        if(response.count){
          this.deshabilitar_tabla=true
        }
      }
    );
    this.getMateriesProducteFinal();
    this.getPrefixos();
  }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.text-instancia {
  text-align: left;
  font-size: 20px;
  padding-left: 8%;
}
.partida-interrompuda {
      background: rgb(241, 241, 153) ;
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
.partida-execusio {
      background: rgb(153, 241, 187) ;
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
.partida-vacia {
      background: rgb(215, 216, 215) ;
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
.partida-normal {
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>