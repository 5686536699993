<template>
  <div id="container-menu">
    <v-dialog v-model="dialogImprimir" persistent overlay-opacity="0.99" max-width="700" height="350px">
        <v-card >
          <v-card-title  class="headline" justify="space-around">ALBARANES 
            <v-btn v-if="OESeleccionada==0 || OESeleccionada==undefined"
            @click="refreshData">
              Recargar
            </v-btn>
            <v-btn v-else
            @click="recargarDatos">
              Rellenar campos extra
            </v-btn>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        <v-card-text>
            ¿Qué albarán quieres imprimir?
            <v-spacer></v-spacer>
            <v-autocomplete
                v-model="OESeleccionada"
                :items="OE_seleccionable"
                item-text="nom"
                item-value="id"
                style="margin-top: 1%"
                outlined
                no-data-text="No hay albaranes para imprimir"
                label="Selecciona Carga"
                @change="seleccionarOE"
                @click:clear="borrarInfo"
                clearable
              >
              </v-autocomplete>
            <div v-if="OESeleccionada_ok">
                  <v-simple-table>
                      <tbody>
                        <tr>
                          <td width="55%">
                            <b>IMPRIMIR LOGO</b>
                          </td>
                          <td>
                                <input type="checkbox" id="checkbox1" v-model="ordreEixida.imprimir_logo">
                                
                          </td>
                        </tr>
                        <tr>
                          <td width="55%">
                            <b>IMPRIMIR HOJA EXTRA</b>
                          </td>
                          <td>
                                <input type="checkbox" id="checkbox" v-model="ordreEixida.hoja_extra">
                                
                          </td>
                        </tr>
                        <tr>
                          <td width="55%">
                            <b>NÚMERO COMANDA ALBARÁN</b>
                          </td>
                          <td>
                          <v-text-field v-model="ordreEixida.num_pedido" 
                          label="Num Comanda" 
                          required></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td width="55%">
                            <b>TRANSPORTISTA</b>
                          </td>
                          <td>
                          <v-text-field v-model="ordreEixida.transportista" 
                          label="Nombre transportista" 
                          required></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td width="55%">
                            <b>Entragado en</b>
                          </td>
                          <td>
                          <v-text-field v-model="ordreEixida.entregado_en" 
                          label="Entregado en" 
                          required></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                  </v-simple-table>
                  <v-simple-table v-for="linea in ordreEixida.lineas" :key="linea.id">
                      <thead width="100%">
                        <span>
                          <td>
                        <h1 style="text-align: center" v-if="linea.partida">{{linea.partida_nom}} - {{linea.borra_nom}} </h1>
                        <h1 style="text-align: center" v-if="linea.materia_primera"> {{textMateriaPrimera(linea.materia_primera)}} </h1>
                        </td>
                        <td>
                        <input type="checkbox" id="checkbox3" v-model="linea.imprimir_observaciones">
                        <label for="checkbox3">Imprimir OBSERVACIONES</label>
                        </td>
                        </span>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="55%">
                            <b>GRS</b>
                          </td>
                          <td>
                          <v-text-field v-model="linea.codigo_grs" 
                          label="GRS" 
                          required></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Referencia Ari</b>
                          </td>
                          <td>
                          <v-text-field v-model.number="linea.ref_ari" 
                          label="Referència Ari"
                          required></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Num Comanda</b>
                          </td>
                          <td>
                          <v-text-field v-model="linea.num_pedido" 
                          label="Num Comanda" 
                          required></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Referencia Materia</b>
                          </td>
                          <td>
                          <v-text-field v-model="linea.ref_materia" 
                          label="Ref Materia" required></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
            </div>
        </v-card-text>
        <v-card-actions id="botonera-imprimir">
          <v-btn block color="red" @click="tancarDialogImprimir">Cerrar</v-btn>
          <v-btn block color="success" @click="btnImprimirAlbaran">Imprimir</v-btn>
          <v-btn block color="warning" @click="btnGenerarAlbaran">Expedir</v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- Opcions menú -->
    <div id="botonera-menu">
      <v-row>
        <v-col xs="4">
          <v-btn
            color="info"
            @click="redireccio('VistaInfoBales')"
          ><span style="white-space: normal;">
            INFORMACIÓN <br> BALA
            </span>
          </v-btn>
        </v-col>
        <v-col v-if="grupo!=1 && grupo!=3 " xs="4">
          <v-btn  color="info" @click="redireccio('PuntOmplirQuartos')">
             PANTALLA <br> CORTADOR
          </v-btn>
        </v-col>
         </v-row>
        <v-row>
        <v-col xs="4" v-if="grupo!=2 && grupo!=1 && grupo!=3 && grupo!=4 ">
          <v-btn color="info" @click="redireccio('PuntPesatgeBales')">Pantalla de Salida <br> de Maquinas</v-btn>
        </v-col>
        <v-col xs="4" v-if="grupo!=2">
          <v-btn
            color="info"
            @click="redireccio('EmmagatzemarBalesPF')"
          >
            ALMACENAR <br> BALAS
          </v-btn>
        </v-col>
        </v-row>
        <v-row>
        <v-col xs="4" v-if="grupo!=2 && grupo!=1">
          <v-btn color="info" @click="redireccio('CarregaOrdreEixida')">
             CARGAS <br> PENDIENTES</v-btn>
        </v-col>

        <v-col v-if="grupo!=1 && grupo!=2" xs="4">
          <v-btn
            color="info"
            @click="btnImprimirOE"
            :loading='deshabilitar_boton'
          >
            IMPRIMIR <br> ALBARANES
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- ./Opcions menú -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "menu-treballadors",
  data: () => ({
    deshabilitar_boton: false,
    //partidaOpcions:[],
    dialogImprimir:false,
    OESeleccionada:0,
    texto_OE:[],
    OESeleccionada_ok: false, 
    OE_seleccionable:[], 
    ordreEixida:""
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      ordreEixides: "getOrdresEixidaFiltrades",
      partidaOpcions: "getPartidesTodasSinAcabar",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      grupo: "getGroup", 
      auth: "getAuth"
    })
  },
  methods: {
    ...mapActions([
      "getClients",
      "getPartides",
      "getPartidesFiltrades",
      "getPartida",
      "getMateriesProducteFinal",
      "getMateriaProducteFinal",
      "getOrdreEixida",
      "generarAlbaran",
      "getOrdresEixidaFiltrades",
      "editOrdreEixida", 
      "editLiniaOrdreEixida",
      "expedirOrdreEixida",
      "imprimirAlbaran_new",
      "getPartidesTodas",
      "getPartidesTodasSinAcabar",
      "getMateriesPrimeres"
    ]),
    redireccio(component) {
      this.$router.push({ name: component });
    },
    tancarDialogImprimir(){
      this.OESeleccionada = "";
      this.dialogImprimir = false; 
      this.OESeleccionada_ok = false; 
      this.ordreEixida = "";
    },
    recargarDatos(){
      this.OESeleccionada_ok = true;
    },
    refreshData(){
      this.OE_seleccionable = [];
      this.getOrdresEixidaFiltrades({expedida: false, completa: true}).then(
        response => 
          {if (response) 
          {
            response.forEach(ordre=>
            {(ordre.lineas.map(linia => {
              // if(linia.partida) {return ' '+this.partidaOpcions.find(el => el.id === linia.partida)?.codi} 
              if(linia.partida) {
                if(this.partidaOpcions.find(el => el.id === linia.partida)){
                  this.btnImprimirOE()
                }else{
                  this.getPartida(linia.partida)
                }}
            }))})
            this.btnImprimirOE()}}
        );
      this.$nextTick(() => this.btnImprimirOE());
    },
    seleccionarOE(){
      this.OESeleccionada_ok = false;
      if(this.OESeleccionada && this.ordreEixida=="")
      {this.getOrdreEixida(this.OESeleccionada).then(
        OE => {
          this.ordreEixida = OE;
          var count=0;
          OE.lineas.forEach(
            el=> 
            {
              this.textPartida(el.partida,count); 
              count +=1;
            }
          )
        }
      )}
    },
    borrarInfo(){
      this.ordreEixida="";
      this.OESeleccionada=""; 
    },

    btnImprimirAlbaran() {
      const OE = this.OESeleccionada;
      this.imprimirAlbaran_new({id: OE, info_impresion:{destino:"FrontEnd", 
            request_id: localStorage.getItem('uuid')}})
    },
    btnGenerarAlbaran() {
      this.editOrdreEixida(this.ordreEixida)
      this.ordreEixida.lineas.forEach(el=>{
        this.editLiniaOrdreEixida({id: el.id, codigo_grs: el.codigo_grs, imprimir_observaciones: el.imprimir_observaciones, ref_ari: el.ref_ari, ref_materia: el.ref_materia, num_pedido: el.num_pedido})
      })
      const OE = this.OESeleccionada;
      this.expedirOrdreEixida(OE).then(status => {
        if (status === 200)
          this.imprimirAlbaran_new({id: OE, info_impresion:{destino:"FrontEnd", 
            request_id: localStorage.getItem('uuid')}})
          //this.generarAlbaran(OE).then(response=>
          //{if(response)
          //{ this.refreshData();
          //  window.open(
          //  "https://" +
          //    window.location.host +
              // `dev.recuperadosllacer.com/api/v1.0/imprimir_albaran_eixida/${OE}/`,
          //    `/api/v1.0/imprimir_albaran_eixida/${OE}/`,
          //  "_blank"
          //);}}
          //)
         
      });
     this.OESeleccionada=""; 
      this.getOrdresEixidaFiltrades({expedida: false, completa:true}).then(response=>
        {if(response) {this.btnImprimirOE}}
      )
      this.OESeleccionada_ok= false;
      this.borrarInfo();
      this.refreshData();
    },
    textPartida(partidaID,index) {
      if(partidaID){
      this.getPartida(partidaID).then(partida => {
        this.ordreEixida.lineas[index].partida_nom =  partida?.codi.replaceAll('.', '');
        this.textBorra(partida.producte_final,index); 
      })} else {
        return 
      }
    },
    textBorra(tipusBorraID,index) {
      this.OESeleccionada_ok=false;
      if(tipusBorraID>0){
        this.getMateriaProducteFinal(tipusBorraID).then(borra=>
        {
          this.ordreEixida.lineas[index].borra_nom = borra.nom;
        })
      }
    },
    textMateriaPrimera(mp_nom) {
      return mp_nom;
    },
    textOrdreEixida(ordres) {
      ordres.forEach((ordre,index) =>
      {var productes = ""
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      const data = ordre?.data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
      this.getOrdreEixida(ordre.id).then(
        ordreEixida =>{
          ordreEixida.lineas.forEach((lin, index)=>{
            productes = productes + " - " +ordre.productos_lineas[index].replaceAll('.', '')
            if(this.partidaOpcions.find(part => part.codi === ordre.productos_lineas[index])?.producte_final_nom)
            {productes = productes+ " [" + this.partidaOpcions.find(part => part.codi === ordre.productos_lineas[index])?.producte_final_nom + "]" +" "+ lin.quilos+"kg" + "["+lin.num_bales+"b]"}
            else{
              productes = productes+" "+ lin.quilos+"kg" + "["+lin.num_bales+"b]"
            }
          })
          this.texto_OE.push(`${client} ${productes} - ${data}`
          )
          this.OE_seleccionable[index].nom = `${client} ${productes} - ${data}`; 
        }
        
      )
      }
      )
    },
    btnImprimirOE(){
      this.texto_OE=[];
      this.textOrdreEixida(this.ordreEixides);
      this.dialogImprimir=true;
      this.ordreEixides.forEach(
        (el)=>{
          this.OE_seleccionable.push({nom: "", id: el.id})
          

        }
      ) 
    },
    


  },
  created() {
    this.getOrdresEixidaFiltrades({expedida: false, completa:true});
    this.getClients();
    //this.getMateriesProducteFinal();
    /* this.getPartides();
    for (let i = 1; i<=3 ; i++)
    { const data = {pagina: i}
      this.getPartidesFiltrades(data).then(
      response => 
        {response.result.forEach(el => this.partidaOpcions.push(el))
        if(i==3) this.deshabilitar_boton=false})
    } */
    this.getPartidesTodasSinAcabar()
    //this.getMateriesPrimeres();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-top: 0.5vh;
  font-size: 2.5vh;
}

#container-menu {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #botonera-menu {

    height: 60%;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    .row {
      height: 40%;
      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
          width: 100%;
          height: 90%;
          border-radius: 15px;
          font-size: 2vh;
        }
      }
    }
  }
}
.v-dialog > .v-card > .v-card__title{
  justify-content: space-around;
}
.v-data-table{
  border: grey solid 1px;
}
#botonera-imprimir{
  width:34%;
  button {
          width: 33%;
          border-radius: 15px;
          font-size: 2vw;
          color: white;
        }
}
</style>