<template>
  <v-container>
    <!-- Llistat Reserves -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1 style="display: contents">Llistat de Reserves  <v-btn color="success" dark @click="redireccio('ReservaCreate')">+</v-btn>
        <v-checkbox
            v-model="filtre.amb_quilos"
            @change="filtrar()"
            no-data-text
            clearable
            label="Enviadas"
            style="margin-top:0px; margin-left:5px"
          ></v-checkbox>
        </h1>
        </td>
        <td v-if="grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="3" offset-sm="1">
          <!-- <v-autocomplete
            v-model="filtre.codi_partida"
            :items="mescladesOpcions"
            no-data-text
            outlined
            rounded
            item-text="codi"
            item-value="codi"
            label="Codi Partida"
            clearable
            @change="filtrar()"
          ></v-autocomplete> -->
          <v-autocomplete
            v-model="filtre.partida"
            :items="partidaOpcions"
            no-data-text
            outlined
            rounded
            item-text="codi"
            item-value="id"
            label="Partida"
            clearable
            @change="filtrar()"
          >
            <template slot="selection" slot-scope="data">{{ data.item.codi.replaceAll('.', '')  }} - {{ data.item.producte_final_nom }} {{textObservacions(data.item.observacions)}}</template>
            <template slot="item" slot-scope="data">{{ data.item.codi.replaceAll('.', '') }} - {{ data.item.producte_final_nom }} {{textObservacions(data.item.observacions)}}</template>
          </v-autocomplete>
        </v-col>
        <v-col sm="2" offset-sm="1">
          <v-autocomplete
            v-model="filtre.client"
            :items="clientsOpcions"
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Clients"
            clearable
            @change="filtrar()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2" offset-sm="1">
          <v-text-field v-model="filtre.quilos" 
            label="Quilos" 
            outlined
            rounded
            @input="filtrar()" required></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(reserva, index) in reserves" :key="index">
          <td @click="reservaDetail(reserva.id)" 
          :class="{'reserva-preparada': reserva.estat==0, 'reserva-en-proceso': reserva.estat==2, 'reserva-fin': reserva.estat==3} "
          >{{ textReserva(reserva) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Reserves -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="reserves.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "reserva-llistat",
  data: () => ({
    filtre: {
      codi_partida: null,
      client: null,
      quilos: null,
      pagina: 1, 
      amb_quilos_pendents: true,
      amb_quilos: false
    },
    estatOpcions: [
      { id: 0, nom: "Pendiente" },
      { id: 1, nom: "Asignada" },
      { id: 2, nom: "Parcialment finalitzada" },
      { id: 3, nom: "Finalitzado" }
    ],
    modalData: false,
    reservesFiltrades: [],
    paginasAñadidas:[],
    pagina: 1,
    json_fields: {
        CLIENT: "client_nom",
        PARTIDA: "partida_nom",
        ESTAT: "estat_nom",
        QUILOS: "quilos", 
        QUILOS_ENVIATS: "quilos_enviats", 
        DATA_CREACIO: "data_creacio", 
      },
    excelArray: [],
    nombreExcel: "Llistat_Reserves",
    carregaInicial: false
  }),
  computed: {
    ...mapGetters({
      clientsOpcions: "getClients",
      mescladesOpcions: "getMescladesReservades",
      reserves: "getReserves",
      totalPagines: "getReservesPaginacio", 
      grupo: "getGroup", 
      partidaOpcions: "getPartidesReservades", 

    })
  },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions([
      "getClients",
      "getMescladesReservades",
      "getReserves",
      "getReservesFiltrades", 
      "getPartidesSimples", 
      "getPartidesReservades",
    ]),
    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      this.filtre.amb_quilos_pendents = !this.filtre.amb_quilos;
      this.getReservesFiltrades(this.filtre);
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      this.getReservesFiltrades(this.filtre);
    },

    // Rerouting
    reservaDetail(reservaID) {
      this.$router.push({ name: "ReservaDetail", params: { id: reservaID } });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    // Auxiliars
    getExcelData(){
      this.excelArray.forEach(elem=> 
      {elem.client_nom = this.textClient(elem);
      elem.partida_nom = this.textPartida(elem);
      elem.estat_nom = this.textEstat(elem.estat);
      }
      )
      this.$nextTick(() => this.$refs.buttonGenerarExcel.$el.click());
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.reserves); 
      this.paginasAñadidas.push(this.pagina);}
    },
    textClient(reserva){
      const client = this.clientsOpcions.find(el => el.id === reserva.client);
      return client?.nom;
    },
    textPartida(reserva){
      const ordreFabricacio = this.mescladesOpcions.find(
        el => el.id === reserva.partida
      );
      return ordreFabricacio?.codi.replaceAll('.', '');
    },
    textEstat(estat) {
      return this.estatOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    textReserva(reserva) {
      //const ordreFabricacio = this.mescladesOpcions.find(
      //  el => el.id === reserva.partida
      //);
      const client = this.clientsOpcions.find(el => el.id === reserva.client);
      return `${reserva.nombre_producto.replaceAll('.', '')} - ${client?.nom} - ${reserva?.quilos}kg - Enviats: ${reserva?.quilos_enviats} kg  ${reserva?.num_pedido? ` - Pedido: ${reserva?.num_pedido}`:``}`;
    }
  },
  created() {
    this.getClients();
    this.getMescladesReservades();
    //this.getReserves();
    this.getReservesFiltrades(this.filtre);
    this.getPartidesReservades();
  }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
}
.reserva-preparada {
      background: rgb(255, 255, 255) ;
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
.reserva-en-proceso {
      background: rgb(153, 241, 187) ;
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
.reserva-fin {
      background: rgb(158, 222, 226) ;
      text-align: left;
      font-size: 20px;
      padding-left: 8%;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>