<template>
  <div class="home">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {}
};
</script>
