import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import axios from 'axios'
import Vuetify from 'vuetify';
import vuetify from './plugins/vuetify';
import VueTouchKeyboard from "vue-touch-keyboard";
import JsonExcel from 'vue-json-excel';
import UUID from "vue-uuid";


Vue.config.productionTip = false

// Opciones de Axios
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

//const serverURL = process.env.VUE_APP_API_URL_DEV
const serverURL = process.env.VUE_APP_API_URL_PROD
/* var serverURL = window.location.host
if (serverURL.includes('localhost')) {
  serverURL= process.env.VUE_APP_API_URL_DEV
} */

const apiAxios = axios.create({
  baseURL: serverURL + process.env.VUE_APP_SOCKET_PATH,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    'Content-Type': 'application/json'
  },
  xhrFields: {
    withCredentials: true
  }
})

Vue.prototype.$http = apiAxios

// Configuración Vuetify
Vue.use(Vuetify);
Vue.use(VueTouchKeyboard);
Vue.use(UUID);
Vue.component('downloadExcel', JsonExcel)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
