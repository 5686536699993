<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Bala -->
      <h1>Edició de Bala</h1>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="bala.quilos" label="Quilos" 
          outlined
          autofocus
          required>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="bala.materia_primera"
            :items="materiesPrimeresOpcions"
            label="Materia primera"
            item-value="id"
            :filter="customFilter"
            outlined
            no-data-text="No s'ha pogut carregar cap materia primera"
          >
            <template slot="selection" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            <template slot="item" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
          </v-autocomplete>
        </v-col>
        <v-col md="1">
          <!-- Dialog/Modal per crear materia primera -->
          <v-dialog v-model="dialogMateriaPrimera" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn v-if="grupo!=4" outlined color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear materia primera</v-card-title>
              <v-container>
                <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="material_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="material_nou.preu_quilo" 
                      label="Preu quilo" 
                      :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                      required>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10">
                      <v-autocomplete
                        v-model="material_nou.proveidor"
                        :items="proveidorOpcions"
                        no-data-text="No s'ha pogut carregar cap proveïdor"
                        item-text="nom"
                        item-value="id"
                        label="Proveïdor"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="2">
                      <!-- Dialog/Modal per crear proveïdors -->
                      <v-dialog v-model="dialogProveidor" persistent max-width="600">
                        <template v-slot:activator="{ on }">
                          <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">Crear</v-card-title>
                          <v-container>
                            <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                              <v-row>
                                <v-col md="12">
                                  <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col md="12">
                                  <v-text-field
                                    v-model="proveidor_nou.poblacio"
                                    label="Població"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col md="12">
                                  <v-text-field
                                    v-model="proveidor_nou.id_facturaplus"
                                    label="ID Facturaplus"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                          <v-card-actions>
                            <v-btn
                              color="green darken-1"
                              text
                              tabindex="-1"
                              @click="dialogProveidor = false"
                            >Cancel·lar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="btnCrearProveidor"
                              tabindex="-1"
                            >Crear</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- ./Dialog/Modal per crear ingredients -->
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="10">
                        <v-autocomplete
                        v-model="material_nou.tipo"
                        :items="tiposOpcions"
                        no-data-text="No s'ha pogut carregar cap tipo de materia"
                        item-text="nom"
                        item-value="id"
                        label="Composició"
                      ></v-autocomplete>
                      </v-col>
                      <v-col sm="2">
                          <!-- Dialog/Modal per crear ingredients -->
                          <v-dialog v-model="dialogTipoMateria" persistent max-width="600">
                            <template v-slot:activator="{ on }">
                              <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                            </template>
                            <v-card>
                              <v-card-title class="headline">Crear Composició</v-card-title>
                              <v-container>
                                <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                                  <v-row>
                                    <v-col md="12">
                                      <v-text-field v-model="tipusMP_nou.nom" label="Composició" autofocus required @keydown.enter.prevent="btnCrearTipusMP"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                              <v-card-actions>
                                <v-btn
                                  color="green darken-1"
                                  text
                                  @click="dialogTipoMateria = false"
                                >Cancel·lar</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" text @click="btnCrearTipusMP">Crear</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <!-- ./Dialog/Modal per crear ingredients -->
                        </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10">
                      <v-autocomplete
                        v-model="material_nou.ingredient"
                        :items="ingredientsOpcions"
                        no-data-text="No s'ha pogut carregar cap tipus de materia"
                        item-text="nom"
                        item-value="id"
                        label="Tipus de materia"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="2">
                      <!-- Dialog/Modal per crear ingredients -->
                      <v-dialog v-model="dialogIngredient" persistent max-width="600">
                        <template v-slot:activator="{ on }">
                          <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">Crear tipus de materia</v-card-title>
                          <v-container>
                            <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                              <v-row>
                                <v-col md="12">
                                  <v-text-field v-model="ingredient_nou.nom" label="Nom" autofocus required @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                          <v-card-actions>
                            <v-btn
                              color="green darken-1"
                              text
                              tabindex="-1"
                              @click="dialogIngredient = false"
                            >Cancel·lar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="btnCrearIngredient"
                              tabindex="-1"
                            >Crear</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- ./Dialog/Modal per crear ingredients -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-checkbox v-model="material_nou.ignifug" label="Ignífug"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogMateriaPrimera = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearMateriaPrimera"
                  tabindex="-1"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear materia primera -->
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="bala.preu_quilo" 
          v-if="grupo!=4"
          label="Preu per quilo" 
          required
          outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field 
          v-model="bala.observacions" 
          label="Observacions"
          outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="bala.posicion_almacenamiento"
            :items="OpcionesSubalmacenes"
            label="Magatzem"
            item-text="nom"
            item-value="barcode"
            outlined
            no-data-text="No s'ha pogut carregar cap magatzem"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear magatzem -->
          <v-dialog v-model="dialogMagatzem" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn v-if="grupo!=4" outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear magatzem</v-card-title>
              <v-container>
                <v-form id="formMagatzem" ref="formMagatzem" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="magatzem_nou.nom" label="Nom" required></v-text-field>
                      <v-text-field v-model="magatzem_nou.numero" label="Numero" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogMagatzem = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="btnCrearMagatzem" tabindex="-1">Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear magatzem -->
        </v-col>
      </v-row>
      <!-- ./Balas -->
      <div id="botonera">
        <v-btn color="error" @click="cancelarEdicio" tabindex="-1">Cancel·lar</v-btn>
        <v-btn color="success" @click="editarBala" :disabled="!bala.quilos || !bala.materia_primera" >Guardar</v-btn>
      </div>
    </v-form>
    <!-- ./Form -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col v-if="this.$route.params.origen=='stock'" class="text-center" cols="12" @click="redireccio('StockMateriaPrimerBalaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
      <v-col v-if="this.$route.params.origen=='sense'" class="text-center" cols="12" @click="$router.push({ name: 'BalaDetail', params: { id: bala.id, sense: true } })" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
      <v-col v-if="this.$route.params.origen!='sense' && this.$route.params.origen!='stock'" class="text-center" cols="12" @click="redireccio('BalaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "bala-edit",
  data: () => ({
    tipusMP_nou: {
      nom: ""
    },
    dialogTipoMateria:false,
    OpcionesSubalmacenes:[],
    bala: {
      id: null,
      quilos: "",
      preu_quilo: "",
      observacions: "",
      materia_primera: "",
      magatzem: ""
    },
    magatzem_inical:"",
    posicion_inical:"",
    dialogIngredient: false,
    dialogMagatzem: false,
    dialogMateriaPrimera: false,
    dialogProveidor: false,
    ingredient_nou: {
      nom: ""
    },
    magatzem_nou: {
      nom: "",
      numero: ""
    },
    material_nou: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    proveidor_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    }
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors", 
      grupo: "getGroup",
      tiposOpcions: "getTipusMateria",
    })
  },
  methods: {
    ...mapActions([
      "editBala",
      "getBala",
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getTipusMateria", 
      "getProveidors",
      "sendIngredient",
      "sendMagatzem",
      "sendMateriaPrimera",
      "sendProveidor", 
      "sendTipusMateria", 
      "almacenarBalas",
      "desalmacenarBalas", 
      "createPosicioMagatzem", 
      "getMagatzemNumero"
    ]),

    editarBala() {
      this.editBala(this.bala).then(response => {
        if (response === 200) {
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala editada correctament",
            color: "success"
          });
          if(this.$route.params.origen=="stock"){
            this.redireccio('StockMateriaPrimerBalaDetail')
          }else if(this.$route.params.origen=="sense"){
            this.$router.push({ name: 'BalaDetail', params: { id: this.bala.id, sense: true } });
          }else{
            this.redireccio('BalaDetail')}
        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error editant la bala",
            color: "error"
          });
      });
      if(this.bala.posicion_almacenamiento != this.posicion_almacenamiento_inical){
        if(this.magatzem_inical){
          this.desalmacenarBalas({posicio_id: this.posicion_inical, balas_materia_prima: [this.bala.id]}).then(
            response=>{
              if (response){
                this.almacenarBalas({posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == this.bala.posicion_almacenamiento).id, 
                balas_materia_prima: [this.bala.id]}).then(
                response=>{
                  if (response){
                    this.$router.push({
                    name: "BalaDetail",
                    params: { id: this.bala.id }
                  });
                  } 
                }
          )
              } 
            }
          )
        }else{
          this.almacenarBalas({posicio_id: this.OpcionesSubalmacenes.find(el=> 
          el.barcode == this.bala.posicion_almacenamiento).id,
           balas_materia_prima: [this.bala.id]}).then(
            response=>{
              if (response){
                this.$router.push({
                name: "BalaDetail",
                params: { id: this.bala.id }
              });
              } 
            }
          )
        }
      }
    },

    // Crear element
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.ingredient = data.data.id;
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearTipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.tipo = data.data.id;
          this.tipusMP_nou = {
            nom: ""
          };
          this.dialogTipoMateria = false;
          this.materia_primera.tipo_nom = this.textCodiGRS(this.materia_primera.tipo);
        }
      });
    },
    textCodiGRS(id){
      if(id)
      {return (this.tiposOpcions.find(el=> el.id == id).nom)}
    },
    btnCrearMagatzem() {
      this.sendMagatzem(this.magatzem_nou).then(data => {
        if (data?.status === 201) {
          this.magatzem_nou = {
            nom: ""
          };
          this.dialogMagatzem = false;
          this.bala.magatzem = data.data.id;
          this.createPosicioMagatzem({magatzem: this.bala.magatzem, posicio: "1", zona:"1"})
          
        }
      });
    },
    btnCrearMateriaPrimera() {
      //this.material_nou.ingredients=[this.material_nou.ingredients];
      this.sendMateriaPrimera(this.material_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou = {
            nom: "",
            preu_quilo: null,
            proveidor: "",
            ignifug: false
          };
          this.dialogMateriaPrimera = false;
          this.bala.materia_primera = data.data.id;
          this.bala.preu_quilo = data.data.preu_quilo;
        }
      });
    },
    btnCrearProveidor() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data?.status === 201) {
          this.proveidor_nou = {
            nom: ""
          };
          this.dialogProveidor = false;
          this.material_nou.proveidor = data.data.id;
        }
      });
    },
    // Auxiliar
    cancelarEdicio() {
      if(this.$route.params.origen=="stock")
      {this.$router.push({
        name: "StockMateriaPrimerBalaDetail",
        params: { id: this.$route.params.id }
      });}else{
        this.$router.push({
        name: "BalaDetail",
        params: { id: this.$route.params.id }
      });
      }
    },
    carregarPreu() {
      const preu_quilo =
        this.materiesPrimeresOpcions.find(
          el => el.id === this.bala.materia_primera
        )?.preu_quilo || 0;

      this.bala.preu_quilo = preu_quilo;
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      if(item){return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());}
    },
    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.bala.id } });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera.proveidor
      );

      if (mpProveidor) return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''} - ${mpProveidor.nom}`;
      return materiaPrimera?.nom;
    }
  },
  created() {
    this.getTipusMateria();
    this.getBala(this.$route.params.id).then(bala => {this.bala = bala;
    this.posicion_almacenamiento_inical= bala.posicion_almacenamiento;
    if (this.bala.magatzem) {
          this.getMagatzemNumero(parseInt(this.bala.posicion_almacenamiento.substr(1, 3))).then(magatzemInfo => {
            this.posicion_inical=bala.magatzem;
            this.bala.magatzem = magatzemInfo[0]?.id;
            this.magatzem_inical= magatzemInfo[0]?.id;
            

          });
        } 
    }
    );
    this.getIngredients();
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getProveidors();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

#botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 46%;
  }
}
</style>