<template>
  <v-container>
    <!-- Llistat Ordres d'Eixida -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1>Listado de Cargas (Ordrenes de Salida)
          <v-btn v-if="deshabilitar_tabla" color="success" dark @click="redireccio('OrdreEixidaCreate')">+</v-btn>
          <v-btn color="success" :loading='!deshabilitar_tabla' v-if="!deshabilitar_tabla"></v-btn>
        </h1>
        
        </td>
        <td v-if="grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Añadir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Vaciar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row v-if="deshabilitar_tabla">
        <v-col sm="3" >
          <v-autocomplete
            v-model="filtre.client"
            :items="clientOpcions"
            no-data-text
            item-text="nom"
            item-value="id"
            label="Cliente"
            clearable
            outlined
            rounded
            @change="filtrar()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" >
          <v-autocomplete
            v-model="filtre.partida"
            :items="partidaOpcions"
            no-data-text
            item-value="id"
            label="Partida"
            clearable
            outlined
            rounded
            @change="filtrar()"
          >
           <template slot="selection" slot-scope="data">{{ data.item.codi.replaceAll('.', '')  }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
            <template slot="item" slot-scope="data">{{ data.item.codi.replaceAll('.', '') }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
          </v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.producte_final"
            :items="partidaOpcions"
            no-data-text
            item-text="producte_final_nom"
            item-value="producte_final"
            label="Producto final"
            clearable
            outlined
            rounded
            @change="filtrar()"
          >
          </v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.estat"
            :items="estatOpcions"
            no-data-text
            item-text="nom"
            item-value="estat"
            label="Estado"
            clearable
            outlined
            rounded
            @click:clear="filtrar()"
            @change="filtrar()"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody >
        <tr v-for="(ordreEixida, index) in ordreEixides" :key="index">
          <td
            @click="ordreEixidaDetail(ordreEixida.id)"
            class="text-instancia"
          >{{ textOrdreEixida(ordreEixida) }} 
          <img v-if="ordreEixida.completa"
            src="https://cdn.pixabay.com/photo/2017/01/13/01/22/ok-1976099_960_720.png"
            alt
          />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Ordres Eixida -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "ordre-eixida-llistat",
  data: () => ({
    deshabilitar_tabla:false,
    filtre: {
      client: null,
      partida: null,
      estat: null, 
      expedida: false,
      producte_final: null
    },
    partides_consultadas_view:[],
    OE_completes:[],
    ordresEixidaFiltrades: [],
    paginasAñadidas:[],
    pagina: 1,
    json_fields: {
        CLIENT: "client_nom",
        COMPLETA: "completa", 
        EXPEDIDA: "expedida", 
        NUM_BALES: "num_bales",
        DATA_CREACIO: "data_hora", 
        NUMERO_ALBARAN: "numero_albaran",
        QUILOS: "quilos", 
        PRODUCTES: "partides_linies"
      },
    excelArray: [],
    nombreExcel: "Llistat_OrdresEixida",
    carregaInicial: false,
    estatOpcions:[
      {estat: 1, nom: "Sin completar"},
      {estat: 2, nom: "Completa"}
      // ,
      // {estat: 3, nom: "Expedida"}
    ]
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      ordreEixides: "getOrdresEixida",
      totalPagines: "getOrdresEixidaPaginacio", 
      partidesPaginacio: "getPartidesPaginacio", 
      grupo: "getGroup", 
      materiesFinals: "getMateriesProducteFinal", 
      partidaOpcions: "getPartidesSimples", 
    })
  },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions([
      "getClients",
      "getMateriesProducteFinal",
      "getOrdresEixida",
      "getOrdresEixidaFiltrades",
      "getPartidesFiltrades", 
      "getPartides", 
      "getPartida",
      "getPartidesTodas",
      "getPartidesSimples",
      "getPartidesTodasSinAcabar",
      "getOrdreEixida",
      "getMateriesPrimeres"
    ]),
    // Filtrat
    filtrar() { 
      this.pagina = 1;
      this.filtre.pagina = 1;
      if(this.filtre.estat==1){
        this.filtre.completa=false;
        this.filtre.expedida=false;
      }else if(this.filtre.estat==2){
        this.filtre.completa = true;
        this.filtre.expedida = false;
      }else if (this.filtre.estat ==3){
        this.filtre.expedida = true;
      }else{
        this.filtre.expedida = null;
        this.filtre.completa = null;
      }
      this.filtre.expedida = false;
      this.OE_completes=[];
      this.getOrdresEixidaFiltrades(this.filtre).then(
          response=> {
            if(response){
              localStorage.setItem('filtre_cargas',  JSON.stringify(this.filtre));
              response.results.forEach(
                ordre=>{
                  this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
                }
              )
            }
          }
        );
      
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      localStorage.setItem('filtre_cargas',  JSON.stringify(this.filtre));
      this.getOrdresEixidaFiltrades(this.filtre).then(
          response=> {
            if(response){
              response.results.forEach(
                ordre=>{
                  this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
                }
              )
            }
          }
        )
      
    },

    // Rerouting
    ordreEixidaDetail(ordreEixidaID) {
      this.$router.push({
        name: "OrdreEixidaDetail",
        params: { id: ordreEixidaID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Texts
    textOrdreEixida(ordre) {
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      const data = ordre?.data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
      var productes = ""

      ordre.productos_lineas.forEach(prod=>
      {
        productes = productes+ " - "+prod.replaceAll('.', '')
        if(this.partidaOpcions.find(part => part.codi === prod)?.producte_final_nom)
        {productes = productes+ " " + this.partidaOpcions.find(part => part.codi === prod)?.producte_final_nom + " "}
        if(this.partidaOpcions.find(part => part.codi === prod)?.observacions)
        {productes = productes + this.partidaOpcions.find(part => part.codi === prod)?.observacions }
        
      })
      //let partides = (ordre.lineas.map(linia => {
      //    if(linia.partida) {return this.partidaOpcions.find(el => el.id === linia.partida)?.codi}
      //    else{
      //      return this.textMateriaPrimera(linia.materia_primera)
      //    }
      //}))
      
      //return `${client} - ${partides.length > 0 ? `[${partides}] - ` : ' '} ${data}`;
      return `${client} ${this.OE_completes.find(OE => OE.id === ordre.id)?.comentaris} ${productes} - ${data}`;
    },
    textClients(ordre) {
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      return client;
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    textLiniesOrdreEixida(ordre) {
      let partides = (ordre.lineas.map(linia => {
          return this.partidaOpcions.find(el => el.id === linia.partida)?.codi.replaceAll('.', '')
      }))
      partides = [...new Set(partides)]
      return `${partides.length > 0 ? `[${partides}] ` : ' '}`;
    },
    textMateriaPrimera(mp_nom) {
      return mp_nom;
    },

    // Auxiliars
    getExcelData(){
      this.excelArray.forEach(elem=> 
      {elem.partides_linies = this.textLiniesOrdreEixida(elem);
      elem.client_nom = this.textClients(elem);
      }
      )
      this.nombreExcel= this.nombreExcel +' '+ new Date().getDate() +'_'+ (parseInt(new Date().getMonth())+1) +
      '_'+ new Date().getFullYear()
      this.$nextTick(() => this.$refs.buttonGenerarExcel.$el.click());
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.ordreEixides); 
      this.paginasAñadidas.push(this.pagina);}
    },
  },
  created() {
    /* this.getPartides(); */
    /* for (let i = 1; i<=3 ; i++)
    { const data = {pagina: i}
      this.getPartidesFiltrades(data).then(
      response => {response.result.forEach(el => this.partidaOpcions.push(el)); 
      if(i==3) this.deshabilitar_tabla=true})
    } */
    this.getClients();
    this.deshabilitar_tabla = true;
    //this.getPartidesTodas().then(
    //  response=> {if(response){this.deshabilitar_tabla=true}}
    //);    
    this.getMateriesProducteFinal();
    
    if(localStorage.getItem('filtre_cargas')){
      this.filtre=JSON.parse(localStorage.getItem('filtre_cargas'));
      this.pagina = this.filtre.pagina;
      this.getOrdresEixidaFiltrades(this.filtre).then(
          response=> {
            if(response){
              response.results.forEach(
                ordre=>{
                  this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
                }
              )
            }
          }
        );
    }else{
      this.getOrdresEixidaFiltrades({pagina:1,expedida: false}).then(
          response=> {
            if(response){
              this.getPartidesSimples()
              response.results.forEach(
                ordre=>{
                  this.getOrdreEixida(ordre.id).then(result=>
                    {if(result){this.OE_completes.push(result)}
                    })
                }
              )
            }
          }
        )
    }
  },
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
img {
  width: 25px;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>