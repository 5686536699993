import Vue from 'vue'
import Vuex from 'vuex'


import authAPI from '@/api/api';

import router from '../router/index.js'

import { EventBus } from "@/event-bus.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      jwt: '',
      nom: '',
      user_id: '', 
      grupo: ''
    },
    bales: [],
    bales_count: 0,
    bales_pagines: 0,
    borres: [],
    borres_count: 0,
    borres_pagines: 0,
    clients: [],
    entrades: [],
    entrades_count: 0,
    fabriques: [],
    fabriques_count: 0,
    ingredients: [],
    maquinas:[],
    informes:[],
    informes_pagines:0,
    magatzems: [],
    materiesPrimeres: [],
    materiesPrimeresNomsSenseDuplicar: [],
    materiesProducteFinal: [],
    mesclades: [],
    mescladesNoAcabades:[],
    mescladesNoAcabades_count:0,
    mescladesExecusio: [],
    mescladesInterrumpidas:[],
    mesclades_count: 0,
    mesclades_reservades: [],
    ordres_eixida: [],
    ordres_eixida_count:0,
    ordres_eixida_pagines:0,
    partides: [],
    partides_count: 0,
    plantilles: [],
    prefixos: [],
    proveidors: [],
    quartos_mezclando:{},
    tipus_materies_primeres:[],
    reserves: [],
    reserves_count: 0,
  },
  getters: {
    getAuth: state => state.auth,
    getGroup: state => state.auth.grupo,
    getBales: state => state.bales,
    getBalesPaginacio: state => state.bales_pagines,
    getInformesPaginacio: state => state.informes_pagines,
    getBorres: state => state.borres,
    getBorresPaginacio: state => state.borres_pagines,
    getClients: state => state.clients,
    getClientsCap: state => {
      let clients = JSON.parse(JSON.stringify(state.clients))
      clients.unshift({ id: null, nom: 'No filtrar' })
      return clients
    },
    getEntrades: state => state.entrades,
    getEntradesPaginacio: state => { return Math.ceil(state.entrades_count / 20) },
    getMaquinas: state=>state.maquinas,
    getInformes: state=>state.informes,
    getFabriques: state => state.fabriques,
    getIngredients: state => state.ingredients,
    getIngredientsCap: state => {
      let ingredients = JSON.parse(JSON.stringify(state.ingredients))
      ingredients.unshift({ id: null, nom: 'No filtrar' })
      return ingredients
    },
    getMagatzems: state => state.magatzems,
    getMagatzemsCap: state => {
      let magatzems = JSON.parse(JSON.stringify(state.magatzems))
      magatzems.unshift({ id: null, nom: 'No filtrar' })
      return magatzems
    },
    getMateriesPrimeres: state => state.materiesPrimeres,
    getMateriesPrimeresCap: state => {
      let materiesPrimeres = JSON.parse(JSON.stringify(state.materiesPrimeres))
      materiesPrimeres.unshift({ id: null, nom: 'No filtrar' })
      return materiesPrimeres
    },
    getMateriesPrimeresNomsSenseDuplicarCap: state => {
      let materiesPrimeresNomsSenseDuplicar = JSON.parse(JSON.stringify(state.materiesPrimeresNomsSenseDuplicar))
      return materiesPrimeresNomsSenseDuplicar
    },
    getMateriesProducteFinal: state => state.materiesProducteFinal,
    getMateriesProducteFinalCap: state => {
      let materiesProducteFinal = JSON.parse(JSON.stringify(state.materiesProducteFinal))
      materiesProducteFinal.unshift({ id: null, nom: 'No filtrar' })
      return materiesProducteFinal
    },
    getMesclades: state => state.mesclades.filter(mesclada => mesclada.estat != "a"),
    getMescladesCap: state => {
      let mesclades = JSON.parse(JSON.stringify(state.mesclades))
      mesclades.unshift({ id: null, codi: 'No filtrar' })
      return mesclades
    },
    getMescladesReservades: state => {
      let mesclades_reservades = JSON.parse(JSON.stringify(state.mesclades_reservades))
      return mesclades_reservades
    },
    getMescladesReservadesCap: state => {
      let mesclades_reservades = JSON.parse(JSON.stringify(state.mesclades_reservades))
      mesclades_reservades.unshift({ id: null, codi: 'No filtrar' })
      return mesclades_reservades
    },
    getMescladesEnCua: state => state.mesclades.filter(mesclada => mesclada.estat === 'c'),
    getMescladesExecusio: state => state.mescladesExecusio,
    getMescladesNoAcabades: state => state.mescladesNoAcabades,
    getMescladesNoAcabadesPaginacio: state => { return Math.ceil((state.mescladesNoAcabades_count) / 20) },
    getMescladesInterrumpidas: state => state.mescladesInterrumpidas,
    getMescladesPaginacio: state => { return Math.ceil((state.mesclades_count-state.partides_count) / 20) },
    getOrdresEixida: state => state.ordres_eixida,
    getOrdresEixidaFiltrades: state => state.ordres_eixida,
    getOrdresEixidaPaginacio: state => state.ordres_eixida_pagines,
    getPartides: state => state.partides,
    getPartidesTodas: state => state.partides,
    getPartidesSimples: state => state.partides,
    getPartidesReservades: state => state.partides,
    getPartidesTodasSinAcabar: state => state.partides,
    getPartidesCap: state => {
      let partides = JSON.parse(JSON.stringify(state.partides))
      partides.unshift({ id: null, codi: 'No filtrar' })
      return partides
    },
    getPartidesCount: state => state.partides_count,
    getPartidesPaginacio: state => { return Math.ceil(state.partides_count / 20) },
    getPlantilles: state => state.plantilles,
    getPlantillesCap: state => {
      let plantilles = JSON.parse(JSON.stringify(state.plantilles))
      plantilles.unshift({ id: null, nom: 'No filtrar' })
      return plantilles
    },
    getPlantillesPaginacio: state => { return Math.ceil(state.plantilles.length / 20) },
    getPrefixos: state => state.prefixos,
    getProveidors: state => state.proveidors,
    getQuartosMezclando: state => state.quartos_mezclando,
    getTipusMateria: state => state.tipus_materies_primeres,
    getProveidorsCap: state => {
      let proveidors = JSON.parse(JSON.stringify(state.proveidors))
      proveidors.unshift({ id: null, nom: 'No filtrar' })
      return proveidors
    },
    getReserves: state => state.reserves,
    getReservesPaginacio: state => { return Math.ceil(state.reserves_count / 20) },
  },
  mutations: {
    LOGIN(state, data) {
      state.auth.jwt = data
    },
    USERGROUP(state, data){
      state.auth.grupo = data;
    },
    REFRESH_TOKEN(state, data){
      state.auth.jwt = data
    },
    LOAD_INITIAL_DATA(state) {
      state.auth.nom = localStorage.getItem('nom')
      state.auth.jwt = localStorage.getItem('jwt')
      state.auth.user_id = localStorage.getItem('user_id')
    },
    // Bales
    GET_BALES(state, data) {
      state.bales = data.result
      state.bales_count = data.count
      state.bales_pagines = Math.ceil(data.count / 20)
    },
    GET_BALES_ASSIGNADES(state, data) {
      state.bales = data.result
      state.bales_count = data.count
      state.bales_pagines = Math.ceil(data.count / 20)
    },
    // Borres
    GET_BORRES(state, data) {
      state.borres = data.result
      state.borres_count = data.count
      state.borres_pagines = Math.ceil(data.count / 20)
    },
    
    // Clients
    GET_CLIENTS(state, data) {
      state.clients = data
    },
    // Entrades
    GET_ENTRADES(state, data) {
      state.entrades = data.result
      state.entrades_count = data.count
    },
    GET_MAQUINAS(state, data){
      state.maquinas = data.results
    },
    GET_INFORMES(state, data){
      state.informes = data.results
      state.informes_pagines = Math.ceil(data.count / 20)
    },
    // Fàbriques
    GET_FABRIQUES(state, data) {
      state.fabriques = data.results
      state.fabriques_count = data.count
    },
    // Ingredients
    GET_INGREDIENTS(state, data) {
      state.ingredients = data
    },
    // Magatzems
    GET_MAGATZEMS(state, data) {
      state.magatzems = data
    },
    // Materies Primeres
    GET_MATERIES_PRIMERES(state, data) {
      state.materiesPrimeres = data
    },
    GET_MATERIES_PRIMERES_NOMS_SENSE_DUPLICAR(state, data) {
      state.materiesPrimeresNomsSenseDuplicar = data
    },
    // Materies Producte Final
    GET_MATERIES_PRODUCTE_FINAL(state, data) {
      state.materiesProducteFinal = data.result
      state.materiesProducteFinal_count = data.count
    },
    // Mesclades
    GET_MESCLADES(state, data) {
      state.mesclades = data.result
      state.mesclades_count = data.count
    },
    GET_MESCLADES_RESERVADES(state, data) {
      state.mesclades_reservades = data.result
    },
    GET_MESCLADES_EXECUSIO(state, data){
      state.mescladesExecusio = data.result
    },
    GET_MESCLADES_NO_ACABADES(state, data){
      state.mescladesNoAcabades = data.result
      state.mescladesNoAcabades_count = data.count
    },
    GET_MESCLADES_INTERRUMPIDAS(state, data){
      state.mescladesInterrumpidas = data.result
    },
    MEZCLAR_BALA(state, data){
      state.quartos_mezclando[data.num_maquina] = {maquina: data.num_maquina, 
        quarto: data.quarto, 
        mesclada: data.mesclada}
    },
    BORRAR_QUARTO(state, data){
      delete state.quartos_mezclando[data];
    },
    // Ordres Eixida
    GET_ORDRES_EIXIDA(state, data) {
      state.ordres_eixida = data
      state.ordres_eixida_count = data.count
      state.ordres_eixida_pagines = Math.ceil(data.count / 20)
    },
    GET_ORDRES_EIXIDA_FILTRADES(state, data) {
      state.ordres_eixida = data.results
      state.ordres_eixida_count = data.count
      state.ordres_eixida_pagines = Math.ceil(data.count / 20)
    },
    // Partides
    GET_PARTIDES(state, data) {
      state.partides = data.result
      state.partides_count = data.count
    },
    // Plantilles
    GET_PLANTILLES(state, data) {
      state.plantilles = data
    },
    // Proveïdors
    GET_TIPOS(state, data) {
      state.tipus_materies_primeres = data
    },
    // Proveïdors
    GET_PROVEIDORS(state, data) {
      state.proveidors = data
    },
    // Reserves
    GET_RESERVES(state, data) {
      state.reserves = data.results
      state.reserves_count = data.count
    },
    GET_RESERVES_FILTRADES(state, data) {
      state.reserves = data.result
      state.reserves_count = data.count
    },
    // Auxiliars
    GET_PREFIXOS(state, data) {
      state.prefixos = data
    }
  },
  actions: {
    login({ commit }, data) {
      authAPI.login(data.username, data.password).then((response) => {
        if (response === 400)
          EventBus.$emit("Auth.Notificacio", {
            text: "Error en el Login. Comprova que el Usuari i Password són correctes.",
            color: "error"
          });
        else {
          localStorage.setItem('nom', data.username)
          this.state.auth.nom = data.username
          EventBus.$emit("Auth.Notificacio", {
            text: "Login correcte",
            color: "success"
          });
          commit('LOGIN', response)
          EventBus.$emit('dialogLogin',false);
          // router.push({ name: 'Home' })
          location.reload()
        }
      })
    },
    comprobar_token({commit}){
      if(!localStorage.getItem('jwt')) EventBus.$emit('dialogLogin',true);
      else
      authAPI.inspectToken(localStorage.getItem('jwt')).then(NewToken=> commit('REFRESH_TOKEN', NewToken))

    },
    refreshToken({commit}){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.refreshToken(localStorage.getItem('refresh')).then(NewToken=> commit('REFRESH_TOKEN', NewToken))
    },
    refreshToken_infinito({commit}){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      else{
        setInterval(function(){
          authAPI.refreshToken(localStorage.getItem('refresh')).then(NewToken=> commit('REFRESH_TOKEN', NewToken))
        },60000)
      }
      authAPI.refreshToken(localStorage.getItem('refresh')).then(NewToken=> commit('REFRESH_TOKEN', NewToken))
    },
    refreshToken_infinito_raul({commit}){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      else{
        setInterval(function(){
          authAPI.login('raul', 'raul_gib').then((response) => {
            if (response === 400)
              EventBus.$emit("Auth.Notificacio", {
                text: "Error en el Login. Comprova que el Usuari i Password són correctes.",
                color: "error"
              });
            else {
              localStorage.setItem('nom', 'raul')
              this.state.auth.nom = 'raul'
              EventBus.$emit('dialogLogin',false)
              commit('LOGIN', response)
            }
          })
        },90000)
        
      }
      authAPI.refreshToken(localStorage.getItem('refresh')).then(NewToken=> commit('REFRESH_TOKEN', NewToken))
    },
    refreshToken_infinito_ivan({commit}){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      else{
        setInterval(function(){
          authAPI.login('ivan', 'ivan_gib').then((response) => {
            if (response === 400)
              EventBus.$emit("Auth.Notificacio", {
                text: "Error en el Login. Comprova que el Usuari i Password són correctes.",
                color: "error"
              });
            else {
              localStorage.setItem('nom', 'ivan')
              this.state.auth.nom = 'ivan'
              EventBus.$emit('dialogLogin',false)
              commit('LOGIN', response)
            }
          })
        },90000)
        
      }
      authAPI.refreshToken(localStorage.getItem('refresh')).then(NewToken=> commit('REFRESH_TOKEN', NewToken))
    },
    load_initial_data({ commit }) {
      commit('LOAD_INITIAL_DATA')
    },
    // Bala
    editBala(state, bala) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editBala(bala).then(bala => bala)
    },
    eliminarBala(state, balaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBala(balaID)
        .then(response => response)
    },
    getBala(state, balaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBala(balaID)
    },
    userType({commit}, id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return} //to do - más de un grupo
      return authAPI.userType(id).then(response => commit('USERGROUP',response.groups[0].id))
    },
    getBalaParcial(state, barcode){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalaParcial(barcode).then(bala => bala)
    },
    modificarBalaParcial(state, bala){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.modificarBalaParcial(bala)
    },
    getBalaBarcode(state, balaBarcode) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalaBarcode(balaBarcode).then(bala => bala)
    },
    getBalesMesclada(state, mesclada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalesMesclada(mesclada).then(bales => bales)
    },
    getBales({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      if(localStorage.getItem('jwt')) authAPI.getBales().then(bales => 
        {commit('GET_BALES', bales)} )
    },
    getBalesPaginacio(state,{commit}){
      commit('GET_BALES_PAGINACIO',Math.ceil(state.bales_count / 20))
    },
    getBalesAssignades({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getBalesAssignades().then(bales => commit('GET_BALES', bales))
    },
    getBalesSenseMagatzem(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalesSenseMagatzem(data).then(bales => bales)
    },
    getBalesAssignadesFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getBalesAssignadesFiltrades(data).then(bales => commit('GET_BALES_ASSIGNADES', bales))
    },
    getBalesFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getBalesFiltrades(data).then(bales => commit('GET_BALES', bales))
    },
    getBalesFiltradesAsignades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getBalesFiltradesAsignades(data).then(bales => commit('GET_BALES_ASSIGNADES', bales))
    },
    getBalesMagatzemMateriaPrimera({ commit }, payload) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalesMagatzemMateriaPrimera(payload).then(magatzems => commit('GET_BALES', magatzems))
    },
    getBalesMagatzemProducteFinal({ commit }, payload) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalesMagatzemProducteFinal(payload).then(response => commit('GET_BORRES', {result: response.result, count: response.count}))
    },
    generarPegatinaBala(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarPegatinaBala(data).then(status => status)
    },
    imprimirPegatinaBala_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirPegatinaBala_new(data).then(status => status)
    },
    imprimirPegatinasMesclada_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirPegatinasMesclada_new(data).then(status => status)
    },
    generarPegatinasRepartidas(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarPegatinasRepartidas(data).then(status => status)
    },
    
    sendBala(state, bala) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createBala(bala).then(bala => {
        if (bala?.data?.id)
          {
          return bala.data}
        else
          return bala
      })
    },
    sendBalaAEntrada(state, bala) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createBala(bala).then(bala => {
        if (bala?.data?.id) 
        return bala.data
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala afegida a Entrada",
            color: "success"
          });
      })
    },
    // Bala Parcial
    crearBalaParcial(state, balaParcial) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createBalaParcial(balaParcial)
    },
    eliminarBalaParcial(state, balaParcialID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBalaParcial(balaParcialID)
        .then(response => response)
    },
    // Quarto Mesclat
    crearQuartosMesclat(state, quartos) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createQuartosMesclat(quartos).then(quarto => quarto)
    },
    eliminarQuartosMesclat(state,quarto_mesclat_id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarQuartosMesclat(quarto_mesclat_id)
    },
    eliminarBalaQuarto(state,bala){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBalaQuarto(bala).then(mesclada=>mesclada)
    },
    eliminarBalaMesclada(state,bala){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBalaMesclada(bala).then(mesclada=>mesclada)
    },
    eliminarBalaPartida(state,bala){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBalaPartida(bala).then(mesclada=>mesclada)
    },
    eliminarBalaQuartoPartida(state,bala){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBalaQuartoPartida(bala).then(mesclada=>mesclada)
    },
    getQuartosMesclat(state, of_id) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getQuartosMesclat(of_id).then(quartos => quartos)
    },
    getQuartoMesclat(state, id) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getQuartoMesclat(id).then(quarto => quarto)
    },
    // mezclar bala
    getMaquinas({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMaquinas().then(maquinas =>{ commit('GET_MAQUINAS', maquinas); return maquinas})
    },
    getInformes({commit},data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getInformes(data).then(informes =>{ commit('GET_INFORMES', informes); return informes})
    },
    getInforme(state, ID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getInforme(ID).then(response => response)
    },
    getMaquina(state, ID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMaquina(ID)
    },
    getMaquinaInfo(state,num_maquina){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMaquinaInfo(num_maquina).then(info => info)
    },
    sendMaquina({ commit }, maquina) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createMaquina(maquina).then(maquina => {
        if (maquina?.data?.id) {
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Maquina creada",
            color: "success"
          });
          authAPI.getMaquinas().then(maquinas => commit('GET_MAQUINAS', maquinas))
          return maquina.status
        }
        else
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Error en la creació de Máquina",
            color: "error"
          });
      })
    },
    editMaquina(state, maquina) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editMaquina(maquina).then(maquina => maquina)
    },
    eliminarMaquina({ commit }, maquinaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarMaquina(maquinaID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            authAPI.getMaquinas().then(maquines => commit('GET_MAQUINAS', maquines))
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    mezclarBala({commit},mezcla){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.mezclarBala(mezcla).then(response => {
        if(response.estado_preparacion_mezclada){var mezclaData = mezcla
        mezclaData.mesclada = response.estado_preparacion_mezclada.mezclada_id
        commit('MEZCLAR_BALA',mezcla)}
        return response})
    },
    setQuartosMezclando({commit},mezcla){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      commit('MEZCLAR_BALA', mezcla)
    },
    borrarQuartosMezclando({commit},mezcla){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      commit('BORRAR_QUARTO', mezcla)
    },
    procesarMezcladaCompleta(state,mezclada_data){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.procesarMezcladaCompleta(mezclada_data).then(response => response)
    },
    cancelarMezclaCuarto(state,mezclada_data){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.cancelarMezclaCuarto(mezclada_data).then(response => response)
    },
    afegirBalesBorraPartida(state,info){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.afegirBalesBorraPartida(info).then(response => response)
    },
    forzarLlenado(state,mezcla){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.forzarLlenado(mezcla).then(response => response)
    },
    finalizarEjecucionQuarto(state,mezcla){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.finalizarEjecucionQuarto(mezcla).then(response => response)
    },
    iniciarEjecucionQuarto(state,mezcla){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.iniciarEjecucionQuarto(mezcla).then(response => response)
    },
    // Bala Producte Final
    getBalaProducteFinalBarcode(state, balaBarcode) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalaProducteFinalBarcode(balaBarcode).then(bala => bala)
    },
    // Borra
    crearBalaProducteFinal(state,info){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createBalaProducteFinal(info).then(response => response)
    },
    editBalaProducteFinal(state,bala){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editBalaProducteFinal(bala).then(response => response)
    },
    imprimirPegatinaBalaBorra_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirPegatinaBalaBorra_new(data).then(status => status)
    },
    moureQuartoMesclat(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.moureQuartoMesclat(data).then(status => status)
    },
    generarPegatinaBalaBorra(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarPegatinaBalaBorra(data).then(status => status)
    },
    imprimirPegatinasPartida(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirPegatinasPartida(data).then(status => status)
    },
    imprimirPegatinasPartida_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirPegatinasPartida_new(data).then(status => status)
    },
    getBorra(state, borraID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBorra(borraID)
    },
    eliminarBorra(state, balaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarBorra(balaID)
        .then(response => response)
    },
    getBorres({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getBorres().then(borres => {commit('GET_BORRES', borres)})
    },
    getBorresFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBorresFiltrades(data).then(bales => {commit('GET_BORRES', bales);
      return bales})
    },
    getBorresPaginacio(state,{commit}){
      commit('GET_BORRES_PAGINACIO',Math.ceil(state.borres_count / 20))
    },
    // Client
    editClient(state, client) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editClient(client).then(client => client)
    },
    eliminarClient({ commit }, clientID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarClient(clientID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            authAPI.getClients().then(clients => commit('GET_CLIENTS', clients))
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    getClient(state, clientID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getClient(clientID)
    },
    getClients({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getClients().then(clients => commit('GET_CLIENTS', clients))
    },
    sendClient({ commit }, client) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createClient(client).then(client => {
        if (client?.data?.id) {
          EventBus.$emit("Client.Notificacio", {
            text: "Client creat",
            color: "success"
          });
          authAPI.getClients().then(clients => commit('GET_CLIENTS', clients))
          return client
        }
        else
          EventBus.$emit("Client.Notificacio", {
            text: "Error en la creació de Client",
            color: "error"
          });
      })
    },
    // Entrada
    editEntrada(state, entrada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editEntrada(entrada).then(entradaStatus => {
        return entradaStatus
      })
    },
    eliminarEntrada(state, entradaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarEntrada(entradaID)
        .then(response => response)
    },
    getEntrada(state, entradaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getEntrada(entradaID)
    },
    getEntrades({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getEntrades().then(entrades => commit('GET_ENTRADES', entrades))
    },
    getEntradesFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getEntradesFiltrades(data).then(entrades => commit('GET_ENTRADES', entrades))
    },
    generarPegatinaEntrada(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarPegatinaEntrada(data).then(status => status)
    },
    imprimirPegatinaEntrada_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirPegatinaEntrada_new(data).then(status => status)
    },
    sendEntrada(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createEntrada(data.entrada).then(response => response)
    },

    // Fàbriques
    editFabrica(state, fabrica) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editFabrica(fabrica).then(fabrica => fabrica)
    },
    eliminarFabrica({ commit }, fabricaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarFabrica(fabricaID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            authAPI.getFabriques().then(fabriques => commit('GET_FABRIQUES', fabriques))
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    getFabrica(state, fabricaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getFabrica(fabricaID)
    },
    getFabriques({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getFabriques().then(fabriques => commit('GET_FABRIQUES', fabriques))
    },
    sendFabrica({ commit }, fabrica) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createFabrica(fabrica).then(fabrica => {
        if (fabrica?.data?.id) {
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Fabrica creada",
            color: "success"
          });
          authAPI.getFabriques().then(fabriques => commit('GET_FABRIQUES', fabriques))
          return fabrica.status
        }
        else
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Error en la creació de fabrica",
            color: "error"
          });
      })
    },

    // Ingredients
    editIngredient(state, ingredient) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editIngredient(ingredient).then(ingredient => ingredient)
    },
    eliminarIngredient({ commit }, ingredientID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarIngredient(ingredientID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            authAPI.getIngredients().then(ingredients => commit('GET_INGREDIENTS', ingredients))
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    getIngredient(state, ingredientID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getIngredient(ingredientID)
    },
    getIngredients({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getIngredients().then(ingredients => commit('GET_INGREDIENTS', ingredients))
    },
    sendIngredient({ commit }, ingredient) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createIngredient(ingredient).then(ingredient => {
        if (ingredient?.data?.id) {
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Tipus de materia creat",
            color: "success"
          });
          authAPI.getIngredients().then(ingredients => commit('GET_INGREDIENTS', ingredients))
          return ingredient
        }
        else
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Error en la creació de tipus de materia",
            color: "error"
          });
      })
    },

    // Línia Ordre Eixida
    getLiniaOrdreEixida(state, liniaOrdreEixidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getLiniaOrdreEixida(liniaOrdreEixidaID).then(response=>response)
    },
    editLiniaOrdreEixida(state, liniaOrdreEixida) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editLiniaOrdreEixida(liniaOrdreEixida).then(liniaOrdreEixida => liniaOrdreEixida)
    },
    eliminarLiniaOrdreEixida(state, liniaOrdreEixidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarLiniaOrdreEixida(liniaOrdreEixidaID)
        .then(response => response)
    },
    addLiniaOrdreEixidaMP(state, liniaOrdreEixidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.addLiniaOrdreEixidaMP(liniaOrdreEixidaID)
    },
    sendLiniaOrdreEixida(state, liniaOrdreEixidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createLiniaOrdreEixida(liniaOrdreEixidaID)
    },

    // Magatzems
    generarPegatinaPosicio(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarPegatinaPosicio(data).then(status => status)
    },
    editMagatzem(state, magatzem) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editMagatzem(magatzem).then(magatzem => magatzem)
    },
    almacenarBalas(state,balas){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.almacenarBalas(balas).then(response => response)
    },
    desalmacenarBalas(state,balas){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.desalmacenarBalas(balas).then(response => response)
    },
    eliminarMagatzem({ commit }, magatzemID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarMagatzem(magatzemID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            authAPI.getMagatzems().then(magatzems => commit('GET_MAGATZEMS', magatzems))
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    eliminarPosicioMagatzem(state, posicioID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarPosicioMagatzem(posicioID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    getPosicioMagatzem(state, posicionID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPosicioMagatzem(posicionID)
    },
    getMagatzem(state, magatzemID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMagatzem(magatzemID)
    },
    getInformeMagatzems(state,fecha){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getInformeMagatzems(fecha)
    },
    getMagatzemNumero(state, magatzemNumero) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMagatzemNumero(magatzemNumero)
    },
    getMagatzemFiltre(state, filtre) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMagatzemFiltre(filtre)
    },
    getMagatzemMateriesPrimeres(state, magatzemID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMagatzemMateriesPrimeres(magatzemID)
    },
    getInformeMP(state, data){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getInformeMP(data)
    },
    getMagatzemProductesFinals(state, magatzemID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMagatzemProductesFinals(magatzemID)
    },
    getMagatzems({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMagatzems().then(magatzems => {commit('GET_MAGATZEMS', magatzems); return magatzems})
    },
    getStockMP(){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getStockMP().then( data => data )
    },
    getStockMP_filtrat(state, data){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getStockMP_filtrat(data).then( response => response )
    },
    getStockPF(){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getStockPF().then( data => data )
    },
    sendMagatzem({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createMagatzem(data).then(magatzem => {
        if (magatzem?.data?.id) {
          EventBus.$emit("Magatzem.Notificacio", {
            text: "Magatzem creat",
            color: "success"
          });
          authAPI.getMagatzems().then(magatzem => commit('GET_MAGATZEMS', magatzem))
          return magatzem
        }
        else {
          if (magatzem.nom)
            EventBus.$emit("Magatzem.Notificacio", {
              text: `Error en la creació del magatzem: ${magatzem.nom}`,
              color: "error"
            });
            if (magatzem.numero)
            EventBus.$emit("Magatzem.Notificacio", {
              text: `Error en la creació del magatzem: ${magatzem.numero}`,
              color: "error"
            });
        }
      })
    },
    createPosicioMagatzem(state,info) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createPosicioMagatzem(info).then(posicio => {
        if (posicio?.data?.id) {
          EventBus.$emit("Magatzem.Notificacio", {
            text: "Posicio creada",
            color: "success"
          });
          return posicio.data
        }
        else {
          if (posicio.numero)
            EventBus.$emit("Magatzem.Notificacio", {
              text: `Error en la creació de la posicio: ${posicio.numero}`,
              color: "error"
            });
          else
            EventBus.$emit("Magatzem.Notificacio", {
              text: "Error en la creació de la posicio",
              color: "error"
            });
        }
      })
    },
    // Materia Primera
    editMateriaPrimera(state, materiaPrimera) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editMateriaPrimera(materiaPrimera).then(materiaPrimera => materiaPrimera)
    },
    eliminarMateriaPrimera({ commit }, materiaPrimeraID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarMateriaPrimera(materiaPrimeraID)
        .then(responseStatus => {
          if (responseStatus === 204) {
            authAPI.getMateriesPrimeres().then(materiaPrimera => commit('GET_MATERIES_PRIMERES', materiaPrimera))
            return responseStatus
          }
          else
            return 'Error'
        })
    },
    getMateriaPrimera(state, materiaPrimeraID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMateriaPrimera(materiaPrimeraID)
    },
    getMateriesPrimeres({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMateriesPrimeres()
      .then(materiesPrimeres => commit('GET_MATERIES_PRIMERES', materiesPrimeres))
    },
    getMateriesPrimeresFiltrades({ commit },filtre) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMateriesPrimeresFiltrades(filtre)
      .then(materiesPrimeres => {commit('GET_MATERIES_PRIMERES', materiesPrimeres); return materiesPrimeres})
    },
    getMateriesPrimeresNomsSenseDuplicar({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMateriesPrimeresNomsSenseDuplicar().then(materiesPrimeresNomsSenseDuplicar => {commit('GET_MATERIES_PRIMERES_NOMS_SENSE_DUPLICAR', materiesPrimeresNomsSenseDuplicar); return materiesPrimeresNomsSenseDuplicar})
    },
    sendMateriaPrimera({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createMateriaPrimera(data).then(materia_primera => {
        if (materia_primera?.data?.id) {
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: "Materia Primera creada",
            color: "success"
          });
          authAPI.getMateriesPrimeres().then(materiesPrimeres => commit('GET_MATERIES_PRIMERES', materiesPrimeres))
          return materia_primera
        }
        else
          if(materia_primera.nom)
          {EventBus.$emit("Proveidor.Notificacio", {
            text: `Error en la creació de la Materia Primera: ${materia_primera.nom}`,
            color: "error"
          });}else{
            EventBus.$emit("Proveidor.Notificacio", {
              text: `Error en la creació de la Materia Primera`,
              color: "error"
          })}
      })
    },
    // Materia Producte final - Tipus de borra
    editMateriaProducteFinal(state, materiaProducteFinal) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editMateriaProducteFinal(materiaProducteFinal).then(materiaProducteFinal => materiaProducteFinal)
    },
    eliminarMateriaProducteFinal(state, materiaProducteFinalID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarMateriaProducteFinal(materiaProducteFinalID)
        .then(response => response)
    },
    getMateriaProducteFinal(state, materiaProducteFinalID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMateriaProducteFinal(materiaProducteFinalID).then(materiaProducteFinal => materiaProducteFinal)
    },
    getMateriesProducteFinal({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMateriesProducteFinal().then(materiesProducteFinal => commit('GET_MATERIES_PRODUCTE_FINAL', materiesProducteFinal))
    },
    sendMateriaProducteFinal({ commit }, materiaProducteFinal) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createMateriesProducteFinal(materiaProducteFinal).then(materiaProducteFinal => {
        if (materiaProducteFinal?.data?.id) {
          EventBus.$emit("MateriaProducteFinal.Notificacio", {
            text: "Producte Final creat",
            color: "success"
          });
          authAPI.getMateriesProducteFinal().then(materiesProducteFinal => commit('GET_MATERIES_PRODUCTE_FINAL', materiesProducteFinal))
          return materiaProducteFinal
        }
        else
          EventBus.$emit("MateriaProducteFinal.Notificacio", {
            text: "Error en la creació del Producte Final",
            color: "error"
          });
      })
    },
    // Mesclada
    editMesclada(state, mesclada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editMesclada(mesclada).then(mescladaStatus => {
        return mescladaStatus
      })
    },
    editPartida(state, mesclada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editPartida(mesclada).then(mescladaStatus => {
        return mescladaStatus
      })
    },
    addBalaMesclada(state,mesclada){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.addBalaMesclada(mesclada).then(response => response)
    },
    addBalaPartida(state,mesclada){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.addBalaPartida(mesclada).then(response => response)
    },
    encolarMesclada(state, mesclada_id) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.encolarMesclada(mesclada_id).then(response => response)
    },
    desencolarMesclada(state, mesclada_id) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.desencolarMesclada(mesclada_id).then(response => response)
    },
    editPrefix(state, mesclada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editPrefix(mesclada).then(response => response)
    },
    eliminarMesclada(state, mescladaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarMesclada(mescladaID)
        .then(response => response)
    },
    getMesclada(state, mescladaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMesclada(mescladaID)
    },
    getPartida_new(state, mescladaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartida_new(mescladaID)
    },
    getMesclada_new(state, mescladaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMesclada_new(mescladaID)
    },
    getEstadoMesclada(state,mescladaID){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getEstadoMesclada(mescladaID).then(estado=>estado)
    },
    getMesclades({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMesclades().then(mesclades => commit('GET_MESCLADES', mesclades))
    },
    getMescladesFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMescladesFiltrades(data)
      .then(mesclades => commit('GET_MESCLADES', mesclades))
      .then(mesclades => mesclades)
    },
    getMescladesExecusio({commit}) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMescladesExecusio().then(mesclades => commit('GET_MESCLADES_EXECUSIO', mesclades))
    },
    getMescladesNoAcabades({commit},data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getMescladesNoAcabades(data)
      .then(mesclades => {commit('GET_MESCLADES_NO_ACABADES', mesclades); return mesclades})

    },
    getMescladesInterrumpidas({commit},data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMescladesInterrumpidas(data).then(mesclades => commit('GET_MESCLADES_INTERRUMPIDAS', mesclades))
    },
    getMescladesReservades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getMescladesReservades(data).then(mesclades => commit('GET_MESCLADES_RESERVADES', mesclades))
    },
    sendMesclada(state, mesclada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createMesclada(mesclada).then(mesclada => {
        if (mesclada?.data?.ordre_fabricacio)
          {return mesclada}
        else
          return mesclada
      })
    },
    interrumpirPartida(state, partida){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.interrumpirPartida(partida)
    },
    reanudarPartida(state, id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.reanudarMesclada(id)
    },
    finalizarCuarto(state, id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.finalizarCuarto(id)
    },
    // Ordre Eixida
    generarAlbaran(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarAlbaran(data).then(status => status)
    },
    imprimirAlbaran_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirAlbaran_new(data).then(status => status)
    },
    enviarAlbaran_mail(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.enviarAlbaran_mail(data).then(status => status)
    },
    generarInformePartida(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.generarInformePartida(data).then(status => status)
    },
    imprimirInformePartida_new(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.imprimirInformePartida_new(data).then(status => status)
    },
    editOrdreEixida(state, mesclada) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editOrdreEixida(mesclada)
    },
    eliminarOrdreEixida(state, ordreEixidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarOrdreEixida(ordreEixidaID)
        .then(response => response)
    },
    getOrdreEixida(state, ordreEixidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getOrdreEixida(ordreEixidaID)
    },
    getOrdresEixida({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getOrdresEixida().then(ordresEixida => {commit('GET_ORDRES_EIXIDA', ordresEixida); return ordresEixida})
      
    },
    getOrdresEixidaFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getOrdresEixidaFiltrades(data).then(ordresEixida =>{ commit('GET_ORDRES_EIXIDA_FILTRADES', ordresEixida); return ordresEixida})
    },
    // ordreEixidaAfegeixBales(state, data) {
    //   authAPI.ordreEixidaAfegeixBales(data).then(response => {
    //     if (response.status === "ordre_eixida_add_bales_error") {
    //       EventBus.$emit("OrdreEixida.Notificacio", {
    //         text: response.message,
    //         color: "error"
    //       });
    //     }

    //   });
    // },
    // ordreEixidaEliminaBales(state, data) {
    //   authAPI.ordreEixidaEliminaBales(data).then(response => {
    //     if (response.status === "ordre_eixida_remove_bales_error") {
    //       EventBus.$emit("OrdreEixida.Notificacio", {
    //         text: response.message,
    //         color: "error"
    //       });
    //     }

    //   });
    // },
    sendOrdreEixida(state, ordreEixida) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createOrdreEixida(ordreEixida).then(ordreEixida => {
        if (ordreEixida?.data?.status === 'ordre_eixida_creada') {
          return ordreEixida
        }
        else
          EventBus.$emit("Proveidor.Notificacio", {
            text: ordreEixida.message,
            color: "error"
          });
      })
    },
    completarOrdreEixida(state,id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.completarOrdreEixida(id).then(response => response)
    },
    desCompletarOrdreEixida(state,id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.desCompletarOrdreEixida(id).then(response => response)
    },
    expedirOrdreEixida(state,id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.expedirOrdreEixida(id)
    },
    desexpedirOrdreEixida(state,id){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.desexpedirOrdreEixida(id)
    },
    asignarPFAOrdreEixida(state,balas){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.asignarPFAOrdreEixida(balas)
    },
    asignarMPAOrdreEixida(state,balas){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.asignarMPAOrdreEixida(balas)
    },
    desasignarAOrdreEixida(state,balas){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.desasignarAOrdreEixida(balas)
    },
    desasignarBalasOrdreEixida(state,balas){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.desasignarBalasOrdreEixida(balas).then(response => {return response})
    },
    // Partida
    getPartida(state, mescladaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartida(mescladaID)
    },
    getBalesPartida(state, mescladaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getBalesPartida(mescladaID)
    },
    getPartidaStocks(state, partidaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidaStocks(partidaID)
    },
    
    getPartides({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartides().then(partides => {commit('GET_PARTIDES', partides); return partides})
      
    },
    getPartidesTodas({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidesTodas().then(partides => {commit('GET_PARTIDES', partides); return partides})
      
    },
    getPartidesSimples({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidesSimples().then(partides => {commit('GET_PARTIDES', partides); return partides})
      
    },
    getPartidesReservades({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidesReservades().then(partides => {commit('GET_PARTIDES', partides); return partides})
      
    },
    getPartidesTodasSinAcabar({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidesTodasSinAcabar().then(partides => {commit('GET_PARTIDES', partides); return partides})
      
    },
    getPartidesTodasDispo({ commit },data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidesTodasDispo(data).then(partides => {commit('GET_PARTIDES', partides); return partides})
      
    },
    getPartidesFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPartidesFiltrades(data).then(partides => {commit('GET_PARTIDES', partides); return partides;})
      // return authAPI.getPartidesFiltrades(data)
    },
    iniciarPartidaSalida(state, info){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.iniciarPartidaSalida(info)
    },

    // Percentatge Mesclat
    sendPercentatgeMesclat(state, percentatgeMesclat) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createPercentatgeMesclat(percentatgeMesclat)
    },
    editPercentatgeMesclat(state, percentatgeMesclat) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editPercentatgeMesclat(percentatgeMesclat).then(percentatgeMesclatStatus => {
        return percentatgeMesclatStatus
      })
    },
    eliminarPercentatgeMesclat(state, percentatgeMesclatID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarPercentatgeMesclat(percentatgeMesclatID)
        .then(response => response)
    },

    // Plantilla
    editPlantilla(state, plantilla) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editPlantilla(plantilla).then(plantillaStatus => {
        return plantillaStatus
      })
    },
    eliminarPlantilla(state, plantillaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarPlantilla(plantillaID)
        .then(response => response)
    },
    getPlantilla(state, plantillaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPlantilla(plantillaID)
    },
    getPlantilles({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getPlantilles().then(plantilles => commit('GET_PLANTILLES', plantilles))
    },
    sendPlantilla(state, plantilla) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createPlantilla(plantilla).then(plantilla => {
        if (plantilla?.data?.status === 'plantilla_creada') {
          router.push({ name: 'PlantillaDetail', params: { id: plantilla.data.plantilla.id } })
        }
      })
    },
    //Tipus materia primera
    
    getInfoTipusMateria(state, tipusMP_ID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getInfoTipusMateria(tipusMP_ID)
    },
    getTipusMateria({commit}){
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getTipusMateria().then(tipos => commit('GET_TIPOS', tipos))
    },
    editTipusMateria(state, tipusMP) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editTipusMateria(tipusMP).then(MPStatus => {
        return MPStatus
      })
    },
    eliminarTipusMateria({ commit }, tipusMP_ID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarTipusMateria(tipusMP_ID)
        .then(response => {
          if (response?.status === 204) {
            authAPI.getTipusMateria().then(tipos => commit('GET_TIPOS', tipos))
            return response.status
          }
          else
            return response
        })
    },
    sendTipusMateria({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createTipusMateria(data).then(tipusMP => {
        if (tipusMP?.data?.id) {
          EventBus.$emit("Proveidor.Notificacio", {
            text: "TIPUS DE MATERIA creat",
            color: "success"
          });
          authAPI.getTipusMateria().then(tipos => commit('GET_TIPOS', tipos))
          return tipusMP
        }
        else
          EventBus.$emit("Proveidor.Notificacio", {
            text: "Error en la creació del TIPUS DE MATERIA",
            color: "error"
          });
      })
    },
    // Proveïdors
    editProveidor(state, proveidor) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editProveidor(proveidor).then(proveidorStatus => {
        return proveidorStatus
      })
    },
    eliminarProveidor({ commit }, proveidorID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarProveidor(proveidorID)
        .then(response => {
          if (response?.status === 204) {
            authAPI.getProveidors().then(proveidors => commit('GET_PROVEIDORS', proveidors))
            return response.status
          }
          else
            return response
        })
    },
    getProveidor(state, proveidorID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getProveidor(proveidorID)
    },
    getProveidors({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getProveidors().then(proveidors => commit('GET_PROVEIDORS', proveidors))
    },
    getProveidorsFiltrats({ commit },stock) {
      
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getProveidorsFiltrats(stock).then(proveidors => commit('GET_PROVEIDORS', proveidors))
    },
    sendProveidor({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createProveidor(data).then(proveidor => {
        if (proveidor?.data?.id) {
          EventBus.$emit("Proveidor.Notificacio", {
            text: "Proveidor creat",
            color: "success"
          });
          authAPI.getProveidors().then(proveidors => commit('GET_PROVEIDORS', proveidors))
          return proveidor
        }
        else
        if(proveidor.nom)
          {EventBus.$emit("Proveidor.Notificacio", {
            text: `Error en la creació del proveïdor ${proveidor.nom}`,
            color: "error"
          });}
        if(proveidor.poblacio)
          {EventBus.$emit("Proveidor.Notificacio", {
            text: `Error en la creació del proveïdor ${proveidor.poblacio}`,
            color: "error"
          });}
      })
    },

    // Reserves
    // SON EDNPOINTS VIEJOS
    // editReservaModificaOF(state, data) {
    //   if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
    //   return authAPI.iniciarMesclada(data)
    //     .then(() => authAPI.iniciarPartida(data))
    //     .then(() => authAPI.editReserva(data).then(reserva => reserva))
    // },
    editReserva(state, reserva) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.editReserva(reserva).then(reservaStatus => reservaStatus)
    },
    incrementarReserva(state, reserva) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.incrementarReserva(reserva)
        .then(response => response)
    },
    reducirReserva(state, reserva) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.reducirReserva(reserva)
        .then(response => response)
    },
    anularReserva(state, reserva) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.anularReserva(reserva).then(response => response)
    },
    finalizarReserva(state, reserva) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.finalizarReserva(reserva)
        .then(response => response)
    },
    eliminarReserva(state, reservaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.eliminarReserva(reservaID)
        .then(response => response)
    },
    getReserva(state, reservaID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getReserva(reservaID)
    },
    getReserves({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      authAPI.getReserves().then(reserves => commit('GET_RESERVES', reserves))
    },
    getEstadoReserva(state, partida) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getEstadoReserva(partida).then(response => response)
    },
    getReservesFiltrades({ commit }, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      // authAPI.getReservesFiltrades(data).then(reserves => commit('GET_RESERVES_FILTRADES', reserves))
      return authAPI.getReservesFiltrades(data).then(reserves => {commit('GET_RESERVES_FILTRADES', reserves); return reserves;})
    },
    getReservesPerClient(state, clientID) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getReservesPerClient(clientID)
    },

    sendReserva(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      // TODO - Els mètodes iniciarMesclada i iniciarPartida són provisionasl fins que l'usuari puga escanejar bales
      return authAPI.createReserva(data).then(reserva => {
        if (reserva?.status == 201) {
          EventBus.$emit("Reserva.Notificacio", {
            text: "Reserva creada",
            color: "success"
          });
          router.push({ name: 'ReservaDetail', params: { id: reserva.data.id } })
        }
        else
          EventBus.$emit("Reserva.Notificacio", {
            text: reserva,
            color: "error"
          });
      })
    },

    // Auxiliars
    getPrefixos({ commit }) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getPrefixos().then(prefixos => {commit('GET_PREFIXOS', prefixos); return prefixos;})
    },
    getSecuencia(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getSecuencia(data).then(secuencia => {return secuencia})
    },
    getLastValues(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.getLastValues(data).then(secuencia => {return secuencia})
    },
    sendGap(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.createGap(data).then(response => {
        if (response?.data?.id)
          {
          return response.data}
        else
          return response
      })
    },
    resetGap(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.resetGap(data).then(response => {
        if (response?.data)
          {
          return response.data}
        else
          return response
      })
    },
    resetearContador(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.resetearContador(data).then(response => {
        if (response?.data)
          {
          return response.data}
        else
          return response
      })
    },
    cambiarContadorPartida(state, data) {
      if(!localStorage.getItem('jwt')) {EventBus.$emit('dialogLogin',true); return}
      return authAPI.cambiarContadorPartida(data).then(response => {
        if (response?.data)
          {
          return response.data}
        else
          return response
      })
    },
  },
  modules: {
  }
})
