<template>
  <v-container>
    <!-- Llistat Bales -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1>Llistat de Bales Producte Final</h1>
        </td>
        <td v-if="grupo!=2 && grupo!=3 && grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="2">
          <v-text-field v-model="filtre.quilos" 
            outlined
            rounded
            label="Quilos" @input="filtrar()" required></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.estat"
            :items="estatOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Estat"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.tipus_borra"
            :items="materiesProducteFinalOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Producte final"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.posicion_almacenamiento"
            :items="OpcionesSubalmacenes"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="barcode"
            label="Magatzem"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.partida"
            :items="partides"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="codi"
            item-value="id"
            label="Partida"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="filtre.creada_en"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="filtre.creada_en" 
                outlined
                rounded
                clearable
                @click:clear="datepickerDelete()"
                label="Creada en" readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filtre.creada_en" locale="ca" :first-day-of-week="1" scrollable @click:date="datepicker()">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(bala, index) in bales" :key="index" :class="{'bala-seleccionat': bala.estat==2,'bala-consumida': bala.estat=='i','bala-expedida': bala.estat=='e', 'bala-pendent': bala.estat=='a'} ">
          <td @click="balaDetail(bala.id)" class="text-bala"> <strong> {{textNomBala(bala)}} </strong> {{ textBala(bala) }}</td>
          <td>
          <v-btn
              v-if="bala.magatzem"
              @click="quitarAlmacenBala(bala)"
            >Traure magatzem</v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Bales -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="bales.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";


export default {
  name: "bales-borra-llistat",
  data: () => ({
    OpcionesSubalmacenes:[],
    filtre: {
      creada_en: "",
      tipus_borra: null,
      magatzem: null,
      posicion_almacenamiento:null,
      fabrica: null,
      partida: null,
      quilos: "",
      pagina: null
    },
    estatOpcions: [
      { id: "c", nom: "Creada" },
      { id: "m", nom: "Almacenada" },
      { id: "a", nom: "Asignada a OE" },
      { id: "e", nom: "Expedida" }
    ],

    paginasAñadidas:[],
    json_fields: {
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        MAGATZEM: "magatzem_nom",
        QUILOS: "quilos", 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        ESTAT: {
          field: "estat",
          callback: value => (value=="e" ? "Expedida" : "Creada")
        },
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        FABRICA: "fabrica", 
        QUARTO: "quarto_nom",
        PRODUCTE_FINAL: "borra_nom",
        DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales_ProducteFinal.xls",
    
    modalData: false,
    balesFiltrades: [],
    pagina: 1,
    carregaInicial: false
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      bales: "getBorres",
      totalPagines: "getBorresPaginacio",
      magatzemOpcions: "getMagatzems",
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      grupo: "getGroup",
      partides: "getPartidesTodas"
    })
  },
  methods: {
    ...mapActions([
      "getBorres",
      "getPartides",
      "getBorresFiltrades",
      "getMagatzems",
      "getPartidesTodas",
      "getMateriesProducteFinal",
      "desalmacenarBalas"
    ]),
    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      this.getBorresFiltrades(this.filtre);
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      this.getBorresFiltrades(this.filtre);
    },

    //Actions 
    quitarAlmacenBala(bala){
      if(bala.barcode.charAt(0) == "0") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_materia_prima: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
           //this.$router.go()
          }else{
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      if(bala.barcode.charAt(0) == "1") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_borra: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){

            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
            
          }else{

            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      
    },
    // Rerouting
    balaDetail(balaID) {
      this.$router.push({ name: "PartidaBalaDetail", params: { id: balaID, list:true } });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    getExcelData(){
      this.excelArray.forEach(el => {
        el.quarto_nom = el.cuarto;
        el.borra_nom = this.textProducteFinal(el.tipus_borra);
        el.magatzem_nom = this.textMagatzem(parseInt(el.posicion_almacenamiento.substr(1, 3)))
        })  

      
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.bales); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Auxiliars
    datepicker() {
      this.$refs.dialog.save(this.filtre.creada_en);
      this.modalData = false;
      this.filtrar();
    },

    datepickerDelete() {
      this.$refs.dialog.save((this.filtre.creada_en = ""));
      this.modalData = false;
      this.filtrar();
    },
    textProducteFinal(ID) {
      const producteFinal = this.materiesProducteFinalOpcions.find(
        el => el.id === ID
      );
      return producteFinal?.nom;
    },
    textMagatzem(magatzemNum) {
      const magatzem = this.magatzemOpcions?.find(el => el.numero === magatzemNum);
      if(magatzem?.nom){
        return magatzem?.nom;
        }
      else{
        return "Sense magatzem"
      }
    },
    textNomBala(bala){
      const textPF = this.textProducteFinal(bala.tipus_borra);
      return textPF + " [" + bala.codi_partida +"]"
    },
    textBala(bala) {
      // const textPF = this.textProducteFinal(bala.tipus_borra);
      let textMagatzem;
      if (bala?.magatzem) {
        textMagatzem = this.magatzemOpcions.find(el => el.numero === 
        parseInt(bala.posicion_almacenamiento.substr(1, 3))
        )
          ?.nom;
      }
      if (textMagatzem === undefined) textMagatzem = "Sense magatzem";

      return `- [${
        bala.quilos
      //} Kg] - ${textMagatzem} - ${bala.creada_en.split("T")[0]}`;
      } Kg] - ${textMagatzem} - ${bala.barcode} - ${bala.creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}`;
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return materiaPrimera?.nom;
    },
    textPartida(partidaID) {
      return this.partides.find(el => el.id === partidaID)?.codi.replaceAll('.', '');
    }
  },
  created() {
    this.getBorresFiltrades(this.filtre);
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesProducteFinal();
    this.getPartidesTodas();
  },
  // watch: {
  //   bales: function(bales) {
  //     // TODO - Fix horrible, s'ha de vore com fer que carregue inicialment les bales en bales filtrades
  //     if (!this.carregaInicial) {
  //       this.balesFiltrades = bales;
  //       this.carregaInicial = true;
  //     }

  //     // Quan modifiquem bales (mitjançant petició de filtrat), actualitzem bales i paginació
  //     this.balesFiltrades = this.bales;
  //   }
  // }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.bala-seleccionat {
      background: rgb(241, 241, 153) ;
}
.bala-pendent {
      background: rgb(153, 188, 241) ;
}
.bala-consumida {
      background: rgb(241, 171, 153) ;
}
.bala-expedida {
      background: rgb(241, 171, 153) ;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>