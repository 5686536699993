<template>
  <v-container>
    <!-- Llistat Materies Primeres -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="6" offset-sm="3">
            <h1>Llistat de Bales de Borra</h1>
            <h1>Organitzades per magatzems</h1>
          </v-col>
        <v-col sm="2">
            <v-btn @click="dialogInforme=true">Crear Informe </v-btn>
            <v-dialog v-model="dialogInforme" persistent max-width="850">
              <v-card style="padding: 2%">
              <v-card-title class="headline">INFORME DE STOCK</v-card-title>
                <v-dialog
                ref="dialog"
                v-model="modalData"
                :return-value.sync="informe.data"
                persistent
                width="280px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="informe.data" 
                  rounded
                  outlined
                  label="Data Informe" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="informe.data" locale="ca" :first-day-of-week="1" scrollable @click:date="datepicker()">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
                  <v-btn text color="primary" @click="datepicker()">OK</v-btn>
                </v-date-picker>
              </v-dialog>
              <v-row v-if="informe.productes_finals">
              <v-col v-for="(materia, index) in informe.productes_finals" :key="index" sm="6">
               <strong> {{index}} : </strong> {{materia.num_bales}} bales - {{materia.quilos}} kg</v-col>
              <!-- <v-col sm="6">{{ magatzem.balas}} bales disponibles</v-col> -->
            </v-row>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  tabindex="-1"
                  @click="cerrarInforme"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  id="generarExcel"
                  ref="buttonGetExcelData"
                  @click="getExcelData"
                  tabindex="-1"
                >Descarregar</v-btn>
                <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel"
                class="btn"
                :name="nombreExcel"
                :data="excelArray"
                :fields="json_fields"
                worksheet = "Pàgina 1"
                type="xls"
              ></downloadexcel>
              </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-if="num_bales_senseMagatzem>0">
          <td
            class="text-instancia"
            @click="magatzemList('Sense')"
            style="background-color: coral;"
          >
            <v-row>
              <v-col sm="12"> {{ num_bales_senseMagatzem}} bales <strong>Sense magatzem</strong></v-col>
              <!-- <v-col sm="6">{{ num_bales_senseMagat}} bales </v-col> -->
            </v-row>
          </td>
        </tr>
        <tr v-for="magatzem in magatzems" :key="magatzem.id">
          <td
            class="text-instancia"
            @click="magatzemList(magatzem.id)"
            v-if="magatzem.balas > 0"
          >
            <v-row>
              <v-col sm="6" style="text-align: left; padding-left:15%"><strong>{{ magatzem.nom }}</strong></v-col>
              <v-col sm="6">{{ magatzem.balas}} bales</v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Materia Primera -->

    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import {mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "stock-borra-llistat1",
  data: () => ({
    num_bales_senseMagatzem:"",
    pagina: 1,
    totalPagines: 1, 
    magatzems:[],
    informe:{productes_finals:"", data:""},
    json_fields: {
        NOM_PRODUCTE: "nom",
        MAGATZEM: "magatzem",
        ZONA: "zona",
        NUM_BALES: "num_bales", 
        QUILOS: "quilos", 
        COST_REAL: "coste_real",
        COST_MIG: "coste_medio",
      },
    excelArray: [],
    nombreExcel: "Informe_stock",
    modalData:false,
    dialogInforme:false
  }),
  // computed: {
  //   ...mapGetters({
  //     magatzems: "getMagatzems"
  //   })
  // },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions(["getMagatzems", "getStockPF","getInformeMagatzems", 
    "getBorresFiltrades"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },
    datepicker() {
      this.$refs.dialog.save(this.informe.data);
      this.getInformeMagatzems(this.informe.data).then(
        results => {this.informe.productes_finals=results.productes_finals;
        for (var producte in results.productes_finals) this.excelArray.push({nom: producte, num_bales: results.productes_finals[producte].num_bales,
        quilos: results.productes_finals[producte].quilos })}
      )
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.informe.data = ""));
      this.modalData = false;
    },
    //Excel
    getExcelData(){
      // this.excelArray = this.informe.productes_finals
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      this.nombreExcel = 'Llistat_Stock_'+this.borra_nom+'_'+this.magatzem.nom;
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.borres); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Rerouting
    magatzemList(magatzemID) {
      this.$router.push({
        name: "StockBorraMagatzemBorraList",
        params: { magatzemID: magatzemID}
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
   
    cerrarInforme(){
      this.dialogInforme = false
      this.informe = {} 
    }
  },
  created() {
    this.getBorresFiltrades({pagina:1, estat: "c", magatzem: "sense"}).then(
      response=>{
          this.num_bales_senseMagatzem = response.count
      }
    )
    this.getStockPF().then(
      data=> {
        for (const prop in data) {
          var balas_magatzem = 0;
          var barcode = "";
        for( const prop2 in data[prop].posicions){
          data[prop].posicions[prop2].tipus_borra.forEach(borra=>
         {
           this.excelArray.push({nom: borra.tipo_borra, num_bales: borra.num_bales,
           coste_medio: borra.coste_medio,
            coste_real: borra.coste_real,
            quilos: borra.quilos, 
            magatzem: data[prop].nom,
            zona: data[prop].posicions[prop2].zona })
            barcode = prop2;
          balas_magatzem+=borra.num_bales;})
          this.magatzems.push({id: barcode, nom:data[prop].nom +
          ' - Nº: '+  data[prop].posicions[prop2].zona, balas:balas_magatzem})
          balas_magatzem = 0;
        }
         
        }
      }
    );
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>