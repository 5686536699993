<template>
  <v-container>
    <!-- Ordre Eixida -->
    <v-form ref="form" class="mt-12">
      <h1>Modificar albarán</h1>
      <!-- Client -->
      <v-row>
        <v-col sm="12">
          <v-simple-table>
                      <tbody>
                        <tr>
                          <td>
                            <v-btn v-if="!nom_ok" @click="nom_ok=true">Mostrar productes</v-btn>
                            <v-btn v-if="nom_ok" @click="nom_ok=false">Amagar productes</v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>IMPRIMIR HOJA EXTRA</b>
                          </td>
                          <td>
                                <input type="checkbox" id="checkbox" v-model="ordreEixida.hoja_extra">
                                
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>IMPRIMIR LOGO</b>
                          </td>
                          <td>
                                <input type="checkbox" id="checkbox" v-model="ordreEixida.imprimir_logo">
                                
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>NUMERO COMANDA ALBARÀ</b>
                          </td>
                          <td>
                          <v-text-field v-model="ordreEixida.num_pedido" 
                          label="Num Comanda" 
                          required></v-text-field>
                          </td>
                        </tr>
                         <tr>
                          <td>
                            <b>Comentaris generals Albarà</b>
                          </td>
                          <td>
                          <v-text-field v-model="ordreEixida.comentari_general" 
                          label="Comentaris generals Albarà" 
                          required></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            <b>TRANSPORTISTA</b>
                          </td>
                          <td>
                          <v-text-field
                          v-if="tte_fuera_listado"
                           v-model="ordreEixida.transportista" 
                          label="Transportista" 
                          required></v-text-field>
                          <v-autocomplete
                            v-else
                            v-model="ordreEixida.transportista"
                            :items="transOpcions_hardcode"
                            label="Transportista"
                            item-text="nom"
                            item-value="nom"
                            no-data-text
                          ></v-autocomplete>
                          <input type="checkbox" id="checkbox2" v-model="tte_fuera_listado">
                          <label for="checkbox2">TTE fuera listado</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>ENTREGADO EN</b>
                          </td>
                          <td>
                          <v-text-field v-model="ordreEixida.entregado_en" 
                          label="Lugar entrega" 
                          required></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                  </v-simple-table>
        </v-col>
      </v-row>
      <v-row v-if="nom_ok">
        <v-col sm="12">
          <v-simple-table v-for="(linea,index) in ordreEixida.lineas" :key="linea.id">
              <thead width="100%" v-if="nom_ok">
                <tr><td><h1 style="text-align: center" v-if="nom_ok">{{linea.nom}}</h1></td>
                
                <input style="margin-top:10px" type="checkbox" id="checkbox" v-model="linea.imprimir_observaciones">
                <label style="margin-top:10px" for="checkbox">Imprimir OBSERVACIONES</label>
                
                <td> <v-btn  color="error" v-if="linea.num_bales==0" @click="btnEliminarLinea(linea)">Eliminar producte</v-btn></td>
                </tr>
              </thead>
              <tbody v-if="nom_ok">
                <tr>
                  <td>
                    <b>GRS</b>
                  </td>
                  <td style="display: flex">
                  <v-text-field v-model="linea.codigo_grs" v-if="GRS_fuera_listado[index]"
                  label="GRS"
                  required
                  ></v-text-field>
                  <v-autocomplete v-model="linea.codigo_grs"  v-if="!GRS_fuera_listado[index]"
                  label="GRS"
                  required
                  item-value="text1"
                  item-text="text2"
                  :items="GRSOpcions"
                  ></v-autocomplete>
                  <v-col sm="2" style="padding-top: 0px; padding-bottom: 0px; ">
                  <input type="checkbox" id="checkbox" v-model="GRS_fuera_listado[index]">
                  <label for="checkbox">GRS fuera de listado</label>
                  </v-col>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Referència Ari</b>
                  </td>
                  <td>
                  <v-text-field v-model.number="linea.ref_ari" 
                  label="Referència Ari"
                  required></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Num Comanda</b>
                  </td>
                  <td>
                  <v-text-field v-model="linea.num_pedido" 
                  label="Num Comanda" 
                  required></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Referència Materia</b>
                  </td>
                  <td>
                  <v-text-field v-model="linea.ref_materia" 
                  label="Ref Matèria" required></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
        </v-col>
      </v-row>
      <!-- ./Client -->
    </v-form>
    <!-- Botonera -->
    <div class="botonera">
      <v-btn color="error" @click="btnCancelar" tabindex="-1">Cancel·lar</v-btn>
      <v-btn
        color="success"
        @click="btnEditOrdreEixida"
        :disabled="!ordreEixida.client"
      >Guardar Ordre d'Eixida</v-btn>
    </div>
    <!-- ./Botonera -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "albara-edit",
  data: () => ({
    client: null,
    ordreEixida: {
      client: null,
      id: null,
      lineas: []
    },
    nom_ok:false,
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    GRSOpcions:[
      {text1:"(98% R-CO 2%R-TR)", text2:"(98% - 2%)" },
      {text1:"(90% R-CO 10%R-TR)", text2:"(90% - 10%)" },
      {text1:"(80% R-CO 20%R-TR)", text2:"(80% - 20%)"},
    ],
     transOpcions_hardcode:[
      {id:"1", nom:"TTES FCO SANCHIS"},
      {id:"2", nom:"TTES BERENGUER"}
    ],
    tte_fuera_listado: false,
    GRS_fuera_listado:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
    dialogClient: false,
    liniesOrdreEixidaAEliminar: [],
    reservaOpcions: []
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      tipusBorra: "getMateriesProducteFinal",
      partidaOpcions: "getPartides"
    }),

    disableEditarClient() {
      return this.ordreEixida.lineas.some(el => el.reserva)
    }
  },
  methods: {
    ...mapActions([
      "editLiniaOrdreEixida",
      "editOrdreEixida",
      "eliminarLiniaOrdreEixida",
      "getBalaProducteFinalBarcode",
      "getOrdreEixida",
      "getClients",
      "getMateriesProducteFinal",
      "getPartides",
      "getReservesPerClient",
      "sendClient",
      "sendLiniaOrdreEixida", 
      "getMesclada", 
      "getPartida_new", 
      "getMateriaProducteFinal"
    ]),

    // Crear element
    btnEditOrdreEixida() {
      this.editOrdreEixida(this.ordreEixida).then(status => {
        // S'ha editat amb èxit
        if (status === 200) {
          this.ordreEixida.lineas.forEach(linia => {
            if (linia?.id) {
              this.editLiniaOrdreEixida({id: linia.id, ref_ari: linia.ref_ari, ref_materia: linia.ref_materia, num_pedido: linia.num_pedido ,
               imprimir_observaciones: linia.imprimir_observaciones, codigo_grs: linia.codigo_grs});
               this.$router.push({
            name: "AlbaraOrdreEixidaDetail",
            params: { id: this.ordreEixida.id }
          });
            }
          });

          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Editat albarà amb èxit`,
            color: "success"
          });
          
        }
        // No s'ha editat amb èxit
        else {
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `No s'ha pogut modificar aquest Albarà`,
            color: "warning"
          });
        }
      });
    },
    textLiniaOE(linea){
      if(linea.partida!=null)
      {this.getPartida_new(linea.partida).then(
        mesclada=>{
          linea.nom = mesclada.codi.replaceAll('.', '');
          this.getMateriaProducteFinal(mesclada.producte_final).then(borra=>
          {linea.nom =`${linea.nom} - ${borra.nom}` ;
          })
        }
      )}else{
        linea.nom = linea.materia_primera;
      }
    },
    btnCancelar() {
      this.$router.push({
        name: "AlbaraOrdreEixidaDetail",
        params: { id: this.ordreEixida.id }
      });
    },
    btnEliminarLinea(linea){
      this.eliminarLiniaOrdreEixida(linea.id)
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getClients();
    this.getMateriesProducteFinal();
    this.getOrdreEixida(this.$route.params.id).then(ordreEixida => {
      this.ordreEixida = ordreEixida;
      ordreEixida.lineas.forEach(linea=>{
        
        this.textLiniaOE(linea);
      })
    });
    this.getPartides();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

.container {
  max-width: 100% !important;
}
/* Creació */

// Botonera
.botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 45%;
  }
}
.botonera-escanejar {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
  }
}
.v-data-table {
  margin-bottom: 10px;
    border-radius: 10px;
    border: 2px black solid;
}
// Stepper
.container {
  padding-top: 3em;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}
</style>