<template>
  <v-container>
      <v-dialog v-model="dialogImpresoras" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">TRIA L'IMPRESORA</v-card-title>
          <v-card-text>
            <v-autocomplete
            v-model="entrada.impresora"
            :items="impresorasOps"
            outlined
            item-text="nom"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap impresora"
            label="Impresora"
          ></v-autocomplete></v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogImpresoras = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCanviMagatzem" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">CANVIAR MAGATZEM</v-card-title>
          <v-card-text>
            <v-autocomplete
            v-model="canvi_nou_magatzem"
            :items="OpcionesSubalmacenes"
            outlined
            item-text="nom"
            item-value="barcode"
            no-data-text="No s'ha pogut carregar cap magatzem"
            label="Nou magatzem"
          ></v-autocomplete></v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogCanviMagatzem = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="canviarMagatzem">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAlmacenar" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Emmagatzemar</v-card-title>
          <v-card-text>
            <v-autocomplete
            v-model="canvi_nou_magatzem"
            :items="OpcionesSubalmacenes"
            outlined
            item-text="nom"
            item-value="barcode"
            no-data-text="No s'ha pogut carregar cap magatzem"
            label="Nou magatzem"
          ></v-autocomplete></v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="cerrardialogAlmacen">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="AlmacenarTodo">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- Entrada -->
    <v-simple-table v-if="entrada" class="mt-12">
      <thead>
        <h1>Vista detallada d'Entrada</h1>
      </thead>
      <tbody>
        <tr v-if="entrada.data_albara">
          <td>
            <b>Data albarà</b>
          </td>
          <td>{{ entrada.data_albara.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1') }}</td>
        </tr>
        <tr></tr>
        <tr>
          <td>
            <b>Proveïdor</b>
          </td>
          <td>{{ entrada.proveidor_nom }}</td>
        </tr>
        <tr>
          <td>
            <b>Bales totals</b>
          </td>
          <td>{{ entrada.bales.length }}</td>
        </tr>
        <tr>
          <td>
            <b>Quilos total</b>
          </td>
          <td>{{ entrada.quilos_total }} kg</td>
        </tr>
        <tr v-if="grupo!=4">
          <td>
            <b>Cost total</b>
          </td>
          <td>{{ entrada.cost_total }} €</td>
        </tr>
        <tr v-if="entrada.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ entrada.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Entrada -->
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="success" dark @click="btnGenerarPegatina_impresora" v-if="entradaImpresa">Generar etiqueta</v-btn>
      <v-dialog v-model="dialogPegatina" persistent max-width="600" v-else>
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark v-on="on">Generar etiqueta</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Etiquetes impreses</v-card-title>
          <v-card-text>Una o més d'estes etiquetes ja han sigut impreses.</v-card-text>
          <v-card-text>Confirme que vol imprimir-les de nou.</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogPegatina = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina_impresora">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn color="primary" dark @click="btnEditarEntrada">Editar entrada</v-btn>
      <!-- Dialog/Modal per eliminar entrada -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar entrada</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar entrada</v-card-title>
          <v-card-text>Està segur que vol eliminar esta entrada?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarEntrada">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar bala -->
    </div>
    <!-- Botons d'opcions -->
    <!-- Balas -->
    <div v-if="entrada && entrada.bales.length > 0">
      <v-simple-table class="llistat-bales">
      <thead>
        <td>
        <h1>Llistat de bales</h1>
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        @click="dialogCanviMagatzem=true"
      >Canviar magatzem</v-btn>
      <v-btn
        @click="dialogAlmacenar=true"
      >Emmagatzemar</v-btn>
        </td>
      </thead>
      <tbody>
        <tr>
          <td style="height: auto">
            <b>Num.</b>
          </td>
          <td style="height: auto">
            <b>Barcode</b>
          </td>
          <td style="height: auto">
            <b>Quilos</b>
          </td>
          <td v-if="grupo!=4" style="height: auto">
            <b>Preu (€/kg)</b>

          </td>
          <td style="width: 25%; height: auto;">
            <b>Materia primera</b>
          </td>
          <td style="height: auto">
            <b>Estat</b>
          </td>
          <td style="height: auto">
            <b>Magatzem</b>
          </td>
        </tr>
        <tr v-for="(bala, index) in entrada.bales" :key="index" @click="balaDetail(bala.id)" :class="{'bala-seleccionat': bala.estat==2 || bala.estat==3 || bala.estat==5,'bala-consumida': bala.estat==4 || bala.estat==6, 'bala-pendent': bala.impresa==false && bala.estat!=2 && bala.estat!=4 && bala.estat!=6 && bala.estat!=5 && bala.estat!=3} ">
          <td style="height: auto">
            {{index+1}}
          </td>
          <td style="height: auto">
            {{bala.barcode}}
          </td>
          <td v-if="bala.quilos" style="height: auto">
            {{parseFloat(bala.quilos).toFixed(0)}} kg
          </td>
          <td v-if="grupo!=4" style="height: auto">
            {{ parseFloat(bala.preu_quilo).toFixed(3) }} 
          </td>
          <td style="width: 25%; height: auto;">{{ textMateriaPrimera(bala.materia_primera) }}</td>
          <td style="height: auto">
            {{ textConsumida(bala.estat) }}
          </td>
          <td style="height: auto">
            {{ textSubMagatzem(bala.posicion_almacenamiento) }}
          </td>
        </tr>

      </tbody>
    </v-simple-table>
    </div>
    <!-- ./Balas -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('EntradaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";

export default {
  name: "entrada-detall",
  data: () => ({
    impresorasOps:[
      {nom: "PDF",
      id: "FrontEnd"}, 
      {nom: "IMPRESORA 1",
      id: "Pantalla1"},
      {nom: "IMPRESORA 2",
      id: "Pantalla2"},
      {nom: "IMPRESORA 3",
      id: "Pantalla3"},

    ],
    json_fields: {
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        MAGATZEM: "magatzem",
        QUILOS:{
          field: "quilos",
          callback: value =>(parseFloat(value).toFixed(2))
        }, 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        CONSUMIDA: {
          field: "estat",
          callback: value => (value==4 ? "Sí" : value==3 ? "En procés" : "No")
        },
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        MATERIA_PRIMERA: "materia_primera",
        DATA_CREACIO: "creada_en"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales_Entrada",
    canvi_nou_magatzem: "",
    dialog: false,
    dialogPegatina: false,
    dialogImpresoras: false,
    dialogCanviMagatzem:false,
    dialogAlmacenar:false,
    OpcionesSubalmacenes:[],
    bales_amb_magatzem_id:[],
    bales_amb_magatzem_pos:"",
    entrada: {
      data_albara: null,
      id: null,
      cost_total: "",
      quilos_total: null,
      observacions: null,
      proveidor: "",
      bales: [
        {
          barcode: null,
          quilos: null,
          preu_quilo: null,
          observacions: null,
          estat: "",
          materia_primera: null,
          magatzem: null,
          barcode_img: ""
        }
      ]
    }
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors",
      grupo: "getGroup"
    }),

    entradaImpresa() {
      return !this.entrada.bales.find(el => el.impresa);
    }
  },
  methods: {
    ...mapActions([
      "eliminarEntrada",
      "generarPegatinaEntrada",
      "getEntrada",
      "getMagatzems",
      "getMateriesPrimeres",
      "getProveidors", 
      "imprimirPegatinaEntrada_new", 
      "almacenarBalas",
      "desalmacenarBalas"
    ]),

    // Botonera
    canviarMagatzem(){
      this.desalmacenarBalas({posicio_id: this.bales_amb_magatzem_pos, balas_materia_prima: this.bales_amb_magatzem_id}).then(
        response => 
        { 
          if(response=="200"){
            EventBus.$emit("Mesclada.Notificacio", {
            text: `Les bales han eixit del magatzem`,
            color: "succes"
          });
            this.almacenarBalas({posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == this.canvi_nou_magatzem).id, balas_materia_prima: this.bales_amb_magatzem_id}).then(
                  response =>{
                    if(response){
                      EventBus.$emit("Mesclada.Notificacio", {
                        text: `Les bales han entrat al magatzem`,
                        color: "succes"
                      });
                      this.$router.go()
                    }
                  }
                )
           //this.$router.go()
          }else{
            EventBus.$emit("Mesclada.Notificacio", {
            text: `L'entrada no s'ha pogut modificar`,
            color: "warning"
          });}
        })
      
    },
    AlmacenarTodo(){
      this.entrada.bales.forEach(bala=>{
        if(bala.estat==1 && bala.posicion_almacenamiento==''){
          this.almacenarBalas({posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == this.canvi_nou_magatzem).id, balas_materia_prima: [bala.id]}).then(
                  response =>{
                    if(response){
                      EventBus.$emit("Mesclada.Notificacio", {
                        text: `La bala ha entrat al magatzem`,
                        color: "succes"
                      });
                    }
                  }
                )
        }
      })
      
    },
    cerrardialogAlmacen(){
      this.dialogAlmacenar = false;
      this.$router.go()
    },
    btnGenerarPegatina_impresora(){
      this.dialogImpresoras =true;
      this.dialogPegatina = false;
    },
    btnGenerarPegatina() {
      this.imprimirPegatinaEntrada_new({id: this.entrada.id, info_impresion:{destino: this.entrada.impresora, 
        request_id: localStorage.getItem('uuid')}})

      this.dialogImpresoras = false;
    },
    btnEditarEntrada() {
      this.$router.push({
        name: "EntradaEdit",
        params: { id: this.entrada.id }
      });
    },
    balaDetail(balaID) {
      this.$router.push({ name: "EntradaBalaDetail", params: { id: balaID , entrada: this.entrada.id} });
    },
    btnEliminarEntrada() {
      this.eliminarEntrada(this.entrada.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Entrada.Notificacio", {
            text: "Entrada eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "EntradaList" });
        }
      });
    },
    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.$route.params.entrada } });
    },
    getExcelData(){
      this.nombreExcel= "Llistat_Bales_Entrada" + this.entrada.id
      this.$nextTick(() => {
      this.$refs.buttonGenerarExcel.$el.click();})
    },
    textConsumida(valor) {
      if (valor == 4) return "Consumida";
      else if (valor == 1) return "Sin asignar";
      else if (valor == 2 || valor == 3) return "En proceso de consumir";
      else if (valor == 5) return "Asignada a Carga";
      else if (valor == 6) return "Expedida";
    },
    textMagatzem(magatzemNum) {
      const magatzem = this.magatzemOpcions?.find(el => el.numero === magatzemNum);
      if(magatzem?.nom){
        return magatzem?.nom;
        }
      else{
        return "Sense magatzem"
      }
    },
    textSubMagatzem(barcode) {
      const magatzem = this.OpcionesSubalmacenes?.find(el => el.barcode === barcode);
      if(magatzem?.nom){
        return magatzem?.nom;
        }
      else{
        return "-"
      }
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''}`;
    },
    textProveidor(proveidorID) {
      const proveidor = this.proveidorOpcions?.find(
        el => el.id === proveidorID
      );
      return proveidor?.nom;
    }
  },
  created() {
    this.getEntrada(this.$route.params.id).then(
      entrada => {this.entrada = entrada;
      this.excelArray = entrada.bales; 
      entrada.bales.forEach(el=>
      {if(el.posicion_almacenamiento!=""){
        this.bales_amb_magatzem_id.push(el.id)
        this.bales_amb_magatzem_pos=el.magatzem
      }})}
    );
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +' Nº' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getProveidors();
  }
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  text-align: center;
  padding-bottom: 0.5em;
}
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
    td{
      display: flex;
      text-align: center;
      justify-content: space-around;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
.llistat-bales{
  margin-bottom: 10px;
  tbody {
    tr {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 12%;
        height: 3vh;
      }
    }
  }
}
#botonera {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;

  button {
    width: 30%;
  }
}
#downloadexcel {
  display: none;
}
.bala-seleccionat {
      background: rgb(241, 241, 153) ;
}
.bala-pendent {
      background: rgb(153, 188, 241) ;
}
.bala-consumida {
      background: rgb(241, 171, 153) ;
}
</style>