<template>
  <v-container>
    <!-- Ordre Eixida -->
    <v-form ref="form" class="mt-12" style="margin-top: 28px !important;">
      <h1>Creació Eixida de Materia Primera</h1>
      <!-- Client -->
      <v-row>
        <v-col sm="10">
          <v-autocomplete
            v-model="client"
            :items="clientOpcions"
            label="Client"
            outlined
            item-text="nom"
            item-value="id"
            @change="carregaReserves"
            no-data-text
          ></v-autocomplete>
        </v-col>
        <v-col v-if="grupo!=4" sm="1">
          <!-- Dialog/Modal per crear client -->
          <v-dialog v-model="dialogClient" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear client</v-card-title>
              <v-container>
                <v-form id="formClient" ref="formClient" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="client_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.emails" label="Correu"></v-text-field>
                      </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogClient = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearClient"
                  tabindex="-1"
                  :disabled="!client_nou.nom || !client_nou.poblacio"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear client -->
        </v-col>
      </v-row>
      <!-- ./Client -->
      <!-- Afegir partida -->
      <v-row>
        <v-col md="2" offset-md="10">
          <v-btn
            block
            color="info"
            @click="afegirLiniaOrdreEixida()"
            tabindex="-1"
          >Afegir producte</v-btn>
        </v-col>
      </v-row>
      <!-- ./Afegir partida -->
      <!-- Partida -->
      <div class="producte" v-for="(linia, index) in liniesOrdreEixida" :key="index">
        <v-row style="justify-content: space-around">
          <v-col sm="4">
            <strong>PRODUCTE #{{ index + 1}}</strong>
          </v-col>
          <v-col sm="2">
            <v-btn
              block
              color="warning"
              @click="eliminarLineaOrdreEixida(index)"
              tabindex="-1"
            >Eliminar</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
          <!-- <v-autocomplete
              :disabled="!client"
              v-model="linia.materia_primera"
              :items="materiesPrimeresOpcions"
              label="Materia Primera"
              item-value="id"
              no-data-text
              :filter="customFilter"
              clearable
              outlined
            >
            <template slot="selection" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            <template slot="item" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            </v-autocomplete> -->
            <v-text-field
              v-model="linia.materia_primera"
              label="NOM PRODUCTE"
              required
              outlined
              :disabled="!client"
            ></v-text-field>
            
          </v-col>
        </v-row>
        <v-row >
          <v-col sm="6">
            <v-text-field
              :disabled="!client"
              v-model="linia.quilos_aproximats"
              label="Quilos aproximats"
              type="number"
              autofocus
              outlined
              required
            ></v-text-field>
          </v-col>
          <!-- <v-col sm="6">
            <v-text-field
              :disabled="true"
              v-model="linia.quilos_disponibles"
              label="Quilos disponibles"
              type="number"
              autofocus
              outlined
              rounded
              required
            ></v-text-field>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              :disabled="!client"
              v-model="linia.max_num_bales"
              label="Màxim número de bales"
              type="number"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field 
            :disabled="!client"
            v-model="linia.observacions"
            outlined 
            label="Observacions"></v-text-field>
          </v-col>
        </v-row>
      </div>
      <!-- ./Partida -->
    </v-form>
    <!-- ./Ordre Eixida -->
    <!-- Botonera -->
    <div class="botonera">
      <v-btn color="error" @click="btnCancelar" tabindex="-1">Cancel·lar</v-btn>
      <!-- <v-btn color="success" @click="pas = 2" :disabled="!client">Escanejar bales</v-btn> -->
      <v-btn color="success" @click="btnCrearOrdreEixida" :disabled="!client">Crear</v-btn>
    </div>
    <!-- ./Botonera -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "ordre-eixida-mp-create",
  data: () => ({
    client: null,
    liniesOrdreEixida: [
      {
        materia_primera:"",
        quilos_aproximats: 0,
        max_num_bales: 0,
        observacions: "",
        tipo_balas:1,
        reserva: null,
        bales: [],
        partida: null,
        estado:{estado_reservas:[]},
      }
    ],
    partidaOpcionsLinea:[],
    reservaLinia:[],
    estado:{estado_reservas:[]},
    dialogClient: false,
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    reservaOpcions: []
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      magatzems: "getMagatzems",
      mesclades: "getMescladesReservades",
      tipusBorra: "getMateriesProducteFinal",
      partidaOpcions: "getPartides",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getBalaProducteFinalBarcode",
      "getClients",
      "getPartida",
      "getMesclada",
      "getMagatzems",
      "getReserva",
      "getMateriesProducteFinal",
      "getMescladesReservades",
      "getPartides",
      "getReservesPerClient",
      "sendClient",
      "sendLiniaOrdreEixida",
      "sendOrdreEixida", 
      "getEstadoReserva",
      "getMateriesPrimeres",
      "getProveidors",
      "addLiniaOrdreEixidaMP"
    ]),

    // Crear element
    btnCrearClient() {
      this.sendClient(this.client_nou).then(data => {
        if (data.status === 201) {
          this.client_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogClient = false;
          this.ordreEixida.client = data.data.id;
        }
      });
    },
    cambioSwitch(linia,index){
      if(linia.completa){linia.quilos_aproximats = 
      parseInt(this.reservaLinia[index].quilos)-parseInt(this.reservaLinia[index].quilos_enviats);}
      else{
        linia.quilos_aproximats = 0;  
      }
    },
    btnCrearOrdreEixida() {
      this.sendOrdreEixida({
        client: this.client
      }).then(ordreEixida => {
        if (ordreEixida.status === 201) {
          var count=0;
          var last = this.liniesOrdreEixida.length;
          this.liniesOrdreEixida.forEach(linia => {
            linia.ordre_eixida = ordreEixida.data.ordre_eixida.id;
            count += 1
              this.addLiniaOrdreEixidaMP(linia).then(dataLinia => 
              {if(count==last){
                if(dataLinia) {
                  this.$router.push({
                    name: "OrdreEixidaDetail",
                    params: { id: ordreEixida.data.ordre_eixida.id }
                  });
                }
              }});
            
          });
          EventBus.$emit("OrdreEixida.Notificacio", {
                    text: "Creada Ordre d'Eixida amb èxit",
                    color: "success"
                  });
        }
      });
    },

    // Auxiliar
    reservaTriada(index,linia){
      if(linia.reserva) {
        linia.partida_reserva = this.reservaOpcions.find(el=> el.id== linia.reserva)?.nom.split(" ")[0];
        linia.partida = this.reservaOpcions.find(el=> el.id== linia.reserva)?.partida;
      this.getReserva(linia.reserva).then(reserva=>
        {this.reservaLinia[index]= reserva;
        linia.quilos_disponibles = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats);
        if(linia.completa) linia.quilos_aproximats = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
      )}
    },
    borrarPartida(linia){
     linia.partida = "";
     linia.quilos_disponibles = 0;
     
     
    },
    // partidaTriada(linia){
      
    // },
    actualizaPartidas(index, linia){
      if(linia.partida && !linia.quilos_disponibles)
      {this.getEstadoReserva(linia.partida).then(response => {
        linia.estado = response
        linia.quilos_disponibles = response.estado_reservas.quilos_disponibles
        })}
      // var reserva_id = this.liniesOrdreEixida[index].reserva;
      // if(linia.reserva && linia.quilos_aproximats==0) {linia.partida = this.reservaOpcions.find(el=> el.id== reserva_id)?.partida;
      // this.getReserva(linia.reserva).then(reserva=>
      //   {this.reservaLinia[index]= reserva;
      //   if(linia.completa) linia.quilos_aproximats = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
      // )}
      // if(linia.partida && !linia.partida_ok)
      // {this.getMesclada(linia.partida).then(
      //   of=>{
      //     this.partidaOpcionsLinea[index] = of.codi; 
      //     linia.partida_ok = true;
      //   }
      // )}
      // this.partidaOpcionsLinea[index] = this.textPartida(this.reservaOpcions.find(el=> el.id== reserva_id)?.partida)
    },
    quitarReserva(linia){
      linia.partida_ok=false; 
      linia.completa = false; 
      linia.partida_reserva ="";
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());
    },
    // Auxiliar
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor.nom;
      return materiaPrimera?.nom;
    },
    textPartida(id){
      var output
      // if(this.partidaOpcions.find(el=> el.id == id))
      // {return this.partidaOpcions.find(el=> el.id == id)?.codi}
      // else 
      if (id) {
        this.getMesclada(id).then(partida=>{
          output= partida.codi.replaceAll('.', '')
        }
        )
        return output
      }
    },
    afegirLiniaOrdreEixida() {
      this.liniesOrdreEixida.push({
        quilos_aproximats: 0,
        max_num_bales: 0,
        observacions: "",
        reserva: null,
        bales: [],
        partida: null,
        tipo_balas:1
      });
    },
    btnCancelar() {
      this.$router.push({
        name: "Home"
      });
    },
    carregaReserves() {
      // Carreguem les reserves filtrades per client
      this.getReservesPerClient(this.client)
        .then(reserves => {
          this.reservaOpcions = reserves;
        })
        // Un cop les tenim, afegim el camp 'nom', calculat a partir de OF i quilos
        .then(() => {
          this.reservaOpcions = this.reservaOpcions.map(reserva => {
            let reservaCodi = this.mesclades.find(
              el => el.id === reserva.partida
            )?.codi.replaceAll('.', '');
            return { ...reserva, nom: `${reservaCodi} - ${reserva.quilos} kg` };
          });
        });
    },
    eliminarLineaOrdreEixida(index) {
      this.liniesOrdreEixida.splice(index, 1);
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getClients();
    this.getMagatzems();
    this.getMateriesPrimeres();
    this.getMescladesReservades();
    this.getPartides();
    this.getProveidors();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
.producte{
  border: #8f8080 solid 1px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.container {
  max-width: 100% !important;
}
/* Creació */

// Botonera
.botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 45%;
  }
}
.botonera-escanejar {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
  }
}

// Stepper
.container {
  padding-top: 3em;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}
</style>