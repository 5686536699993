<template>
  <v-container>
    <!-- Llistat Mesclades -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1>Llistat de Mesclades
           <v-btn v-if="deshabilitar_tabla" color="success" dark @click="redireccio('MescladaCreate')">+</v-btn>
          <v-btn color="success" :loading='!deshabilitar_tabla' v-if="!deshabilitar_tabla"></v-btn>
        </h1>
        </td>
        <td v-if="grupo != 2 && grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="3" >
          <v-autocomplete
            v-model="filtre.prefix"
            :items="prefixosOpcions_hardcode"
            item-text="nom"
            item-value="nom"
            outlined
            rounded
            no-data-text
            label="Referencia" 
            @input="filtrar()"
            clearable>
            <template slot="selection" slot-scope="data">{{ (data.item.nom.replaceAll('.','')) }}</template>
            <template slot="item" slot-scope="data">{{(data.item.nom.replaceAll('.','')) }}</template>
            </v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.estat"
            :items="estatOpcions"
            @input="filtrar()"
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="id"
            clearable
            label="Estat mescalda"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.producte_final"
            :items="materiesProducteFinal"
            @input="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Producte final"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-text-field
            v-model="filtre.quilos_totals"
            label="Quilos Totals"
            @input="filtrar()"
            required
            outlined
            rounded
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(mesclada, index) in mesclades" :key="index">
          <td
            @click="mescladaDetail(mesclada.id)"
            class="text-mesclada"
          ><strong>{{mesclada.producte_final_nom}}</strong> {{ textMesclada(mesclada) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Plantilles -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="mesclades.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "mesclada-llistat",
  data: () => ({
    deshabilitar_tabla:false,
    filtre: {
      codi: "",
      prefix: null,
      plantilla: null,
      quilos_totals: "",
      producte_final:null,
      estat:"",
      pagina: 1, 
    },
    prefixosOpcions_hardcode:[
      {id:"1", nom:"1"},
      {id:"2", nom:"2"},
      {id:"3", nom:"3"},
      {id:"4", nom:"4"},
      {id:"5", nom:"RC"},
      {id:"6", nom:"A"},
      {id:"7", nom:"AR"},
    ],
    paginasAñadidas:[],
    json_fields: {
        CODI: "codi",
        ESTAT: "estat_nom",
        QUILOS_BORRA: "quilos_borra_totales", 
        PRODUCTE_FINAL: "producte_final_nom", 
        COST_MATERIES_PRIMERES: "cost_materies_primeres",
        QUILOS_MATERIES_PRIMERES: "quilos_totals", 
        QUILOS_OBJETIVO: "quilos_deseados",
        NUM_BALES: "numero_bales_borra",
        NUM_QUARTOS: "numero_quartos",
        DATA_CREACIO: "creat_en", 
        DATA_FINALITZACIO: "finalizat_en"
      },
    excelArray: [],
    nombreExcel: "Llistat_Mesclades",
    estatOpcions: [
      { id: "-", nom: "Incompleta" },
      { id: "c", nom: "En cua" },
      { id: "e", nom: "Execució" },
      { id: "i", nom: "Interrompuda" },
    ],
    modalData: false,
    pagina: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      materiesProducteFinal: "getMateriesProducteFinal",
      mesclades: "getMescladesNoAcabades",
      plantillesOpcions: "getPlantilles",
      prefixosOpcions: "getPrefixos",
      totalPagines: "getMescladesNoAcabadesPaginacio", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getMateriesProducteFinal",
      "getMescladesNoAcabades",
      "getMescladesFiltrades",
      "getPlantilles",
      "getPrefixos"
    ]),

    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;

      // Comprovem si té prefix i si en té, afegir a filtres
      //if (
      //  this.filtre.codi === "LL" ||
      //  this.filtre.codi === "R" ||
      //  this.filtre.codi === "P" ||
      //  this.filtre.codi === "N"
      //) {
      //  this.filtre.prefix = this.prefixosOpcions.filter(
      //    el => el.nom === this.filtre.codi
      //  )[0]?.id;
      // } else this.filtre.prefix = null;
      this.getMescladesNoAcabades(this.filtre);
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      this.getMescladesNoAcabades(this.filtre);
    },

    // Rerouting
    mescladaDetail(mescladaID) {
      this.$router.push({
        name: "MescladaDetail",
        params: { id: mescladaID }
      });
    },

    getExcelData(){
      this.excelArray.forEach(elem=> 
      {elem.producte_final_nom = this.textProducteFinal(elem.producte_final);
      elem.estat_nom = this.textEstat(elem.estat);
      }
      )
      this.$nextTick(() => this.$refs.buttonGenerarExcel.$el.click());
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.mesclades); 
      this.paginasAñadidas.push(this.pagina);}
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textMesclada(mesclada) {
      const data = mesclada?.creat_en?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
      return `
            
            ${mesclada.observacions ? `-  ${mesclada.observacions} ` : ""}
            ${mesclada.prefix_nom ? `- Ref: [${mesclada.prefix_nom.replaceAll('.', '')}]` : ""}
            ${data ? `-  ${data}` : ""}
            ${mesclada.quilos_deseados ? `- Objectiu: ${mesclada.quilos_deseados} kg` : ""}
            ${mesclada.quilos_totals ? `- Assignats: ${mesclada.quilos_totals} kg` : ""} 
            - Quartos: ${mesclada.numero_quartos} - Estat: 
            ${this.textEstat(mesclada.estat)} `;
    },
    textPlantilla(plantillaID) {
      return (
        this.plantillesOpcions.find(el => el.id === plantillaID)?.nom || 0
      );
    },
    textEstat(estat) {
      return (
        this.estatOpcions.find(el => el.id === estat)?.nom || 0
      );
    },
    textProducteFinal(producteFinalID) {
      return this.materiesProducteFinal.find(
        producteFinal => producteFinal.id === producteFinalID
      )?.nom;
    }
  },

  mounted() {
    
    this.deshabilitar_tabla=false;
    this.getMescladesNoAcabades(this.filtre).then(
      response=> {if(response){
        this.deshabilitar_tabla=true;
        this.getMateriesProducteFinal();
        this.getPlantilles();
        this.getPrefixos();
      }}
    );
  }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.text-mesclada {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>