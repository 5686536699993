<template>
  <v-container>
    <!-- Llistat Materies Primeres -->
    <v-simple-table class="mt-12" style="padding-bottom: 1em">
      <thead>
        <v-row>
          <v-col sm="10" offset-sm="0">
            <h1>Llistat de Materies Primeres</h1>
          </v-col>
          <v-col sm="1" offset-sm="0">
            <!-- Dialog/Modal per crear materia primera -->
            <v-dialog v-model="dialogMateriaPrimera" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear materia primera</v-card-title>
                <v-container>
                  <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="materia_primera_nova.nom" label="Nom" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                          v-model="materia_primera_nova.preu_quilo"
                          label="Preu quilo"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-autocomplete
                          v-model="materia_primera_nova.proveidor"
                          :items="proveidorOpcions"
                          no-data-text="No s'ha pogut carregar cap proveïdor"
                          item-text="nom"
                          item-value="id"
                          label="Proveïdor"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="10">
                        <v-autocomplete
                          v-model="materia_primera_nova.tipo"
                          :items="tiposOpcions"
                          no-data-text="No s'ha pogut carregar cap tipo de materia"
                          clearable
                          item-text="nom"
                          item-value="id"
                          label="Composició"
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="2">
                  <!-- Dialog/Modal per crear ingredients -->
                  <v-dialog v-model="dialogTipoMateria" persistent max-width="600">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Crear Composició</v-card-title>
                      <v-container>
                        <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="tipusMP_nou.nom" label="Composició" autofocus required @keydown.enter.prevent="btnCrearTipusMP"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialogTipoMateria = false"
                        >Cancel·lar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="btnCrearTipusMP">Crear</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- ./Dialog/Modal per crear ingredients -->
                </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="10">
                        <v-autocomplete
                          v-model="materia_primera_nova.ingredient"
                          :items="ingredientsOpcions"
                          no-data-text="No s'ha pogut carregar cap tipus de materia"
                          item-text="nom"
                          item-value="id"
                          label="Tipus de materia"
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="2">
                        <!-- Dialog/Modal per crear ingredients -->
                        <v-dialog v-model="dialogIngredient" persistent max-width="600">
                          <template v-slot:activator="{ on }">
                            <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="headline">Crear tipus de materia</v-card-title>
                            <v-container>
                              <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                                <v-row>
                                  <v-col md="12">
                                    <v-text-field v-model="ingredient_nou.nom" label="Nom" autofocus required @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                            <v-card-actions>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="dialogIngredient = false"
                              >Cancel·lar</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn color="green darken-1" text @click="btnCrearIngredient">Crear</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- ./Dialog/Modal per crear ingredients -->
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-checkbox v-model="materia_primera_nova.ignifug" label="Ignífug"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialogMateriaPrimera = false"
                  >Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearMateriaPrimera"
                    :disabled="!materia_primera_nova.nom || !materia_primera_nova.proveidor|| !materia_primera_nova.preu_quilo"
                  >Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear materia primera -->
          </v-col>
        </v-row>
        <v-row style="display: flex; justify-content: space-betwenn; text-align: center;">
        <v-btn
          id="generarExcel"
          ref="buttonGetExcelData"
          @click="getExcelData"
        >Generar Excel</v-btn>
        <downloadexcel
          id="downloadexcel"
          ref="buttonGenerarExcel"
          class="btn"
          :name="nombreExcel"
          :data="excelArray"
          :fields="json_fields"
          worksheet = "Pàgina 1"
          type="xls"
        ></downloadexcel>
        <v-btn
          id="generarExcel"
          @click="addExcelData"
          style="margin-left: auto"
        >Afegir a Excel</v-btn>
        <v-btn
          id="generarExcel"
          @click="buidarExcel"
          style="margin-left: auto"
        >Buidar Excel</v-btn>
        </v-row>
        <v-row>
        <v-col sm="4" offset-sm="0">
          <v-autocomplete
            v-model="filtre.proveidor"
            @input="filtrar()"
            :items="proveidorOpcions"
            no-data-text
            clearable
            outlined
            rounded
            @click:clear="filtrar()"
            item-text="nom"
            item-value="id"
            label="Proveidor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4">
          <v-autocomplete
            v-model="filtre.tipo"
            :items="tiposOpcions"
            @input="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            @click:clear="filtrar()"
            item-text="nom"
            item-value="id"
            label="Composició"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4">
          <v-autocomplete
            v-model="filtre.ingredient"
            :items="ingredientsOpcions"
            @input="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            @click:clear="filtrar()"
            item-text="nom"
            item-value="id"
            label="Tipo de materia"
          ></v-autocomplete>
        </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="materiaPrimera in materiesPrimeres" :key="materiaPrimera.id">
          <td class="text-materia-primera">
            <div
              @click="materiaPrimeraDetail(materiaPrimera.id)"
            ><strong>{{ materiaPrimera.nom }} {{materiaPrimera.codigo_grs? 
            '- '+ materiaPrimera.codigo_grs: ''}} {{materiaPrimera.nom_ingredient? 
            '- '+ materiaPrimera.nom_ingredient: ''}} </strong> - {{textProveidors(materiaPrimera.proveidor)}} </div>
            <div>
              <v-btn
                color="error"
                dark
                @click="btnModalEliminar(materiaPrimera.id)"
              >Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Materia Primera -->
    <!-- Dialog/Modal per eliminar materia primera -->
    <v-dialog v-model="dialogEliminarMateriesPrimeres" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar</v-card-title>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogEliminarMateriesPrimeres = false"
          >Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btneliminarMateriaPrimera()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar materia primera -->
    <!-- Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";

export default {
  name: "materia-primera-llistat",
  data: () => ({
    dialogEliminarMateriesPrimeres: false,
    dialogIngredient: false,
    dialogMateriaPrimera: false,
    dialogEditMateriaPrimera: false,
    dialogTipoMateria: false,
    materiesPrimeresEliminarID: null,
    json_fields: {
        Nom: "nom",
        Proveïdor: "proveidor_nom",
        GRS: "codigo_grs", 
        PREU_QUILO:  {
          field: "preu_quilo",
          callback: value => (value.replace('.',','))
        }, 
        QUILOS: "quilos",
        NUM_BALES: "num_bales"
        
      },
    excelArray: [],
    nombreExcel: "Llistat_MateriesPrimeres.xls",
    ingredient_nou: {
      nom: ""
    },
    tipusMP_nou: {
      nom: ""
    },
    filtre:{ tipo:"", proveidor:""},
    materia_primera_nova: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    pagina: 1,
    totalPagines: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      materiesPrimeres: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors",
      tiposOpcions: "getTipusMateria"
    })
  },
  methods: {
    ...mapActions([
      "getIngredients",
      "getMateriesPrimeres",
      "getProveidors",
      "eliminarMateriaPrimera",
      "sendIngredient",
      "sendMateriaPrimera",
      "getTipusMateria",
      "sendTipusMateria", 
      "getMateriesPrimeresFiltrades"
    ]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      if(!this.filtre.proveidor) this.filtre.proveidor="";
      if(!this.filtre.tipo) this.filtre.tipo="";
      this.getMateriesPrimeresFiltrades(this.filtre);
    },
    //Excel 
    getExcelData(){
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
      this.paginasAñadidas = [];

    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];
    },
    addExcelData(){
      this.excelArray.push.apply(this.excelArray, this.materiesPrimeres); 
      this.excelArray.forEach(element => {
        element.proveidor_nom = this.textProveidors(element.proveidor)
      });
    },

    // Edició de materia primera
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(ingredient => {
        if (ingredient?.data?.id) {
          this.materia_primera_nova.ingredient = ingredient.data.id;
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearTipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(data => {
        if (data?.status === 201) {
          this.materia_primera_nova.tipo = data.data.id;
          this.tipusMP_nou = {
            nom: ""
          };  
          this.dialogTipoMateria = false;
        }
      });
    },
    btnCrearMateriaPrimera() {
      //this.materia_primera_nova.ingredients=[this.materia_primera_nova.ingredients];
      this.sendMateriaPrimera(this.materia_primera_nova).then(materia_primera => {
        if (materia_primera?.data?.id) {
          this.tancarCrearMateriaPrimera();
        }
      });
    },
    btnModalEliminar(materiaPrimera) {
      this.dialogEliminarMateriesPrimeres = true;
      this.materiesPrimeresEliminarID = materiaPrimera;
    },

    btneliminarMateriaPrimera() {
      this.eliminarMateriaPrimera(this.materiesPrimeresEliminarID).then(
        response => {
          if (response === 204) {
            EventBus.$emit("MateriaPrimera.Notificacio", {
              text: "MateriaPrimera eliminat correctament",
              color: "success"
            });
            this.dialogEliminarMateriesPrimeres = false;
            this.materiesPrimeresEliminarID = null;
          } else this.dialogEliminarMateriesPrimeres = false;
        }
      );
    },

    // Rerouting
    materiaPrimeraDetail(materiaPrimeraID) {
      this.$router.push({
        name: "MateriaPrimeraDetail",
        params: { id: materiaPrimeraID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearMateriaPrimera() {
      this.materia_primera_nova = {
        ignifug: false,
        ingredient: [],
        nom: "",
        preu_quilo: null,
        proveidor: ""
      };
      this.dialogMateriaPrimera = false;
    },
    textProveidors(mpProveidor){
      let proveidor = this.proveidorOpcions.find(
        el => el.id === mpProveidor
      );

      if (proveidor) return proveidor?.nom;
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeres.find(el => el.id === mpID);

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor.nom;
      return materiaPrimera?.nom;
    }
  },
  created() {
    this.getTipusMateria(); 
    this.getMateriesPrimeres();
    this.getProveidors();
    this.getIngredients();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  // padding-bottom: 1em;
}
.text-materia-primera {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>