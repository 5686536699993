<template>
  <v-container>
    <!-- Llistat maquines -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="10" offset-sm="1">
            <h1>INFORMES DE STOCK</h1>
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="(informe, index) in informes" :key="index">
          <td class="text-maquina">
            <div> {{informe.data}} </div>

            <!-- <v-btn
                  color="green darken-1"
                  text
                  @click="descargarBtn(informe.id)"
                  tabindex="-1"
                >Generar</v-btn> -->
                
              <v-btn
                  color="green darken-1"
                  text
                  id="generarExcel"
                  ref="buttonGetExcelData"
                  @click="getExcelData(informe, index)"
                  tabindex="-1">Descarregar Informes
              </v-btn>


              <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel"
                class="btn"
                :name="nombreExcel_MP+'_'+informe.data+'.xls'"
                :data="excelArrayMP"
                :fields="json_fields_MP"
                worksheet = "Pàgina 1"
                type="xls">
              </downloadexcel>

              <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel2"
                class="btn"
                :name="nombreExcel_Borra+'_'+informe.data+'.xls'"
                :data="excelArrayBorra"
                :fields="json_fields_Borra"
                worksheet = "Pàgina 1"
                type="xls">
              </downloadexcel>
                            

              
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Fàbriques -->
    <!-- Dialog/Modal per eliminar fàbrica -->
    <v-dialog v-model="dialogEliminarMaquina" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar fàbrica</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarMaquina = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminarMaquina()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar fàbrica -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";
import downloadexcel from "vue-json-excel";

export default {
  name: "maquina-llistat",
  data: () => ({
    dialogMaquina: false,
    dialogEliminarMaquina: false,
    maquinaEliminarID: null,
    numero_contador:[],
    contadores:[],
    prefix_id:0,
    salto:0,
    lasts_values:[],
    maquina_nova: {
      nom: "",
      prefix: "",
    },
    // json_fields_MP: {
    //     NOM_PRODUCTE: "nom",
    //     MAGATZEM: "magatzem",
    //     ZONA: "zona",
    //     NUM_BALES: "num_bales", 
    //     QUILOS: "quilos", 
    //     COST_REAL: "coste_real",
    //     COST_MIG: "coste_medio",
    //   },
          json_fields_MP: {
        NOM_PRODUCTE: "nom_complet",
        PROVEIDOR: "proveidor",
        MAGATZEM: "magatzem",
        ZONA: "zona",
        NUM_BALES: "num_bales", 
        QUILOS: "quilos", 
        COST_TOTAL: "coste_real",
        COST_MIG: "coste_medio",
      },
    json_fields_Borra: {
        NOM_PRODUCTE: "nom",
        MAGATZEM: "magatzem",
        ZONA: "zona",
        NUM_BALES: "num_bales", 
        QUILOS: "quilos", 
        //COST_REAL: "coste_real",
        //COST_MIG: "coste_medio",
        
      },
    excelArray: [],
    excelArrayMP: [],
    excelArrayBorra: [],
    nombreExcel_MP: "Informe_stock",
    nombreExcel_Borra: "Informe_stock_Borra",
    pagina: 1,
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      maquines: "getMaquinas", 
      informes: "getInformes", 
      fabriques: "getFabriques", 
      prefixos: "getPrefixos",
      totalPagines: "getInformesPaginacio",
    })
  },
  methods: {
    ...mapActions(["eliminarMaquina", "getMaquinas","getFabriques", 
    "sendMaquina", 
    "sendGap",
    "resetGap",
    "resetearContador","getInformes", "getInforme",
    "getPrefixos", "getSecuencia","getLastValues"]),

    // Paginació
    paginar() {
      this.getInformes({pagina: this.pagina})
    },

    // Edició de fàbrica
    btnModificar(id){
      this.dialogMaquina=true; 
      this.prefix_id = id;
    },
    btnResetearContador(){
      this.resetearContador(this.prefix_id).then(maquina => {
        this.pagina = maquina;
        if (maquina.new_last_value != this.salto) {
          this.tancarCrearMaquina();
          this.contadores = [];
          this.lasts_values = [];
          this.prefixos.forEach(el => {
          if(el.nom=='1' || el.nom=='2' || el.nom=='3' || el.nom=='10'){
          this.getSecuencia(el.id).then(result=>
          this.contadores.push(result))
          this.getLastValues(el.id).then(result=>
          this.lasts_values.push(result))}})
        }
      });
    },
    btnCrearMaquina() {
      this.sendMaquina(this.maquina_nova).then(maquina => {
        this.pagina = maquina
        if (maquina == 201) {
          this.tancarCrearMaquina();
          
        }
      });
    },
    descargarBtn(informe){
      this.excelArrayMP=[];
      this.excelArrayBorra=[];
      this.getInforme(informe).then(
        response => {
          var data=response.materias_primeras;
          for (const prop in data) {
            for( const prop2 in data[prop].posicions){
              data[prop].posicions[prop2].materies_primeres.forEach(materia=>
                  {
                    this.excelArrayMP.push({
                      nom: materia.materia_primera, 
                      nom_complet: materia.nom_complet, 
                      quilos: materia.quilos.replaceAll('.', ','), 
                      proveidor:  materia.proveidor_nom, 
                      coste_medio: materia.coste_medio.replaceAll('.', ','),
                      coste_real: materia.coste_real.replaceAll('.', ','),
                      num_bales: materia.num_bales,
                      num_bales_disponibles: materia.num_bales_disponibles,
                      quilos_disponibles: materia.quilos_disponibles, 
                      magatzem: data[prop].nom,
                      zona: data[prop].posicions[prop2].zona
                      })
                    })
              }
          }
          var data_borra = response.productes_finals
          for (const prop in data_borra) {
            for( const prop2 in data_borra[prop].posicions){
              data_borra[prop].posicions[prop2].tipus_borra.forEach(materia=>
                  {
                    this.excelArrayBorra.push({
                      nom: materia.tipo_borra, 
                      quilos: materia.quilos.replaceAll('.', ','), 
                      coste_medio: materia.coste_medio.replaceAll('.', ','),
                      coste_real: materia.coste_real.replaceAll('.', ','),
                      num_bales: materia.num_bales,
                      num_bales_disponibles: materia.num_bales_disponibles,
                      quilos_disponibles: materia.quilos_disponibles, 
                      magatzem: data_borra[prop].nom,
                      zona: data_borra[prop].posicions[prop2].zona
                      })
                    })
              }
         
          }
        }
      );
    },
    getExcelData(informe, index){

      this.descargarBtn(informe.id)
      console.log(this.informes)
      this.$refs.buttonGenerarExcel[index].$el.click();
      this.$refs.buttonGenerarExcel2[index].$el.click();

    },
    cambiarNombre(){
      this.nombreExcel_MP = "Informe_stock"
      this.nombreExcel_Borra = "Informe_stock_Borra"
    },

    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    btnCrearGap() {
      //this.sendGap({id: this.prefix_id, data:{num_huecos: this.salto}}).then(maquina => {
      //  if (maquina == 201) {
      //    this.tancarCrearMaquina();
      //  }
      //});
      this.resetGap({id: this.prefix_id, data:{posicion: this.salto}}).then(maquina => {
        this.pagina = maquina;
        if (maquina.new_last_value == this.salto) {
          this.tancarCrearMaquina();
          this.contadores = [];
          this.lasts_values = [];
          this.prefixos.forEach(el => {
          if(el.nom=='1' || el.nom=='2' || el.nom=='3' || el.nom=='10'){
          this.getSecuencia(el.id).then(result=>
          this.contadores.push(result))
          this.getLastValues(el.id).then(result=>
          this.lasts_values.push(result))}})
        }
      });
    },
    btnModalEliminar(maquina) {
      this.dialogEliminarMaquina = true;
      this.maquinaEliminarID = maquina;
    },
    textFabrica(fabricaID) {
      return this.fabriques.find(el => el.id === fabricaID)?.nom;
    },

    btnEliminarMaquina() {
      this.eliminarMaquina(this.maquinaEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Maquina.Notificacio", {
            text: "Maquina eliminat correctament",
            color: "success"
          });
          this.dialogEliminarMaquina = false;
          this.maquinaEliminarID = null;
        } else
          EventBus.$emit("Maquina.Notificacio", {
            text: "Error eliminant el maquina",
            color: "error"
          });
      });
    },

    // Rerouting
    maquinaDetail(maquinaID) {
      this.$router.push({
        name: "MaquinaDetail",
        params: { id: maquinaID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearMaquina() {
      this.maquina_nova = {
        nom: "",
        prefix: "",
      };
      this.dialogMaquina = false;
    }
  },
  created() {

    this.getInformes({pagina:1});
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.text-maquina {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}

</style>