<template>
  <v-container>
    <!-- Llistat Ordres d'Eixida -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1 style="display: contents">Llistat d'Albarans
        <v-btn color="success" :loading='!deshabilitar_tabla' v-if="!deshabilitar_tabla"></v-btn>
        <v-checkbox
            v-model="filtre.llacer"
            @change="filtrar()"
            no-data-text
            clearable
            label="No Llacer"
            style="margin-top:0px; margin-left:5px"
          ></v-checkbox>
        </h1>
        </td>
        <td v-if="grupo!=4">
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="4" >
          <v-autocomplete
            v-model="filtre.facturada"
            :items="opsFact"
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Facturació"
            clearable
            @change="filtrar()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4" >
          <v-autocomplete
            v-model="filtre.client"
            :items="clientOpcions"
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Clients"
            clearable
            @change="filtrar()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4" >
          <v-autocomplete
            v-model="filtre.partida"
            :items="partidaOpcions"
            no-data-text
            outlined
            rounded
            item-text="codi"
            item-value="id"
            label="Partida"
            clearable
            @change="filtrar()"
          >
            <template slot="selection" slot-scope="data">{{ data.item.codi.replaceAll('.', '')  }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
            <template slot="item" slot-scope="data">{{ data.item.codi.replaceAll('.', '') }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody v-if="deshabilitar_tabla">
        <tr v-for="(ordreEixida, index) in ordres" :key="index">
          <td
            @click="albaraDetail(ordreEixida.id)"
            class ="text-instancia"
          >{{ textOrdreEixida(ordreEixida) }}
          <img v-if="ordreEixida.facturada"
            src="https://cdn.pixabay.com/photo/2017/01/13/01/22/ok-1976099_960_720.png"
            alt
          />
          <img v-if="ordreEixida.correo_enviado"
            src="https://cdn.pixabay.com/photo/2014/07/10/14/58/letters-389108_960_720.png"
            alt
          />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Ordres Eixida -->
    <!-- Paginació -->
    <v-pagination
      v-if="deshabilitar_tabla"
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "albarans-llistat",
  data: () => ({
    opsFact:[{id:false, nom: 'Sense facturar'}, {id:true, nom: 'Facturat'}],
    filtre: {
      client: null,
      partida: null,
      estat: null,
      facturada:null,
      llacer:false
    },
    deshabilitar_tabla:false,
    ordresEixidaFiltrades: [],
    paginasAñadidas:[],
    pagina: 1,
    json_fields: {
        NUMERO_ALBARAN: "numero_albaran",
        CLIENT: "client_nom",
        COMPLETA: "completa", 
        EXPEDIDA: "expedida", 
        NUM_BALES: "num_bales",
        DATA_CREACIO: "data_hora", 
        QUILOS: "quilos", 
        PRODUCTES: "partides_linies"
      },
    excelArray: [],
    nombreExcel: "Llistat_OrdresEixida_Albarans",
    carregaInicial: false,
    estatOpcions:[
      {estat: 1, nom: "Sense completar"},
      {estat: 2, nom: "Completa"},
      {estat: 3, nom: "Expedida"}
    ]
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      ordres: "getOrdresEixidaFiltrades",
      partidaOpcions: "getPartidesSimples",
      totalPagines: "getOrdresEixidaPaginacio", 
      grupo: "getGroup"
    })
  },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions([
      "getClients",
      "getMateriesProducteFinal",
      "getOrdresEixidaFiltrades",
      "getPartidesTodas",
      "getPartidesSimples"

    ]),
    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      this.filtre.expedida = true;
      localStorage.setItem('filtre_albarans',  JSON.stringify(this.filtre));
      this.getOrdresEixidaFiltrades(this.filtre);
    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      localStorage.setItem('filtre_albarans',  JSON.stringify(this.filtre));
      this.getOrdresEixidaFiltrades(this.filtre);
    },

    // Rerouting
    albaraDetail(ordreEixidaID) {
      this.$router.push({
        name: "AlbaraOrdreEixidaDetail",
        params: { id: ordreEixidaID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Texts
    textOrdreEixida(ordre) {
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      const data = ordre?.data_hora_expedicio?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
      var productes = ""
      ordre.productos_lineas.forEach(prod=>
      {
        productes = productes+ " - "+prod.replaceAll('.', '') 
        
        if(this.partidaOpcions.find(part => part.codi === prod)?.producte_final_nom)
        {productes = productes+ " " + this.partidaOpcions.find(part => part.codi === prod)?.producte_final_nom + " "}
        if(this.partidaOpcions.find(part => part.codi === prod)?.observacions)
        {productes = productes + this.partidaOpcions.find(part => part.codi === prod)?.observacions }
      })
      return `Nº ${ordre.numero_albaran} - ${client} ${productes}  ${data? `- [${data}] ` : ' ' }`;
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    textClients(ordre) {
      const client = this.clientOpcions.find(el => el.id === ordre.client)?.nom;
      return client;
    },
    textLiniesOrdreEixida(ordre) {
      let partides = (ordre.lineas.map(linia => {
          return this.partidaOpcions.find(el => el.id === linia.partida)?.codi.replaceAll('.', '')
      }))
      partides = [...new Set(partides)]
      return `${partides.length > 0 ? `[${partides}] ` : ' '}`;
    },

    // Auxiliars
    getExcelData(){
      this.excelArray.forEach(elem=> 
      {elem.partides_linies = this.textLiniesOrdreEixida(elem);
      elem.client_nom = this.textClients(elem);
      }
      )
      this.nombreExcel= this.nombreExcel +' '+ new Date().getDate() +'_'+ (parseInt(new Date().getMonth())+1) +
      '_'+ new Date().getFullYear()
      this.$nextTick(() => this.$refs.buttonGenerarExcel.$el.click());
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.ordreEixides); 
      this.paginasAñadidas.push(this.pagina);}
    },
  },
  created() {
    this.deshabilitar_tabla = false;
    this.getClients();
    this.getMateriesProducteFinal();
    if(localStorage.getItem('filtre_albarans')){
      this.filtre=JSON.parse(localStorage.getItem('filtre_albarans'));
      this.pagina = this.filtre.pagina;
      this.getOrdresEixidaFiltrades(this.filtre)
    }else{
      this.getOrdresEixidaFiltrades({pagina:1,expedida:true, llacer:false})
    }
    
    this.getPartidesSimples().then(
      response=>{if(response){
        this.deshabilitar_tabla = true;
      }}
    );
    
  },
  watch: {
    ordreEixides: function(ordres) {
      if (!this.carregaInicial) {
        this.ordresEixidaFiltrades = ordres;
        this.carregaInicial = true;
      }

      // Quan modifiquem ordres (mitjançant petició de filtrat), actualitzem ordres i paginació
      this.ordresEixidaFiltrades = this.ordreEixides;
      this.deshabilitar_tabla = true;
      // this.totalPagines = this.getOrdresEixidaPaginacio;
    }
  }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
img {
  width: 25px;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>