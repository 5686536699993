<template>
  <v-app>
    <v-card >
      <v-app-bar fixed color="primary accent-4" 
                  flat 
                  dark 
                  height="45%">
        <template>
          <v-tabs slider-color="white">
            <!-- Menú Bales -->
            <v-btn 
            class="mx-2"
            @click="$router.go()"
            fab
            dark
            small
            color="primary">
            <v-icon dark>mdi-cached</v-icon>
           </v-btn>
           <v-btn 
            class="mx-2"
            @click="redireccio('Home')"
            fab
            dark
            small
            color="primary">
            <img
              style="height: 25px;"
              src="https://cdn.pixabay.com/photo/2014/04/02/11/15/house-305683_960_720.png"
              alt
              />
           </v-btn>
           <!-- JONATAN -->
<!--            <v-btn 
            class="mx-2"
            @click="redireccio('')"
            fab
            dark
            small
            color="primary">
            <img
              title="Cambiar idioma"
              style="height: 32px;"
              src="../src/assets/traducir.png"
              alt
              />
           </v-btn> -->
           <!-- FIN JONATAN -->
            <v-menu :disabled="habilitarMenu" bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-if="!habilitarMenu && auth.grupo!=1 && auth.grupo!=2 && esPantalla==false" text class="align-self-center mr-4" v-on="on" >
                  Bales
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-subheader>Bales Materia Primera</v-subheader>
                <v-list-item v-if="esPantalla==false " @click="redireccio('BalaSearch')">Buscar Bala</v-list-item>
                <v-list-item v-if="auth.grupo!=2 && auth.grupo!=3 && esPantalla==false " @click="redireccio('BalaCreate')">Crear Bala</v-list-item>
                <v-list-item @click="redireccio('BalaList')">Visualitzar Bales MP</v-list-item>
                <v-subheader>Bales Borra</v-subheader>
                <v-list-item @click="redireccio('BalaPFList')">Visualitzar Bales PF</v-list-item>
              </v-list>
            </v-menu>
            <!-- ./Menú Bales -->
            <!-- Menú Entrades -->
            <v-menu :disabled="habilitarMenu" bottom left>
              <template v-slot:activator="{ on }">
                <v-btn  v-if="!habilitarMenu && auth.grupo!=2 && auth.grupo!=1 && auth.grupo!=3 && esPantalla==false" text class="align-self-center mr-4" v-on="on">
                  Entrades
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item @click="redireccio('EntradaCreate')">Crear Entrada</v-list-item>
                <v-list-item @click="redireccio('EntradaList')">Visualitzar Entrades</v-list-item>
              </v-list>
            </v-menu>
            <!-- ./Menú Bales -->
            <!-- Menú Stock -->
            <v-menu bottom left :disabled="habilitarMenu">
              <template v-slot:activator="{ on }" >
                <v-btn  v-if="!habilitarMenu &&  auth.grupo!=2 && auth.grupo!=1 && auth.grupo!=3 && auth.grupo!=4 && esPantalla==false" text class="align-self-center mr-4" v-on="on">
                  Stock
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                  @click="redireccio('StockMateriaPrimeraPerMagatzemList')"
                >Stock Materia Primera</v-list-item>
                <!--
                <v-list-item @click="redireccio('BalaAssignadaMagatzemsList')">Bales Assignades</v-list-item>
                 -->
                <v-list-item @click="redireccio('StockBorraEnMagatzemsList')">Stock Borra</v-list-item>
              </v-list>
            </v-menu>
            <!-- ./Menú Stock -->
            <!-- Menú Mesclades -->
            <v-menu :disabled="habilitarMenu" bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-if="!habilitarMenu && auth.grupo!=1 && auth.grupo!=2 && auth.grupo!=3 && esPantalla==false" text class="align-self-center mr-4" v-on="on" >
                  Mesclades
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list v-if="auth.grupo!=4" subheader class="grey lighten-3">
                <v-subheader v-if="auth.grupo!=2">Plantilles</v-subheader>
                <v-list-item v-if="auth.grupo!=2" @click="redireccio('PlantillaCreate')">Crear Plantilla</v-list-item>
                <v-list-item v-if="auth.grupo!=2" @click="redireccio('PlantillaList')">Visualitzar Plantilles</v-list-item>
              </v-list>
              <v-list subheader class="grey lighten-3">
                <v-subheader>Mesclades</v-subheader>
                <v-list-item v-if="auth.grupo!=2" @click="redireccio('MescladaCreate')">Crear Mesclada</v-list-item>
                <v-list-item @click="redireccio('MescladaList')">Visualitzar Mesclades</v-list-item>
                <v-list-item v-if="auth.grupo!=2 && auth.grupo!=3 && auth.grupo!=4" @click="redireccio('ProcesarMesclada')">Processar Mesclada</v-list-item>
                
              </v-list>
            </v-menu>
            <!-- ./Menú Mesclades -->
            <!-- Menú Reserves -->
            <v-menu bottom left :disabled="habilitarMenu">
              <template v-slot:activator="{ on }">
                <v-btn v-if="!habilitarMenu && auth.grupo!=1 && auth.grupo!=2 && esPantalla==false" text class="align-self-center mr-4" v-on="on" >
                  Partides
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list subheader class="grey lighten-3">
                  <v-subheader>Partides</v-subheader>
                  <v-list-item @click="redireccio('PartidaList')">Visualitzar Partides</v-list-item>
                </v-list>
              </v-list>
              <v-list v-if="auth.grupo!=2 && esPantalla==false" class="grey lighten-3">
                <v-list subheader class="grey lighten-3">
                  <v-subheader v-if="auth.grupo!=2">Reserves</v-subheader>
                  <v-list-item v-if="auth.grupo!=2 && auth.grupo!=3" @click="redireccio('ReservaCreate')">Crear Reserva</v-list-item>
                  <v-list-item v-if="auth.grupo!=2" @click="redireccio('ReservaList')">Visualitzar Reserves</v-list-item>
                </v-list>
              </v-list>
            </v-menu>
            <!-- ./Menú Reserves -->
            <!-- Menú Ordre Eixida -->
            <v-menu bottom left :disabled="habilitarMenu">
              <template v-slot:activator="{ on }">
                <v-btn v-if="!habilitarMenu && auth.grupo!=2 && auth.grupo!=1 && esPantalla==false" text class="align-self-center mr-4" v-on="on" >
                  Ordre Eixida
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-subheader>Ordres d'eixida</v-subheader>
                <v-list-item v-if="auth.grupo!=3" @click="redireccio('OrdreEixidaCreate')">Crear Ordre d'Eixida</v-list-item>
                <v-list-item v-if="auth.grupo!=3" @click="redireccio('OrdreEixidaCreateNOLL')">Crear Ordre d'Eixida NO LL</v-list-item>
                <v-list-item @click="redireccio('OrdreEixidaList')">Visualitzar Ordres d'Eixida</v-list-item>
                <v-list-item @click="redireccio('CorreccioOrdreEixida')">Corregir Ordres d'Eixida</v-list-item>
                <!-- <v-subheader v-if="auth.grupo!=3" >Materia primera</v-subheader>
                <v-list-item v-if="auth.grupo!=3" @click="redireccio('OrdreEixidaMPCreate')">Crear Eixida Materia Primera</v-list-item> -->
              </v-list>
              <v-list class="grey lighten-3">
                <v-list subheader class="grey lighten-3">
                  <v-subheader>Albarans</v-subheader>
                  <v-list-item @click="redireccio('AlbaraOrdreEixidaList')">Visualitzar Albarans</v-list-item>
                </v-list>
              </v-list>
            </v-menu>
            <!-- ./Menú Ordre Eixida -->
            <v-tab v-if="!habilitarMenu && esPantalla==false " @click="redireccio('MenuTreballadors')" :disabled="habilitarMenu">Menu vistes</v-tab>
            <v-spacer />
            <v-tab v-if="!habilitarMenu && esPantalla" @click="redireccio('PuntPesatgeBales')" :disabled="habilitarMenu">PANTALLA</v-tab>
           <!--  <v-tab v-if="!habilitarMenu && auth.user_id==11" @click="redireccio('PuntPesatgeBales')" :disabled="habilitarMenu">PANTALLA</v-tab> -->
            <v-spacer />
            
            <v-menu :disabled="habilitarMenu" bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-if="!habilitarMenu && auth.grupo!=2 && auth.grupo!=1 && auth.grupo!=3 && auth.grupo!=4 && esPantalla==false" text class="align-self-center mr-4" v-on="on" >
                  Llistats
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list subheader class="grey lighten-3">
                <v-list-item @click="redireccio('ProveidorList')">Proveïdors</v-list-item>
                <v-list-item @click="redireccio('ClientList')">Client</v-list-item>
                <v-list-item @click="redireccio('MagatzemList')">Magatzems</v-list-item>
                <v-list-item @click="redireccio('MateriaPrimeraList')">Matèries Primeres</v-list-item>
                <v-list-item @click="redireccio('TipusMateriaPrimeraList')">Composicions</v-list-item>
                <v-list-item @click="redireccio('IngredientList')">Tipus de materia</v-list-item>
                <v-list-item @click="redireccio('TipusBorraList')">Borres</v-list-item>
                <v-list-item @click="redireccio('FabricaList')">Fàbriques</v-list-item>
                <v-list-item @click="redireccio('MaquinaList')">Màquines</v-list-item>
                <v-list-item @click="redireccio('ContadorList')">Contadores</v-list-item>
                <v-list-item @click="redireccio('InformeList')">Informes</v-list-item>
              </v-list>
            </v-menu>
            <v-tab v-if="auth.jwt" @click="logout()">Logout ({{ auth.nom }})</v-tab>
            <v-tab v-else @click="dialogLogin=true">Login</v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </v-card>

    <!-- Contingut -->
    <v-content>
      <div v-if="this.$route.path=='/'" style="padding: 15%; padding-top: 12%; text-align: center" >
        <h1 @click="refrescarToken" > GESTIÓ INTEGRAL DE BORRERES</h1>
        <h2 style="padding-bottom:2%; font-size: 15mm"> {{ new Date().getFullYear() }} </h2>
        <v-img
          style="margin:auto"
          max-width="80%"
          src="../public/logo.png"
        ></v-img>
        <h3 v-if="habilitarMenu" style="padding-top:8%"> Pulsa "LOGIN" per accedir a l'aplicació </h3>
      </div>
      <v-dialog v-model="dialogLogin" persistent overlay-opacity="0.99" max-width="700">
        <v-card >
          <v-card-title class="headline">Login de l'usuari</v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        <v-card-text>
            <v-text-field v-model="username" id="username" label="Usuario" autocomplete="on" 
            required
            @focus="show"
            rounded
            outlined></v-text-field>
            <v-text-field v-model="password" id="password" label="Password" 
            @focus="show"
            :append-icon="ocultarPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (ocultarPass = !ocultarPass)"
            :type="ocultarPass ? 'password' : 'text'"
            autocomplete="on" v-on:keyup.enter="loginFormSend" required outlined rounded></v-text-field>
            <v-spacer></v-spacer>
            <v-btn v-if="teclat==false" text color="success" @click="teclat= true">Activa teclat</v-btn>
            <v-btn v-if="teclat==true" text color="success" @click="amagaTeclat">Amaga teclat</v-btn>
            <v-spacer></v-spacer>
            <v-btn block color="success" @click="loginFormSend">Login</v-btn>
        </v-card-text>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
        <v-card-actions >
          <v-row style="justify-content: space-around; margin: 0px">
          <v-btn color="primary"
           @click="loginFormSendPantallas(1)">Pantalla 1</v-btn>
          <v-btn color="primary"
           @click="loginFormSendPantallas(2)">Pantalla 2</v-btn>
          <v-btn color="primary"
           @click="loginFormSendPantallas(3)">Pantalla 3</v-btn>
          </v-row>
        </v-card-actions>
            
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAviso.value" persistent overlay-opacity="0.99" max-width="700">
        <v-card >
          <v-card-title class="headline">Aviso: se acerca fin de login</v-card-title>
          <v-card-text justify="center">
          <pre>{{dialogAviso.msg}} segundos</pre>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        <v-card-text>
            Que vols fer?
            <v-spacer></v-spacer>
        </v-card-text>
        <v-card-actions style="width: 50%">
            <v-btn color="red" style="width: 30%" dark block @click="dialogAviso.value = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" style="width: 30%; margin-left: 5%" dark block @click="refrescarToken">Login</v-btn>
        </v-card-actions>

        </v-card>
      </v-dialog>
      
      <Home />
    <vue-touch-keyboard
        id="keyboard"
        v-if="visible"
        layout="compact"
        :cancel="hide"
        :accept="accept"
        :input="input"
        :next="next"
      />
    </v-content>
    <!-- ./Contingut -->
    <!-- Notificació -->
    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}
      <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- ./Notificació -->
    
    <!-- Footer -->
    <!-- ./Footer -->
  </v-app>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import { mapGetters, mapActions } from "vuex";

import Home from "./views/Home";

export default {
  name: "App",

  components: {
    Home
  },

  data: () => ({
    ocultarPass: true,
    teclat: false,
    esPantalla:false,
    habilitarMenu: false,
    habilitarLogo: false,
    visible: false,
    layout: "numeric",
    input: null,
    options: {
      useKbEvents: false
    },
    snackbar: false,
    text: "",
    color: "",
    refrescadoToken:false,
    dialogLogin: false,
    dialogAviso:{ value:false,
    msg:"" 
    },
    username: "",
    password: "", 
    connection: null,
    connection1: null,
    connection2: null
  }),
  computed: {
    ...mapGetters({
      auth: "getAuth", 
      grupo: "getGroup"
    })
    
  },
  methods: {
    ...mapActions(["userType","load_initial_data","login","refreshToken",
    "refreshToken_infinito", "getGroup",
    "refreshToken_infinito_ivan",
    "refreshToken_infinito_raul",
    "comprobar_token"]),

    amagaTeclat(){
      this.teclat = false; 
      this.hide();
    },
    notificacio(data) {
      this.text = data.text;
      this.color = data.color;
      this.snackbar = true;
    },
    comprobarMenu(){
      if (localStorage.getItem('jwt')){
        this.habilitarMenu = false;
      }else{
        this.habilitarMenu = true;
      }
    },
    comprobarPagina(){
      if (this.$route.path== "/"){
        this.habilitarLogo = false;
      }else{
        this.habilitarLogo = true;
      }
    },
    refreshLoop(){
          async function getRefresh(){
                EventBus.$emit('refrescadoToken')
          }
          setInterval( getRefresh            
          , 2000);
    },
    jose(){
      this.comprobar_token()
    },
    // Auth
    redireccio(component) {
      this.$router.push({ name: component });
    },
    logout() {
      localStorage.removeItem("nom");
      localStorage.removeItem("jwt");
      localStorage.removeItem("filtre_entrades");
      localStorage.removeItem("filtre_partides");
      localStorage.removeItem("filtre_albarans");
      localStorage.removeItem("filtre_stockMP");
      localStorage.removeItem("filtre_cargas");
      //this.dialogLogin = true; 
      this.$router.go() 
      
    },
    loginFormSend() {
      this.login({ username: this.username, password: this.password })
      this.username ="";
      this.password ="";
    }, 
    loginFormSendPantallas(pantalla) {
      if(pantalla==1){
        this.login({ username: 'MAQ_1', password: 'maq1maq1' })
      }
      if(pantalla==2){
        this.login({ username: 'MAQ_2', password: 'maq2maq2' })
      }
      if(pantalla==3){
        this.login({ username: 'MAQ_3', password: 'maq3maq3' })
      }
      
      this.username ="";
      this.password ="";
    },
    refrescarToken(){
      this.refreshToken(localStorage.getItem("jwt"));
      this.refrescadoToken=false;
    },
    accept() {
      // Cierra el Keypad
      this.hide();
    },
    show(e) {
      // Muestra el keypad en poner el focus en un input
      if(this.teclat)
      {this.input = e.target;
      this.layout = e.target.dataset.layout;

      if (!this.visible) this.visible = true;}
    },
    hide() {
      // Cierra el Keypad
      this.visible = false;
    },
    next() {
      // Selecciona el siguiente input
      let inputs = document.querySelectorAll("input");
      let found = false;
      [].forEach.call(inputs, (item, i) => {
        if (!found && item == this.input && i < inputs.length - 1) {
          found = true;
          this.$nextTick(() => {
            inputs[i + 1].focus();
          });
        }
      });
      if (!found) {
        this.input.blur();
        this.hide();
      }
    },
  },
  created: function() {
    this.getGroup();
    if(localStorage.getItem('nom')=='Pantalla1'){
      this.esPantalla=true
    }
    if(localStorage.getItem('nom')=='Pantalla2'){
      this.esPantalla=true
    }
    if(localStorage.getItem('nom')=='Pantalla3'){
      this.esPantalla=true
    }
    this.refreshToken_infinito()
    if(localStorage.getItem('nom')=='raul' )
      {this.refreshToken_infinito_raul()}
      if(localStorage.getItem('nom')=='ivan')
      {this.refreshToken_infinito_ivan()}
    console.log("Starting connection to WebSocket Server")
    //this.connection = new WebSocket("wss://dev.recuperadosllacer.com/ws/notificaciones/FrontEnd/")
    this.connection = new WebSocket("wss://gib.recuperadosllacer.com/ws/notificaciones/FrontEnd/")
    this.connection.onopen = function(event) {
      console.log(event)
      console.log("Successfully connected to the echo websocket server...")
    }
    this.connection.onmessage = function(m) {
      console.log("Recibido: ", JSON.parse(m.data).pegatina);
      if(JSON.parse(m.data).type =="error"){
          EventBus.$emit("Bala.Notificacio", {
            text: JSON.parse(m.data).message,
            color: "error"
          });
        }
      if(JSON.parse(m.data).request_id == localStorage.getItem('uuid'))
        {
        if(JSON.parse(m.data).type =="error"){
          EventBus.$emit("Bala.Notificacio", {
            text: JSON.parse(m.data).message,
            color: "error"
          });
        }
        else if(JSON.parse(m.data).type =="pdf.download")
        {const byteString = window.atob(JSON.parse(m.data).pegatina);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        EventBus.$emit("Bala.Notificacio", {
            text: "Petició d'impresió rebuda",
            color: "green"
          });
          console.log(url)
        window.open(url, '_blank');}
        }
    }
    // TODO - VER SI HACE FALTA
    /* this.connection1 = new WebSocket("wss://dev.recuperadosllacer.com/ws/notificaciones/Pantalla1/")

    this.connection1.onopen = function(event) {
      console.log(event)
      console.log("Successfully connected to the echo websocket server...")
    }
    this.connection1.onmessage = function(m) {
      console.log("Recibido: ", JSON.parse(m.data).pegatina);
      if(JSON.parse(m.data).request_id == localStorage.getItem('uuid'))
        {const byteString = window.atob(JSON.parse(m.data).pegatina);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        EventBus.$emit("Bala.Notificacio", {
            text: "Petició d'impresió rebuda",
            color: "green"
          });
          console.log(url)
        window.open(url, '_blank');
        }
    }
    this.connection2 = new WebSocket("wss://dev.recuperadosllacer.com/ws/notificaciones/Pantalla2/")

    this.connection2.onopen = function(event) {
      console.log(event)
      console.log("Successfully connected to the echo websocket server...")
    }
    this.connection2.onmessage = function(m) {
      console.log("Recibido: ", JSON.parse(m.data).pegatina);
      if(JSON.parse(m.data).request_id == localStorage.getItem('uuid'))
        {const byteString = window.atob(JSON.parse(m.data).pegatina);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        EventBus.$emit("Bala.Notificacio", {
            text: "Petició d'impresió rebuda",
            color: "green"
          });
          console.log(url)
        window.open(url, '_blank');
        }
    } */
    this.load_initial_data();
    this.refreshLoop();
    this.comprobarMenu();
    this.comprobarPagina();
    if(localStorage.getItem('jwt') && localStorage.getItem('user_id')){
       this.userType(localStorage.getItem('user_id')).then(
         response => this.data_user = response
       );
     }
    
    /* EventBus.$on('refrescadoToken',function (){console.log('Recibido')}); */
    EventBus.$on('dialogLogin',function (dialogLogin){ if(this.dialogLogin != dialogLogin){this.dialogLogin= dialogLogin;}}.bind(this));
    EventBus.$on('dialogAviso',function (dialogAviso){ if(this.dialogAviso.value != dialogAviso.value){this.dialogAviso.value= dialogAviso.value; this.dialogAviso.msg=dialogAviso.time}}.bind(this));
    EventBus.$on("Auth.Notificacio", this.notificacio);
    EventBus.$on("Bala.Notificacio", this.notificacio);
    EventBus.$on("Client.Notificacio", this.notificacio);
    EventBus.$on("Entrada.Notificacio", this.notificacio);
    EventBus.$on("Fabrica.Notificacio", this.notificacio);
    EventBus.$on("Ingredient.Notificacio", this.notificacio);
    EventBus.$on("Magatzem.Notificacio", this.notificacio);
    EventBus.$on("MateriaPrimera.Notificacio", this.notificacio);
    EventBus.$on("MateriaProducteFinal.Notificacio", this.notificacio);
    EventBus.$on("Mesclada.Notificacio", this.notificacio);
    EventBus.$on("Mesclada.Notificacio", this.notificacio);
    EventBus.$on("OrdreEixida.Notificacio", this.notificacio);
    EventBus.$on("Partida.Notificacio", this.notificacio);
    EventBus.$on("PercentatgeMesclat.Notificacio", this.notificacio);
    EventBus.$on("Plantilla.Notificacio", this.notificacio);
    EventBus.$on("Proveidor.Notificacio", this.notificacio);
    EventBus.$on("Reserva.Notificacio", this.notificacio);
    EventBus.$on("TipusBorra.Notificacio", this.notificacio);
  },
  destroyed: function() {
    EventBus.$off("dialogLogin");
    EventBus.$off("dialogAviso");
    EventBus.$off("Auth.Notificacio", this.notificacio);
    EventBus.$off("Bala.Notificacio", this.notificacio);
    EventBus.$off("Client.Notificacio", this.notificacio);
    EventBus.$off("Entrada.Notificacio", this.notificacio);
    EventBus.$off("Fabrica.Notificacio", this.notificacio);
    EventBus.$off("Ingredient.Notificacio", this.notificacio);
    EventBus.$off("Magatzem.Notificacio", this.notificacio);
    EventBus.$off("MateriaPrimera.Notificacio", this.notificacio);
    EventBus.$off("MateriaProducteFinal.Notificacio", this.notificacio);
    EventBus.$off("Mesclada.Notificacio", this.notificacio);
    EventBus.$off("OrdreEixida.Notificacio", this.notificacio);
    EventBus.$off("Partida.Notificacio", this.notificacio);
    EventBus.$off("PercentatgeMesclat.Notificacio", this.notificacio);
    EventBus.$off("Plantilla.Notificacio", this.notificacio);
    EventBus.$off("Proveidor.Notificacio", this.notificacio);
    EventBus.$off("Reserva.Notificacio", this.notificacio);
    EventBus.$off("TipusBorra.Notificacio", this.notificacio);
  }
};
</script>

<style lang="scss">
@import "../node_modules/vue-touch-keyboard/dist/vue-touch-keyboard.css";
footer {
  color: #fff !important;
  background-color: #1976d2 !important;
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100%;
  cursor: alias;
}
.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 64px;
    padding: 0 5px !important;
}
.v-application .mr-4 {
    margin-right: 4px !important;
    margin-left: 2px !important;
}
.v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: contents !important;
    visibility: hidden;
}
.card {
  padding: 15px;
  .h1 {
  padding: 15px;
  text-align: center;
  padding-bottom: 1em;
  }
  .button {
    padding-top: 1em;
    width: 46%;
  }
}
// Keypad
  #keyboard {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;

    z-index: 1000;
    width: 90%;
    max-width: 950px;
    margin: 0 auto;
    max-height: auto;
    padding: 1em;

    background-color: #eee;
    box-shadow: 0px -3px 10px rgba(black, 0.3);

    border-radius: 10px;
  }
  
</style>