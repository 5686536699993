<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Plantilla -->
      <h1>Creació de Plantilla</h1>
      <!-- Plantilla Base -->
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="plantilla.nom" label="Nom" autofocus outlined required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="plantilla.producte_final"
            :items="materiesProducteFinalOpcions"
            label="Producte Final"
            item-value="id"
            item-text="nom"
            no-data-text="No s'ha pogut carregar cap producte final"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear materia producte final -->
          <v-dialog v-model="dialogProducteFinal" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear materia producte final</v-card-title>
              <v-container>
                <v-form id="formMateriaProducteFinal" ref="formMateriaProducteFinal" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="materia_producte_final_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="materia_producte_final_nou.descripcio"
                        label="Descripció"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogProducteFinal = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearProducteFinal"
                  tabindex="-1"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear materia producte final -->
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="plantilla.observacions" outlined label="Observacions"></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Plantilla Base -->
      <!-- ./Plantilla -->
      <!-- Percentatges -->
      <v-row>
        <v-col sm="2" offset-sm="8">
          <v-btn color="info" @click="afegirIngredient()" tabindex="-1">Afegir Ingredient</v-btn>
        </v-col>
      </v-row>
      <v-row v-for="(item, index) in plantilla.percentatges" :key="index" id="ingredients">
        <v-col sm="2" offset-sm="0">
          <v-text-field
            v-model.number="item.percentatge"
            label="Percentatge"
            @input="limitarPercentatge(index, item.percentatge)"
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="4" offset-sm="0">
          <v-autocomplete
            v-model="item.ingredient"
            :items="ingredientsOpcions"
            label="Ingredient"
            item-value="id"
            item-text="nom"
            no-data-text="No s'ha pogut carregar cap ingredient"
            outlined
          />
        </v-col>
        <v-col sm="2" offset-sm="0">
          <v-btn
            outlined
            block
            color="success"
            dark
            @click="btnCrearIngredientModal(index)"
            tabindex="-1"
          >+</v-btn>
        </v-col>
        <v-col sm="2" offset-sm="1">
          <v-btn color="error" @click="eliminaIngredient(index)" tabindex="-1">Eliminar</v-btn>
        </v-col>
      </v-row>
      <!-- ./Percentatges -->
    </v-form>
    <!-- Form -->
    <div id="botonera">
      <v-btn
        dark
        color="error"
        @click="redireccio('PlantillaList')" 
      >Cancel·lar</v-btn>
      <v-btn
        color="success"
        @click="crearPlantilla"
        :disabled="comprovadorPercentatge"
      >Crear</v-btn>
      
    </div>
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
    <!-- Dialog/Modal per crear ingredients -->
    <v-dialog v-model="dialogIngredient" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Crear tipus de materia</v-card-title>
        <v-container>
          <v-form id="formIngredient" ref="formIngredient" class="mt-12">
            <v-row>
              <v-col md="12">
                <v-text-field v-model="ingredient_nou.nom" label="Nom" required autofocus @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogIngredient = false"
            tabindex="-1"
          >Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnCrearIngredient">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per crear ingredients -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "plantilla-create",
  data: () => ({
    plantilla: {
      nom: "",
      observacions: "",
      percentatges: [{ percentatge: 0, ingredient: null }],
      producte_final: null
    },
    dialogIngredient: false,
    dialogProducteFinal: false,
    nouIngredientIndex: null,
    ingredient_nou: {
      nom: ""
    },
    materia_producte_final_nou: {
      nom: "",
      descripcio: ""
    }
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      materiesProducteFinalOpcions: "getMateriesProducteFinal"
    }),
    comprovadorPercentatge() {
      let total = 0;
      const percentatges = this.plantilla.percentatges.map(
        el => el.percentatge
      );

      // Comprovem que cap percentatge siga un 0
      if (percentatges.includes(0)) return true;

      // Comprovem que la suma de tots els percentatges siga 100
      percentatges.forEach(el => {
        if (!isNaN(parseFloat(el))) total += parseFloat(el);
      });

      if (total != 100) return true;

      // Comprovem que els materials siguen diferents i no tinguen cap element null
      const ingredients = this.plantilla.percentatges.map(el => el.ingredient);
      if (
        ingredients.length != new Set(ingredients).size ||
        ingredients.includes(null)
      )
        return true;
      return false;
    }
  },
  methods: {
    ...mapActions([
      "getIngredients",
      "getMateriesProducteFinal",
      "sendIngredient",
      "sendMateriaProducteFinal",
      "sendPlantilla"
    ]),

    // Crear plantilla
    crearPlantilla() {
      this.sendPlantilla(this.plantilla);
    },

    // Ingredients
    afegirIngredient() {
      this.plantilla.percentatges.push({ percentatge: 0, ingredient: null });
    },
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
          this.plantilla.percentatges[this.nouIngredientIndex].ingredient =
            data.data.id;
        }
      });
    },
    btnCrearProducteFinal() {
      this.sendMateriaProducteFinal(this.materia_producte_final_nou).then(
        data => {
          if (data?.status === 201) {
            this.materia_producte_final_nou = {
              nom: "",
              descripcio: ""
            };
            this.dialogProducteFinal = false;
            this.plantilla.producte_final = data.data.id;
          }
        }
      );
    },
    btnCrearIngredientModal(index) {
      this.dialogIngredient = true;
      this.nouIngredientIndex = index;
    },
    eliminaIngredient(index) {
      if (this.plantilla.percentatges.length > 1)
        this.plantilla.percentatges.splice(index, 1);
    },

    // Auxiliar
    limitarPercentatge(index, percentatge) {
      if (percentatge <= 0) {
        this.$nextTick(() => {
          this.plantilla.percentatges[index].percentatge = 0;
        });
      }
      if (percentatge >= 100) {
        this.$nextTick(() => {
          this.plantilla.percentatges[index].percentatge = 100;
        });
      }
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getIngredients();
    this.getMateriesProducteFinal();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
#ingredients {
  border: grey solid 1px;
  border-radius: 15px;
  margin-bottom: 15px;
}
</style>