<template>
  <v-container>
    <!-- Configuració del Stepper -->
    <v-stepper v-model="pas">
      <v-stepper-items>
        <!-- Primera pàgina -->
        <v-stepper-content step="1">
          <!-- Mesclades -->
          <v-form id="pas-1" ref="form" class="mt-12">
            <h1 id="titol-pas-1">Creació de Mesclada</h1>
            <v-row>
              <v-col sm="12">
                <v-autocomplete
                  v-model="of.prefix"
                  :items="maquinas"
                  label="Referencia"
                  item-text="nom"
                  item-value="id"
                  no-data-text
                  outlined
                  autofocus
                >
                <template slot="selection" slot-scope="data">{{ (data.item.nom.replaceAll('.','')) }}</template>
            <template slot="item" slot-scope="data">{{(data.item.nom.replaceAll('.','')) }}</template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col sm="12">
                <v-autocomplete
                  v-model="of.estat"
                  :items="estatOpcions"
                  label="Estat"
                  item-text="nom"
                  item-value="id"
                  no-data-text                  
                  outlined
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col sm="12">
                <v-text-field
                  v-model="of.observacions"
                  label="Observacions"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-autocomplete
                  v-model="of.plantilla"
                  :items="plantillesOpcions"
                  label="Plantilla"
                  @change="carregarPlantilla()"
                  item-text="nom"
                  item-value="id"
                  no-data-text
                  
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8">
                <v-autocomplete
                  v-model="of.producte_final"
                  :items="materiesProducteFinalOpcions"
                  label="Producte Final"
                  item-value="id"
                  item-text="nom"
                  no-data-text="No s'ha pogut carregar cap producte final"
                  @input="elegidoProducto" 
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col sm="1">
                <!-- Dialog/Modal per crear materia producte final -->
                <v-dialog v-model="dialogProducteFinal" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="grupo!=4" outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear materia producte final</v-card-title>
                    <v-container>
                      <v-form
                        id="formMateriaProducteFinal"
                        ref="formMateriaProducteFinal"
                        class="mt-12"
                      >
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="materia_producte_final_nou.nom"
                              label="Nom"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="materia_producte_final_nou.descripcio"
                              label="Descripció"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="materia_producte_final_nou.cost_trinxat" label="Cost trinxat"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        tabindex="-1"
                        @click="dialogProducteFinal = false"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearProducteFinal"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear materia producte final -->
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-if="grupo!=4"
                  v-model="of.cost_trinxat"
                  label="Trinxat"
                  @click="vaciarTrinxat"
                  required
                  outlined
                  :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-if="grupo!=4"
                  v-model="of.preu_venda"
                  label="Preu venta"
                  @click="vaciarPreuVenda"
                  required
                  outlined
                  :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-if="grupo!=4"
                  v-model="of.cost_financier"
                  label="Cost financer"
                  @click="vaciarCostFinancier"
                  required
                  outlined
                  :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-model="of.cost_transport"
                  label="Cost transport"
                  @click="vaciarCostTransport"
                  required
                  outlined
                  :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-text-field v-model="of.quilos_deseados" label="Quilos aprox" type="number" required
                  @click="vaciarQuilosAprox"
                  outlined
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8">
                <v-text-field v-model="of.num_quartos_mesclat" label="Quartos" type="number" 
                required
                
                outlined></v-text-field>
              </v-col>
              <v-col sm="1">
                <v-btn width="25%" block color="primary" dark @click="of.num_quartos_mesclat= of.num_quartos_mesclat+1" style="font-size: 25px" tabindex="-1">+</v-btn>
              </v-col>
              <v-col sm="1" style="margin-left:10px">
                <v-btn width="25%" block color="primary" dark @click="restarQuarto" style="font-size: 25px" tabindex="-1">-</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <!-- ./Mesclades -->
          <div class="botonera">
            <v-btn color="error" @click="btnCancelar" tabindex="-1">Cancel·lar</v-btn>
            <v-btn color="primary" 
             @click="btnCrear"
             :disabled="of.num_quartos_mesclat <= 0 || !of.prefix || !of.producte_final || of.preu_venda.includes(',') || of.cost_transport.includes(',')"
             tabindex="-1">Crear</v-btn>
            <v-btn
              color="success"
              @click="btnDistribuir"
              :disabled="of.num_quartos_mesclat <= 0 || !of.prefix || !of.producte_final || of.preu_venda.includes(',') || of.cost_transport.includes(',') "
            ><span style="white-space: normal;">Crear i distribuïr</span></v-btn>
          </div>
        </v-stepper-content>
        <!-- ./Primera pàgina -->
      </v-stepper-items>
    </v-stepper>
    <!-- ./Configuració del Stepper -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('MescladaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import { EventBus } from "@/event-bus.js";

export default {
  name: "mesclada-create",
  data: () => ({
    of: {
      id: null,
      prefix: null,
      estat: "-",
      prioritat: 1,
      plantilla: null,
      producte_final: null,
      // quilos_aprox: 0,
      cost_trinxat:0,
      quilos_deseados:0,
      num_quartos_mesclat: 1, 
      quartos_mesclat:[], 
      preu_venda:"0",
      cost_transport:"0",
      cost_financier:"0"
    },
    maquinas:[],
    ver_contadores:"",
    boton_eliminarBalas: false,
    msg_quarto:{num_quarto:"", bales:""},
    dialogQuarto:false,
    bala_assignada_id: 0,
    quartos_vacios:[],
    noves_porcions:[],  
    quartos_existents:[],
    estatOpcions: [
      { id: "-", nom: "Incompleta" },
      { id: "c", nom: "En cua" },
      { id: "e", nom: "Execució" },
      { id: "i", nom: "Interrompuda" },
      { id: "a", nom: "Acabada" },
    ],
    prioritatOpcions: [
      { id: 1, nom: "Baixa" },
      { id: 2, nom: "Mitjana" },
      { id: 3, nom: "Alta" },
      { id: 4, nom: "Urgent" }
    ],
    balaAssignar: {
      barcode: null,
      ingredient: null,
      id: null,
      materia_primera: null,
      preu_quilo: null,
      quilos: null
    },
    dialogBala: false, 
    dialogError: false, 
    msg_error: [],
    quartos_mesclat: [],
    barcodeACarregar: null,
    bales_carregades: [],
    porcions_mesclat: [],
    dialogProducteFinal: false,
    proporcio_actual: "g",
    proporcions_actuals: [],
    plantilla_carregada: {percentatges:[]},
    pas: 1,
    quartos: [],
    materia_producte_final_nou: {
      nom: "",
      descripcio: ""
    }
  }),
  computed: {
    ...mapGetters({
      // ingredientsOpcions: "getIngredients",
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      prefixosOpcions: "getPrefixos",
      plantillesOpcions: "getPlantilles",
      grupo: "getGroup"
      // proveidorOpcions: "getProveidors"
    }),

    // Comprovadors
    calcular_kg_actual() {
      return this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) || 0),
        0
      );
    },
    calcular_preu_actual() {
      return this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) * b.preu_quilo || 0),
        0
      );
    },
    comprovadorSumatoriKG() {
      // Si no hi ha plantilla, no hi ha objectiu i sempre és correcte
      if (!this.of.plantilla) return "comprovador-exacte";
      const objectiuKG = parseInt(this.of.quilos_deseados);
      const actualKG = this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) || 0),
        0
      );
      if (objectiuKG === actualKG) return "comprovador-exacte";
      return "comprovador-error";
    },
    cost_per_kg() {
      const cost_per_kg = (
        this.calcular_preu_actual / this.calcular_kg_actual
      ).toFixed(2);
      return !isNaN(cost_per_kg) ? cost_per_kg : 0;
    }
  },
  methods: {
    ...mapActions([
      "getBalaBarcode",
      "getSecuencia",
      "getLastValues",
      "getIngredients",
      "getMateriesPrimeres",
      "getMateriesProducteFinal",
      "getProveidors",
      "getPlantilla",
      "getPlantilles",
      "getPrefixos",
      "sendMesclada",
      "sendMateriaProducteFinal"
      
    ]),

    btnCrearProducteFinal() {
      this.sendMateriaProducteFinal(this.materia_producte_final_nou).then(
        data => {
          if (data?.status === 201) {
            this.materia_producte_final_nou = {
              nom: "",
              descripcio: ""
            };
            this.dialogProducteFinal = false;
            this.of.producte_final = data.data.id;
          }
        }
      );
    },
    vaciarQuilosAprox(){
      if(this.of.quilos_deseados==0){
        this.of.quilos_deseados=""
      }
    },
    vaciarPreuVenda(){
      if(this.of.preu_venda=="0"){
        this.of.preu_venda=""
      }
    },
    elegidoProducto(){
      this.of.cost_trinxat= this.materiesProducteFinalOpcions.find(el=> el.id==this.of.producte_final).cost_trinxat;
    },
    vaciarTrinxat(){
      if(this.of.cost_trinxat=="0"){
        this.of.cost_trinxat=""
      }
    },
    vaciarCostFinancier(){
      if(this.of.cost_financier=="0"){
        this.of.cost_financier=""
      }
    },
    vaciarCostTransport(){
      if(this.of.cost_transport=="0"){
        this.of.cost_transport=""
      }
    },
    mostrarQuarto(quarto){
      if(this.dialogQuarto==false){this.dialogQuarto = true}else{
        this.dialogQuarto=false;}
      this.dialogQuarto=true;
      this.msg_quarto= {bales:this.arrayBalesDeQuarto(quarto), num_quarto: quarto};
    },
    restarQuarto(){
      if(this.of.num_quartos_mesclat>0) this.of.num_quartos_mesclat= this.of.num_quartos_mesclat-1;
    },
    focusCodi(){
      this.$refs.campBarcode.focus();
      // this.$nextTick(() => this.focusCodi());
    },
    tancarDialogError(){
      this.dialogError = false;
      this.dialogBala = false;
      this.dialogQuarto = false; 
      this.boton_eliminarBalas = false;
      this.$nextTick(() => this.focusCodi());
    },
    desactivarBotonConfirmar(){
      if(this.quartos.length === 0){
        return true
      }else{
        if(this.of.plantilla){
          if(this.balaAssignar.ingredient){return false}else{return true}
        }else{
          return false
        }
      }
    },
    contadorBala(){
      this.porcions_mesclat.forEach(balas=> 
        balas.contador=this.porcions_mesclat.filter(porcio=> porcio.bala==balas.bala).length)
    },
    contarBalas(porcions){
      var total=0;
      porcions.forEach(el=> total = parseFloat(1/el.contador)+total)
      return total.toFixed(2)
    },
    btnDistribuir() {
      if(this.of.quilos_deseados =="") this.of.quilos_deseados = 0;
      if(this.of.cost_transport =="") this.of.cost_transport = 0;
      if(this.of.cost_financier =="") this.of.cost_financier = 0;
      if(this.of.preu_venda =="") this.of.preu_venda = 0;
      this.sendMesclada(this.of).then(mesclada =>
      { if(mesclada.id)
      {this.$router.push({ name: 'MescladaEdit', params: { id: mesclada.id, partida:1 } })}
      });
    },
    btnCrear() {
      if(this.of.quilos_deseados =="") this.of.quilos_deseados = 0;
      if(this.of.cost_transport =="") this.of.cost_transport = 0;
      if(this.of.cost_financier =="") this.of.cost_financier = 0;
      if(this.of.preu_venda =="") this.of.preu_venda = 0;
      if(this.of.quilos_deseados =="") this.of.quilos_deseados = 0;
      this.sendMesclada(this.of).then(
      response=> {if(response.id)
      {this.redireccio('MescladaList')}}
      );
    },
    btnSendMesclada() {
      this.Cuartos_i_porcions();
      this.of.quartos_mesclat = this.quartos_mesclat;
      this.sendMesclada(this.of).then(mezclada => {
        this.quartos_vacios.forEach(el=> this.crearQuartosMesclat({quarto: el.num_quarto, ordre_fabricacio: mezclada.id }))
        this.$router.push({ name: 'MescladaDetail', params: { id: mezclada.id } })
        // if (error) {
        //   error.forEach(msg => {
        //     this.$nextTick(function() {
        //       this.balaError[msg] = true;
        //     });
        //   });
        // }
      });
      
    },
    // Plantilla
    carregarPlantilla() {
      this.getPlantilla(this.of.plantilla).then(plantilla => {
        this.plantilla_carregada = plantilla;
        this.proporcions_actuals = [];
        this.of.producte_final = plantilla.producte_final;
        plantilla.percentatges.forEach(el =>
          this.proporcions_actuals.push({
            ingredient: el.ingredient,
            percentatge: 0,
            quilos: 0,
            bales: []
          })
        );
      });
    },
    btnCancelar() {
      this.$router.push({
        name: "Home"
      });
    },
    textIngredient(ingredient) {
      return this.ingredientsOpcions.find(el => el.id === ingredient)?.nom;
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return materiaPrimera?.nom;
    },
    textMateriaPrimeraProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor.nom;
      return materiaPrimera?.nom;
    }
  },
  created() {
    // this.getIngredients();
    // this.getMateriesPrimeres();
    this.getMateriesProducteFinal();
    // this.getProveidors();
    this.getPrefixos().then( prefixos=>
      {prefixos.forEach(el=> {this.getSecuencia(el.id)})
      this.maquinas = prefixos}
    );
    this.getPlantilles();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

.container {
  max-width: 100% !important;
}
/* Creació */

/* Distribució */
#distribucio {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    height: 100%;
  }

  #quartos {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 50%);

    .quarto {
      width: 95%;
      height: 200px;
      margin: 0.5em 0em;
      background: rgb(187, 187, 187);
      border-radius: 10px;
      color: white;
      overflow: hidden;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1.5vw;
      }

      .bales-info {
        text-align: center;
        overflow-y: scroll;
        height: 100%;
        padding-bottom: 1em;
        p{
          margin-bottom: 5px;
        }
        .bala-parcial{
          margin-bottom: 5px;
          background: burlywood;
        }
      }

      h3 {
        margin-left: 1em;
      }
      button{
        font-size: 1.4vw;
      }
    }
    .quarto-seleccionat {
      background: gold;
    }
  }

  #info {
    width: 40%;
    margin-bottom: 0px;
  }
}

// Botonera
.botonera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  button {
    width: 30%;
    font-size: 1.8vw;
  }
}
.botonera-crear {
  padding-top: 0px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
    font-size: 1.8vw;
  }
}

// Panell info
#panell-info {
  max-width: 80%;
  margin: auto;
  background: rgb(223, 217, 217);

  #taula-info {
    max-width: 90%;
    margin: auto;

    .row {
      height: 4vh;
      .col-sm-4 {
        font-weight: bold;
        text-align: left;
        padding: 0 2px;
      }
      .col-sm-6 {
        text-align: right;
        padding: 0 2px;
      }
    }
  }
  #ingredients {
    background: white;
    max-width: 90%;
    margin: auto;
    .v-radio {
      padding-left: 16px;
    }

    .row {
      .col-sm-12 {
        padding: 6px 12px;
      }
    }
  }

}
.botonera2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1vh;
}
// Targetes
#proporcions-dialog{
  outline: 2px dashed black;
  background-color: blanchedalmond;
  border-color: black;
  font-size: 2.5vh; 
  color: black;
  margin-left: 10vw; 
  width:20vw;
  margin-bottom: 1.5vh;
  text-align: center;
}
.proporcions-dialog-error{
  outline: 2px dashed black;
  background-color: rgb(249, 117, 104)!important;
  border-color: black;
  font-size: 2.5vh; 
  color: black;
  margin-bottom: 1.5vh;
  text-align: center;
  justify-content: center;
}
.v-card {
  color: black !important;
}

.v-card__text {
  margin: 0.2em 0em;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
#cards-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;

  .comprovador-error {
    background: rgba(255, 103, 103, 1);
  }
  .comprovador-exacte {
    background: rgba(129, 237, 129, 1);
  }

  #pes {
    width: 100%;
    margin-bottom: 1%;
  }
  #proporcions {
    width: 100%;
    background: rgba(129, 237, 129, 1);

    div {
      padding-top: 0px !important;
      margin-top: 0px !important;
    }

    .proporcions-columnes {
      width: 100%;
      display: flex;
      flex-direction: row;

      div {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

#cost-total {
  background: rgb(157, 157, 230);
}
// Stepper
.container {
  padding-top: 2.2em;
}
.row{
    padding-bottom: 0px;
    height: 75px;
  }
#pas-1{
  margin-top: 0px !important;
  .h1{
    padding-bottom: 0px !important;
  }
  .row{
    padding-bottom: 0px;
    height: 5%;
  }
}
#pas-1 h1{
  padding-bottom: 0px !important;
}
#pas-1 .row{
  padding-bottom: 0px;
  height: 75px;
}

// Imatges
img {
  width: 20px;
}

// Selector d'ingredients sense plantilla
#selector-ingredients {
  padding: 0 3em;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}
</style>