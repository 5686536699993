<template>
  <v-container>
    <!-- Reserva -->
    <v-simple-table v-if="reserva" class="mt-12">
      <thead>
        <h1>Vista detallada de Reserva</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Data</b>
          </td>
          <td>{{ textData(reserva.data_creacio) }} </td>
        </tr>
        <tr>
          <td>
            <b>Estat</b>
          </td>
          <td>{{ textEstat(reserva.estat) }} </td>
        </tr>
        <tr>
          <td>
            <b>Quilos</b>
          </td>
          <td>{{ reserva.quilos }} kg</td>
        </tr>
        <tr v-if="reserva.quilos_enviats">
          <td>
            <b>Quilos enviats</b>
          </td>
          <td>{{ reserva.quilos_enviats }} kg</td>
        </tr>
        <tr v-if="reserva.quilos_enviats">
          <td>
            <b>Quilos restants</b>
          </td>
          <td>{{ parseInt(reserva.quilos)-reserva.quilos_enviats }} kg</td>
        </tr>
        <tr v-if="reserva.formalitzada">
          <td>
            <b>Formalitzada</b>
          </td>
          <td>{{ reserva.formalitzada ? 'Formalitzada' : 'No formalitzada'}}</td>
        </tr>
        <tr v-if="reserva.num_pedido">
          <td>
            <b>Número de pedido</b>
          </td>
          <td>{{ reserva.num_pedido }}</td>
        </tr>
        <tr>
          <td>
            <b>Client</b>
          </td>
          <td>{{ reserva.client }}</td>
        </tr>
        <tr>
          <td>
            <b>Partida</b>
          </td>
          <td>{{ reserva.nombre_producto }}</td>
        </tr>
        <tr v-if="reserva.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ reserva.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="primary" dark @click="btnEditarReserva">Editar reserva</v-btn>
      <v-btn  v-if="reserva.quilos_enviats==0" color="error" dark @click="dialog=true">Anular reserva</v-btn>
      <v-btn v-if="reserva.estat!=3 && reserva.quilos_enviats!=0" color="error" dark @click="dialogFin=true" >Finalitzar reserva</v-btn>
      <!-- Dialog/Modal per eliminar reserva -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Confirmar eliminar reserva</v-card-title>
          <v-card-text>Està segur que vol anular esta reserva?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarReserva">Anular</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar reserva -->
      <v-dialog v-model="dialogFin" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Confirmar Finalitzar reserva</v-card-title>
          <v-card-text>Està segur que vol Finalitzar esta reserva?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogFin = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnFinalitzarReserva">Finalitzar</v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Reserva -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%" >
      <v-col  class="text-center" cols="12" @click="redireccio('ReservaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "reserva-create",
  data: () => ({
    reserva: {
      quilos: null,
      formalitzada: false,
      observacions: "",
      num_pedido: "",
      client: null,
      partida: null
    },
    estatOpcions: [
      { id: 0, nom: "Pendiente" },
      { id: 1, nom: "Asignada" },
      { id: 2, nom: "Parcialment finalitzada" },
      { id: 3, nom: "Finalitzado" }
    ],
    dialog: false,
    dialogFin: false
  }),
  methods: {
    ...mapActions([
      "eliminarReserva",
      "getClient",
      "getMesclada",
      "getPartida_new",
      "getReserva", 
      "anularReserva", 
      "finalizarReserva"
    ]),

    // Botonera
    btnEditarReserva() {
      this.$router.push({
        name: "ReservaEdit",
        params: { id: this.reserva.id }
      });
    },
    btnEliminarReserva() {
      this.anularReserva(this.reserva.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Reserva.Notificacio", {
            text: "Reserva anulada correctament",
            color: "success"
          });
          this.$router.push({ name: "ReservaList" });
        } else
          EventBus.$emit("Reserva.Notificacio", {
            text: "Error anulant la reserva",
            color: "error"
          });
      });
    },
    btnFinalitzarReserva() {
      this.finalizarReserva(this.reserva.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Reserva.Notificacio", {
            text: "Reserva finalitzada correctament",
            color: "success"
          });
          this.$router.push({ name: "ReservaList" });
        } else
          EventBus.$emit("Reserva.Notificacio", {
            text: "Error finalitzant la reserva",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textData(data_hora) {
      return data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    },
    textEstat(estat) {
      return this.estatOpcions.filter(el => el.id === estat)[0]?.nom;
    },
  },
  created() {
    this.getReserva(this.$route.params.id).then(
      reserva => (this.reserva = reserva)
    ).then(() => {
      this.getClient(this.reserva.client).then(client => this.reserva.client = client?.nom)
      this.getPartida_new(this.reserva.partida).then(mesclada => this.reserva.partida = mesclada?.codi.replaceAll('.', ''))
    });
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>