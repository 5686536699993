<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Reserva -->
      <h1>Creació de Reserva</h1>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            outlined
            v-model="reserva.client"
            :items="clientsOpcions"
            label="Client"
            item-text="nom"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap client"
            autofocus
          ></v-autocomplete>
        </v-col>
        <v-col v-if="grupo!=4" sm="1">
          <!-- Dialog/Modal per crear client -->
          <v-dialog v-model="dialogClient" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear client</v-card-title>
              <v-container>
                <v-form id="formClient" ref="formClient" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="client_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.emails" label="Correu"></v-text-field>
                      </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogClient = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearClient"
                  tabindex="-1"
                  :disabled="!client_nou.nom || !client_nou.poblacio"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear client -->
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-autocomplete
            v-model="reserva.partida"
            :items="partidesOpcions"
            label="Partida"
            item-value="id"
            item-text="codi"
            no-data-text="No s'ha pogut carregar cap partida"
            outlined
            @input="comprobarEstado(reserva.partida)"
          >
          <template slot="selection" slot-scope="data">{{ data.item.codi.replaceAll('.', '')  }} - {{ data.item.producte_final_nom  }}{{textObservacions(data.item.observacions)}}
            - Disp: {{ data.item.quilos_disponibles  }}kg  </template>
          <template slot="item" slot-scope="data">{{ data.item.codi.replaceAll('.', '') }} - {{ data.item.producte_final_nom  }}{{textObservacions(data.item.observacions)}}
            - Disp: {{ data.item.quilos_disponibles  }}kg
          </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field
            v-model="reserva.num_pedido"
            label="Num pedido"
            no-data-text="No s'ha pogut carregar cap partida"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" >
          <v-text-field v-model="reserva.quilos" 
          outlined
          :rules="[v => v <=(estado.estado_reservas.quilos_disponibles) || 'Superats kg disponibles ('+(estado.estado_reservas.quilos_disponibles)+ 'kg)']" 
          label="Quilos" required></v-text-field>
        </v-col>
        <!-- 
        <v-col sm="6">
          <v-text-field v-model="reserva.quilos" 
          outlined
          :rules="[v => v <=estado.estado_reservas.quilos_disponibles || 'Superats kg disponibles ('+estado.estado_reservas.quilos_disponibles+ 'kg)']" 
          label="Quilos a añadir a la reserva" required></v-text-field>
        </v-col>
        -->
        <v-col sm="6">
          <v-text-field v-model="estado.estado_reservas.quilos_disponibles" 
          rounded
          outlined
          label="Quilos disponibles" required></v-text-field>
        </v-col>
        <v-col sm="5" style="padding-top: 0px; padding-bottom: 0px; ">
             <v-switch v-model="reserva.completa" class="mx-2" label="Completa?" 
             @change="cambioSwitch()"></v-switch>
        </v-col>  
      </v-row>
      <!-- 
      <v-row v-if="quilos_reserva.quilos">
        <v-col sm="6">
          <v-text-field v-model="quilos_reserva.quilos" 
          outlined
          rounded
          readonly
          label="Quilos reserva actual" required></v-text-field>
        </v-col>
        <v-col sm="6">
          <v-text-field v-model="quilos_reserva.quilos_enviats" 
          outlined
          rounded
          readonly
          label="Quilos enviats" required></v-text-field>
        </v-col>
      </v-row>
      -->
      <!-- <v-row>
        <v-col sm="12">
          <v-checkbox v-model="reserva.formalitzada" label="Formalitzada"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="reserva.num_pedido" outlined label="Número de comanda"></v-text-field>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="reserva.observacions" outlined label="Observacions"></v-text-field>
        </v-col>
      </v-row>

      <!-- ./Reserva -->
    </v-form>
    
    <div id="botonera">
      <v-btn
        
        block
        color="success"
        @click="crearReserva"
        :disabled="!reserva.quilos || !reserva.client || !reserva.partida"
      >Crear reserva</v-btn>
      <!-- 
      <v-btn
        
        block
        color="success"
        @click="btnIncrementarReserva"
        :disabled="!reserva.quilos || !reserva.client || !reserva.partida"
      >Incrementar quilos reserva</v-btn>-->
    </div>
    
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "reserva-create",
  data: () => ({
    reserva: {
      quilos: "",
      formalitzada: false,
      observacions: "",
      num_pedido: "",
      client: null,
      partida: null
    },
    quilos_disp:0,
    quilos_reserva:{
      quilos:"", 
      quilos_enviats: ""
    },
    existente: false, 
    reserva_id: "",
    estado:{
      estado_reservas:[]
    },
    dialogClient: false,
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    }
  }),
  computed: {
    ...mapGetters({
      clientsOpcions: "getClients",
      partidesOpcions: "getPartidesTodas", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions(["getClients", 
                  "getPartides", 
                  "getPartidesTodas", 
                  "getPartidesTodasDispo", 
                  "getEstadoReserva", 
                  "sendClient", 
                  "sendReserva",
                  "incrementarReserva",
                  "getReservesFiltrades"
                  ]),

    // Crear element
    crearReserva() {
      this.sendReserva(this.reserva).then(
        response => 
        this.existente = response
      );
    },
    btnIncrementarReserva() {
      // sin acabar, ¿como saco el id de la reserva?
      this.incrementarReserva({id: this.reserva_id, quilos: parseInt(this.reserva.quilos)}).then(response=>
          {if(response.data){
            EventBus.$emit("Reserva.Notificacio", {
              text: "Reserva incrementada de quilos correctament",
              color: "success"
            });
             this.$router.push({
              name: "ReservaDetail",
              params: { id: this.reserva_id }
            });
          }})
    },
    comprobarEstado(partida){
      this.getEstadoReserva(partida).then(response => {
        this.estado = response
        if(response.estado_reservas.clients.find(el=> el.id==this.reserva.client)){
          this.existente = true;
          this.quilos_disp=(response.estado_reservas.quilos_disponibles+response.estado_reservas.quilos_reservats-response.estado_reservas.quilos_enviats);
          this.getReservesFiltrades({client: this.reserva.client, partida: this.partidesOpcions.find(el=> el.id == partida)?.id})
          .then(reserva => 
          {this.reserva_id = reserva.result[0].id;
          this.quilos_reserva.quilos = parseInt(reserva.result[0].quilos);
          this.quilos_reserva.quilos_enviats = reserva.result[0].quilos_enviats;
          })
        }else{
          this.existente = false;
          this.quilos_reserva = {quilos:"", quilos_enviats:""} 
        }
        })
    },
    btnCrearClient() {
      this.sendClient(this.client_nou).then(data => {
        if (data.status === 201) {
          this.client_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogClient = false;
          this.reserva.client = data.data.id;
        }
      });
    },

    cambioSwitch() {
      this.reserva.quilos = this.estado.estado_reservas.quilos_disponibles;
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    // Auxiliar

    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getClients();
    //this.getPartidesTodasDispo();
    if(this.$route.params.partida){
      this.reserva.partida = this.$route.params.partida;
      this.comprobarEstado(this.$route.params.partida);
    }
    this.getPartidesTodasDispo({internas: '1'});
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.col{
height: auto;
}

#botonera {
  padding-bottom: 50px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>