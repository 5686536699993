<template>
  <v-container>
    <!-- Llistat Ingredients -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="8" offset-sm="1">
            <h1>Llistat de Tipus de materia</h1>
          </v-col>
          <v-col sm="1" offset-sm="1">
            <!-- Dialog/Modal per crear ingredient -->
            <v-dialog v-model="dialogIngredient" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear tipus de materia</v-card-title>
                <v-container>
                  <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="ingredient_nou.nom" label="Nom" autofocus required @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCrearIngredient">Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearIngredient"
                    :disabled="!ingredient_nou.nom"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear ingredient -->
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="ingredient in ingredients" :key="ingredient.id">
          <td class="text-ingredient">
            <div @click="ingredientDetail(ingredient.id)">{{ ingredient.nom }}</div>
            <div>
              <v-btn color="error" dark @click="btnModalEliminar(ingredient.id)">Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Ingredients -->
    <!-- Dialog/Modal per eliminar ingredient -->
    <v-dialog v-model="dialogEliminarIngredient" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar ingredient</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarIngredient = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminarIngredient()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar ingredient -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "ingredient-llistat",
  data: () => ({
    dialogIngredient: false,
    dialogEliminarIngredient: false,
    ingredientEliminarID: null,
    ingredient_nou: {
      nom: ""
    },
    pagina: 1,
    totalPagines: 1
  }),
  computed: {
    ...mapGetters({
      ingredients: "getIngredients"
    })
  },
  methods: {
    ...mapActions(["eliminarIngredient", "getIngredients", "sendIngredient"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    // Edició de ingredient
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(ingredient => {
        if (ingredient?.data?.id) {
          this.tancarCrearIngredient();
        }
      });
    },
    btnModalEliminar(ingredient) {
      this.dialogEliminarIngredient = true;
      this.ingredientEliminarID = ingredient;
    },

    btnEliminarIngredient() {
      this.eliminarIngredient(this.ingredientEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Ingredient eliminat correctament",
            color: "success"
          });
          this.dialogEliminarIngredient = false;
          this.ingredientEliminarID = null;
        } else
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Error eliminant el ingredient",
            color: "error"
          });
      });
    },

    // Rerouting
    ingredientDetail(ingredientID) {
      this.$router.push({
        name: "IngredientDetail",
        params: { id: ingredientID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearIngredient() {
      this.ingredient_nou = {
        nom: ""
      };
      this.dialogIngredient = false;
    }
  },
  created() {
    this.getIngredients();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.text-ingredient {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>