<template>
  <v-container>
    <!-- Fàbrica -->
    <v-simple-table v-if="fabrica" class="mt-12">
      <thead>
        <h1>Vista detallada de fabrica</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ fabrica.nom }}</td>
        </tr>
        <tr>
          <td>
            <b>Préfix</b>
          </td>
          <td>{{ fabrica.prefix }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per editar fabrica -->
      <v-dialog v-model="dialogEditFabrica" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark @click="btnDialogFabrica" v-on="on">Editar fàbrica</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar fàbrica</v-card-title>
          <v-container>
            <v-form id="formFabrica" ref="formFabrica" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="fabrica_edit.nom" label="Nom" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="fabrica_edit.prefix" label="Prefix" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditFabrica = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btneditFabrica"
              :disabled="!fabrica_edit.nom"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar fàbrica -->
      <!-- Dialog/Modal per eliminar fàbrica -->
      <v-dialog v-model="dialogeliminarFabrica" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar fàbrica</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar fàbrica</v-card-title>
          <v-card-text>Està segur que vol eliminar esta fàbrica?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogeliminarFabrica = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btneliminarFabrica">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar fàbrica -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Fàbrica -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('FabricaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "fabrica-detail",
  data: () => ({
    fabrica: {
      nom: "",
      prefix: "",
    },
    fabrica_edit: {
      nom: "",
      prefix: "",
    },
    dialogEditFabrica: false,
    dialogeliminarFabrica: false
  }),
  methods: {
    ...mapActions(["editFabrica", "eliminarFabrica", "getFabrica"]),

    // Botonera
    btnDialogFabrica() {
      this.fabrica_edit = JSON.parse(JSON.stringify(this.fabrica));
    },
    btneditFabrica() {
      this.editFabrica(this.fabrica_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Fabrica editat correctament",
            color: "success"
          });
          this.dialogEditFabrica = false;
          this.getFabrica(this.fabrica.id).then(
            fabrica => (this.fabrica = fabrica)
          );
        } else
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Error editant la fàbrica",
            color: "error"
          });
      });
    },
    btneliminarFabrica() {
      this.eliminarFabrica(this.fabrica.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Fabrica eliminat correctament",
            color: "success"
          });
          this.$router.push({ name: "FabricaList" });
        } else
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Error eliminant la fàbrica",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getFabrica(this.$route.params.id).then(
      fabrica => (this.fabrica = fabrica)
    );
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>