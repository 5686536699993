<template>
  <div id="vista-info-bales">
    <div id="container">
    <!-- Escanejar bala pel llistat -->

    <!-- ./Escanejar bala pel llistat -->
      <!-- Dades -->
      <v-row id="dades" justify="center">
        <!-- Llistat bales seleccionades -->
        <v-col sm="12">
          <v-row id="llistat-bales-titol">
            <h3>Escanea una bala</h3>
          </v-row>
          <div id="info-llistat-bales">
            <div class="separador"></div>
            <v-row id="bales-seleccionades-1">
              <v-col>
                <v-text-field
                  v-model="codiEscanejatLlistat"
                  label="Codi escanejat"
                  v-on:keyup.enter="carregaCodi"
                  autofocus 
                  outlined
                  required
                  background-color="white"
                  ref="campBarcode"
                ></v-text-field>
              </v-col>
              <v-col v-if="info_bala">
                <v-row id="bales-seleccionades-2">
                <div class="ficha-info" v-if="infoBalaBorra">
                  <v-simple-table >
                  <!-- <thead>
                    <h1>Vista detallada de stock de borra</h1>
                  </thead> -->
                  <tbody>
                    <tr>
                      <td>
                        <b>Barcode</b>
                      </td>
                      <td>{{ infoBalaBorra.barcode }}</td>
                    </tr>
                    <tr v-if="infoBalaBorra.estat!='e'">
                      <td>
                        <b>Estat</b>
                      </td>
                      <td>{{ textEstatBala(infoBalaBorra.estat) }}</td>
                    </tr>
                    <tr v-else>
                      <td>
                        <b>Estat</b>
                      </td>
                      <td>{{ textEstatBala(infoBalaBorra.estat) }} - 
                        {{textOrdreEixida(infoBalaBorra.linea_ordre_eixida)}}</td>
                    </tr>
                    <tr infoBalaBorra.fabrica>
                      <td>
                        <b>Fàbrica</b>
                      </td>
                      <td>{{ infoBalaBorra.fabrica }}</td>
                    </tr>
                    <tr v-if="infoBalaBorra.cuarto ">
                      <td>
                        <b>Fabricada en quarto</b>
                      </td>
                      <td>{{ infoBalaBorra.cuarto }}</td>
                    </tr>
                    <tr v-if="infoBalaBorra.estat=='e'">
                      <td>
                        <b>Magatzem</b>
                      </td>
                      <td>{{ infoBalaBorra.magatzem }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Partida</b>
                      </td>
                      <td>{{ infoBalaBorra.partida }}</td>
                    </tr>
                    <tr v-if="grupo!=4">
                      <td>
                        <b>Cost quilo</b>
                      </td>
                      <td>{{ infoBalaBorra.cost_kg.toFixed(2) }} €/kg</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Quilos</b>
                      </td>
                      <td>{{ infoBalaBorra.quilos }} kg</td>
                    </tr>
                    <tr v-if="infoBalaBorra.reservada">
                      <td>
                        <b>Reservada</b>
                      </td>
                      <td>{{ infoBalaBorra.reservada ? 'Sí' : 'No' }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tipus de borra</b>
                      </td>
                      <td>{{ infoBalaBorra.tipus_borra }}</td>
                    </tr>
                    <tr v-if="infoBalaBorra.observacions">
                      <td>
                        <b>Observacions</b>
                      </td>
                      <td>{{ infoBalaBorra.observacions }}</td>
                    </tr>
                  </tbody>
                   </v-simple-table>
                </div>
                <div class="ficha-info" v-if="infoBalaMP">
                      <v-simple-table>
                      <!-- <thead>
                        <h1>Vista detallada de Bala</h1>
                      </thead> -->
                      <tbody>
                        <tr v-if="infoBalaMP.barcode">
                          <td>
                            <b>Barcode</b>
                          </td>
                          <td>{{ infoBalaMP.barcode }}</td>
                        </tr>
                        <tr v-if="infoBalaMP.creada_en">
                          <td>
                            <b>Data creació</b>
                          </td>
                          <td>{{ infoBalaMP.creada_en.split("T",1)[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1') }}</td>
                        </tr>
                        <tr v-if="infoBalaMP.quilos">
                          <td>
                            <b>Quilos</b>
                          </td>
                          <td>{{ infoBalaMP.quilos }} kg</td>
                        </tr>
                        <tr v-if="infoBalaMP.preu_quilo && grupo!=4">
                          <td>
                            <b>Preu quilo (€/kg)</b>

                          </td>
                          <td>{{ parseFloat(infoBalaMP.preu_quilo).toFixed(3) }} €/kg</td>
                        </tr>
                        <tr v-if="infoBalaMP.estat">
                          <td>
                            <b>Estat</b>
                          </td>
                          <td>{{ textConsumida(infoBalaMP.estat) }}</td>
                        </tr>
                        <tr v-if="visible_todo">
                          <td>
                            <b>Partida</b>
                          </td>
                          <td>{{ infoBalaMP.partida_codi }} {{ infoBalaMP.nom_producte_final }} {{ infoBalaMP.partida_observacions }} </td>
                        </tr>
                        <tr></tr>
                        <tr v-if="infoBalaMP.materia_primera">
                          <td>
                            <b>Materia primera</b>
                          </td>
                          <td>{{ infoBalaMP.materia_primera }}</td>
                        </tr>
                        <tr></tr>
                        <tr v-if="infoBalaMP.entrada" @click="entradaDetail(infoBalaMP.entrada)">
                          <td>
                            <b>Entrada</b>
                          </td>
                          <td>{{textEntrada(entrada) }}</td>
                        </tr>
                        <tr v-if="infoBalaMP.estat!=4">
                          <td>
                            <b>Magatzem</b>
                          </td>
                          <td>{{ infoBalaMP.magatzem }}</td>
                        </tr>
                        <tr v-if="infoBalaMP.observacions">
                          <td>
                            <b>Observacions</b>
                          </td>
                          <td>{{ infoBalaMP.observacions }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                </div>
                </v-row>
              </v-col>
            
            <div class="separador"></div>
            <v-col v-if="infoBalaBorra || infoBalaMP">
              <!-- Botons d'opcions -->
              <div class="botonera">
                <v-btn x-large color="normal" dark @click="BorrarBala">Borrar info</v-btn>
                <v-btn v-if="grupo !=2 &&  grupo !=1 &&  grupo !=3" x-large color="primary" dark @click="btnEditarBala">Editar</v-btn>
                <v-btn v-if="grupo !=2 &&  grupo !=1 &&  grupo !=3" x-large color="error" dark @click="EliminarBala">Eliminar</v-btn>
              </div>
              <!-- Dialog/Modal per eliminar bala -->
                <v-dialog v-model="dialog" persistent max-width="600">
                  <v-card>
                    <v-card-title class="headline">Confirmar eliminar bala</v-card-title>
                    <v-card-text>Està segur que vol eliminar esta bala?</v-card-text>
                    <v-card-actions>
                      <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="btnEliminarBala">Eliminar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per eliminar bala -->
              <!-- Botons d'opcions -->
            </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- /Llistat bales seleccionades -->
        <!-- Dades posició emmagatzenament -->
        <!-- ./Dades posició emmagatzenament -->
      </v-row>
      <!-- ./Dades -->
      <!-- Footer -->
      <v-footer class="font-weight-medium" height="38%">
        <v-col class="text-center" cols="12" @click="redireccio('MenuTreballadors')" style="padding:0px">
          <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
      <!-- ./Footer -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "vista-info-bales",
  data: () => ({
    visible_todo:"false",
    codiEscanejatLlistat: "",
    infoBalaBorra:"",
    infoBalaMP:"",
    info_bala:false,
    dialog: false,
    entrada:{},
    balesSeleccionades: [],
    dades: {
      magatzem: 0,
      fila: 0
    }, 
    estatBalaOpcions: [
      { id: "c", nom: "Creada" },
      { id: "m", nom: "En magatzem" },
      { id: "a", nom: "Asignada" },
      { id: "e", nom: "Expedida" }
    ],
  }),
  computed: {
    ...mapGetters({
      borres: "getBorres",
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      magatzems: "getMagatzems",
      fabriques: "getFabriques",
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getBalaProducteFinalBarcode",
      "getBalaBarcode",
      "getPartida_new",
      "getQuartoMesclat",
      "getBalaParcial",
      "getBorres",
      "getBorra",
      "getFabriques",
      "getFabrica",
      "getMateriaPrimera",
      "getMagatzem",
      "getMagatzemNumero",
      "getProveidor",
      "getQuartoMesclat",
      "getMateriaProducteFinal",
      "getOrdreEixida", 
      "getPartida", 
      "getEntrada", 
      "getLiniaOrdreEixida", "eliminarBala",
      "eliminarBorra"
    ]),
    // Escaneja bales
    BorrarBala(){
      this.infoBalaBorra="";
      this.infoBalaMP="";
      this.info_bala=false;
      this.dialog = false;
      this.focusCodi();
    },
    EliminarBala(){
      this.dialog = true;
    },
    btnEliminarBala() {
      var bala_id; 
      if(this.infoBalaBorra){
        bala_id = this.infoBalaBorra.id;
        this.eliminarBorra(bala_id).then(response => {
        if (response === 204) {
          this.BorrarBala();
          this.$nextTick(() => this.focusCodi());
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala eliminada correctament",
            color: "success"
          });
          
        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error eliminant la bala",
            color: "error"
          });
      });
      }
      if(this.infoBalaMP){
        bala_id = this.infoBalaMP.id;
        this.eliminarBala(bala_id).then(response => {
        if (response === 204) {
          this.BorrarBala();
          this.$nextTick(() => this.focusCodi());
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala eliminada correctament",
            color: "success"
          });
          this.BorrarBala();
          this.$nextTick(() => this.focusCodi());

        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error eliminant la bala",
            color: "error"
          });
      });
      }
      
    },
    btnEditarBala() {
      if(this.infoBalaMP)
      {this.$router.push({ name: "BalaEdit", params: { id: this.infoBalaMP.id } });}
      if(this.infoBalaBorra)
      {this.$router.push({ name: "PartidaBalaEdit", params: { id: this.infoBalaBorra.id } });}
    },
    carregaCodi() {
      this.visible_todo= false;
      this.info_bala=false;
      if (this.codiEscanejatLlistat == "") {this.cerrardialogMagatzem; return;}
      if (this.codiEscanejatLlistat.charAt(0) == "9"){
          this.codiEscanejatLlistat="";
          return;
        }
      if (this.codiEscanejatLlistat.charAt(0) == "1"){
      this.getBalaProducteFinalBarcode(this.codiEscanejatLlistat).then(bala => {       
          if (bala?.id) {
          this.infoBalaMP = null;
          this.infoBalaBorra = bala;
          this.textQuarto(bala.cuarto);
          this.textFabrica(bala.fabrica);
          this.textMagatzemBorra(parseInt(bala.posicion_almacenamiento.substr(1, 3)));
          this.textPartida(bala.partida);
          this.textBorra(bala.tipus_borra);
          
          this.codiEscanejatLlistat="";
          return;
        }else{
          this.codiEscanejatLlistat="";
          return;
        }
          });
      }else if(this.codiEscanejatLlistat.charAt(0) == "0"){     
      this.getBalaBarcode(this.codiEscanejatLlistat).then(bala => {
        if (bala?.id) {
          this.infoBalaBorra = null;
          this.infoBalaMP = bala;
          this.textMagatzem(parseInt(bala.posicion_almacenamiento.substr(1, 3)));
          this.textMateriaPrimera(bala.materia_primera);
          this.codiEscanejatLlistat="";
          if(bala.entrada>0){
              this.getEntrada(bala.entrada).then(
                entrada=>{
                  this.entrada = entrada
                }
              )
            }
          
          if(bala.estat==2){
            this.visible_todo = false;
            this.getBalaParcial(bala.barcode).then(bala_parcial=>{
            if(bala_parcial[0].quarto>0){
              this.getQuartoMesclat(bala_parcial[0].quarto).then(quarto=>
             {if(quarto.ordre_fabricacio)
               {
                 if(quarto.ordre_fabricacio_estat=="a" || quarto.ordre_fabricacio_estat=="e" || quarto.ordre_fabricacio_estat=="i")
                 {this.getPartida_new(quarto.ordre_fabricacio)
                .then(mesclada => {
                  this.mesclada = mesclada;

                  this.getMateriaProducteFinal(mesclada.producte_final).then(
                    materia_PF =>{
                      this.infoBalaMP.nom_producte_final = materia_PF.nom
                      this.infoBalaMP.partida_codi = mesclada.codi.replaceAll('.', '')
                      this.infoBalaMP.partida_observacions =`${mesclada.observacions? ` ${mesclada.observacions} `:''}`;
                      this.visible_todo = true;
                    }
                  )
                })}else{this.getMesclada_new(quarto.ordre_fabricacio)
                .then(mesclada => {
                  this.mesclada = mesclada;

                  this.getMateriaProducteFinal(mesclada.producte_final).then(
                    materia_PF =>{
                      this.infoBalaMP.nom_producte_final = materia_PF.nom
                      this.infoBalaMP.partida_codi = mesclada.codi?.replaceAll('.', '')
                      this.infoBalaMP.partida_observacions =`${mesclada.observacions? ` ${mesclada.observacions} `:''}`;
                      this.visible_todo = true;
                    }
                  )
                })}

                }
              })
          }});
      } 
          if(bala.estat==4){
            this.visible_todo = false;
            this.getBalaParcial(bala.barcode).then(bala_parcial=>{
            if(bala_parcial[0].quarto>0){
              this.getQuartoMesclat(bala_parcial[0].quarto).then(quarto=>
             {if(quarto.ordre_fabricacio)
               {this.getPartida_new(quarto.ordre_fabricacio)
                .then(mesclada => {
                  this.mesclada = mesclada;

                  this.getMateriaProducteFinal(mesclada.producte_final).then(
                    materia_PF =>{
                      this.infoBalaMP.nom_producte_final = materia_PF.nom
                      this.infoBalaMP.partida_codi = mesclada.codi.replaceAll('.', '')
                      this.infoBalaMP.partida_observacions =`${mesclada.observacions? ` ${mesclada.observacions} `:''}`;
                      this.visible_todo = true;
                    }
                  )
                })}
              })
          }});
      } 
          return;
        }else{
          this.codiEscanejatLlistat="";
          return;
        }
      });
      }
    },
    textFabrica(fabricaID) {
      if(fabricaID>0)
      {this.getFabrica(fabricaID).then(
        fabricaInfo=>{
          this.infoBalaBorra.fabrica = fabricaInfo.nom
        }
      )}

    },
    textEntrada(entrada) {
      return `
      ${
        entrada.observacions ? ` ${entrada.observacions} ` : ""
      }
      ${
        entrada.quilos_total ? ` Bales: ${entrada.bales.length}  - [${entrada.quilos_total} kg] ` : ""
      } ${entrada.data_albara ? " - " + entrada.data_albara.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1') : ""}
      
      `;
    },
    // Rerouting
    entradaDetail(entradaID) {
      this.$router.push({ name: "EntradaDetail", params: { id: entradaID } });
    },
    textMagatzem(magatzemNum) {
      if(magatzemNum>0){
      this.getMagatzemNumero(magatzemNum).then(magatzem => {
        this.infoBalaMP.magatzem =  magatzem[0]?.nom;
        if(parseInt(this.infoBalaMP.posicion_almacenamiento.substr(4, 2))>0){
              this.infoBalaMP.magatzem = magatzem[0]?.nom + ' - Nº '+ this.infoBalaMP.posicion_almacenamiento.substr(4, 2)
            }
      })} else {
        this.infoBalaMP.magatzem =  "Sense Magatzem"
      }
    },
    textBorra(tipusBorraID) {
      if(tipusBorraID>0){
        this.getMateriaProducteFinal(tipusBorraID).then(borra=>
        {
          this.infoBalaBorra.tipus_borra = borra.nom;
        })
      }
    },
    textMagatzemBorra(magatzemNum) {
      if(magatzemNum>0){
      this.getMagatzemNumero(magatzemNum).then(magatzem => {
        this.infoBalaBorra.magatzem =   magatzem[0]?.nom;
      })} else {
        this.infoBalaBorra.magatzem =   "Sense Magatzem"
      }
    },
    textQuarto(id){
      if(id){
        this.getQuartoMesclat(id).then(quartoInfo=>{
          this.infoBalaBorra.cuarto = quartoInfo.quarto
        })
      }
    },
    textPartida(partidaID) {
      if(partidaID){
      this.getPartida(partidaID).then(partida => {
        this.info_bala = true;
        this.infoBalaBorra.partida= partida?.codi.replaceAll('.', '');
        this.infoBalaBorra.cost_kg = parseFloat(partida.cost_materies_primeres)/parseFloat(partida.quilos_borra_totales)
      })} else {
        return 
      }
    },
    textOrdreEixida(ordreID){
      this.getLiniaOrdreEixida(ordreID).then(response => this.tipusBorra=response)
    },
    textTipusBorra(tipusBorraID) {
      return this.tipusBorra.find(el => el.id === tipusBorraID)?.nom;
    },
    textConsumida(valor) {
      if (valor == 4) return "Consumida";
      else if (valor == 1) return "Sin asignar";
      else if (valor == 2 || valor == 3) return "En proceso de consumir";
      else if (valor == 5) return "Asignada a Carga";
      else if (valor == 6) return "Expedida";
    },
    textMateriaPrimera(mpID) {
      if(mpID>0){this.getMateriaPrimera(mpID).then(
        mp=>{
          if(mp.proveidor){
            this.getProveidor(mp.proveidor).then(proveidor2=>{
              this.info_bala = true;
              return this.infoBalaMP.materia_primera = mp?.nom +" - "+ mp?.codigo_grs +" - "+ mp?.nom_ingredient + " - " + proveidor2.nom ;
            })
          }else{
              this.info_bala = true;
              return this.infoBalaMP.materia_primera = mp?.nom +" - "+ mp?.codigo_grs +" - "+ mp?.nom_ingredient;
          }
        });}      
    },
    textEstat(estat) {
      return this.estatOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    textEstatBala(estat) {
      return this.estatBalaOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    focusCodi(){
      this.$refs.campBarcode.focus();
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created(){
    this.getFabriques;
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

h1,
h2,
h3,
h3,
h4,
p,
span {
  font-size: 2vh;
}
.text-mostrar-bales {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  div:first-child {
    width: 20%;
  }
  div:nth-child(2) {
    width: 60%;
  }
  div:nth-child(3) {
    width: 20%;
  }
}

footer > div > span {
  font-size: 1em !important;
}

img {
  width:3vw;
}
.v-data-table td {
    font-size: 2vh;
    text-align: left;
}
.v-data-table thead {
    font-size: 2.5vh;
    text-align: center;
    justify-content: center;
}
.v-input{
  font-size: 3vh;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 3vw;
}
.v-dialog > .v-card > .v-card__text {
    padding: 12px;
}
// General
#vista-info-bales {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #container {
    padding-top: 2vh;
    width: 100%;
    height: 80%;
  }
}

// Títols
#llistat-bales-titol,
#dades-posicio-titol {
  display: flex;
  justify-content: center;
  h3 {
    position: relative;
    top: 16px;
    font-size: 4vw;
    background: white;
    border: 1px solid black;
    padding: 5px;
  }
}


// Bloc esquerra
#info-llistat-bales {
  background: rgb(211, 211, 211);
  padding: 0 1em;
  border: 1px solid black;

  .row {

    justify-content: center;
    border:none!important;
    .col{
    flex-direction: column;
      // background: white;
    border:none!important;
    .bales-seleccionades-llistat{
      height: 35vh;
      overflow-y: scroll;
    }
    }
    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      // background: white;
      
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
  .separador {
    width: 100%;
    height: 30px;
  }
}

// Bloc dreta
#info-posicio-emmagatzenament {
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  height: 50vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      background: white;
      width: 100%;
      min-height: 50px;
      display: flex;
      flex-direction: row;
      
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
  .separador {
    width: 100%;
    height: 30px;
  }
}

// Botonera
.botonera {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  // justify-content: space-between  !important;
  background: rgb(211, 211, 211) !important;
  border: none !important;

  button {
    width: 33%;
    border-radius: 5px;
    font-size: 1.7vh;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }
}

// TODO - Convertir açò en v-list
#bales-seleccionades-1 {
  display: block !important;
  // overflow-y: scroll;
  font-size: 1.5vh !important;
  div{
    justify-content: center;
    height: auto;
  }
  span{
    margin:0px !important
  }
}
#bales-seleccionades-2 {
  display: block !important;
  overflow-y: scroll;
  // height: 65vh !important;
  font-size: 2.5vh !important;
  background: white !important;
  border-radius: 1vh;
  border: 1px dashed black;
  div{
    justify-content: center;
    height: auto;
    outline: 1px black;
    text-align: center;
  }
  span{
    margin:0px !important
  }
}

#quadres-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
}

.reset-row-escanejat-codi {
  // width: 100%;
  height: 5%;
  padding-bottom: 0px;
  margin-bottom: 0px;
  * {
    background: none !important;
    border: none !important;
  }
}
</style>