<template>
  <v-container>
    <!-- Bales -->
    <v-simple-table class="mt-12">
      <thead>
      <td style="height: auto">
        <h1>Llistat de Stock de {{ borra_nom}} en {{ magatzem.nom }}</h1>
        </td>
        <td>
        <v-btn
        id="generarExcel"
        ref="buttonGetExcelData"
        @click="getExcelData"
      >Generar Excel</v-btn>
      <downloadexcel
        id="downloadexcel"
        ref="buttonGenerarExcel"
        class="btn"
        :name="nombreExcel"
        :data="excelArray"
        :fields="json_fields"
        worksheet = "Pàgina 1"
        type="xls"
      ></downloadexcel>
      <v-btn
        id="generarExcel"
        @click="addExcelData"
      >Afegir a Excel</v-btn>
      <v-btn
        id="generarExcel"
        @click="buidarExcel"
      >Buidar Excel</v-btn>
        </td>      
      </thead>
      <tbody>
        <tr v-for="(bala, index) in borres" :key="index">
          <td @click="stockBorraDetail(bala.id)" class="text-bala">{{ textBorra(bala) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Bales -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('StockBorraMagatzemBorraList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "stock-borra-final-llistat",
  data: () => ({
    borra_nom:[],
    json_fields: {
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        MAGATZEM: "magatzem_nom",
        QUILOS: "quilos", 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        ESTAT: {
          field: "estat",
          callback: value => (value=="e" ? "Expedida" : "Creada")
        },
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        FABRICA: "fabrica", 
        QUARTO: "quarto_nom",
        PRODUCTE_FINAL: "borra_nom",
        PARTIDA: "partida",
        DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales_Partida",
    paginasAñadidas:[],
    materiaPrimera: {},
    borra:{},
    magatzem: {},
    pagina: 1,
    totalPagines: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      getBorresPaginacio: "getBorresPaginacio",
      borres: "getBorres",
      magatzems: "getMagatzems",
      partides: "getPartides"
    })
  },
  methods: {
    ...mapActions([
      "getBalesMagatzemProducteFinal",
      "getIngredients",
      "getMagatzem",
      "getPartides",
      "getMagatzemNumero",
      "getMateriaPrimera",
      "getBorra",
      "getTipusBorres",
      "getBorres", "getMagatzems", "getMateriesProducteFinal", "getMateriaProducteFinal"
    ]),

    // Paginació
    stockBorraDetail(balaID) {
      this.$router.push({ name: "StockBorraDetail", params: { id: balaID } });
    },
    paginar() {
      this.getBalesMagatzemProducteFinal({
        pagina: this.pagina,
        magatzemID: this.magatzem.id,
        posicion_almacenamiento: this.$route.params.magatzemID,
        BorraID: this.$route.params.BorraID
      });
    },
    //Excel
    getExcelData(){
      
      this.excelArray.forEach(el => {
        el.quarto_nom = el.cuarto;
        el.borra_nom = this.borra_nom;
        el.magatzem_nom = this.magatzem_nom;
        })  

      
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      this.nombreExcel = 'Llistat_Stock_'+this.borra_nom+'_'+this.magatzem.nom;
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.borres); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Text
    textProducteFinal(ID) {
      const producteFinal = this.tipusBorra.find(
        el => el.id === ID
      );
      return producteFinal?.nom;
    },
    textMagatzem(magatzemNum) {
      const magatzem = this.magatzems?.find(el => el.numero === magatzemNum);
      if(magatzem?.nom){
        return magatzem?.nom;
        }
      else{
        return "Sense magatzem"
      }
    },
    textPrioritat(prioritat) {
      return this.prioritatOpcions.filter(el => el.id === prioritat)[0]?.nom;
    },
    textEstatBala(estat) {
      return this.estatBalaOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    textQuarto(quartoID){
      const quarto = this.partida.quartos_mesclat?.find(el => el.id === quartoID);
      return quarto?.quarto
    },
    textBorra(borra) {
      return `${borra.barcode} - ${borra.producte_final} (${borra.codi_partida.replaceAll('.', '')}) [${borra.quilos} Kg] `;
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({
        name: component,
        params: { id: this.$route.params.magatzemID }
      });
    }
  },
  created() {
    
    this.getMagatzemNumero(parseInt(this.$route.params.magatzemID.charAt(1)+ this.$route.params.magatzemID.charAt(2) + this.$route.params.magatzemID.charAt(3) ))
    .then(
      magatzem => (
        this.getBalesMagatzemProducteFinal({
        pagina: this.pagina,
        magatzemID: magatzem[0].id,
        posicion_almacenamiento: this.$route.params.magatzemID,
        BorraID: this.$route.params.BorraID
      }),
      this.magatzem = magatzem[0])
    );
    //this.getMagatzems();
    //this.getPartides();
    this.getMateriaProducteFinal(this.$route.params.BorraID).then(data=>
    {
      this.borra_nom = data.nom
    });
    
  },
  watch: {
    borres: function() {
      this.totalPagines = this.getBorresPaginacio;
    }
  }
};
</script>

<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 100%;
      }
    }
  }
}
#botonera {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    width: 90%;
  }
}
#downloadexcel {
  display: none;
}
</style>