<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Entrada -->
      <h1>Creació d'Entrada</h1>
      <v-row>
        <v-col sm="12">
          <v-dialog
            ref="dialog"
            v-model="modalData"
            :return-value.sync="entrada.data_albara"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field v-model="entrada.data_albara" label="Data albarà" 
              outlined readonly v-on="on" autofocus
              @click:clear="datepickerDelete()"
              clearable></v-text-field>
            </template>
            <v-date-picker
              v-model="entrada.data_albara"
              locale="ca"
              :first-day-of-week="1"
              scrollable
              @click:date="datepicker()"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datepickerDelete()" tabindex="-1">Borrar</v-btn>
              <v-btn text color="primary" @click="datepicker()" tabindex="-1">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="entrada.proveidor"
            :items="proveidorOpcions"
            no-data-text="No s'ha pogut carregar cap proveïdor"
            item-text="nom"
            item-value="id"
            label="Proveïdor"
            outlined
            @change="filtrarMateriesPrimeres()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear proveïdor -->
          <v-dialog v-model="dialogProveidor" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn v-if="grupo!=4" block outlined color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear proveïdor</v-card-title>
              <v-container>
                <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="proveidor_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="proveidor_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                        
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogProveidor = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="btnCrearProveidor" tabindex="-1" :disabled="!proveidor_nou.nom || !proveidor_nou.poblacio">Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per eliminar bala -->
        </v-col>
      </v-row>
      <v-row>
        <v-col md="5" style="display: flex">
          <v-text-field v-model="entrada.quilos_teorics" label="Quilos totals" 
          outlined
          :rules="[v => v < 32767 || 'Ha superat el máxim de 32700 kg' ]" 
          rounded
          required
          :class="{'quilos-not-ok': quilosNoOk()}"></v-text-field>
          <span>
          <img v-if="quilosOk()"
            src="https://cdn.pixabay.com/photo/2017/01/13/01/22/ok-1976099_960_720.png"
            alt
          />
          </span>
        </v-col>
        <v-col md="5" v-if="entrada.quilos_total>0">
          <v-text-field v-model="entrada.quilos_total" 
          label="Quilos reals" 
          outlined
          :rules="[v => v < 32767 || 'Ha superat el máxim de 32700 kg' ]" 
          readonly
          required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-text-field v-model="entrada.cost_total" 
          label="Cost total" 
          outlined
          v-if="grupo!=4"
          readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="entrada.posicion_almacenamiento"
            :items="OpcionesSubalmacenes"
            outlined
            label="Magatzem"
            item-text="nom"
            item-value="barcode"
            no-data-text="No s'ha pogut carregar cap magatzem"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear magatzem -->
          <v-dialog v-model="dialogMagatzem" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn v-if="grupo!=4" outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear</v-card-title>
              <v-container>
                <v-form id="formMagatzem" ref="formMagatzem" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="magatzem_nou.nom" label="Nom" required></v-text-field>
                      <v-text-field v-model="magatzem_nou.numero" label="Numero" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogMagatzem = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="btnCrearMagatzem" tabindex="-1">Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear magatzem -->
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-text-field v-model="entrada.observacions" label="Observacions" outlined></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Entrada -->

      <!-- Grups -->
      <v-row>
        <v-col md="6">
          <v-btn
            block
            color="info"
            @click="crearGrupMaterial()"
            tabindex="-1"
          >Crear nou grup de material</v-btn>
        </v-col>
        <v-col md="6">
        <v-btn color="success" block @click="crearEntrada" :disabled="!entrada.proveidor || !entrada.data_albara || entrada.quilos_total>32700">Guardar entrada</v-btn>
        </v-col>      
      </v-row>
      <div v-for="(grup_bales, index_grup) in grups.grup_bales" :key="index_grup" class="pb-2 pt-2">
        <!-- Creació de bales -->
        <h3 v-if="grups.grup_bales">Grup de bales {{index_grup + 1}} -
        <strong :class="{'quilos-not-ok': !quilosGrupOk(index_grup)}">
          {{ calcular_kg_grup(grup_bales.bales) }} kg </strong>
          <img v-if="quilosGrupOk(index_grup)"
            src="https://cdn.pixabay.com/photo/2017/01/13/01/22/ok-1976099_960_720.png"
            alt
          />
          <strong v-if="grupo!=4"> - {{ calcular_preu_grup(grup_bales.bales) }} € </strong>
        </h3>
        <v-row>
          <v-col md="3">
            <v-autocomplete
              v-model="grups.grup_bales[index_grup].materia_primera"
              :items="materiesPrimeresOpcions"
              label="Materia primera"
              item-value="id"
              outlined
              :filter="customFilter"
              no-data-text="No s'ha pogut carregar cap materia primera"
              @change="carregarPreu(index_grup)"
            >
              <template slot="selection" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
              <template slot="item" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            </v-autocomplete>
          </v-col>
          <v-col md="1">
            <v-btn
              v-if="grupo!=4"
              outlined
              block
              color="success"
              dark
              tabindex="-1"
              @click="btnCrearMateriaPrimeraModal(index_grup)"
            >+</v-btn>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="grups.grup_bales[index_grup].preu_quilo"
              label="Preu kg"
              v-if="grupo!=4"
              outlined
              :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
              required
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="grups.grup_bales[index_grup].bales_a_crear"
              label="Bales a crear"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="grups.grup_bales[index_grup].quilos_grup"
              label="kg grup"
              outlined
              required
              @keydown.enter.prevent="crearBales(index_grup)"
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-btn
              color="success"
              @click="crearBales(index_grup)"
              class="crear-bales"
              tabindex="-1"
            >Crear bales</v-btn>
            <v-btn
              color="warning"
              @click="eliminarGrup(index_grup)"
              class="crear-bales"
              tabindex="-1"
            >Eliminar grup</v-btn>
          </v-col>
        </v-row>
        <!-- ./Creació de bales -->
        <!-- Bales -->
        <div
          v-for="(bala, index_bala) in grups.grup_bales[index_grup].bales"
          :key="index_bala"
          class="pb-2 pt-2"
        >
          <v-row>
            <v-col md="3">
              <h4>Bala #{{ index_grup +1 }}.{{ index_bala +1 }}</h4>
            </v-col>
            <v-col md="3">
              <v-text-field
                v-model="bala.quilos"
                outlined
                label="Quilos"
                @input="calcular_kg_preu()"
                required
              ></v-text-field>
            </v-col>
            <v-col md="3"></v-col>
            <v-col md="3">
              <v-btn
                block
                color="error"
                @click="eliminarBala(index_grup, index_bala)"
                tabindex="-1"
              >Eliminar bala</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- ./Bales -->
      </div>

      <!-- ./Grups -->
      <div id="botonera">
        <v-btn color="info"  @click="crearGrupMaterial()" >Crear nou grup de material</v-btn>
        <v-btn color="success"  @click="crearEntrada" :disabled="!entrada.proveidor || !entrada.data_albara || entrada.quilos_total>32700">Guardar entrada</v-btn>
      </div>
    </v-form>
    <!-- Form -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('EntradaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->

    <!-- Dialog/Modal per crear materia primera -->
    <v-dialog v-model="dialogMateriaPrimera" persistent max-width="600">
        <v-card>
        <v-card-title class="headline">Crear materia primera</v-card-title>
        <v-container>
          <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
            <v-row>
              <v-col md="12">
                <v-text-field v-model="material_nou.nom" label="Nom" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field v-model="material_nou.preu_quilo" label="Preu quilo" required :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="10">
                <v-autocomplete
                  v-model="entrada.proveidor"
                  :items="proveidorOpcions"
                  no-data-text="No s'ha pogut carregar cap proveïdor"
                  item-text="nom"
                  item-value="id"
                  label="Proveïdor"
                ></v-autocomplete>
              </v-col>
              <v-col sm="2">
                <!-- Dialog/Modal per crear proveïdors -->
                <v-dialog v-model="dialogProveidor" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear proveïdor</v-card-title>
                    <v-container>
                      <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="proveidor_nou.poblacio"
                              label="Població"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="proveidor_nou.id_facturaplus"
                              label="ID Facturaplus"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialogProveidor = false"
                        tabindex="-1"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearProveidorMP"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear ingredients -->
              </v-col>
            </v-row>
            <v-row>
                <v-col md="10">
                  <v-autocomplete
                    v-model="material_nou.tipo"
                    :items="tiposOpcions"
                    no-data-text="No s'ha pogut carregar cap tipo de materia"
                    item-text="nom"
                    item-value="id"
                    label="Composició"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <!-- Dialog/Modal per crear ingredients -->
                  <v-dialog v-model="dialogTipoMateria" persistent max-width="600">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Crear Composició</v-card-title>
                      <v-container>
                        <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="tipusMP_nou.nom" label="Composició" required autofocus @keydown.enter.prevent="btnCrearTipusMP"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialogTipoMateria = false"
                        >Cancel·lar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="btnCrearTipusMP">Crear</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- ./Dialog/Modal per crear ingredients -->
                </v-col>
            </v-row>
            <v-row>
              <v-col md="10">
                <v-autocomplete
                  v-model="material_nou.ingredient"
                  :items="ingredientsOpcions"
                  no-data-text="No s'ha pogut carregar cap tipus de materia"
                  item-text="nom"
                  item-value="id"
                  label="Tipo de materia"
                ></v-autocomplete>
              </v-col>
              <v-col sm="2">
                <!-- Dialog/Modal per crear ingredients -->
                <v-dialog v-model="dialogIngredient" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear tipus de materia</v-card-title>
                    <v-container>
                      <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="ingredient_nou.nom" label="Nom" required autofocus @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        tabindex="-1"
                        @click="dialogIngredient = false"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearIngredient"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear ingredients -->
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-checkbox v-model="material_nou.ignifug" label="Ignífug"></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMateriaPrimera = false"
            tabindex="-1"
          >Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnCrearMateriaPrimera" tabindex="-1" :disabled="!material_nou.ingredient || !material_nou.tipo || !material_nou.nom">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per crear materia primera -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "entrada-create",
  data: () => ({
    entrada: {
      data_albara: null,
      cost_total: null,
      quilos_total: null,
      quilos_teorics:null,
      observacions: "",
      proveidor: "",
      magatzem: "", 
      posicion_almacenamiento:""
    },
    bales_creades:[],
    grups: {
      grup_bales: [
        {
          materia_primera: "",
          preu_quilo: "",
          bales_a_crear: "",
          bales: []
        }
      ]
    },
    ingredient_nou: {
      nom: ""
    },
    dialogTipoMateria: false,
    tipusMP_nou: {
      nom: ""
    },
    magatzem_nou: {
      nom: ""
    },
    material_nou: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    proveidor_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    dialogIngredient: false,
    dialogMagatzem: false,
    dialogMateriaPrimera: false,
    dialogProveidor: false,
    novaMateriaPrimeraIndex: null,
    materiesPrimeresOpcionsFiltrades:[],
    OpcionesSubalmacenes:[],
    modalData: false
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors",
      tiposOpcions: "getTipusMateria", 
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getMateriesPrimeresFiltrades",
      "getProveidors",
      "sendEntrada",
      "sendIngredient",
      "sendMagatzem",
      "sendMateriaPrimera",
      "sendProveidor",
      "almacenarBalas",
      "getTipusMateria",
      "sendTipusMateria"
    ]),

    eliminarBala(index_grup, index_bala) {
      this.grups.grup_bales[index_grup].bales.splice(index_bala, 1);
    },
    eliminarGrup(index_grup) {
      this.grups.grup_bales.splice(index_grup, 1);
      this.calcular_kg_preu();
    },
    btnCrearTipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.tipo = data.data.id;
          this.tipusMP_nou = {
            nom: ""
          };
          this.dialogTipoMateria = false;
          this.materia_primera.tipo_nom = this.textCodiGRS(this.materia_primera.tipo);
        }
      });
    },
    crearEntrada() {
      // Agrupem totes les bales existents en un array
      let bales = [];
      this.grups.grup_bales.forEach(el => bales.push(el.bales));
      this.entrada.bales = bales.flat();


      // Ho enviem
      this.sendEntrada({ entrada: this.entrada }).then(
        response => {
            response.data.entrada_materies_primeres.bales.forEach(el=>this.bales_creades.push(el.id))
            if(this.bales_creades){
              this.almacenarBalas({balas_materia_prima: this.bales_creades, posicio_id: this.OpcionesSubalmacenes.find(el=> 
                el.barcode == this.entrada.posicion_almacenamiento).id})
            }
            if (response?.data?.status === "entrada_creada") {
              this.$router.push({ name: 'EntradaDetail', params: { id: response.data.entrada_materies_primeres.id } })
            }

          
        }
      );
    },
    // Creació de la estructura de bales
    crearBales(index_grup) {
      const grup = this.grups.grup_bales[index_grup];
      const materia_primera = grup.materia_primera;
      const preu_quilo = grup.preu_quilo;

      const bales_a_crear = grup.bales_a_crear;
      const bales_existents = grup.bales.length;

      if (!materia_primera || !preu_quilo || !bales_a_crear) {
        EventBus.$emit("Entrada.Notificacio", {
          text: "No et deixes cap camp buid per a crear bales",
          color: "warning"
        });
        return;
      }

      if (bales_existents > bales_a_crear) {
        // Eliminar bales
        this.grups.grup_bales[index_grup].bales.splice(
          bales_a_crear - bales_existents,
          bales_existents
        );
      } else if (bales_existents < bales_a_crear) {
        // Afegir bales
        const bales_totals_a_crear = bales_a_crear - bales_existents;
        for (let index = 0; index < bales_totals_a_crear; index++) {
          this.grups.grup_bales[index_grup].bales.push({
            quilos: "",
            preu_quilo: preu_quilo,
            materia_primera: materia_primera
          });
        }
      } else {
        // No fer res
      }
    },
    crearGrupMaterial() {
      this.grups.grup_bales.push({
        materia_primera: "",
        preu_quilo: "",
        bales_a_crear: "",
        bales: []
      });
    },
    //filtrar materies primeres
    filtrarMateriesPrimeres(){
      this.getMateriesPrimeresFiltrades( {proveidor:this.entrada.proveidor}).then(
      );
      //this.materiesPrimeresOpcionsFiltrades = this.materiesPrimeresOpcions.filter(el=> el.proveidor == this.entrada.proveidor);
    },
    // Crear nous models
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.ingredient = data.data.id;
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearProveidor() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data.status === 201) {
          this.material_nou.proveidor = data.data.id;
          this.proveidor_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogProveidor = false;
          this.entrada.proveidor = data.data.id;
        }
      });
    },
    btnCrearProveidorMP() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data.status === 201) {
          this.proveidor_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogProveidor = false;
          this.material_nou.proveidor = data.data.id;
        }
      });
    },
    btnCrearMagatzem() {
      this.sendMagatzem(this.magatzem_nou).then(data => {
        if (data.status === 201) {
          this.magatzem_nou = {
            nom: ""
          };
          this.dialogMagatzem = false;
          this.entrada.magatzem = data.data.id;
        }
      });
    },
    btnCrearMateriaPrimera() {
      this.material_nou.proveidor = this.entrada.proveidor;
      //this.material_nou.ingredients = [this.material_nou.ingredients];

      this.sendMateriaPrimera(this.material_nou).then(data => {
        if (data.status === 201) {
          this.material_nou = {
            nom: "",
            preu_quilo: null,
            proveidor: "",
            ignifug: false
          };
          this.dialogMateriaPrimera = false;
          this.grups.grup_bales[this.novaMateriaPrimeraIndex].materia_primera =
            data.data.id;
          this.grups.grup_bales[this.novaMateriaPrimeraIndex].preu_quilo =
            data.data.preu_quilo;
          this.materiesPrimeresOpcionsFiltrades.push(data.data)
          this.getMateriesPrimeresFiltrades({proveidor:this.entrada.proveidor})
        }
      });
    },
    btnCrearMateriaPrimeraModal(index) {
      this.getIngredients() 
      this.getTipusMateria()
      this.novaMateriaPrimeraIndex = index;
      this.dialogMateriaPrimera = true;
    },
    // Datepicker
    datepicker() {
      this.$refs.dialog.save(this.entrada.data_albara);
      this.modalData = false;
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.entrada.data_albara = null));
      this.modalData = false;
    },
    // Auxiliar
    calcular_preu_grup(grup_bales) {
      if (grup_bales.length === 0) return 0;
      let total = 0;
      grup_bales.forEach(bala => {
        if (bala.quilos){
           total += parseFloat(bala.quilos*bala.preu_quilo)
           }
      });
      return total.toFixed(2);
    },
    quilosOk(){
      if(this.entrada.quilos_teorics>0) return this.entrada.quilos_teorics==this.entrada.quilos_total
    },
    quilosGrupOk(index_grup){
      if(this.grups.grup_bales[index_grup].quilos_grup!=0 && 
      this.grups.grup_bales[index_grup].quilos_grup == this.calcular_kg_grup(this.grups.grup_bales[index_grup].bales)){
        return true
      }
    },
    quilosNoOk(){
      if(this.entrada.quilos_teorics>0 && this.entrada.quilos_total>0) return this.entrada.quilos_teorics!=this.entrada.quilos_total
    },
    calcular_kg_grup(grup_bales) {
      if (grup_bales.length === 0) return 0;
      let total = 0;
      grup_bales.forEach(bala => {
        if (bala.quilos) total += parseInt(bala.quilos);
      });
      return total;
    },
    calcular_kg_preu() {
      let balesKG = [];
      let finalKG = 0;
      let finalPreu = 0;

      // Agrupem totes les bales en un únic array
      this.grups.grup_bales.forEach(el => balesKG.push(el.bales));
      balesKG = balesKG.flat();

      // Calculem els KG
      for (var i in balesKG) {
        if (!isNaN(parseInt(balesKG[i].quilos))) {
          finalKG += parseInt(balesKG[i].quilos);
          finalPreu += parseInt(balesKG[i].quilos) * balesKG[i].preu_quilo;
        }
      }
      this.entrada.quilos_total = finalKG;
      this.entrada.cost_total = finalPreu.toFixed(2);
    },
    carregarPreu(index_grup) {
      const materia_primera_id = this.grups.grup_bales[index_grup]
        .materia_primera;
      const preu_quilo =
        this.materiesPrimeresOpcions.find(el => el.id === materia_primera_id)
          ?.preu_quilo || 0;
      this.grups.grup_bales[index_grup].preu_quilo = preu_quilo;
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera.proveidor
      );

      if (mpProveidor) return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''} - ${mpProveidor.nom}`;
      return materiaPrimera?.nom;
    }
  },
  created() {
    // this.getTipusMateria();
    // this.getIngredients();
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getProveidors();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
h2 {
  text-align: center;
  padding-bottom: 0.5em;
}
h3 {
  text-align: center;
  padding-top: 0.5em;
}
.crear-bales {
  width: 100%;
}
.quilos-ok{
  color: rgb(207, 244, 161);
  border-radius: 10px;
  border: 1px dashed black;
  padding: 0px;
  margin: 0px;
}
.quilos-not-ok{
  color: red;
}
img {
  width: 25px;
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 48%;
  }
}
// Form Proveïdor
#formProveidor {
  margin-top: 0px !important;
}
</style>