<template>
  <v-container>
    <!-- Llistat Tipus de borra -->
    <v-simple-table class="mt-12" style="padding-bottom:0.5em">
      <thead>
        <v-row>
          <v-col sm="9" offset-sm="1">
            <h1>Llistat de Tipus de borra</h1>
          </v-col>
          <v-col sm="1" offset-sm="0">
            <!-- Dialog/Modal per crear materia producte final -->
            <v-dialog v-model="dialogTipusBorra" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear tipus de borra</v-card-title>
                <v-container>
                  <v-form id="formTipusBorra" ref="formTipusBorra" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="tipus_borra_nova.nom" label="Nom" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                          v-model="tipus_borra_nova.descripcio"
                          label="Descripció"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="tipus_borra_nova.cost_trinxat" label="Cost trinxat"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCrearTipusBorra">Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearTipusBorra"
                    :disabled="!tipus_borra_nova.nom"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear materia producte final -->
          </v-col>
        </v-row>
        <v-row style="display: flex; justify-content: space-betwenn; text-align: center; padding-bottom:1em">
        <v-btn
          id="generarExcel"
          ref="buttonGetExcelData"
          @click="getExcelData"
        >Generar Excel</v-btn>
        <downloadexcel
          id="downloadexcel"
          ref="buttonGenerarExcel"
          class="btn"
          :name="nombreExcel"
          :data="excelArray"
          :fields="json_fields"
          worksheet = "Pàgina 1"
          type="xls"
        ></downloadexcel>
        <v-btn
          id="generarExcel"
          @click="addExcelData"
          style="margin-left: auto"
        >Afegir a Excel</v-btn>
        <v-btn
          id="generarExcel"
          @click="buidarExcel"
          style="margin-left: auto"
        >Buidar Excel</v-btn>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="materiaProducteFinal in materiesProducteFinal" :key="materiaProducteFinal.id">
          <td class="text-materia-producte-final">
            <div @click="tipusBorraDetail(materiaProducteFinal.id)">{{ materiaProducteFinal.nom }}</div>
            <div>
              <v-btn
                color="error"
                dark
                @click="btnModalEliminar(materiaProducteFinal.id)"
              >Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Tipus Borra -->
    <!-- Dialog/Modal per eliminar Tipus Borra -->
    <v-dialog v-model="dialogEliminarTipusBorra" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar materia de producte final</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarTipusBorra = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btneliminarMateriaProducteFinal()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar Tipus Borra -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";

export default {
  name: "materies-producte-final-llistat",
  data: () => ({
    dialogTipusBorra: false,
    dialogEliminarTipusBorra: false,
    materiaProducteFinalEliminarID: null,
    tipus_borra_nova: {
      nom: ""
    },
    json_fields: {
        Nom: "nom",
        COST_TRINXAR:  {
          field: "cost_trinxat",
          callback: value => (value.replace('.',','))
        }, 
        PREU_QUILO:  {
          field: "preu_quilo",
          callback: value => (value.replace('.',','))
        }, 
        NUM_BALES: "num_bales", 
        QUILOS: "quilos"
        
      },
    excelArray: [],
    nombreExcel: "Llistat_Tipus_de_Borra.xls",
    pagina: 1,
    totalPagines: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      materiesProducteFinal: "getMateriesProducteFinal"
    })
  },
  methods: {
    ...mapActions([
      "eliminarMateriaProducteFinal",
      "getMateriesProducteFinal",
      "sendMateriaProducteFinal"
    ]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },
    // Excel
     getExcelData(){
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
      this.paginasAñadidas = [];

    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];
    },
    addExcelData(){
      this.excelArray.push.apply(this.excelArray, this.materiesProducteFinal); 

    },
    // Edició de tipus de borra
    btnCrearTipusBorra() {
      this.sendMateriaProducteFinal(this.tipus_borra_nova).then(materiaProducteFinal => {
        if (materiaProducteFinal?.data?.id) {
          this.tancarCrearTipusBorra();
          this.getMateriesProducteFinal();
        }
      });
    },
    btnModalEliminar(materiaProducteFinal) {
      this.dialogEliminarTipusBorra = true;
      this.materiaProducteFinalEliminarID = materiaProducteFinal;
    },

    btneliminarMateriaProducteFinal() {
      this.eliminarMateriaProducteFinal(
        this.materiaProducteFinalEliminarID
      ).then(response => {
        if (response === 204) {
          EventBus.$emit("TipusBorra.Notificacio", {
            text: "Tipus de Borra eliminat correctament",
            color: "success"
          });
          this.dialogEliminarTipusBorra = false;
          this.materiaProducteFinalEliminarID = null;
          this.getMateriesProducteFinal();
        } else {
          EventBus.$emit("TipusBorra.Notificacio", {
            text: "Error eliminant el tipus de borra",
            color: "error"
          });
          this.dialogEliminarTipusBorra = false;
        }
      });
    },

    // Rerouting
    tipusBorraDetail(materiaProducteFinalID) {
      this.$router.push({
        name: "TipusBorraDetail",
        params: { id: materiaProducteFinalID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearTipusBorra() {
      this.tipus_borra_nova = {
        nom: ""
      };
      this.dialogTipusBorra = false;
    }
  },
  created() {
    this.getMateriesProducteFinal();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  // padding-bottom: 1em;
}
.text-materia-producte-final {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>