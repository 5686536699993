<template>
  <v-container>
    <v-dialog v-model="dialogAcabar" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Llistat bales repartides</v-card-title>
          <v-card-text>
            <v-simple-table class="mt-12">
              <tbody v-if="quartos_mesclat_ordenats.length>=1">
                <tr >
                  <td>
                    <b>Bala</b>
                  </td>
                  <td>
                  <strong>En quartos </strong>
                  </td>
                </tr>
                <tr v-for="bala in bales_repartides" :key="bala.id" >
                  <td>
                    {{bala.barcode}}
                  </td>
                  <td style="display: flex; justify-content: space-around"><p v-for="(quarto,index) in bala.en_quarto" :key="index">
                    {{quarto}} </p> 
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogAcabar = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="imprimirMezclada">Imprimir</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Mesclada -->
    <v-simple-table v-if="mesclada" class="mt-12">
      <thead>
        <h1>Vista detallada de Mesclada</h1>
        <v-btn v-if="mesclada.estat=='c'" @click="redireccio('ProcesarMesclada',{id: mesclada.id})"> Produir mesclada </v-btn>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nº Partida</b>
          </td>
          <td>{{ mesclada.codi.replaceAll('.', '') }}</td>
        </tr>
        <tr>
          <td>
            <b>Máquina</b>
          </td>
          <td>{{ mesclada.prefix_nom.replaceAll('.', '') }}</td>
        </tr>
        <tr v-if="mesclada.observacions!=''">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ mesclada.observacions}}</td>
        </tr>
        <tr>
          <td>
            <b>Estat</b>
          </td>
          <td>{{ textEstat(mesclada.estat) }}</td>
        </tr>
        <!-- <tr>
          <td>
            <b>Prioritat</b>
          </td>
          <td>{{ textPrioritat(mesclada.prioritat) }}</td>
        </tr> -->
        <tr v-if="mesclada.plantilla">
          <td>
            <b>Plantilla</b>
          </td>
          <td>{{ mesclada.plantilla }}</td>
        </tr>
        <tr v-if="producte_final_nom">
          <td>
            <b>Producte final</b>
          </td>
          <td>{{ (producte_final_nom) }}</td>
        </tr>
        <tr v-if="mesclada.quilos_deseados>0">
          <td>
            <b>Quilos objectiu</b>
          </td>
          <td>{{ mesclada.quilos_deseados }} kg</td>
        </tr>
        <tr>
          <td>
            <b>Quilos totals</b>
          </td>
          <td>{{ mesclada.quilos_totals }} kg</td>
        </tr>
        </tbody>
        <thead align="center !important" v-if="quartos_mesclat_ordenats.length>=1">
            <h1>INFORMACIÓ QUARTOS</h1> 
            <v-btn v-if="mesclada.estat!='-' && ids_bales_repartides.length >0" @click="btnImprimirBales"> Imprimir bales repartides </v-btn>
        </thead>
        <tbody v-if="quartos_mesclat_ordenats.length>=1">
        <tr v-for="(quarto,index) in quartos_mesclat_ordenats" :key="index" >
          <td style="width:20%">
            <b>Quarto {{quarto.quarto}}</b>
          </td>
          <td style="width:65%">Bales: {{contarBalas(quarto.porcions_mesclat)}} - Quilos: {{calcular_kg_grup(quarto.porcions_mesclat)}} kg </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Mesclada -->

    <!-- Balas -->

    <!-- ./Balas -->
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn v-if="mesclada.estat=='-' && grupo!=2 && grupo!=3" color="primary" dark @click="btnEditarMesclada">Editar </v-btn>
      <v-btn v-if="mesclada.estat!='-'" color="primary" dark @click="btnEditarMesclada">Distribució </v-btn>
      <v-btn v-if="mesclada.estat=='-' && grupo!=2 && grupo!=3" color="green" dark @click="btnEncolarMesclada">Acabar </v-btn>
      <v-btn v-if="mesclada.estat=='c' && grupo!=2 && grupo!=3" color="green" dark @click="btnDesencolarMesclada">Desencolar </v-btn>
      <!-- Dialog/Modal per eliminar mesclada -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn v-if="grupo!=2 && grupo!=3" color="error" dark v-on="on">Eliminar </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar mesclada</v-card-title>
          <v-card-text>Està segur que vol eliminar esta mesclada?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarMesclada">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar mesclada -->
    </div>
    <!-- Botons d'opcions -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('MescladaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "mesclada-detall",
  data: () => ({
    dialog: false,
    producte_final_nom:"",
    dialogAcabar: false,
    bales_repartides:[],
    ids_bales_repartides:[],
    mesclada: {quartos_mesclat:[]},
    estatOpcions: [
      { id: "-", nom: "Incompleta" },
      { id: "c", nom: "En cua" },
      { id: "e", nom: "Execució" },
      { id: "i", nom: "Interrompuda" },
      { id: "a", nom: "Acabada" }
    ],
    quartos_mesclat_ordenats:[],
    prioritatOpcions: [
      { id: 1, nom: "Baixa" },
      { id: 2, nom: "Mitjana" },
      { id: 3, nom: "Alta" },
      { id: 4, nom: "Urgent" }
    ]
  }),
  computed: {
    ...mapGetters({
      prefixosOpcions: "getPrefixos", 
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "eliminarMesclada",
      "encolarMesclada",
      "desencolarMesclada",
      "getMesclada",
      "getMesclada_new",
      "getPlantilla",
      "getPrefixos",
      "getQuartosMesclat",
      "getMateriesProducteFinal",
      "generarPegatinasRepartidas",
      "getMateriaProducteFinal", 
      "imprimirPegatinasMesclada_new"
    ]),
    // Botonera
    btnEditarMesclada() {
      this.$router.push({
        name: "MescladaEdit",
        params: { id: this.mesclada.id, partida: 1 }
      });
    },
    btnEncolarMesclada(){
      this.bales_repartides=[];
      this.ids_bales_repartides=[];
      this.quartos_mesclat_ordenats.forEach(el=>
      {
        el.porcions_mesclat.forEach(porcio=>{
        if(porcio.contador>1 && !this.bales_repartides.find(bala=> bala.barcode == porcio.barcode))
          { porcio.en_quarto = [el.quarto];
            this.bales_repartides.push(porcio)
            this.ids_bales_repartides.push(porcio.bala)}
        else if (this.bales_repartides.find(bala=> bala.barcode == porcio.barcode)){
          this.bales_repartides.find(bala=> bala.barcode == porcio.barcode).en_quarto.push(el.quarto)
        }
        })
        
      })
      if(this.ids_bales_repartides.length >0){
        this.dialogAcabar = true;
      }
      this.encolarMesclada(this.mesclada.id).then(
        response => {this.mesclada.estat=response.estat}
      )

    },
    btnImprimirBales(){
      this.bales_repartides=[];
      this.ids_bales_repartides=[];
      this.quartos_mesclat_ordenats.forEach(el=>
      {
        el.porcions_mesclat.forEach(porcio=>{
        if(porcio.contador>1 && !this.bales_repartides.find(bala=> bala.barcode == porcio.barcode))
          { porcio.en_quarto = [el.quarto];
            this.bales_repartides.push(porcio)
            this.ids_bales_repartides.push(porcio.bala)}
        else if (this.bales_repartides.find(bala=> bala.barcode == porcio.barcode)){
          this.bales_repartides.find(bala=> bala.barcode == porcio.barcode).en_quarto.push(el.quarto)
        }
        })
      })
      if(this.ids_bales_repartides.length >0){
        this.dialogAcabar = true;
      }
    },
    btnProduir(){
      console.log('Forzar producción de mesclada')
    },
    imprimirMezclada(){
      if(this.ids_bales_repartides.length>0)
      {
      /* this.generarPegatinasRepartidas(this.mesclada.id).then(status=>
      {if (status === 200)
          this.dialogAcabar =false;
          window.open(
            "https://" +
              window.location.host +
              `/api/v1.0/imprimir_mezclada/${this.mesclada.id}/`,
            "_blank"
          );}
      ) */
      this.imprimirPegatinasMesclada_new({id: this.mesclada.id, info_impresion:{destino:"FrontEnd", 
      request_id: localStorage.getItem('uuid')}})
      }
      
    },
    btnDesencolarMesclada(){
      this.desencolarMesclada(this.mesclada.id).then(
        response => {this.mesclada.estat=response.estat}
        )
    },
    btnEliminarMesclada() {
      this.eliminarMesclada(this.mesclada.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Mesclada.Notificacio", {
            text: "Mesclada eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "MescladaList" });
        } else
          EventBus.$emit("Mesclada.Notificacio", {
            text: "Error eliminant la mesclada",
            color: "error"
          });
      });
    },
    // Auxiliars
    calcular_kg_grup(grup_bales) {
      if (grup_bales.length === 0) return 0;
      let total = 0;
      grup_bales.forEach(bala => {
        if (bala.quilos) total += parseFloat(bala.quilos);
      });
      return total?.toFixed(2);
    },
    textProducteFinal(ID) {
      const producteFinal = this.materiesProducteFinalOpcions.find(
        el => el.id === ID
      );
      return producteFinal?.nom;
    },
    contarBalas(porcions){
      var total=0;
      porcions.forEach(el=> total = parseFloat(1/el.contador)+total)
      return total?.toFixed(2)
    },
    // TODO - Simplificar en una sola funció on passem array i valor
    redireccio(component, params) {
      if(params){
        this.$router.push({ name: component, params: params});
      }else{
        this.$router.push({ name: component });
      }
      
    },
    textEstat(estat) {
      return this.estatOpcions.filter(el => el.id === estat)[0]?.nom;
    },
    textPrefixos(prefix) {
      return this.prefixosOpcions.filter(el => el.id === prefix)[0]?.nom;
    },
    textPrioritat(prioritat) {
      return this.prioritatOpcions.filter(el => el.id === prioritat)[0]?.nom;
    }
  },
  created() {
    this.getMesclada_new(this.$route.params.id)
      .then(mesclada => {
        this.mesclada = mesclada;
        this.getMateriaProducteFinal(mesclada.producte_final).then(producte=>
        this.producte_final_nom = producte.nom)
        var porcions_mesclat =[];
        var quartos = [];
        this.getQuartosMesclat(mesclada.id).then(
          quartos_mesclada=>{
            this.mesclada.quartos_mesclat = quartos_mesclada;
            quartos_mesclada.forEach(quarto => {
              quartos.push(quarto.quarto); 
          quarto.porcions_mesclat.forEach(porcio=>porcions_mesclat.push(porcio))})
          function comparar ( a, b ){ return a - b; }
          quartos = quartos.sort(comparar)
          this.quartos_mesclat_ordenats=[]
          quartos.forEach(elem=> this.quartos_mesclat_ordenats.push(this.mesclada.quartos_mesclat.filter(el => el.quarto == elem)[0])
              )
          this.bales_repartides=[];
          this.ids_bales_repartides=[];
          this.quartos_mesclat_ordenats.forEach(element=> 
            element.porcions_mesclat.forEach(balas=>{ balas.contador=porcions_mesclat.filter(porcio=> porcio.bala==balas.bala).length;
            element.porcions_mesclat.forEach(porcio=>{
              if(porcio.contador>1 && !this.bales_repartides.find(bala=> bala.barcode == porcio.barcode))
                { porcio.en_quarto = [element.quarto];
                  this.bales_repartides.push(porcio)
                  this.ids_bales_repartides.push(porcio.bala)}
              else if (this.bales_repartides.find(bala=> bala.barcode == porcio.barcode)){
              this.bales_repartides.find(bala=> bala.barcode == porcio.barcode).en_quarto.push(element.quarto)
              }
            })
            }
          )
          )
            }
        );
        //mesclada.quartos_mesclat.forEach(quarto => {
        //  quartos.push(quarto.quarto); 
        //  quarto.porcions_mesclat.forEach(porcio=>porcions_mesclat.push(porcio))})
        //  function comparar ( a, b ){ return a - b; }
        //  quartos = quartos.sort(comparar)
        //  this.quartos_mesclat_ordenats=[]
        //  quartos.forEach(elem=> this.quartos_mesclat_ordenats.push(mesclada.quartos_mesclat.filter(el => el.quarto == elem)[0])
        //      )
        //  this.bales_repartides=[];
        //  this.ids_bales_repartides=[];
        //  this.quartos_mesclat_ordenats.forEach(element=> 
        //    element.porcions_mesclat.forEach(balas=>{ balas.contador=porcions_mesclat.filter(porcio=> porcio.bala==balas.bala).length;
        //    element.porcions_mesclat.forEach(porcio=>{
        //      if(porcio.contador>1 && !this.bales_repartides.find(bala=> bala.barcode == porcio.barcode))
        //        { porcio.en_quarto = [element.quarto];
        //          this.bales_repartides.push(porcio)
        //          this.ids_bales_repartides.push(porcio.bala)}
        //      else if (this.bales_repartides.find(bala=> bala.barcode == porcio.barcode)){
        //      this.bales_repartides.find(bala=> bala.barcode == porcio.barcode).en_quarto.push(element.quarto)
        //      }
        //    })
        //    }
        //  )
        //  )

      })
      .then(() => {
        if (this.mesclada.plantilla) this.getPlantilla(this.mesclada.plantilla).then(plantilla => this.mesclada.plantilla = plantilla?.nom);
      });
    // this.getMateriesProducteFinal();
    
  }
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  text-align: center;
  padding-bottom: 0.5em;
}
// Taules
table {
  
  thead {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 20px;
      td {
        width: 40%;
        font-size: 18px;
        padding-top: 10px;
      }
    }
  }
}
.bales-repartides{
  display: flex; 
  justify-content: space-around;
}
#botonera {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;

  button {
    width: 30%;
  }
}
</style>