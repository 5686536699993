<template>
  <v-container>
    <!-- Materia primera -->
    <v-simple-table v-if="materia_primera" class="mt-12">
      <thead>

        <h1>Vista detallada de materia primera
        <v-btn @click="dialogInforme=true" style="margin:5px">Crear Informe </v-btn>

        </h1>
            <v-dialog v-model="dialogInforme" persistent max-width="850">
              <v-card style="padding: 2%">
              <v-card-title class="headline">INFORME DE STOCK</v-card-title>
                <v-dialog
                ref="dialog"
                v-model="modalData"
                :return-value.sync="informe_data"
                persistent
                width="280px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="informe_data" 
                  rounded
                  outlined
                  label="Data Informe" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="informe_data" locale="ca" :first-day-of-week="1" scrollable @click:date="datepicker()">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
                  <v-btn text color="primary" @click="datepicker()">OK</v-btn>
                </v-date-picker>
              </v-dialog>
              <v-row v-if="informe">
              <v-col v-for="(estado, index) in informe.estados" :key="index" sm="6">
               <strong> {{index}} : </strong> {{estado.num_bales}} bales - {{estado.quilos}} kg</v-col>
            </v-row>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  tabindex="-1"
                  @click="cerrarInforme"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  id="generarExcel"
                  ref="buttonGetExcelData"
                  @click="getExcelData"
                  tabindex="-1"
                >Descarregar</v-btn>
                <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel"
                class="btn"
                :name="nombreExcel"
                :data="excelArray"
                :fields="json_fields"
                worksheet = "Pàgina 1"
                type="xls"
              ></downloadexcel>
              </v-card-actions>
              </v-card>
            </v-dialog>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ materia_primera.nom }}</td>
        </tr>
        <tr>
          <td>
            <b>Proveïdor</b>
          </td>
          <td>{{ proveidor_nom }}</td>
        </tr>
        <tr v-if="materia_primera.tipo">
          <td>
            <b>Composició</b>
          </td>
          <td>{{ materia_primera.codigo_grs }}</td>
        </tr>
        <tr>
          <td>
            <b>Preu quilo (€/kg)</b>

          </td>
          <td>{{ parseFloat(materia_primera.preu_quilo).toFixed(3) }}</td>
        </tr>
        <tr v-if="materia_primera.nom_ingredient">
          <td>
            <b>Tipus de materia</b>
          </td>
          <td>{{ materia_primera.nom_ingredient }}</td>
        </tr>
        <tr>
          <td>
            <b>Ignífug</b>
          </td>
          <td>{{ materia_primera.ignifug ? "Sí" : "No" }}</td>
        </tr>
        <!-- <tr>
          <td>
            <b>Quilos Stock</b>
          </td>
          <td>{{ materia_primera.quilos}} kg</td>
        </tr> -->
        <tr>
          <td>
            <b>Quilos assignats</b>
          </td>
          <td>{{ materia_primera.quilos_asignados}} kg</td>
        </tr>
        <tr>
          <td>
            <b>Quilos no assignats</b>
          </td>
          <td>{{ materia_primera.quilos_sin_asignar}} kg</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per editar materia primera -->
      <v-dialog v-model="dialogEditMateriaPrimera" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            dark
            @click="btnDialogMateriaPrimera"
            v-on="on"
          >Editar</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar materia primera</v-card-title>
          <v-container>
            <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="materia_primera_edit.nom" label="Nom" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="materia_primera_edit.preu_quilo"
                    :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                    label="Preu quilo"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-autocomplete
                    v-model="materia_primera_edit.proveidor"
                    :items="proveidorOpcions"
                    no-data-text="No s'ha pogut carregar cap proveïdor"
                    item-text="nom"
                    item-value="id"
                    label="Proveïdor"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="10">
                  <v-autocomplete
                    v-model="materia_primera_edit.tipo"
                    :items="tiposOpcions"
                    no-data-text="No s'ha pogut carregar cap tipo de materia"
                    item-text="nom"
                    item-value="id"
                    label="Composició"
                  ></v-autocomplete>
                </v-col>
                  <v-col sm="2">
                  <!-- Dialog/Modal per crear ingredients -->
                  <v-dialog v-model="dialogTipoMateria" persistent max-width="600">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Crear Composició</v-card-title>
                      <v-container>
                        <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="tipusMP_nou.nom" label="Composició" autofocus required @keydown.enter.prevent="btnCrearTipusMP"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialogTipoMateria = false"
                        >Cancel·lar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="btnCrearTipusMP">Crear</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- ./Dialog/Modal per crear ingredients -->
                </v-col>
              </v-row>
              <v-row>
                <v-col md="10">
                  <v-autocomplete
                    v-model="materia_primera_edit.ingredient"
                    :items="ingredientsOpcions"
                    no-data-text="No s'ha pogut carregar cap tipus de materia"
                    item-text="nom"
                    item-value="id"
                    label="Tipus de materia"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <!-- Dialog/Modal per crear ingredients -->
                  <v-dialog v-model="dialogIngredient" persistent max-width="600">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Crear tipus de materia</v-card-title>
                      <v-container>
                        <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="ingredient_nou.nom" label="Nom" autofocus required @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialogIngredient = false"
                        >Cancel·lar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="btnCrearIngredient">Crear</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- ./Dialog/Modal per crear ingredients -->
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-checkbox v-model="materia_primera_edit.ignifug" label="Ignífug"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditMateriaPrimera = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btnEditMateriaPrimera"
              :disabled="!materia_primera_edit.nom || !materia_primera_edit.proveidor|| !materia_primera_edit.preu_quilo"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar materia primera -->
      <!-- Dialog/Modal per eliminar materia primera -->
      <v-dialog v-model="dialogEliminarMateriaPrimera" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar materia primera</v-card-title>
          <v-card-text>Està segur que vol eliminar este materia primera?</v-card-text>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              @click="dialogEliminarMateriaPrimera = false"
            >Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarMateriaPrimera">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar materia primera -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./materia primera -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('MateriaPrimeraList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import downloadexcel from "vue-json-excel";

import { EventBus } from "@/event-bus.js";

export default {
  name: "materia-primera-detail",
  data: () => ({
    ingredient_nou: {
      nom: ""
    },
    informe_data:"",
    tipusMP_nou: {
      nom: ""
    },
    json_fields: {
        NOM_PRODUCTE: "estat",
        NUM_BALES: "num_bales", 
        QUILOS: "quilos"
      },
    excelArray: [],
    nombreExcel: "Informe_stock",
    modalData:false,
    dialogInforme:false,
    informe:{estados:[]},
    materia_primera: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    proveidor_nom:"",
    materia_primera_edit: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: "",
      tipo:""
    },
    dialogEditMateriaPrimera: false,
    dialogTipoMateria: false,
    dialogIngredient: false,
    dialogEliminarMateriaPrimera: false
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      proveidorOpcions: "getProveidors",
      tiposOpcions: "getTipusMateria",
    })
  },
  components:{
    downloadexcel
  },
  methods: {
    ...mapActions([
      "editMateriaPrimera",
      "eliminarMateriaPrimera",
      "getIngredients",
      "getMateriaPrimera",
      "getProveidor",
      "getProveidors",
      "getTipusMateria", 
      "sendTipusMateria", 
      "getInformeMP"

    ]),
     datepicker() {
      this.$refs.dialog.save(this.informe_data);
      this.getInformeMP({id: this.$route.params.id, fecha: this.informe_data}).then(
        results => {this.informe=results;
        this.informe.data="";
        this.informe.estados={};
        this.nombreExcel= 'Informe_Stock_'+this.materia_primera.nom+'_'+this.proveidor_nom+'_'+this.informe_data
        for (var estado in results){
          if(results[estado].num_bales){
            this.excelArray.push({estat: estado, num_bales:results[estado].num_bales, 
            quilos:results[estado].quilos })
            this.informe.estados[estado]=results[estado]
          }
        }
        }
      )
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.informe_data = ""));
      this.modalData = false;
    },
    cerrarInforme(){
      this.dialogInforme = false
      this.informe = {} 
    },
    //Excel
    getExcelData(){
      // this.excelArray = this.informe.productes_finals
      this.nombreExcel = 'Llistat_Stock_MP';
      // this.excelArray=this.informe; 
      this.$refs.buttonGenerarExcel.$el.click();
      // this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      this.nombreExcel = 'Llistat_Stock_MP';
      this.excelArray=this.informe; 
    },
    // Botonera
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearTipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(data => {
        if (data?.status === 201) {
          this.materia_primera_edit.tipo = data.data.id;
          this.tipusMP_nou = {
            nom: ""
          };
          this.dialogTipoMateria = false;
          this.materia_primera.tipo_nom = this.textCodiGRS(this.materia_primera.tipo);
        }
      });
    },
    btnDialogMateriaPrimera() {
      this.getTipusMateria();
      this.materia_primera_edit = JSON.parse(
        JSON.stringify(this.materia_primera)
      );
    },
    btnEditMateriaPrimera() {
      //this.materia_primera_edit.ingredients=[this.materia_primera_edit.ingredients];
      this.editMateriaPrimera(this.materia_primera_edit).then(response => {
        if (response.status === 200) {
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: "Materia Primera editada correctament",
            color: "success"
          });
          this.materia_primera = response.data
          this.proveidor_nom = this.textProveidor(response.data.proveidor)
          this.dialogEditMateriaPrimera = false;
        } else
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: "Error editant la materia primera",
            color: "error"
          });
      });
    },
    btnEliminarMateriaPrimera() {
      this.eliminarMateriaPrimera(this.materia_primera.id).then(response => {
        if (response === 204) {
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: "MateriaPrimera eliminat correctament",
            color: "success"
          });
          this.$router.push({ name: "MateriaPrimeraList" });
        } else
          EventBus.$emit("MateriaPrimera.Notificacio", {
            text: "Error eliminant la materia primera",
            color: "error"
          });
      });
    },

    // Auxiliars
    textIngredients(ingredientsArray) {
      return (
        ingredientsArray
          .reduce((acc, obj) => acc + " - " + obj.nom, "")
          .replace(" - ", "") || "Sense tipus de materia"
      );
    },
    textCodiGRS(id){
      if(id)
      {return (this.tiposOpcions.find(el=> el.id == id).nom)}
    },
    textProveidor(id){
      if(id)
      {return (this.proveidorOpcions.find(el=> el.id == id).nom)}
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getIngredients();
    this.getProveidors();
    // this.getTipusMateria();
    this.getMateriaPrimera(this.$route.params.id)
      .then(materiaPrimera => {this.materia_primera = materiaPrimera;
      this.proveidor_nom = this.textProveidor(materiaPrimera.proveidor)})
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
#downloadexcel {
  display: none;
}
</style>