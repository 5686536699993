<template>
  <v-container>
    <!-- Client -->
    <v-simple-table v-if="client" class="mt-12">
      <thead>
        <h1>Vista detallada de client</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ client.nom }}</td>
        </tr>
        <tr>
          <td>
            <b>Població</b>
          </td>
          <td>{{ client.poblacio }}</td>
        </tr>
        <tr  v-if="client.emails">
          <td>
            <b>Correu</b>
          </td>
          <td>{{ client.emails }}</td>
        </tr>
        <tr v-if="client.id_facturaplus">
          <td>
            <b>ID Facturaplus</b>
          </td>
          <td>{{ client.id_facturaplus }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per editar client -->
      <v-dialog v-model="dialogEditClient" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark @click="btnDialogClient" v-on="on">Editar client</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar client</v-card-title>
          <v-container>
            <v-form id="formClient" ref="formClient" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="client_edit.nom" label="Nom" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="client_edit.poblacio" label="Població" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="client_edit.emails" label="Correu"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="client_edit.id_facturaplus" label="ID Facturaplus"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditClient = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btnEditClient"
              :disabled="!client_edit.nom || !client_edit.poblacio"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar client -->
      <!-- Dialog/Modal per eliminar client -->
      <v-dialog v-model="dialogEliminarClient" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar client</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar client</v-card-title>
          <v-card-text>Està segur que vol eliminar este client?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEliminarClient = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarClient">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar client -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Client -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('ClientList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "client-detail",
  data: () => ({
    client: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    client_edit: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    dialogEditClient: false,
    dialogEliminarClient: false
  }),
  methods: {
    ...mapActions(["editClient", "eliminarClient", "getClient"]),

    // Botonera
    btnDialogClient() {
      this.client_edit = JSON.parse(JSON.stringify(this.client));
    },
    btnEditClient() {
      this.client_edit.emails = this.client_edit.emails.split(';');
      this.editClient(this.client_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("Client.Notificacio", {
            text: "Client editat correctament",
            color: "success"
          });
          this.dialogEditClient = false;
          this.getClient(this.client.id).then(
            client => (this.client = client)
          );
        } else
          EventBus.$emit("Client.Notificacio", {
            text: "Error editant el client",
            color: "error"
          });
      });
    },
    btnEliminarClient() {
      this.eliminarClient(this.client.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Client.Notificacio", {
            text: "Client eliminat correctament",
            color: "success"
          });
          this.$router.push({ name: "ClientList" });
        } else
          EventBus.$emit("Client.Notificacio", {
            text: "Error eliminant el client",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getClient(this.$route.params.id).then(
      client => (this.client = client)
    );
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>