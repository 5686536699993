<template>
  <div id="emmagatzemar-bales-pf">
    <div id="container">
    <v-dialog v-model="dialogMagatzem" max-width="700" @keydown="cerrardialogMagatzem" retain-focus>
        <v-card >
          <v-card-title class="justify-center">EMMAGATZEMAR EN {{dades.magatzem_nom}}</v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="justify-center">S'estan emmagatzenant en {{dades.magatzem_nom}} les bales següents:
          <tbody style="align=center; margin: 100px; width:100%">
            <tr v-for="(bala,index) in bales_carregades" :key="index">
              <td class="text-mostrar-bales">
                <strong>Bala {{index + 1}}</strong> - {{bala.barcode}} - {{bala.quilos}} kg 
              </td>
            </tr>
          </tbody>
           </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="cerrardialogMagatzem">OK</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDesalmacenar" max-width="700" @keydown="cerrardialogMagatzem" retain-focus>
        <v-card >
          <v-card-title class="justify-center">Aquestes bales estan actualment emmagatzemades</v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="justify-center">
          <tbody style="align=center; width:100%">
            <tr v-for="(bala,index) in balas_almacenadas" :key="index">
              <td class="text-mostrar-bales">
                <strong>Bala {{index + 1}}</strong> - {{bala.barcode}} - {{bala.quilos}} kg - {{textMagatzem(bala.posicion_almacenamiento)}}
              </td>
            </tr>
          </tbody>
           </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="quitarAlmacenBalas">OK</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogError" @keydown="tancarDialogError" max-width="600">
        <v-card>
          <v-card-title class="headline" justify="center">{{msg_error}}</v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError">TORNAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- Escanejar bala pel llistat -->

    <!-- ./Escanejar bala pel llistat -->
      <!-- Dades -->
      <v-row id="dades" justify="center">
        <!-- Llistat bales seleccionades -->
        <v-col sm="12">
          <v-row id="llistat-bales-titol">
            <h3 v-if="almacenar">Listado de balas a almacenar <v-btn outlined color="success" dark  tabindex="-1" @click="CambioDesalmacenar()">-> </v-btn></h3>
            <h3 v-else>Listado de balas a desalmacenar <v-btn outlined color="success" dark  tabindex="-1" @click="CambioAlmacenar()">-> </v-btn></h3>
            
          </v-row>
          <div id="info-llistat-bales">
            <div class="separador"></div>
            <v-row id="bales-seleccionades-1">
              <v-col>
                <v-text-field
                  v-model="codiEscanejatLlistat"
                  label="Código escaneado"
                  v-on:keyup.enter="carregaCodi"
                  autofocus 
                  outlined
                  required
                  background-color="white"
                  ref="campBarcode"
                ></v-text-field>
              </v-col>
              <v-col v-if="bales_carregades.length>0">
                <v-row id="bales-seleccionades-2">
                <div v-for="(bala, index) in bales_carregades" :key="index">
                  <span><strong>{{ index + 1}}:</strong>   {{ bala.barcode}} - {{bala.quilos}} kg - {{textPartida(bala.partida)}}
                    <img
                    src="https://cdn.pixabay.com/photo/2016/03/31/18/31/cancel-1294426_960_720.png"
                    alt
                    @click.stop="btnBorrarBala(bala.id)"
                    /> 
                  </span>
                </div>
                </v-row>
              </v-col>
            
            <div class="separador"></div>
            <v-col v-if="bales_carregades.length>1">
              <!-- Botons d'opcions -->
              <div class="botonera">
                <v-btn x-large color="error" dark @click="esborrarLlistat">Vaciar listado</v-btn>
              </div>
              <!-- Botons d'opcions -->
            </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- /Llistat bales seleccionades -->
        <!-- Dades posició emmagatzenament -->
        <!-- ./Dades posició emmagatzenament -->
      </v-row>
      <!-- ./Dades -->
      <!-- Footer -->
      <v-footer class="font-weight-medium" height="38%">
        <v-col class="text-center" cols="12" @click="redireccio('MenuTreballadors')" style="padding:0px">
          <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
      <!-- ./Footer -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "emmagatzemar-bales-pf",
  data: () => ({
    codiEscanejatLlistat: "",
    codiEscanejatPosicio: "",
    balesSeleccionades: [],
    dades: {
      magatzem: 0,
      posicio: 0
    }, 
  bales_carregades:[],
  bales_carregades_borra:[],
  bales_carregades_mp:[],
  balas_almacenadas:[],
  balas_almacenadas_mp:[],
  balas_almacenadas_borra:[],
  dialogMagatzem: false,
  dialogDesalmacenar: false,
  dialogError: false, 
  msg_error:[], 
  almacenar: true
  }),
  computed: {
    ...mapGetters({
      borres: "getBorres",
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      partides: "getPartides",
      magatzems: "getMagatzems"
    })
  },
  methods: {
    ...mapActions([
      "getBalaProducteFinalBarcode",
      "getBalaBarcode",
      "getBorres",
      "getMateriesProducteFinal", 
      "getPartides", 
      "getMagatzems", 
      "almacenarBalas",
      "desalmacenarBalas",
      "getMagatzemNumero", 
      "getMagatzemFiltre"
    ]),
    // Escaneja bales
    carregaCodi() {
      if (this.codiEscanejatLlistat == "") {this.cerrardialogMagatzem; return;}
      if (this.codiEscanejatLlistat.charAt(0) == "9"){
        if(this.bales_carregades.length>0){
          this.magatzems.forEach(el => el.posicions.forEach(pos=> {if(pos.barcode == this.codiEscanejatLlistat){
            this.dades.magatzem= el.id;
            this.dades.barcode = pos.barcode;
            this.dades.posicio = pos.id;
            this.dades.magatzem_nom = el.nom + 'Nº '+ pos.zona;
          }}));

          // this.getMagatzemFiltre(
          //   { numero: parseInt(this.codiEscanejatLlistat.charAt(1)+ this.codiEscanejatLlistat.charAt(2) + this.codiEscanejatLlistat.charAt(3)),
          //   zona: parseInt(this.codiEscanejatLlistat.charAt(4)+ this.codiEscanejatLlistat.charAt(5)), 
          //   posicio: parseInt(this.codiEscanejatLlistat.charAt(6)+ this.codiEscanejatLlistat.charAt(7)+ this.codiEscanejatLlistat.charAt(8))}).then(magatzemInfo=>{
          //   if(magatzemInfo.length==1)
          //   {this.dades.magatzem = magatzemInfo[0].magatzem;
          //   this.dades.barcode = this.codiEscanejatLlistat;
          //   this.dades.posicio = magatzemInfo[0].id;}
          //   else{
          //     var filtrado = magatzemInfo.filter(el=> el.barcode == this.codiEscanejatLlistat)
              // this.dades.magatzem = filtrado.magatzem;
              // this.dades.barcode = this.codiEscanejatLlistat;
              // this.dades.posicio = filtrado.id;
              // }

          // })
          
          this.dialogMagatzem = true;
          this.codiEscanejatLlistat="";
          return;
        } else {
          this.codiEscanejatLlistat="";
          return;
        }}
      if (
        this.bales_carregades.find(el => el.barcode === this.codiEscanejatLlistat)
      ) {
        this.dialogError=true;
        this.msg_error = `La bala ${this.codiEscanejatLlistat} ja està al llistat`
        this.codiEscanejatLlistat="";
        return;
      }
      if (this.codiEscanejatLlistat.charAt(0) == "1"){
      this.getBalaProducteFinalBarcode(this.codiEscanejatLlistat).then(bala => {
        if (bala.barcode>1){
          if(!this.almacenar){
          this.quitarAlmacenBala(bala);
          return;
          }
          if(bala.estat =="a" || bala.estat=="e" ){
            this.dialogError = true; 
            this.msg_error = `La bala ${bala.barcode} està asignada o expedida`;
            this.codiEscanejatLlistat="";
          }else{
            this.bales_carregades.push(bala);
            this.bales_carregades_borra.push(bala);
            this.codiEscanejatLlistat="";
            }
          return;
          } else {
            EventBus.$emit("Mesclada.Notificacio", {
            text: `No s'ha trobat la bala ${this.codiEscanejatLlistat} `,
            color: "warning"
          });
          this.codiEscanejatLlistat="";
          return;
          }
          });
      }else if(this.codiEscanejatLlistat.charAt(0) == "0"){     
      this.getBalaBarcode(this.codiEscanejatLlistat).then(bala => {
        if (bala?.id) {
          if(!this.almacenar){
          this.quitarAlmacenBala(bala);
          return;
          }
          if(bala.estat >= 3 ){
            this.dialogError = true; 
            this.msg_error = `La bala ${bala.barcode} està consumida`;
            this.codiEscanejatLlistat="";
          }else{
            this.balaAssignar = bala;
            this.bales_carregades.push(bala);
            this.bales_carregades_mp.push(bala);
            this.codiEscanejatLlistat="";
            }
          
          return;
        }else{
          EventBus.$emit("Mesclada.Notificacio", {
            text: `No s'ha trobat la bala ${this.codiEscanejatLlistat} `,
            color: "warning"
          });
          this.codiEscanejatLlistat="";
          return;
        }
      });
      }
    },
    btnBorrarBala(id){
      this.bales_carregades=this.bales_carregades.filter(el=> el.id!=id)
      this.bales_carregades_mp=this.bales_carregades_mp.filter(el=> el.id!=id)
      this.bales_carregades_borra=this.bales_carregades_borra.filter(el=> el.id!=id)
    },
    tancarDialogError(){
      this.dialogError = false;
      this.$nextTick(() => this.focusCodi());
    },
    cerrardialogMagatzem(){
      if(this.dialogMagatzem==true){
      this.dialogMagatzem = false;
      var balas_borra=[]; 
      this.bales_carregades_borra.forEach(el=> {balas_borra.push(el.id)
      if(el.magatzem!= null) {
        this.balas_almacenadas_borra.push({magatzem_id: el.magatzem, balas_borra: [el.id]});
        this.balas_almacenadas.push(el);
      }
      }
      );
      var balas_mp=[]; 
      this.bales_carregades_mp.forEach(el=> {balas_mp.push(el.id)
      if(el.magatzem!= null) {
        this.balas_almacenadas_mp.push({magatzem_id: el.magatzem, balas_mp_ids: [el.id]});
        this.balas_almacenadas.push(el);
      }
      });
      if(this.balas_almacenadas.length>0) {this.dialogDesalmacenar=true}
      else{
      var balas ={posicio_id: this.dades.posicio ,magatzem_id: this.dades.magatzem, balas_borra: balas_borra, balas_materia_prima: balas_mp};
      this.enviarAlmacenBala(balas);
      this.dialogMagatzem = false;
      this.dades.magatzem =0;
      this.bales_carregades = [];
      this.bales_carregades_borra=[];
      this.bales_carregades_mp=[];
      this.$nextTick(() => this.focusCodi());}}
    },
    enviarAlmacenBala(balas){
      this.almacenarBalas(balas);
    },
    quitarAlmacenBala(bala){
      if(bala.barcode.charAt(0) == "0") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_materia_prima: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){
            this.codiEscanejatLlistat="";
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
            
          }else{
            this.codiEscanejatLlistat="";
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      if(bala.barcode.charAt(0) == "1") this.desalmacenarBalas({posicio_id: bala.magatzem, balas_borra: [bala.id]}).then(
        response => 
        { 
          if(response=="200"){
            this.codiEscanejatLlistat="";
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} ha eixit del magatzem`,
            color: "succes"
          });
            
          }else{
            this.codiEscanejatLlistat="";
            EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${bala.barcode} no s'ha pogut modificar`,
            color: "warning"
          });
            }
        }
      );
      
      
    },
    quitarAlmacenBalas(){
      this.bales_carregades.forEach(el=>{
        if(el.magatzem==null){
          if(el.barcode.charAt(0) == "0") this.enviarAlmacenBala({posicio_id: this.dades.posicio ,magatzem_id: this.dades.magatzem, balas_materia_prima: [el.id]})
          if(el.barcode.charAt(0) == "1") this.enviarAlmacenBala({posicio_id: this.dades.posicio ,magatzem_id: this.dades.magatzem, balas_borra: [el.id]})
        }
      })
      this.balas_almacenadas_mp.forEach(el=> {this.desalmacenarBalas({posicio_id: el.magatzem_id, balas_materia_prima: el.balas_mp_ids}).then(
        response => { 
          if(response){
            this.enviarAlmacenBala({posicio_id: this.dades.posicio ,magatzem_id: this.dades.magatzem, balas_materia_prima: el.balas_mp_ids});
          }
        }
      );
      })
      this.balas_almacenadas_borra.forEach(el=> {this.desalmacenarBalas({posicio_id: el.magatzem_id, balas_borra: el.balas_borra}).then(
        response => {
          if(response){
            this.enviarAlmacenBala({posicio_id: this.dades.posicio ,magatzem_id: this.dades.magatzem, balas_borra: el.balas_borra});
          }
        }
      );
     })
      this.bales_carregades =[]; 
      this.bales_carregades_mp =[]; 
      this.bales_carregades_borra =[]; 
      this.balas_almacenadas=[];
      this.balas_almacenadas_mp=[];
      this.balas_almacenadas_borra=[];
      this.balas_borra_mp=[];
      this.dialogDesalmacenar = false;
      this.dialogMagatzem = false;
      this.$nextTick(() => this.focusCodi())
    },
    carregaBalaPosicio() {
      this.getBalaBarcode(this.codiEscanejatPosicio).then(bala => {
        if(!this.almacenar){
          this.quitarAlmacenBala(bala);
          return;
        }
        if (bala.consumida === "s") {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${this.balaAssignar.barcode} està consumida`,
            color: "warning"
          });
          return;
        }
        if (bala.consumida === "p") {
          EventBus.$emit("Mesclada.Notificacio", {
            text: `La bala ${this.balaAssignar.barcode} està en procés de ser consumida`,
            color: "warning"
          });
          return;
        }
        if (bala?.id) {
          if(!this.almacenar){
          this.quitarAlmacenBala(bala);
          return;
          }
          this.balaAssignar = bala;
          this.bales_carregades.push(bala);
        }
      });
    },
    textPartida(partidaID) {
      return this.partides.find(el => el.id === partidaID)?.codi.replaceAll('.', '');
    },

    textMagatzem(barcodeMag) {
      var nom; 
      this.magatzems.forEach(el => el.posicions.forEach(pos=> {if(pos.barcode == barcodeMag){
            nom = el.nom + 'Nº '+ pos.zona;
          }}));
      return nom;
    },
    finalitzar() {
      console.log("Finalitza");
    },
    CambioDesalmacenar(){
      this.almacenar=false;
    },
    CambioAlmacenar(){
      this.almacenar=true;
    },
    // Auxiliars
    esborrarLlistat() {
      console.log("esborrarLlistat");
      this.bales_carregades = [];
      this.$nextTick(() => this.focusCodi());
    },
    esborrarInfo() {
      console.log("esborrarInfo");
      this.dades.magatzem = 0;
      this.$nextTick(() => this.focusCodi());
    },
    focusCodi(){
      this.$refs.campBarcode.focus();
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created(){
    this.getPartides();
    this.getMagatzems();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

h1,
h2,
h3,
h3,
h4,
p,
span {
  font-size: 2vh;
}
.text-mostrar-bales {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  div:first-child {
    width: 20%;
  }
  div:nth-child(2) {
    width: 60%;
  }
  div:nth-child(3) {
    width: 20%;
  }
}

footer > div > span {
  font-size: 1em !important;
}

img {
  width:3vw;
}
.v-input{
  font-size: 3vh;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 3vw;
}
.v-dialog > .v-card > .v-card__text {
    padding: 12px;
}
// General
#emmagatzemar-bales-pf {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #container {
    padding-top: 4vh;
    width: 100%;
  }
}

// Títols
#llistat-bales-titol,
#dades-posicio-titol {
  display: flex;
  justify-content: center;
  h3 {
    position: relative;
    top: 16px;
    font-size: 4vw;
    background: white;
    border: 1px solid black;
    padding: 5px;
  }
}


// Bloc esquerra
#info-llistat-bales {
  background: rgb(211, 211, 211);
  padding: 0 1em;
  border: 1px solid black;
  height: 70vh;
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border:none!important;
    .col{
    flex-direction: column;
      // background: white;
    border:none!important;
    .bales-seleccionades-llistat{
      height: 35vh;
      overflow-y: scroll;
    }
    }
    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      // background: white;
      
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
  .separador {
    width: 100%;
    height: 30px;
  }
}

// Bloc dreta
#info-posicio-emmagatzenament {
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  height: 50vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      background: white;
      width: 100%;
      min-height: 50px;
      display: flex;
      flex-direction: row;
      
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
  .separador {
    width: 100%;
    height: 30px;
  }
}

// Botonera
.botonera {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  background: rgb(211, 211, 211) !important;
  border: none !important;

  button:first-child {
    width: auto;
    border-radius: 5px;
    height: 50px;
    font-size: 2vh;
  }
}

// TODO - Convertir açò en v-list
#bales-seleccionades-1 {
  display: block !important;
  // overflow-y: scroll;
  font-size: 1.5vh !important;
  div{
    justify-content: center;
    height: auto;
  }
  span{
    margin:0px !important
  }
}
#bales-seleccionades-2 {
  display: block !important;
  overflow-y: scroll;
  height: 35vh !important;
  font-size: 1.5vh !important;
  background: white !important;
  border-radius: 1vh;
  border: 1px dashed black;
  div{
    justify-content: center;
    height: auto;
    outline: 1px black;
    text-align: center;
  }
  span{
    margin:0px !important
  }
}

#quadres-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
}

.reset-row-escanejat-codi {
  // width: 100%;
  height: 5%;
  padding-bottom: 0px;
  margin-bottom: 0px;
  * {
    background: none !important;
    border: none !important;
  }
}
</style>