<template>
  <v-container>
    <!-- Materia Producte Final -->
    <v-simple-table v-if="materiaProducteFinal" class="mt-12">
      <thead>
        <h1>Vista detallada de tipus de borra</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ materiaProducteFinal.nom }}</td>
        </tr>
        <tr v-if="materiaProducteFinal.cost_trinxat!=0">
          <td>
            <b>Cost de trinxat (€/kg) </b>
          </td>
          <td>{{ materiaProducteFinal.cost_trinxat }}</td>
        </tr>
        <tr v-if="materiaProducteFinal.preu_quilo!=0">
          <td>
            <b>Preu quilo (€/kg) </b>
          </td>
          <td>{{ materiaProducteFinal.preu_quilo }}</td>
        </tr>
        <tr v-if="materiaProducteFinal.nom">
          <td>
            <b>Numero de bales</b>
          </td>
          <td>{{ materiaProducteFinal.num_bales }}</td>
        </tr>
        <tr v-if="materiaProducteFinal.nom">
          <td>
            <b>Quilos</b>
          </td>
          <td>{{ materiaProducteFinal.quilos }} kg</td>
        </tr>
        <tr v-if="materiaProducteFinal.descripcio">
          <td>
            <b>Descripció</b>
          </td>
          <td>{{ materiaProducteFinal.descripcio }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per editar materia producte final -->
      <v-dialog v-model="dialogEditMateriaProducteFinal" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark @click="btnDialogMateriaProducteFinal" v-on="on">Editar tipus de borra</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar tipus de borra</v-card-title>
          <v-container>
            <v-form id="formTipusBorra" ref="formTipusBorra" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="materia_producte_final_edit.nom" label="Nom" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="materia_producte_final_edit.cost_trinxat" label="Cost trinxat"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="materia_producte_final_edit.preu_quilo" label="Preu quilo €/kg"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="materia_producte_final_edit.descripcio" label="Descripció"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditMateriaProducteFinal = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btneditMateriaProducteFinal"
              :disabled="!materia_producte_final_edit.nom"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar proveïdor -->
      <!-- Dialog/Modal per eliminar bala -->
      <v-dialog v-model="dialogEliminarMateriaProducteFinal" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar tipus de borra</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar tipus de borra</v-card-title>
          <v-card-text>Està segur que vol eliminar este tipus de borra?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEliminarMateriaProducteFinal = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarMateriaProducteFinal">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar bala -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Proveïdor -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('TipusBorraList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "tipus-borra-detail",
  data: () => ({
    materiaProducteFinal: {
      nom: "",
      descripcio: "",
    },
    materia_producte_final_edit: {
      nom: "",
      descripcio: "",
    },
    dialogEditMateriaProducteFinal: false,
    dialogEliminarMateriaProducteFinal: false
  }),
  methods: {
    ...mapActions(["editMateriaProducteFinal", "eliminarMateriaProducteFinal", "getMateriaProducteFinal"]),

    // Botonera
    btnDialogMateriaProducteFinal() {
      this.materia_producte_final_edit = JSON.parse(JSON.stringify(this.materiaProducteFinal));
    },
    btneditMateriaProducteFinal() {
      this.editMateriaProducteFinal(this.materia_producte_final_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("TipusBorra.Notificacio", {
            text: "Tipus de borra editat correctament",
            color: "success"
          });
          this.dialogEditMateriaProducteFinal = false;
          this.getMateriaProducteFinal(this.materiaProducteFinal.id).then(
            materiaProducteFinal => (this.materiaProducteFinal = materiaProducteFinal)
          );
        } else
          EventBus.$emit("TipusBorra.Notificacio", {
            text: "Error editant el tipus de borra",
            color: "error"
          });
      });
    },
    btnEliminarMateriaProducteFinal() {
      this.eliminarMateriaProducteFinal(this.materiaProducteFinal.id).then(response => {
        if (response === 204) {
          EventBus.$emit("TipusBorra.Notificacio", {
            text: "Tipus de borra eliminat correctament",
            color: "success"
          });
          this.$router.push({ name: "TipusBorraList" });
        } else
          EventBus.$emit("TipusBorra.Notificacio", {
            text: "Error eliminant el tipus de borra",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getMateriaProducteFinal(this.$route.params.id).then(
      materiaProducteFinal => (this.materiaProducteFinal = materiaProducteFinal)
    );
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>