<template>
  <v-container>
    <!-- Llistat Fabriques -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="8" offset-sm="1">
            <h1>Llistat de Fabriques</h1>
          </v-col>
          <v-col sm="1" offset-sm="0">
            <!-- Dialog/Modal per crear fabriques -->
            <v-dialog v-model="dialogFabrica" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear fàbrica</v-card-title>
                <v-container>
                  <v-form id="formFabrica" ref="formFabrica" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="fabrica_nova.nom" label="Nom" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="fabrica_nova.prefix" label="Prefix" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCrearFabrica">Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearFabrica"
                    :disabled="!fabrica_nova.nom || !fabrica_nova.prefix"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear fàbrica -->
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="fabrica in fabriques" :key="fabrica.id">
          <td class="text-fabrica">
            <div @click="fabricaDetail(fabrica.id)">{{ fabrica.nom }}</div>
            <div>
              <v-btn color="error" dark @click="btnModalEliminar(fabrica.id)">Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Fàbriques -->
    <!-- Dialog/Modal per eliminar fàbrica -->
    <v-dialog v-model="dialogEliminarFabrica" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar fàbrica</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarFabrica = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminarFabrica()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar fàbrica -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "fabrica-llistat",
  data: () => ({
    dialogFabrica: false,
    dialogEliminarFabrica: false,
    fabricaEliminarID: null,
    fabrica_nova: {
      nom: "",
      prefix: "",
    },
    pagina: 1,
    totalPagines: 1
  }),
  computed: {
    ...mapGetters({
      fabriques: "getFabriques"
    })
  },
  methods: {
    ...mapActions(["eliminarFabrica", "getFabriques", "sendFabrica"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    // Edició de fàbrica
    btnCrearFabrica() {
      this.sendFabrica(this.fabrica_nova).then(fabrica => {
        if (fabrica?.data?.id) {
          this.tancarCrearFabrica();
        }
      });
    },
    btnModalEliminar(fabrica) {
      this.dialogEliminarFabrica = true;
      this.fabricaEliminarID = fabrica;
    },

    btnEliminarFabrica() {
      this.eliminarFabrica(this.fabricaEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Fabrica eliminat correctament",
            color: "success"
          });
          this.dialogEliminarFabrica = false;
          this.fabricaEliminarID = null;
        } else
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Error eliminant el fabrica",
            color: "error"
          });
      });
    },

    // Rerouting
    fabricaDetail(fabricaID) {
      this.$router.push({
        name: "FabricaDetail",
        params: { id: fabricaID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearFabrica() {
      this.fabrica_nova = {
        nom: "",
        prefix: "",
      };
      this.dialogFabrica = false;
    }
  },
  created() {
    this.getFabriques();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.text-fabrica {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }

}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>