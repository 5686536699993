<template>
  <v-container>
    <!-- Llistat Materies Primeres -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="12" offset-sm="0">
            <h1>Llistat de Bales de Materies Primeres Assignades</h1>
            <h1>Organitzats per magatzems</h1>
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="magatzem in magatzems" :key="magatzem.id">
          <td
            class="text-instancia"
            @click="magatzemList(magatzem.id)"
            v-if="magatzem.num_bales_asignadas_y_no_consumidas > 0"
          >
            <v-row>
              <v-col sm="6">{{ magatzem.nom }}</v-col>
              <v-col sm="6">{{ magatzem.num_bales_asignadas_y_no_consumidas}} bales</v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Materia Primera -->

    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "bala-assignada-magatzems-llistat",
  data: () => ({
    pagina: 1,
    totalPagines: 1
  }),
  computed: {
    ...mapGetters({
      magatzems: "getMagatzems"
    })
  },
  methods: {
    ...mapActions(["getMagatzems"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    // Rerouting
    magatzemList(magatzemID) {
      this.$router.push({
        name: "BalaAssignadaMagatzemList",
        params: { id: magatzemID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getMagatzems();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>