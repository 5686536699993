<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Bala -->
      <h1>Creació de Bala</h1>
      <v-row>
        <v-col sm="12">
          <v-text-field
            v-model="bala.quilos"
            label="Quilos"
            :error="balaError.quilos ? true : false"
            required
            outlined
            autofocus
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="bala.materia_primera"
            :items="materiesPrimeresOpcions"
            label="Materia primera"
            outlined
            item-value="id"
            :filter="customFilter"
            :error="balaError.materia_primera ? true : false"
            no-data-text="No s'ha pogut carregar cap materia primera"
            @change="carregarPreu()"
          >
            <template slot="selection" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
            <template slot="item" slot-scope="data">{{ textMateriaPrimera(data.item.id) }}</template>
          </v-autocomplete>
        </v-col>
        <v-col sm="1">
          <v-btn v-if="grupo!=4" outlined block color="success" dark @click="botonDialogMP" tabindex="-1">+</v-btn>
          <!-- Dialog/Modal per crear materia primera -->
          <v-dialog v-model="dialogMateriaPrimera" persistent max-width="600">
            <v-card>
              <v-card-title class="headline">Crear materia primera</v-card-title>
              <v-container>
                <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="material_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="material_nou.preu_quilo" label="Preu quilo" required
                      :error="balaError.preu_quilo ? true : false"
                      :rules="[v => 
                      v.includes(',')==false || 'Utilitza . no ,' ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10">
                      <v-autocomplete
                        v-model="material_nou.proveidor"
                        :items="proveidorOpcions"
                        no-data-text="No s'ha pogut carregar cap proveïdor"
                        item-text="nom"
                        item-value="id"
                        label="Proveïdor"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="2">
                      <!-- Dialog/Modal per crear proveïdors -->
                      <v-dialog v-model="dialogProveidor" persistent max-width="600">
                        <template v-slot:activator="{ on }">
                          <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">Crear proveïdor</v-card-title>
                          <v-container>
                            <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                              <v-row>
                                <v-col md="12">
                                  <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col md="12">
                                  <v-text-field
                                    v-model="proveidor_nou.poblacio"
                                    label="Població"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col md="12">
                                  <v-text-field
                                    v-model="proveidor_nou.id_facturaplus"
                                    label="ID Facturaplus"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                          <v-card-actions>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogProveidor = false"
                              tabindex="-1"
                            >Cancel·lar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="btnCrearProveidor"
                              tabindex="-1"
                            >Crear</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- ./Dialog/Modal per crear ingredients -->
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="10">
                        <v-autocomplete
                          v-model="material_nou.tipo"
                          :items="tiposOpcions"
                          no-data-text="No s'ha pogut carregar cap tipo de materia"
                          item-text="nom"
                          item-value="id"
                          label="Composició"
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="2">
                  <!-- Dialog/Modal per crear ingredients -->
                  <v-dialog v-model="dialogTipoMateria" persistent max-width="600">
                    <template v-slot:activator="{ on }">
                      <v-btn outlined block color="success" dark v-on="on">+</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Crear Composició</v-card-title>
                      <v-container>
                        <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="tipusMP_nou.nom" label="Composició" autofocus required @keydown.enter.prevent="btnCrearTipusMP"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                      <v-card-actions>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="dialogTipoMateria = false"
                        >Cancel·lar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="btnCrearTipusMP">Crear</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- ./Dialog/Modal per crear ingredients -->
                </v-col>
                    </v-row>
                  <v-row>
                    <v-col md="10">
                      <v-autocomplete
                        v-model="material_nou.ingredient"
                        :items="ingredientsOpcions"
                        no-data-text="No s'ha pogut carregar cap tipus de materia"
                        item-text="nom"
                        item-value="id"
                        label="Tipo de materia"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="2">
                      <!-- Dialog/Modal per crear ingredients -->
                      <v-dialog v-model="dialogIngredient" persistent max-width="600">
                        <template v-slot:activator="{ on }">
                          <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">Crear Tipus de materia</v-card-title>
                          <v-container>
                            <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                              <v-row>
                                <v-col md="12">
                                  <v-text-field v-model="ingredient_nou.nom" label="Nom" required autofocus @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                          <v-card-actions>
                            <v-btn
                              color="green darken-1"
                              text
                              tabindex="-1"
                              @click="dialogIngredient = false"
                            >Cancel·lar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="btnCrearIngredient"
                              tabindex="-1"
                            >Crear</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- ./Dialog/Modal per crear ingredients -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-checkbox v-model="material_nou.ignifug" label="Ignífug"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogMateriaPrimera = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearMateriaPrimera"
                  tabindex="-1"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear materia primera -->
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field
            v-if="grupo!=4"
            v-model="bala.preu_quilo"
            label="Preu per quilo"
            :error="balaError.preu_quilo ? true : false"
            :rules="[v => 
            v.includes(',')==false || 'Utilitza . no ,' ]" 
            required
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field
            v-model="bala.observacions"
            label="Observacions"
            outlined
            :error="balaError.observacions ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="bala.magatzem"
            :items="OpcionesSubalmacenes"
            label="Magatzem"
            item-text="nom"
            item-value="id"
            clearable
            outlined
            :error="balaError.magatzem ? true : false"
            no-data-text="No s'ha pogut carregar cap magatzem"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear magatzem -->
          <v-dialog v-model="dialogMagatzem" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn v-if="grupo!=4" outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear magatzem</v-card-title>
              <v-container>
                <v-form id="formMagatzem" ref="formMagatzem" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="magatzem_nou.nom" label="Nom" required></v-text-field>
                      <v-text-field v-model="magatzem_nou.numero" label="Numero" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogMagatzem = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="btnCrearMagatzem" tabindex="-1">Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear magatzem -->
        </v-col>
      </v-row>
      <!-- ./Balas -->
    </v-form>
    <!-- ./Form -->
    <div id="botonera">
      <v-btn
        block
        color="success"
        @click="crearBala"
        :disabled="bala.quilos<=0 || !bala.materia_primera"
      >Crear bala</v-btn>
    </div>
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('BalaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "bala-create",
  data: () => ({
    bala: {
      quilos: "",
      preu_quilo: "",
      observacions: "",
      materia_primera: "",
      magatzem: ""
    },
    OpcionesSubalmacenes:[],
    dialogTipoMateria: false,
    tipusMP_nou: {
      nom: ""
    },
    balaError: {
      quilos: "",
      preu_quilo: "",
      observacions: "",
      materia_primera: "",
      magatzem: ""
    },
    dialogIngredient: false,
    dialogMagatzem: false,
    dialogMateriaPrimera: false,
    dialogProveidor: false,
    ingredient_nou: {
      nom: ""
    },
    magatzem_nou: {
      nom: ""
    },
    material_nou: {
      ignifug: false,
      ingredient: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    proveidor_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    }
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors",
      tiposOpcions: "getTipusMateria",
      grupo: "getGroup"
    })
  },
  methods: {
    ...mapActions([
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getProveidors",
      "sendBala",
      "sendIngredient",
      "sendMateriaPrimera",
      "sendMagatzem",
      "sendProveidor",
      "almacenarBalas",
      "getTipusMateria",
      "sendTipusMateria"
    ]),
    botonDialogMP(){
      this.dialogMateriaPrimera = true
      this.getIngredients();
      this.getTipusMateria();
    },
    // Crear element
    crearBala() {
      const bala_a_crear = {
        materia_primera: this.bala.materia_primera,
        observacions: this.bala.observacions,
        preu_quilo: this.bala.preu_quilo,
        quilos: this.bala.quilos
      }
      this.sendBala(bala_a_crear).then(bala=>
      {
        if(bala.id && this.bala.magatzem){
          this.almacenarBalas({posicio_id: this.bala.magatzem, balas_materia_prima: [bala.id]}).then(
            response=>{
              if (response) this.$router.push({ name: 'BalaDetail', params: { id: bala.id } })
            }
          )
          
        } else if(bala.id){
          this.$router.push({ name: 'BalaDetail', params: { id: bala.id } })
        }
      })
      .then(error => {
        error?.forEach(msg => {
          this.$nextTick(function() {
            this.balaError[msg] = true;
          });
        });
      });
    },
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.ingredient = data.data.id;
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearTipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou.tipo = data.data.id;
          this.tipusMP_nou = {
            nom: ""
          };
          this.dialogTipoMateria = false;
          this.materia_primera.tipo_nom = this.textCodiGRS(this.materia_primera.tipo);
        }
      });
    },
    btnCrearMateriaPrimera() {
      //this.material_nou.ingredient=[this.material_nou.ingredient];
      this.sendMateriaPrimera(this.material_nou).then(data => {
        if (data?.status === 201) {
          this.material_nou = {
            ignifug: false,
            ingredient: [],
            nom: "",
            preu_quilo: null,
            proveidor: ""
          };
          this.dialogMateriaPrimera = false;
          this.bala.materia_primera = data.data.id;
          this.bala.preu_quilo = data.data.preu_quilo;

        }
      });
    },
    btnCrearMagatzem() {
      this.sendMagatzem(this.magatzem_nou).then(data => {
        if (data?.status === 201) {
          this.magatzem_nou = {
            nom: ""
          };
          this.dialogMagatzem = false;
          this.bala.magatzem = data.data.id;
        }
      });
    },
    btnCrearProveidor() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data?.status === 201) {
          this.proveidor_nou = {
            nom: ""
          };
          this.dialogProveidor = false;
          this.material_nou.proveidor = data.data.id;
        }
      });
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());
    },
    // Auxiliar
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient ? ` - ${ materiaPrimera?.nom_ingredient} `:''} - ${mpProveidor.nom}`;
      return materiaPrimera?.nom;
    },
    carregarPreu() {
      const preu_quilo =
        this.materiesPrimeresOpcions.find(
          el => el.id === this.bala.materia_primera
        )?.preu_quilo || 0;

      this.bala.preu_quilo = preu_quilo;
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getProveidors();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.input-error {
  color: red;
}
#botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>