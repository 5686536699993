<template>
  <v-container>
    <v-dialog v-model="dialogError1" max-width="600">
        <v-card>
          <v-card-title class="headline">ERROR PROCESANT MESCLADA</v-card-title>
          <v-card-text v-if="error_procesar">
              {{error_procesar}}
          </v-card-text>
          <v-card-text >
              Revisa el número del contador, puede que no sea correcto
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Entrada -->
      <h1>RESETEAR mesclada Manualment</h1>
      <v-row>
         <v-col sm="3" >
          <span style=" background: none; margin-top: 5px;" >
          <v-select
                  style="border: none; background: none; height: 65px"
                  v-model="num_maquina"
                  :items="maquinas"
                  filled
                  no-data-text
                  outlined
                  item-text="numero"
                  item-value="id"
                  label="Nº Máquina"
                ></v-select></span>
        </v-col>
        <v-col sm="7" style="height= 5%">  
          <span style=" background: none; margin-top: 5px;" >
          <v-text-field
            v-model="codiEscanejat"
            label="Codi escanejat"
            v-on:keyup.enter="carregaBala"
            outlined
            required
            ref="campBarcode"
            v-if="!mesclada.id"
          ></v-text-field>
          <v-select
            v-else
              style="border: none; background: none; height: 65px"
              v-model="MagatzemPartida"
              :items="OpcionesSubalmacenes"
              filled
              no-data-text
              outlined
              item-text="nom"
              item-value="id"
              label="Magatzem"
            ></v-select>
        </span>
        </v-col>
        <v-col sm="2">  
          <v-btn 
          @click="cancelarMesclada">
            Cancelar mesclada
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="mesclada.id">
         <v-col sm="4" style="height= 5%; text-align: center">
         <strong> Mesclada_id: </strong> {{mesclada.id}}
        </v-col>
        <v-col sm="4" style="height= 5%; text-align: center">  
         <strong> Producte final:</strong> {{textProducteFinal(mesclada.producte_final)}}
        </v-col>
        <v-col sm="4" style="height= 5%; text-align: center">  
          <strong>Quilos totals:</strong> {{mesclada.quilos_total}} kg
        </v-col>
      </v-row>
      <!-- ./Entrada -->

      <!-- Grups -->
      <div v-for="(grup_bales, index_grup) in quartos" :key="index_grup" class="pb-2 pt-2" style="border: black solid 2px; border-radius: 20px">
        <!-- Creació de bales -->
        <v-row>
          <v-col md="8">
        <h3>Grup de bales {{ index_grup + 1}} - QUARTO: {{grup_bales.quarto}} - Quilos borra: {{quilos_cuartos_comp[index_grup]}} kg</h3>
        </v-col>
          <v-col md="3">
            <v-text-field
              v-model="grup_bales.bales_a_crear"
              label="Bales a crear"
              outlined
              required
              :rules="[rules.required, rules.counter]"
              @input="crearBales(grup_bales,index_grup)"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- ./Creació de bales -->
        <!-- Bales -->
        <div
          v-for="(bala, index_bala) in grup_bales.bales"
          :key="index_bala"
          class="pb-2"
        >
          <v-row>
            <v-col offset-md="1" md="4">
              <h4>Bala #{{ index_grup +1 }}.{{ index_bala +1 }}</h4>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="bala.quilos"
                outlined
                dense
                label="Quilos"
                required
                @input="calcular_kgs()"
                :rules="[rules.required, rules.quilos]"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <!-- ./Bales -->
      </div>

      <!-- ./Grups -->
      <div id="botonera">
        <v-btn color="success"  @click="btnProcesar" :disabled="!mesclada.id || !num_maquina">Processar</v-btn>
        <v-btn color="primary"  @click="btnGuardar" :disabled="!mesclada.id || !num_maquina">Guardar</v-btn>
      </div>
    </v-form>
    <!-- Form -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('MescladaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->

    <!-- Dialog/Modal per crear materia primera -->
    <v-dialog v-model="dialogMateriaPrimera" persistent max-width="600">
        <v-card>
        <template v-slot:activator="{ on }">
        <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
      </template>
        <v-card-title class="headline">Crear materia primera</v-card-title>
        <v-container>
          <v-form id="formMateriaPrimera" ref="formMateriaPrimera" class="mt-12">
            <v-row>
              <v-col md="12">
                <v-text-field v-model="material_nou.nom" label="Nom" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field v-model="material_nou.preu_quilo" label="Preu quilo" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="10">
                <v-autocomplete
                  v-model="material_nou.proveidor"
                  :items="proveidorOpcions"
                  no-data-text="No s'ha pogut carregar cap proveïdor"
                  item-text="nom"
                  item-value="id"
                  label="Proveïdor"
                ></v-autocomplete>
              </v-col>
              <v-col sm="2">
                <!-- Dialog/Modal per crear proveïdors -->
                <v-dialog v-model="dialogProveidor" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear proveïdor</v-card-title>
                    <v-container>
                      <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="proveidor_nou.poblacio"
                              label="Població"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="proveidor_nou.id_facturaplus"
                              label="ID Facturaplus"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialogProveidor = false"
                        tabindex="-1"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearProveidorMP"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear ingredients -->
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="material_nou.GSR"
                  label="Codi GSR"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="10">
                <v-autocomplete
                  v-model="material_nou.ingredients"
                  :items="ingredientsOpcions"
                  no-data-text="No s'ha pogut carregar cap tipus de materia"
                  item-text="nom"
                  return-object
                  label="Ingredient"
                ></v-autocomplete>
              </v-col>
              <v-col sm="2">
                <!-- Dialog/Modal per crear ingredients -->
                <v-dialog v-model="dialogIngredient" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear tipus de materia</v-card-title>
                    <v-container>
                      <v-form id="formIngredient" ref="formIngredient" class="mt-12">
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="ingredient_nou.nom" label="Nom" required autofocus @keydown.enter.prevent="btnCrearIngredient"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        tabindex="-1"
                        @click="dialogIngredient = false"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearIngredient"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear ingredients -->
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-checkbox v-model="material_nou.ignifug" label="Ignífug"></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMateriaPrimera = false"
            tabindex="-1"
          >Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnCrearMateriaPrimera" tabindex="-1">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per crear materia primera -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "mesclada-create",
  data: () => ({
    mesclada: {
      data_albara: null,
      cost_total: null,
      quilos_total: null,
      quilos_teorics:null,
      observacions: "",
      proveidor: "",
      magatzem: ""
    },
    mezclada_data : {
        maquina_id : "",
        mezclada_id: "",
        mezclada: "",
        inicio: "2021-09-07T11:15:03.809624+00:00",
        fin: "2021-09-07T11:17:23.809624+00:00",
        pesos_balas_salida : {1: [], 2: [], 3: [], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[], 11:[], 12:[] }
      },
    csv:"",
    MagatzemPartida:"",
    OpcionesSubalmacenes:[],
    error_procesar:"",
    rules: {
      required: value => !!value || 'Obligatori',
      counter: value => value < 250 || 'Més de 250 bales',
      quilos: value=> value>0 || 'Pes major que 0'
    },
    quartos:[],
    dialogError1: false,
    codiEscanejat:"",
    num_maquina:"",
    bales_creades:[],
    grups: {
      grup_bales: [
        {
          materia_primera: "",
          preu_quilo: "",
          bales_a_crear: "",
          bales: []
        }
      ]
    },
    quilos_cuartos:[0,0,0,0,0,0,0],
    ingredient_nou: {
      nom: ""
    },
    magatzem_nou: {
      nom: ""
    },
    material_nou: {
      ignifug: false,
      ingredients: [],
      nom: "",
      preu_quilo: null,
      proveidor: ""
    },
    proveidor_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    dialogIngredient: false,
    dialogMagatzem: false,
    dialogMateriaPrimera: false,
    dialogProveidor: false,
    novaMateriaPrimeraIndex: null,
    materiesPrimeresOpcionsFiltrades:[],
    modalData: false
  }),
  computed: {
    quilos_cuartos_comp: function(){
      var total_cuartos=[]
      this.quartos.forEach(grup_bales=>{
        if (!grup_bales.bales) {
        grup_bales.kg = 0;
        total=0;}
        if (grup_bales.bales?.length === 0){ 
          grup_bales.kg =0;
          total= 0;}
        let total = 0;
        grup_bales.bales.forEach(bala => {
        if (bala.quilos) total += parseInt(bala.quilos);
        });
        grup_bales.kg=total
        // this.quilos_cuartos[index] = total;
        total_cuartos.push(total)
      })
       return total_cuartos

    },
    ...mapGetters({
      tipusBorra: "getMateriesProducteFinal",
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors",
      maquinas: "getMaquinas"
    })
  },
  methods: {
    ...mapActions([
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getProveidors",
      "sendEntrada",
      "sendBala", 
      "addBalaMesclada",
      "sendIngredient",
      "sendMagatzem",
      "sendMateriaPrimera",
      "sendProveidor",
      "almacenarBalas", 
      "getMesclada",
      "getMateriesProducteFinal",
      "getMaquinas", 
      "getPrefixos",
      "getQuartoMesclat",
      "getQuartosMesclat",
      "getBalaBarcode",
      "getBalaParcial",
      "procesarMezcladaCompleta"
    ]),

    eliminarBala(grup, index_bala) {
      grup.bales.splice(index_bala, 1);
    },
    partidaDetail(partidaID) {
      this.$router.push({
        name: "PartidaDetail",
        params: { id: partidaID }
      });
    },
    textProducteFinal(ID) {
      const producteFinal = this.tipusBorra.find(
        el => el.id === ID
      );
      return producteFinal?.nom;
    },
    btnGuardar(){
      this.mezclada_data.pesos_balas_salida = {1: [], 2: [], 3: [], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[], 11:[], 12:[] };
      this.quartos.forEach(quarto=>
      {
        quarto.bales.forEach(bala =>{
          if(bala.quilos)
          {this.mezclada_data.pesos_balas_salida[quarto.quarto].push(parseInt(bala.quilos))}
        })
      });
      localStorage.setItem('balas_a_procesar',  JSON.stringify(this.quartos));
      localStorage.setItem('mesclada',  JSON.stringify(this.mesclada));
    },
    btnProcesar(){
        this.mezclada_data.maquina_id = this.num_maquina;
       this.mezclada_data.mezclada = this.mesclada.id;
      if(this.mesclada.quartos_mesclat[0].porcions_mesclat.length>0){
       
      

      this.procesarMezcladaCompleta(this.mezclada_data).then(
        response=> 
        {if(response.partida.estat=="a"){
          
          if(response.partida.bales_productes_finals){
            response.partida.bales_productes_finals.forEach(
              el => this.almacenarBalas({posicio_id: this.MagatzemPartida,
              balas_borra: [el.id]})
            )
            
          }
          this.cancelarMesclada();
          this.partidaDetail(response.partida.id)
          
        }
        }
      )
      .catch(error=> 
      {if(error){
        this.dialogError1=true;
        this.error_procesar=error.data;
      }})
      }
      else
      {const bala_a_crear = {
        materia_primera: 26,
        observacions: "FAKE",
        preu_quilo: "0.123",
        quilos: "1"
        }
        this.sendBala(bala_a_crear).then(bala=>
          {
          if(bala.id){
            this.almacenarBalas({posicio_id: 2, balas_materia_prima: [bala.id]}).then(
              response=> {if(response){
                this.addBalaMesclada({id:this.mesclada.id ,bala:{bala: bala.id, 
                quartos:[ this.mesclada.quartos_mesclat[0].id]}}).then(
                  response=> {if(response) 
                  //this.mezclarBala({barcode: bala.barcode, 
                  //quarto:this.mesclada.quartos_mesclat[0].id, num_maquina: this.num_maquina})

                    this.procesarMezcladaCompleta(this.mezclada_data).then(
                      response=> 
                      {if(response.partida.estat=="a"){
                        if(response.partida.bales_productes_finals){
                          response.partida.bales_productes_finals.forEach(
                            el => this.almacenarBalas({posicio_id: this.MagatzemPartida, balas_borra: [el.id]})
                          )
                          
                        }
                        this.cancelarMesclada();
                        this.partidaDetail(response.partida.id)
                      }
                      }
                    )
                    .catch(error=> 
                    {if(error){
                      this.dialogError1=true;
                      this.error_procesar=error;
                    }})
                  }
                )
              }}
            )
          }
          })}

      
    },
    // Creació de la estructura de bales
    crearBales(grup, index) {
      if(!grup.bales){
        grup.bales=[];
      }
      if(grup.bales_a_crear==0 || grup.bales_a_crear=="" || grup.bales_a_crear<0){
        return
      }
      const bales_a_crear = grup.bales_a_crear;
      const bales_existents = grup.bales.length;

      if (!bales_a_crear) {
        EventBus.$emit("Entrada.Notificacio", {
          text: "No et deixes cap camp buid per a crear bales",
          color: "warning"
        });
        return;
      }

      if (bales_existents > bales_a_crear) {
        // Eliminar bales
        grup.bales.splice(
          bales_a_crear - bales_existents,
          bales_existents
        );
      } else if (bales_existents < bales_a_crear) {
        // Afegir bales
        const bales_totals_a_crear = bales_a_crear - bales_existents;
        for (let index = 0; index < bales_totals_a_crear; index++) {
          grup.bales.push({
            quilos: ""
          });
        }
      } else {
        // No fer res
      }

      this.calcular_kg_grup(grup, index)
    },
    // Crear nous models
    btnCrearIngredient() {
      this.sendIngredient(this.ingredient_nou).then(data => {
        if (data?.status === 201) {
          this.ingredient_nou = {
            nom: ""
          };
          this.dialogIngredient = false;
        }
      });
    },
    btnCrearProveidor() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data.status === 201) {
          this.proveidor_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogProveidor = false;
          this.mesclada.proveidor = data.data.id;
        }
      });
    },
    btnCrearProveidorMP() {
      this.sendProveidor(this.proveidor_nou).then(data => {
        if (data.status === 201) {
          this.proveidor_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogProveidor = false;
          this.material_nou.proveidor = data.data.id;
        }
      });
    },
    btnCrearMagatzem() {
      this.sendMagatzem(this.magatzem_nou).then(data => {
        if (data.status === 201) {
          this.magatzem_nou = {
            nom: ""
          };
          this.dialogMagatzem = false;
          this.mesclada.magatzem = data.data.id;
        }
      });
    },
    btnCrearMateriaPrimera() {
      this.material_nou.ingredients=[this.material_nou.ingredients];
      this.sendMateriaPrimera(this.material_nou).then(data => {
        if (data.status === 201) {
          this.material_nou = {
            nom: "",
            preu_quilo: null,
            proveidor: "",
            ignifug: false
          };
          this.dialogMateriaPrimera = false;
          this.grups.grup_bales[this.novaMateriaPrimeraIndex].materia_primera =
            data.data.id;
          this.grups.grup_bales[this.novaMateriaPrimeraIndex].preu_quilo =
            data.data.preu_quilo;
        }
      });
    },
    btnCrearMateriaPrimeraModal(index) {
      this.novaMateriaPrimeraIndex = index;
      this.dialogMateriaPrimera = true;
    },
    cancelarMesclada(){
      this.mesclada=[]
      this.quartos=[]
      localStorage.removeItem('balas_a_procesar')
      localStorage.removeItem('mesclada')
    },
    carregaBala() {
      if(this.codiEscanejat == "") return;
      this.getBalaBarcode(this.codiEscanejat).then(bala => {
        if(bala == []){
          this.codiEscanejat = "";
          return;
        }
        if (bala.estat == 1) {
          this.dialogError1=true;
          this.codiEscanejat = "";
          return;
        }
        if (bala.estat == 4 || bala.estat == 5 || bala.estat == 6) {
          this.dialogError1=true;
          this.codiEscanejat = "";
          return;
        }
        if (bala.estat == 2 || bala.estat == 3) {
          this.getBalaParcial(this.codiEscanejat).then(bala_parcial=>{
            if(bala_parcial[0].quarto>0){
              this.getQuartoMesclat(bala_parcial[0].quarto).then(quarto=>
             {if(quarto.ordre_fabricacio)
               {this.getMesclada(quarto.ordre_fabricacio)
                .then(mesclada => {
                  this.mesclada = mesclada;
                  this.getQuartosMesclat(mesclada.id).then(quartos_mesclada=>
                  {
                    var porcions_mesclat =[];
                    var quartos = []; 
                    this.mesclada.quartos_mesclat = quartos_mesclada;
                    quartos_mesclada.forEach(quarto => {
                    quarto.bales=[];
                    quarto.kg =0;
                    quartos.push(quarto.quarto); 
                    this.quartos.push(quarto)
                  quarto.porcions_mesclat.forEach(porcio=>porcions_mesclat.push(porcio))})
                  })
                })}
              })
          }});
          this.codiEscanejat = "";
          // this.focusCodi();
          return;
        }
        if (bala.estat == 4) {
          this.codiEscanejat = "";
          this.dialogError2=true;
          return;
        }
      });
    },
    // Datepicker
    datepicker() {
      this.$refs.dialog.save(this.mesclada.data_albara);
      this.modalData = false;
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.mesclada.data_albara = null));
      this.modalData = false;
    },
    // Auxiliar
    calcular_preu_grup(grup_bales) {
      if (grup_bales.length === 0) return 0;
      let total = 0;
      grup_bales.bales.forEach(bala => {
        if (bala.quilos){
           total += parseFloat(bala.quilos*bala.preu_quilo)
           }
      });
      return total.toFixed(2);
    },
    quilosOk(){
      if(this.mesclada.quilos_teorics>0) return this.mesclada.quilos_teorics==this.mesclada.quilos_total
    },
    quilosGrupOk(index_grup){
      if(this.grups.grup_bales[index_grup].quilos_grup!=0 && 
      this.grups.grup_bales[index_grup].quilos_grup == this.calcular_kg_grup(this.grups.grup_bales[index_grup].bales)){
        return true
      }
    },
    quilosNoOk(){
      if(this.mesclada.quilos_teorics>0 && this.mesclada.quilos_total>0) return this.mesclada.quilos_teorics!=this.mesclada.quilos_total
    },
    calcular_kg_grup(grup_bales, index) {
      if (!grup_bales.bales) {
        grup_bales.kg = 0;
        return 0;}
      if (grup_bales.bales?.length === 0){ 
        grup_bales.kg =0;
        return 0;}
      let total = 0;
      grup_bales.bales.forEach(bala => {
        if (bala.quilos) total += parseInt(bala.quilos);
      });
      grup_bales.kg=total
      this.quilos_cuartos[index] = total;
      return total;
    },
    calcular_kgs() {
      let balesKG = [];
      let finalKG = 0;
      
      // Agrupem totes les bales en un únic array
      this.mesclada.quartos_mesclat.forEach(el => 
      el.bales.forEach(bala=>{balesKG.push(bala)}));
      balesKG = balesKG.flat();

      // Calculem els KG
      for (var i in balesKG) {
        if (!isNaN(parseInt(balesKG[i].quilos))) {
          finalKG += parseInt(balesKG[i].quilos);
        }
      }
      this.mesclada.quilos_total = finalKG;
    },
    carregarPreu(index_grup) {
      const materia_primera_id = this.grups.grup_bales[index_grup]
        .materia_primera;
      const preu_quilo =
        this.materiesPrimeresOpcions.find(el => el.id === materia_primera_id)
          ?.preu_quilo || 0;
      this.grups.grup_bales[index_grup].preu_quilo = preu_quilo;
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor.nom;
      return materiaPrimera?.nom;
    }
  },
  created() {
    if(this.$route.params.id){
      this.getMesclada(this.$route.params.id).then(mesclada => {
                  this.mesclada = mesclada;
                  this.getQuartosMesclat(mesclada.id).then(quartos_mesclada=>
                  {
                    var porcions_mesclat =[];
                    var quartos = []; 
                    this.mesclada.quartos_mesclat = quartos_mesclada;
                    quartos_mesclada.forEach(quarto => {
                    quarto.bales=[];
                    quarto.kg =0;
                    quartos.push(quarto.quarto); 
                    if(!localStorage.getItem('balas_a_procesar')){this.quartos.push(quarto);
                    
                    }
                    
                  quarto.porcions_mesclat.forEach(porcio=>porcions_mesclat.push(porcio))})
                  })
                })
    }
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesProducteFinal();
    this.getPrefixos();
    this.getMaquinas();
    if(localStorage.getItem('balas_a_procesar')){ this.quartos = JSON.parse(localStorage.getItem('balas_a_procesar'))}
    if(localStorage.getItem('mesclada')){ this.mesclada = JSON.parse(localStorage.getItem('mesclada'));
    this.calcular_kgs()}
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
h2 {
  text-align: center;
  padding-bottom: 0.5em;
}
h3 {
  text-align: center;
  padding-top: 0.5em;
}
.crear-bales {
  width: 100%;
}
.quilos-ok{
  color: rgb(207, 244, 161);
  border-radius: 10px;
  border: 1px dashed black;
  padding: 0px;
  margin: 0px;
}
.v-text-field__details {
    display: none !important;
}
.quilos-not-ok{
  color: red;
}
img {
  width: 25px;
}
#botonera {
  padding-bottom: 50px;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 46%;
  }
}
// Form Proveïdor
#formProveidor {
  margin-top: 0px !important;
}
</style>