<template>
  <v-container>
    <!-- Llistat Proveïdors -->
    <v-simple-table class="mt-12" style="padding-bottom: 1em">
      <thead>
        <v-row>
          <v-col sm="8" offset-sm="1">
            <h1>Llistat Proveïdors</h1>
          </v-col>
          <v-col sm="1" offset-sm="1">
            <!-- Dialog/Modal per crear proveïdor -->
            <v-dialog v-model="dialogProveidor" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear proveïdor</v-card-title>
                <v-container>
                  <v-form id="formProveidor" ref="formProveidor" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="proveidor_nou.nom" label="Nom" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="proveidor_nou.poblacio" label="Població" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="proveidor_nou.id_facturaplus" label="ID Facturaplus"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCrearProveidor">Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearProveidor"
                    :disabled="!proveidor_nou.nom || !proveidor_nou.poblacio"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear proveïdor -->
          </v-col>
        </v-row>
        <v-row style="display: flex; justify-content: space-betwenn; text-align: center;">
        <v-btn
          id="generarExcel"
          ref="buttonGetExcelData"
          @click="getExcelData"
        >Generar Excel</v-btn>
        <downloadexcel
          id="downloadexcel"
          ref="buttonGenerarExcel"
          class="btn"
          :name="nombreExcel"
          :data="excelArray"
          :fields="json_fields"
          worksheet = "Pàgina 1"
          type="xls"
        ></downloadexcel>
        <v-btn
          id="generarExcel"
          @click="addExcelData"
          style="margin-left: auto"
        >Afegir a Excel</v-btn>
        <v-btn
          id="generarExcel"
          @click="buidarExcel"
          style="margin-left: auto"
        >Buidar Excel</v-btn>
        </v-row>
      <v-row>
        <v-col sm="5" offset-sm="6">
          <v-autocomplete
            v-model="switch1"
            :items="conStock"
            item-text="nom"
            item-value="id"
            label="Filtre per Stock"
            @change="filtrar"
            outlined
            rounded
          ></v-autocomplete>
        </v-col>
        <!-- <v-col sm="2" offset-sm="8">
          <v-switch
          v-model="switch1"
          @change="filtrar"
          :label="'Sin Stock'"
        ></v-switch>
        </v-col>
        <v-col sm="2" >
          <v-switch
          v-model="switch2"
          @change="filtrar"
          :label="'Con Stock'"
        ></v-switch>
        </v-col> -->
      </v-row>
      </thead>
      
      <tbody>
        <tr v-for="proveidor in proveidors" :key="proveidor.id">
          <td class="text-proveidor">
            <div @click="proveidorDetail(proveidor.id)">{{ proveidor.nom }}</div>
            <div>
              <v-btn color="error" dark @click="btnModalEliminar(proveidor.id)">Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Proveïdors -->
    <!-- Dialog/Modal per eliminar proveidor -->
    <v-dialog v-model="dialogEliminarProveidor" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar proveïdor</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarProveidor = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminarProveidor()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar proveidor -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";

export default {
  name: "proveidor-llistat",
  data: () => ({
    conStock: [{nom:"Todos",id:1}, {nom:"Con Stock",id:2}, {nom:"Sin Stock",id:3} ],
    dialogProveidor: false,
    dialogEliminarProveidor: false,
    proveidorEliminarID: null,
    proveidor_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    paginasAñadidas:[],
    json_fields: {
        Nom: "nom",
        Poblacio: "poblacio",
        ID_Facturaplus: "id_facturaplus"
        
      },
    excelArray: [],
    nombreExcel: "Llistat_Proveidors.xls",
    switch1:true,
    switch2:true,
    pagina: 1,
    totalPagines: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      proveidors: "getProveidors"
    })
  },
  methods: {
    ...mapActions(["eliminarProveidor", "getProveidors", "getProveidorsFiltrats", "sendProveidor"]),
    filtrar(){
      var stock; 
      // if(this.switch1 & this.switch2){ stock="" }
      // if(this.switch1 & !this.switch2){ stock=false }
      // if(!this.switch1 & this.switch2){ stock=true }
      if(this.switch1 == 1){ stock="" }
      if(this.switch1 == 3){ stock=false }
      if(this.switch1 == 2){ stock=true }
      this.getProveidorsFiltrats(stock);
    },
    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      // this.getBalesFiltrades(this.filtre);
    },
    getExcelData(){
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
      this.paginasAñadidas = [];

    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];
    },
    addExcelData(){
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.proveidors); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Edició de proveïdor
    btnCrearProveidor() {
      this.sendProveidor(this.proveidor_nou).then(proveidor => {
        if (proveidor?.data?.id) {
          this.tancarCrearProveidor();
        }
      });
    },
    btnModalEliminar(proveidor) {
      this.dialogEliminarProveidor = true;
      this.proveidorEliminarID = proveidor;
    },

    btnEliminarProveidor() {
      this.eliminarProveidor(this.proveidorEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Proveidor.Notificacio", {
            text: "Proveïdor eliminat correctament",
            color: "success"
          });
          this.dialogEliminarProveidor = false;
          this.proveidorEliminarID = null;
        } else
          EventBus.$emit("Proveidor.Notificacio", {
            text: "Error eliminant el proveïdor",
            color: "error"
          });
      });
    },

    // Rerouting
    proveidorDetail(proveidorID) {
      this.$router.push({
        name: "ProveidorDetail",
        params: { id: proveidorID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCrearProveidor() {
      this.proveidor_nou = {
        nom: "",
        poblacio: "",
        id_facturaplus: ""
      };
      this.dialogProveidor = false;
    }
  },
  created() {
    this.getProveidors();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
}
.text-proveidor {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>