<template>
  <v-container>
    <!-- Llistat tipusMPs -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="8" offset-sm="1">
            <h1>Llistat de Composicions</h1>
          </v-col>
          <v-col sm="1" offset-sm="1">
            <!-- Dialog/Modal per crear tipusMP -->
            <v-dialog v-model="dialogtipusMP" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear tipus MP</v-card-title>
                <v-container>
                  <v-form id="formtipusMP" ref="formtipusMP" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="tipusMP_nou.nom" autofocus @keydown.enter.prevent="btnCreartipusMP" label="Nom" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCreartipusMP">Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCreartipusMP"
                    :disabled="!tipusMP_nou.nom"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear tipusMP -->
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="tipusMP in tipusMPs" :key="tipusMP.id">
          <td class="text-tipusMP">
            <div @click="tipusMPDetail(tipusMP.id)">{{ tipusMP.nom }}</div>
            <div>
              <v-btn color="error" dark @click="btnModalEliminar(tipusMP.id)">Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat tipusMPs -->
    <!-- Dialog/Modal per eliminar tipusMP -->
    <v-dialog v-model="dialogEliminartipusMP" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar tipusMP</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminartipusMP = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminartipusMP()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar tipusMP -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "tipusMP-llistat",
  data: () => ({
    dialogtipusMP: false,
    dialogEliminartipusMP: false,
    tipusMPEliminarID: null,
    tipusMP_nou: {
      nom: ""
    },
    pagina: 1,
    totalPagines: 1
  }),
  computed: {
    ...mapGetters({
      tipusMPs: "getTipusMateria"
    })
  },
  methods: {
    ...mapActions(["eliminarTipusMateria", "getTipusMateria", "sendTipusMateria"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    // Edició de tipusMP
    btnCreartipusMP() {
      this.sendTipusMateria(this.tipusMP_nou).then(tipusMP => {
        if (tipusMP?.data?.id) {
          this.tancarCreartipusMP();
        }
      });
    },
    btnModalEliminar(tipusMP) {
      this.dialogEliminartipusMP = true;
      this.tipusMPEliminarID = tipusMP;
    },

    btnEliminartipusMP() {
      this.eliminarTipusMateria(this.tipusMPEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Tipus de Materia primera eliminat correctament",
            color: "success"
          });
          this.dialogEliminartipusMP = false;
          this.tipusMPEliminarID = null;
        } else
          EventBus.$emit("Ingredient.Notificacio", {
            text: "Error eliminant el tipusMP",
            color: "error"
          });
      });
    },

    // Rerouting
    tipusMPDetail(tipusMPID) {
      this.$router.push({
        name: "TipusMateriaPrimeraDetail",
        params: { id: tipusMPID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    tancarCreartipusMP() {
      this.tipusMP_nou = {
        nom: ""
      };
      this.dialogtipusMP = false;
    }
  },
  created() {
    this.getTipusMateria();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
.text-tipusMP {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>