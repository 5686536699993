<template>
  <v-container>
    <v-dialog v-model="dialogError" @keydown="tancarDialogError" max-width="600">
        <v-card>
          <v-card-title class="justify-center"><pre>{{msg_error}}</pre></v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError" autofocus>TORNAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="boton_eliminarBalas" color="red" dark @click="btnEliminarBalesParcials()" autofocus>Eliminar todas</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEliminarBala" max-width="600">
        <v-card>
          <v-card-title class="justify-center"><pre>¿Estás segur que vols borrar aquesta bala?</pre></v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError" autofocus>TORNAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="btnEliminarBalaParcial(info_balaEliminar)" autofocus>Eliminar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogQuarto" @keydown="tancarDialogError" max-width="600">
        <v-card>
          <v-card-title v-if="of.estat !='-' && of.estat !='c' " class="justify-center" 
          style = "text-align: center"
          > Quarto {{msg_quarto.num_quarto}} - Núm de bales: {{contarBalas(arrayBalesDeQuarto(msg_quarto.num_quarto))}} - {{contarQuilos(arrayBalesDeQuarto(msg_quarto.num_quarto))}} kg
            <br> Estat: {{textEstatQuarto(msg_quarto.estat)}}
          </v-card-title>
          <v-card-title v-else class="justify-center"> Quarto {{msg_quarto.num_quarto}} - Núm de bales: {{contarBalas(arrayBalesDeQuarto(msg_quarto.num_quarto))}} - {{contarQuilos(arrayBalesDeQuarto(msg_quarto.num_quarto))}} kg
          </v-card-title>
          <v-card-text class="text-center">
          <div id="proporcions-dialog" 
            :v-if="of.plantilla"
            v-for="item in proporcions_actuals_quarto(msg_quarto.num_quarto)"
            :key="item.id"
            :class="{'proporcions-dialog-error': item.percentatge>100}"
          >{{ item.percentatge }}% {{ textIngredient(item.ingredient) }} ( {{ item.quilos }}kg de {{ item.quilos_totals.toFixed(1) }}kg)  </div>
          <p v-for="(balaParcial, index) in arrayBalesDeQuarto(msg_quarto.num_quarto)" 
          :key="index"
          style="color: black !important; font-size: 15px !important; margin-left: 10px; text-align:center;"
          :class="{'bala-parcial-dialog': balaParcial.contador>1}">
            {{ balaParcial.contador === 1 ? '' : `1/${balaParcial.contador} -`}}  {{ balaParcial.quilos }}kg - {{ balaParcial.barcode }} - 
              {{ balaParcial.material_proveidor ? balaParcial.material_proveidor : textMateriaPrimeraProveidor(balaParcial.materia_primera)}} 
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError" autofocus>TORNAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey" dark @click="btnMoureCuarto(msg_quarto.id)" autofocus>MOURE QUARTO</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMoureQuarto" max-width="600">
        <v-card>
          <v-card-title class="justify-center" 
          style = "text-align: center"
          > Selecciona la mesclada/partida <br> a la que vols moure el quarto
          <v-col md="3">
          <v-checkbox v-model="mesclades_partides" label="Mesc/part"></v-checkbox>
                </v-col>
          </v-card-title>

          <v-card-text class="text-center">
          <div v-if="mesclades_partides"
          > 
          <v-autocomplete

                  v-model="partida_destino"
                  :items="partides"
                  label="Partida destí"
                  item-text="codi"
                  item-value="id"
                  no-data-text
                  outlined
                  clearable
                >
                <template slot="selection" slot-scope="data">{{ data.item.codi.replaceAll('.', '')  }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
            <template slot="item" slot-scope="data">{{ data.item.codi.replaceAll('.', '') }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
                </v-autocomplete>
          </div>
          <div v-else
          > 
          <v-autocomplete

                  v-model="partida_destino"
                  :items="mesclades"
                  label="Mesclada destí"
                  item-text="codi"
                  item-value="id"
                  no-data-text
                  outlined
                  clearable
                >
                <template slot="selection" slot-scope="data">{{ data.item.codi.replaceAll('.', '')  }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
            <template slot="item" slot-scope="data">{{ data.item.codi.replaceAll('.', '') }} - {{ data.item.producte_final_nom }}{{textObservacions(data.item.observacions)}}</template>
                </v-autocomplete>
          </div>

          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError" autofocus>TORNAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="btnMoureCuarto_confirm" autofocus>CONFIRMAR CANVI</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEscandallo" max-width="600" v-if="grupo!=4">
        <v-card>
          <v-card-title class="justify-center" 
          style = "text-align: center"
          > ESCANDALLO DE LA PARTIDA
          </v-card-title>
          <v-card-text class="text-center">
          
          <div>
            <b>Resulta:</b>
            <span>{{ parseFloat(cost_per_kg).toFixed(3) }} €</span>
          </div>
          <div>
            <b>Trinxat:</b>
            <!-- <span>{{ extractCostTrinat() }} €</span> -->
            <span>{{ of.cost_trinxat }} €</span>
          </div>
          <div>
            <b>Mermes 5%:</b>
            <span>{{ (cost_per_kg*0.05).toFixed(3) }} €</span>
          </div>
          <div>
            <b>Giro:</b>
            <span><v-text-field
                  v-model="of.cost_financier"
                  label="Cost financer (%)"
                  @click="vaciarFinancier"
                  required
                  outlined
                  suffix="%"
                ></v-text-field></span>
          </div>
          <div>
            <b>Ports:</b>
            <span><v-text-field
                  v-model="of.cost_transport"
                  label="Cost transport (€)"
                  @click="vaciarTransport"
                  required
                  outlined
                  suffix="€"
                ></v-text-field> </span>
          </div>
          <div>
            <b>Preu de venta:</b>
            <span><v-text-field
                  v-model="of.preu_venda"
                  label="Preu de venta (€)"
                  @click="vaciarPreu"
                  required
                  outlined
                  :rules="[v => v.includes(',')==false || 'Utilitza . no ,' ]" 
                  suffix="€"
                ></v-text-field></span>
          </div>
          <div style="font-size: 5mm;">
          <b>TOTAL:</b>
            <span style="font-weight: bold;">{{ sumaCostos() }} €</span>
          </div>
          <div style="font-size: 5mm;">
          <b>BENEFICIS:</b>
            <span style="font-weight: bold;">{{ calculoBeneficios() }} €</span>
          </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" dark @click="cerrarEscandallo" autofocus>TORNAR</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogResumBales" max-width="800" v-if="grupo!=4">
        <v-card>
          <v-card-title class="justify-center" 
          style = "text-align: center"
          > Llistat de BALES
          </v-card-title>
          <v-card-text class="text-center">
          <div 
            v-for="(item, index) in balas_llistat_resum"
            :key="index"
            style="font-size:20px; text-align: center; margin-bottom: 25px"
            class="justify-center" 
          >{{ textMateriaPrimera(item.materia_primera) }} - {{textProveidor(item.materia_primera)}} - {{item.barcode}} - {{item.preu_quilo}} €/kg
        
          </div>
          
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" dark @click="dialogResumBales=false" autofocus>TORNAR</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogResum" max-width="800" v-if="grupo!=4">
        <v-card>
          <v-card-title class="justify-center" 
          style = "text-align: center"
          > RESUM DE LA MESCLADA
          </v-card-title>
          <v-card-text class="text-center">
          <div 
            v-for="(item, index) in resum_mp.ids"
            :key="index"
            style="font-size:20px; text-align: center; margin-bottom: 25px"
            class="justify-center" 
          >{{ textMateriaPrimera(item) }} - {{textProveidor(item)}} -  {{ parseFloat(resum_mp.kgs[index])}} KG ({{ parseFloat(resum_mp.bales[index])}}b)  {{ textMateriaPrimeraEscandallo(item)}} 
          <img
          src="https://cdn.pixabay.com/photo/2016/10/10/01/49/plus-1727487_960_720.png"
          @click.stop="ListarBalas(resum_mp.lista[index])"
          alt
          />
          </div>
          
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogError" autofocus>TORNAR</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogError1" max-width="800px">
        <v-card>
          <v-card-title class="headline"> NO ES POT REDUIR MÉS EL NÚMERO DE QUARTOS </v-card-title>
          <v-card-text style="font-size:25px"> Número quartos actual: {{quartos_mesclat.length}} - Número de quartos demanat: {{of.quartos}} 
            <br><br> No s'ha pogut alcançar el número de quartos demanat <br><br> vuida {{quartos_mesclat.length-of.quartos}} quartos </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="dialogError1=false">OK</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogBala" persistent max-width="700">
              <v-card>
              <div id="panell-info" style="font-size: 20px" v-if="balaAssignar.id">
                <!-- Taula informativa -->
                <div id="taula-info_dialog">
                  <v-row>
                    <v-col sm="4">Barcode</v-col>
                    <v-col sm="6" offset-sm="2">{{ balaAssignar.barcode }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4">Material</v-col>
                    <v-col
                      sm="8"
                      offset-sm="0"
                      style="text-align:right"
                    >{{ textMateriaPrimera(balaAssignar.materia_primera) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4">Proveïdor</v-col>
                    <v-col
                      sm="8"
                      style="text-align:right"
                    >{{ textProveidor(balaAssignar.materia_primera) }}</v-col>
                  </v-row>
                  <v-row v-if="grupo!=4">
                    <v-col sm="4">Preu Quilo</v-col>
                    <v-col
                      sm="6"
                      offset-sm="2"
                    >{{ parseFloat(balaAssignar.preu_quilo).toFixed(3) }} €</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4">Quilos</v-col>
                    <v-col sm="6" offset-sm="2">{{ balaAssignar.quilos }} kg</v-col>
                  </v-row>
                </div>
              </div>
              <v-card-text>
              <div id="ingredients" v-if="of.plantilla">
                  <v-row v-if="of.plantilla">
                    <v-col sm="12">Assignar material com a:</v-col>
                  </v-row>
                  <v-row>
                    <v-radio-group v-model="balaAssignar.ingredient">
                      <v-radio
                        v-for="item in plantilla_carregada.percentatges"
                        :label="textIngredient(item.ingredient)"
                        :value="item.ingredient"
                        :key="item.id"
                      />
                    </v-radio-group>
                  </v-row>
                  <!-- <v-row v-else id="selector-ingredients">
                    <v-autocomplete
                      v-model="balaAssignar.ingredient"
                      :items="ingredientsOpcions"
                      label="Ingredient"
                      item-text="nom"
                      item-value="id"
                      no-data-text
                    ></v-autocomplete>
                  </v-row> -->
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" dark @click="tancarDialogError" left>Confirmar </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" dark @click="eliminarBala" rigth>Cancel·lar</v-btn>
              </v-card-actions>
              </v-card>
            </v-dialog>
    <!-- Configuració del Stepper -->
    <v-stepper v-model="pas">
      <v-stepper-items>
        <!-- Primera pàgina -->
        <v-stepper-content step="1">
          <!-- Mesclades -->
          <v-form id="pas-1" ref="form" class="mt-12">
            <h1>Edició de Mesclada</h1>
            <v-row>
              <v-col sm="12">
                <v-autocomplete
                  v-model="nou_prefix"
                  :items="prefixosOpcions"
                  label="Referencia"
                  item-text="nom"
                  item-value="id"
                  no-data-text
                  autofocus
                  outlined
                  :disabled="of.estat!='-'"
                >
                <template slot="selection" slot-scope="data">{{ (data.item.nom.replaceAll('.','')) }}</template>
            <template slot="item" slot-scope="data">{{(data.item.nom.replaceAll('.','')) }}</template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-autocomplete
                  v-model="of.estat"
                  :items="estatOpcions"
                  label="Estat"
                  item-text="nom"
                  item-value="id"
                  disabled
                  outlined
                  no-data-text
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-text-field
                  v-model="of.observacions"
                  label="Observacions"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-autocomplete
                  v-model="of.plantilla"
                  :items="plantillesOpcions"
                  label="Plantilla"
                  @change="carregarPlantilla()"
                  item-text="nom"
                  item-value="id"
                  :disabled="bales_carregades.length>=1 || of.estat!='-'"
                  no-data-text
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="9">
                <v-autocomplete
                  v-model="of.producte_final"
                  :items="materiesProducteFinalOpcions"
                  label="Producte Final"
                  item-value="id"
                  item-text="nom"
                  no-data-text="No s'ha pogut carregar cap producte final"
                  :disabled="of.estat!='-'"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col sm="1">
                <!-- Dialog/Modal per crear materia producte final -->
                <v-dialog v-model="dialogProducteFinal" persistent max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined block color="success" :disabled="of.estat!='-'" dark v-on="on" tabindex="-1">+</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Crear materia producte final</v-card-title>
                    <v-container>
                      <v-form
                        id="formMateriaProducteFinal"
                        ref="formMateriaProducteFinal"
                        class="mt-12"
                      >
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="materia_producte_final_nou.nom"
                              label="Nom"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field
                              v-model="materia_producte_final_nou.descripcio"
                              label="Descripció"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="12">
                            <v-text-field v-model="materia_producte_final_nou.cost_trinxat" label="Cost trinxat"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        text
                        tabindex="-1"
                        @click="dialogProducteFinal = false"
                      >Cancel·lar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="btnCrearProducteFinal"
                        tabindex="-1"
                      >Crear</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- ./Dialog/Modal per crear materia producte final -->
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-model="of.cost_trinxat"
                  label="Trinxat"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-model="of.preu_venda"
                  label="Preu venta"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-model="of.cost_financier"
                  label="Cost financer"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="grupo!=4">
              <v-col sm="12">
                <v-text-field
                  v-model="of.cost_transport"
                  label="Cost transport"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-text-field
                  v-model.number="of.quilos_deseados"
                  label="Quilos aprox"
                  type="number"
                  required
                  :disabled="of.estat!='-'"
                  :@change="recalcularProporcions()"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8">
                <v-text-field v-model="of.quartos" 
                label="Quartos" 
                type="number" 
                required
                outlined
                :disabled="of.estat!='-' && of.estat!='e' "
                ></v-text-field>
              </v-col>
              <!-- <v-col sm="1">
                <v-btn width="25%" block color="primary" dark @click="of.quartos= of.quartos+1" style="font-size: 25px" tabindex="-1">+</v-btn>
              </v-col>
              <v-col sm="1" style="margin-left:10px">
                <v-btn width="25%" block color="primary" dark @click="restarQuarto" style="font-size: 25px" tabindex="-1">-</v-btn>
              </v-col> -->
            </v-row>
          </v-form>
          <!-- ./Mesclades -->
          <div class="botonera">
            <v-btn color="error" @click="btnCancelar" tabindex="-1">Cancel·lar</v-btn>
            <v-btn color="primary" v-if="of.estat=='-'" @click="btnGuardar" tabindex="-1">Guardar i tornar</v-btn>

            <v-btn color="success" v-if="of.estat=='-'" @click="distribuirBales">Guardar i distribuir</v-btn>
            <v-btn color="success" v-else @click="guardar_y_pasar">Vore distribució</v-btn>
          </div>
        </v-stepper-content>
        <!-- ./Primera pàgina -->
        <!-- Segona pàgina -->
        <v-stepper-content step="2">
          <div class="botonera-crear">
            <!-- ./Primera pàgina  <v-btn v-if="of.estat=='-'" color="deep-purple" dark @click="btnEncolarMesclada" tabindex="-1">Acabar mesclada</v-btn>
            <v-btn v-if="of.estat=='c'" color="red" dark @click="btnDesencolarMesclada" tabindex="-1">Desencolar mesclada</v-btn> -->
            <v-btn color="success" @click="pas = 1" tabindex="-1">Propietats</v-btn>
            <v-btn v-if="of.estat=='e' || of.estat=='i'" dark tabindex="-1">{{of.codi}} {{of.producte_final_nom}} {{of.observacions}} </v-btn>
            <v-btn v-else dark tabindex="-1">{{of.producte_final_nom}} {{of.observacions}} </v-btn>
            <v-btn
              v-if="of.estat=='-'"
              color="primary"
              @click="editarMesclada"
            >Guardar i tornar</v-btn>
            
            <v-btn
              v-else
              color="primary"
              @click="btnCancelar"
            >Tornar</v-btn>
          </div>
          <div id="distribucio">
            <!-- Bloc 1: Quartos -->
            <div id="quartos" v-if="quartos_mesclat.length > 0">
              <div
                class="quarto"
                v-for="(i,index) in quartos_mesclat"
                :key="index"
                @click="clickQuarto(i)"
                :class="{'quarto-seleccionat': bala_esta_seleccionada(i), 
                'quarto-consumit' : quarto_esta_consumit(i),
                'quarto-execusio' : quarto_esta_execusio(i),
                'quarto-mesclat' : quarto_esta_mesclat(i)}"
              >
                <div class="header">
                  <h3> {{i.quarto}} - {{contarBalas(arrayBalesDeQuarto(i.quarto))}} {{ contarBalas(arrayBalesDeQuarto(i.quarto)) === 1 ? 'bala' : 'bales'}}</h3>
                  <v-btn
                    outlined
                    style="height: auto; padding: 0px !important"
                    
                    @click.stop="mostrarQuarto(i)"
                  >Info {{ i.quarto }}</v-btn>
                </div>
                <div class="bales-info">
                  <p v-for="(balaParcial, index) in arrayBalesDeQuarto(i.quarto)" :key="index"
                  :class="{'bala-parcial': balaParcial.contador>1}">
                    {{ balaParcial.contador === 1 ? '' : `1/${balaParcial.contador} -`}} 
                    {{ parseInt(balaParcial.quilos) }}kg - {{balaParcial.barcode }} - 
                    {{ textMateriaPrimera(balaParcial.materia_primera)}}
                    <span>
                      <img
                        v-if="of.estat=='-' || of.estat=='e' || of.estat=='i' && i.estat!=2 && i.estat!=4"
                        src="https://cdn.pixabay.com/photo/2016/03/31/18/31/cancel-1294426_960_720.png"
                        alt
                        @click.stop="btnEliminarBalaParcialDialog({id: balaParcial.bala, quarto:balaParcial.quarto, contador: balaParcial.contador})"
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <!-- ./Bloc 1: Quartos -->
            <!-- Bloc 2: Info -->
            <div id="info">
              <!-- Codi escanejat -->
              <div>
                <v-row>
                  <v-col sm="12" >
                    <v-text-field
                      v-model="barcodeACarregar"
                      label="Codi escanejat"
                      v-on:keyup.enter="carregaBala"
                      required
                      outlined
                      
                      ref="campBarcode"
                      autofocus
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- ./Codi escanejat -->
              <!-- Panell informatiu de bala -->
              <div id="panell-info" v-if="balaAssignar.id">
                <div class="botonera2">
                  <v-btn color="warning" style="margin-bottom: 10px" @click="eliminarBala" tabindex="-1">Cancel·lar</v-btn>
                  <v-btn
                    color="success"
                    @click="confirmarBala"
                    :disabled="desactivarBotonConfirmar()"
                    tabindex="-1"
                  >Confirmar</v-btn>
                </div>
                <!-- Taula informativa -->
                <div id="taula-info">
                  <v-row>
                    <v-col sm="12"><strong>Barcode</strong></v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">{{ balaAssignar.barcode }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12"><strong>Material</strong></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      sm="12"
                    >{{ textMateriaPrimera(balaAssignar.materia_primera) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12"><strong>Proveïdor</strong></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      sm="12"
                    >{{ textProveidor(balaAssignar.materia_primera) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12"><strong>Preu Quilo</strong></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      sm="12"
                    >{{ parseFloat(balaAssignar.preu_quilo).toFixed(3) }} €</v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12"><strong>Quilos</strong></v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">{{ balaAssignar.quilos }} kg</v-col>
                  </v-row>
                </div>
                <!-- ./Taula informativa -->
                <!-- Ingredient -->
                <div id="ingredients" v-if="of.plantilla">
                  <v-row v-if="of.plantilla">
                    <v-col sm="12">Assignar material com a:</v-col>
                  </v-row>
                  <v-row >
                    <v-radio-group v-model="balaAssignar.ingredient">
                      <v-radio
                        v-for="item in plantilla_carregada.percentatges"
                        :label="textIngredient(item.ingredient)"
                        :value="item.ingredient"
                        :key="item.id"
                      />
                    </v-radio-group>
                  </v-row>
                </div>
                <!-- ./Ingredient -->
                <!-- Quarts a enviar -->
                <div></div>
                <!-- ./Quarts a enviar -->
              </div>
              <!-- ./Panell informatiu de bala -->
              <!-- Card Pes -->
              <div id="cards-info" @click="obrirResum">
                <template>
                  <v-card class="mx-auto" id="pes" :class="comprovadorSumatoriKG">
                    <v-card-text>
                      <div>PES</div>
                      <div v-if="of.plantilla">
                        <b>Objectiu:</b>
                        <span>{{ of.quilos_deseados }} KG</span>
                      </div>
                      <div>
                        <b>Actual:</b>
                        <span>{{ calcular_kg_actual }} KG</span>
                      </div>
                    </v-card-text>
                  </v-card>
                  <!-- ./Card Pes  -->
                  <!-- Card Proporcions -->
                  <v-card class="mx-auto" id="proporcions" v-if="of.plantilla">
                    <v-card-text>
                      <div>Proporcions {{ proporcio_actual === 'g' ? ' globals' : `en quarto ${proporcio_actual}` }}</div>
                      <div v-if="of.plantilla">
                        <b>Objectiu:</b>
                        <b>Actuals:</b>
                      </div>
                      <div class="proporcions-columnes" v-if="of.plantilla">
                        <div>
                          <span
                            v-for="item in plantilla_carregada.percentatges"
                            :key="item.id"
                          >{{ item.percentatge }}% {{ textIngredient(item.ingredient) }}</span>
                        </div>
                        <div v-if="proporcio_actual === 'g'">
                          <span
                            v-for="item in proporcions_actuals"
                            :key="item.id"
                          >{{ item.percentatge }}% {{ textIngredient(item.ingredient) }}</span>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </div>
              <!-- ./Card Proporcions  -->
              <!-- Card Cost total -->
              <template>
                <v-card class="mx-auto" id="cost-total" @click="dialogEscandallo=true" v-if="grupo!=4">
                  <v-card-text>
<!--                     <div>
                      <b>Cost total:</b>
                      <span>{{ calcular_preu_actual.toFixed(2) }} €</span>
                    </div> -->
                    <div>
                      <b>Resulta:</b>
                      <span>{{ parseFloat(cost_per_kg).toFixed(3) }} €</span>
                    </div>
                    <div>
                      <b>Trinxat:</b>
                      <span>{{ extractCostTrinat() }} €</span>
                    </div>
                    <div>
                      <b>Mermes 5%:</b>
                      <span>{{ (cost_per_kg*0.05).toFixed(3) }} €</span>
                    </div>
                    <div>
                      <b>Giro:</b>
                      <span>{{ (of.preu_venda*parseFloat(of.cost_financier)/100).toFixed(3) }} €</span>
                    </div>
                    <div>
                      <b>Ports:</b>
                      <span>{{ parseFloat(of.cost_transport).toFixed(3)}} €</span>
                    </div>
                    <div>
                    <b>TOTAL:</b>
                      <span>{{ sumaCostos() }} €</span>
                    </div>
                  </v-card-text>
                </v-card>
              </template>
              <!-- ./Card Cost total  -->
              
            </div>
            <!-- Bloc 2: Info -->
          </div>
        </v-stepper-content>
        <!-- ./Segona pàgina -->
      </v-stepper-items>
    </v-stepper>
    <!-- ./Configuració del Stepper -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%" >
      <v-col  v-if="of.estat=='a' || of.estat=='e' || of.estat=='i'" class="text-center" cols="12" @click="redireccio('PartidaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
      <v-col v-else class="text-center" cols="12" @click="redireccio('MescladaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import { EventBus } from "@/event-bus.js";

export default {
  name: "mesclada-edit",
  data: () => ({
    dialogResumBales: false,
    balas_llistat_resum:[],
    cuarto_a_mover:"",
    mesclades_partides: false,
    partida_destino:"",
    of: {
      porcions_mesclat: [],
      codi: "",
      creat_en: "",
      estat: null,
      id: null,
      plantilla: null,
      prefix: null,
      prioritat: 1,
      producte_final: null,
      quartos: null,
      quilos_deseados: null,
      quilos_totals: null,
      cost_trinxat: null
    },
    info_balaEliminar:{},
    bales_repartides:[],
    ids_bales_repartides:[],
    boton_eliminarBalas: false,
    bala_assignada_id: 0,
    balesParcials_a_modificar:[],
    quartos_existents:[],
    dialogQuarto:false,
    dialogMoureQuarto:false,
    dialogEscandallo:false,
    dialogResum:false,
    quartos_mesclat_ordenats:[],
    msg_quarto:{num_quarto:"", bales:"", id:""},
    dialogBala: false,
    dialogError: false,
    dialogError1: false,
    msg_error:[],
    estatOpcions: [
      { id: "-", nom: "Incompleta" },
      { id: "c", nom: "En cua" },
      { id: "e", nom: "Execució" },
      { id: "i", nom: "Interrompuda" },
      { id: "a", nom: "Acabada" },
    ],
    prioritatOpcions: [
      { id: 1, nom: "Baixa" },
      { id: 2, nom: "Mitjana" },
      { id: 3, nom: "Alta" },
      { id: 4, nom: "Urgent" }
    ],
    balaAssignar: {
      barcode: null,
      ingredient: null,
      id: null,
      materia_primera: null,
      preu_quilo: null,
      quilos: null
    },
    barcodeACarregar: null,
    dialogEliminarBala:false,
    bales_a_crear: [],
    balesParcials_a_eliminar: [],
    bales_carregades: [],
    quartos_mesclat: [],
    porcions_mesclat: [],
    nou_prefix: "",
    dialogProducteFinal: false,
    proporcio_actual: "g",
    proporcions_actuals: [],
    resum_mp:{ids:[], kgs:[], bales:[], preu:[] , lista:[]},
    plantilla_carregada: {},
    pas: 2,
    quartos: [],
    materia_producte_final_nou: {
      nom: "",
      descripcio: ""
    }, 
    materia_producte_final_seleccionado: {
      nom: "",
      cost_trinxat: ""
    }
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      materiesProducteFinalOpcions: "getMateriesProducteFinal",
      prefixosOpcions: "getPrefixos",
      plantillesOpcions: "getPlantilles",
      proveidorOpcions: "getProveidors",
      grupo: "getGroup", 
      partides: "getPartidesSimples",
      mesclades: "getMesclades",

    }),

    // Comprovadors
    calcular_kg_actual() {
      return this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) || 0),
        0
      );
    },
    calcular_preu_actual() {
      return this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) * b.preu_quilo || 0),
        0
      );
    },
    cost_per_kg() {
      const cost_per_kg = (
        this.calcular_preu_actual / this.calcular_kg_actual
      ).toFixed(3);
      return !isNaN(cost_per_kg) ? cost_per_kg : 0;
    },
    comprovadorSumatoriKG() {
      // Si no hi ha plantilla, no hi ha objectiu i sempre és correcte
      if (!this.of.plantilla) return "comprovador-exacte";
      const objectiuKG = parseInt(this.of.quilos_deseados);
      const actualKG = this.bales_carregades.reduce(
        (a, b) => a + (parseInt(b.quilos) || 0),
        0
      );
      if (objectiuKG === actualKG) return "comprovador-exacte";
      return "comprovador-error";
    }
  },
  methods: {
    ...mapActions([
      "crearBalaParcial",
      "crearQuartosMesclat",
      "addBalaMesclada",
      "addBalaPartida",
      "editMesclada",
      "editPartida",
      "editPrefix",
      "eliminarBalaParcial",
      "modificarBalaParcial",
      "eliminarQuartosMesclat",
      "getBalaBarcode",
      "getBalesMesclada",
      "getQuartosMesclat",
      "getIngredients",
      "getMateriesPrimeres",
      "getMateriesProducteFinal",
      "getMesclada",
      "moureQuartoMesclat",
      "getMesclada_new",
      "getPartida_new",
      "getProveidors",
      "getPlantilla",
      "getPlantilles",
      "getPrefixos",
      "sendMateriaProducteFinal", 
      "eliminarBalaQuarto", 
      "eliminarBalaQuartoPartida", 
      "eliminarBalaMesclada", 
      "eliminarBalaPartida", 
      "encolarMesclada",
      "desencolarMesclada",
      "generarPegatinasRepartidas", 
      "imprimirPegatinasMesclada_new",
      "getPartidesSimples", 
      "getMesclades"
    ]),

    btnCrearProducteFinal() {
      this.sendMateriaProducteFinal(this.materia_producte_final_nou).then(
        data => {
          if (data?.status === 201) {
            this.materia_producte_final_nou = {
              nom: "",
              descripcio: ""
            };
            this.dialogProducteFinal = false;
            this.of.producte_final = data.data.id;
          }
        }
      );
    },
    mostrarQuarto(i){
      this.dialogQuarto = true;
      this.msg_quarto= {bales:this.arrayBalesDeQuarto(i.quarto), num_quarto: i.quarto, estat: i.estat, id: i.id};
    },
    desactivarBotonConfirmar(){
      if(this.quartos.length === 0){
        if(this.quartos_mesclat.length ==1) {return false}else {return true}
      }else{
        if(this.of.plantilla){
          if(this.balaAssignar.ingredient){return false}else{return true}
        }else{
          return false
        }
      }
    },
    Cuartos_i_porcions(){
      var quartos =[];
      var id_quartos=[];
      this.quartos_mesclat.forEach(el => id_quartos.push({id: el.id, quarto: el.quarto}));
      this.quartos_mesclat=[];
      this.porcions_mesclat.forEach(el => {
        if(!quartos.includes(el.quarto)){
          quartos.push(el.quarto);
          this.quartos_mesclat.push({num_quarto: el.quarto, porcions_mesclat: [el], id: id_quartos.find(num_quarto => num_quarto.quarto==el.quarto).id });
        } else {
          this.quartos_mesclat[quartos.indexOf(el.quarto)].porcions_mesclat.push(el);
        }
      })
    },
    obrirResum(){
      this.dialogResum =true;
      this.resumMesclada();
    },
    vaciarFinancier(){
      if(this.of.cost_financier==0){
        this.of.cost_financier=""
      }
    },
    vaciarTransport(){
      if(this.of.cost_transport==0){
        this.of.cost_transport=""
      }
    },
    vaciarPreu(){
      if(this.of.preu_venda==0){
        this.of.preu_venda=""
      }
    },
    
    textEstatQuarto(estat){
      if(estat==4){
        return "Consumit"
      } else if(estat==3){
        return "Execució"
      } else if (estat==2){
        return "Mesclat"
      } else if (estat == 1){
        return "En preparació"
      } else{
        return "En cua"
      }
    },
    editarMesclada() {
      
      // this.$router.push({
      //       name: "MescladaDetail",
      //       params: { id: this.of.id }
      //     });
      // this.of.porcions_mesclat = this.porcions_mesclat;
      //this.Cuartos_i_porcions();
      if(!this.of.plantilla) this.of.plantilla = null;
      let editMesclada = (({
        estat,
        id,
        plantilla,
        prefix,
        prioritat,
        observacions,
        producte_final, 
        quilos_deseados, 
        preu_venda, 
        cost_transport, 
        cost_financier
      }) => ({
        estat,
        id,
        plantilla,
        prefix,
        prioritat,
        observacions,
        producte_final,
        quilos_deseados, 
        preu_venda, 
        cost_transport, 
        cost_financier
      }))(this.of);
      this.editMesclada(editMesclada).then(response => {
        if (response === 200) {
      //     // Eliminar-Afegir bales a partir de l'ID
            if (this.nou_prefix != this.of.prefix)
            {this.editPrefix({ nou_prefix: this.nou_prefix, id: this.of.id }).then(responsePrefix =>
            {
              if(responsePrefix.ordre_fabricacio.prefix == this.nou_prefix)
              {this.$router.push({
                name: "MescladaDetail",
                params: { id: this.of.id }
              })}}
            );}else{
              this.$router.push({
                name: "MescladaDetail",
                params: { id: this.of.id }
              })
            }
      //     // Si editem una Mesclada satisfactòriament, eliminem les bales...
      //     this.balesParcials_a_eliminar.forEach(bala => {
      //       this.eliminarBalaParcial(bala.id); });
      //     this.balesParcials_a_modificar.forEach(bala=>
      //     { this.modificarBalaParcial({id: bala.id, quilos: bala.quilos})})

      //     // ...i afegim les noves
      //     this.bales_a_crear.forEach(bala => {
      //       bala.ordre_fabricacio = this.of.id;
      //       this.crearBalaParcial(bala);
      //     });

      //     // ...i canvíem el prefix, en cas de que siga diferent
      //     if (this.nou_prefix != this.of.prefix)
      //       this.editPrefix({ nou_prefix: this.nou_prefix, id: this.of.id });

      //     EventBus.$emit("Mesclada.Notificacio", {
      //       text: "Mesclada editada correctament",
      //       color: "success"
      //     });
          }
      //   } else
      //     EventBus.$emit("Mesclada.Notificacio", {
      //       text: "Error editant la mesclada",
      //       color: "error"
      //     });
      });
    },
    // Bales
    carregaBala() {
      if(this.barcodeACarregar==""){
        this.tancarDialogError();
        return;}
      if (
        this.bales_carregades.find(el => el.barcode === this.barcodeACarregar)
      ) {
        var bala = this.bales_carregades.filter(el=> el.barcode === this.barcodeACarregar);
        this.bala_assignada_id = bala;
        this.dialogError =true;
        this.boton_eliminarBalas = true; 
        this.msg_error =`La bala ${this.barcodeACarregar} ja està a aquesta mesclada`;
        this.barcodeACarregar ="";
        return;
      }

      this.getBalaBarcode(this.barcodeACarregar).then(bala => {
        if (bala.estat === 4) {
          this.dialogError =true; 
          this.msg_error =`La bala ${this.barcodeACarregar} està consumida`;
          this.barcodeACarregar ="";
          return;
        }
        if (bala.estat === 2 || bala.estat === 3 ) {
          this.dialogError =true; 
          this.msg_error =`Bala ${this.barcodeACarregar} en procés de ser consumida`;
          this.barcodeACarregar ="";
          return;
        }
        if (bala?.id) {
          if(bala.posicion_almacenamiento==""){
            this.dialogError =true; 
            this.msg_error =`Bala ${this.barcodeACarregar} -> Sense magatzem`;
            this.barcodeACarregar ="";
            return;
          }else{
            this.balaAssignar = bala;
            this.dialogBala = true;
            this.barcodeACarregar ="";
          }
        }else{
          this.barcodeACarregar ="";
        }
      });
    },
    confirmarBala() {
      this.bales_carregades.push(this.balaAssignar);
      if(this.quartos_mesclat.length ==1){
        this.quartos.push(1)
      }
      if (this.of.plantilla) {
        // Afegim bala a proporcions_actuals
        let ingredient = this.proporcions_actuals.find(
          el => el.ingredient === this.balaAssignar.ingredient
        );
        ingredient.bales.push({
          quilos: parseFloat(this.balaAssignar.quilos),
          bala_id: this.balaAssignar.id
        });
      }

      // Recalculem les proporcions sumant els quilos i treient el % relatiu a cada ingredient
      this.recalcularProporcions();
      // Calcular KG per quarto
      const parcialKG = parseFloat(
        parseFloat(this.balaAssignar.quilos) / this.quartos.length
      ).toFixed(1);
      
      // Per cada quarto, assignar kg, barcode i quarto => "porcions_mesclat": [{"bala": 104, "quilos": 10, "quarto": 2}]
      this.quartos.forEach(quarto => {
        const novaBala = {
          bala: this.balaAssignar.id,
          barcode: this.balaAssignar.barcode,
          quilos: parseInt(parcialKG),
          quarto: quarto,
          materia_primera: this.balaAssignar.materia_primera,
          material_proveidor: this.textMateriaPrimeraProveidor(
            this.balaAssignar.materia_primera
          ),
          ingredient: this.balaAssignar.ingredient
        };
        this.porcions_mesclat.push(novaBala);
      });
      
      if(this.quartos.length <= this.quartos_mesclat.length){
      var bala_a_añadir = {}; 
      bala_a_añadir={bala: this.balaAssignar.id, ingredient:this.balaAssignar.ingredient,
       quartos: []}
      var id_quartos =[];
      this.quartos_mesclat.forEach(el => id_quartos.push({id: el.id, num_quarto: el.quarto}));
      this.quartos.forEach(quarto =>{
        // const novaBalaCrear = {
        //   bala: this.balaAssignar.id,
        //   barcode: this.balaAssignar.barcode,
        //   quilos: parseInt(parcialKG),
        //   quarto: id_quartos.find(el => el.num_quarto == quarto).id,
        //   material_proveidor: this.textMateriaPrimeraProveidor(
        //     this.balaAssignar.materia_primera
        //   ),
        //   ingredient: this.balaAssignar.ingredient
        // };
        bala_a_añadir.quartos.push(id_quartos.find(el => el.num_quarto == quarto).id)
      });
      if(this.of.estat=="e" || this.of.estat=="i" || this.of.estat=="a" ){
        this.addBalaPartida({id:this.of.id ,bala:bala_a_añadir});
      }else{
        this.addBalaMesclada({id:this.of.id ,bala:bala_a_añadir});
      }
      }
    this.contadorBala();
    this.$nextTick(() => this.focusCodi());
    this.resetBales();
    },
    eliminarBala() {
      this.bales_carregades = this.bales_carregades.filter(
        el => el.id !== this.balaAssignar.id
      );
      this.dialogBala = false;
      this.resetBales();
    },
    restarQuarto(){
      if(this.of.quartos>0) this.of.quartos= this.of.quartos-1;
    },
    focusCodi(){
      this.$refs.campBarcode.focus();
      // this.$nextTick(() => this.focusCodi());
    },
    tancarDialogError(){
      this.cuarto_a_mover = '';
      this.dialogMoureQuarto = false;
      this.dialogError = false;
      this.dialogBala = false;
      this.dialogQuarto = false; 
      this.boton_eliminarBalas = false;
      this.dialogEscandallo = false;
      this.dialogResum = false;
      this.dialogResumBales = false;
      this.dialogEliminarBala = false;
      this.$nextTick(() => this.focusCodi());
    },
    btnMoureCuarto(id){
      this.cuarto_a_mover = id;
      this.dialogMoureQuarto = true;
      this.dialogError = false;
      this.dialogBala = false;
      this.dialogQuarto = false; 
      this.boton_eliminarBalas = false;
      this.dialogEscandallo = false;
      this.dialogResum = false;
      this.dialogEliminarBala = false;
    },
    btnMoureCuarto_confirm(){
      this.moureQuartoMesclat({id: this.cuarto_a_mover, post_data:{mezclada_destino_id: this.partida_destino}}).then(
        response=>{
          if(response){
            this.$router.go()
          }
        }
      )
    },
    cerrarEscandallo(){
      let editMesclada = (({
        id,
        plantilla,
        prefix,
        prioritat,
        observacions,
        producte_final, 
        quilos_deseados, 
        preu_venda, 
        cost_transport, 
        cost_financier
      }) => ({
        id,
        plantilla,
        prefix,
        prioritat,
        observacions,
        producte_final,
        quilos_deseados, 
        preu_venda, 
        cost_transport, 
        cost_financier
      }))(this.of);
      if(this.of.estat=="e" || this.of.estat=="i" || this.of.estat=="a" ){
        this.editPartida(editMesclada);
      }else{
        this.editMesclada(editMesclada);
      }
      this.tancarDialogError()
    },
    btnEliminarBalesParcials() {
      this.eliminarBalaMesclada({mesclada: this.of.id, info:{bala: this.bala_assignada_id[0].id}})
      this.bales_carregades = this.bales_carregades.filter(el => el.id != this.bala_assignada_id[0].id);
      // let bales_a_eliminar = this.porcions_mesclat.filter(el => el.bala === this.bala_assignada_id[0].id);
      // this.balesParcials_a_eliminar = this.balesParcials_a_eliminar.concat(
      //   bales_a_eliminar
      // );
      this.porcions_mesclat = this.porcions_mesclat.filter(el => el.bala != this.bala_assignada_id[0].id);
      this.proporcions_actuals.forEach(el => {
        el.bales = el.bales.filter(bala => bala.bala_id != this.bala_assignada_id[0].id);
      });
      this.recalcularProporcions();
      this.contadorBala;
      this.barcodeACarregar = "";
      this.bala_assignada_id = "";
      this.tancarDialogError();
      
    },
    btnEliminarBalaParcialDialog(info){
      this.dialogEliminarBala=true;
      this.info_balaEliminar = info; 
    },
    btnEliminarBalaParcial(info) {
      var id_quartos=[];
      this.quartos_mesclat.forEach(el => id_quartos.push({id: el.id, quarto: el.quarto}));
      this.noves_porcions=[];
      if(info.contador>1){
        if(this.of.estat=="e" || this.of.estat=="i" || this.of.estat=="a" ){
              this.eliminarBalaQuartoPartida({mesclada:this.of.id, info:{bala:info.id,
            cuarto: id_quartos.find(num_quarto => num_quarto.quarto==info.quarto).id}}).then(
            mesclada=> {
            this.quartos_mesclat = mesclada.quartos_mesclat;
            this.$router.go();
            this.tancarDialogError();
            })
        }else{
          this.eliminarBalaQuarto({mesclada:this.of.id, info:{bala:info.id,
          cuarto: id_quartos.find(num_quarto => num_quarto.quarto==info.quarto).id}}).then(
            mesclada=> {
            this.quartos_mesclat = mesclada.quartos_mesclat;
            this.$router.go();
            this.tancarDialogError();
            })
        }
        
       }else {
         if(this.of.estat=="e" || this.of.estat=="i" || this.of.estat=="a" ){
              this.eliminarBalaPartida({mesclada: this.of.id, info:{bala:info.id}}).then(
              mesclada=>{if(mesclada) this.tancarDialogError()}
            )
        }else{
          this.eliminarBalaMesclada({mesclada: this.of.id, info:{bala:info.id}}).then(
           mesclada=>{if(mesclada) this.tancarDialogError()}
         )
        }
         
       }

      this.porcions_mesclat.forEach(el=>{
        if(el.bala!=info.id){this.noves_porcions.push(el)}
        else{
          if(el.quarto != info.quarto) {this.noves_porcions.push(el);}
        }})
      this.porcions_mesclat = this.noves_porcions;

      var quartos=[];
      var contador =0;
      this.porcions_mesclat.forEach(el => {
        if(el.bala == info.id) {
          quartos.push(el.quarto);
          contador = contador +1;
          }
      })
      if(contador == 0) {
        this.bales_carregades = this.bales_carregades.filter(el => el.id != info.id);
         } 
      else{
      this.porcions_mesclat.filter(el => el.bala == info.id).forEach(el => {
        el.quilos = parseInt(parseFloat(this.bales_carregades.filter(el => el.id == info.id)[0].quilos)/parseFloat(contador));
        el.contador = contador; })
        this.balesParcials_a_modificar = this.porcions_mesclat.filter(el => el.bala == info.id);
        }

      this.proporcions_actuals.forEach(el => {
        el.bales = el.bales.filter(bala => bala.bala_id != info.id);
      });
      this.recalcularProporcions();
      // this.contadorBala;
      this.barcodeACarregar ="";
      this.$nextTick(() => this.focusCodi());

    },
    resetBales() {
      this.balaAssignar = {
        id: null,
        barcode: null,
        materia_primera: null,
        preu_quilo: null,
        quilos: null,
        observacions: "",
        ingredient: null
      };
      this.quartos = [];
    },
    // Cambio ventana
    guardar_y_pasar(){
      this.pas=2;
      let editMesclada = (({
        id,
        plantilla,
        prefix,
        prioritat,
        observacions,
        producte_final, 
        quilos_deseados, 
        preu_venda, 
        cost_transport, 
        cost_trinxat, 
        cost_financier
      }) => ({
        id,
        plantilla,
        prefix,
        prioritat,
        observacions,
        producte_final,
        quilos_deseados, 
        preu_venda, 
        cost_transport,
        cost_trinxat,  
        cost_financier
      }))(this.of);

      if(this.of.estat=="e" || this.of.estat=="i" || this.of.estat=="a" ){
        this.editPartida(editMesclada);
      }else{
        this.editMesclada(editMesclada);
      }

      
    },
    distribuirBales(){
    this.quartos_existents = [];
    this.quartos_mesclat.forEach(quarto =>  this.quartos_existents.push(quarto.quarto));
    var cuartos_a_eliminar=[];
    this.quartos_mesclat.forEach(el => {
      if(this.of.quartos < (this.quartos_mesclat.length-cuartos_a_eliminar.length)){
        if (el.porcions_mesclat.length == 0){
          cuartos_a_eliminar.push(el.id)
        }
      }
    });
    if(this.of.quartos < (this.quartos_mesclat.length-cuartos_a_eliminar.length)) {
      this.dialogError1 =true; 
      return
    }else if(cuartos_a_eliminar.length>0){
      this.eliminarQuartosMesclat({mesclada: this.of.id, cuartos: cuartos_a_eliminar}).then(respone=>
          {this.quartos_mesclat = respone.quartos_mesclat;
          this.$router.go();}
          );
    }
    if(this.of.quartos > this.quartos_mesclat.length){
        this.crearQuartosMesclat({mesclada: this.of.id, 
          num_cuartos_a_crear:(this.of.quartos - this.quartos_mesclat.length)}).then(message =>{
            this.$router.go();
        this.quartos_mesclat = message.quartos_mesclat})
      }

    let editMesclada = (({
        estat,
        id,
        plantilla,
        prefix,
        prioritat,
        producte_final, 
        quilos_deseados,
        observacions, 
        cost_trinxat
      }) => ({
        estat,
        id,
        plantilla,
        prefix,
        prioritat,
        producte_final,
        quilos_deseados,
        observacions, 
        cost_trinxat
      }))(this.of);

      this.editMesclada(editMesclada).then(response => {
        if (response === 200) {
            if (this.nou_prefix != this.of.prefix)
            this.editPrefix({ nou_prefix: this.nou_prefix, id: this.of.id })
          }
      });
    this.pas = 2;
    },
    // Plantilla
    carregarPlantilla() {
      if (this.of.plantilla) {
        this.getPlantilla(this.of.plantilla).then(plantilla => {
          this.plantilla_carregada = plantilla;
          this.proporcions_actuals = [];
          this.of.producte_final = plantilla.producte_final;
          plantilla.percentatges.forEach(el =>
            this.proporcions_actuals.push({
              ingredient: el.ingredient,
              percentatge: 0,
              quilos: 0,
              bales: []
            })
          );
        });
      }
    },

    // Auxiliar
    bala_esta_seleccionada(i) {
      if(this.of.estat == "-" || this.of.estat == "e" || this.of.estat == "i"  && i.estat==0) 
        return this.quartos.includes(i.quarto);
      return false
    },
    quarto_esta_consumit(i){
      return (this.of.estat != "-" && this.of.estat != "c" && i.estat==4) 
    },
    quarto_esta_mesclat(i){
      return (this.of.estat != "-" && this.of.estat != "c" && i.estat==2) 
    },
    quarto_esta_execusio(i){
      return (this.of.estat != "-" && this.of.estat != "c" && i.estat==3) 
    },
    btnCancelar() {
      if(this.of.estat!="a"){
      this.$router.push({
        name: "MescladaDetail",
        params: { id: this.of.id }
      });
      }
      else
      {
        this.$router.push({
        name: "PartidaDetail",
        params: { id: this.of.id }
      });
      }
    },
    btnGuardar () {
      this.quartos_existents = [];
      this.quartos_mesclat.forEach(quarto =>  this.quartos_existents.push(quarto.quarto));
      var cuartos_a_eliminar=[];
      this.quartos_mesclat.forEach(el => {
        if(this.of.quartos < (this.quartos_mesclat.length-cuartos_a_eliminar.length)){
          if (el.porcions_mesclat.length == 0){
            cuartos_a_eliminar.push(el.id)
          }
        }
      });
      if(this.of.quartos < (this.quartos_mesclat.length-cuartos_a_eliminar.length)) {
        this.dialogError1 =true; 
        return
      }else if(cuartos_a_eliminar.length>0){
        this.eliminarQuartosMesclat({mesclada: this.of.id, cuartos: cuartos_a_eliminar}).then(respone=>
            {this.quartos_mesclat = respone.quartos_mesclat
            }
            );
      }
      if(this.of.quartos > this.quartos_mesclat.length){
          this.crearQuartosMesclat({mesclada: this.of.id, 
            num_cuartos_a_crear:(this.of.quartos - this.quartos_mesclat.length)}).then(message =>{
            
          this.quartos_mesclat = message.quartos_mesclat})
        }
      this.editarMesclada()
    },
    btnEncolarMesclada(){
      this.bales_repartides=[];
      this.ids_bales_repartides=[];
      this.quartos_mesclat_ordenats.forEach(el=>
      {
        el.porcions_mesclat.forEach(porcio=>{
        if(porcio.contador>1 && !this.bales_repartides.find(bala=> bala.barcode == porcio.barcode))
          { porcio.en_quarto = [el.quarto];
            this.bales_repartides.push(porcio)
            this.ids_bales_repartides.push(porcio.bala)}
        else if (this.bales_repartides.find(bala=> bala.barcode == porcio.barcode)){
          this.bales_repartides.find(bala=> bala.barcode == porcio.barcode).en_quarto.push(el.quarto)
        }
        })
      })
      if(this.ids_bales_repartides.length >0)
      {
      /* this.generarPegatinasRepartidas(this.of.id).then(status=>
      {if (status === 200)
          this.dialogAcabar =false;
          window.open(
            "https://" +
              window.location.host +
              `/api/v1.0/imprimir_mezclada/${this.of.id}/`,
            "_blank"
          );}
      ) */
      this.imprimirPegatinasMesclada_new({id: this.mesclada.id, info_impresion:{destino:"FrontEnd", 
      request_id: localStorage.getItem('uuid')}})
      }

      this.encolarMesclada(this.of.id).then(
        response => {this.of.estat=response.estat;
        this.$router.push({
        name: "MescladaDetail",
        params: { id: this.of.id }
          });}
      )
    },
    btnDesencolarMesclada(){
      this.desencolarMesclada(this.of.id).then(
        response => {this.of.estat=response.estat}
      )
    },
    clickQuarto(quarto) {
      if(quarto.estat==0)
      {if (this.quartos.includes(quarto.quarto))
        this.quartos = this.quartos.filter(el => el != quarto.quarto);
      else this.quartos.push(quarto.quarto);}
    },
    arrayBalesDeQuarto(quarto) {
      //this.quartos_mesclat.forEach(el => this.porcions_mesclat.push(el.porcions_mesclat));
      // return this.porcions_mesclat.filter(el => el.quarto === quarto);
      var bales_quarto=this.porcions_mesclat.filter(el => el.quarto === quarto);
      var bales_quarto_ordenadas = [];
      if(bales_quarto != [] || bales_quarto!=undefined ){
        var contadores =[];
        bales_quarto.forEach(el=> {if(!contadores.includes(el.contador)) contadores.push(el.contador)})
        contadores = contadores.sort().reverse()
        contadores.forEach(cont => 
        bales_quarto.filter(el=> el.contador==cont).forEach(bala_filtrada=>
        bales_quarto_ordenadas.push(bala_filtrada)));
        // this.ver_contadores=bales_quarto_ordenadas;
        return bales_quarto_ordenadas
      }else{
        return bales_quarto
      }
    },
    contadorBala(){
      this.porcions_mesclat.forEach(balas=> 
        balas.contador=this.porcions_mesclat.filter(porcio=> porcio.bala==balas.bala).length)
    },
    resumMesclada(){
      //var mps = [];
      this.resum_mp.bales=[];
      this.resum_mp.ids=[];
      this.resum_mp.kgs=[];
      this.resum_mp.preu=[];
      this.resum_mp.lista=[];
      this.bales_carregades.forEach(bala=>{
        if(this.resum_mp.ids.find(el => el === bala.materia_primera)){
          var index = this.resum_mp.ids.findIndex(el => el === bala.materia_primera);
          this.resum_mp.bales[index] = parseFloat(this.resum_mp.bales[index])+1;
          this.resum_mp.lista[index].push(bala);
          this.resum_mp.kgs[index] = parseFloat(this.resum_mp.kgs[index])+parseFloat(bala.quilos);
          this.resum_mp.preu[index] = parseFloat(this.resum_mp.preu[index])+(parseFloat(bala.quilos)*parseFloat(this.materiesPrimeresOpcions.find(el => el.id === bala.materia_primera).preu_quilo));
        }else{
          this.resum_mp.ids.push(bala.materia_primera)
          this.resum_mp.bales.push(1)
          this.resum_mp.lista.push([bala])
          this.resum_mp.kgs.push(bala.quilos)
          this.resum_mp.preu.push(parseFloat(bala.quilos)*parseFloat(this.materiesPrimeresOpcions.find(el => el.id === bala.materia_primera).preu_quilo));

        }
      })
    },
    contarBalas(porcions){
      var total=0;
      porcions.forEach(el=> total = parseFloat(1/el.contador)+total)
      return total.toFixed(2)
    },
    contarQuilos(porcions){
      var total=0;
      porcions.forEach(el=> total = parseFloat(el.quilos)+total)
      return total.toFixed(2)
    },
    recalcularProporcions() {
      this.proporcions_actuals.forEach(el => {
        // Sumem tots els quilos de cada ingredient i el guardem en la clau 'quilos'
        if (el.bales.length > 1)
          el.quilos = el.bales.reduce((acc, obj) => acc + parseFloat(obj.quilos), 0);
        else if (el.bales.length === 1) el.quilos = parseFloat(el.bales[0].quilos);
        else el.quilos = 0;

        // Calculem el percentatge relatiu als quilos totals de la Mesclada
        el.percentatge = (
          (el.quilos * 100) /
          parseFloat(this.of.quilos_deseados)
        ).toFixed(2);
      });
    },
    proporcions_actuals_quarto(quarto) {
      let proporcio = [];
      if(this.of.plantilla){
      this.plantilla_carregada.percentatges?.map(el => {
        let sumQuilos = this.porcions_mesclat.filter(
          bala => bala.ingredient === el.ingredient && bala.quarto === quarto
        );
        const quartoQuilosTotals = this.porcions_mesclat
          .filter(bala => bala.quarto === quarto)
          .reduce((acc, obj) => acc + parseInt(obj.quilos), 0);
        sumQuilos = sumQuilos.reduce((acc, obj) => acc + parseInt(obj.quilos), 0);

        const percentatge = (
          (sumQuilos * 100) /
          parseFloat(quartoQuilosTotals)
        ).toFixed(2);

        proporcio.push({
          quilos: sumQuilos,
          quilos_totals: parseFloat(parseFloat(this.of.quilos_deseados)/parseFloat(this.of.quartos))*parseFloat(el.percentatge)/100,
          ingredient: el.ingredient,
          percentatge: isNaN(percentatge) ? 0 : percentatge
        });
      });}
      return proporcio;
    },
    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.of.id } });
    },
    textIngredient(ingredient) {
      return this.ingredientsOpcions.find(el => el.id === ingredient)?.nom;
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return `${materiaPrimera?.nom} ${materiaPrimera?.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''}`;
    },
    ListarBalas(item){
      this.balas_llistat_resum = item; 
      this.dialogResumBales=true;
    },
    textMateriaPrimeraEscandallo(mpID) {
      var preu_quilo;
      this.bales_carregades.forEach(
        el => {if(el.materia_primera === mpID){ preu_quilo=el.preu_quilo}}
      );
      return  " - "+ preu_quilo + "€/kg";
    },
    textProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return mpProveidor?.nom;
      return materiaPrimera?.nom;
    },
    sumaCostos(){
      if(this.materiesProducteFinalOpcions.find(el => el.id === this.of.producte_final))
      {return (parseFloat(this.of.cost_transport) + parseFloat(this.of.cost_trinxat) + parseFloat(this.cost_per_kg) + parseFloat((this.of.preu_venda*parseFloat(this.of.cost_financier)/100).toFixed(3)) + parseFloat((this.cost_per_kg*0.05).toFixed(3))).toFixed(3)}
    },
    calculoBeneficios(){
      if(this.of.preu_venda)
      {return  (parseFloat(this.of.preu_venda)-this.sumaCostos()).toFixed(3)}
    },
    extractCostTrinat(){
      this.materia_producte_final_seleccionado = this.materiesProducteFinalOpcions.find(el => el.id === this.of.producte_final)
      if(this.materiesProducteFinalOpcions.find(el => el.id === this.of.producte_final))
      {return parseFloat(this.of.cost_trinxat).toFixed(3)}
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    textMateriaPrimeraProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return  `${materiaPrimera?.nom} ${materiaPrimera?.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''} - ${mpProveidor?.nom}`;
      return `${materiaPrimera?.nom} ${materiaPrimera?.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''}`;
    }
  },
  created() {
    this.getIngredients();
    console.log(this.$route.params)
    if(this.$route.params.partida==1){
      this.getMesclada_new(this.$route.params.id).then(entrada => {
      // Guardem el prefix existent, ja que el canviarem amb un action
      this.nou_prefix = entrada.prefix;

      // Assignem les dades existents a OF
      var quartos = [];
      var porcions_mesclat =[];
      this.of = entrada;
      this.getQuartosMesclat(entrada.id).then(
        quartos_mesclada=>{
          this.of.quartos_mesclat = quartos_mesclada;
          this.of.quartos_mesclat.forEach(quarto => 
      {this.quartos_mesclat.push(quarto);
      quartos.push(quarto.quarto);
      quarto.porcions_mesclat.forEach(porcio=>porcions_mesclat.push(porcio))})
      this.of.quartos_mesclat.forEach(el => el.porcions_mesclat.forEach(porcio => {
      porcio.quarto = el.quarto
        this.porcions_mesclat.push(porcio)
      }));
      function comparar ( a, b ){ return a - b; }
      quartos = quartos.sort(comparar)
      this.quartos_mesclat_ordenats=[]
      quartos.forEach(elem=> this.quartos_mesclat_ordenats.push(this.quartos_mesclat.filter(el => el.quarto == elem)[0])
      )
      this.quartos_mesclat_ordenats.forEach(element=> 
        element.porcions_mesclat.forEach(balas=> balas.contador=porcions_mesclat.filter(porcio=> porcio.bala==balas.bala).length)
        )
      
      this.quartos_mesclat = this.quartos_mesclat_ordenats;
      if (entrada?.quartos_mesclat.length > 0)
        this.of.quartos = entrada.quartos_mesclat.length;
      else this.of.quartos = 0;
      this.of.quilos_deseados = entrada.quilos_deseados;
      // Assignem les bales existents a porcions_mesclat
      //this.porcions_mesclat = entrada.porcions_mesclat;
      // Assignem les bales existents a bales_carregades
      let bales_parcials = [];
      this.porcions_mesclat?.map(bala => {
        if (!bales_parcials.some(el => el.barcode === bala.barcode))
          bales_parcials.push(bala);
      });
      // A partir del llistat de bales parcials, carreguem totes les bales existents
      this.getBalesMesclada(this.$route.params.id).then(
        bales=>{
          if(bales.length>0) {this.bales_carregades = bales;}
        }
      )
        }
      )
      
      // Si existeix, agafem la plantilla i assignem a proporcions actuals els valors de bala_mesclat
      if (this.of.plantilla) {
        this.getPlantilla(this.of.plantilla)
          .then(plantilla => {
            this.plantilla_carregada = plantilla;
            this.proporcions_actuals = [];
            plantilla.percentatges.forEach(el =>
              this.proporcions_actuals.push({
                ingredient: el.ingredient,
                percentatge: 0,
                quilos: 0,
                bales: []
              })
            );
          })
          .then(() => {
            this.porcions_mesclat.forEach(bala =>
              this.proporcions_actuals
                .find(ingredient => ingredient.ingredient === bala.ingredient)
                .bales.push({ bala_id: bala.bala, quilos: bala.quilos })
            );
          });
      }
    });}
    else{
      this.getPartida_new(this.$route.params.id).then(entrada => {
      // Guardem el prefix existent, ja que el canviarem amb un action
      this.nou_prefix = entrada.prefix;

      // Assignem les dades existents a OF
      var quartos = [];
      var porcions_mesclat =[];
      this.of = entrada;
      this.getQuartosMesclat(entrada.id).then(
        quartos_mesclada=>{
          this.of.quartos_mesclat = quartos_mesclada;
          this.of.quartos_mesclat.forEach(quarto => 
      {this.quartos_mesclat.push(quarto);
      quartos.push(quarto.quarto);
      quarto.porcions_mesclat.forEach(porcio=>porcions_mesclat.push(porcio))})
      this.of.quartos_mesclat.forEach(el => el.porcions_mesclat.forEach(porcio => {
      porcio.quarto = el.quarto
        this.porcions_mesclat.push(porcio)
      }));
      function comparar ( a, b ){ return a - b; }
      quartos = quartos.sort(comparar)
      this.quartos_mesclat_ordenats=[]
      quartos.forEach(elem=> this.quartos_mesclat_ordenats.push(this.quartos_mesclat.filter(el => el.quarto == elem)[0])
      )
      this.quartos_mesclat_ordenats.forEach(element=> 
        element.porcions_mesclat.forEach(balas=> balas.contador=porcions_mesclat.filter(porcio=> porcio.bala==balas.bala).length)
        )
      
      this.quartos_mesclat = this.quartos_mesclat_ordenats;
      if (entrada?.quartos_mesclat.length > 0)
        this.of.quartos = entrada.quartos_mesclat.length;
      else this.of.quartos = 0;
      this.of.quilos_deseados = entrada.quilos_deseados;
      // Assignem les bales existents a porcions_mesclat
      //this.porcions_mesclat = entrada.porcions_mesclat;
      // Assignem les bales existents a bales_carregades
      let bales_parcials = [];
      this.porcions_mesclat?.map(bala => {
        if (!bales_parcials.some(el => el.barcode === bala.barcode))
          bales_parcials.push(bala);
      });
      // A partir del llistat de bales parcials, carreguem totes les bales existents
      this.getBalesMesclada(this.$route.params.id).then(
        bales=>{
          if(bales.length>0) {this.bales_carregades = bales;}
        }
      )
      //bales_parcials.forEach(bala =>
      //  this.getBalaBarcode(bala.barcode).then(bala =>
      //    this.bales_carregades.push(bala)
      //  )
      //);
        }
      )
      
      // Si existeix, agafem la plantilla i assignem a proporcions actuals els valors de bala_mesclat
      if (this.of.plantilla) {
        this.getPlantilla(this.of.plantilla)
          .then(plantilla => {
            this.plantilla_carregada = plantilla;
            this.proporcions_actuals = [];
            plantilla.percentatges.forEach(el =>
              this.proporcions_actuals.push({
                ingredient: el.ingredient,
                percentatge: 0,
                quilos: 0,
                bales: []
              })
            );
          })
          .then(() => {
            this.porcions_mesclat.forEach(bala =>
              this.proporcions_actuals
                .find(ingredient => ingredient.ingredient === bala.ingredient)
                .bales.push({ bala_id: bala.bala, quilos: bala.quilos })
            );
          });
      }
    });
    }
    this.getMateriesPrimeres();
    this.getMateriesProducteFinal();
    this.getProveidors();
    this.getPrefixos();
    this.getPlantilles();
    this.getPartidesSimples();
    this.getMesclades();
  },
  updated() {
    this.recalcularProporcions();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

.container {
  max-width: 100% !important;
  padding-bottom: 50px;
}
/* Creació */

/* Distribució */
#distribucio {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    height: 100%;
  }

  #quartos {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 50%);

    .quarto {
      width: 95%;
      height: 200px;
      margin: 0.5em 0em;
      background: rgb(187, 187, 187);
      border-radius: 10px;
      color: white;
      overflow: hidden;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 2.1vw;
      }

      .bales-info {
        text-align: center;
        overflow-y: scroll;
        height: 90%;
        font-size: 2.1vw;
        padding-bottom: 1em;
        p{
          margin-bottom: 5px;
        }
        .bala-parcial{
          margin-bottom: 5px;
          background: burlywood;
        }
      }

      h3 {
        margin-left: 1em;
      }
      button{
        font-size: 1.4vw;
      }
    }
    .quarto-seleccionat {
      background: gold;
    }
    .quarto-consumit {
      .header{
      background: rgb(157, 42, 31);
      button{
        color: white;
      }
      }
    }
    .quarto-execusio {
      .header{
      background: rgb(78, 130, 88);
      button{
        color: white;
      }
      }
    }
    .quarto-mesclat {
      .header{
      background: rgb(133, 118, 69);
      }
    }
  }

  #info {
    width: 40%;
    margin-bottom: 0px;
  }
}

// Botonera
.botonera {
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
    font-size: 1.8vw;
  }
}
.botonera-crear {
  // padding-top: 50px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
    font-size: 1.8vw;
  }
}

// Panell info
#panell-info {
  // max-width: 80%;
  margin: auto;
  background: rgb(223, 217, 217);
  #taula-info_dialog {
    max-width: 90%;
    margin: auto;
    // background: rgb(255, 249, 122);

    .row {
      height: 4vh;
      .col-sm-4 {
        font-weight: bold;
        text-align: left;
        padding: 0 12px;
      }
      .col-sm-8 {
        text-align: left;
        padding: 0 12px;
      }
      .col-sm-6 {
        text-align: right;
        padding: 0 2px;
      }
    }
  }
  #taula-info {
    // max-width: 90%;
    margin: auto;
    // background: rgb(255, 249, 122);

    .row {
      height: 3vh;
      .col-sm-4 {
        font-weight: bold;
        text-align: left;
        padding: 0 12px;
      }
      .col-sm-10 {
        // font-weight: bold;
        text-align: center;
        padding: 0 12px;
      }
      .col-sm-12 {
        // font-weight: bold;
        text-align: center;
        padding: 0 12px;
      }
      .col-sm-8 {
        text-align: left;
        padding: 0 12px;
      }
      .col-sm-6 {
        text-align: right;
        padding: 0 2px;
      }
    }
  }
  #ingredients {
    background: white;
    max-width: 90%;
    margin: auto;
    .v-radio {
      padding-left: 16px;
    }

    .row {
      .col-sm-12 {
        padding: 6px 12px;
      }
    }
  }
}
.v-stepper__content {
    padding: 20px 2px 1px 2px;
}
#proporcions-dialog{
  outline: 2px dashed black;
  background-color: blanchedalmond;
  border-color: black;
  font-size: 2.5vh; 
  color: black;
  margin-bottom: 1.5vh;
  text-align: center;
  justify-content: center;
}
// .v-card{
  
// }

.botonera2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1vh;
}
// Targetes
.bala-parcial-dialog{
  color: black !important;
  font-size: 20px !important; 
  margin-left: 10px; 
  text-align:center;
  background-color: rgb(252, 243, 177) !important
}
.proporcions-dialog-error{
  outline: 2px dashed black;
  background-color: rgb(249, 117, 104)!important;
  border-color: black;
  font-size: 2.5vh; 
  color: black;
  margin-bottom: 1.5vh;
  text-align: center;
  justify-content: center;
}

.v-card {
  color: black !important;
}


.v-card__text {
  margin: 0.2em 0em;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
#cards-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;

  .comprovador-error {
    background: rgba(255, 103, 103, 1);
  }
  .comprovador-exacte {
    background: rgba(129, 237, 129, 1);
  }

  #pes {
    width: 100%;
    margin-bottom: 1%;
  }
  #proporcions {
    width: 100%;
    background: rgba(129, 237, 129, 1);

    .proporcions-columnes {
      width: 100%;
      display: flex;
      flex-direction: row;

      div {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

#cost-total {
  background: rgb(157, 157, 230);
}
// Stepper
.container {
  padding-top: 2.2em;
}
.row{
    padding-bottom: 0px;
    height: auto;
  }
#pas-1{
  margin-top: 0px !important;
  .h1{
    padding-bottom: 0px !important;
  }
  .row{
    padding-bottom: 0px;
    height: 5%;
  }
}
#pas-1 h1{
  padding-bottom: 0px !important;
}
#pas-1 .row{
  padding-bottom: 0px;
  height: 75px;
}

// Imatges
img {
  width: 20px;
  height: 20px;
}

// Selector d'ingredients sense plantilla
#selector-ingredients {
  padding: 0 3em;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}
</style>