<template>
  <v-container>
    <v-form ref="form" class="mt-12 pt-12">
      <h1>Login de l'usuari</h1>

      <v-text-field v-model="username" id="username" label="Usuario" autocomplete="on" required
      outlined rounded></v-text-field>

      <v-text-field v-model="password" id="password" label="Password" outlined rounded type="password" autocomplete="on" v-on:keyup.enter="loginFormSend" required></v-text-field>

      <v-btn block color="success" @click="loginFormSend">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    username: "",
    password: ""
  }),
  methods: {
    ...mapActions(["login"]),

    loginFormSend() {
      this.login({ username: this.username, password: this.password });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}
button {
  padding-top: 1em;
  width: 46%;
}
</style>