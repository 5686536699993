<template>
  <v-container>
    <!-- Form -->
    <v-form ref="form" class="mt-12">
      <!-- Reserva -->
      <h1>Edició de Reserva</h1>
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="reserva.client"
            :items="clientsOpcions"
            label="Client"
            item-text="nom"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap client"
            autofocus
            outlined
            :disabled="reserva.partida!=partida_inicial"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1">
          <!-- Dialog/Modal per crear client -->
          <v-dialog v-model="dialogClient" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear client</v-card-title>
              <v-container>
                <v-form id="formClient" ref="formClient" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="client_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.emails" label="Correu"></v-text-field>
                      </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogClient = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearClient"
                  tabindex="-1"
                  :disabled="!client_nou.nom || !client_nou.poblacio"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear magatzem -->
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-autocomplete
            v-model="reserva.partida"
            :items="partidesOpcions"
            :disabled="reserva.client!=client_inicial"
            label="Partida"
            item-text="codi"
            item-value="id"
            no-data-text="No s'ha pogut carregar cap partida"
            outlined
            @change="cambioPartida"
          >
          <template slot="item" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}}- {{ textProducteFinal(data.item.producte_final) }}{{textObservacions(data.item.observacions)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}}- {{ textProducteFinal(data.item.producte_final) }}{{textObservacions(data.item.observacions)}}
              </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="estado.estado_reservas">
        <v-col sm="6">
          <v-text-field v-model="reserva.quilos" 
          :rules="[v => 
          v <= (estado.estado_reservas.quilos_disponibles + quilos_iniciales) || 
          'Superats kg disponibles ('+(estado.estado_reservas.quilos_disponibles + quilos_iniciales)+ 'kg)']" 
          label="Quilos"
          outlined 
          :disabled="reserva.client!=client_inicial || reserva.partida!=partida_inicial"
          required></v-text-field>
        </v-col>
        <v-col sm="6">
          <v-text-field v-model="estado.quilos_disponibles_edicion" 
          label="Quilos disponibles"
          outlined
          rounded 
          readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col sm="12">
          <v-checkbox v-model="reserva.formalitzada" label="Formalitzada"></v-checkbox>
        </v-col>
      </v-row>
       -->
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="reserva.num_pedido" label="Nº pedido"
          outlined
          rounded 
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-text-field v-model="reserva.observacions" label="Observacions"
          outlined
          rounded 
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Reserva -->
      <div id="botonera">
        <v-btn color="error" @click="cancelarEdicio" tabindex="-1">Cancel·lar edició</v-btn>
        <v-btn
          color="success"
          @click="editarReserva"
          :disabled="!reserva.quilos || !reserva.client || !reserva.partida || (reserva.partida==partida_inicial && reserva.client==client_inicial && reserva.quilos == quilos_iniciales && reserva.observacions == coment_inicial && reserva.num_pedido == num_pedido_inicial)"
        >Guardar canvis</v-btn>
      </div>
    </v-form>
    <!-- ./Form -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('ReservaDetail')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "reserva-edit",
  data: () => ({
    reserves:[],
    //partidesOpcions:[],
    reserva: {
      quilos: "",
      formalitzada: false,
      observacions: "",
      num_pedido: "",
      client: null,
      partida: null
    },
    reserva_inicial:{},
    quilos_iniciales: 0,
    estado:[],
    dialogClient: false,
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    partida_inicial: null, 
    client_inicial: null,
    coment_inicial: null,
    num_pedido_inicial: null,
  }),
  computed: {
    ...mapGetters({
      clientsOpcions: "getClients",
      tipusBorra: "getMateriesProducteFinal",
      partidesOpcions: "getPartidesTodas",
    })
  },
  methods: {
    ...mapActions([
      "editReserva",
      "editReservaModificaOF",
      "getEstadoReserva",
      "getReservesFiltrades",
      "getClients",
      "getPartides",
      "getPartidesTodas",
      "getMateriesProducteFinal",
      "getPartidesFiltrades",
      "getReserva",
      "sendClient", 
      "incrementarReserva", 
      "reducirReserva"
    ]),

    editarReserva() {
      if (this.reserva.partida == this.partida_inicial && this.reserva.client == this.client_inicial) {
        if(this.quilos_iniciales< this.reserva.quilos){
          this.incrementarReserva({id: this.reserva.id, quilos: parseInt(this.reserva.quilos)-parseInt(this.quilos_iniciales)}).then(response=>
          {if(response.data){
            EventBus.$emit("Reserva.Notificacio", {
              text: "Reserva incrementada de quilos correctament",
              color: "success"
            });
             this.$router.push({
              name: "ReservaDetail",
              params: { id: this.reserva.id }
            });
          }})
        }else if(this.quilos_iniciales > this.reserva.quilos){
          this.reducirReserva({id: this.reserva.id, quilos: parseInt(this.quilos_iniciales)- parseInt(this.reserva.quilos) }).then(response=>{
            {if(response.data){
            EventBus.$emit("Reserva.Notificacio", {
              text: "Reserva reduïda de quilos correctament",
              color: "success"
            });
             this.$router.push({
              name: "ReservaDetail",
              params: { id: this.reserva.id }
            });
          }}
          })
        }else{
        
        this.editReserva(this.reserva).then(response => {
          this.estado = response
          
          if (response.status === 200) {
            EventBus.$emit("Reserva.Notificacio", {
              text: "Reserva editada correctament",
              color: "success"
            });
            this.$router.push({
              name: "ReservaDetail",
              params: { id: this.reserva.id }
            });
          } else
            EventBus.$emit("Reserva.Notificacio", {
              text: "Error editant la reserva",
              color: "error"
            });
        });
        }
      } else {
        this.editReserva(this.reserva).then(response => {
          if (response.status === 200) {
            EventBus.$emit("Reserva.Notificacio", {
              text: "Reserva editada correctament",
              color: "success"
            });
            this.$router.push({
              name: "ReservaDetail",
              params: { id: this.reserva.id }
            });
          } else
            EventBus.$emit("Reserva.Notificacio", {
              text: "Error editant la reserva",
              color: "error"
            });
        });
      }
    },

    // Crear element
    btnCrearClient() {
      this.sendClient(this.client_nou).then(data => {
        if (data.status === 201) {
          this.client_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogClient = false;
          this.reserva.client = data.data.id;
        }
      });
    },
    //Cambio partida
    cambioPartida(){

    },
    textProducteFinal(id_producte){
      return this.tipusBorra.find(el => el.id === id_producte)?.nom
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    // Auxiliar
    cancelarEdicio() {
      this.$router.push({
        name: "ReservaDetail",
        params: { id: this.$route.params.id }
      });
    },

    redireccio(component) {
      this.$router.push({ name: component, params: { id: this.reserva.id } });
    }
  },
  created() {
    //for (let i = 1; i<=3 ; i++)
    //{ const data = {pagina: i}
    //  this.getPartidesFiltrades(data).then(
    //  response => (response.result.forEach(el => this.partidesOpcions.push(el))))
    //}
    this.getMateriesProducteFinal();
    this.getPartidesTodas();
    this.getReserva(this.$route.params.id).then(reserva => {
      this.reserva = reserva;
      this.client_inicial= reserva.client;
      this.coment_inicial= reserva.observacions;
      this.num_pedido_inicial= reserva.num_pedido;
      this.getReservesFiltrades({pagina: 0, client: this.client_inicial}).then(response=> this.reserves = response.result)
      this.quilos_iniciales = parseInt(reserva.quilos)
      this.partida_inicial = reserva.partida;
      
      this.getEstadoReserva(reserva.partida).then(
        estado => {
        this.estado = estado;
        this.estado.quilos_disponibles_edicion = this.estado.estado_reservas.quilos_disponibles 
            + this.quilos_iniciales;
        //this.partidesOpcions.push({id: reserva.partida, codi: estado.estado_reservas.partida} )
            }
      )
    });
    
    this.getClients();
    //this.getPartides();
  },
  watch: {
    mescladesOpcions: function() {
      this.mescladesOpcions.push(
        this.mesclades.find(mesclada => mesclada.id === this.reserva.partida)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

#botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 46%;
  }
}
</style>