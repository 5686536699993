import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/auxiliares/Login.vue'

import BalaCreate from '../views/materies_primeres/bales/BalaCreate.vue'
import BalaSearch from '../views/materies_primeres/bales/BalaSearch.vue'
import BalaDetail from '../views/materies_primeres/bales/BalaDetail.vue'
import BalaEdit from '../views/materies_primeres/bales/BalaEdit.vue'
import BalaList from '../views/materies_primeres/bales/BalaList.vue'
import BalaPFList from '../views/partides/BalaPFList.vue'

import BalaAssignadaDetail from '../views/gestio_stock/bales_assignades/BalaAssignadaDetail.vue'
import BalaAssignadaMagatzemList from '../views/gestio_stock/bales_assignades/BalaAssignadaMagatzemList.vue'
import BalaAssignadaMagatzemsList from '../views/gestio_stock/bales_assignades/BalaAssignadaMagatzemsList.vue'

import ClientList from '../views/llistats/clients/ClientList.vue'
import ClientDetail from '../views/llistats/clients/ClientDetail.vue'

import EntradaCreate from '../views/materies_primeres/entrades/EntradaCreate.vue'
import EntradaEdit from '../views/materies_primeres/entrades/EntradaEdit.vue'
import EntradaDetail from '../views/materies_primeres/entrades/EntradaDetail.vue'
import EntradaList from '../views/materies_primeres/entrades/EntradaList.vue'
import EntradaBalaDetail from '../views/materies_primeres/entrades/EntradaBalaDetail.vue'

import IngredientDetail from '../views/llistats/ingredients/IngredientDetail.vue'
import IngredientList from '../views/llistats/ingredients/IngredientList.vue'

import FabricaDetail from '../views/llistats/fabriques/FabricaDetail.vue'
import FabricaList from '../views/llistats/fabriques/FabricaList.vue'

import MaquinaDetail from '../views/llistats/maquines/MaquinaDetail.vue'
import MaquinaList from '../views/llistats/maquines/MaquinaList.vue'
import ContadorList from '../views/llistats/maquines/ContadorList.vue'
import InformeList from '../views/llistats/maquines/InformeList.vue'  

import MateriaPrimeraDetail from '../views/llistats/materies_primeres/MateriaPrimeraDetail.vue'
import MateriaPrimeraList from '../views/llistats/materies_primeres/MateriaPrimeraList.vue'

import TipusMateriaPrimeraDetail from '../views/llistats/tipusMP/tipusMPDetail.vue'
import TipusMateriaPrimeraList from '../views/llistats/tipusMP/tipusMPList.vue'

import MagatzemList from '../views/llistats/magatzems/MagatzemList.vue'
import MagatzemDetail from '../views/llistats/magatzems/MagatzemDetail.vue'

import MescladaCreate from '../views/mesclada/mesclades/MescladaCreate.vue'
import MescladaDetail from '../views/mesclada/mesclades/MescladaDetail.vue'
import MescladaEdit from '../views/mesclada/mesclades/MescladaEdit.vue'
import MescladaList from '../views/mesclada/mesclades/MescladaList.vue'
import ProcesarMesclada from '../views/mesclada/mesclades/ProcesarMesclada.vue'
import ResetearMesclada from '../views/mesclada/mesclades/ResetearMesclada.vue'

import OrdreEixidaCreate from '../views/ordres_eixida/OrdreEixidaCreate.vue'
import OrdreEixidaCreateNOLL from '../views/ordres_eixida/OrdreEixidaCreateNOLL.vue'
import OrdreEixidaMPCreate from '../views/ordres_eixida/OrdreEixidaMPCreate.vue'
//import OrdreEixidaEdit from '../views/ordres_eixida/OrdreEixidaEdit.vue'
import OrdreEixidaEdit from '../views/ordres_eixida/OrdreEixidaEdit_new.vue'
import OrdreEixidaDetail from '../views/ordres_eixida/OrdreEixidaDetail.vue'
import OrdreEixidaList from '../views/ordres_eixida/OrdreEixidaList.vue'
import AlbaraOrdreEixidaList from '../views/ordres_eixida/AlbaraOrdreEixidaList.vue'
import AlbaraOrdreEixidaDetail  from '../views/ordres_eixida/AlbaraOrdreEixidaDetail.vue'
import AlbaraOrdreEixidaEdit  from '../views/ordres_eixida/AlbaraOrdreEixidaEdit.vue'

import PartidaDetail from '../views/partides/PartidaDetail.vue'
import PartidaList from '../views/partides/PartidaList.vue'
import PartidaBalaDetail from '../views/partides/PartidaBalaDetail.vue'
import PartidaBalaEdit from '../views/partides/PartidaBalaEdit.vue'
import AfegirBalesPartida from '../views/partides/AfegirBalesPartida.vue'

import PlantillaCreate from '../views/mesclada/plantilles/PlantillaCreate.vue'
import PlantillaDetail from '../views/mesclada/plantilles/PlantillaDetail.vue'
import PlantillaEdit from '../views/mesclada/plantilles/PlantillaEdit.vue'
import PlantillaList from '../views/mesclada/plantilles/PlantillaList.vue'

import ProveidorDetail from '../views/llistats/proveidors/ProveidorDetail.vue'
import ProveidorList from '../views/llistats/proveidors/ProveidorList.vue'

import ReservaCreate from '../views/reserves/ReservaCreate.vue'
import ReservaDetail from '../views/reserves/ReservaDetail.vue'
import ReservaEdit from '../views/reserves/ReservaEdit.vue'
import ReservaList from '../views/reserves/ReservaList.vue'

import StockMateriaPrimerBalaDetail from '../views/gestio_stock/stock_materies_primeres/StockMateriaPrimerBalaDetail.vue'
import StockMateriaPrimeraPerMagatzemList from '../views/gestio_stock/stock_materies_primeres/StockMateriaPrimeraPerMagatzemList.vue'
import StockMateriaPrimeraEnMagatzemList from '../views/gestio_stock/stock_materies_primeres/StockMateriaPrimeraEnMagatzemList.vue'
import StockMateriaPrimeraMagatzemMateriaPrimeraList from '../views/gestio_stock/stock_materies_primeres/StockMateriaPrimeraMagatzemMateriaPrimeraList.vue'

import StockBorraDetail from '../views/gestio_stock/stock_borra/StockBorraDetail.vue'
import StockBorraList from '../views/gestio_stock/stock_borra/StockBorraList.vue'
import StockBorraEnMagatzemsList from '../views/gestio_stock/stock_borra/StockBorraEnMagatzemsList.vue'
import StockBorraMagatzemBorraList from '../views/gestio_stock/stock_borra/StockBorraMagatzemBorraList.vue'

import TipusBorraDetail from '../views/llistats/tipus_borra/TipusBorraDetail.vue'
import TipusBorraList from '../views/llistats/tipus_borra/TipusBorraList.vue'

// Menú treballadors

import MenuTreballadors from '../views/vistes_treballadors/MenuTreballadors.vue'
import EmmagatzemarBalesPF from '../views/vistes_treballadors/EmmagatzemarBalesPF.vue'
import VistaInfoBales from '../views/vistes_treballadors/VistaInfoBales.vue'
import PuntOmplirQuartos from '../views/vistes_treballadors/PuntOmplirQuartos.vue'
import OmplirQuartoEnEspera from '../views/vistes_treballadors/OmplirQuartoEnEspera.vue'
import PuntPesatgeBales from '../views/vistes_treballadors/PuntPesatgeBales.vue'
import CarregaOrdreEixida from '../views/vistes_treballadors/CarregaOrdreEixida.vue'

// Vista corrección OE
import CorreccioOrdreEixida from '../views/vistes_treballadors/CorreccioOrdreEixida.vue'

Vue.use(VueRouter)

const routes = [
  // General
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login
  },

  // Bales
  {
    path: '/bala-buscar/',
    name: 'BalaSearch',
    component: BalaSearch
  },
  {
    path: '/bala-crear/',
    name: 'BalaCreate',
    component: BalaCreate
  },
  {
    path: '/bala-detall/:id/',
    name: 'BalaDetail',
    component: BalaDetail
  },
  {
    path: '/bala-edit/:id/',
    name: 'BalaEdit',
    component: BalaEdit
  },
  {
    path: '/bala-llistat/',
    name: 'BalaList',
    component: BalaList
  },
  {
    path: '/bales-PF-llistat/',
    name: 'BalaPFList',
    component: BalaPFList
  },

  // Bales assignades
  {
    path: '/bala-assignada-detail/:id/',
    name: 'BalaAssignadaDetail',
    component: BalaAssignadaDetail,
  },
  {
    path: '/bala-assignada-magatzem-llistat/:id/',
    name: 'BalaAssignadaMagatzemList',
    component: BalaAssignadaMagatzemList,
  },
  {
    path: '/bala-assignada-magatzems-llistat/',
    name: 'BalaAssignadaMagatzemsList',
    component: BalaAssignadaMagatzemsList,
  },

  // Borres 
  // TODO - No s'ha de fer com un llistat, si no com un element important més
  // {
  //   path: '/borra-llistat/',
  //   name: 'BorraList',
  //   component: BorraList
  // },

  // Clients
  {
    path: '/client-detail/:id/',
    name: 'ClientDetail',
    component: ClientDetail
  },
  {
    path: '/client-llistat/',
    name: 'ClientList',
    component: ClientList
  },

  // Entrades
  {
    path: '/entrada-crear/',
    name: 'EntradaCreate',
    component: EntradaCreate
  },
  {
    path: '/entrada-edit/:id/',
    name: 'EntradaEdit',
    component: EntradaEdit
  },
  {
    path: '/entrada-detall/:id/',
    name: 'EntradaDetail',
    component: EntradaDetail
  },
  {
    path: '/entrada-llistat/',
    name: 'EntradaList',
    component: EntradaList
  },
  {
    path: '/entrada-bala-detall/:entrada/:id/',
    name: 'EntradaBalaDetail',
    component: EntradaBalaDetail
  },

  // Fàbrica
  {
    path: '/fabrica-detall/:id/',
    name: 'FabricaDetail',
    component: FabricaDetail
  },
  {
    path: '/fabrica-llistat/',
    name: 'FabricaList',
    component: FabricaList
  },

  // Màquina
  {
    path: '/maquina-detall/:id/',
    name: 'MaquinaDetail',
    component: MaquinaDetail
  },
  {
    path: '/maquina-llistat/',
    name: 'MaquinaList',
    component: MaquinaList
  },
  {
    path: '/contador-llistat/',
    name: 'ContadorList',
    component: ContadorList
  },
  {
    path: '/informes-llistat/',
    name: 'InformeList',
    component: InformeList
  },
  // Ingredient
  {
    path: '/ingredient-detall/:id/',
    name: 'IngredientDetail',
    component: IngredientDetail
  },
  {
    path: '/ingredient-llistat/',
    name: 'IngredientList',
    component: IngredientList
  },

  // Magatzem
  {
    path: '/magatzem-detall/:id/',
    name: 'MagatzemDetail',
    component: MagatzemDetail
  },
  {
    path: '/magatzem-llistat/',
    name: 'MagatzemList',
    component: MagatzemList
  },
  // Materia primera
  {
    path: '/materia-primera-llistat/',
    name: 'MateriaPrimeraList',
    component: MateriaPrimeraList
  },
  {
    path: '/materia-primera-detail/:id',
    name: 'MateriaPrimeraDetail',
    component: MateriaPrimeraDetail
  },
  {
    path: '/tipus-materia-primera-llistat/',
    name: 'TipusMateriaPrimeraList',
    component: TipusMateriaPrimeraList
  },
  {
    path: '/tipus-materia-primera-detail/:id',
    name: 'TipusMateriaPrimeraDetail',
    component: TipusMateriaPrimeraDetail
  },
  // Mesclades
  {
    path: '/mesclades-crear/',
    name: 'MescladaCreate',
    component: MescladaCreate
  },
  {
    path: '/mesclada-detail/:id/',
    name: 'MescladaDetail',
    component: MescladaDetail
  },
  {
    path: '/mesclada-edit/:id/:partida',
    name: 'MescladaEdit',
    component: MescladaEdit
  },
  {
    path: '/mesclada-llistat/',
    name: 'MescladaList',
    component: MescladaList
  },
  {
    path: '/procesar-mesclada/',
    name: 'ProcesarMesclada',
    component: ProcesarMesclada
  },
  {
    path: '/resetear-mesclada/',
    name: 'ResetearMesclada',
    component: ResetearMesclada
  },
  // Ordres d'eixida
  {
    path: '/ordre-eixida-crear/',
    name: 'OrdreEixidaCreate',
    component: OrdreEixidaCreate
  },
  {
    path: '/ordre-eixida-crear-no-ll/',
    name: 'OrdreEixidaCreateNOLL',
    component: OrdreEixidaCreateNOLL
  },
  {
    path: '/ordre-mp-eixida-crear/',
    name: 'OrdreEixidaMPCreate',
    component: OrdreEixidaMPCreate
  },
  {
    path: '/ordre-eixida-detail/:id/',
    name: 'OrdreEixidaDetail',
    component: OrdreEixidaDetail
  },
  {
    path: '/ordre-eixida-edit/:id/',
    name: 'OrdreEixidaEdit',
    component: OrdreEixidaEdit
  },
  {
    path: '/ordre-eixida-llistat/',
    name: 'OrdreEixidaList',
    component: OrdreEixidaList
  },
  {
    path: '/albara-detail/:id',
    name: 'AlbaraOrdreEixidaDetail',
    component: AlbaraOrdreEixidaDetail
  },
  {
    path: '/albarans-llistat/',
    name: 'AlbaraOrdreEixidaList',
    component: AlbaraOrdreEixidaList
  },
  {
    path: '/albara-edit/:id',
    name: 'AlbaraOrdreEixidaEdit',
    component: AlbaraOrdreEixidaEdit
  },
   

  // Partides
  {
    path: '/partida-detail/:id/',
    name: 'PartidaDetail',
    component: PartidaDetail
  },
  {
    path: '/partida-bala-detail/:id/',
    name: 'PartidaBalaDetail',
    component: PartidaBalaDetail
  },
  {
    path: '/partida-llistat/',
    name: 'PartidaList',
    component: PartidaList
  },
  {
    path: '/bala-borra-edit/:id/',
    name: 'PartidaBalaEdit',
    component: PartidaBalaEdit
  },
  {
    path: '/afegir-bales-borra/:id/',
    name: 'AfegirBalesPartida',
    component: AfegirBalesPartida
  },

  // Plantilles
  {
    path: '/plantilla-crear/',
    name: 'PlantillaCreate',
    component: PlantillaCreate
  },
  {
    path: '/plantilla-detail/:id/',
    name: 'PlantillaDetail',
    component: PlantillaDetail
  },
  {
    path: '/plantilla-edit/:id/',
    name: 'PlantillaEdit',
    component: PlantillaEdit
  },
  {
    path: '/plantilla-llistat/',
    name: 'PlantillaList',
    component: PlantillaList
  },

  // Proveïdors
  {
    path: '/proveidor-detail/:id/',
    name: 'ProveidorDetail',
    component: ProveidorDetail,
  },
  {
    path: '/proveidor-llistat/',
    name: 'ProveidorList',
    component: ProveidorList,
  },
  // Reserves
  {
    path: '/reserva-crear/',
    name: 'ReservaCreate',
    component: ReservaCreate
  },
  {
    path: '/reserva-detail/:id/',
    name: 'ReservaDetail',
    component: ReservaDetail,
  },
  {
    path: '/reserva-edit/:id/',
    name: 'ReservaEdit',
    component: ReservaEdit,
  },
  {
    path: '/reserva-llistat/',
    name: 'ReservaList',
    component: ReservaList
  },

  // Stock Borra
  {
    path: '/stock-borra-detail/:id/',
    name: 'StockBorraDetail',
    component: StockBorraDetail,
  },
  {
    path: '/stock-borra-llistat/:magatzemID/:BorraID',
    name: 'StockBorraList',
    component: StockBorraList,
  },
  {
    path: '/stock-borra-llistat1/',
    name: 'StockBorraEnMagatzemsList',
    component: StockBorraEnMagatzemsList,
  },
  {
    path: '/stock-borra-llistat-per-borres/:magatzemID',
    name: 'StockBorraMagatzemBorraList',
    component: StockBorraMagatzemBorraList,
  },
  

  // Stock Materia Primera
  {
    path: '/stock-materia-primera-bala-detail/:id',
    name: 'StockMateriaPrimerBalaDetail',
    component: StockMateriaPrimerBalaDetail,
  },
  {
    path: '/stock-materia-primera-magatzem-mp-llistat/:magatzemID/:materiaPrimeraID',
    name: 'StockMateriaPrimeraMagatzemMateriaPrimeraList',
    component: StockMateriaPrimeraMagatzemMateriaPrimeraList,
  },
  {
    path: '/stock-materia-primera-magatzems-llistat/',
    name: 'StockMateriaPrimeraPerMagatzemList',
    component: StockMateriaPrimeraPerMagatzemList,
  },
  {
    path: '/stock-materia-primera-magatzem-llistat/:id/',
    name: 'StockMateriaPrimeraEnMagatzemList',
    component: StockMateriaPrimeraEnMagatzemList,
  },

  // Tipus de borra
  {
    path: '/tipus-borra-detail/:id/',
    name: 'TipusBorraDetail',
    component: TipusBorraDetail,
  },
  {
    path: '/tipus-borra-llistat/',
    name: 'TipusBorraList',
    component: TipusBorraList,
  },
  /* Vistes treballadors */
  // Menú treballadors
  {
    path: '/vista-menu/',
    name: 'MenuTreballadors',
    component: MenuTreballadors,
  },
  {
    path: '/emmagatzemar-bales-producte-final/',
    name: 'EmmagatzemarBalesPF',
    component: EmmagatzemarBalesPF,
  },
  {
    path: '/vista-info-bales/',
    name: 'VistaInfoBales',
    component: VistaInfoBales,
  },
  {
    path: '/punt-omplir-quartos/',
    name: 'PuntOmplirQuartos',
    component: PuntOmplirQuartos,
  },
  {
    path: '/omplir-quarto-en-espera/',
    name: 'OmplirQuartoEnEspera',
    component: OmplirQuartoEnEspera,
  },
  {
    path: '/punt-pesatge-bales/',
    name: 'PuntPesatgeBales',
    component: PuntPesatgeBales,
  },
  {
    path: '/carrega-ordre-eixida/',
    name: 'CarregaOrdreEixida',
    component: CarregaOrdreEixida,
  },
  {
    path: '/correccio-ordre-eixida/',
    name: 'CorreccioOrdreEixida',
    component: CorreccioOrdreEixida,
  },
  // En cas d'adreça no existent, retorna al root
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
