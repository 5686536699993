<template>
  <v-container>
    <!-- Ordre Eixida -->
    <v-form ref="form" class="mt-12" style="margin-top: 28px !important;">
      <h1>New Editar Ordre d'Eixida</h1>
      <!-- Client -->
      <v-row>
        <v-col sm="11">
          <v-autocomplete
            v-model="ordreEixida.client"
            :items="clientOpcions"
            outlined
            label="Client"
            item-text="nom"
            item-value="id"
            @change="carregaReserves"
            :disabled="disableEditarClient"
            no-data-text
          ></v-autocomplete>
        </v-col>
        <v-col v-if="grupo!=4" sm="1">
          <!-- Dialog/Modal per crear client -->
          <v-dialog v-model="dialogClient" persistent max-width="600">
            <template v-slot:activator="{ on }">
              <v-btn outlined block color="success" dark v-on="on" tabindex="-1">+</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Crear client</v-card-title>
              <v-container>
                <v-form id="formClient" ref="formClient" class="mt-12">
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.nom" label="Nom" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field v-model="client_nou.poblacio" label="Població" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-text-field
                        v-model="client_nou.id_facturaplus"
                        label="ID Facturaplus"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.emails" label="Correu"></v-text-field>
                      </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogClient = false"
                  tabindex="-1"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="btnCrearClient"
                  tabindex="-1"
                  :disabled="!client_nou.nom || !client_nou.poblacio"
                >Crear</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- ./Dialog/Modal per crear client -->
        </v-col>
      </v-row>
      <v-row v-if="ordreEixida.llacer">
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox" v-model="ordreEixida.imprimir_logo">
          <label for="checkbox">Imprimir LOGO</label>
        </v-col>
      </v-row>
       <v-row v-if="ordreEixida.llacer">
        <v-col sm="11" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox1" v-model="ordreEixida.hoja_extra">
          <label for="checkbox1">Imprimir HOJA EXTRA</label>
        </v-col>
      </v-row>
       <v-row v-if="ordreEixida.llacer">
        <v-col sm="11">
          <v-text-field
              v-model="ordreEixida.num_pedido"
              label="Nº pedido"
              required
              outlined
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="ordreEixida.llacer">
          <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            v-model="ordreEixida.comentari_general" 
            outlined
            label="Comentaris generals Albarà"></v-text-field>
          </v-col>
        </v-row>
      <v-row>
          <v-col sm="11" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            v-model="ordreEixida.comentaris" 
            outlined
            label="Comentaris Operari"></v-text-field>
          </v-col>
        </v-row>
      <v-row>
        <v-col v-if="tte_fuera_listado" sm="9" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field
              v-model="ordreEixida.transportista"
              label="Transportista"
              required
              outlined
            ></v-text-field>
        </v-col>
        <v-col v-else sm="9" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-autocomplete
            v-model="ordreEixida.transportista"
            :items="transOpcions_hardcode"
            label="Transportista"
            item-text="nom"
            item-value="nom"
            no-data-text
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col sm="2" style="padding-top: 0px; padding-bottom: 15px; ">
          <input type="checkbox" id="checkbox2" v-model="tte_fuera_listado">
          <label for="checkbox2">TTE fuera listado</label>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="11">
          <v-text-field
              v-model="ordreEixida.entregado_en"
              label="Entregado en"
              required
              outlined
            ></v-text-field>
        </v-col>
      </v-row>
      <!-- ./Client -->
      <!-- Afegir partida -->
      <v-row>
        <v-col md="2" offset-md="6">
          <v-btn 
          block 
          color="info" 
          @click="afegirLiniaOrdreEixida()" 
          tabindex="-1
          ">Afegir Borra</v-btn>
        </v-col>
        <v-col md="2" offset-md="1">
          <v-btn 
          block 
          color="info" 
          @click="afegirLiniaOrdreEixidaMP()" 
          tabindex="-1
          ">Afegir Drap</v-btn>
        </v-col>
      </v-row>
      <!-- ./Afegir partida -->
      <!-- Partida -->
      <div v-for="(linia, index) in liniesOrdreEixida" :key="index" class="producte">
        <v-row style="justify-content: space-around">
          <v-col sm="4">
            <strong>PRODUCTE #{{ index + 1}}</strong>
          </v-col>
          <v-col sm="2" offset-sm="4">
            <v-btn
              block
              color="warning"
              @click="eliminarLineaOrdreEixida(index)"
              tabindex="-1"
            >Eliminar</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="linia.tipo_balas==2 && ordreEixida.llacer">
          <v-col sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-autocomplete
              v-model="linia.reservas[0]"
              :items="reservaOpcions"
              label="Reserva"
              item-text="nom"
              item-value="id"
              no-data-text="No hi ha cap reserva d'aquest Client"
              clearable
              outlined
              @click:clear="quitarReserva(linia)"
              :disabled="!ordreEixida.client"
              :@change="reservaTriada(index,linia)" 
            >
            </v-autocomplete>
          </v-col>
          <v-col sm="5" v-if="linia.reservas[0]" style="padding-top: 0px; padding-bottom: 0px; ">
             <v-switch v-model="linia.completa" class="mx-2" label="Completa?"
             @change="cambio_tota_reserva(liniesOrdreEixida[index], index)"></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="linia.tipo_balas==2">
          <!-- <v-col  sm="12" style="padding-top: 0px;">
            <span> <strong> Partida: {{linia.partida}} </strong> </span>
          </v-col> -->
          <v-col sm="6">
          <span  v-if="linia.id!=''"> Si es vol canviar la partida, s'ha d'eliminar la línia i afegir una nova  <br>.</span>
          <v-autocomplete
              :disabled="!ordreEixida.client || linia.reservas[0] || linia.id!=''"
              v-model="linia.partida"
              :items="partidaOpcions"
              label="Partida"
              item-text="codi"
              item-value="id"
              no-data-text
              outlined
              clearable
              :@change="calcularQuilosDispo(index,linia)"
            >
            <template slot="item" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}}- {{ textProducteFinal(data.item.producte_final) }}{{textObservacions(data.item.observacions)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}}- {{ textProducteFinal(data.item.producte_final) }}{{textObservacions(data.item.observacions)}}
              </template>
            </v-autocomplete>
            
          </v-col>
          <v-col v-if="liniesOrdreEixida[index].partida && !liniesOrdreEixida[index].reservas[0] " sm="2" style="padding-top: 0px; padding-bottom: 0px; ">
              <v-switch
                  v-model="liniesOrdreEixida[index].partida_completa"
                  label="Toda"
                  inset
                  hide-details
                  @change="cambio_tota_partida(liniesOrdreEixida[index], index)"
                ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="ordreEixida.llacer" >
        <v-col v-if="linia.tipo_balas!=1 && !GRS_fuera_listado[index]" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">  
            <v-autocomplete
              v-model="linia.codigo_grs"
              label="GRS"
              item-value="text1"
              item-text="text2"
              required
              :items="GRSOpcions"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col v-if="linia.tipo_balas!=1 && GRS_fuera_listado[index]" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">  
            <v-text-field
              v-model="linia.codigo_grs"
              label="GRS"
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col sm="2" style="padding-top: 0px; padding-bottom: 0px; " v-if="linia.tipo_balas!=1">
          <input type="checkbox" id="checkbox3" v-model="GRS_fuera_listado[index]">
          <label for="checkbox3">GRS fuera de listado</label>
          </v-col>
          <v-col sm="2" style="padding-top: 0px; padding-bottom: 0px; " v-if="linia.tipo_balas!=1">
          <input type="checkbox" id="checkbox4" v-model="linia.imprimir_observaciones">
          <label for="checkbox4">Imprimir OBSERVACIONES</label>
          </v-col>
        </v-row> 
        <v-row v-if="linia.tipo_balas==1">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field
              v-model="linia.materia_primera"
              label="NOM PRODUCTE"
              @click="editarLineaOrdreEixida(index)"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row >
          <v-col v-if="linia.completa && linia.tipo_balas==2" sm="12" style="padding-top: 0px;">
            <span> Quilos aproximats: {{liniesOrdreEixida[index].quilos_aproximats}} kg</span>
          </v-col>
          <v-col v-if="!linia.completa && linia.tipo_balas==2" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              v-model="linia.quilos_aproximats"
              label="Quilos aproximats"
              type="number"
              @click="vaciarCampo(index,'q')"
              required
              outlined
              :rules="[v => v <=quilos_disponibles[index] 
              || 'Superats kg disponibles ('+quilos_disponibles[index]+ 'kg)']"
            ></v-text-field>
          </v-col>
          <v-col v-if="!linia.completa && linia.tipo_balas==2" sm="3" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              v-model="quilos_disponibles[index]"
              label="Quilos disponibles"
              type="number"
              required
              outlined
              disabled
            ></v-text-field>
          </v-col>
          <v-col sm="3" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="true"
              v-model="bales_disponibles[index]"
              label="Bales disponibles"
              type="number"
              outlined
              rounded
              required
            ></v-text-field>
          </v-col>
          <v-col v-if="!linia.completa && linia.reserva" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="true"
              v-model="linia.quilos_disponibles"
              label="Quilos pendents"
              type="number"
              outlined
              rounded
              required
            ></v-text-field>
          </v-col>
          <v-col v-if="linia.tipo_balas==1" sm="6" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              v-model="linia.quilos_aproximats"
              label="Quilos aproximats"
              type="number"
              @click="vaciarCampo(index,'q')"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field
              :disabled="!ordreEixida.client"
              v-model="linia.max_num_bales"
              @click="vaciarCampo(index,'m')"
              label="Màxim número de bales"
              type="number"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            :disabled="!ordreEixida.client"
            outlined
            v-model="linia.observacions" label="Observacions"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="ordreEixida.llacer">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field 
            :disabled="!ordreEixida.client"
            v-model="linia.comentaris" 
            outlined
            label="Comentaris"></v-text-field>
          </v-col>
        </v-row>
         <v-row v-if="ordreEixida.llacer">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field v-model="linia.ref_materia" 
            label="Ref Matèria" required
            :disabled="!ordreEixida.client"
            outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="ordreEixida.llacer">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
            <v-text-field v-model="linia.num_pedido" 
            label="Num Comanda" 
            outlined
            :disabled="!ordreEixida.client"
            required></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="ordreEixida.llacer">
          <v-col sm="12" style="padding-top: 0px; padding-bottom: 0px; ">
          <v-text-field v-model.number="linia.ref_ari" 
                  label="Referència Cliente"
                  :disabled="!ordreEixida.client"
                  outlined
                  required></v-text-field>
          </v-col>
        </v-row>  
      </div>
      <!-- ./Partida -->
    </v-form>
    <!-- ./Ordre Eixida -->
    <!-- Botonera -->
    <div class="botonera">
      <v-btn color="error" @click="btnCancelar" tabindex="-1">Cancel·lar</v-btn>
          <v-btn 

          color="info" 
          @click="afegirLiniaOrdreEixida()" 
          tabindex="-1
          ">Afegir Borra</v-btn>
          <v-btn 

          color="info" 
          @click="afegirLiniaOrdreEixidaMP()" 
          tabindex="-1
          ">Afegir Drap</v-btn>
      <v-btn
        color="success"
        @click="btnEditOrdreEixida"
        :disabled="!ordreEixida.client"
      >Guardar</v-btn>
    </div>
    <!-- ./Botonera -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="btnCancelar" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "ordre-eixida-create",
  data: () => ({
    client: null,
    liniesOrdreEixida: [
      {
        bales: [],
        bales_disponibles:"",
        codigo_grs: "",
        comentaris: "",
        id:"" ,
        imprimir_observaciones: "",
        materia_primera: null,
        max_num_bales: "",
        num_bales: "",
        num_pedido: "",
        observacions: "",
        ordre_eixida: "",
        partida: "",
        precio_venta: null,
        quilos: 0,
        quilos_aproximats: 0,
        quilos_aproximats_superats: false,
        ref_ari: "",
        ref_materia: "",
        reservas: [],
        tipo_balas: ""}
    ],
    estado:[],
    GRSOpcions:[
      {text1:"(98% R-CO 2%R-TR)", text2:"(98% - 2%)" },
      {text1:"(90% R-CO 10%R-TR)", text2:"(90% - 10%)" },
      {text1:"(80% R-CO 20%R-TR)", text2:"(80% - 20%)"},
      {text1:"(90% R-CO 10% R-VI)", text2:"(90% - 10% viscosa)"},
    ],
     transOpcions_hardcode:[
      {id:"1", nom:"TTES FCO SANCHIS"},
      {id:"2", nom:"TTES BERENGUER"}
    ],
    GRS_fuera_listado:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
    tte_fuera_listado: false,
    quilos_visibles:false,
    partidaOpcionsLinea:[],
    quilos_disponibles:[],
    bales_disponibles:[],
    reservaLinia:[],
    ordreEixida: {
      client: null,
      id: null,
      lineas: []
    },
    ordreEixidaPrev: [],
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    dialogClient: false,
    liniesOrdreEixidaAEliminar: [],
    liniesOrdresEixidaACrear: [],
    reservaOpcions: []
  }),
  computed: {
    ...mapGetters({
      clientOpcions: "getClients",
      magatzems: "getMagatzems",
      mesclades: "getMescladesReservades",
      tipusBorra: "getMateriesProducteFinal",
      partidaOpcions: "getPartidesTodasSinAcabar",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors"
    }),

    disableEditarClient() {
      return this.ordreEixida.lineas.some(el => el.reserva)
    }
  },
  methods: {
    ...mapActions([
      "editLiniaOrdreEixida",
      "editOrdreEixida",
      "eliminarLiniaOrdreEixida",
      "getBalaProducteFinalBarcode",
      "getOrdreEixida",
      "getClients",
      "getMagatzems",
      "getMescladesReservades",
      "getMateriesProducteFinal",
      "getMesclada",
      "getPartida",
      "getPartides",
      "getReservesPerClient",
      "sendClient",
      "sendLiniaOrdreEixida",
      "getEstadoReserva",
      "getMateriesPrimeres",
      "getPartidesTodas",
      "getPartidesTodasSinAcabar",
      "getPartidesTodasDispo",
      "getBorresFiltrades",
      "getReserva",
      "getProveidors"
    ]),

    // Crear element
    btnEditOrdreEixida() {
      this.ordreEixida.lineas.forEach(lin=>{
        if(lin.max_num_bales == ''){lin.max_num_bales =0}
        if(lin.quilos_aproximats == ''){lin.quilos_aproximats =0}
      })
      this.editOrdreEixida(this.ordreEixida).then(status => {
        // S'ha editat amb èxit
        if (status === 200) {
          this.ordreEixida.lineas.forEach((linia, index) => {
            if (linia?.id && linia.materia_primera == this.ordreEixidaPrev[index].materia_primera) {
              var linia_enviar = {
                id: linia.id, 
                codigo_grs: linia.codigo_grs,
                comentaris: linia.comentaris,
                imprimir_observaciones: linia.imprimir_observaciones,
                max_num_bales: linia.max_num_bales,
                num_bales: linia.num_bales,
                num_pedido: linia.num_pedido,
                observacions: linia.observacions,
                precio_venta: linia.precio_venta,
                quilos: linia.quilos,
                quilos_aproximats: linia.quilos_aproximats,
                ref_ari: linia.ref_ari,
                ref_materia: linia.ref_materia,
                reservas: linia.reservas
              }
              this.editLiniaOrdreEixida(linia_enviar).then(response=>
              {if(response){
              this.$router.push({
                name: "OrdreEixidaDetail",
                params: { id: this.ordreEixida.id }
              });
              }}
              );
            } else if (linia?.id && linia.materia_primera!=this.ordreEixidaPrev[index].materia_primera){
              var linia_crear = {
                id: linia.id, 
                codigo_grs: linia.codigo_grs,
                comentaris: linia.comentaris,
                imprimir_observaciones: linia.imprimir_observaciones,
                max_num_bales: linia.max_num_bales,
                materia_primera: linia.materia_primera,
                num_bales: linia.num_bales,
                num_pedido: linia.num_pedido,
                observacions: linia.observacions,
                precio_venta: linia.precio_venta,
                quilos: linia.quilos,
                quilos_aproximats: linia.quilos_aproximats,
                ref_ari: linia.ref_ari,
                ref_materia: linia.ref_materia,
                reservas: linia.reservas
              }
              this.eliminarLiniaOrdreEixida(linia.id)
              this.sendLiniaOrdreEixida(linia_crear).then(response=>
              {if(response){
              this.$router.push({
                name: "OrdreEixidaDetail",
                params: { id: this.ordreEixida.id }
              });
              }}
              );
            } else {
              this.sendLiniaOrdreEixida(linia).then(response=>
              {if(response){
              this.$router.push({
                name: "OrdreEixidaDetail",
                params: { id: this.ordreEixida.id }
              });
              }}
              );
            }
          });

          // Eliminar LOEs
          this.liniesOrdresEixidaACrear.forEach(linia => {
            if(this.ordreEixida.lineas[linia].id)
            {this.sendLiniaOrdreEixida(this.ordreEixida.lineas[linia]);}
          });
          this.liniesOrdreEixidaAEliminar.forEach(linia => {
            if(linia?.id)
            this.eliminarLiniaOrdreEixida(linia.id);
          });

          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `Editada Ordre de Fabricació amb èxit`,
            color: "success"
          });
        }
        // No s'ha editat amb èxit
        else {
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: `No s'ha pogut modificar aquesta notificació`,
            color: "warning"
          });
        }
      });
    },
    cambio_tota_partida(lin, index){
      if(lin.partida_completa == true){
        //lin.observacions = "TODA";
        lin.quilos_aproximats = this.quilos_disponibles[index];
        this.getBorresFiltrades({pagina:1, estats:["c","a","m"], partida:lin.partida}).then(
                  bales => {lin.observacions = "TODA (" + bales.count + "b)";
                  lin.max_num_bales=bales.count}
                );
      }
    },
    cambio_tota_reserva(lin, index){
      
        //lin.observacions = "";
        this.liniesOrdreEixida[index].quilos_aproximats = this.quilos_disponibles[index];
      
    },
    textProducteFinal(id_producte){
      return this.tipusBorra.find(el => el.id === id_producte)?.nom
    },
    textObservacions(obs){
      if(obs) return `${obs}`
    },
    vaciarCampo(index,tipo){
      if(tipo=='m'){
        if(this.ordreEixida.lineas[index].max_num_bales ==0){
          this.ordreEixida.lineas[index].max_num_bales = ''
        }
      }
      if(tipo=='q'){
        if(this.ordreEixida.lineas[index].quilos_aproximats ==0){
          this.ordreEixida.lineas[index].quilos_aproximats = ''
        }
      }
    },
    btnCrearClient() {
      this.sendClient(this.client_nou).then(data => {
        if (data.status === 201) {
          this.client_nou = {
            nom: "",
            poblacio: "",
            id_facturaplus: ""
          };
          this.dialogClient = false;
          this.ordreEixida.client = data.data.id;
        }
      });
    },

    // Auxiliar
    reservaTriada(index,linia){
      if(linia.reservas[0]) {
        linia.partida_reserva = this.reservaOpcions.find(el=> el.id== linia.reservas[0])?.nom.split(" ")[0];
        linia.partida = this.reservaOpcions.find(el=> el.id== linia.reservas[0])?.partida;
      this.getReserva(linia.reservas[0]).then(reserva=>
        {this.reservaLinia[index]= reserva;
        linia.num_pedido = reserva.num_pedido;
        if(reserva.observacions && this.comentari_general=="") {this.comentari_general = reserva.observacions;}
        linia.quilos_disponibles = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats);
        this.getBorresFiltrades({pagina:1, estats:["c","m"], partida:linia.partida}).then(
              bales => linia.bales_disponibles = bales.count
            );
        if(linia.completa) linia.quilos_aproximats = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
      )}
    },
    // Auxiliar
    actualizaPartidas(index, linia){
      if(linia.partida)
      {
        this.getEstadoReserva(linia.partida).then(response => {
        this.estado = response
        })
      }
      //var reserva_id = this.liniesOrdreEixida[index].reserva;
      if(linia.reservas[0] && linia.quilos_aproximats==0) {
        
      this.getReserva(linia.reservas[0]).then(reserva=>
        {this.reservaLinia[index]= reserva;
        linia.partida = reserva.partida;
        this.quilos_disponibles[index] = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
      )
      }
      if(!linia.partida_ok)
      { 
        if(linia.reservas[0]) {
        linia.partida_reserva = this.reservaOpcions.find(el=> el.id== linia.reserva)?.nom.split(" ")[0];
        //linia.partida = this.reservaOpcions.find(el=> el.id== linia.reserva)?.partida;
        this.getReserva(linia.reservas[0]).then(reserva=>
          {this.reservaLinia[index]= reserva;
          linia.num_pedido = reserva.num_pedido;
          if(reserva.observacions && this.comentari_general=="") {this.comentari_general = reserva.observacions;}
          linia.quilos_disponibles = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats);
          this.getBorresFiltrades({pagina:1, estats:["c","m"], partida:linia.partida}).then(
                bales => linia.bales_disponibles = bales.count
              );
          if(linia.completa) linia.quilos_aproximats = parseInt(reserva.quilos)-parseInt(reserva.quilos_enviats)}
        )}
        this.quilos_visibles = false;
        }
      // this.partidaOpcionsLinea[index] = this.textPartida(this.reservaOpcions.find(el=> el.id== reserva_id)?.partida)
    },
    calcularQuilosDispo(index,linia){
      if(linia.partida){this.getPartida(linia.partida).then(
        of=>{
          this.partidaOpcionsLinea[index] = of.codi.replaceAll('.', ''); 
          this.quilos_disponibles[index] = of.quilos_disponibles; 
          this.getBorresFiltrades({pagina:1, estats:["c","m"], partida:linia.partida}).then(
                  bales => {this.bales_disponibles[index] = bales.count}
                );


        })}
      
    },
    calcularQuilosDispoIniciales(index,linia){
      if(linia.partida){this.getPartida(linia.partida).then(
        of=>{
          this.partidaOpcionsLinea[index] = of.codi.replaceAll('.', ''); 
          this.quilos_disponibles[index] = of.quilos_disponibles + linia.quilos_aproximats; 


        })}
      
    },
    quitarReserva(linia){
      linia.partida_ok=false; 
      linia.completa = false; 
      linia.reservas =[]
    },
    customFilter(item, textFiltrat) {
      const textMateriaPrimera = this.textMateriaPrimera(item.id);
      return textMateriaPrimera
        .toLowerCase()
        .includes(textFiltrat.toLowerCase());
    },
    // Auxiliar
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );

      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor.nom;
      return materiaPrimera?.nom;
    },
    textPartida(id){
      var output
      // if(this.partidaOpcions.find(el=> el.id == id))
      // {return this.partidaOpcions.find(el=> el.id == id)?.codi}
      // else 
      if (id) {
        this.getPartida(id).then(partida=>{
          output= partida.codi.replaceAll('.', '')
        }
        )
        return output
      }
    },
    afegirLiniaOrdreEixida() {
      this.ordreEixida.lineas.push({
        quilos_aproximats: 0,
        max_num_bales: 0,
        tipo_balas: 2,
        observacions: "",
        bales: [],
        partida: "",
        ordre_eixida: this.ordreEixida.id,
        codigo_grs: "",
        comentaris: "",
        id:"" ,
        imprimir_observaciones: false,
        materia_primera: null,
        num_bales: "",
        num_pedido: "",
        precio_venta: null,
        quilos: 0,
        quilos_aproximats_superats: false,
        ref_ari: "",
        ref_materia: "",
        reservas: []
      });
    },
    afegirLiniaOrdreEixidaMP() {
      this.ordreEixida.lineas.push({
        quilos_aproximats: 0,
        max_num_bales: 0,
        tipo_balas: 1,
        observacions: "",
        reserva: "",
        bales: [],
        partida: "",
        ordre_eixida: this.ordreEixida.id
      });
    },
    btnCancelar() {
      this.$router.push({
        name: "OrdreEixidaDetail",
        params: { id: this.ordreEixida.id }
      });
    },
    carregaReserves() {
      // Carreguem les reserves filtrades per client
      if (this?.ordreEixida?.client) {
        this.getReservesPerClient(this.ordreEixida?.client)
          .then(reserves => {
            this.reservaOpcions = reserves;
          })
          // Un cop les tenim, afegim el camp 'nom', calculat a partir de OF i quilos
          .then(() => {
            this.reservaOpcions = this.reservaOpcions.map(reserva => {
              //let reservaCodi = this.mesclades.find(
              //  el => el.id === reserva.partida
              //)?.codi;
              let obs_partida = this.mesclades.find(
                el => el.id === reserva.partida
              )?.observacions;
              return { ...reserva, nom: `${reserva.nombre_producto.replaceAll('.', '')} ${obs_partida? `${obs_partida}`:``}- ${reserva.quilos}` };
            });
          });
      } else return [];
    },
    eliminarLineaOrdreEixida(index) {
      this.liniesOrdreEixidaAEliminar.push(
        this.ordreEixida.lineas.splice(index, 1)[0]
      );
    },
    editarLineaOrdreEixida(index){
      if(this.liniesOrdresEixidaACrear.find(element => element == index)!=index){this.liniesOrdreEixidaAEliminar.push(
        this.ordreEixida.lineas[index]
      );}
      if(this.liniesOrdresEixidaACrear.find(element => element == index)!=index){this.liniesOrdresEixidaACrear.push(index)}
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getClients();
    this.getMagatzems();
    this.getProveidors();
    this.getMateriesPrimeres();
    this.getMateriesProducteFinal();
    this.getMescladesReservades();
    this.getOrdreEixida(this.$route.params.id).then(ordreEixida => {
      this.ordreEixida = ordreEixida;
      if(ordreEixida.llacer){
        this.getPartidesTodasDispo({internas: '1'});
      }else{
        this.getPartidesTodasDispo({internas: '0'});
      }
      this.getReservesPerClient(ordreEixida?.client)
          .then(reserves => {
            this.reservaOpcions = reserves;
          })
          // Un cop les tenim, afegim el camp 'nom', calculat a partir de OF i quilos
          .then(() => {
            this.reservaOpcions = this.reservaOpcions.map(reserva => {
              //let reservaCodi = this.mesclades.find(
              //  el => el.id === reserva.partida
              //)?.codi;
              let obs_partida = this.mesclades.find(
                el => el.id === reserva.partida
              )?.observacions;
              return { ...reserva, nom: `${reserva.nombre_producto.replaceAll('.', '')} ${obs_partida? `${obs_partida}`:``}- ${reserva.quilos}` };
            });
          });
      this.liniesOrdreEixida = ordreEixida.lineas;
      ordreEixida.lineas.forEach((lin,index)=>{
        this.calcularQuilosDispo(index,lin)
      })
      
      this.carregaReserves();
      this.ordreEixidaPrev = this.ordreEixida.lineas;
    });
    //this.getPartides();
    //this.getPartidesTodasSinAcabar();
    
    
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
.producte{
  border: #8f8080 solid 1px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.container {
  max-width: 100% !important;
}
/* Creació */

// Botonera
.botonera {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 20%;
  }
}
.botonera-escanejar {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 30%;
  }
}

// Stepper
.container {
  padding-top: 3em;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: goldenrod;
}
</style>