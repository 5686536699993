<template>
  <v-container>
    <!-- Plantilla -->
    <v-simple-table v-if="plantilla" class="mt-12">
      <thead>
        <h1>Vista detallada de Plantilla</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Nom</b>
          </td>
          <td>{{ plantilla.nom }}</td>
        </tr>
        <tr>
          <td>
            <b>Producte final</b>
          </td>
          <td>{{ plantilla.producte_final }}</td>
        </tr>
        <tr v-if="plantilla.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ plantilla.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Plantilla -->

    <!-- Percentatges -->
    <h2>Ingredients</h2>
    <div>
      <v-simple-table v-for="(ingredient, index) in plantilla.percentatges" :key="index">
        <thead></thead>
        <tbody>
          <tr>
            <td>
              <b>Ingredient</b>
            </td>
            <td>{{ (ingredient.percentatge) }}% de {{ textIngredient(ingredient.ingredient) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <!-- ./Percentatges -->
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="primary" dark @click="btnEditarPlantilla">Editar plantilla</v-btn>
      <!-- Dialog/Modal per eliminar plantilla -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar plantilla</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar plantilla</v-card-title>
          <v-card-text>Està segur que vol eliminar esta plantilla?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarPlantilla">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar mesclada -->
    </div>
    <!-- Botons d'opcions -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('PlantillaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "plantilla-detall",
  data: () => ({
    dialog: false,
    plantilla: {
      id: null,
      nom: "",
      observacions: null,
      ingredient: [
        { id: null, plantilla: null, ingredient: "", percentatge: "" }
      ]
    }
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredientsCap"
    })
  },
  methods: {
    ...mapActions([
      "eliminarPlantilla",
      "getIngredients",
      "getMateriaProducteFinal",
      "getPlantilla"
    ]),

    // Botonera
    btnEditarPlantilla() {
      this.$router.push({
        name: "PlantillaEdit",
        params: { id: this.plantilla.id }
      });
    },
    btnEliminarPlantilla() {
      this.eliminarPlantilla(this.plantilla.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Plantilla.Notificacio", {
            text: "Plantilla eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "PlantillaList" });
        } else
          EventBus.$emit("Plantilla.Notificacio", {
            text: "Error eliminant la plantilla",
            color: "error"
          });
      });
    },
    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    },
    textIngredient(ingredientID) {
      return this.ingredientsOpcions.find(
        ingredient => ingredient.id === ingredientID
      )?.nom;
    }
  },
  mounted() {
    this.getIngredients();
    this.getPlantilla(this.$route.params.id).then(
      plantilla => (this.plantilla = plantilla)
    ).then(() => {
      this.getMateriaProducteFinal(this.plantilla.producte_final).then(producteFinal => this.plantilla.producte_final = producteFinal?.nom)
    });
  }
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  text-align: center;
  padding-bottom: 0.5em;
}
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}

#botonera {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>