<template>
  <v-container>
    <!-- Llistat Clients -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="6" offset-sm="3">
            <h1>Llistat de Clients</h1>
          </v-col>
          <v-col sm="1" offset-sm="1">
            <!-- Dialog/Modal per crear clients -->
            <v-dialog v-model="dialogClient" persistent max-width="600">
              <template v-slot:activator="{ on }">
                <v-btn block color="success" dark v-on="on">+</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Crear client</v-card-title>
                <v-container>
                  <v-form id="formClient" ref="formClient" class="mt-12">
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.nom" label="Nom" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.poblacio" label="Població" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.id_facturaplus" label="ID Facturaplus"></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- >v-row>
                      <v-col md="12">
                        <v-text-field v-model="client_nou.emails" label="Correu"></v-text-field>
                      </v-col>
                    </v-row! -->
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="tancarCrearClient">Cancel·lar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="btnCrearClient"
                    :disabled="!client_nou.nom || !client_nou.poblacio"
                  >Crear</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ./Dialog/Modal per crear client -->
          </v-col>
        </v-row>
        <v-row style="display: flex; justify-content: space-betwenn; text-align: center; padding-bottom:1em">
        <v-btn
          id="generarExcel"
          ref="buttonGetExcelData"
          @click="getExcelData"
        >Generar Excel</v-btn>
        <downloadexcel
          id="downloadexcel"
          ref="buttonGenerarExcel"
          class="btn"
          :name="nombreExcel"
          :data="excelArray"
          :fields="json_fields"
          worksheet = "Pàgina 1"
          type="xls"
        ></downloadexcel>
        <v-btn
          id="generarExcel"
          @click="addExcelData"
          style="margin-left: auto"
        >Afegir a Excel</v-btn>
        <v-btn
          id="generarExcel"
          @click="buidarExcel"
          style="margin-left: auto"
        >Buidar Excel</v-btn>
        </v-row>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.id">
          <td class="text-client">
            <div @click="clientDetail(client.id)">{{ client.nom }}</div>
            <div>
              <v-btn color="error" dark @click="btnModalEliminar(client.id)">Eliminar</v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Clients -->
    <!-- Dialog/Modal per eliminar client -->
    <v-dialog v-model="dialogEliminarClient" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Eliminar</v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialogEliminarClient = false">Cancel·lar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="btnEliminarClient()">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ./Dialog/Modal per eliminar client -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import downloadexcel from "vue-json-excel";
import { EventBus } from "@/event-bus.js";

export default {
  name: "client-llistat",
  data: () => ({
    dialogClient: false,
    dialogEliminarClient: false,
    clientEliminarID: null,
    client_nou: {
      nom: "",
      poblacio: "",
      id_facturaplus: ""
    },
    json_fields: {
        Nom: "nom",
        Poblacio: "poblacio",
        ID_Facturaplus: "id_facturaplus"
        
      },
    excelArray: [],
    nombreExcel: "Llistat_Clients.xls",
    pagina: 1,
    totalPagines: 1
  }),
  components:{
    downloadexcel
  },
  computed: {
    ...mapGetters({
      clients: "getClients"
    })
  },
  methods: {
    ...mapActions(["eliminarClient", "getClients", "sendClient"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },

    // Edició de client
    btnCrearClient() {
      this.sendClient(this.client_nou).then(client => {
        if (client?.data?.id){
          this.tancarCrearClient();
        }
      });
    },
    btnModalEliminar(client) {
      this.dialogEliminarClient = true;
      this.clientEliminarID = client;
    },

    btnEliminarClient() {
      this.eliminarClient(this.clientEliminarID).then(response => {
        if (response === 204) {
          EventBus.$emit("Client.Notificacio", {
            text: "Client eliminat correctament",
            color: "success"
          });
          this.dialogEliminarClient = false;
          this.clientEliminarID = null;
        } else
          EventBus.$emit("Client.Notificacio", {
            text: "Error eliminant el client",
            color: "error"
          });
      });
    },

    // Rerouting
    clientDetail(clientID) {
      this.$router.push({
        name: "ClientDetail",
        params: { id: clientID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    //Excel 
    getExcelData(){
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];


    },
    buidarExcel(){
      this.excelArray=[];
    },
    addExcelData(){
    this.excelArray.push.apply(this.excelArray, this.clients); 
    },
    // Auxiliars
    tancarCrearClient() {
      this.client_nou = {
        nom: "",
        poblacio: "",
        id_facturaplus: ""
      };
      this.dialogClient = false;
    }
  },
  created() {
    this.getClients();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  // padding-bottom: 1em;
}
.text-client {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 80%;
  }
  div:nth-child(2) {
    width: 20%;
  }

}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>