<template>
  <v-container>
    <!-- Llistat Materies Primeres -->
    <v-simple-table class="mt-12">
      <thead>
        <v-row>
          <v-col sm="7" offset-sm="1">
            <h1>Llistat de Stock de Materies Primeres</h1>
            <h1>Organitzats per magatzems</h1>
          </v-col>
          <v-col sm="2">
            <v-btn @click="dialogInforme=true">Crear Informe </v-btn>
            <v-dialog v-model="dialogInforme" persistent max-width="850">
              <v-card style="padding: 2%">
              <v-card-title class="headline">INFORME DE STOCK</v-card-title>
                <v-dialog
                ref="dialog"
                v-model="modalData"
                :return-value.sync="informe.data"
                persistent
                width="280px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="informe.data" 
                  rounded
                  outlined
                  label="Data informe" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="informe.data" locale="ca" :first-day-of-week="1" scrollable @click:date="datepicker()">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datepickerDelete()">Borrar</v-btn>
                  <v-btn text color="primary" @click="datepicker()">OK</v-btn>
                </v-date-picker>
              </v-dialog>
              <v-row v-if="informe.materies_primeres">
              <v-col v-for="(materia, index) in informe.materies_primeres" :key="index" sm="6">
               <v-row v-for="(materiaProveidor,index2) in materia" :key="index2">
                 <strong> {{index}} 
                  -{{ index2}}: </strong> {{materiaProveidor.num_bales}} bales - {{materiaProveidor.quilos}} kg
               </v-row></v-col>
              <!-- <v-col sm="6">{{ magatzem.balas}} bales disponibles</v-col> -->
            </v-row>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  tabindex="-1"
                  @click="cerrarInforme"
                >Cancel·lar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="getExcelData"
                  tabindex="-1"
                >Descarregar</v-btn>
                <downloadexcel
                id="downloadexcel"
                ref="buttonGenerarExcel"
                class="btn"
                :name="nombreExcel"
                :data="excelArray"
                :fields="json_fields"
                worksheet = "Pàgina 1"
                type="xls"
              ></downloadexcel>
              </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </thead>
      <tbody>
        <tr v-if="num_bales_senseMagat>0">
          <td
            class="text-instancia"
            @click="magatzemList('Sense')"
            style="background-color: coral;"
          >
            <v-row>
              <v-col sm="12"> {{ num_bales_senseMagat}} bales <strong>Sense magatzem</strong></v-col>
              <!-- <v-col sm="6">{{ num_bales_senseMagat}} bales </v-col> -->
            </v-row>
          </td>
        </tr>
        <tr v-for="magatzem in magatzems" :key="magatzem.id">
          <td
            class="text-instancia"
            @click="magatzemList(magatzem.id)"
            v-if="magatzem.balas > 0"
          >
            <v-row>
              <v-col sm="6"><strong>{{ magatzem.nom }}</strong></v-col>
              <v-col sm="6">{{ magatzem.balas}} bales</v-col>
            </v-row>
          </td>
        </tr>
        
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Materia Primera -->

    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="totalPagines > 1"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import downloadexcel from "vue-json-excel";

export default {
  name: "stock-materia-primera-magatzems-llistat",
  data: () => ({
    informe:{},
    num_bales_senseMagat:"",
    json_fields: {
        NOM_PRODUCTE: "nom",
        PROVEIDOR: "proveidor",
        MAGATZEM: "magatzem",
        ZONA: "zona",
        NUM_BALES: "num_bales", 
        NUM_BALES_DISPO: "num_bales_disponibles", 
        QUILOS: "quilos", 
        QUILOS_DISPO: "quilos_disponibles", 
        COST_REAL: "coste_real",
        COST_MIG: "coste_medio",
      },
    excelArray: [],
    nombreExcel: "Informe_stock",
    modalData:false,
    dialogInforme:false,
    pagina: 1,
    totalPagines: 1, 
    magatzems: []
  }),
  components:{
    downloadexcel
  },
  // computed: {
  //   ...mapGetters({
  //     magatzems: "getMagatzems"
  //   })
  // },
  methods: {
    ...mapActions(["getMagatzems", "getStockMP",
    "getBalesSenseMagatzem",
    "getInformeMagatzems"]),

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
    },
    datepicker() {
      this.$refs.dialog.save(this.informe.data);
      //this.getInformeMagatzems(this.informe.data).then(
      //  results => {this.informe=results;
      //  this.informe.data="";
      //  for (const materia in results.materies_primeres){
      //    for (const materiaProv in results.materies_primeres[materia] ){
      //      this.excelArray.push({nom: materia, quilos: results.materies_primeres[materia][materiaProv].quilos, 
      //      proveidor: materiaProv, 
      //      num_bales: results.materies_primeres[materia][materiaProv].num_bales})
      //    }
      //  }}
      //)
    },
    datepickerDelete() {
      this.$refs.dialog.save((this.informe.data = ""));
      this.modalData = false;
    },
    //Excel
    getExcelData(){
      // this.excelArray = this.informe.productes_finals
      this.$refs.buttonGenerarExcel.$el.click();
      this.excelArray =[];
    },
    buidarExcel(){
      this.excelArray=[];
      this.paginasAñadidas = [];

    },
    addExcelData(){
      this.nombreExcel = 'Llistat_Stock_'+this.borra_nom+'_'+this.magatzem.nom;
      if(!this.paginasAñadidas.find(el=> el==this.pagina))
      {this.excelArray.push.apply(this.excelArray, this.borres); 
      this.paginasAñadidas.push(this.pagina);}
    },
    // Rerouting
    magatzemList(magatzemID) {
      if(magatzemID=='Sense'){
       this.$router.push({
        name: "StockMateriaPrimeraEnMagatzemList",
        params: { id: 'Sense' }
      });
      }else{
        this.$router.push({
        name: "StockMateriaPrimeraEnMagatzemList",
        params: { id: magatzemID }
      });
      }
      
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
   
    cerrarInforme(){
      this.dialogInforme = false
      this.informe = {} 
    }
  },
  created() {
    this.getStockMP().then(
      data=> {
        for (const prop in data) {
            var balas_magatzem = 0;
            var barcode ="";
            for( const prop2 in data[prop].posicions){
              barcode = prop2
              data[prop].posicions[prop2].materies_primeres.forEach(materia=>
                  {
                    balas_magatzem+=materia.num_bales;
                    this.excelArray.push({nom: materia.nom_complet, quilos: materia.quilos, 
                      proveidor:  materia.proveidor_nom, 
                      coste_medio: materia.coste_medio,
                      coste_real: materia.coste_real,
                      num_bales: materia.num_bales,
                      num_bales_disponibles: materia.num_bales_disponibles,
                      quilos_disponibles: materia.quilos_disponibles, 
                      magatzem: data[prop].nom,
                      zona: data[prop].posicions[prop2].zona
                      })
                    })
          
                this.magatzems.push({id: barcode, nom:data[prop].nom +
                ' - Nº: '+  data[prop].posicions[prop2].zona, balas:balas_magatzem})
                balas_magatzem = 0;
              }
         
          }
      }
    );
    this.getBalesSenseMagatzem({pagina: 1, magatzem: null, estat: 1}).then(
      result =>{
        this.num_bales_senseMagat = result.count
      }
    );
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  font-size: 5.5mm;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
</style>