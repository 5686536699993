<template>
  <!-- <div id="punt-omplir-quartos"  v-on:dbclick="focusCodi()"> -->
  <div id="punt-omplir-quartos" >
    <div id="container">
      <!-- Escanejar bala -->
      <v-row justify="center" style="height: 10%">
        <v-col sm="4" style="height= 5%">  
          <span style=" background: none; margin-top: 5px;" >
          <v-text-field
            v-model="codiEscanejat"
            label="Código escaneado"
            v-on:keyup.enter="carregaBala"
            outlined
            required
            ref="campBarcode"
          ></v-text-field>
        </span>
        </v-col>
        <v-col sm="2" >
          <span style=" background: none; margin-top: 5px;" >
          <v-select
                  style="border: none; background: none; height: 65px"
                  v-model="num_maquina"
                  :items="maquinas"
                  filled
                  @input="canviarMaquina"
                  no-data-text
                  outlined
                  item-text="numero"
                  item-value="id"
                  label="Nº Máquina"
                ></v-select></span>
        </v-col>
        
        <!-- <v-col sm="1" >
        <v-switch
          v-model="switch1"
          label="Fora"
        ></v-switch>
        </v-col> -->

      </v-row>
      <v-dialog v-model="dialogError" @keydown="tancarDialogs" max-width="600">
        <v-card>
          <v-card-title class="justify-center"><pre>{{msg_error}}</pre></v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="tancarDialogs" autofocus>Cerrar</v-btn>
            <v-spacer></v-spacer>

            <v-btn color="red" dark @click="forzarVaciado" autofocus>CONFIRMAR</v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogError1" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">BALA NO ASIGNADA</v-card-title>
          <v-card-text>Esta bala no está asignada a ninguna mezclada</v-card-text>
          <v-card-text v-if="quarto_actiu.quarto > 0">¿Qué quieres hacer?</v-card-text>
          <v-card-actions v-if="quarto_actiu.quarto>0 && dialogError1">
            <v-btn color="red" dark @click="tancarDialogs">Ha sido un error</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="cambioDialogAssignar">Asignar</v-btn>
          </v-card-actions>
          <v-card-actions v-else-if="dialogError1">
            <v-btn color="red" dark @click="tancarDialogs">Ha sido un error</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogError2" max-width="600" @keydown="tancarDialogs">
        <v-card>
          <v-card-title class="headline" style="text-align:center">BALA CONSUMIDA</v-card-title>
          <v-card-text style="text-align:center">Esta bala aparece como consumida en el sistema</v-card-text>
          <v-card-text style="text-align:center">Escanea otra bala</v-card-text>
          <v-card-actions>
            <v-btn color="red" dark @click="tancarDialogs">CERRAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCambioQuarto" max-width="600" @keydown.stop="canviarQuarto">
        <v-card>
          <v-card-title class="headline">CUARTO COMPLETADO - CAMBIO DE CUARTO</v-card-title>
          <v-card-text style="text-align:center">SE HAN CONSUMIDO TODAS LAS BALAS DE ESTE CUARTO</v-card-text>
          <v-card-title v-for="(quarto,index) in quartosAssignar" :key="index"> Cuarto: {{quarto}}</v-card-title>
          <v-card-actions>
            <v-btn color="red" dark @click="canviarQuarto">CERRAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFinMezclada" max-width="600" >
        <v-card>
          <v-card-title class="headline">TODAS LAS BALAS HAN SIDO CORTADAS <br>E INTRODUCIDAS A LA MÁQUINA</v-card-title>
          <v-card-text>Máquina ocupada hasta que se finalice la partida</v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogMostrarBales" @click:outside="tancarDialogs" @keydown="tancarDialogs" max-width="600">
        <v-card>
          <v-card-title class="headline">BALAS PENDIENTES PARA ESTE CUARTO</v-card-title>
          <tbody style="align=center">
            <tr v-for="(bala,index) in quarto.bales_pendents" :key="index">
              <td class="text-mostrar-bales">
                Bala {{index + 1}} - {{bala.barcode}} - {{bala.quilos}} kg {{bala.nom_ingredient? `- Ingredient: ${bala.nom_ingredient}`: ""}}
              </td>
            </tr>
          </tbody>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark right @click="tancarDialogs">VOLVER</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFinalitzarQuarto" max-width="600" >
        <v-card>
          <v-card-title class="headline">BALAS PENDIENTES PARA ESTE CUARTO</v-card-title>
          <tbody style="align=center">
            <tr v-for="(bala,index) in quarto.bales_pendents" :key="index">
              <td class="text-mostrar-bales">
                Bala {{index + 1}} - {{bala.barcode}} - {{bala.quilos}} kg 
              </td>
            </tr>
          </tbody>
          <v-card-actions>
            <v-btn color="red" dark right @click="dialogFinalitzarQuarto = false">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark right @click="finalitzarQuarto">Finalizar y consumir balas pendientes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAssignarQuarto" persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Selecciona el cuarto al que corresponde la bala</v-card-title>
          
            <!-- <v-btn style="margin:25px; font-size:30px" width="100px" height="100px" v-for="(quarto,index) in quartos" :key="index" color="primary" dark right @click="elegirQuarto(index)" >
              {{quarto}}</v-btn> -->
          <div id="distribucio">
            <div id="quartos">
            <div
                class="quarto"
                v-for="i in quartosAssignar"
                :key="i.quarto"
                @click="clickQuarto(i)"
                :class="{'quarto-seleccionat': bala_esta_seleccionada(i)}"
              >{{i.quarto}}</div>
            </div>
            </div>
            <v-card-actions>
            <v-btn color="red" dark right @click="cancelarDialogAssignar">CANCELAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark right @click="TriarQuarto">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Escanejar bala -->
      <!-- Dades -->
      <v-row id="dades" justify="center">
        <!-- Quarto actual -->
        <v-col style="padding-bottom: 0px;">
          <v-row id="quarto-titol">
            <h3>Información cuarto actual</h3> <h3 v-if="quarto_actiu.quarto > 0 "> {{quarto_actiu.quarto}}</h3>
          </v-row>
          <div id="info-quarto-bales">
            <div class="separador"></div>
            <v-row>
              <div id="bales-completes">
                <strong>BALAS COMPLETAS TOTALES</strong>
                <span>{{ quarto.bales_completes}}</span>
              </div>
            </v-row>
            <v-row>
              <div id="bales-completes-consumides">
                <strong>BALES COMPLETAS CONSUMIDAS</strong>
                <span>{{ quarto.bales_completes_consumides }}</span>
              </div>
            </v-row>
            <div class="separador"></div>
            <v-row>
              <div id="bales-parcials">
                <strong>BALAS PARCIALES</strong>
                <span>{{ quarto.bales_parcials }}</span>
              </div>
            </v-row>
            <v-row>
              <div id="bales-parcials-consumides">
                <strong>BALAS PARCIALES CONSUMIDAS</strong>
                <span>{{ quarto.bales_parcials_consumides }}</span>
              </div>
            </v-row>
              <!-- Botons d'opcions -->
              <div id="botonera">
                <v-btn x-large color="primary" dark @click="mostrarBalesPendents" :disabled="quarto_actiu.id == 0"> <span style="white-space: normal;font-size:4vw">Balas Pendientes </span></v-btn>
                <v-btn x-large color="error" dark @click="dialogFinalitzarQuarto=true" :disabled="quarto_actiu.id == 0">
                  <span style="white-space: normal;font-size:4vw">Finalizar </span> </v-btn>
              </div>
              <!-- Botons d'opcions -->
          </div>
        </v-col>
        <!-- /Quarto actual -->
      </v-row>
      <v-row id="dades" justify="center">
                <!-- Mesclada actual -->
        <v-col style="padding-top: 0px;" >
          <v-row id="mesclada-titol">
            <h3>DATOS MEZCLADA ACTUAL {{textMesclada(mesclada.id)}} </h3>
          </v-row>
          <div id="info-mesclada-bales">
            <div class="separador"></div>
            <v-row>
              <div id="bales-totals">
                <strong>BALAS TOTALES</strong>
                <span>{{ mesclada.bales_totals }}</span>
              </div>
            </v-row>
            <v-row>
              <div id="bales-consumides">
                <strong>BALAS CONSUMIDAS</strong>
                <span>{{ mesclada.bales_consumides }}</span>
              </div>
            </v-row>
            <div class="separador"></div>
            <v-row>
              <div id="quartos-totals">
                <strong>CUARTOS TOTALES</strong>
                <span>{{ mesclada.quartos_totals }}</span>
              </div>
            </v-row>
            <v-row>
              <div id="quartos-completats">
                <strong>CUARTOS COMPLETADOS</strong>
                <span>{{ mesclada.quartos_completats }}</span>
              </div>
            </v-row>
            <div class="separador"></div> 
            
          </div>
        </v-col>
        <!-- ./Mesclada actual -->
      </v-row>
      
      <v-row justify="center" style="padding-left: 10%; padding-right: 10%">
        <v-btn id="boton-quarto-espera" block v-if="comprobarLocal" color="info" @click="IntroduirQuartoEnEspera">
             Introducir Cuarto <br>En espera</v-btn>
        <v-btn id="boton-quarto-espera" block v-else color="info" @click="redireccio('OmplirQuartoEnEspera')">
             Llenar Cuarto <br>En espera</v-btn>
        <!-- <v-btn @click="btnForzarVaciado"> Buidar màquina </v-btn> -->
      </v-row>
      <!-- ./Dades -->
      <!-- Footer -->
      <v-footer class="font-weight-medium" height="38%">
        <v-col class="text-center" cols="12" @click="redireccio('MenuTreballadors')" style="padding:0px">
          <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
      <!-- ./Footer -->
    </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
// import { EventBus } from "@/event-bus.js";

export default {
  name: "punt-omplir-quartos",
  data: () => ({
    comprobarLocal:"",
    codiEscanejat: "",
    quarto: {
      bales_completes: 0,
      bales_completes_consumides: 0,
      bales_parcials: 0,
      bales_parcials_consumides: 0, 
      bales_pendents: ""
    },
    quartosMaquinas:[],
    maquinasTodo:[],
    quartosMezclando:[],
    switch1: false,
    msg_error:[],
    dialogError: false,
    bales_a_crear:[],
    quartosAssignar:[],
    info_mescalda:[],
    bales_parcials_carregades:[],
    mesclada_activa:[],
    quarto_actiu:{id:0, quarto:0},
    quartos: [],
    maquina_info:"",
    num_maquina: 1,
    numeros_maquinas: [],
    bales_carregades:[],
    quartos_mesclat: 0,
    dialogError1: false, 
    dialogError2: false,
    dialogMostrarBales: false,
    dialogFinalitzarQuarto: false,
    dialogAssignarQuarto:false,
    dialogCambioQuarto: false,
    dialogFinMezclada: false,
    mesclada: {
      bales_totals: 0,
      bales_consumides: 0,
      quartos_totals: 0,
      quartos_completats: 0,
      quartos: [], 
      id: 0 
    }
  }),
  computed: {
    ...mapGetters({
      mesclades: "getMesclades",
      ingredientsOpcions: "getIngredients",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidorOpcions: "getProveidors", 
      maquinas: "getMaquinas", 
      quartos_mezclando: "getQuartosMezclando"
    })
  },
  methods: {
    ...mapActions([
      "getBalaBarcode",
      "getMateriesPrimeres", 
      "getBalaParcial",
      "getIngredients",
      "getQuartosMesclat",
      "getQuartoMesclat",
      "getMesclades",
      "mezclarBala", 
      "iniciarEjecucionQuarto",
      "finalizarEjecucionQuarto",
      "forzarLlenado", 
      "editMesclada",
      "crearBalaParcial",
      "getProveidors", 
      "getMaquinaInfo", 
      "getMaquinas", 
      "getEstadoMesclada", 
      "setQuartosMezclando","borrarQuartosMezclando"
      ]),
    focusCodi(){
      this.$refs.campBarcode.focus();
      // this.$nextTick(() => this.focusCodi());
    },
    // comprobarLocal(){
    //   if(localStorage.getItem('datos')) return true 
    //   else 
    //   return false
    // },
    codiDeseleccionat(){
      this.$nextTick(() => this.focusCodi());
    },
    tancarDialogs(){
      if(this.dialogMostrarBales == true ) {
        this.dialogMostrarBales = false;
        this.$nextTick(() => this.focusCodi());} 
      if(this.dialogError1 == true) {
        this.dialogError1 = false;
        this.$nextTick(() => this.focusCodi());} 
      if(this.dialogError2 == true) {
        this.dialogError2 = false;
        this.$nextTick(() => this.focusCodi());} 
      if(this.dialogError == true) {
        this.dialogError = false;
        this.$nextTick(() => this.focusCodi());} 
      if(this.dialogCambioQuarto == true) {
        this.dialogCambioQuarto = false;
        this.$nextTick(() => this.focusCodi());} 
    },
    // Escaneja bala
    carregaBala() {
      if(this.codiEscanejat == "") return;
      this.getBalaBarcode(this.codiEscanejat).then(bala => {
        if(bala == []){
          this.codiEscanejat = "";
          return;
        }
        if (bala.estat == 1) {
          this.dialogError1=true;
          this.codiEscanejat = "";
          return;
        }
        if (bala.estat == 2 || bala.estat == 3) {
          //this.mesclada.bales_consumides = parseInt(this.mesclada.bales_consumides) + 1;
          //this.codiEscanejat = "";
          // if (this.bales_carregades.length <= 1){
          // this.mesclada.bales_totals = this.mesclada_activa.porcions_mesclat.length;
          // this.Cuartos_i_bales_per_cuarto();}
          var quarto_mezclar=0;
          var puede_continuar =1;
          this.getBalaParcial(this.codiEscanejat).then(bala_parcial=>{
            if(bala_parcial){
              bala_parcial.forEach(bala=>
              {
                Object.keys(this.quartos_mezclando).forEach( propiedad => { 
                        if (this.quartos_mezclando[propiedad].quarto == bala.quarto &&
                        propiedad!=this.num_maquina) { 
                              this.dialogError = true; 
                              this.msg_error = `Cuarto plenant-se a altra màquina [ Nº ${propiedad}]`
                              return

                        }
                })
              })
              if(this.quarto_actiu.id>0) quarto_mezclar =this.quarto_actiu.id;
              else {
                if(bala_parcial.length==1)
                {quarto_mezclar = bala_parcial[0].quarto
                puede_continuar =2;
                this.getQuartoMesclat(quarto_mezclar).then(quarto=>
                { 
                  if(Object.keys(this.quartos_mezclando).length>0){Object.keys(this.quartos_mezclando).forEach( (propiedad, index) => { 
                          if (this.quartos_mezclando[propiedad].mesclada == quarto.ordre_fabricacio &&
                               propiedad!=this.num_maquina) { 
                                puede_continuar = 3;
                                this.dialogError = true; 
                                this.msg_error = `Mesclada plenant-se a altra màquina  [ Nº ${propiedad}]`    
                          }
                          if(index == (this.quartos_mezclando.length-1) && puede_continuar==2){
                                  this.mezclarBalaFuncion({barcode: bala_parcial[0].barcode, quarto:quarto_mezclar, num_maquina: this.num_maquina})
                          }

                  })}
                  else{
                    this.mezclarBalaFuncion({barcode: bala_parcial[0].barcode, quarto:quarto_mezclar, num_maquina: this.num_maquina})
                  }})
                }
                else{
                  this.bala_parcial = bala_parcial

                  bala_parcial.forEach(bala=> this.getQuartoMesclat(bala.quarto).then( quarto=>
                  {{
                    if(this.quartosMaquinas.includes(quarto.id)){
                      console.log('Si')
                      return
                    }else {
                      if (quarto.estat==0) this.quartosAssignar.push(quarto);
                    }
                    if(quarto.mezclando_en_cuarto) this.quartosMezclando.push(quarto.mezclando_en_cuarto)}}))
                  this.dialogAssignarQuarto = true;
                  return
                }
                }
              
              // this.bales_parcials_carregades=bala_parcial;
              if(puede_continuar==1)
              {this.mezclarBala({barcode: bala_parcial[0].barcode, quarto:quarto_mezclar, num_maquina: this.num_maquina}).then(balaParcial => {
              if(balaParcial.error){
                this.dialogError = true;
                this.msg_error = `BALA ${balaParcial.barcode} NO VA A AQUEST QUARTO`
                // this.msg_error = balaParcial.error;
              }else{
              this.mesclada_activa = this.mesclades.find(mesclada => mesclada.id == balaParcial.estado_preparacion_mezclada.mezclada_id);
              this.quarto_actiu.quarto= balaParcial.estado_preparacion_quarto.num_quarto;
              this.quarto_actiu.id= balaParcial.estado_preparacion_quarto.quarto_id;
              this.quarto.bales_parcials = balaParcial.estado_preparacion_quarto.balas_parciales_totales;
              this.quarto.bales_parcials_consumides = parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_parcialmente_consumidas)
              + parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_consumidas);
              this.quarto.bales_completes = balaParcial.estado_preparacion_quarto.balas_completas_totales;
              this.quarto.bales_completes_consumides = balaParcial.estado_preparacion_quarto.balas_completas_consumidas;
              this.mesclada.bales_totals= balaParcial.estado_preparacion_mezclada.balas_totales;
              this.mesclada.bales_consumides= balaParcial.estado_preparacion_mezclada.balas_consumidas;
              this.mesclada.quartos_totals = balaParcial.estado_preparacion_mezclada.cuartos;
              this.mesclada.quartos_completats = balaParcial.estado_preparacion_mezclada.cuartos_iniciados;
              this.mesclada.id = balaParcial.estado_preparacion_mezclada.mezclada_id;
              // this.getMaquinaInfo(this.num_maquina).then(info=>{
              //   if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1"){
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual})
              //   } else if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
              //   {
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual})
              //   }
              // })
              if(balaParcial.cuarto_finalizado && balaParcial.estado_preparacion_mezclada.balas_totales != balaParcial.estado_preparacion_mezclada.balas_consumidas){
                    this.dialogCambioQuarto = true;
                    this.borrarQuartosMezclando(this.num_maquina); 
              } else if(balaParcial.estado_preparacion_mezclada.balas_totales == balaParcial.estado_preparacion_mezclada.balas_consumidas){
                this.dialogFinMezclada = true;
                this.borrarQuartosMezclando(this.num_maquina); 
                this.quarto_actiu={id:0, quarto:0};
                this.quarto= {
                bales_pendents: 0,
                bales_completes: 0,
                bales_completes_consumides: 0,
                bales_parcials: 0,
                bales_parcials_consumides: 0} 
                this.mesclada= {
                  bales_totals: 0,
                  bales_consumides: 0,
                  quartos_totals: 0,
                  quartos_completats: 0,
                  quartos: [], 
                  id: 0 
                };
                
                // if(!this.switch1){
                // this.getMaquinaInfo(this.num_maquina).then(info=>{
                // if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
                //   {   
                //     this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual}).then(
                //       response=>{
                //       if(response.error)
                //       {
                //         this.dialogError = true; 
                //         this.msg_error = `Error al finalitzar quarto - ${info.cuartos[1].cuarto_actual}`
                //       }
                //       else {
                //         this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[0].cuarto_actual}`
                //           }}
                //         )}}
                //       )
                //   } else if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1") {
                //     this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
                //       response => {
                //       if(response.error)
                //       {
                //         this.dialogError = true; 
                //         this.msg_error = `Error al finalitzar quarto - ${info.cuartos[0].cuarto_actual}`
                //       }
                //       else {this.iniciarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[1].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[1].cuarto_actual}`
                //           }}
                //         )}}
                // )} else if(info.cuartos[0].estat == "0" && info.cuartos[1].estat == "1"){
                //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[1].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[1].cuarto_actual}`
                //           }}
                //         )
                // } else if(info.cuartos[0].estat == "1" && info.cuartos[1].estat == "0"){
                //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[0].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[1].cuarto_actual}`
                //           }}
                //         )
                // }
                // })
                // }else{
                //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: balaParcial.estado_preparacion_quarto.quarto_id}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${balaParcial.estado_preparacion_quarto.quarto_id}`
                //           }}
                //         )
                // }
              }
              
              if(this.quarto.bales_pendents==0){
                this.getQuartoMesclat(balaParcial.estado_preparacion_quarto.quarto_id).then(
                quarto=>{
                  this.quarto.bales_pendents = quarto.porcions_mesclat.filter(balaPendent => balaPendent.barcode!= bala_parcial.barcode);
                }
              )
              }else{
                this.quarto.bales_pendents = this.quarto.bales_pendents.filter(balaPendent => balaPendent.barcode!= bala_parcial.barcode)
              } 
            }}); }
            }
          });
          this.codiEscanejat = "";
          this.focusCodi();
          return;
        }
        if (bala.estat == 4) {
          this.codiEscanejat = "";
          this.dialogError2=true;
          return;
        }
      });
    },
    cancelarDialogAssignar(){
      this.dialogAssignarQuarto=false
      this.quartosAssignar=[];
      this.quartos=[];
    },
    mezclarBalaFuncion(data){
      this.mezclarBala(data).then(balaParcial => {
              if(balaParcial.error){
                this.dialogError = true;
                this.msg_error = `BALA ${balaParcial.barcode} NO VA A AQUEST QUARTO`
                // this.msg_error = balaParcial.error;
              }else{
              this.mesclada_activa = this.mesclades.find(mesclada => mesclada.id == balaParcial.estado_preparacion_mezclada.mezclada_id);
              this.quarto_actiu.quarto= balaParcial.estado_preparacion_quarto.num_quarto;
              this.quarto_actiu.id= balaParcial.estado_preparacion_quarto.quarto_id;
              this.quarto.bales_parcials = balaParcial.estado_preparacion_quarto.balas_parciales_totales;
              this.quarto.bales_parcials_consumides = parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_parcialmente_consumidas)
              + parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_consumidas);
              this.quarto.bales_completes = balaParcial.estado_preparacion_quarto.balas_completas_totales;
              this.quarto.bales_completes_consumides = balaParcial.estado_preparacion_quarto.balas_completas_consumidas;
              this.mesclada.bales_totals= balaParcial.estado_preparacion_mezclada.balas_totales;
              this.mesclada.bales_consumides= balaParcial.estado_preparacion_mezclada.balas_consumidas;
              this.mesclada.quartos_totals = balaParcial.estado_preparacion_mezclada.cuartos;
              this.mesclada.quartos_completats = balaParcial.estado_preparacion_mezclada.cuartos_iniciados;
              this.mesclada.id = balaParcial.estado_preparacion_mezclada.mezclada_id;
              // this.getMaquinaInfo(this.num_maquina).then(info=>{
              //   if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1"){
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual})
              //   } else if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
              //   {
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual})
              //   }
              // })
              if(balaParcial.cuarto_finalizado && balaParcial.estado_preparacion_mezclada.balas_totales != balaParcial.estado_preparacion_mezclada.balas_consumidas){
                    this.dialogCambioQuarto = true; 
                    this.borrarQuartosMezclando(this.num_maquina); 
              } else if(balaParcial.estado_preparacion_mezclada.balas_totales == balaParcial.estado_preparacion_mezclada.balas_consumidas){
                this.dialogFinMezclada = true;
                this.borrarQuartosMezclando(this.num_maquina); 
                this.quarto_actiu={id:0, quarto:0};
                this.quarto= {
                bales_pendents: 0,
                bales_completes: 0,
                bales_completes_consumides: 0,
                bales_parcials: 0,
                bales_parcials_consumides: 0} 
                this.mesclada= {
                  bales_totals: 0,
                  bales_consumides: 0,
                  quartos_totals: 0,
                  quartos_completats: 0,
                  quartos: [], 
                  id: 0 
                };
                
                // if(!this.switch1){
                // this.getMaquinaInfo(this.num_maquina).then(info=>{
                // if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
                //   {   
                //     this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual}).then(
                //       response=>{
                //       if(response.error)
                //       {
                //         this.dialogError = true; 
                //         this.msg_error = `Error al finalitzar quarto - ${info.cuartos[1].cuarto_actual}`
                //       }
                //       else {
                //         this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[0].cuarto_actual}`
                //           }}
                //         )}}
                //       )
                //   } else if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1") {
                //     this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
                //       response => {
                //       if(response.error)
                //       {
                //         this.dialogError = true; 
                //         this.msg_error = `Error al finalitzar quarto - ${info.cuartos[0].cuarto_actual}`
                //       }
                //       else {this.iniciarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[1].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[1].cuarto_actual}`
                //           }}
                //         )}}
                // )} else if(info.cuartos[0].estat == "0" && info.cuartos[1].estat == "1"){
                //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[1].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[1].cuarto_actual}`
                //           }}
                //         )
                // } else if(info.cuartos[0].estat == "1" && info.cuartos[1].estat == "0"){
                //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[0].cuarto_actual}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${info.cuartos[1].cuarto_actual}`
                //           }}
                //         )
                // }
                // })
                // }else{
                //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: balaParcial.estado_preparacion_quarto.quarto_id}).then(
                //           response => {
                //           if(response.error)
                //           {
                //             this.dialogError = true; 
                //             this.msg_error = `Error al iniciar quarto - ${balaParcial.estado_preparacion_quarto.quarto_id}`
                //           }}
                //         )
                // }
              }
              
              if(this.quarto.bales_pendents==0){
                this.getQuartoMesclat(balaParcial.estado_preparacion_quarto.quarto_id).then(
                quarto=>{
                  this.quarto.bales_pendents = quarto.porcions_mesclat.filter(balaPendent => balaPendent.barcode!= data.barcode);
                }
              )
              }else{
                this.quarto.bales_pendents = this.quarto.bales_pendents.filter(balaPendent => balaPendent.barcode!= data.barcode)
              } 
            }}); 
    },
    TriarQuarto(){
      this.dialogAssignarQuarto = false; 
      // this.quarto_actiu = this.quartos.id;
      // this.quartos = [];
      this.mezclarBala({barcode: this.bala_parcial[0].barcode, quarto:this.quartos.id, num_maquina: this.num_maquina}).then(balaParcial => {
              if(balaParcial.error){
                this.dialogError = true;
                this.msg_error = `BALA ${balaParcial.barcode} NO VA A AQUEST QUARTO`
                // this.msg_error = balaParcial.error;
              }else{
              this.mesclada_activa = this.mesclades.find(mesclada => mesclada.id == balaParcial.estado_preparacion_mezclada.mezclada_id);
              this.quarto_actiu.quarto= balaParcial.estado_preparacion_quarto.num_quarto;
              this.quarto_actiu.id= balaParcial.estado_preparacion_quarto.quarto_id;
              this.quarto.bales_parcials = balaParcial.estado_preparacion_quarto.balas_parciales_totales;
              this.quarto.bales_parcials_consumides = parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_parcialmente_consumidas)
              + parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_consumidas);
              this.quarto.bales_completes = balaParcial.estado_preparacion_quarto.balas_completas_totales;
              this.quarto.bales_completes_consumides = balaParcial.estado_preparacion_quarto.balas_completas_consumidas;
              this.mesclada.bales_totals= balaParcial.estado_preparacion_mezclada.balas_totales;
              this.mesclada.bales_consumides= balaParcial.estado_preparacion_mezclada.balas_consumidas;
              this.mesclada.quartos_totals = balaParcial.estado_preparacion_mezclada.cuartos;
              this.mesclada.quartos_completats = balaParcial.estado_preparacion_mezclada.cuartos_iniciados;
              this.mesclada.id = balaParcial.estado_preparacion_mezclada.mezclada_id;
              // this.getMaquinaInfo(this.num_maquina).then(info=>{
              //   if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1"){
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual})
              //   } else if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
              //   {
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual})
              //   }
              // })
              if(balaParcial.cuarto_finalizado && balaParcial.estado_preparacion_mezclada.balas_totales != balaParcial.estado_preparacion_mezclada.balas_consumidas){
                    this.dialogCambioQuarto = true; 
                    this.borrarQuartosMezclando(this.num_maquina); 
              } else if(balaParcial.estado_preparacion_mezclada.balas_totales == balaParcial.estado_preparacion_mezclada.balas_consumidas){
                this.dialogFinMezclada = true;
                this.borrarQuartosMezclando(this.num_maquina); 
                this.quarto_actiu={id:0, quarto:0};
                this.quarto= {
                bales_pendents: 0,
                bales_completes: 0,
                bales_completes_consumides: 0,
                bales_parcials: 0,
                bales_parcials_consumides: 0} 
                this.mesclada= {
                  bales_totals: 0,
                  bales_consumides: 0,
                  quartos_totals: 0,
                  quartos_completats: 0,
                  quartos: [], 
                  id: 0 
                };} 
              }
      }
              )
              this.quartos=[];
              this.quartosAssignar=[]},  
    IntroduirQuartoEnEspera(){
      var balas_en_espera = JSON.parse(localStorage.getItem('datos'))
      var quarto_en_espera =localStorage.getItem('quarto')
      this.comprobarLocal = false; 
      
      for (var bala in balas_en_espera){
        // window.setTimeout(this.mezclarBala({barcode: balas_en_espera[bala], quarto:quarto_en_espera, num_maquina: this.num_maquina}),1000)
        window.setTimeout(this.mezclarBala({barcode: balas_en_espera[bala], quarto:quarto_en_espera, num_maquina: this.num_maquina}).then(balaParcial => {
              if(balaParcial.error){
                this.dialogError = true;
                this.msg_error = `BALA ${balaParcial.barcode} NO VA A AQUEST QUARTO`
                // this.msg_error = balaParcial.error;
              }else{
              this.mesclada_activa = this.mesclades.find(mesclada => mesclada.id == balaParcial.estado_preparacion_mezclada.mezclada_id);
              this.quarto_actiu.quarto= balaParcial.estado_preparacion_quarto.num_quarto;
              this.quarto_actiu.id= balaParcial.estado_preparacion_quarto.quarto_id;
              this.quarto.bales_parcials = balaParcial.estado_preparacion_quarto.balas_parciales_totales;
              this.quarto.bales_parcials_consumides = parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_parcialmente_consumidas)
              + parseInt(balaParcial.estado_preparacion_quarto.balas_parciales_consumidas);
              this.quarto.bales_completes = balaParcial.estado_preparacion_quarto.balas_completas_totales;
              this.quarto.bales_completes_consumides = balaParcial.estado_preparacion_quarto.balas_completas_consumidas;
              this.mesclada.bales_totals= balaParcial.estado_preparacion_mezclada.balas_totales;
              this.mesclada.bales_consumides= balaParcial.estado_preparacion_mezclada.balas_consumidas;
              this.mesclada.quartos_totals = balaParcial.estado_preparacion_mezclada.cuartos;
              this.mesclada.quartos_completats = balaParcial.estado_preparacion_mezclada.cuartos_iniciados;
              this.mesclada.id = balaParcial.estado_preparacion_mezclada.mezclada_id;
              // this.getMaquinaInfo(this.num_maquina).then(info=>{
              //   if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1"){
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual})
              //   } else if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
              //   {
              //     this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual})
              //   }
              // })
              if(balaParcial.cuarto_finalizado && balaParcial.estado_preparacion_mezclada.balas_totales != balaParcial.estado_preparacion_mezclada.balas_consumidas){
                    this.dialogCambioQuarto = true; 
                    this.borrarQuartosMezclando(this.num_maquina); 
              } else if(balaParcial.estado_preparacion_mezclada.balas_totales == balaParcial.estado_preparacion_mezclada.balas_consumidas){
                this.dialogFinMezclada = true;
                this.borrarQuartosMezclando(this.num_maquina); 
                this.quarto_actiu={id:0, quarto:0};
                this.quarto= {
                bales_pendents: 0,
                bales_completes: 0,
                bales_completes_consumides: 0,
                bales_parcials: 0,
                bales_parcials_consumides: 0} 
                this.mesclada= {
                  bales_totals: 0,
                  bales_consumides: 0,
                  quartos_totals: 0,
                  quartos_completats: 0,
                  quartos: [], 
                  id: 0 
                };} 
              }
      }),1000)
      }
      localStorage.removeItem('datos')
      localStorage.removeItem('quarto')
    },
    AssignarBala(){
      this.dialogError1=false;
      this.dialogAssignarQuarto = false;
      const parcialKG = parseFloat(
        parseFloat(this.bales_parcials_carregades.quilos) / this.quartos.length
      ).toFixed(2);
      this.quartos.forEach(quarto => {
        const novaBala = {
          bala: this.bales_parcials_carregades.id,
          barcode: this.bales_parcials_carregades.barcode,
          quilos: parseInt(parcialKG),
          quarto: quarto,
          material_proveidor: this.textMateriaPrimeraProveidor(
            this.bales_parcials_carregades.materia_primera
          ),
          ingredient: 1,
          ordre_fabricacio: this.mesclada_activa.id
          // ingredient: this.bales_parcials_carregades.ingredient
        };
        this.crearBalaParcial(novaBala);
      });
    },
    vaciarMaquina(){
      if(this.switch1)
      {this.getMaquinaInfo(this.num_maquina).then(info=>{
        this.maquina_info = info;
        info.cuartos.forEach(el=> {
        if(el.estat=="1"){
        this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: el.cuarto_actual}).then(
          response => {
          if(response) this.finalizarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: el.cuarto_actual})}
        )}
        if(el.estat=="-"){
          this.finalizarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: el.cuarto_actual})
        }
        })
        if(this.mesclada.bales_totals == this.mesclada.bales_consumides){
          this.canviarMaquina();
          this.quarto.bales_pendents=0;
        }
        })
        }else{
        this.getMaquinaInfo(this.num_maquina).then(info=>{
        this.maquina_info = info;
          if(info.cuartos[0].estat == "0" && info.cuartos[1].estat == "1"){
            this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual})
          } else if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "0")
          {
            this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual})
          } else if(info.cuartos[0].estat == "-" && info.cuartos[1].estat == "1")
          {
            this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
            response=>{
            if(response) this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual})}
            )
          } else if (info.cuartos[0].estat == "1" && info.cuartos[1].estat == "-")
          {   
            this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual}).then(
              response=>{
              if(response)  this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual})}
              )
           } else if(info.cuartos[0].estat == "1" && info.cuartos[1].estat == "1") {
             this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
              response => {
              if(response) this.finalizarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[0].cuarto_actual})}
        )

           }
        })
        if(this.mesclada.bales_totals == this.mesclada.bales_consumides){
          this.canviarMaquina();
          this.quarto.bales_pendents=0;
        }
        this.quarto_actiu ={id:0, quarto:0};
        }
    },
    btnForzarVaciado(){
      this.dialogError = true;
      this.msg_error = "ESTÁS SEGUR QUE VOLS FORÇAR\n EL BUIDAMENT DE LA MÁQUINA"+ `${this.num_maquina} ?`
      this.botonForzar = true;
    },
    forzarVaciado(){
      this.dialogError = false;
      this.getMaquinaInfo(this.num_maquina).then(info=>{
        info.cuartos.forEach(el=>{
          if(el.estat=="-") {
            this.finalizarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: el.cuarto_actual})
          }
        }
        )
        if(info.cuartos[0].estat =="1"){
         this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[0].cuarto_actual}).then(
              response => {
              if(response) this.finalizarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[0].cuarto_actual})}
        )
          }
         if(info.cuartos[1].estat =="1"){
         this.iniciarEjecucionQuarto({num_maquina: this.num_maquina, quarto_id: info.cuartos[1].cuarto_actual}).then(
              response => {
              if(response) this.finalizarEjecucionQuarto({num_maquina: this.num_maquina,quarto_id: info.cuartos[1].cuarto_actual})}
        )
          }
      })
    },
    canviarMaquina(){
      this.getMaquinaInfo(this.num_maquina).then(info=>{this.maquina_info = info;
      info.cuartos.forEach(el=> {
        if(el.estat=="+"){
        this.getQuartoMesclat(el.cuarto_actual).then(quarto=>{
          this.quarto.bales_pendents = quarto.porcions_mesclat
              .filter(balaPendent => balaPendent.estat==1 );
          this.quarto_actiu.id=quarto.id; 
          this.quarto_actiu.quarto = quarto.quarto;
          this.mesclada_activa = this.mesclades.find(mesclada => mesclada.id == quarto.ordre_fabricacio);
          if(quarto.ordre_fabricacio)
          {
            this.getEstadoMesclada(quarto.ordre_fabricacio).then(estado=>{
            this.mesclada.bales_totals= estado.balas_totales;
            this.mesclada.bales_consumides= estado.balas_consumidas;
            this.mesclada.quartos_totals = estado.cuartos;
            this.mesclada.quartos_completats = estado.cuartos_mezclados;
          })
          }
          }
        )}else{
          this.quarto_actiu.id=0; 
          this.quarto_actiu={id:0, quarto:0};
          this.quarto= {
          bales_pendents: 0,
          bales_completes: 0,
          bales_completes_consumides: 0,
          bales_parcials: 0,
          bales_parcials_consumides: 0, 
          };
          this.quarto_actiu.quarto = 0;
          this.mesclada_activa = "";
          this.mesclada.bales_totals= 0;
          this.mesclada.bales_consumides= 0;
          this.mesclada.quartos_totals = 0;
          this.mesclada.quartos_completats = 0;
        }
        })
        })
    },
    canviarQuarto(){
      // this.vaciarMaquina();
      this.dialogCambioQuarto = false;
      if(this.switch1){
        this.quarto_actiu={id:0, quarto:0};
      }
        this.quarto_actiu={id:0, quarto:0};
      this.quarto= {
      bales_pendents: 0,
      bales_completes: 0,
      bales_completes_consumides: 0,
      bales_parcials: 0,
      bales_parcials_consumides: 0, 
      };
      if(this.mesclada.bales_totals == this.mesclada.bales_consumides){
          this.canviarMaquina();
          this.quarto.bales_pendents=0;
        }
      this.$nextTick(() => this.focusCodi());
      


    },
    bala_esta_seleccionada(i) {
      return this.quartos.quarto==i.quarto;
    },
    textMateriaPrimeraProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = this.proveidorOpcions.find(
        el => el.id === materiaPrimera?.proveidor
      );

      if (mpProveidor) return materiaPrimera?.nom + " - " + mpProveidor?.nom;
      return materiaPrimera?.nom;
    },
    textMaquina(num){
      return `Maquina nº ${num}`
    },
    textMesclada(id_of) {
      return this.mesclades.find(el => el.id === id_of)?.codi.replaceAll('.', '')
    },
    clickQuarto(quarto) {
      this.quartos = quarto;
    },
    elegirQuarto(){
    },
    cambioDialogAssignar(){
      this.quartosAssignar = [];
      if(this.dialogAssignarQuarto == true){
      this.dialogError1 = true;
      this.dialogAssignarQuarto = false;
      }else{
      this.quartos = [this.quarto_actiu.quarto];
      this.dialogError1 = false;
      this.dialogAssignarQuarto = true; 
      }
    },
    Cuartos_i_bales_per_cuarto(){
      var quartos =[];
      var bales_per_quarto = [];
      this.mesclada_activa.porcions_mesclat.forEach(el => {
        if(!quartos.includes(el.quarto)){
          quartos.push(el.quarto);
          bales_per_quarto.push(1);
        } else {
          bales_per_quarto[quartos.indexOf(el.quarto)] +=1 ;
        }
      })
      this.mesclada.quartos = quartos;
      this.mesclada.quartos_totals = quartos.length;
      this.mesclada.bales_per_quarto = bales_per_quarto;
    },
    finalitzarQuarto() {
      //console.log("Finalitza");
      this.dialogFinalitzarQuarto = false;

      this.forzarLlenado ({num_maquina: this.num_maquina, quarto_id: this.quarto_actiu.id}).then(
        response => {
          if (response.status){
          this.borrarQuartosMezclando(this.num_maquina); 
          this.mesclada.bales_consumides= response.estado_preparacion_mezclada.balas_consumidas;
          this.mesclada.quartos_completats = response.estado_preparacion_mezclada.cuartos_completados;
          this.quarto_actiu={id:0, quarto:0};
          this.quarto= {
          bales_completes: 0,
          bales_completes_consumides: 0,
          bales_parcials: 0,
          bales_parcials_consumides: 0, 
          };
          this.canviarMaquina()}}
      );
      this.$nextTick(() => this.focusCodi());
    },

    // Auxiliars
    mostrarBalesPendents() {
      console.log("mostrarBalesPendents");
      this.dialogMostrarBales=true;
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    if(localStorage.getItem('datos')) {this.comprobarLocal=  true }
      else {this.comprobarLocal= false}
  this.getMesclades();
  this.getMateriesPrimeres();
  this.getProveidors();
  this.getIngredients();
  this.getMaquinas().then(
    response=> 
    {response.results.forEach(el=>{
      el.cuartos.forEach(cuarto=>
      {this.maquinasTodo.push({maquina: el.numero ,quarto_id: cuarto.cuarto_actual, of: cuarto.ordre_fabricacio});
      this.quartosMaquinas.push(cuarto.cuarto_actual)
      if(cuarto.estat == "+")
      {
        this.getQuartoMesclat(cuarto.cuarto_actual).then(quartoMesclat=>
        this.setQuartosMezclando({num_maquina: el.numero, quarto: cuarto.cuarto_actual, 
        mesclada: quartoMesclat.ordre_fabricacio}))
        }}
      )
      
    })}
  );
  },
  mounted(){
    this.$refs.campBarcode.focus();
    this.canviarMaquina();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  padding-bottom: 1em;
}

h1,
h2,
h3,
h3,
h4,
p,
span,
strong {
  font-size: 2em;
}

footer > div > span {
  font-size: 1em !important;
}

// General
#boton-quarto-espera{
  width: 70%;
}
#punt-omplir-quartos {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4vh;
  #container {
    height: 100%;
    width: 100%;
  }
}
#distribucio {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    height: 100%;
  }

  #quartos {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5, 20%);

    .quarto {
      width: 90%;
      height: 150px;
      // margin: 0.5em 0em;
      margin-bottom: 10%;
      margin-top: 10%;
      background: rgb(187, 187, 187);
      border-radius: 10px;
      color: white;
      text-align: center;
      padding-top: 15%;
      font-size: 60px;
      overflow: hidden;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .bales-info {
        text-align: center;
        overflow-y: scroll;
        height: 100%;
        padding-bottom: 1em;
      }

      h3 {
        margin-left: 1em;
      }
    }
    .quarto-seleccionat {
      background: rgb(245, 216, 112);
      color: rgb(0, 0, 83);
    }
  }

  #info {
    width: 40%;
  }
}
// Títols
#quarto-titol,
#mesclada-titol {
  display: flex;
  justify-content: center;
  font-size: 2.5vw !important;
  h3 {
    position: relative;
    top: 16px;
    background: white;
    border: 1px solid black;
    padding: 0.5vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

// Row central


// Bloc esquerra
#info-quarto-bales {
  font-size: 2vw !important;
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  height: auto;
  // min-height: 500px;
  display: flex;
  flex-direction: column;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;
      
      * {
        margin: auto 0;
      }
    }
  }
  .separador {
    width: 100%;
    height: 2vh;
  }
}

// Bloc dreta
#info-mesclada-bales {
  font-size: 2vw !important;
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  height: auto;
  margin-bottom: 2vh;
  // min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      background: white;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
  .separador {
    width: 100%;
    height: 2vh;
  }
}
.text-mostrar-bales {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div:first-child {
    width: 20%;
  }
  div:nth-child(2) {
    width: 60%;
  }
  div:nth-child(3) {
    width: 20%;
  }
}
// Botonera
#botonera {
  padding-bottom: 1vh;
  padding-top: 1vh;
  width: 100%;
  justify-content: center;
  background: rgb(211, 211, 211) !important;
  border: none !important;

  button:first-child {
    width: 45vw ;
    border-radius: 5px;
    height: 12vw;
    // font-size: 28px;
    margin-right: 10%;
  }
  button:nth-child(2) {
    width: 30vw;
    border-radius: 5px;
    height: 12vw;
    // font-size: 28px;
  }
}
</style>