<template>
  <v-container>
    <!-- Llistat Plantilles -->
    <v-simple-table class="mt-12">
      <thead>
        <h1>Llistat de Plantilles  <v-btn color="success" dark @click="redireccio('PlantillaCreate')">+</v-btn></h1>
      </thead>
      <!-- Filtres -->
      <v-row>
        <v-col sm="3" offset-sm="2">
          <v-autocomplete
            v-model="filtre.producte_final"
            :items="productesFinals"
            @input="filtrar()"
            no-data-text
            outlined
            rounded
            item-text="nom"
            item-value="id"
            clearable
            label="Producte final"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" offset-sm="2">
          <v-autocomplete
            v-model="filtre.ingredient"
            :items="ingredientsOpcions"
            @input="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="nom"
            label="Ingredient"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody>
        <tr v-for="(plantilla, index) in plantillesFiltrades" :key="index">
          <td
            @click="plantillaDetail(plantilla.id)"
            class="text-plantilla"
          >{{ textPlantilla(plantilla) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Plantilles -->
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="plantillesFiltrades.length > 0"
    ></v-pagination>
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer absolute class="font-weight-medium">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "plantilla-llistat",
  data: () => ({
    filtre: {
      nom: "",
      ingredient: null
    },
    modalData: false,
    pagina: 1,
    plantillesFiltrades: []
  }),
  computed: {
    ...mapGetters({
      ingredientsOpcions: "getIngredients",
      plantilles: "getPlantilles",
      plantillesOpcions: "getPlantilles",
      productesFinals: "getMateriesProducteFinal", 
      totalPagines: "getPlantillesPaginacio"
    })
  },
  methods: {
    ...mapActions(["getIngredients", "getPlantilles", "getMateriesProducteFinal"]),

    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      /* Filtrat manual */
      // Filtre per nom
      if (this.filtre.nom) {
        this.plantillesFiltrades = this.plantilles.filter(item =>
          item.nom.includes(this.filtre.nom)
        );
      } else this.plantillesFiltrades = this.plantilles;
      if (this.filtre.producte_final) {
        this.plantillesFiltrades = this.plantilles.filter(item =>
          item.producte_final == this.filtre.producte_final
        );
      } else this.plantillesFiltrades = this.plantilles;
      // Filtre per ingredient
      if (this.filtre.ingredient ) {
        this.plantillesFiltrades = this.plantillesFiltrades.filter(item => {
          let arrayIngredients = this.textIngredients(item);
          if (
            arrayIngredients
              .join()
              .toLowerCase()
              .includes(this.filtre.ingredient.toLowerCase())
          )
            return true;
          return false;
        });
      }
    },

    // Rerouting
    plantillaDetail(plantillaID) {
      this.$router.push({
        name: "PlantillaDetail",
        params: { id: plantillaID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },

    // Auxiliars
    textPlantilla(plantilla) {
      let arrayIngredients = this.textIngredients(plantilla)
      
      if (arrayIngredients)
        return plantilla?.nom + " - " + this.textProducteFinal(plantilla.producte_final) + " - [" + arrayIngredients.join(" - ") + "] ";
      return plantilla?.nom;
    },
    textProducteFinal(id_producte){
      return this.productesFinals.find(el => el.id === id_producte)?.nom
    },
    textIngredients(plantilla) {
      if (plantilla?.percentatges)
        return plantilla.percentatges.map(percentatge => this.ingredientsOpcions.find(ingredient => ingredient.id === percentatge.ingredient)).map(el => el.nom)
      return "";
    }
  },
  watch: {
    plantilles: function(plantilles) {
      this.plantillesFiltrades = plantilles;
    }
  },

  mounted() {
    this.getIngredients();
    this.getPlantilles();
    this.getMateriesProducteFinal();
  }
};
</script>
<style lang="scss" scoped>
thead > h1 {
  text-align: center;
}
.text-plantilla {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>