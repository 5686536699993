<template>
  <v-container>
    <!-- Fàbrica -->
    <v-simple-table class="mt-12">
      <thead>
        <h1>Vista detallada de maquina</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Número</b>
          </td>
          <td>{{ maquina.numero }}</td>
        </tr>
        <tr>
          <td>
            <b>Fàbrica</b>
          </td>
          <td>{{ textFabrica(maquina.fabrica) }}</td>
        </tr>
        <tr v-if="maquina.partida_actual && maquina.ordreFabricacio">
          <td>
            <b>Partida eixint</b>
          </td>
          <td>{{ maquina.ordreFabricacio.codi.replaceAll('.', '') }}</td>
        </tr>
        <tr v-if="maquina.cuarto_actual && maquina.ordreFabricacio">
          <td>
            <b>Quarto eixint</b>
          </td>
          <!-- 
          <td>{{ textQuarto(maquina.cuarto_actual) }}</td> -->
          <td>{{ maquina.cuarto_actual }} </td> 
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table class="mt-12">
      <thead>
        <h1>Estat quartos</h1>
      </thead>
      <hr>
      <tbody v-for="quarto in maquina.cuartos" :key="quarto.id">
        <tr>
          <td>
            <b>Numero quarto</b>
          </td>
          <td>{{ quarto.numero }}</td>
        </tr>
        <tr>
          <td>
            <b>Estat quarto</b>
          </td>
          <td>{{ textEstat(quarto.estat) }}</td>
        </tr>
        <tr>
          <td>
            <b>Quarto Actual</b>
          </td>
          <td>{{ quarto.cuarto_actual}}  </td>
        </tr>
        <tr >
         <td style="
            display: inline; height: auto;"> <v-btn dark @click="vaciarQuarto(quarto.cuarto_actual)"> VACIAR CUARTO </v-btn> <v-btn @click="iniciarQuarto(quarto.cuarto_actual)"> INICIAR CUARTO </v-btn> <v-btn @click="iniciarPartidaSalida()"> INICIAR PARTIDA </v-btn> </td>  
        </tr>
        <hr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <!-- Dialog/Modal per editar maquina -->
      <v-dialog v-model="dialogEditMaquina" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark @click="btnDialogMaquina" v-on="on">Editar MÀQUINA</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Editar MÀQUINA</v-card-title>
          <v-container>
            <v-form id="formMaquina" ref="formMaquina" class="mt-12">
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="maquina_edit.numero" label="Numero" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field v-model="maquina_edit.fabrica" label="Fabrica" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogEditMaquina = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="btneditMaquina"
              :disabled="!maquina_edit.numero"
            >Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per editar màquina -->
      <!-- Dialog/Modal per eliminar màquina -->
      <v-dialog v-model="dialogeliminarMaquina" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar MÀQUINA</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar màquina</v-card-title>
          <v-card-text>Està segur que vol eliminar esta màquina?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogeliminarMaquina = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btneliminarMaquina">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar màquina -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Fàbrica -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('MaquinaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "maquina-detail",
  data: () => ({
    maquina: {
      nom: "",
      prefix: "",
    },
    maquina_edit: {
      nom: "",
      prefix: "",
    },
    dialogEditMaquina: false,
    dialogeliminarMaquina: false
  }),
  computed: {
    ...mapGetters({
      fabriques: "getFabriques"
    })
  },
  methods: {
    ...mapActions(["editMaquina","iniciarPartidaSalida", "iniciarEjecucionQuarto", "cancelarMezclaCuarto", "eliminarMaquina", "getMaquina", "getFabriques", "getMesclada"]),

    // Botonera
    btnDialogMaquina() {
      this.maquina_edit = JSON.parse(JSON.stringify(this.maquina));
    },
    btneditMaquina() {
      this.editMaquina(this.maquina_edit).then(response => {
        if (response === 200) {
          EventBus.$emit("Maquina.Notificacio", {
            text: "Maquina editat correctament",
            color: "success"
          });
          this.dialogEditMaquina = false;
          this.getMaquina(this.maquina.id).then(
            maquina => (this.maquina = maquina)
          );
        } else
          EventBus.$emit("Maquina.Notificacio", {
            text: "Error editant la màquina",
            color: "error"
          });
      });
    },
    iniciarQuarto(quarto){
      this.iniciarEjecucionQuarto({num_maquina: this.maquina.numero, quarto_id: quarto}).then(
          response=>{if(response){
            this.$router.go() 
          }}

        )
    },
    vaciarQuarto(quarto){
      this.cancelarMezclaCuarto({quarto_id: quarto}).then(
          response=>{if(response){
            //this.$router.go() 
          }}

        )
    },
    iniciarPartidaSelect(){
      this.iniciarPartidaSalida({id: this.maquina.partida_actual, num_maquina: this.maquina.numero});
      
    },
    textEstat(estat){
      if(estat == 0){
        return "Vacío"
      } else if (estat == "+"){
        return "Llenando"
      } else if(estat == "-"){
        return "Vaciando"
      } else if (estat == 1){
        return "Lleno"
      }
    },
    textFabrica(fabricaID) {
      return this.fabriques.find(el => el.id === fabricaID)?.nom;
    },
    textQuarto(quartoID){
      if(this.maquina.ordreFabricacio)
     {return this.maquina?.ordreFabricacio.quartos_mesclat.find(el=> el.id === quartoID)?.quarto}
    },
    btneliminarMaquina() {
      this.eliminarMaquina(this.maquina.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Maquina eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "MaquinaList" });
        } else
          EventBus.$emit("Fabrica.Notificacio", {
            text: "Error eliminant la màquina",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getMaquina(this.$route.params.id).then(
      maquina => {(this.maquina = maquina)
      if(maquina.partida_actual)
      {this.getPartida_new(maquina.partida_actual).then(
      mesclada => {this.maquina.ordreFabricacio = mesclada;
      this.maquina.ok=true;}
      )}else{
        this.maquina.ok = true;
      }
      }
    );
    this.getFabriques();
    
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    margin-bottom: 15px;
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        padding-top: 12px;
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 45%;
  }
}
</style>