<template>
  <v-container>
    <!-- Llistat Bales -->
    <v-simple-table class="mt-12">
      <thead>
        <td>
        <h1>Buscar Bala</h1>
        </td>
        <td>
        <v-btn-toggle v-model="balaType" mandatory rounded color="primary">
        <v-btn>
          Bala de materia prima
        </v-btn>

        <v-btn>
          Bala de materia final
        </v-btn>
        </v-btn-toggle> 
        </td>

      </thead>
      <!-- Filtres -->

      <!-- Si es Materia Prima -->
      <div v-if="!balaType">

      <v-row class="botones">

        <!-- Filtro Quilos -->
        <v-col sm="2">
          <v-text-field outlined rounded v-model="filtre.quilos" label="Quilos"  required></v-text-field>
        </v-col>

        <!-- Filtro Materia prima -->
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.materia_primera__nom"
            :items="materiesPrimeresNomsSenseDuplicarOpcions"
            
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="id"
            label="Materia primera"
          ></v-autocomplete>
        </v-col>
        
        <!-- Filtro Proveedor -->
        <v-col sm="2">
          <v-autocomplete
            v-model="filtre.proveidor"
            :items="proveidorOpcions"
            
            no-data-text
            clearable
            rounded
            outlined
            item-text="nom"
            item-value="id"
            label="Proveïdor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
            <v-btn block rounded large x-large color=primary @click="filtrar()" >Buscar</v-btn>
        </v-col>

      </v-row> 
      </div>
      <!-- Si es Materia Final -->
      <div v-if="balaType">

        <v-row class="botones">

        <!-- Filtro Quilos -->
        <v-col sm="2">
            <v-text-field outlined rounded v-model="filtreBorra.quilos" label="Quilos"  required></v-text-field>
        </v-col>

        <!-- Filtro Partida -->
        <v-col sm="2">
          <v-autocomplete
            v-model="filtreBorra.partida"
            :items="partidaOpcions"
            no-data-text
            clearable
            outlined
            rounded
            item-text="codi"
            item-value="id"
            label="Partida"
          ></v-autocomplete>
        </v-col>

        <!-- Filtro Tipo de materia final -->
        <v-col sm="2">
          <v-autocomplete
            v-model="filtreBorra.tipus_borra"
            :items="producte_finalOpcoions"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Producte final"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2">
            <v-btn block rounded large x-large color=primary @click="filtrarBorres()" >Buscar</v-btn>
        </v-col>
        </v-row>
      </div>

      
      <!-- ./Filtres -->
      
      <!-- Listado de Materia Prima -->
      <tbody v-if="!balaType && visible">
      <div v-if="bales.length==0">

          <v-subheader class="noBalas" 
          >No s'ha trobat cap bala amb aquestes especificacions.</v-subheader>

      </div>
        <tr v-for="(bala, index) in bales" :key="index" :class="{'bala-seleccionat': bala.estat==2,'bala-consumida': bala.estat==4, 'bala-pendent': bala.impresa==false && bala.estat!=2 && bala.estat!=4 && bala.estat!=6 && bala.estat!=5 && bala.estat!=3} ">
          <td @click="balaDetail(bala.id)" class="text-bala"><strong> {{textNomBala(bala)}} </strong> {{ textBala(bala) }} 
          <img
          v-if="bala.estat==2"
          src="https://cdn.pixabay.com/photo/2014/04/02/10/44/warning-sign-304370_960_720.png"
          alt
          />
          <img
          v-if="bala.estat==3 || bala.estat==5"
          src="https://cdn.pixabay.com/photo/2013/07/12/12/29/arrow-145781_960_720.png"
          alt
          />
          <img
          v-if="bala.impresa==false && bala.estat!=2 && bala.estat!=4 && bala.estat!=6 && bala.estat!=5 && bala.estat!=3"
          src="https://cdn.pixabay.com/photo/2017/06/26/00/46/flat-2442462_960_720.png"
          alt
          />

          </td>
        </tr>
      </tbody>

      <!-- Listado de Materia Final -->
        <tbody v-if="balaType && visible1">
          <div v-if="borres.length==0">

          <v-subheader class="noBalas" 
          >No s'ha trobat cap bala amb aquestes especificacions.</v-subheader>

      </div>
        <tr v-for="(bala, index) in borres" :key="index">
          <td @click="borraDetail(bala.id)" class="text-bala"> <strong>{{textNomBorra(bala)}}
              </strong>{{textBorra(bala)}}
          </td>
          <!-- <td> {{bala.id}}</td> -->
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Llistat Bales -->
    <!-- Paginació -->

    <!-- Paginació Bales MP -->
    <div v-if="!balaType && visible"> 
    <v-pagination 
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="bales.length > 0"
    ></v-pagination>
    </div> 

    <!-- Paginació Bales MF -->
<div v-if="balaType && visible1"> 
    <v-pagination 
      v-model="pagina"
      :length="totalPaginesBorres"
      @input="paginarBorres()"
      v-if="borres.length > 0"
    ></v-pagination>
    </div> 
    
    <!-- ./Paginació -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('Home')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import downloadexcel from "vue-json-excel";

export default {
  name: "bala-llistat",
  data: () => ({
    OpcionesSubalmacenes:[],
    balaType: undefined,
    filtre: {
      materia_primera__nom: null,
      proveidor: null,
      quilos: "",
      pagina: null, 
    },
    filtreBorra: {
      quilos: "",
      partida: null,
      tipus_borra: null,

    },
    estatOpcions: [
      { id: 1, nom: "Sin asignar" },
      { id: 2, nom: "Asignanada a Mesclada" },
      { id: 3, nom: "Mezclando" },
      { id: 4, nom: "Mezclada" },
      { id: 5, nom: "Asignada a OE" },
      { id: 6, nom: "Expedida" }
    ],

    paginasAñadidas:[],
    json_fields: {
        BARCODE: "barcode",
        POSICION_ALMACENAMIENTO: "posicion_almacenamiento", 
        QUILOS: "quilos", 
        IMPRESA: {
          field: "impresa",
          callback: value => (value ? "Sí" : "No")
        },
        ESTAT: {
          field: "estat",
          callback: value => (value==4 ? "Sí" : value==3 ? "En procés" : "No")
        },
        MATERIA_PRIMREA: "materia_primera_nom", 
        PREU_QUILO: {
          field: "preu_quilo", 
          callback: value =>(parseFloat(value).toFixed(2))
        },
        PROVEIDOR: "proveidor_nom", 
        DATA_CREACIO: "creada_en", 
        OBSERVACIONS: "observacions"
      },
    excelArray: [],
    nombreExcel: "Llistat_Bales.xls",
    
    modalData: false,
    balesFiltrades: [],
    pagina: 1,
    carregaInicial: false,
    visible: false,
    visible1: false
    // totalPagines: 1
  }),
//   components:{
//     downloadexcel
//   },
  computed: {
    ...mapGetters({
      bales: "getBales",
      totalPagines: "getBalesPaginacio",
      ingredientsOpcions: "getIngredients",
      magatzemOpcions: "getMagatzems",
      materiesPrimeresOpcions: "getMateriesPrimeres",
      materiesPrimeresNomsSenseDuplicarOpcions:
        "getMateriesPrimeresNomsSenseDuplicarCap",
      proveidorOpcions: "getProveidors",
      tipoOpcions: "getTipusMateria",
      grupo: "getGroup",
      partidaOpcions: "getPartidesTodas",
      producte_finalOpcoions: "getMateriesProducteFinal",
      borres: "getBorres",
      totalPaginesBorres: "getBorresPaginacio",

    })
  },
  methods: {
    ...mapActions([
      "getBales",
      "getBalesFiltrades",
      "getIngredients",
      "getMagatzems",
      "getMateriesPrimeres",
      "getMateriesPrimeresNomsSenseDuplicar",
      "getProveidors",
      "getTipusMateria",
      "getMateriesProducteFinal",
      "getPartides",
      "getPartidesTodas",
      "getBorres",
      "getBorresFiltrades"
    ]),
    // Filtrat
    filtrar() {
      this.pagina = 1;
      this.filtre.pagina = 1;
      this.getBalesFiltrades(this.filtre);
      this.visible = true
      this.visible1 = false

    },
    cambiarBalas(){
        console.log("cambia")
    },
    filtrarBorres(){
        this.pagina = 1;
        this.filtre.pagina = 1;
        this.getBorresFiltrades(this.filtreBorra);
        this.visible1 = true;
        this.visible = false;

    },

    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      this.getBalesFiltrades(this.filtre);
    },
    paginarBorres() {
      this.filtreBorra.pagina = this.pagina;
      this.getBorresFiltrades(this.filtreBorra);
    },

    // Rerouting
    balaDetail(balaID) {
      this.$router.push({ name: "BalaDetail", params: { id: balaID , pagina: this.filtre.pagina} });
    },
    borraDetail(balaID) {
      this.$router.push({ name: "PartidaBalaDetail", params: { id: balaID, list:true} });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    },
    // getExcelData(){
    //   this.excelArray.forEach(elem=> 
    //   {elem.materia_primera_nom = this.textMateriaPrimera(elem.materia_primera);
    //   elem.proveidor_nom = this.textProveidor(elem.materia_primera)}
    //   )
    //   this.$refs.buttonGenerarExcel.$el.click();
    //   this.excelArray =[];
    // },
    // buidarExcel(){
    //   this.excelArray=[];
    //   this.paginasAñadidas = [];

    // },
    // addExcelData(){
    //   if(!this.paginasAñadidas.find(el=> el==this.pagina))
    //   {this.excelArray.push.apply(this.excelArray, this.bales); 
    //   this.paginasAñadidas.push(this.pagina);}
    // },
    // Auxiliars
    datepicker() {
      this.$refs.dialog.save(this.filtre.creada_en);
      this.modalData = false;
      this.filtrar();
    },

    datepickerDelete() {
      this.$refs.dialog.save((this.filtre.creada_en = ""));
      this.modalData = false;
      this.filtrar();
    },
    textNomBala(bala) {
      const textMP = this.textMateriaPrimera(bala.materia_primera);
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === bala.materia_primera
      );

      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }

      return `${textMP} - ${mpProveidor?.nom}`;
    },
    textBala(bala) {

      let textMagatzem;
      if (bala?.magatzem) {
        textMagatzem = this.magatzemOpcions.find(el => el.numero === 
        parseInt(bala.posicion_almacenamiento.substr(1, 3))
        )
          ?.nom;
        if(parseInt(bala.posicion_almacenamiento.substr(4, 2))>0){
              textMagatzem = textMagatzem + ' - Nº '+ bala.posicion_almacenamiento.substr(4, 2)
            }
      }

      if (textMagatzem === undefined) 
        {if(bala.estat==4){
          textMagatzem = "Consumida"
        } else if(bala.estat==6){
          textMagatzem = "Expedida"
        }else{
          textMagatzem = "Sense magatzem";
        }}
      

      return `
      [${parseInt(bala.quilos)} Kg] - ${textMagatzem} - ${bala.creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}`;
    },

    // Texto Borra

    //ARREGLAR ESTO
    textProducteFinal(ID) {
      const producteFinal = this.producte_finalOpcoions.find(
        el => el.id === ID
      );
      return producteFinal?.nom;
    },
    textNomBorra(bala){
      const textPF = this.textProducteFinal(bala.tipus_borra);
      return textPF
    },
    textBorra(bala) {
      // const textPF = this.textProducteFinal(bala.tipus_borra);
      let textMagatzem;
      if (bala?.magatzem) {
        textMagatzem = this.magatzemOpcions.find(el => el.numero === 
        parseInt(bala.posicion_almacenamiento.substr(1, 3))
        )
          ?.nom;
      }
      if (textMagatzem === undefined) textMagatzem = "Sense magatzem";

      return `- [${
        bala.quilos
    //   } Kg] - ${textMagatzem} - ${bala.creada_en.split("T")[0]}`;
      } Kg] - ${textMagatzem} - ${bala.barcode}`;
    },

    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return `${materiaPrimera?.nom} ${materiaPrimera.codigo_grs? ` - ${materiaPrimera.codigo_grs} `:''} ${ materiaPrimera?.nom_ingredient? ` - ${ materiaPrimera?.nom_ingredient} `:''}`;
    },
    textProveidor(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidorOpcions.find(
          el => el.id === materiaPrimera.proveidor
        );
      }
      return mpProveidor?.nom;
    }
  },
  created() {
    
    this.getBales();
    if(this.$route.params.pagina) {this.pagina = this.$route.params.pagina;
    this.filtre.pagina = this.$route.params.pagina;
    this.paginar();}
    
    this.getIngredients();
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesPrimeres();
    this.getMateriesPrimeresNomsSenseDuplicar();
    this.getProveidors();
    this.getTipusMateria();
    this.getPartidesTodas();
    this.getMateriesProducteFinal();
    this.getBorres();

    this.getBorresFiltrades(this.filtre);
    this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
    this.getMateriesProducteFinal();
    this.getPartidesTodas();
    
  },
  // watch: {
  //   bales: function(bales) {
  //     // TODO - Fix horrible, s'ha de vore com fer que carregue inicialment les bales en bales filtrades
  //     if (!this.carregaInicial) {
  //       this.balesFiltrades = bales;
  //       this.carregaInicial = true;
  //     }

  //     // Quan modifiquem bales (mitjançant petició de filtrat), actualitzem bales i paginació
  //     this.balesFiltrades = this.bales;
  //     // this.totalPagines = this.getBalesPaginacio;
  //   }
  // }
};
</script>
<style lang="scss" scoped>
thead > td {
  text-align: center;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
img {
  width: 15px;
}
.bala-seleccionat {
      background: rgb(241, 241, 153) ;
}
.bala-pendent {
      background: rgb(153, 188, 241) ;
}
.bala-consumida {
      background: rgb(241, 171, 153) ;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
#downloadexcel {
  display: none;
}
.botones{
  align-content: center;
  margin-left: 25%;
}
.noBalas{
  margin-left: 39.75%;
}
</style>