<template>
  <v-container>
    <!-- Llistat Materies Primeres -->
    <v-simple-table class="mt-12" style="padding-bottom: 25px">
      <thead>
        <v-row>
          <v-col>
            <h1>Llistat de Stock de Materies Primeres</h1>
            <h1 v-if="magatzem.nom">en {{ magatzem.nom }} - Nº {{magatzem.zona}}</h1>
            <h1 v-else> SENSE MAGATZEM</h1>
          </v-col>
        </v-row>
      </thead>
            <!-- Filtres -->
      <v-row>
        <v-col sm="3" v-if="$route.params.id!='Sense'">
          <v-autocomplete
            v-model="filtre.proveidor"
            :items="proveidors"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Proveïdor"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" v-if="$route.params.id!='Sense'">
          <v-autocomplete
            v-model="filtre.tipo"
            :items="tipoOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Composició"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" v-if="$route.params.id!='Sense'">
          <v-autocomplete
            v-model="filtre.ingredient"
            :items="ingredientsOpcions"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="id"
            label="Tipus materia"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            v-model="filtre.materia_primera"
            :items="ListaMaterias"
            @change="filtrar()"
            no-data-text
            clearable
            outlined
            rounded
            item-text="nom"
            item-value="nom"
            label="Materia Primera"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- ./Filtres -->
      <tbody v-if="magatzem.id!='Sense'" style="margin-bottom: 25px">
        <tr v-for="materiaPrimera in materiesPrimeres" :key="materiaPrimera.materia_primera_id">
          <td v-if="materiaPrimera.num_bales>0"
            class="text-instancia"
            @click="magatzemMateriaPrimeraList(magatzem.barcode, materiaPrimera.materia_primera_id)"
          >
            <v-row>
              <v-col
                sm="6"
              > <strong>{{ materiaPrimera.materia_primera }} {{textMateriaPrimera(materiaPrimera.materia_primera_id)}} </strong>
              - {{ textProveidor(materiaPrimera.materia_primera_id) }}</v-col>
              <v-col
                sm="6"
              >{{ materiaPrimera.num_bales === 1 ? '1 bala' : materiaPrimera.num_bales + ' bales' }}/ Disponibles: {{ materiaPrimera.num_bales_disponibles}} <br> {{materiaPrimera.quilos}} kg / Disponibles: {{ materiaPrimera.quilos_disponibles}}</v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
      <tbody v-if="$route.params.id=='Sense'">
        <tr v-for="(bala, index) in bales" :key="index">
          <td @click="balaDetail(bala.id)" class="text-bala"> <strong> {{textNomBala(bala)}} </strong> {{ textBala(bala) }} 
          <img
          v-if="bala.estat==4 || bala.estat==6"
          src="https://cdn.pixabay.com/photo/2014/04/02/10/44/warning-sign-304370_960_720.png"
          alt
          />
          <img
          v-if="bala.estat==3 || bala.estat==5"
          src="https://cdn.pixabay.com/photo/2013/07/12/12/29/arrow-145781_960_720.png"
          alt
          />

          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Paginació -->
    <v-pagination
      v-model="pagina"
      :length="totalPagines"
      @input="paginar()"
      v-if="bales.length > 0"
    ></v-pagination>
    <!-- ./Llistat Materia Primera -->

    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col
        class="text-center"
        cols="12"
        @click="redireccio('StockMateriaPrimeraPerMagatzemList')"
        style="padding:0px"
      >
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "stock-materia-primera-materies-primeres-llistat",
  data: () => ({
    ListaMaterias:[],
    bales:[],
    totalPagines:"",
    pagina:1,
    filtre:{
      proveidor:"", 
      materia_primera:""
    },
    magatzem: {
      id: null,
      nom: "",
      zona:""
    },
    materiesPrimeres: [], 
    materies:[]
  }),
  computed: {
    ...mapGetters({
      materiesPrimeresOpcions: "getMateriesPrimeres",
      proveidors: "getProveidors", 
      magatzems: "getMagatzems", 
      tipoOpcions: "getTipusMateria",
      ingredientsOpcions: "getIngredients",
      materiesPrimeresOpcionsFiltre:"getMateriesPrimeresNomsSenseDuplicarCap"
    })
  },
  methods: {
    ...mapActions([
      "getMagatzems",
      "getMagatzemMateriesPrimeres",
      "getMateriesPrimeres",
      "getProveidors",
      "getStockMP",
      "getMateriesPrimeresNomsSenseDuplicarCap", 
      "getMateriesPrimeresNomsSenseDuplicar", 
      "getMateriesPrimeresFiltrades",
      "getTipusMateria",
      "getIngredients",
      "getStockMP_filtrat", 
      "getBalesSenseMagatzem"
    ]),
    // Paginació
    paginar() {
      this.filtre.pagina = this.pagina;
      localStorage.setItem('filtre_stockMP',  JSON.stringify(this.filtre)); 
      this.getBalesSenseMagatzem({pagina:this.pagina, estat:1}).then(
        response=>(this.bales=response.result)
      )
    },
    filtrar(){ 
      localStorage.setItem('filtre_stockMP',  JSON.stringify(this.filtre)); 
      this.getStockMP_filtrat(this.filtre).then(data=>
    { 
      if(this.filtre.proveidor){
        this.ListaMaterias=[];
        this.getMateriesPrimeresFiltrades(this.filtre).then(
        response => response.forEach(el=> this.ListaMaterias.push(el.nom))
      )}else{
        this.getMateriesPrimeres()
        this.getMateriesPrimeresNomsSenseDuplicar().then(
          response => {if(response) this.ListaMaterias = response} 
        );
      }
      this.materiesPrimeres=[];
      for (const prop in data) {
        if(parseInt(this.$route.params.id))
         {
         this.magatzem.barcode = prop;
         var materies=[]
         for (const prop2 in data[prop].posicions){ 
         if(prop2 == this.$route.params.id)
         {
           data[prop].posicions[prop2].materies_primeres.forEach(materia=>
         { materies.push(materia.materia_primera_id)
             this.materiesPrimeres.push(materia)
            })
         }}
        }
        this.materies= materies
        if(this.filtre.materia_primera){
          this.materiesPrimeres = this.materiesPrimeres.filter(mat => mat.materia_primera == this.filtre.materia_primera )
        }
    }})
    if(this.filtre.materia_primera){
      this.materiesPrimeres = this.materiesPrimeres.filter(mat => mat.materia_primera == this.filtre.materia_primera )
    }
    },
    // Text
    textProveidor(materiaPrimeraID) {
      const proveidorID = this.materiesPrimeresOpcions.find(materiaPrimera => materiaPrimera.id === materiaPrimeraID)?.proveidor
      return this.proveidors.find(proveidor => proveidor.id === proveidorID)?.nom
    },
    balaDetail(balaID) {
      this.$router.push({ name: "BalaDetail", params: { id: balaID , sense: true} });
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
 

      return `${materiaPrimera.codigo_grs? ' '+materiaPrimera.codigo_grs: ''} ${materiaPrimera.nom_ingredient? 
                '- '+ materiaPrimera.nom_ingredient: ''}`;
    },
    textNomBala(bala) {
      const textMP = this.textMateriaPrimera(bala.materia_primera);
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === bala.materia_primera
      );

      let mpProveidor = "";
      if (materiaPrimera?.proveidor) {
        mpProveidor = this.proveidors.find(
          el => el.id === materiaPrimera.proveidor
        );
      }

      return `${textMP} - ${mpProveidor?.nom}`;
    },
    textBala(bala) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === bala.materia_primera
      );

      let codiGRS ="";
      if (materiaPrimera?.tipo) {
        codiGRS = this.tipoOpcions.find(
          el => el.id === materiaPrimera.tipo
        );
      }

      let textMagatzem;
      if (bala?.magatzem) {
        textMagatzem = this.magatzems.find(el => el.numero === 
        parseInt(bala.posicion_almacenamiento.substr(1, 3))
        )
          ?.nom;
        if(parseInt(bala.posicion_almacenamiento.substr(4, 2))>0){
              textMagatzem = textMagatzem + ' - Nº '+ bala.posicion_almacenamiento.substr(4, 2)
            }
      }

      if (textMagatzem === undefined) 
        {if(bala.estat==4){
          textMagatzem = "Consumida"
        } else if(bala.estat==6){
          textMagatzem = "Expedida"
        }else{
          textMagatzem = " ";
        }}
      

      return `${codiGRS?.nom ? `- [${codiGRS?.nom}] -` :` `} 
      [${bala.quilos} Kg] - ${bala.barcode} ${textMagatzem} - ${bala.creada_en.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}`;
    },


    // Rerouting
    magatzemMateriaPrimeraList(magatzemID, materiaPrimeraID) {
      this.$router.push({
        name: "StockMateriaPrimeraMagatzemMateriaPrimeraList",
        params: { magatzemID: this.$route.params.id, materiaPrimeraID: materiaPrimeraID }
      });
    },
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  created() {
    this.getIngredients();
    if(this.$route.params.id =='Sense'){
      this.getTipusMateria();
      this.getBalesSenseMagatzem({pagina:1, estat:1}).then(
        response=>{this.bales=response.result; 
        this.totalPagines=Math.ceil(response.count/20)}
      )
    }else{
    this.getTipusMateria();
    if(localStorage.getItem('filtre_stockMP')){
      this.filtre=JSON.parse(localStorage.getItem('filtre_stockMP'));
      this.getStockMP_filtrat(this.filtre).then(data=>
    { 
      if(this.filtre.proveidor){
        this.ListaMaterias=[];
        this.getMateriesPrimeresFiltrades(this.filtre).then(
        response => response.forEach(el=> this.ListaMaterias.push(el.nom))
      )}else{
        this.getMateriesPrimeres()
        this.getMateriesPrimeresNomsSenseDuplicar().then(
          response => {if(response) this.ListaMaterias = response} 
        );
      }
      this.materiesPrimeres=[];
      for (const prop in data) {
        if(parseInt(this.$route.params.id))
         {
         this.magatzem.barcode = prop;
         var materies=[]
         for (const prop2 in data[prop].posicions){ 
         if(prop2 == this.$route.params.id)
         {
          this.magatzem = data[prop]
           this.magatzem.barcode = prop;
           this.magatzem.zona = data[prop].posicions[prop2].zona
           data[prop].posicions[prop2].materies_primeres.forEach(materia=>
         { materies.push(materia.materia_primera_id)
             this.materiesPrimeres.push(materia)
            })
         }}
        }
        this.materies= materies
        if(this.filtre.materia_primera){
          this.materiesPrimeres = this.materiesPrimeres.filter(mat => mat.materia_primera == this.filtre.materia_primera )
        }
    }})
    if(this.filtre.materia_primera){
      this.materiesPrimeres = this.materiesPrimeres.filter(mat => mat.materia_primera == this.filtre.materia_primera )
    }
    }
    else{
    this.getStockMP().then(data=>
    {
      for (const prop in data) {
        if(parseInt(this.$route.params.id))
         {
         var materies=[]
         for (const prop2 in data[prop].posicions){ 
         if(prop2 == this.$route.params.id)
         {
           this.magatzem = data[prop]
           this.magatzem.barcode = prop;
           this.magatzem.zona = data[prop].posicions[prop2].zona
           data[prop].posicions[prop2].materies_primeres.forEach(materia=>
         {   materies.push(materia.materia_primera_id)
             this.materiesPrimeres.push(materia)
            })
         }}
        }
        this.materies= materies
    }})
    }
    }
    // this.getMagatzem(this.$route.params.id).then(
    //   magatzem => (this.magatzem = magatzem)
    // );
    // this.getMagatzemMateriesPrimeres(this.$route.params.id).then(
    //   materiesPrimeres => (this.materiesPrimeres = materiesPrimeres)
    // );
    this.getMateriesPrimeres();
    this.getMateriesPrimeresNomsSenseDuplicar().then(
      response => {if(response) this.ListaMaterias = response} 
    );
    this.getMagatzems();
    this.getProveidors();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
}
.text-bala {
  text-align: center;
  font-size: 20px;
}
.text-instancia {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
}
th {
  text-align: right !important;
}
.row {
  margin: 0px;
}
.v-pagination {
  padding-bottom: 50px;
}
</style>