<template>
  <v-container>
    <!-- Bala -->
    <v-simple-table v-if="bala" class="mt-12">
      <thead>
        <h1>Vista detallada de Bala</h1>
      </thead>
      <tbody>
        <tr v-if="bala.barcode">
          <td>
            <b>Barcode</b>
          </td>
          <td>{{ bala.barcode }}</td>
        </tr>
        <tr v-if="bala.quilos">
          <td>
            <b>Quilos</b>
          </td>
          <td>{{ bala.quilos }} kg</td>
        </tr>
        <tr v-if="bala.preu_quilo">
          <td>
            <b>Preu quilo (€/kg)</b>

          </td>
          <td>{{ parseFloat(bala.preu_quilo).toFixed(3) }} €/kg</td>
        </tr>
        <tr v-if="bala.estat">
          <td>
            <b>Consumida</b>
          </td>
          <td>{{ textConsumida(bala.estat) }}</td>
        </tr>
        <tr></tr>
        <tr v-if="bala.materia_primera">
          <td>
            <b>Materia primera</b>
          </td>
          <td>{{ bala_text.materia_primera }}</td>
        </tr>
        <tr></tr>
        <tr v-if="bala.magatzem">
          <td>
            <b>Magatzem</b>
          </td>
          <td>{{ bala_text.magatzem }}</td>
        </tr>
        <tr v-if="bala.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ bala.observacions }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="success" dark @click="btnGenerarPegatina" v-if="!bala.impresa">Generar etiqueta</v-btn>
      <v-dialog v-model="dialogPegatina" persistent max-width="600" v-else>
        <template v-slot:activator="{ on }">
          <v-btn color="success" dark v-on="on">Generar etiqueta</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Etiqueta impresa</v-card-title>
          <v-card-text>Esta etiqueta està impresa</v-card-text>
          <v-card-text>Confirme que vol imprimir-la de nou.</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogPegatina = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnGenerarPegatina">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn color="primary" dark @click="btnEditarBala">Editar bala</v-btn>
      <!-- Dialog/Modal per eliminar bala -->
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar bala</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar bala</v-card-title>
          <v-card-text>Està segur que vol eliminar esta bala?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarBala">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar bala -->
    </div>
    <!-- Botons d'opcions -->
    <!-- ./Bala -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col
        class="text-center"
        cols="12"
        @click="redireccio('BalaAssignadaMagatzemList')"
        style="padding:0px"
      >
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "bala-assignada-magatzem-detall",
  data: () => ({
    bala: {
      quilos: "",
      preu_quilo: "",
      observacions: "",
      materia_primera: null,
      magatzem: null,
    },
    bala_text: {
      materia_primera: "",
      magatzem: ""
    },
    dialog: false,
    dialogPegatina: false
  }),
  methods: {
    ...mapActions([
      "eliminarBala",
      "getBala",
      "getMagatzem",
      "getProveidor",
      "getMateriaPrimera",
      "generarPegatinaBala"
    ]),

    // Botonera
    btnGenerarPegatina() {
      this.imprimirPegatinaBala_new({id: this.bala.id, info_impresion:{destino:this.bala.impresora, 
      request_id: localStorage.getItem('uuid')}})
      this.dialogPegatina = false;
    },
    btnEditarBala() {
      this.$router.push({ name: "BalaEdit", params: { id: this.bala.id } });
    },
    btnEliminarBala() {
      this.eliminarBala(this.bala.id).then(response => {
        if (response === 204) {
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "BalaList" });
        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error eliminant la bala",
            color: "error"
          });
      });
    },

    // Auxiliars
    redireccio(component) {
      console.log(this.bala.magatzem);
      this.$router.push({
        name: component,
        params: {
          id: this.bala.magatzem,
        }
      });
    },
    textConsumida(valor) {
      if (valor == 4) return "Sí";
      else if (valor == 1) return "No";
      else if (valor == 2 || valor == 3) return "En procés";
    }
  },
  created() {
    this.getBala(this.$route.params.id)
      .then(bala => (this.bala = bala))
      .then(() => {
        this.getMateriaPrimera(this.bala.materia_primera).then(
          materiaPrimera => {
            this.bala_text.materia_primera = materiaPrimera?.nom;
            this.getProveidor(materiaPrimera.proveidor).then(
              proveidor =>
                (this.bala_text.materia_primera += ` - ${proveidor?.nom}`)
            );
          }
        );
        if (this.bala.magatzem) {
          this.getMagatzem(this.bala.magatzem).then(magatzem => {
            this.bala_text.magatzem = magatzem?.nom;
            if(parseInt(this.bala.posicion_almacenamiento.substr(4, 2))>0){
              this.bala_text.magatzem = magatzem?.nom + ' - Nº '+ this.bala.posicion_almacenamiento.substr(4, 2)
            }
          });
        }
      });
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1 {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
      }
    }
  }
}
#botonera {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 30%;
  }
}
</style>