import { render, staticRenderFns } from "./ReservaEdit.vue?vue&type=template&id=3906dc88&scoped=true"
import script from "./ReservaEdit.vue?vue&type=script&lang=js"
export * from "./ReservaEdit.vue?vue&type=script&lang=js"
import style0 from "./ReservaEdit.vue?vue&type=style&index=0&id=3906dc88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3906dc88",
  null
  
)

export default component.exports