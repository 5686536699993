<template>
  <div id="punt-pesatge-bales">
    <div id="container">
      <!-- Escanejar bala -->
      <v-row>
        <v-col sm="8" >
              <div id="num-maquina">
                <span style=" background: none;" >
                  <strong style=" margin-left:5vw; margin-right:5vw; font-size:4vh ">Nº MÁQUINA</strong>
                  <v-select
                  style="border: none; background: none; height: 65px; font-size:18px; width:30mm"
                  v-model="dades_pa.num_maquina"
                  :items="maquinas"
                  item-value="id"
                  item-text="numero"
                  filled
                  single-line
                  no-data-text
                  chips
                  outlined
                  label="Nº"
                  @change="cargarMaquina"
                  :disabled="num_maquina_abled"
                ></v-select>
                <v-btn v-if="dades_pa.num_maquina" @click="cargarMaquina"> Recargar </v-btn>
                <v-btn v-if="dades_pa.num_maquina" @click="cargarMaquina_NO_LL"> NO LL </v-btn>
                </span>
              </div>
        </v-col>
        <v-col sm="4" >
          <div id="botonera">
            <v-btn x-large color="warning" dark @click="dialogInterrumpir = true" :disabled="mescladaSelect==0 || mescladaSelect==undefined || numeros_quartos_pendientes.length<1"  >Interrumpir <br>Partida</v-btn>
            <v-btn x-large color="error" dark @click="dialogFinalitzar = true" :disabled="mescladaSelect==0 || mescladaSelect==undefined || numeros_quartos_pendientes.length>=1">Finalizar <br>Partida</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogInterrumpir" persistent max-width="800">
        <v-card>
          <v-card-title class="headline">¿ESTÁS SEGURO QUE QUIERES INTERRUMPIR LA PARTIDA ACTUAL?</v-card-title>
          <v-card-text>¿Qué quieres hacer?</v-card-text>
          <v-card-actions>
            <v-btn color="red" dark @click="dialogInterrumpir = false">Ha sido un error, CONTINUAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="interromprePartida">INTERRUMPIR PARTIDA</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFinalitzar" persistent max-width="800">
        <v-card>
          <v-card-title class="headline">¿ESTÁS SEGURO QUE QUIERES FINALIZAR LA PARTIDA ACTUAL?</v-card-title>
          <v-card-title>Esta partida tiene:  {{dades_pa.bales_totals}} balas y {{dades_pa.kg_totals}} kg</v-card-title>
          <v-card-text>¿Qué quieres hacer?</v-card-text>
          <v-card-actions>
            <v-btn color="red" dark @click="dialogFinalitzar = false">Ha sido un error, CONTINUAR</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="finalitzarPartida">FINALIZAR PARTIDA</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogReimprimir" persistent max-width="600">
        <v-card>
          <v-card-title class="headline" style="justify-content: center">¿QUÉ BALA QUIERES IMPRIMIR?</v-card-title>
          <v-card-text><v-select
                  style="border: none; background: none; height: 65px; 
                  font-size:18px; width:300mm; margin-top:5px"
                  v-model="bala_a_imprimir"
                  :items="dades_pa.bales_productes_finals"
                  item-value="id"
                  filled
                  single-line
                  no-data-text
                  chips
                  outlined
                  @change="cargarBala"
                  label="Codi Bala"
                >
                <template slot="item" slot-scope="data">
                {{data.item.barcode}} - {{parseFloat(data.item.quilos).toFixed(0)}}kg
              </template>
              <template slot="selection" slot-scope="data">
                {{data.item.barcode}} - {{parseFloat(data.item.quilos).toFixed(0)}}kg
              </template>
              </v-select>
                <v-row>
                <v-col sm="12" v-if="bala_a_imprimir">
                  <v-text-field v-model="bala_cargada.quilos" label="Kilos" @focus="show2" @click="bala_cargada.quilos = ''" outlined required></v-text-field>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions style="justify-content: space-between">
            <v-btn color="red" dark @click="dialogReimprimir = false">CERRAR</v-btn>
            <v-btn color="normal" dark @click="editarBala">MODIFICAR E IMPRIMIR</v-btn>
            <v-btn color="green darken-1" dark @click="btn_reimprimirPegatina">IMPRIMIR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogError" max-width="600">
        <v-card>
          <v-card-title class="headline">{{msg_error}}</v-card-title>
          <v-card-actions>
            <v-btn color="primary" dark @click="dialogError = false">VOLVER</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Escanejar bala -->
      <!-- Dades -->
      <v-row id="dades" class="text-center">
        <!-- Informació Bala PA -->
        <v-col sm="6" class="text-center">
          <v-row id="info-bala-titol">
            <h3>Información bala {{ textQuarto(bala_pa.num_quarto) }}</h3>
          </v-row>
          <div id="info-bala-pa">
            <v-row>
              <div id="kg-bala" style="justify-content: left; margin-top: 25px">
                <strong>KG BALA</strong>
                <v-spacer style="border: none; height: 1px; width: 15px"></v-spacer>
                  <v-text-field
                    v-model="bala_pa.kg_bala"
                    required
                    @focus="show"
                    v-on:keyup.enter="imprimirPegatina"
                    outlined
                    style="height:55px; border: none; font-size: 30px; min-width: 120px; max-width: 100px;"
                  ></v-text-field>
                <strong>
                  kg</strong>
                  <!-- <v-btn style="margin-left: 5px; font-size: 30px" color="error" dark> Pesar</v-btn> -->
              </div>
            </v-row>
            <v-row>
              <div id="num-quarto">
                <strong>Nº QUARTO</strong>
                <span style=" background: none;" ><v-select
                  style="border: none; background: none;"
                  v-model="bala_pa.num_quarto"
                  :items="numeros_quartos"
                  filled
                  chips
                  single-line
                  no-data-text
                  outlined
                  label="Nº"
                  :disabled="true"
                ></v-select></span>
              </div>
            </v-row>
              <div id="botonera-pegatina">
              <!-- Botons d'opcions -->
              <v-btn x-large block color="success" dark @click="imprimirPegatina">Imprimir etiqueta</v-btn>
              <!-- Botons d'opcions -->
              </div>
          </div>
        </v-col>
        <!-- ./Informació Bala PA -->
        <!-- Partida actual -->
        <v-col sm="6">
          <v-row id="info-dades-titol">
            <h3>Partida  {{dades_pa.codi}} {{ textProducteFinal(dades_pa.producte_final) }}</h3>  
          </v-row>
          <div id="info-dades-pa">
            <!-- <v-row v-if="!mescladaSelect">
              <v-autocomplete
                v-model="mescladaSelect"
                style="font-size: 35px; max-height: 70px;"
                :items="mescladesOps"
                :disabled="dades_pa.kg_totals!=0 || dades_pa.num_bales || dades_pa.num_maquina==0"
                @click="mescladesPreparades"
                @click:append="mescladesPreparades"
                @click:clear="mescladaSelect=0"
                @change="mescladaElegida"
                no-data-text
                fullWidth
                clearable
                outlined
                item-text="codi"
                item-value="id"
                label="Mesclada"
              ></v-autocomplete> 
            </v-row> -->
            <v-row>
              <div id="bales-totals">
                <strong>BALAS TOTALES</strong>
                <span>{{ dades_pa.bales_totals }}</span>
              </div>
            </v-row>
            <v-row>
              <div id="kg-totals">
                <strong>KG TOTALES</strong>
                <span>{{ dades_pa.kg_totals }}</span>
              </div>
            </v-row>
            <v-row v-if="maquina_info && estado_cuarto>0 && numeros_quartos_pendientes.length!=0">
              <div id="estat-quartos">
                <!-- <v-btn @click="btnQuarto1"> Cuarto 1 : {{textEstat(maquina_info.cuartos[0].estat)}} </v-btn>
                <v-btn @click="btnQuarto2"> Cuarto 2 : {{textEstat(maquina_info.cuartos[1].estat)}}</v-btn> -->
                <v-btn v-if="estado_cuarto==1 && boton=='i'" @click="iniciarQuarto"> INICIAR CUARTO </v-btn>
                <v-btn v-if="estado_cuarto==1 && boton=='c'" @click="retomarQuarto"> RETOMAR CON EL CUARTO </v-btn>
                <v-btn :disabled="bala_pa.siguiente_cuarto==0" v-if="estado_cuarto==2 && numeros_quartos_pendientes.length>0" @click="cambiarQuarto"> CAMBIAR CUARTO </v-btn>
                <span style=" background: none;" ><v-select
                  style="border: none; background: none;"
                  v-model="bala_pa.num_quarto"
                  :items="numeros_quartos"
                  filled
                  chips
                  single-line
                  no-data-text
                  outlined
                  label="Nº"
                  v-if="estado_cuarto==1 && boton=='i'"
                ></v-select></span>
                <span style=" background: none;" ><v-select
                  style="border: none; background: none;"
                  v-model="bala_pa.siguiente_cuarto"
                  :items="numeros_quartos_pendientes"
                  filled
                  chips
                  single-line
                  no-data-text
                  outlined
                  label="Nº"
                  v-if="estado_cuarto==2 || boton=='c'"
                ></v-select></span>
              </div>
            </v-row>
            <v-row v-if="(mescladaSelect!=0 || mescladaSelect!=undefined) && dades_pa.num_maquina>0">
              <div id="magatzem">
                <strong>Almacén</strong>
                <v-select
                  style="border: none; background: none; height: 65px; font-size:18px; width:30mm; margin-top:5px"
                  v-model="dades_pa.magatzem"
                  :items="OpcionesSubalmacenes"
                  item-value="id"
                  item-text="nom"
                  filled
                  single-line
                  no-data-text
                  chips
                  outlined
                  label="Almacén"
                ></v-select>
              </div>
            </v-row>
            <v-row v-if="(mescladaSelect!=0 || mescladaSelect!=undefined) && dades_pa.num_maquina>0 && maquina_info.partida_actual==null">
              <div id="magatzem">
                 <v-btn
                  fab
                  dark
                  medium
                  @click="recarregarMesclades"
                >
                  <v-icon dark>mdi-cached</v-icon>
                </v-btn>
                <v-select
                  v-if="boton=='i'"
                  style="border: none; background: none; height: 65px; 
                  font-size:18px; width:30mm; margin-top:5px"
                  v-model="of"
                  :items="mescladesOps"
                  item-value="id"
                  filled
                  single-line
                  no-data-text
                  chips
                  outlined
                  label="Mescladas en cola"
                  @change="cargarMescladaSeleccionada"
                >
                <template slot="item" slot-scope="data">
                {{ textPrefix(data.item.prefix_nom) }}{{ textProducteFinal(data.item.producte_final) }}  {{textObservacions(data.item.observacions)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ textPrefix(data.item.prefix_nom) }}{{ textProducteFinal(data.item.producte_final) }} {{textObservacions(data.item.observacions)}}
              </template>
              </v-select>
              <v-select
                  v-if="boton=='c'"
                  style="border: none; background: none; height: 65px; 
                  font-size:18px; width:30mm; margin-top:5px"
                  v-model="of"
                  :items="mescladesIntOps"
                  item-value="id"
                  filled
                  single-line
                  no-data-text
                  chips
                  outlined
                  label="Mezcladas interrumidas"
                  @change="cargarPartidaSeleccionada"
                >
                <template slot="item" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}} - {{ textProducteFinal(data.item.producte_final) }} {{textObservacions(data.item.observacions)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{data.item.codi.replaceAll('.', '')}} - {{ textProducteFinal(data.item.producte_final) }} {{textObservacions(data.item.observacions)}}
              </template>
              </v-select>
               
                <v-btn v-if="of>0" @click="ProduirSenseBales">
                <strong style="font-size:25px;" >PRODUCIR</strong>
                </v-btn>
                <v-btn v-if="of==null && boton=='i'" @click="btn_interrumpidas">
                <strong style="font-size:25px;" >INTERRUMPIDAS</strong>
                </v-btn>
                <v-btn v-if="of==null && boton=='c'" @click="btn_en_cola">
                <strong style="font-size:25px;" >EN COLA</strong>
                </v-btn>
                
              </div>
            </v-row>
            <v-row>
              <div id="magatzem" style="justify-content: center;">
                <v-btn @click="reimprimirPegatina"  :disabled="mescladaSelect==0 || mescladaSelect==undefined">
                <strong>REIMPRIMIR O CORREGIR ETIQUETA</strong>
                </v-btn>
              </div>
              <div v-if="!maquina_info.cuarto_actual && maquina_info.cuartos[0].cuarto_actual" id="magatzem" style="justify-content: center;">
                <v-btn @click="iniciarCuartoAtascado()"  >
                <strong>Iniciar cuarto atascado</strong>
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-col>
        <!-- ./Partida actual -->
            <vue-touch-keyboard
        id="keyboard"
        v-if="visible"
        layout="numeric"
        :cancel="hide"
        :accept="accept"
        :input="input"
        :next="next"
      />
      <vue-touch-keyboard
        id="keyboard2"
        v-if="visible2"
        layout="numeric"
        :cancel="hide2"
        :accept="accept2"
        :input="input2"
        :next="next2"
      />
      </v-row>
      <!-- ./Dades -->
      <!-- Footer -->
      <v-footer class="font-weight-medium" height="38%">
        <v-col class="text-center" cols="12" @click="redireccio('MenuTreballadors')" style="padding:0px">
          <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
      <!-- ./Footer -->
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "punt-pesatge-bales",
  data: () => ({
    maquinas:[{id:1, numero: 1}, {id:2, numero: 2}, {id:3, numero: 3}, {id:4, numero: 4} ],
    estado_cuarto:0,
    of:[],
    impresora:"FrontEnd",
    bala_a_imprimir:null,
    bala_cargada:[],
    num_maquina_abled:false,
    visible: false,
    visible2: false,
    layout: "numeric",
    input: null,
    input2: null,
    options: {
      useKbEvents: false
    }, 
    OpcionesSubalmacenes:[],
    bala_pa: {
      kg_bala: "",
      num_quarto: 0, 
      siguiente_cuarto: 0
    },
    bala:[],
    boton:"i",
    dialogError: false,
    dialogReimprimir:false,
    msg_error:"",
    maquina_info:{
      cuartos:[{cuarto_actual:""}]
    },
    dialogInterrumpir: false,
    dialogFinalitzar: false,
    mesclada_activa:[],
    numeros_maquinas: [],
    numeros_quartos: [],
    numeros_quartos_pendientes: [],
    mescladaSelect: 0,
    dades_pa: {
      producte_final: 0,
      num_maquina: 0,
      bales_totals: 0,
      kg_totals: 0,
      bales_productes_finals:[], 
      quartos_mesclat:[],
      magatzem: 4
    }
  }),
  computed: {
    ...mapGetters({
      mesclades: "getMescladesNoAcabades",
      productesFinals: "getMateriesProducteFinal", 
      //maquinas: "getMaquinas",
      mescladesOps: "getMescladesNoAcabades", 
      mescladesIntOps: "getMescladesInterrumpidas", 
      magatzems: "getMagatzems"
    })
  },
  methods: {
    ...mapActions([
      "addBalaMesclada",
      "mezclarBala",
      "getMescladesExecusio",
      "getMescladesNoAcabades",
      "getMescladesInterrumpidas", 
      "getMesclades",
      "getMaquinas",
      "getMesclada",
      "getMesclada_new",
      "getPartida_new",
      "getMagatzems",
      "getMaquinaInfo",
      "getQuartoMesclat",
      "getMateriesProducteFinal", 
      "interrumpirPartida", 
      "iniciarPartidaSalida",
      "reanudarPartida",
      "getQuartosMesclat",
      "crearBalaProducteFinal", 
      "generarPegatinaBala",
      "finalizarEjecucionQuarto",
      "generarPegatinaBalaBorra", 
      "iniciarEjecucionQuarto",
      "almacenarBalas", 
      "imprimirPegatinaBalaBorra_new",
      "sendBala", 
      "getBorra",
      "getBalesPartida",
      "editBalaProducteFinal"
      
    ]),
    recarregarMesclades(){
      if(this.boton=='i'){
        if(this.maquina_info.numero==1) this.getMescladesNoAcabades({estat: "c", prefix: "1"});
        if(this.maquina_info.numero!=1) this.getMescladesNoAcabades({estat: "c", prefix: this.maquina_info.numero});
        }
      if(this.boton=='c'){
        if(this.maquina_info.numero==1) this.getMescladesInterrumpidas({pagina:1,estat: "i", prefix: "1"});
        if(this.maquina_info.numero!=1) this.getMescladesInterrumpidas({pagina:1,estat: "i", prefix: this.maquina_info.numero});
        }
      
    },
    cargarBala(){
      this.getBorra(this.bala_a_imprimir).then(
      borra => {this.bala_cargada = borra;}
    );
    },
    editarBala() {
      this.editBalaProducteFinal(this.bala_cargada).then(response => {
        if (response === 200) {
          EventBus.$emit("Bala.Notificacio", {
            text: "Bala editada correctament",
            color: "success"
          });
          //this.dades_pa.bales_productes_finals.find(el=> el.barcode==this.bala_cargada.barcode).quilos = this.bala_cargada.quilos;
          this.btn_reimprimirPegatina();
          this.cargarMaquina();

        } else
          EventBus.$emit("Bala.Notificacio", {
            text: "Error editant la bala",
            color: "error"
          });
      });
    },
    reimprimirPegatina(){
      this.getBalesPartida(this.mescladaSelect).then(
        response=>{
          if(response) this.dades_pa.bales_productes_finals = response.bales_productes_finals
        }
      )
      this.dialogReimprimir=true;
      this.hide();
    },
    btn_reimprimirPegatina(){
      if(this.bala_a_imprimir)
      {this.imprimirPegatinaBalaBorra_new({id: this.bala_a_imprimir, info_impresion:{destino:this.impresora, 
                  request_id: localStorage.getItem('uuid')}})}
    },
    cargarPartidaSeleccionada(){
      this.getPartida_new(this.of).then(of=>{
         this.dades_pa.bales_totals = of.numero_bales_borra;
         this.dades_pa.codi = of.codi.replaceAll('.', '');
         this.dades_pa.producte_final = of.producte_final;
         //SI NO VIENEN LOS CUARTOS EN LA VISTA DETALLADA AQUÍ HARÍA FALTA OTRA LLAMADA
         this.getQuartosMesclat(of.id).then(quartos=>
         {
          this.dades_pa.quartos_mesclat = quartos;
         })
         this.dades_pa.kg_totals = of.quilos_borra_totales;
         this.getBalesPartida(of.id).then(
            response=>{
              if(response) this.dades_pa.bales_productes_finals = response.bales_productes_finals
            }
          )
         //this.dades_pa.bales_productes_finals = of.bales_productes_finals;
         })
    },
    cargarMescladaSeleccionada(){
      this.getMesclada_new(this.of).then(of=>{
         this.dades_pa.bales_totals = of.numero_bales_borra;
         this.dades_pa.codi = of.codi.replaceAll('.', '');
         this.dades_pa.producte_final = of.producte_final;
         //SI NO VIENEN LOS CUARTOS EN LA VISTA DETALLADA AQUÍ HARÍA FALTA OTRA LLAMADA
         this.getQuartosMesclat(of.id).then(quartos=>
         {
          this.dades_pa.quartos_mesclat = quartos;
         })
         this.dades_pa.kg_totals = of.quilos_borra_totales;
         //this.getBalesPartida(of.id).then(
         //   response=>{
         //     if(response) this.dades_pa.bales_productes_finals = response.bales_productes_finals
         //   }
         // )
         //this.dades_pa.bales_productes_finals = of.bales_productes_finals;
         })
    },
    iniciarQuarto(){
      //this.mescladesOps.find(el => el.id === this.of).quartos_mesclat[(this.bala_pa.num_quarto-1)].porcions_mesclat.forEach((porcio,index)=>{
      this.dades_pa.quartos_mesclat[(this.bala_pa.num_quarto-1)].porcions_mesclat.forEach((porcio,index)=>{
              this.mezclarBala({barcode: porcio.barcode, 
                  quarto: this.dades_pa.quartos_mesclat[(this.bala_pa.num_quarto-1)].id, num_maquina: this.dades_pa.num_maquina}).then(
                   response => {if(response && index=== (this.dades_pa.quartos_mesclat[(this.bala_pa.num_quarto-1)].porcions_mesclat.length-1)) this.cargarMaquina}
                  )
            })
      this.cargarMaquina();
      this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.dades_pa.quartos_mesclat[(this.bala_pa.num_quarto-1)].id}).then(
          response=>{if(response){
          this.cargarMaquina();
          this.estado_cuarto =2;
          this.iniciarPartidaSalida();
          }}

        )
    },
    iniciarCuartoAtascado(){
      //this.mescladesOps.find(el => el.id === this.of).quartos_mesclat[(this.bala_pa.num_quarto-1)].porcions_mesclat.forEach((porcio,index)=>{
      this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.maquina_info.cuartos[0].cuarto_actual}).then(
          response=>{if(response){
          this.cargarMaquina();
          this.estado_cuarto =2;
          this.iniciarPartidaSalida();
          }}

        )
    },
    retomarQuarto(){
      //this.mescladesOps.find(el => el.id === this.of).quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].porcions_mesclat.forEach((porcio,index)=>{
      this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].porcions_mesclat.forEach((porcio,index)=>{
              this.mezclarBala({barcode: porcio.barcode, 
                  quarto: this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].id, num_maquina: this.dades_pa.num_maquina}).then(
                   response => {if(response && index=== (this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].porcions_mesclat.length-1)) this.cargarMaquina}
                  )
            })
      this.cargarMaquina();
      this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].id}).then(
          response=>{if(response){
          
          this.estado_cuarto =2;
          this.iniciarPartidaSalida();
          this.cargarMaquina();
          }}

        )
      this.cargarMaquina();
    },
    cambiarQuarto(){
      this.finalizarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.maquina_info.cuartos[0].cuarto_actual}).then(
        response=> 
           {
            if(response) 
            { this.cargarMaquina
              this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].porcions_mesclat.forEach((porcio,index)=>{
              this.mezclarBala({barcode: porcio.barcode, 
                  quarto: this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].id, num_maquina: this.dades_pa.num_maquina}).then(
                   response => {if(response && index=== (this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].porcions_mesclat.length-1)) 
                   this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.dades_pa.quartos_mesclat[(this.bala_pa.siguiente_cuarto-1)].id}).then(
                    response=>{if(response){
                      this.cargarMaquina;
                      this.bala_pa.num_quarto = response.cuarto.quarto;
                      this.estado_cuarto =2;
                      this.bala_pa.siguiente_cuarto = 0;
                    }}
                  )
                   this.cargarMaquina}
                  )
            })}}
        )
        
    },
    btn_en_cola(){
      if(this.maquina_info.numero==1) this.getMescladesNoAcabades({estat: "c", prefix: "1"});
      if(this.maquina_info.numero!=1) this.getMescladesNoAcabades({estat: "c", prefix: this.maquina_info.numero});
      this.boton = "i";
    },
    btn_interrumpidas(){
      if(this.maquina_info.numero==1) this.getMescladesInterrumpidas({pagina:1,estat: "i", prefix: "1"});
      if(this.maquina_info.numero!=1) this.getMescladesInterrumpidas({pagina:1,estat: "i", prefix: this.maquina_info.numero});
      this.boton = "c";
    },
    ProduirSenseBales(){
      if(this.dades_pa.quartos_mesclat[0].porcions_mesclat.length>0){
        // this.mescladesOps.find(el => el.id === this.of).quartos_mesclat.forEach(quarto=>{
        //   quarto.porcions_mesclat.forEach(porcio=>{
        //     this.mezclarBala({barcode: porcio.barcode, 
        //         quarto: quarto.id, num_maquina: this.dades_pa.num_maquina}).then(
        //          response => {if(response) this.cargarMaquina}
        //         )
        //   })
        // })
        // this.mescladesOps.find(el => el.id === this.of).quartos_mesclat[0].porcions_mesclat.forEach((porcio,index)=>{
        //      this.mezclarBala({barcode: porcio.barcode, 
        //          quarto: this.mescladesOps.find(el => el.id === this.of).quartos_mesclat[0].id, num_maquina: this.dades_pa.num_maquina}).then(
        //           response => {if(response && index=== (this.mescladesOps.find(el => el.id === this.of)?.quartos_mesclat[0].porcions_mesclat.length-1)) this.cargarMaquina}
        //          )
        //    })
        if(this.dades_pa.quartos_mesclat.length==1){
          this.dades_pa.quartos_mesclat[0].porcions_mesclat.forEach((porcio,index)=>{
              this.mezclarBala({barcode: porcio.barcode, 
                  quarto: this.dades_pa.quartos_mesclat[0].id, num_maquina: this.dades_pa.num_maquina}).then(
                   response => {if(response && index=== (this.dades_pa.quartos_mesclat[0].porcions_mesclat.length-1)) this.cargarMaquina}
                  )
            })
            this.cargarMaquina
            this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.dades_pa.quartos_mesclat[0].id}).then(
          response=>{if(response){
          this.cargarMaquina();
          this.estado_cuarto =2;
          this.iniciarPartidaSalida();
          }}

        )
        }else{
        this.estado_cuarto=1;
        this.mescladaElegida()}
      }
      else
      {
        const bala_a_crear = {
        materia_primera: 26,
        observacions: "FAKE",
        preu_quilo: "0.123",
        quilos: "1"
      }
        this.dades_pa.quartos_mesclat.forEach(q=>{
          this.sendBala(bala_a_crear).then(bala=>
      {
        if(bala.id){
          this.almacenarBalas({posicio_id: 2, balas_materia_prima: [bala.id]}).then(
            response=> {if(response){
              this.addBalaMesclada({id:this.of ,bala:{bala: bala.id, 
              quartos:[ q.id]}}).then(
                response=> {if(response && q.id == this.dades_pa.quartos_mesclat[0].id) 
                this.mezclarBala({barcode: bala.barcode, 
                quarto: q.id, num_maquina: this.dades_pa.num_maquina})
                 this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: q.id}).then(
                    response=>{if(response){
                    this.cargarMaquina();
                    this.estado_cuarto =2;
                    this.iniciarPartidaSalida();
                    }}

                  )
                }
              )
            }}
          )
        } else if(bala.id){ 
          // this.$router.push({ name: 'BalaDetail', params: { id: bala.id } })
        }
      })
        })
      }
    },
    cargarMaquina_NO_LL(){
      if(this.boton=='i'){
        this.getMescladesNoAcabades({estat: "c", internas: "0"});
        }
      if(this.boton=='c'){
        //this.getMescladesNoAcabades({estat: "i", internas: "0"});
        this.getMescladesInterrumpidas({pagina:1, estat: "i", internas: "0"});
        }
    
    },
    cargarMaquina(){
       this.getMaquinaInfo(this.dades_pa.num_maquina).then(info=>{
       this.maquina_info = info
       this.mescladaSelect=info.partida_actual;
       this.of = info.partida_actual;
       if(info.cuarto_actual){
         this.estado_cuarto=2;
       }else{
         this.estado_cuarto=0;
       }
       if(info.partida_actual){
       this.getPartida_new(info.partida_actual).then(of=>{
         this.dades_pa.bales_totals = of.numero_bales_borra;
         this.dades_pa.codi = of.codi.replaceAll('.', '');
         this.dades_pa.producte_final = of.producte_final;
         //this.dades_pa.quartos_mesclat = of.quartos_mesclat;
         this.dades_pa.kg_totals = of.quilos_borra_totales;
         this.getBalesPartida(this.mescladaSelect).then(
            response=>{
              if(response) this.dades_pa.bales_productes_finals = response.bales_productes_finals
            }
          )
         //this.dades_pa.bales_productes_finals = of.bales_productes_finals;
         
         //SI NO VIENEN LOS CUARTOS EN LA VISTA DETALLADA AQUÍ HARÍA FALTA OTRA LLAMADA
         this.getQuartosMesclat(of.id).then(quartos=>
         {
          this.dades_pa.quartos_mesclat = quartos;
          this.mescladaElegida();
          quartos.forEach(el=>{
           if(el.id == info.cuarto_actual) this.bala_pa.num_quarto = el.quarto;
         })
         })
         
       })}else{
          this.recarregarMesclades();
          this.dades_pa.codi="";
          this.dades_pa.bales_totals=0;
          this.dades_pa.kg_totals =0;
          this.dades_pa.bales_productes_finals="";
          this.dades_pa.quartos_mesclat="";
          this.dades_pa.producte_final="";

       }
       //if(info.partida_actual) {this.mescladaElegida()}
       })
       
       
    },
    mescladaElegida(){
      this.mescladaSelect = this.of;
      this.numeros_quartos=[];
      this.numeros_quartos_pendientes=[];
      //this.getQuartosMesclat(this.mescladaSelect).then(quartos=> quartos.forEach(el=>
      this.dades_pa.quartos_mesclat.forEach(el=>
      {this.numeros_quartos.push(el.quarto)
      if(el.estat==0){this.numeros_quartos_pendientes.push(el.quarto)}}
      )
      function comparar ( a, b ){ return a - b; } 
      this.numeros_quartos = this.numeros_quartos.sort(comparar);
    },
    btnQuarto1(){
      if(this.maquina_info.cuartos[0].estat=="1"){
        this.getQuartoMesclat(this.maquina_info.cuartos[0].cuarto_actual).then(
          response=> {this.bala_pa.num_quarto=response.quarto}
        )
        this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.maquina_info.cuartos[0].cuarto_actual}).then(
          this.cargarMaquina
        )}
        if(this.maquina_info.cuartos[0].estat=="-"){
        // this.getQuartoMesclat(this.maquina_info.cuartos[0].cuarto_actual).then(
        //   response=> {this.bala_pa.num_quarto=response.quarto}
        // )
        this.finalizarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.maquina_info.cuartos[0].cuarto_actual}).then(
           this.cargarMaquina
        )}
    },
    btnQuarto2(){
      if(this.maquina_info.cuartos[1].estat=="1"){
        this.getQuartoMesclat(this.maquina_info.cuartos[1].cuarto_actual).then(
          response=> {this.bala_pa.num_quarto=response.quarto}
        )
        this.iniciarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.maquina_info.cuartos[0].cuarto_actual}).then(
           this.cargarMaquina
        )}
        if(this.maquina_info.cuartos[1].estat=="-"){
        this.finalizarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina, quarto_id: this.maquina_info.cuartos[0].cuarto_actual}).then(
           this.cargarMaquina
        )}
    },
    // Escaneja bala

    iniciarPartidaSelect(){
      if(this.dades_pa.bales_totals==0){
      this.iniciarPartidaSalida({id: this.mescladaSelect, num_maquina: this.dades_pa.num_maquina});
      }
    },
    textEstat(estat){
      if(estat == 0){
        return "Vacío"
      } else if (estat == "+"){
        return "Llenando"
      } else if(estat == "-"){
        return "Vaciando"
      } else if (estat == 1){
        return "Lleno"
      }
    },
    textPrefix(prefix_nom){
      if(prefix_nom !="1" && prefix_nom !="2" && prefix_nom !="3" && prefix_nom !="4" ){
        return `[${prefix_nom}]`
      }
    },
    textProducteFinal(id_producte){
      return this.productesFinals.find(el => el.id === id_producte)?.nom 
    },
    textObservacions(obs){
      if(obs) return ` -  ${obs} `
      //return this.productesFinals.find(el => el.id === id_producte)?.nom 
    },
    textQuarto(num){
      if(num>0 && this.maquina_info.cuarto_actual>0) {return  ` - Cuarto: ${num}`} else return
    },
    // Accions
    imprimirPegatina() {
      if(this.mescladaSelect==0){
        this.dialogError = true; 
        this.msg_error = "No hi ha MESCLADA ACTIVA, selecciona una";
        return
      }
      if(this.dades_pa.num_maquina==0){
        this.dialogError = true;
        this.msg_error = " Falta el número de máquina";
        return
      }
      if(this.bala_pa.num_quarto>0 && parseInt(this.bala_pa.kg_bala)>0 ){
      this.crearBalaProducteFinal({num_maquina: this.dades_pa.num_maquina, 
      quilos: parseFloat(this.bala_pa.kg_bala)})
      .then(
        response => {
          if(response.bala.id){
            this.dades_pa.bales_totals += 1;
            //this.dades_pa.kg_totals +=  parseInt(this.bala_pa.kg_bala);
            this.almacenarBalas({posicio_id: this.dades_pa.magatzem, balas_borra: [response.bala.id]})
            //CREO QUE NO HACE FALTA PORQUE HACE LO MISMO QUE CARGAR MÁQUINA
            //this.getMesclada(this.maquina_info.partida_actual).then(of=>{
            //this.dades_pa.bales_productes_finals = of.bales_productes_finals;})
          }
          if(response.bala.id){
                this.imprimirPegatinaBalaBorra_new({id: response.bala.id, info_impresion:{destino:this.impresora, 
                  request_id: localStorage.getItem('uuid')}})
                }
                this.cargarMaquina();
              });
      this.bala_pa.kg_bala = "";
      this.getMaquinaInfo(this.dades_pa.num_maquina).then(info=>{this.maquina_info = info}) 
      return 
      } else {
        this.dialogError = true;
        this.msg_error = " Falten els quilos o el número de quarto";
        return
      }
    },
    finalitzarPartida() {
      this.getMaquinaInfo(this.dades_pa.num_maquina).then(info=>{
      this.finalizarEjecucionQuarto({num_maquina: this.dades_pa.num_maquina,quarto_id: info.cuarto_actual}).then(
        response=>{
                      if(response.error)
                      {
                        this.dialogError = true; 
                        this.msg_error = `Error al finalitzar quarto - ${info.cuarto_actual}`
                      }
                      else {
                          this.dades_pa.codi=""
                          this.dialogError = true; 
                          this.msg_error = "PARTIDA FINALIZADA CON ÉXITO"
                          this.dades_pa.bales_totals = 0;
                          this.dades_pa.kg_totals = 0;
                          this.mescladaSelect = null;
                          this.dialogFinalitzar = false;
                          this.numeros_quartos=[];
                          this.bala_pa.num_quarto= null;
                          this.estado_cuarto =0;
                          //this.getMescladesExecusio();
                          this.recarregarMesclades();
                          this.of=null;
                          this.cargarMaquina;
                        }}
      )
      })
      
    },
    interromprePartida() {
      this.dades_pa.codi=""
      this.getMaquinaInfo(this.dades_pa.num_maquina).then(info=>{
      this.interrumpirPartida({quarto_id: info.cuarto_actual,
       num_maquina: this.dades_pa.num_maquina }).then(
        response=>{
                      if(response.error)
                      {
                        this.dialogError = true; 
                        this.msg_error = `Error al interrumpir partida - ${info.cuarto_actual}`
                      }
                      else {
                          this.dades_pa.codi=""
                          this.dialogError = true; 
                          this.msg_error = "PARTIDA INTERRUMPIDA CON ÉXITO"
                          this.dades_pa.bales_totals = 0;
                          this.dades_pa.kg_totals = 0;
                          this.mescladaSelect = null;
                          this.dialogInterrumpir = false;
                          this.numeros_quartos=[];
                          this.bala_pa.num_quarto= null;
                          this.estado_cuarto =0;
                          //this.getMescladesExecusio();
                          this.recarregarMesclades();
                          this.of=null;
                          this.cargarMaquina;
                        }}
      )
       ;})
       
      
      //this.editStatePartida(this.mescladaSelect)
      // this.mescladaSelect = [];
    },

    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    },
    //teclado
    accept() {
      // Cierra el Keypad
      this.hide();
    },
    show(e) {
      // Muestra el keypad en poner el focus en un input
      this.input = e.target;
      this.layout = e.target.dataset.layout;

      if (!this.visible) this.visible = true;
    },
    hide() {
      // Cierra el Keypad
      this.visible = false;
    },
    next() {
      // Selecciona el siguiente input
      this.imprimirPegatina()
      // let inputs = document.querySelectorAll("input");
      // let found = false;
      // [].forEach.call(inputs, (item, i) => {
      //   if (!found && item == this.input && i < inputs.length - 1) {
      //     found = true;
      //     this.$nextTick(() => {
      //       inputs[i + 1].focus();
      //     });
      //   }
      // });
      // if (!found) {
      //   this.input.blur();
      //   this.hide();
      // }
    },
    //teclado2
    accept2() {
      // Cierra el Keypad
      this.hide2();
    },
    show2(e) {
      // Muestra el keypad en poner el focus en un input
      this.input2 = e.target;
      this.layout = e.target.dataset.layout;

      if (!this.visible2) this.visible2 = true;
    },
    hide2() {
      // Cierra el Keypad
      this.visible2 = false;
    },
    next2() {
      this.editarBala(); 
      this.hide2(); 
    }
  }, 
  created() {
  
  //this.getMaquinas();
  if(localStorage.getItem("nom")=="Pantalla1"){
    this.dades_pa.num_maquina = 1;
    this.num_maquina_abled = true;
    this.impresora = "Pantalla1"
    //this.getMescladesNoAcabades({estat: "c", prefix: this.maquina_info.numero})
    this.cargarMaquina()
  }
  if(localStorage.getItem("nom")=="Pantalla2"){
     this.dades_pa.num_maquina = 2;
     this.num_maquina_abled = true;
     this.impresora = "Pantalla2"
     //this.getMescladesNoAcabades({estat: "c", prefix: this.maquina_info.numero})
    this.cargarMaquina()

   }
   if(localStorage.getItem("nom")=="Pantalla3"){
     this.dades_pa.num_maquina = 3;
     this.num_maquina_abled = true;
     this.impresora = "Pantalla3"
     //this.getMescladesNoAcabades({estat: "c", prefix: this.maquina_info.numero})
    this.cargarMaquina()

   }
  //this.getMesclades();
  this.getMateriesProducteFinal();
  //this.getMescladesExecusio();
  
  this.getMagatzems().then(
      response => {response.forEach(el=> el.posicions.forEach(posicio=> 
      {
        posicio.nom = el.nom +'- Nº ' + posicio.zona
        this.OpcionesSubalmacenes.push(posicio)}) )}
    );
  },
};
</script>

<style lang="scss" scoped>
@import "../../../node_modules/vue-touch-keyboard/dist/vue-touch-keyboard.css";
h1 {
  text-align: center;
  padding-bottom: 1em;
}

h1,
h2,
h3,
h3,
h4,
p,
span,
strong {
  font-size: 2em;
}

footer > div > span {
  font-size: 1em !important;
}

// General
#punt-pesatge-bales {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  #container {
    height: 80%;
    width: 100%;
    .row{
      height: auto;
    }
  }
}

// Títols
#info-bala-titol,
#info-dades-titol {
  display: flex;
  justify-content: center;
  h3 {
    position: relative;
    top: 16px;
    font-size: 1.8em;
    background: white;
    border: 1px solid black;
    padding: 5px;
  }
}

// Row central
#container {
  padding-top: 3vw;
}
// Bloc esquerra
#info-bala-pa {
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  height: 40vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      
      width: 100%;
      height: auto;
      min-height: 8vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      .num-quarto{
        height: auto;
        min-height: 8vh;
      }

      * {
        margin: auto 0;
      }
    }
    button {
      
      max-height: 50px;
    }
  }
}

#num-quarto{
  height: 80px !important;
  padding: 0px;
  .span{ 
    text-align: center; 
    padding-top: 5px;
    height: 60px !important;
    margin-left:80px;
  }
  .v-select {
    text-align: center;
    font-size: 26px !important;
    width: 120px !important;
    margin-top: 8px !important;
    padding: 0px;
  }
}

#num-maquina{
  height: 70px !important;
  outline:1px solid black; 
  span{ 
    text-align: center; 
    height: 65px !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    strong{
      height: 30px;
      margin-top: 5px;
    }
    button {
      margin-top: 15px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .v-select {
    text-align: center;
    font-size: 26px !important;
    margin-top: 8px !important;
    padding: 0px;
  }
  
  #v-select__selections {
      line-height: normal !important;
  }
  .v-select {
    text-align: center;
    font-size: 26px !important;
    width: 50px !important;
    padding: 0px;
    .wn-menu-content-list .v-select-select {
        border: none;
      }

    .wn-menu-content-list .v-select-select option {
        padding: 0px;
        line-height: 24px;
        height: 24px;
        font-family: Tahoma;
        font-size: 40px !important;
        color: #003399;
      }
      .v-select__slot > .v-select__selections {
      line-height: normal !important;
}
  }
  
}
#estat-quartos{
  justify-content: center;
  button{
    font-size: 1.5vw;
    color:green;
    width: 70%;
  }
  .v-select {
    text-align: center;
    font-size: 26px !important;
    width: 120px !important;
    margin-top: 8px !important;
    padding: 0px;
  }
}
// Bloc dreta
#info-dades-pa {
  background: rgb(211, 211, 211);
  padding: 0 2em;
  border: 1px solid black;
  height: 480px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      background: white;
      width: 100%;
      height: 70px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px dashed black;
      margin: 0.3em 0;
      padding: 0 0.5em;

      * {
        margin: auto 0;
      }
    }
  }
}

// Botonera
#botonera {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: none !important;

  button {
    font-size: 100%;
    width: 40%;
    height: 100%;
    border-radius: 10px;
  }
}

#botonera-pegatina {
  width: 100%;
  height: 10vh !important;
  background: none !important;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: none !important;
  margin-top: 40px;
  margin-bottom: 20px;

  button {
    font-size: 2.8vw;
    background: none;
    width: 40%;
    height: 100% !important;
    border-radius: 10px;
    margin-bottom: 20px;
  }
}

// Keypad
  #keyboard {
    position: fixed;
    left: -50%;
    right: 0;
    bottom: 60px;
    font-size: 6mm;

    z-index: 1000;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    max-height: auto;
    padding: 1em;

    background-color: #eee;
    box-shadow: 0px 3px 10px rgba(black, 0.3);

    border-radius: 10px;
  }
  #keyboard2 {
    position: fixed;
    left: 40%;
    right: 0%;
    bottom: 60px;
    font-size: 6mm;

    z-index: 1000;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    max-height: auto;
    padding: 1em;

    background-color: #eee;
    box-shadow: 0px 3px 10px rgba(black, 0.3);

    border-radius: 10px;
  }
</style>