<template>
  <v-container>
    <!-- Ordre Eixida -->
    <v-simple-table class="mt-12">
      <thead>
        <h1>Vista detallada d'Ordre d'Eixida</h1>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Data</b>
          </td>
          <td>{{ textData(ordreEixida.data_hora) }}</td>
        </tr>
        <tr>
          <td>
            <b>Client</b>
          </td>
          <td>{{ ordreEixida.client }}</td>
        </tr>
        <tr v-if="ordreEixida.imprimir_logo">
          <td></td>
          <td>
            <b>Amb logo</b>
          </td>
        </tr>
        <tr v-else>
          <td></td>
          <td>
            <b>Sense logo</b>
          </td>
        </tr>
        <tr v-if="ordreEixida.expedida">
          <td>
            <b>Estat</b>
          </td>
          <td>Expedida</td>
        </tr>
        <tr v-else-if="ordreEixida.completa">
          <td>
            <b>Estat</b>
          </td>
          <td>Completa</td>
        </tr>
        <tr v-if="ordreEixida.observacions">
          <td>
            <b>Observacions</b>
          </td>
          <td>{{ ordreEixida.observacions }}</td>
        </tr>
        <tr v-if="ordreEixida.num_pedido">
          <td>
            <b>Num. Pedido</b>
          </td>
          <td>{{ ordreEixida.num_pedido }}</td>
        </tr>
        <tr v-if="ordreEixida.comentari_general">
          <td>
            <b>Comentari general</b>
          </td>
          <td>{{ ordreEixida.comentari_general }}</td>
        </tr>
        <tr v-if="ordreEixida.comentaris">
          <td>
            <b>Comentaris per a Operari</b>
          </td>
          <td>{{ ordreEixida.comentaris }}</td>
        </tr>
        <tr v-if="ordreEixida.transportista">
          <td>
            <b>Transportista</b>
          </td>
          <td>{{ ordreEixida.transportista }}</td>
        </tr>
        <tr v-if="ordreEixida.entregado_en">
          <td>
            <b>Entregado en</b>
          </td>
          <td>{{ ordreEixida.entregado_en }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- ./Ordre Eixida -->

    <!-- Linies -->
    <v-simple-table v-if="ordreEixida" class="mt-12">
      <thead>
        <h2>Linies d'Ordre d'Eixida</h2>
      </thead>
      <tbody>
        <div v-for="(linia, index) in ordreEixida.lineas" :key="linia.id">
          <tr>
            <td>
            <h3>
              <b>Producte {{ index + 1}}</b>
            </h3>
            </td>
            <td>
            <v-btn color="normal" dark v-if="ordreEixida.num_bales>0" @click="desasociarbalas(linia)">Desasociar balas</v-btn>
            </td>
          </tr>
          <tr v-if="linia.reserva">
            <td>
              <b>Reserva</b>
            </td>
            <td>{{ textReserva(linia.reserva) }}</td>
          </tr>
          <tr v-if="linia.partida">
            <td>
              <b>Partida</b>
            </td>
            <td>{{ textPartida(linia.partida) }} - {{textBorra(linia.partida)}}</td>
          </tr>
          <tr v-if="linia.imprimir_observaciones">
            <td>
              
            </td>
            <td><b>**Se imprimen las observaciones</b></td>
          </tr>
          <tr v-if="linia.codigo_grs">
            <td>
              <b>Código GRS para Albarán </b>
            </td>
            <td>{{linia.codigo_grs}}</td>
          </tr>
          <tr v-if="linia.materia_primera">
            <td>
              <b>Materia primera</b>
            </td>
            <td>{{ linia.materia_primera }}</td>
          </tr>
          <tr v-if="linia.quilos_aproximats>0">
            <td>
              <b>Quilos aproximats</b>
            </td>
            <td>{{ linia.quilos_aproximats }} kg</td>
          </tr>
          <tr v-if="linia.quilos>0">
            <td>
              <b>Quilos assignats</b>
            </td>
            <td>{{ linia.quilos }} kg</td>
          </tr>
          <tr v-if="linia.max_num_bales>0">
            <td>
              <b>Màx. núm. bales</b>
            </td>
            <td>{{ linia.max_num_bales }}</td>
          </tr>
          <tr v-if="linia.num_bales>0">
            <td>
              <b>Número de bales assignades
                
                </b> [{{ linia.num_bales }}]
            </td>
            <td>
            <tr v-for="(bala, index) in linia.bales" :key="bala.id" style="justify-content: left">
                {{index+1}} -  {{bala.barcode}} - {{bala.quilos}}kg <span>
                      <img
                        src="https://cdn.pixabay.com/photo/2016/03/31/18/31/cancel-1294426_960_720.png"
                        alt
                        @click="desasignarBalasOrdreEixida({barcodes: [bala.barcode]});"
                      />
                    </span></tr> </td>
          </tr>
          <tr v-if="linia.observacions">
            <td>
              <b>Observacions</b>
            </td>
            <td>{{ linia.observacions }} - {{ linia.comentaris }} </td>
          </tr>
          <tr v-if="linia.ref_ari">
            <td>
              <b>Referencia Cliente</b>
            </td>
            <td>{{ linia.ref_ari }}</td>
          </tr>
          <tr v-if="linia.num_pedido">
            <td>
              <b>Num pedido</b>
            </td>
            <td>{{ linia.num_pedido }}</td>
          </tr>
          <tr v-if="linia.ref_materia">
            <td>
              <b>Ref. Materia</b>
            </td>
            <td>{{ linia.ref_materia }}</td>
          </tr>
          
        </div>
      </tbody>
    </v-simple-table>
    <!-- ./Linies -->

    <!-- Botons d'opcions -->
    <div id="botonera">
      <v-btn color="primary" dark @click="btnEditarOrdreEixida">Editar</v-btn>
      <!-- Dialog/Modal per eliminar ordre d'eixida -->
      <v-spacer></v-spacer>
      <v-btn color="green" dark v-if="ordreEixida.completa && ordreEixida.num_bales==0" @click="desCompletarOE">Desencolar</v-btn>
      <v-btn color="green" dark v-if="!ordreEixida.completa && ordreEixida.num_bales==0" @click="completarOE">Completar</v-btn>
      
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" persistent max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn color="error" dark v-on="on">Eliminar</v-btn>
          </template>
        <v-card>
          <v-card-title class="headline">Confirmar eliminar ordre d'eixida</v-card-title>
          <v-card-text>Està segur que vol eliminar esta ordre d'eixida?</v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel·lar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="btnEliminarOrdreEixida">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ./Dialog/Modal per eliminar ordre d'eixida -->
    </div>
    <!-- Botons d'opcions -->
    <!-- Footer -->
    <v-footer class="font-weight-medium" height="38%">
      <v-col class="text-center" cols="12" @click="redireccio('OrdreEixidaList')" style="padding:0px">
        <span>Gestió Integral Borreres, {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <!-- ./Footer -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { EventBus } from "@/event-bus.js";

export default {
  name: "ordre-eixida-detall",
  data: () => ({
    dialog: false,
    partides:[],
    ordreEixida: {
      quilos_aproximats: null,
      max_num_bales: null,
      observacions: "",
      client: null
    }
  }),
  computed: {
    ...mapGetters({ reserves: "getReserves", materiesPrimeresOpcions: "getMateriesPrimeres" })
  },
  methods: {
    ...mapActions([
      "eliminarOrdreEixida",
      "getClient",
      "getMesclada",
      "getPartida_new",
      "getOrdreEixida",
      "getPartides",
      "getMateriaProducteFinal",
      "getReserves", 
      "completarOrdreEixida",
      "desCompletarOrdreEixida",
      "desasignarBalasOrdreEixida",
      "getMateriesPrimeres"
    ]),

    // Botonera
    completarOE(){
      this.completarOrdreEixida(this.ordreEixida.id).then(response=>{
         this.ordreEixida.completa = response.ordre_eixida.completa
      })
    },
    desCompletarOE(){
      this.desCompletarOrdreEixida(this.ordreEixida.id).then(response=>{
        this.ordreEixida.completa = response.ordre_eixida.completa
      })
    },
    btnEditarOrdreEixida() {
      this.$router.push({
        name: "OrdreEixidaEdit",
        params: { id: this.ordreEixida.id }
      });
    },
    btnEliminarOrdreEixida() {
      this.eliminarOrdreEixida(this.ordreEixida.id).then(response => {
        if (response === 204) {
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: "OrdreEixida eliminada correctament",
            color: "success"
          });
          this.$router.push({ name: "OrdreEixidaList" });
        } else
          EventBus.$emit("OrdreEixida.Notificacio", {
            text: "Error eliminant l'ordre d'eixida",
            color: "error"
          });
      });
    },

    // Text
    textData(data_hora) {
      return data_hora?.split("T")[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    },
    textMateriaPrimera(mpID) {
      const materiaPrimera = this.materiesPrimeresOpcions.find(
        el => el.id === mpID
      );
      return materiaPrimera?.nom;
    },
    textReserva(reservaID) {
      const reserva = this.reserves.find(el => el.id === reservaID);
      const partidaCodi = reserva?.nombre_producto.replaceAll('.', '');
      return `${partidaCodi} - ${reserva?.quilos}`;
    },
    textPartida(partidaID) {
      var codi= this.partides.find(el => el.id === partidaID)?.codi.replaceAll('.', '');
      

      return `${codi}  `
    },
    textBorra(partidaID) {
      var obs= this.partides.find(el => el.id === partidaID)?.observacions;
      var nom = this.partides.find(el => el.id === partidaID)?.producte_final_nom;
      return `${nom} - ${obs? `${obs}`: ""}`;
    },
    
    desasociarbalas(linia)
    {
      var id_bales=[]
      linia.bales.forEach(bala=>{
        id_bales.push(bala.barcode)
        
      })
      this.desasignarBalasOrdreEixida({barcodes: id_bales}).then(response=>
        {if(response)
        {this.$router.go();}}
      )
      
      
    },
    // Auxiliars
    redireccio(component) {
      this.$router.push({ name: component });
    }
  },
  async created() {
    await this.getOrdreEixida(this.$route.params.id).then(ordreEixida => {
      this.ordreEixida = ordreEixida;
      ordreEixida.lineas.forEach(element => {
        element.ver_balas = false
        
        if(element.partida){this.getPartida_new(element.partida).then(mesclada=>{
          this.getMateriaProducteFinal(mesclada.producte_final).then(response=>
          {if(response) 
          mesclada.producte_final_nom = response.nom;
          this.partides.push(mesclada);
          })
        })}
      });
    });

    this.getClient(this.ordreEixida.client).then(
      client => (this.ordreEixida.client = client?.nom)
    );
    

    //this.getPartides();
    this.getReserves();
    //this.getMateriesPrimeres();
  }
};
</script>

<style lang="scss" scoped>
// Taules
table {
  thead {
    h1,
    h2 {
      text-align: center;
      padding-bottom: 1em !important;
    }
  }
  tbody {
    tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      td {
        width: 40%;
        min-height: 38px;
        height: auto;
      }
    }
  }
}
img {
  width: 20px;
}
h3 {
  text-align: center;
  padding-bottom: 2em !important;
}

#botonera {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 30%;
  }
}
</style>